import React, { Component, createRef } from "react";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  IconButton,
  Box,
  Dialog,
  Tooltip,
  Badge,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { connect } from "react-redux";
import {
  setSelectedItem,
  setAvailableItem,
  filterAvailableItems,
  setItemSpecifications,
} from "../../../redux/actions/itemActions";
import { getVendors } from "../../../redux/actions/vendorActions";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import { DataGridPro, GridOverlay } from "@mui/x-data-grid-pro";
import _ from "lodash";
import { openSnackbar } from "../../../redux/actions/snackbarAction";
import { getFilterOperators } from "../../Utils/datagridHelper";
import {
  filterItems,
  getAllLinkedItems,
  updateFileSpecificationswithmultiplefiles,
} from "../../../config/apiUrl";
import axios from "axios";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import { setprcombinationItems } from "../../../redux/actions/prrfqactions";

class BrowseItem extends Component {
  constructor(props) {
    super(props);
    this.descriptionElementRef = createRef(null);
  }
  state = {
    rows: [],
    page: 1,
    total: 0,
    filteredTotal: 0,
    selectionModel: [],
    lastPage: "",
    isFilterActive: false,
    filteredRows: [],
    filterDataCurrentPage: 1,
    filterDataLastPage: 1,
    item_name: "",
    item_name_operator: "",
    item_code: "",
    item_code_operator: "",
    item_unit: "",
    item_unit_operator: "",
    counter: 0,
    commonRowsInFilter: 0,
    mainSelectionModel: [],
    showSelected: false,
    selectedRows: [],
    loading: false,
    filterModel: {
      items: [],
    },
  };

  componentDidMount() {
    this.fetchItems(1);
    // in order to wait for the element to be actually drawn, using setTimeout
    setTimeout(() => {
      if (this.descriptionElementRef.current !== null) {
        this.descriptionElementRef.current.focus();
      }
    }, 0);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.showSelected && !this.state.mainSelectionModel.length) {
      this.setState({
        showSelected: false,
      });
    }

    if (
      prevState.mainSelectionModel?.length !==
      this.state.mainSelectionModel?.length
    ) {
      // in order to solve the error -> row with id # not found
      setTimeout(() => {
        this.setState({
          selectedRows: this.state.mainSelectionModel,
        });
      });
    }
  }

  handleScrollEnd = () => {
    this.setState(
      {
        counter: this.state.counter + 1,
      },
      () => {
        if (this.state.counter > 1) {
          if (this.state.isFilterActive) {
            if (
              this.state.filterDataCurrentPage !== this.state.filterDataLastPage
            ) {
              this.setState(
                {
                  filterDataCurrentPage: this.state.filterDataCurrentPage + 1,
                },
                () => {
                  this.fetchFilterItems(this.state.filterDataCurrentPage);
                }
              );
            }
          } else {
            if (this.state.page !== this.state.lastPage) {
              this.setState(
                {
                  page: this.state.page + 1,
                },
                () => {
                  this.fetchItems(this.state.page);
                }
              );
            }
          }
        }
      }
    );
  };

  handleFilterModelChange = (filterModel) => {
    let item_name = "";
    let item_name_operator = "";
    let item_code = "";
    let item_code_operator = "";
    let item_unit = "";
    let item_unit_operator = "";

    this.setState({
      filterModel: filterModel,
    });
    filterModel.items.forEach((filter) => {
      if (filter.value) {
        if (filter.field === "name") {
          item_name = filter.value;
          item_name_operator = filter.operator;
        } else if (filter.field === "code") {
          item_code = filter.value;
          item_code_operator = filter.operator;
        } else if (filter.field === "unit") {
          item_unit = filter.value;
          item_unit_operator = filter.operator;
        }
      }
    });

    if (item_name || item_code || item_unit) {
      this.setState(
        {
          isFilterActive: true,
          item_name,
          item_name_operator,
          item_code,
          item_code_operator,
          item_unit,
          item_unit_operator,
          filterDataCurrentPage: 1, // whenever filter changes, set page to 1
          filteredRows: [],
          filterDataLastPage: 1,
          filteredTotal: 0,
          commonRowsInFilter: 0,
          selectionModel: [],
        },
        () => {
          this.fetchFilterItems(1);
        }
      );
    } else {
      this.setState({
        isFilterActive: false,
        item_name: "",
        item_code: "",
        item_unit: "",
        country: "",
        industry_type: "",
        filterDataCurrentPage: 1,
        filteredRows: [],
        filterDataLastPage: 1,
        commonRowsInFilter: 0,
      });
    }
  };

  fetchItems = (page) => {
    let plant_code = null;
    let uniqueRows = [];
    if (this.props.prcombination) {
      const items = this.props.selectedprcombination?.items;
      const removed_items =
        this.props.selectedprcombination?.removed_items || [];
      const all_ids = [...removed_items, ...items];
      let jsonObject = all_ids.map(JSON.stringify);
      let uniqueSet = new Set(jsonObject);
      uniqueRows = Array.from(uniqueSet).map(JSON.parse) || [];
      plant_code = this.props.selectedprcombination.plant.code;
    }
    const url = getAllLinkedItems(
      this.props.selectedCategory?.id,
      page,
      uniqueRows,
      plant_code
    );
    this.setState({
      loading: true,
    });
    axios
      .get(url)
      .then((res) => {
        let finalRows;
        if (this.props.selectedItems?.length) {
          const newRows = [];
          res.data?.data?.forEach((avail) => {
            if (
              !this.props.selectedItems.some((element) => {
                return element.id === avail.id;
              })
            ) {
              //rows will contain unique items only
              newRows.push(avail);
            }
          });

          finalRows = [...this.state.rows, ...newRows];
        } else {
          finalRows = [...this.state.rows, ...res.data?.data];
        }
        let jsonObject = finalRows.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        let uniqueRows = Array.from(uniqueSet).map(JSON.parse);

        this.setState(
          {
            loading: false,
            rows: uniqueRows,
            page: res.data.meta.current_page,
            lastPage: res.data.meta.last_page,
            ...(!this.state.total && { total: res.data.meta.total }),
          },
          () => {
            if (this.state.page !== this.state.lastPage) {
              const newRows = [];
              res.data?.data?.forEach((avail) => {
                if (
                  !this.props.selectedItems.some((element) => {
                    return element.id === avail.id;
                  })
                ) {
                  //rows will contain unique items only
                  newRows.push(avail);
                }
              });
              if (this.state.rows.length < 10 || newRows.length === 0) {
                this.setState(
                  {
                    page: this.state.page + 1,
                  },
                  () => {
                    this.fetchItems(this.state.page);
                  }
                );
              }
            }
          }
        );
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
        console.log("fetch items err", err);
      });
  };

  fetchFilterItems = (page) => {
    const url = filterItems(
      this.props.selectedCategory?.id,
      this.state.item_name.trim(),
      this.state.item_name_operator,
      this.state.item_code.trim(),
      this.state.item_code_operator,
      this.state.item_unit.trim(),
      this.state.item_unit_operator,
      page
    );

    this.setState({
      loading: true,
    });

    axios
      .get(url)
      .then((res) => {
        const newRows = [];
        let count = 0;
        if (this.props.selectedItems?.length) {
          res.data?.data?.forEach((avail) => {
            if (
              !this.props.selectedItems.some((element) => {
                return element.id === avail.id;
              })
            ) {
              newRows.push(avail);
            } else {
              count += 1;
            }
          });
        } else {
          newRows.push(...res.data?.data);
        }
        if (page === 1) {
          this.setState({
            loading: false,
            filteredRows: newRows,
            filterDataCurrentPage: res.data.meta.current_page,
            filterDataLastPage: res.data.meta.last_page,
            filteredTotal: res.data.meta.total,
            commonRowsInFilter: count,
          });
        } else {
          let finalRows = [...this.state.filteredRows, ...newRows];
          let jsonObject = finalRows.map(JSON.stringify);
          let uniqueSet = new Set(jsonObject);
          let uniqueRows = Array.from(uniqueSet).map(JSON.parse);
          this.setState({
            loading: false,
            filteredRows: uniqueRows,
            filterDataCurrentPage: res.data.meta.current_page,
            filterDataLastPage: res.data.meta.last_page,
          });
        }
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
        console.log("fetch filter items err", err);
      });
  };

  getSelectableRows = (data) => {
    return data;
    // if (this.props.master_type !== "category"){
    //   return data.filter((params) => {
    //     return params.vendor_count.total_count > 0;
    //   })
    // } else {
    //   return data
    // }
  };

  isItemSelected = (row) => {
    return this.state.mainSelectionModel.some((e) => e.id === row.id);
  };

  handleItemClick = (item) => {
    const selectedIndex = this.state.mainSelectionModel
      .map((ob) => ob.id)
      .indexOf(item.id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.mainSelectionModel, item);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state.mainSelectionModel.slice(1));
    } else if (selectedIndex === this.state.mainSelectionModel.length - 1) {
      newSelected = newSelected.concat(
        this.state.mainSelectionModel.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        this.state.mainSelectionModel.slice(0, selectedIndex),
        this.state.mainSelectionModel.slice(selectedIndex + 1)
      );
    }
    this.setState({
      mainSelectionModel: newSelected,
    });
  };

  handleSelectAllClick = (event) => {
    if (this.state.showSelected) {
      this.setState({
        mainSelectionModel: [],
      });
      return;
    }
    let newSelecteds = [];
    if (this.state.isFilterActive) {
      let newRows = [
        ...this.getSelectableRows(this.state.filteredRows),
        ...this.state.mainSelectionModel,
      ];
      let finalRows = newRows;
      let jsonObject = finalRows.map(JSON.stringify);
      let uniqueSet = new Set(jsonObject);
      newSelecteds = Array.from(uniqueSet).map(JSON.parse);
    } else {
      let newRows = [
        ...this.getSelectableRows(this.state.rows),
        ...this.state.mainSelectionModel,
      ];
      let finalRows = newRows;
      let jsonObject = finalRows.map(JSON.stringify);
      let uniqueSet = new Set(jsonObject);
      newSelecteds = Array.from(uniqueSet).map(JSON.parse);
    }
    if (event.target.checked) {
      this.setState({
        mainSelectionModel: newSelecteds,
      });
      return;
    }

    let update = [];
    if (this.state.isFilterActive) {
      update = this.state.mainSelectionModel.filter(
        (el) => !this.state.filteredRows.map((ob) => ob.id).includes(el.id)
      );
    } else {
      update = this.state.mainSelectionModel.filter(
        (el) => !this.state.rows.map((ob) => ob.id).includes(el.id)
      );
    }
    this.setState({
      mainSelectionModel: update,
    });
  };

  commonItems = (arr1, arr2) => {
    return arr1.filter(function (o1) {
      return arr2.some(function (o2) {
        return o1.id === o2.id; // return the ones with equal id
      });
    });
  };
  getitemspecificfiles = (items) => {
    const url = updateFileSpecificationswithmultiplefiles(items, "items");
    axios
      .get(url)
      .then((res) => {
        this.props.setItemSpecifications(res.data.data || []);
      })
      .catch((err) => {
        console.log(err, " getitemfilespecs error");
      });
  };
  getFirstColumn = () => {
    if (this.state.showSelected) {
      return {
        field: "id",
        width: 20,
        disableColumnMenu: true,
        align: "left",
        headerAlign: "left",
        editable: false,
        sortable: false,
        filterable: false,
        renderHeader: () => (
          <Tooltip title="remove all items">
            <IconButton
              onClick={(event) => this.handleSelectAllClick(event)}
              color="error">
              <RemoveCircleIcon />
            </IconButton>
          </Tooltip>
        ),
        renderCell: (params) => {
          return (
            <Tooltip title="remove item">
              <IconButton
                onClick={() => this.handleItemClick(params.row)}
                color="error">
                <RemoveCircleIcon />
              </IconButton>
            </Tooltip>
          );
        },
      };
    } else {
      return {
        field: "id",
        width: 20,
        disableColumnMenu: true,
        align: "left",
        headerAlign: "left",
        editable: false,
        sortable: false,
        filterable: false,
        renderHeader: () => (
          <React.Fragment>
            <Checkbox
              onChange={(event) => this.handleSelectAllClick(event)}
              indeterminate={
                this.state.isFilterActive
                  ? this.commonItems(
                      this.state.mainSelectionModel,
                      this.getSelectableRows(this.state.filteredRows)
                    )?.length > 0 &&
                    this.commonItems(
                      this.state.mainSelectionModel,
                      this.getSelectableRows(this.state.filteredRows)
                    )?.length <
                      this.getSelectableRows(this.state.filteredRows).length
                  : this.commonItems(
                      this.state.mainSelectionModel,
                      this.getSelectableRows(this.state.rows)
                    )?.length > 0 &&
                    this.commonItems(
                      this.state.mainSelectionModel,
                      this.getSelectableRows(this.state.rows)
                    )?.length < this.getSelectableRows(this.state.rows).length
              }
              checked={
                this.state.isFilterActive
                  ? this.commonItems(
                      this.state.mainSelectionModel,
                      this.getSelectableRows(this.state.filteredRows)
                    )?.length > 0 &&
                    this.commonItems(
                      this.state.mainSelectionModel,
                      this.getSelectableRows(this.state.filteredRows)
                    )?.length ===
                      this.getSelectableRows(this.state.filteredRows).length
                  : this.commonItems(
                      this.state.mainSelectionModel,
                      this.getSelectableRows(this.state.rows)
                    )?.length > 0 &&
                    this.commonItems(
                      this.state.mainSelectionModel,
                      this.getSelectableRows(this.state.rows)
                    )?.length === this.getSelectableRows(this.state.rows).length
              }
            />
          </React.Fragment>
        ),
        renderCell: (params) => {
          // check here in case of itemize
          // if (this.props.master_type !== "category" && !params.row.vendor_count.total_count > 0){
          //   return "";
          // }
          return <Checkbox checked={this.isItemSelected(params.row)} />;
        },
      };
    }
  };

  render() {
    const columns = [
      this.getFirstColumn(),
      {
        field: "name",
        headerName: "Item Name",
        width: 350,
        align: "left",
        filterOperators: getFilterOperators(),
        // valueGetter: (params) => _.unescape(params.row.name),
        renderCell: (params) => {
          return (
            <Tooltip
              title={_.unescape(params.row.name)}
              disableInteractive
              placement="bottom-start">
              <Typography noWrap fontWeight={500}>
                {_.unescape(params.row.name)}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "code",
        headerName: "Item Code",
        headerAlign: "left",
        filterOperators: getFilterOperators(),
        align: "left",
        flex: 1,
        renderCell: (params) => {
          return (
            <Tooltip
              title={_.unescape(params.row.code)}
              disableInteractive
              placement="bottom-start">
              <Typography noWrap color="primary.main" fontWeight={500}>
                {_.unescape(params.row.code)}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "unit",
        headerName: "Item Unit",
        headerAlign: "left",
        filterOperators: getFilterOperators(),
        renderCell: (params) => {
          return <Typography noWrap>{_.unescape(params.row.unit)}</Typography>;
        },
        align: "left",
        flex: 1,
      },
    ];
    return (
      <React.Fragment>
        <Dialog
          scroll="body"
          open={this.props.open}
          // onClose={() => {
          //   this.props.handleClose();
          //   this.setState({
          //     rows: [],
          //   });
          // }}
          fullWidth
          maxWidth="md"
          aria-labelledby="draggable-dialog-title">
          <DialogTitle
            sx={{
              "&:focus-visible": {
                border: "none",
                outline: "none",
              },
            }}
            ref={this.descriptionElementRef}
            tabIndex={-1}>
            {this.props.prcombination
              ? "Browse Deleted Items"
              : "Browse Linked Items"}
            <Box className="dialog-title-action">
              <Tooltip
                title={
                  this.state.mainSelectionModel.length && "View Selected items"
                }>
                <IconButton
                  sx={{
                    ml: "auto",
                    color: (theme) =>
                      this.state.showSelected
                        ? theme.palette.primary.main
                        : theme.palette.grey[600],
                  }}
                  disabled={!this.state.mainSelectionModel.length}
                  onClick={() =>
                    this.setState({
                      showSelected: !this.state.showSelected,
                    })
                  }>
                  <Badge
                    badgeContent={this.state.mainSelectionModel.length}
                    color="error">
                    <ListAltOutlinedIcon />
                  </Badge>
                </IconButton>
              </Tooltip>

              {!this.props.prcombination &&
                (this.props.userInfo?.is_super_user === "Y" ||
                  this.props.masterPermission?.item_master === "Y") && (
                  <Tooltip title="Add New Item">
                    <IconButton
                      onClick={() => {
                        this.props.handleClose();
                        this.setState({
                          rows: [],
                        });
                        this.props.openAddItemDialog();
                      }}
                      sx={{ color: (theme) => theme.palette.grey[600] }}>
                      <AddCircleOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                )}
              {!this.props.prcombination &&
                (this.props.userInfo?.is_super_user === "Y" ||
                  this.props.masterPermission?.linking === "Y") && (
                  <Tooltip title="Link Items To Category">
                    <IconButton
                      onClick={() => {
                        this.props.handleOpenLinkUnlinkItem();
                        this.props.handleClose();
                        this.setState({
                          rows: [],
                        });
                      }}
                      sx={{ color: (theme) => theme.palette.grey[600] }}>
                      <InsertLinkOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                )}
              {/* {this.props.master_type !== "category" &&
                (this.props.userInfo?.is_super_user === "Y" ||
                  this.props.masterPermission.linking === "Y") && (
                  <Tooltip title="Link Unlink Vendors with Item">
                    <IconButton
                      sx={{ color: "grey.600" }}
                      onClick={() => {
                        this.props.handleClose();
                        this.setState({
                          rows: [],
                        });
                        this.props.handleItemVendorLinkOpen();
                      }}
                      size="small"
                    >
                      <LinkVendorIcon />
                    </IconButton>
                  </Tooltip>
                )} */}
              <IconButton
                onClick={() => {
                  this.props.handleClose();
                  this.setState({
                    rows: [],
                  });
                }}
                sx={{ color: (theme) => theme.palette.bluegrey[500] }}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent sx={{ padding: 0 }}>
            <div style={{ height: 400 }}>
              {this.state.showSelected ? (
                <DataGridPro
                  key={0}
                  sx={{
                    borderLeft: 0,
                    borderRight: 0,
                    borderRadius: 0,
                    "& .Mui-disabled": {
                      visibility: "hidden",
                    },
                    ".MuiDataGrid-columnHeader:first-of-type": {
                      padding: 0,
                    },
                    "& .MuiDataGrid-columnHeaderTitleContainer, .MuiDataGrid-cell:first-of-type":
                      {
                        padding: "0 6px",
                      },
                    "& .MuiDataGrid-selectedRowCount": {
                      visibility: "hidden",
                    },
                  }}
                  rowSelectionModel={this.state.mainSelectionModel.map(
                    (ob) => ob.id
                  )}
                  columns={columns}
                  rows={this.state.selectedRows}
                  slots={{
                    noRowsOverlay: () => {
                      return (
                        <GridOverlay>
                          <Typography>No item(s) found</Typography>
                        </GridOverlay>
                      );
                    },
                  }}
                  hideFooterPagination
                  rowHeight={48}
                  disableColumnSelector
                  disableColumnReorder
                  disableColumnPinning
                />
              ) : (
                <DataGridPro
                  key={1}
                  sx={{
                    borderLeft: 0,
                    borderRight: 0,
                    borderRadius: 0,
                    "& .Mui-disabled": {
                      visibility: "hidden",
                    },
                    ".MuiDataGrid-columnHeader:first-of-type": {
                      padding: 0,
                    },
                    "& .MuiDataGrid-columnHeaderTitleContainer, .MuiDataGrid-cell:first-of-type":
                      {
                        padding: "0 6px",
                      },
                    "& .MuiDataGrid-selectedRowCount": {
                      visibility: "hidden",
                    },
                  }}
                  disableColumnPinning
                  columns={columns}
                  selectedRowCount={false}
                  rows={
                    this.state.isFilterActive
                      ? this.state.filteredRows
                      : this.state.rows
                  }
                  loading={this.state.loading}
                  slots={{
                    noRowsOverlay: () => {
                      return (
                        <GridOverlay>
                          <Typography>No item(s) found</Typography>
                        </GridOverlay>
                      );
                    },
                    footer: () => {
                      return (
                        <div
                          style={{
                            textAlign: "right",
                          }}>
                          <Box
                            sx={{
                              textAlign: "right",
                              margin: "4px",
                              padding: "10px 16px",
                            }}>
                            {!this.state.isFilterActive
                              ? `Showing ${this.state.rows.length} / ${
                                  this.state.total -
                                    this.props.selectedItems.length >
                                  0
                                    ? this.state.total -
                                      this.props.selectedItems.length
                                    : 0
                                }`
                              : `Showing ${this.state.filteredRows.length} / ${
                                  this.state.filteredTotal -
                                    this.state.commonRowsInFilter >
                                  0
                                    ? this.state.filteredTotal -
                                      this.state.commonRowsInFilter
                                    : 0
                                }`}
                          </Box>
                        </div>
                      );
                    },
                  }}
                  hideFooterPagination
                  onRowsScrollEnd={this.handleScrollEnd}
                  rowHeight={48}
                  // onSelectionModelChange={(newSelectionModel) => {
                  //   this.setState({ selectionModel: newSelectionModel });
                  // }}
                  rowSelectionModel={
                    this.state.isFilterActive
                      ? this.state.mainSelectionModel
                          .filter((el) =>
                            this.state.filteredRows
                              .map((ob) => ob.id)
                              .includes(el.id)
                          )
                          .map((row) => row.id)
                      : this.state.mainSelectionModel
                          .filter((el) =>
                            this.state.rows.map((ob) => ob.id).includes(el.id)
                          )
                          .map((row) => row.id)
                  }
                  filterMode="server"
                  filterModel={this.state.filterModel}
                  onFilterModelChange={this.handleFilterModelChange}
                  // isRowSelectable={(params) => {
                  //   if (this.props.master_type !== "category"){
                  //     return params.row.vendor_count.total_count > 0;
                  //   }
                  //   return true;
                  // }}
                  onRowClick={(params) => {
                    // if (this.props.master_type !== "category"){
                    //   if (params.row.vendor_count.total_count > 0) {
                    //     this.handleItemClick(params.row)
                    //   }
                    // } else{
                    // }
                    this.handleItemClick(params.row);
                  }}
                  disableColumnSelector
                />
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => {
                this.props.handleClose();
                this.setState({
                  rows: [],
                });
              }}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                if (!this.state.mainSelectionModel.length) {
                  this.props.openSnackbar("error", "No item(s) selected");
                  return;
                }
                let newlySelectedItems = [];
                this.state.mainSelectionModel.forEach((modelSelectedItem) => {
                  if (
                    !this.props.selectedItems.some((element) => {
                      return element.id === modelSelectedItem.id;
                    })
                  ) {
                    newlySelectedItems.push(modelSelectedItem);
                  }
                });
                if (newlySelectedItems.length) {
                  if (this.props.prcombination) {
                    const removed_items =
                      this.props.selectedprcombination?.removed_items || [];
                    removed_items.map((e) => {
                      newlySelectedItems.map((rmtm) => {
                        if (e.id === rmtm.id) {
                          rmtm["prlineitems_ids"] = e.prlineitems_ids;
                        }
                      });
                    });           
                    const newlySelectedItemsids = newlySelectedItems.reduce(
                      (a, b) => {
                        a.push(b.id);
                        return a;
                      },
                      []
                    );
                    const newremoved_items = removed_items.filter(
                      (e) => newlySelectedItemsids.indexOf(e.id) === -1
                    );
                    const mainitems = [
                      ...this.props.selectedItems,
                      ...newlySelectedItems,
                    ];
                    this.props.setprcombinationItems(
                      mainitems,
                      newremoved_items
                    );
                  }
                  const items = [
                    ...this.props.selectedItems,
                    ...newlySelectedItems,
                  ];
                  if (items.length > 50) {
                    this.props.openSnackbar(
                      "error",
                      "Cart size cannot be greater than 50!"
                    );
                    return;
                  }
                  this.props.setSelectedItem(items);
                  this.getitemspecificfiles(items);
                  if (this.props?.includeTB && this.props.itemizeTB) {
                    this.props.addTBItems(newlySelectedItems);
                  }
                } else {
                  this.props.setSelectedItem([]);
                }
                this.setState({
                  mainSelectionModel: [],
                  selectionModel: [],
                  rows: [],
                });
                this.props.handleClose();
              }}
              autoFocus>
              Add to Cart
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedCategory: state.categoryReducer.selectedCategory,
  selectedItems: state.itemReducer.selectedItems,
  availableItem: state.itemReducer.availableItem,
  availableItemMeta: state.itemReducer.availableItemMeta,
  sessionId: state.sessionReducer.sessionId,
  loading: state.itemReducer.loading,
  error: state.itemReducer.error,
  filteredItems: state.itemReducer.filteredItems,
  filteredItemsLoading: state.itemReducer.filteredItemsLoading,
  filteredItemsError: state.itemReducer.filteredItemsError,
  filteredItemsMeta: state.itemReducer.filteredItemsMeta,
  masterPermission: state.permissionsReducer.masterPermission,
  userInfo: state.userInfoReducer.userInfo,
  selectedprcombination:
    state.prrfqreducer.prCombinations[state.prrfqreducer.disableIndex],
});

export default connect(mapStateToProps, {
  setSelectedItem,
  setAvailableItem,
  getVendors,
  filterAvailableItems,
  openSnackbar,
  setItemSpecifications,
  setprcombinationItems,
})(BrowseItem);
