import { SET_SESSION_ID } from '../constants/type'
const initialState = {
    sessionId : ''
}

const sessionReducer = (state = initialState, { type, payload }) => {
    switch (type) {
    case SET_SESSION_ID:
        return { ...state, sessionId: payload }
    default:
        return state
    }
}

export default sessionReducer