export const ALLOWED_COMPANY = {
  default: [4, 17],

  /* for vend-x.com urls */
  "vend-x.com":[4, 119],
  "static.vend-x.com": [4, 119],

  /* for ej-buy.com urls */
  "ej-buy.com": [],
  "static.ej-buy.com": [],

  /* for ultratechcement.com urls */
  "static-utcl.vend-x.com": [],
  "ultratechcement.vend-x.com": [],
};
