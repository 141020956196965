import { styled } from '@mui/material/styles';
import Drawer from "@mui/material/Drawer";

export const QuatationDrawerRight = styled(Drawer)( ({ theme }) => ({
    "&.SidebarDrawer":{
        ".MuiPaper-root": {
            boxShadow:"none",
            borderRadius:"0",
        },
        '& .MuiDrawer-paper': {
            width: '350px',     
            boxSizing: 'border-box',
            [theme.breakpoints.down("sm")]: {
                width: '100%',
            }
        },
        "& .Timeline-Wrap":{
            height: "calc(100vh - 60px)",
            overflow: "auto"
        },
        "& .Timeline-List":{
            paddingTop: "12px",
            paddingLeft: "22px",
            ".Timeline-Wrap":{
                height: 'calc(100vh - 60px)',
                overflowY: 'auto',
                overflowX: 'hidden'
            },
            ".MuiListItem-root":{
                minHeight: "70px",
                alignItems:"unset",
                padding:"0"
            },
            ".MuiListItemAvatar-root":{
                minWidth:"auto",
                display:"flex",
                // flex: "0",
                flexDirection: "column",
                alignItems: "center",
                ".MuiAvatar-root":{
                    height:'36px',
                    width:'36px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin:'7px 0',
                    boxShadow:"none",
                    fontSize:"14px",
                    zIndex:"11",
                }
            },
            ".TimeLineConnector":{
                width:1,
                backgroundColor: "var(--grey-400)",
                flexGrow: 1
            },
            '.MuiListItem-root:last-child .TimeLineConnector': {
                display: 'none'
            },
            ".MuiListItemText-root": {
                padding:"6px 16px 18px 16px",
                margin:"0"
            },
            ".DateTimeText":{
                fontSize:'13px',
                color:'var(--grey-500)',
                lineHeight:'normal',
                '& .MuiSvgIcon-root':{
                    fontSize:'16px',
                    float:'left',
                    marginRight:'5px',
                    marginTop:'-1px'
                }
            },
            ".ListHeader .MuiChip-root":{
                fontSize:'10px', 
                height:'18px',
                "&.MuiChip-root svg.MuiSvgIcon-root": {
                    fontSize: '5px'
                }
            },
            ".InfoStatusChip":{
                marginBottom:"4px",
                ".MuiChip-label": {
                    fontWeight:"400",
                    textDecoration:"underline"
                },
                ".MuiChip-icon":{
                    fontSize:"8px"
                }
            },            
            "& .MuiListItem-root":{
                transition: "background 0.2s, color 0.2s",
                ".MuiListItemAvatar-root:before":{
                    backgroundColor: "var(--bluegrey-500)",
                    width: "44px",
                    height: "44px",
                    content: '""',
                    position: "absolute",
                    borderRadius: "50%",
                    top: "3px",
                    left: "-4px",
                    opacity: "0",
                    transition: "transform 0.2s, opacity 0.2s",
                    transform: "scale(.8)"
                },
                ".MuiListItemAvatar-root:after":{
                    width: "42px",
                    height: "42px",
                    background: "var(--grey-0)",
                    content: '""',
                    position: "absolute",
                    top: "4px",
                    borderRadius: "50%",
                    opacity: "0",
                    transition: "transform 0.2s, opacity 0.2s",
                    transform: "scale(.8)"
                }
            },
            "& .MuiListItem-root:hover":{
                ".MuiListItemAvatar-root:before":{                   
                    opacity: "1",
                    transform: "scale(1)"
                },
                ".MuiListItemAvatar-root:after":{                    
                    opacity: "1",
                    transform: "scale(1)"
                }                
            },
            "& .selected":{
                ".InfoStatusChip .MuiChip-label:not(:nth-child(2)), .MuiTypography-caption": {
                    fontWeight:"600",
                },
                ".MuiListItemAvatar-root:before":{                    
                    opacity: "1",
                    transition: "transform 0.2s, opacity 0.2s",
                    transform: "scale(1)"
                },
                ".MuiListItemAvatar-root:after":{                    
                    opacity: "1",
                    transition: "transform 0.2s, opacity 0.2s",
                    transform: "scale(1)"
                }
            },
        },
        
    },    
})
)