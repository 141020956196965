import React, { Component } from "react";
import { styles } from "../TemporaryDrawer/style";
import {
  Grid,
  Button,
  Drawer,
  Toolbar,
  Typography,
  Checkbox,
  FormControl,
  FormControlLabel,
  ButtonGroup,
  ToggleButton,
  ToggleButtonGroup
} from "@mui/material";
import { Box } from "@mui/system/"
import { withStyles } from "@mui/styles";

import { setRFQSettings, setReminderSettings, setIncludetbvalue,setActivityTemplate } from "../../redux/actions/drawerActions";
import { setDefaultValues, setDisplayOptions } from "../../redux/actions/prrfqactions";
import { connect } from "react-redux";
//import Template from '../../config/Template'
import axios from "axios";
import {
  getAllowMultipleSolutionOptionLabel,
  getSubCategoryList,
  getTemplates,
  templateURi,
  devTemplateURi,
  getDropdownOptions
} from "../../config/apiUrl";
import moment from "moment";
import { openSnackbar } from "../../redux/actions/snackbarAction";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import RfqSettingTab from "./RfqSettingTab";
import Reminders from "./Reminders";
import _, { uniqueId } from "lodash";
import ConfirmAlert from "../Dialogs/ConfirmAlert.js";
class PrRFQSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subCategory: "",
      subCategoriesList: [],
      projects: [],
      activityTemplate: {},
      allowVendorFill:"",
      allowVendorFillUpdate:false,
      autoTriggerRFQ: false,
      allowMultipleSolution: false,
      vendorMasterPaymentTerm: false,
      vendorMasterIncoTerm: false,
      serviceRFQ: false,
      serviceRFQChanged: false,
      basicRate: false,
      landedCost: false,
      codedItemFile: "",
      loading: false,
      priorityRfq: false,
      sealedGroupRfq: false,
      validityDate: "",
      projectList: [],
      label: "",
      service_label: "",
      service_from_pr: "",
      allowMultipleSolutionOptionLabel: "",
      vendorMasterPaymentTermDefaultSet: false,
      vendorMasterIncoTermDefaultSet: false,
      multiple_solutions_selectionDefaultSet: false,
      rfqsetting: true,
      alignmentvalue: "rfqsetting",
      rfqType: "",
      reminder: false,
      rfqsettingtab: "",
      rfqremindertab: "",
      applyFlag: false,
      error: "",
      templateOptions: [],
      template: "",
      remiderDaysTB: 0,
      remiderDaysTBError: "",
      sendSMSWithHourlyReminderTB: "",
      enableHourlyReminder: false,
      startDate: "",
      intervalDays: 0,
      intervalDaysError: "",
      includeReminderInterval: false,
      sendSMS: false,
      hourlyReminderValueTB: "",
      hourlyReminderValue: "",
      enableHourlyReminderTB: false,
      sendSMSWithHourlyReminder: "",
      jsonTemplate: "",
      validTemplate: false,
      includeTB: false,
      vendorMasterIncoTermReminder: false,
      vendorMasterPaymentTermReminder: false,
      allowMultipleSolutionReminder: false,
      basicRateReminder: false,
      landedCostReminder: false,
      projectsReminder: [],
      selectAllVendors: false,
      applyToAllDrawerOpen: false,
      remainingPlantWithError: [],
      disabledtechnicalbid: false,
      validityTime: "23:30",
      validityMaxDays: 0,
      validityDateError: false,
      maxValidityDateError: false,
      includeSlab: false,
      allowed_slab: "",
      allowMultiPlant: false
    };
  }
  handleChange = (e, val = undefined) => {
    const { name, value, type, checked } = e.target;
    setTimeout(() => {
      if(name == 'activityTemplate'){
        value["update"] = 0;
        this.setState({
          activityTemplate : value
        },() => {
          // this.props.setActivityTemplate(value)
        });
      }
    },800);
    if(name == 'allowVendorFill'){
      this.setState({
        allowVendorFill : value,
        allowVendorFillUpdate:true,
      });
    }
    if(name == 'allowMultipleSolution'){
      if(value && typeof value === 'boolean'){
        this.setState({
          serviceRFQ: false,
        });
      }
      if(typeof value === 'boolean'){
        this.setState({
          allowMultipleSolution: value,
          allowed_slab : (this.props.displayOptions?.allowed_slab && value) ? 1 : 0,
          includeSlab: this.props.displayOptions?.allowed_slab ? value : false
        });
      }
    }
    if(name == 'serviceRFQ'){
      if(value){
        this.setState({
        includeSlab: false,
        allowed_slab :  0,
        allowMultipleSolution: false,
        });
      }
      if(window.location.pathname.indexOf("prcombination") == -1 || 
        (window.location.pathname.indexOf("prcombination") > -1 && this.state.service_from_pr === "1")
      ) {
        this.setState({
          serviceRFQ: value,
        });
      }
    }
    if (name == 'intervalDays' || name == "remiderDaysTB") {
      this.setState({
        // [name]: type === "checkbox" ? checked : value,
        [name]: type === "checkbox" ? checked : (value.trim() !== '') ?
          value > 0 ? value : this.state[name] :           //if value is greater then zero then set value else set value from state
          this.state[name].length - 1 == value.length ? "" : //check if in state only one element for clear last value from input
            this.state[name],                   //else set value from state and if name is not intervaldays set value
      }, () => {
        if (name === "template" && value !== "") {
          this.fetchJSONTemplate();
        }
      })
    }
    else {
      this.setState({
        [name]: type === "checkbox" ? checked : value,
      }, () => {
        if (name === "template" && value !== "") {
          this.fetchJSONTemplate();
        }
      });
    }
    if (name === "applyFlag") { }
    if (name === "includeReminderInterval") {
      this.setState({
        remiderDaysTB: 1,
        remiderDaysTBError: ""
      })
    }
    if (name === "enableHourlyReminderTB" && !checked) {
      this.setState({
        sendSMSWithHourlyReminderTB: false,
        hourlyReminderValueTB: ""
      })
    }
    if (name === "enableHourlyReminder" && !checked) {
      this.setState({
        sendSMSWithHourlyReminder: false,
        hourlyReminderValue: ""
      })
    }
    if (name === "startDate") {
      if (!value || !value?._isValid || !(value.isAfter(moment()))) {
        let intervalDaysUpdate = "";
        if (
          this.props.featurePermission?.default_Quotation_reminder_interval_days?.feature_value === "Y" 
        ) {
          let days = this.props.featurePermission?.default_Quotation_reminder_interval_days?.feature_permission_value;
          intervalDaysUpdate = { ...this.state.intervalDays };
          intervalDaysUpdate = days;
        }
        this.setState({
          sendSMS: false,
          intervalDays: intervalDaysUpdate,
          intervalDaysError: ""
        });
      }
    }
    if (
      (name === "validityDate" && value)
      && !(
        moment(value).isValid()
        && (moment(value).isSame(moment(), "day") || moment(value).isAfter(moment()))
      )
    ) {
      console.log(value, "ifff")
      this.setState({
        validityDateError: true
      })
    } else {

      let max = this.props.featurePermission?.max_rfq_validity_days?.feature_permission_value;
      let given = moment(value);
      let current = moment().startOf('day');
      let result = moment.duration(given.diff(current)).asDays();
      if (result > parseFloat(max)) {
        this.setState({
          maxValidityDateError: true,
          validityDateError: true
        })
      } else {
        this.setState({
          maxValidityDateError: false,
          validityDateError: false
        })
        if(name == "validityDate"){
          this.setState({
            validityDate: value
          });
        }else if(name == "validityTime"){
          this.setState({
            validityTime : value
          });
        }
      }
    }
    // if(name==="includeTB"){
    // this.props.setIncludetbvalue(checked);
    // }



  };


  fetchJSONTemplate = () => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;

    console.log("template == ", this.state.template)
    let id = this.state.template?.id;
    let batch_id = this.state.template?.batch_id;
    let templateURL = "";
    //console.log("this rfqsetting = ",this.props.companyInfo);
    if (process.env.NODE_ENV === "production") {
      templateURL = templateURi(this.props.userInfo?.division_id, this.props.companyInfo?.display_name, id, batch_id);
    } else {
      templateURL = devTemplateURi(this.props.userInfo?.division_id, this.props.companyInfo?.display_name, id, batch_id);
    }
    axios
      .get(templateURL)
      .then((response) => {
        let prplants = this.props.prplants
        this.check_pr_plants_availability_in_template(response, prplants);
        this.setState({
          jsonTemplate: response.data,
          jsonTemplateloading: false,
        });
      })
      .catch((err) => {
        console.log(err, "main json err");
        this.props.openSnackbar("error", err.message);

        this.setState({
          jsonTemplateloading: false,
          jsonTemplate: ""
        });
      });
  }

  handleDateChanges() {
    this.setState({
      sendSMS: false,
      intervalDays: "",
      intervalDaysError: ""
    });
  }

  handleAlignment = (
    event,
    newAlignment
  ) => {
    if (newAlignment !== null) {
      this.setState({ alignmentvalue: newAlignment });
    }
  };
  setinervalDaysError = (msg = "") => {
    this.setState({
      intervalDaysError: msg
    })
  }
  setRemiderDaysTBError = (event) => {
    if (parseInt(event.target.value) <= 0) {
      this.setState({
        remiderDaysTBError: "Interval Days cannot be negative or 0"
      })
    } else {
      this.setState({
        remiderDaysTBError: ""
      })
    }
  }
  handleTemplateChange = (value) => {
    this.setState({
      template: value
    })
  }
  fetchTempates = () => {
    this.setState({
      loading: true,
    });
    axios
      .get(getTemplates)
      .then((res) => {
        this.setState({
          templateOptions: res.data.data,
          template: "",
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
        console.log(err);
      });
  };

  componentDidUpdate(prevProps, prevState) {

  }


  componentDidMount() {
    // if (this.props.selectedCategory) {
    // this.fetchSubCategoriesOptions();
    // }
    // this.fetchTempates();

    if (this.state.applyFlag === true) {
      console.log(JSON.parse(window.localStorage.getItem("rfqsettings")))
    }
    if (this.props.opentab === "reminder") {
      this.setState({ alignmentvalue: "reminder" });
    }
    if (window.location.pathname.indexOf("prcombination") !== -1) {
      this.setState({ alignmentvalue: "reminder" });
    }
    // axios
    // .get(getAllowMultipleSolutionOptionLabel)
    // .then((response) => {
    //   this.setState({
    //     allowMultipleSolutionOptionLabel: response.data.options?.alternate_options,
    //   });
    // })
    // .catch((err) => console.log(err));
  }


  fetchSubCategoriesOptions = () => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;
    this.setState({
      loading: true,
    });
    axios
      .get(getSubCategoryList(this.props.selectedCategory.id))
      .then((res) => {
        this.setState({
          subCategoriesList: res.data.data,
          subCategory: res.data.data[0],
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
        console.log(err);
      });
  };
  UNSAFE_componentWillReceiveProps = async (nextProps) => {
    const rfqSettingValues = this.props.rfqSettingValues || {};
    const reminderDrawerValues = this.props.remiderDrawer || {};
    const merged = {
      ...rfqSettingValues,
      ...reminderDrawerValues,
      activityTemplate: {}
    };
    let activityTemplateValues = nextProps.activityTemplateValues || {};
    merged.activityTemplate = activityTemplateValues;
    if(window.location.pathname.indexOf("prcombination") > -1 && !merged.serviceRFQ && nextProps.accountModulePermission["Service PR"]
    ?.module_activated === "Y" && merged.service_from_pr === "1"){
      merged.serviceRFQ = true;
    }
    this.setState(merged);
    if (
      nextProps.accountModulePermission["Multiple Solutions"]
        ?.module_activated === "Y" || nextProps.accountModulePermission["Service PR"]
        ?.module_activated === "Y"
    ) {
      if (!this.state.label || !this.state.service_label) {
        if (this.props.displayOptions.label === "" || this.props.displayOptions.service_label === "") {
          await axios
            .get(getAllowMultipleSolutionOptionLabel)
            .then((response) => {
              this.setState({
                label: response.data.options?.alternate_options,
                service_label: response.data.options?.service_option,
                service_from_pr: response.data.options?.service_from_pr_option,
                allowed_slab: response.data?.allowed_slab,
                allowed_slab_limit: response.data?.allowed_slab_limit
              }, () => this.props.setRFQSettings(this.state));
              this.props.setDisplayOptions({
                label: response.data.options?.alternate_options,
                service_label : response.data.options?.service_option,
                service_from_pr: response.data.options?.service_from_pr_option,
                allowed_slab: response.data?.allowed_slab,
                allowed_slab_limit: response.data?.allowed_slab_limit
              })
            })
            .catch((err) => console.log(err));
        } else {
          this.setState({
            label: this.props.displayOptions.label,
            service_label: this.props.displayOptions.service_label,
            service_from_pr: this.props.displayOptions.service_from_pr,
            allowed_slab: this.props.displayOptions.allowed_slab
          }, () => this.props.setRFQSettings(this.state));
        }
      }
    }
    if (this.props?.templateOptions?.length) {
      this.setState({
        templateOptions: this.props?.templateOptions,
      })
      if (this.props.templateOptions.length === 1) {
        this.setState({
          template: this.props.templateOptions[0]
        })
      }
    }
    if (Object.keys(this.props.defaultValues).length === 0) {
      if (Object.keys(this.props.selectedPrCategory).length > 0 && _.isEqual(nextProps.setPrLineItemsData, this.props.setPrLineItemsData) === false) {
        this.setState({
          projects: []
        })
      }
      // if(this.state.isLoadedFirstTime){
      if (
        nextProps.featurePermission?.change_payment_terms?.feature_value === "Y" && !this.state.vendorMasterPaymentTermDefaultSet
      ) {
        this.setState({
          vendorMasterPaymentTerm: true,
          vendorMasterPaymentTermReminder: true,
          vendorMasterPaymentTermDefaultSet: true
        }, () => this.props.setRFQSettings(this.state));
      }

      if (nextProps.featurePermission?.change_inco_terms?.feature_value === "Y" && !this.state.vendorMasterIncoTermDefaultSet) {
        this.setState({
          vendorMasterIncoTerm: true,
          vendorMasterIncoTermReminder: true,
          vendorMasterIncoTermDefaultSet: true,
        }, () => this.props.setRFQSettings(this.state));
      }

      if (
        nextProps.featurePermission?.multiple_solutions_selection
          ?.feature_value === "Y" && !this.state.multiple_solutions_selectionDefaultSet && this.state.allowed_slab != 1 && !nextProps.rfqSettingValues.serviceRFQ
      ) {
        this.setState({
          allowMultipleSolution: true,
          allowMultipleSolutionReminder: true,
          multiple_solutions_selectionDefaultSet: true,
        }, () => this.props.setRFQSettings(this.state));
      }
      if (
        nextProps.featurePermission?.multiple_solutions_selection
          ?.feature_value === "Y" && !this.state.multiple_solutions_selectionDefaultSet && this.state.allowed_slab == 1 && !nextProps.rfqSettingValues.serviceRFQ  && !(window.location.pathname.indexOf("prcombination") > -1 && nextProps.accountModulePermission["Service PR"]
          ?.module_activated === "Y") && (window.location.pathname.indexOf("prcombination") == -1 && nextProps.accountModulePermission["Service PR"]
          ?.module_activated != "Y")
      ) {
        this.setState({
          includeSlab: true,
          multiple_solutions_selectionDefaultSet: true,
        }, () => this.props.setRFQSettings(this.state));
      }
      if (nextProps.sessionId && this.state.projectList?.length === 0) {
        //this.fetchProjectsOptions(nextProps.sessionId);
      }
      if (this.props.accountModulePermission["Not Allowed for Category Specific RFQ"]?.module_activated === "Y") {
        this.setState({
          rfqType: "item",
          isDisabled: true,
          setDeafaultRFQType: true,
        })
      } else {
        if (this.props.accountModulePermission["Itemize Vendor Specific RFQ"]?.module_activated === "Y") {
          if (this.props.featurePermission?.category_specific_rfq?.feature_value === "Y") {
            // set default value based on the feature-permission key
            this.setState({
              setDeafaultRFQType: true,
              rfqType: "category",
              isDisabled: false
            })
          } else {
            this.setState({
              setDeafaultRFQType: true,
              rfqType: "item",
              isDisabled: false
            })
          }
        } else {
          this.setState({
            setDeafaultRFQType: true,
            rfqType: "category",
            isDisabled: true
          })
        }
      }
      if (this.props.accountModulePermission["TB Template Value Integration"]?.module_activated === "Y" && this.props.selectedPrCategory?.tb_flag == 1) {
        this.setState({
          includeTB: true,
          disabledtechnicalbid: true,
        })
      } else if (window.location.pathname.indexOf("prcombination") > -1 && new URLSearchParams(window.location.search)?.get('multiplant_rfq') == 1 ) {
        this.setState({
          includeTB: false,
          disabledtechnicalbid: true,
        })
      } else {
        this.setState({
          includeTB: false,
          disabledtechnicalbid: false,
        })
      }
      if (this.props.featurePermission?.sealed_rfq?.feature_value === "Y") {
        this.setState({
          sealedGroupRfq: true
        });
      }
      if (this.props.featurePermission.restrict_users_to_deselect_linked_vendors_while_floating_rfq?.feature_value === "Y") {
        this.setState({
          selectAllVendors: true
        });
      }
      if (this.props.featurePermission?.max_rfq_validity_days?.feature_value === "Y") {
        let days = this.props.featurePermission?.max_rfq_validity_days?.feature_permission_value;
        this.setState({
          validityMaxDays: days,
        });
      }
      if(this.props.featurePermission?.default_RFQ_validity_days?.feature_value === "Y"){
        let days = this.props.featurePermission?.default_RFQ_validity_days?.feature_permission_value;
        let updateddate = moment().add(days, "days");
        this.setState({
          validityDate: updateddate._d,
        });
      }
      if(window.location.pathname.indexOf("prcombination") !== -1){
       this.setState({
        includeReminderInterval: false,
        enableHourlyReminderTB: false,
        sendSMSWithHourlyReminderTB: "",
        remiderDaysTB: 0,
        hourlyReminderValueTB: "",
       })
      }
    }
  }

  handleClose = () => {
    // this.setState(this.props.rfqSettingValues);
    this.props.handleClose();
  }
  handleApplyToAll = () => {
    const {
      remiderDaysTB,
      remiderDaysTBError,
      sendSMSWithHourlyReminderTB,
      enableHourlyReminder,
      startDate,
      intervalDays,
      intervalDaysError,
      includeReminderInterval,
      sendSMS,
      hourlyReminderValueTB,
      hourlyReminderValue,
      enableHourlyReminderTB,
      sendSMSWithHourlyReminder,
      validityDate,
      template,
      projectList,
      rfqType,
      priorityRfq,
      sealedGroupRfq,
      includeTB,
      vendorMasterIncoTermReminder,
      vendorMasterPaymentTermReminder,
      allowMultipleSolutionReminder,
      basicRateReminder,
      landedCostReminder,
      projects,
      projectsReminder,
      selectAllVendors,
      validityTime,
      serviceRFQ,
      allowVendorFill,
      allowVendorFillUpdate,
      includeSlab,
      allowMultipleSolution,
      activityTemplate
    } = this.state;
    let remiderDrawer = {
      remiderDaysTB,
      remiderDaysTBError,
      sendSMSWithHourlyReminderTB,
      enableHourlyReminder,
      startDate,
      intervalDays,
      intervalDaysError,
      includeReminderInterval,
      sendSMS,
      hourlyReminderValueTB,
      hourlyReminderValue,
      enableHourlyReminderTB,
      sendSMSWithHourlyReminder,
      vendorMasterIncoTermReminder,
      vendorMasterPaymentTermReminder,
      allowMultipleSolutionReminder,
      basicRateReminder,
      landedCostReminder,
      validityDate,
      template,
      projectList,
      rfqType,
      priorityRfq,
      sealedGroupRfq,
      includeTB,
      projectsReminder,
      selectAllVendors,
      validityTime
    }
    let rfqSettingValues = {
      serviceRFQ : serviceRFQ,
      vendorMasterIncoTerm: vendorMasterIncoTermReminder,
      vendorMasterPaymentTerm: vendorMasterPaymentTermReminder,
      allowMultipleSolution: allowMultipleSolutionReminder,
      basicRate: basicRateReminder,
      landedCost: landedCostReminder,
      sealedGroupRfq,
      projectList,
      projects: projects,
      allowVendorFill: allowVendorFill,
      allowVendorFillUpdate: true,
      validityTime: validityTime,
      allowMultipleSolution : allowMultipleSolution,
      includeSlab : includeSlab
    }
    let activityTemplateValues = this.state.activityTemplate;
    var id = uniqueId();
    let applyToAll = {
      validityDate,
      template,
      projectList,
      rfqType,
      priorityRfq,
      sealedGroupRfq,
      includeTB,
      rfqSettingValues,
      remiderDrawer,
      projectsReminder,
      selectAllVendors,
      validityTime,
      id,
      activityTemplateValues
    }
    console.log("apply to all", this.state)
    this.props.setRFQSettings(rfqSettingValues);
    this.props.setDefaultValues(applyToAll);
    this.props.setReminderSettings(remiderDrawer);
    this.props.setIncludetbvalue(includeTB)
    this.setState({
      applyToAllDrawerOpen: false
    })
    activityTemplateValues.update = 1;
    this.props.setActivityTemplate(activityTemplateValues)
    this.props.handleClose();
  }
  check_plant_in_template = (destinations, prplants, plant_code_key, plant_name_key) => {
    let plant_with_error = [];
    prplants.forEach(prplant => {
      let filtered_plant_length = 0;
      if (plant_code_key == 'code') {
        filtered_plant_length = destinations.filter(destination => {
          const plant_arr = destination[plant_code_key].split("-");
          return plant_arr[plant_arr.length - 1] == prplant ? 1 : 0
        }).length;
      }
      else {
        filtered_plant_length = destinations.filter(destination => destination[plant_code_key] == prplant).length;
      }

      if (filtered_plant_length == 0) {
        plant_with_error.push(prplant);
      }
    });
    return plant_with_error;
  }

  show_plant_missing_error = (plants_with_error, template_name) => {
    //for showing plant error from remaining rfqs
    let remainingPlant = this.props.remainingPrCombination.map((pr, index) => pr.plant.code)
    let remainingPlantWithError = plants_with_error.filter(x => remainingPlant.includes(x));
    if (remainingPlantWithError.length) {
      this.setState({
        validTemplate: false,
        jsonTemplate: null,
        jsonTemplateloading: false,
        remainingPlantWithError
      });
      this.props.openSnackbar("error", `Destination ${remainingPlantWithError} does not exists in ${template_name}`);
    } else {
      this.setState({
        validTemplate: true,
        remainingPlantWithError: []
      })
      //this.props.openSnackbar("success", "Destinations  Matched");
    }
  }

  check_and_show_plant_error = (destinations, prplants, plant_code_key, plant_name_key, template_name) => {
    let plants_with_error = this.check_plant_in_template(
      destinations, prplants, plant_code_key, plant_name_key);
    this.show_plant_missing_error(plants_with_error, template_name);
  }

  check_pr_plants_availability_in_template = (response, prplants) => {
    response.data?.COMMON.map((form_element, index) => {
      if (form_element.Identifier === "Destination" || form_element.Identifier === "DestinationForeign") {
        if (form_element.DropdownEdit === "Y") {
          const destinationDropDown = getDropdownOptions(
            "Destination",
            response.data?.PARAM[0]?.template_id
          );
          axios
            .get(destinationDropDown)
            .then((dest) => {
              this.check_and_show_plant_error(
                dest.data.data, prplants, 'dropdown_element_code', 'dropdown_element_name',
                response.data?.PARAM[0]?.template_name);
            });
        } else {
          this.check_and_show_plant_error(
            form_element.DefaultValue, prplants, 'code', 'name',
            response.data?.PARAM[0]?.template_name);
        }
      }
    });
  }

  render() {
    const { classes, open } = { ...this.props }
    const { applyFlag, alignmentvalue } = { ...this.state }
    //console.log("this state = =",this.state.applyFlag)
    // console.log("location path == ",window.location.pathname)
    return (
      <div>
        <React.Fragment>
          <Drawer
            className={classes.RFQSettingSlide}
            anchor={"right"}
            open={open}
            onClose={this.handleClose}
          >
            <Toolbar className="drawer-toolbar">
              <TuneOutlinedIcon sx={{ mr: 1, color: 'grey.900' }} />
              <Typography className="drawer-title">Settings</Typography>
            </Toolbar>
            <Grid container className="drawerinner_content" spacing={0}>
              {/* { 
            window.location.pathname.indexOf("prcombination") > -1?
            <>
            <FormControl
              component="fieldset">
                      <FormControlLabel
                        sx={{ '& .MuiTypography-root': { fontWeight: 500, } }}
                        control={<Checkbox
                          color="primary"
                          onChange={(e)=>this.handleChange(e)}
                          name="applyFlag"
                          checked={applyFlag}
                         />}
                        label="Apply to All RFQ" />
                    <Typography
                      sx={{
                        fontSize: 14,
                        color: (theme) => theme.palette.grey[500],
                      }}>This settings applied to all RfQ</Typography>
                      </FormControl>
              </>:null
            } */}
              <Box position="sticky" bottom="0" right="0" sx={{ mb: 1 }} >
                <ToggleButtonGroup
                  color="primary"
                  sx={{ width: '100%', mt: 1 }}
                  size="small"
                  onChange={this.handleAlignment}
                  value={alignmentvalue}
                  exclusive
                  aria-label="Platform"
                >    {window.location.pathname.indexOf("prcombination") > -1 &&
                  <ToggleButton sx={{ width: '50%' }} value="reminder" aria-label="left aligned">
                    GROUP SETTING
                  </ToggleButton>
                  }
                  <ToggleButton sx={{ width: '50%' }} value="rfqsetting" aria-label="left aligned">
                    RFQ SETTING
                  </ToggleButton>
                  {window.location.pathname.indexOf("prcombination") === -1 &&
                    <ToggleButton sx={{ width: '50%' }} value="reminder" aria-label="left aligned">
                      REMINDERS
                    </ToggleButton>
                  }
                </ToggleButtonGroup>
              </Box>
              {(alignmentvalue === "rfqsetting") ?
                <RfqSettingTab
                  accountModulePermission={this.props.accountModulePermission}
                  applyFlag={applyFlag} settings={this.state}
                  handleChange={this.handleChange}
                  openSnackbar={this.props.openSnackbar}
                  label={this.state.label}
                  service_label={this.state.service_label}
                  service_from_pr={this.state.service_from_pr}
                  allowed_slab={this.state.allowed_slab}
                  featurePermission={this.props.featurePermission}
                  sessionId={this.props.sessionId}
                  masterPermission={this.props.masterPermission}
                  includeTB={this.props.includeTB}
                  triggerTemplateIFrame = {this.props.triggerTemplateIFrame}
                /> :
                <Reminders
                  accountModulePermission={this.props.accountModulePermission}
                  settings={this.state}
                  templateOptions={this.state.templateOptions}
                  label={this.state.label}
                  setinervalDaysError={this.setinervalDaysError}
                  setRemiderDaysTBError={this.setRemiderDaysTBError}
                  handleDateChanges={this.handleDateChanges}
                  handleChange={this.handleChange}
                  openSnackbar={this.props.openSnackbar}
                  handleTemplateChange={this.handleTemplateChange}
                  featurePermission={this.props.featurePermission}
                  masterPermission={this.props.masterPermission}
                  sessionId={this.props.sessionId}
                  service_label={this.state.service_label}
                  service_from_pr={this.state.service_from_pr}
                  triggerTemplateIFrame = {this.props.triggerTemplateIFrame}
                />
              }
            </Grid>
            <Box className="drawr-bottom" position="sticky" bottom="0" right="0" sx={{ background: (theme) => theme.palette.grey[50] }} >
              <Button
                variant='outlined'
                // className="lightbg_btn"
                onClick={this.handleClose}
              >
                Cancel
              </Button>
              {window.location.pathname.indexOf("prcombination") > -1 ?
                alignmentvalue === "rfqsetting" ?
                  <Button
                    variant='contained'
                    onClick={() => {
                      if (this.props.featurePermission?.project_selection?.feature_value === "Y") {
                        if (!this.state.projects.length) {
                          this.props.openSnackbar("error", "Please select the Project(s)");
                          return;
                        }
                      }
                      if (this.state.startDate) {
                        if (!this.state.intervalDays) {
                          this.setinervalDaysError("Required");
                          this.props.openSnackbar("error", "Interval Days field is mandotory if Start Date is provided");
                          return;
                        }
                      }
                      if(this.state.serviceRFQ){
                        if (!this.state.activityTemplate.template_id) {
                          this.props.openSnackbar("error", `Please select the ${this.state.service_label} Template`);
                          return;
                        }
                        if (!this.state.allowVendorFill) {
                          this.props.openSnackbar("error", `Please select the Vendor to Fill ${this.state.service_label} (Partial / Complete)`);
                          return;
                        }
                      }
                      let activityTemplateValues = this.state.activityTemplate;
                      console.log("state == ", this.state)
                      window.localStorage.setItem("rfqsettings",
                        JSON.stringify(this.state))
                      this.props.setRFQSettings(this.state)
                      // this.props.setReminderSettings(reminders)
                      activityTemplateValues.update = 1;
                      this.props.setActivityTemplate(activityTemplateValues);
                      this.props.handleClose();
                    }}
                  >
                    Apply
                  </Button>
                  : <Button
                    onClick={() => {
                      if (this.state.remainingPlantWithError.length) {
                        this.props.openSnackbar("error", `Destination ${this.state.remainingPlantWithError} does not exists in ${this.state.template.name}`);
                        return;
                      }
                      if (this.props.featurePermission?.project_selection?.feature_value === "Y") {
                        if (!this.state.projectsReminder.length) {
                          this.props.openSnackbar("error", "Please select the Project(s)");
                          return;
                        }
                      }

                      if(this.state.serviceRFQ && this.state.service_from_pr === "1"){
                        if (!this.state.activityTemplate.template_id) {
                          this.props.openSnackbar("error", `Please select the ${this.state.service_label} Template`);
                          return;
                        }
                        if (!this.state.allowVendorFill) {
                          this.props.openSnackbar("error", `Please select the Vendor to Fill ${this.state.service_label} (Partial / Complete)`);
                          return;
                        }
                      }
                      if (this.state.startDate) {
                        if (!this.state.intervalDays) {
                          this.setinervalDaysError("Required");
                          this.props.openSnackbar("error", "Interval Days field is mandotory if Start Date is provided");
                          return;
                        }
                      }

                      if (this.state.maxValidityDateError) {
                        this.props.openSnackbar("error", `Maximum RFQ Validity is ${this.state.validityMaxDays} days`);
                        return;
                      }
                      if (this.state.validityDateError) {
                        this.props.openSnackbar("error", `Enter Valid RFQ Validity Date`);
                        return;
                      }
                      if (this.state.startDate) {
                        if (moment(this.state.startDate).isAfter(this.state.validityDate)) {
                          this.props.openSnackbar("error", "Reminder date can't be after the RFQ validity date!");
                          return;
                        }
                      }
                      this.setState({
                        applyToAllDrawerOpen: true
                      })
                    }}
                    variant='contained'>
                    Apply To All
                  </Button>
                :
                <Button
                  variant='contained'
                  disabled={this.state.applyFlag && !this.state.validTemplate}
                  onClick={() => {
                    if (this.props.featurePermission?.project_selection?.feature_value === "Y") {
                      if (!this.state.projects.length) {
                        this.props.openSnackbar("error", "Please select the Project(s)");
                        return;
                      }
                    }

                    if(this.state.serviceRFQ){
                      if (!this.state.activityTemplate?.template_id) {
                        this.props.openSnackbar("error", `Please select the ${this.state.service_label} Template`);
                        return;
                      }
                      if (!this.state.allowVendorFill) {
                        this.props.openSnackbar("error", `Please select the Vendor to Fill ${this.state.service_label} (Partial / Complete)`);
                        return;
                      }
                    }
                    if (this.state.startDate) {
                      if (!this.state.intervalDays) {
                        this.setinervalDaysError("Required");
                        this.props.openSnackbar("error", "Interval Days field is mandotory if Start Date is provided");
                        return;
                      }
                    }

                    let reminders = {
                      remiderDaysTB: this.state.remiderDaysTB,
                      remiderDaysTBError: this.state.remiderDaysTBError,
                      sendSMSWithHourlyReminderTB: this.state.sendSMSWithHourlyReminderTB,
                      enableHourlyReminder: this.state.enableHourlyReminder,
                      startDate: this.state.startDate,
                      intervalDays: this.state.intervalDays,
                      intervalDaysError: this.state.intervalDaysError,
                      includeReminderInterval: this.state.includeReminderInterval,
                      sendSMS: this.state.sendSMS,
                      hourlyReminderValueTB: this.state.hourlyReminderValueTB,
                      hourlyReminderValue: this.state.hourlyReminderValue,
                      enableHourlyReminderTB: this.state.enableHourlyReminderTB,
                      sendSMSWithHourlyReminder: this.state.sendSMSWithHourlyReminder,
                    }
                    let activityTemplateValues = this.state.activityTemplate;
                    console.log("state == ", this.state)
                    window.localStorage.setItem("rfqsettings",
                      JSON.stringify(this.state))
                    this.props.setRFQSettings(this.state)
                    activityTemplateValues.update = 1;
                    this.props.setActivityTemplate(activityTemplateValues);
                    this.props.setReminderSettings(reminders)
                    this.props.handleClose();
                  }}
                >
                  Apply
                </Button>
              }
              {/* <Button
                  variant='contained'
                  disabled={this.state.applyFlag && !this.state.validTemplate}
                  onClick={() => {
                    if (this.props.featurePermission?.project_selection?.feature_value === "Y"){
                      if (!this.state.projects.length){
                        this.props.openSnackbar("error", "Please select the Project(s)");
                        return;
                      }
                    }
                    if (this.state.startDate) {
                      if (!this.state.intervalDays) {
                        this.setinervalDaysError("Required");
                        this.props.openSnackbar("error", "Interval Days field is mandotory if Start Date is provided");  
                        return;
                      }
                    }

                    let reminders= {
                      remiderDaysTB:this.state.remiderDaysTB,
                      remiderDaysTBError:this.state.remiderDaysTBError,
                      sendSMSWithHourlyReminderTB:this.state.sendSMSWithHourlyReminderTB,
                      enableHourlyReminder:this.state.enableHourlyReminder,
                      startDate:this.state.startDate,
                      intervalDays:this.state.intervalDays,
                      intervalDaysError:this.state.intervalDaysError,
                      includeReminderInterval:this.state.includeReminderInterval,
                      sendSMS: this.state.sendSMS,
                      hourlyReminderValueTB:this.state.hourlyReminderValueTB,
                      hourlyReminderValue:this.state.hourlyReminderValue,
                      enableHourlyReminderTB:this.state.enableHourlyReminderTB,
                      sendSMSWithHourlyReminder:this.state.sendSMSWithHourlyReminder
                    }
                    console.log("state == ",this.state)
                    window.localStorage.setItem("rfqsettings",
                      JSON.stringify(this.state))
                    this.props.setRFQSettings(this.state)
                    this.props.setReminderSettings(reminders)
                    this.props.handleClose();
                  }}                 
                >
                  Apply
                </Button> */}
            </Box>
            <ConfirmAlert
              open={this.state.applyToAllDrawerOpen}
              handleClose={() => {
                this.setState({
                  applyToAllDrawerOpen: false
                })
              }}
              handleSubmit={() => this.handleApplyToAll()}
              msg="All unsaved RFQ will be updated with this changes."
              title="confirmation"
            />
          </Drawer>
        </React.Fragment>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedCategory: state.categoryReducer.selectedCategory,
  sessionId: state.sessionReducer.sessionId,
  accountModulePermission: state.permissionsReducer.accountModulePermission,
  masterPermission: state.permissionsReducer.masterPermission,
  userInfo: state.userInfoReducer.userInfo,
  rfqSettingValues: state.drawerValuesReducer.rfqSettingValues,
  activityTemplateValues: state.drawerValuesReducer.activityTemplateValues,
  featurePermission: state.permissionsReducer.featurePermission,
  prplants: state.prrfqreducer.plantcodes,
  companyInfo: state.companyInfoReducer.companyInfo,
  remiderDrawer: state.drawerValuesReducer.remiderDrawer,
  defaultValues: state.prrfqreducer.defaultValues,
  setPrLineItemsData: state.prrfqreducer.setPrLineItemsData,
  selectedPrCategory: state.prrfqreducer.selectedPrCategory,
  includeTB: state.drawerValuesReducer.includeTB,
  remainingPrCombination: state.prrfqreducer.remainingPrCombination,
  templateOptions: state.prrfqreducer.templateOptions,
  displayOptions: state.prrfqreducer.displayOptions
});
export default connect(mapStateToProps, {
  setRFQSettings,
  openSnackbar,
  setReminderSettings,
  setDefaultValues,
  setIncludetbvalue,
  setDisplayOptions,
  setActivityTemplate
})(withStyles(styles)(PrRFQSettings));
