import { SET_IMPORT_EXCEL_RFQ_DATA } from "../constants/type";

const initialState = {
  excelResponse: {},
};

const ImportExcelReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_IMPORT_EXCEL_RFQ_DATA:
      return { ...state, excelResponse: payload };
    default:
      return state;
  }
};

export default ImportExcelReducer;
