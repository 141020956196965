import React, { Component } from "react";
import { Alert, Badge, Button, Chip, Grid, IconButton, Stack, Tooltip ,Typography } from "@mui/material";
import { Box } from "@mui/system";
import {PRTitleHeading} from "../BreadCrumb/style";
import "../../pages/style.css";
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import { DeleteOutlined,ErrorOutline, MenuOpen,MapsHomeWorkOutlined , SourceOutlined, Error } from "@mui/icons-material";
import { connect } from "react-redux";
import PrGroupList from "./PrGroupList";
import PrRFQSettings from "./RfqSettings";
import { setDisabledPrCategory, setDisableIndex, setPrLineItemsData, setPRselectedChange } from "../../redux/actions/prrfqactions";
import ConfirmAlert from "../Dialogs/ConfirmAlert.js";
import {getPrLineItems} from "../../config/apiUrl";
import { openSnackbar } from "../../redux/actions/snackbarAction";

import axiosInstance from  "../../interceptor/axiosInstance";
import { bindActionCreators } from "redux";
import DestinationDetailsDialog from "../DestinationDetailsDialog/DestinationDialog";
class PRHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      groupListOpen: false,
      value: null,
      inputValue: "",
      open:false,
      confirmDialogOpen:false,
      confirmGroupRfqCloseDialogOpen:false,
      count : 0,
      plantData : [],
      IsDialogOpen:false,
    };
  }

  handleClose = () => {
    this.setState({
      groupListOpen: false,
      value: null,
      inputValue: "",
    });
  };
  handleChange = (event, newValue) => {
    const { options } = this.state;
    this.setState({
      options: newValue ? [newValue, ...options] : options,
      value: newValue,
    });
  };
  
  handleDelete = (category)=> {
    this.setState({confirmDialogOpen:true})
  }

  handleSelectedID =  (ID) => {
    this.props.setSelctedPrKey(ID)
    // this.setState({selctedIndex:ID})

  }

  handleClick =() =>{

    this.setState({
      groupListOpen:true
    })
    console.log("===",this.state.groupListOpen)
  }
  
  handleSettingsClick = () => {

    this.setState({
      open:true,
      groupListOpen:false
    })
  }
  handleSettingsClose = () => {

    this.setState({open:false, groupListOpen:false})
  }
  setprcombinationcount = (value) => {
    this.setState({count:value});
  }
   handleOpenDialog = (names, codes) => {
    const values1 = names;
    const plantsName = values1;
    const values2 = codes;
    const plantsCode = values2;
    const plant = plantsName.map((name, i) => {
      return { plant_name: name, plant_code: plantsCode[i] };
    });
    this.setState({
      plantData:plant,
      IsDialogOpen:true
    });
  };
  handleCloseDialog = () => {
    // setIsDialogOpen(false);
    this.setState({
      IsDialogOpen:false
    });
  };
  render() {
    let category = this.props.selectedPrCategory.category;
    return (
      <>
         { <PrGroupList
            prOpen={this.state.groupListOpen}
            sessionId={this.props.sessionID}
            prCombinations={this.props.prCombininations}
            handleClose={this.handleClose}
            openSnackbar={this.props.openSnackbar}
            prcombinationcount = {this.setprcombinationcount}
            selectedKey = {this.handleSelectedID}
            handleOpen={this.handleClick}
          /> 
    }
           <PrRFQSettings
            open={this.state.open}
            sessionId={this.props.sessionID}
            handleClose={this.handleSettingsClose}
            triggerTemplateIFrame={this.props.triggerTemplateIFrame}
            groupRFQ={(this.state.count > 1) ? true : false}
          />
          <ConfirmAlert
            open={this.state.confirmDialogOpen}
            handleClose={() => {
              this.setState({
                confirmDialogOpen: false,
              });
            }}
            handleSubmit={() => {
              this.props.disablePrCategory({[this.props.disableIndex]:true})
              this.props.disableIndex === undefined?this.props.disablePrIndex(1):this.props.disablePrIndex(this.props.disableIndex+1)
              this.setState({
                confirmDialogOpen: false,
              });
            }}
          msg="RFQ will be removed & you can not add it again"
          title="Remove RFQ for PR?"
        />
        <ConfirmAlert
            open={this.state.confirmGroupRfqCloseDialogOpen}
            handleClose={() => {
              this.setState({
                confirmGroupRfqCloseDialogOpen: false,
              });
            }}
            handleSubmit={() => {
              
              this.setState({
                confirmGroupRfqCloseDialogOpen: false,
              });
              window.parent.postMessage("refresh-pr-grid", "*");
            }}
            msg={[
              "This action will deselect all the PR line items used to create RFQ combinations.",
              "Do you still want to go back to the PR Register ?"
            ]}
            title="Confirmation"
        />
        <Box className="page-header" position="sticky" sx={{background:(theme) => theme.palette.grey[100]}}>
          <Grid container className="main_header_inner1" alignItems="center" >
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Box sx={{display:'flex', alignItems: 'center'}}
               className="search-box">
              <Tooltip title="Group List">
              <Button color='trasparent' size="medium"
               variant='icon-contained'
                onClick={()=>this.handleClick()}>
                  <Badge color="secondary" badgeContent={this.state.count}>
                  <MenuOpen/>
                  </Badge>
              </Button>
                </Tooltip>
                
                <Box sx={{ml:2,minWidth:'50%',maxWidth:'50%'}}>
                 <PRTitleHeading   
                    color="textPrimary"
                    variant="h5"
                    className="page_heading"
                  >
                 {this.props.selectedPrCategory?.category?.name}
                  </PRTitleHeading>
                  <Box sx={{ display:'flex', alignItems:'center',gap:'10px' , }}>
                  {this.props.selectedPrCategory?.category?.code && 
                    <Tooltip title={this.props.selectedPrCategory?.category?.code}>
                      <Box sx={{ display:'flex', alignItems:'center' }}>
                      <Chip size="small" color="primary" sx={{borderRadius:'4px',fontWeight:500,maxWidth:'160px'}} label={this.props.selectedPrCategory?.category?.code} noWrap/>
                      </Box>
                    </Tooltip> 
                  }
                  {Array.isArray(this.props.selectedPrCategory?.plant?.name) ?
                  <Tooltip title={this.props.selectedPrCategory?.plant?.name[0]}>
                  <Box sx={{ display:'flex', alignItems:'center', maxWidth:'300px' }}>
                      <MapsHomeWorkOutlined sx={{fontSize:'20px', mr: 1, color: 'grey.500'}}></MapsHomeWorkOutlined>
                      <Typography sx={{fontSize:'12px', color: 'grey.500'}} noWrap>
                      {this.props.selectedPrCategory?.plant?.name[0]}
                      </Typography>
                      {(this.props.selectedPrCategory?.plant?.name.length - 1) > 0 && (
                        <Typography
                          sx={{
                            color: "primary.main",
                            textDecoration: "underline",
                            cursor: "pointer",
                            padding: "0 4px",
                            fontWeight: "bold",
                          }}
                          onClick={() => this.handleOpenDialog(this.props.selectedPrCategory?.plant?.name, this.props.selectedPrCategory?.plant?.code)}
                        >
                          +{this.props.selectedPrCategory?.plant?.name.length - 1}
                        </Typography>
                      )}
                  </Box>
                </Tooltip> : 
                <Tooltip title={this.props.selectedPrCategory?.plant?.name}>
                      <Box sx={{ display:'flex', alignItems:'center', maxWidth:'300px' }}>
                          <MapsHomeWorkOutlined sx={{fontSize:'20px', mr: 1, color: 'grey.500'}}></MapsHomeWorkOutlined>
                          <Typography sx={{fontSize:'12px', color: 'grey.500'}} noWrap>
                          {this.props.selectedPrCategory?.plant?.name}
                          </Typography>
                      
                      </Box>
                    </Tooltip>
                  }
                 
                  { Array.isArray(this.props.selectedPrCategory?.plant?.code) ? 
                    <Tooltip title={this.props.selectedPrCategory?.plant?.code[0]}>
                    <Box sx={{ display:'flex', alignItems:'center', maxWidth:'300px' }}>
                    <SourceOutlined sx={{fontSize:'20px', mr: 1, color: 'grey.500'}}></SourceOutlined>
                        <Typography sx={{fontSize:'12px', color: 'grey.500'}} noWrap>
                        {this.props.selectedPrCategory?.plant?.code[0]}
                        </Typography>
                        {(this.props.selectedPrCategory?.plant?.code.length - 1) > 0 && (
                          <Typography
                            sx={{
                              color: "primary.main",
                              textDecoration: "underline",
                              cursor: "pointer",
                              padding: "0 4px",
                              fontWeight: "bold",
                            }}
                            onClick={() => this.handleOpenDialog(this.props.selectedPrCategory?.plant?.name, this.props.selectedPrCategory?.plant?.code)}
                          >
                            +{this.props.selectedPrCategory?.plant?.code.length - 1}
                          </Typography>
                        )}
                    </Box>
                  </Tooltip>
                  :
                  <Tooltip title={this.props.selectedPrCategory?.plant?.code}>
                      <Box sx={{ display:'flex', alignItems:'center', width:'50%' }}>                                            
                        <SourceOutlined sx={{fontSize:'20px', mr: 1, color: 'grey.500'}}></SourceOutlined>
                        <Typography sx={{fontSize:'12px', color: 'grey.500'}} noWrap>
                        {this.props.selectedPrCategory?.plant?.code}
                        </Typography>
                    </Box>
                  </Tooltip>
                  }
                  </Box>
                </Box>
                  
              </Box>
            </Grid>

            {<Grid item lg={6} md={6} sm={3} xs={3} className="text-right">
            <Stack direction="row" spacing={1}  justifyContent="right" alignItems="center">
             { (this.props.inValidCombinations) &&
             <Alert severity="error" 
             sx={{
               display: 'inline-flex',               
                '& .MuiAlert-message, .MuiAlert-icon': {
                  padding: 0,
                }
             }}
             >
              1 Group RFQ can only have 10 RFQ combinations
            </Alert>
             }
             <Tooltip title="Delete">
              <IconButton onClick={() => this.handleDelete()}>
                <DeleteOutlined  color='error' />
              </IconButton>
            </Tooltip>
            <Tooltip title="Settings">
              <IconButton size="medium" variant='icon-contained' 
              onClick={() => this.handleSettingsClick()}>
                <SettingsIcon />                
              </IconButton> 
              </Tooltip>                    
               <Tooltip title="Close">
               <IconButton size="medium" sx={{ml:1}}
              onClick={() => {
                if(this.props.group_no) {
                  window.parent.postMessage("refresh-pr-grid", "*");
                }
                else{
                  this.setState({
                    confirmGroupRfqCloseDialogOpen:true
                  });
                }
              }}>
                <ClearIcon />                
                </IconButton>
              </Tooltip>  
            </Stack>

            </Grid> 
            }
          </Grid>
        </Box>
        <DestinationDetailsDialog
            handleClose={this.handleCloseDialog}
            open={this.state.IsDialogOpen}
            plantData={this.state.plantData}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedPrCategory: state.prrfqreducer.selectedPrCategory,
  userInfo: state.userInfoReducer.userInfo,
  masterPermission: state.permissionsReducer.masterPermission,
  disableIndex:state.prrfqreducer.disableIndex,
  prCombinations:state.prrfqreducer.prCombinations,
  prIds:state.prrfqreducer.selectedPrItems,
  group_no:state.prrfqreducer.group_no,
  inValidCombinations:state.prrfqreducer.inValidCombinations,
});

const mapDispatchToPops = (dispatch) => ({
  disablePrCategory: (data) => {
    dispatch(setDisabledPrCategory(data));
    dispatch(setPRselectedChange());
  },
  disablePrIndex:(num) => {
    dispatch(setDisableIndex(num))
  },
  setPrLineItemsData:(PrLineItems) => {
    dispatch(setPrLineItemsData(PrLineItems));
  },
  ...bindActionCreators({openSnackbar},dispatch)
  
})

export default connect(mapStateToProps,mapDispatchToPops) (PRHeader)