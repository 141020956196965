import React, { useState, useEffect } from "react";
import _, { debounce } from "lodash";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LandedCostRFQRight } from "../LandedCostRFQDrawer/style";
import { makeStyles } from "@mui/styles";
import { getSymbol } from "../../AccountTemplate/CurrencySymbol";

const drawerWidth = 350;

const useStyles = makeStyles(() => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
}));

export default function LandedCostRFQDrawer({
  isLandedCostRFQOpened,
  isLandedCostRFQClosed,
  data,
  mainTemplateQuotation,
  selectedCurrency,
  currencyDetails,
  transactionItemSequence,
  vendorType,
  TotalCostFIeld,
  EvalResultsKeyWise,
  currentTxn,
  from,
  RateField,
  PerCost,
}) {
  const classes = useStyles();
  const [totalBasicValue, setTotalBasicValue] = useState(0);
  const [totalValue, setTotalValue] = useState(0);
  const [RateKeyWise, setRateKeyWise] = useState(0);
  const [itemNames, setItemNames] = useState({});
  const [currencyIcon, setCurrencyIcon] = useState({});
  const [
    reorderedMainTemplateQuotationItemSequence,
    setReorderedMainTemplateQuotationItemSequence,
  ] = useState({});
  // Extract item names from the API response
  useEffect(() => {
    if (data && data.items) {
      setItemNames(data.items);
    }
  }, [data]);

  // Calculate total basic value and total value
  const calculateTotals = () => {
    let basicValue = 0;
    let value = 0;
    let rateKeyWise = {};
    if (mainTemplateQuotation) {
      // Object.values(mainTemplateQuotation).forEach((item) => {
      Object.entries(mainTemplateQuotation).forEach(([key, item]) => {
        const quantity = parseFloat(item.qty);
        let rate = parseFloat(item.rate);

        if (isNaN(rate)) {
          if (RateField && from == "View") {
            rate =
              currentTxn?.template?.itemize?.[key]?.solution[0].data[0]?.[
                RateField.FieldId
              ];
          } else if (RateField && from != "View") {
            rate = EvalResultsKeyWise[key][RateField.FieldId];
          }
        }
        rateKeyWise[key] = rate;
        // Convert undefined, null, or NaN to zero
        const validQuantity = isNaN(quantity) ? 0 : quantity;
        const validRate = isNaN(rate) ? 0 : rate;

        const itemValue = validQuantity * validRate;

        let totalValue = 0;
        if (from === "View") {
          if (PerCost) {
            totalValue =
              (currentTxn?.template?.itemize?.[key]?.solution[0].data[0]?.[
                TotalCostFIeld.FieldId
              ] /
                (mainTemplateQuotation[key][PerCost.FieldId]["value"] || 0)) *
              validQuantity;
          } else {
            totalValue =
              currentTxn?.template?.itemize?.[key]?.solution[0].data[0]?.[
                TotalCostFIeld.FieldId
              ] * validQuantity;
          }
        } else {
          if (PerCost) {
            totalValue =
              (EvalResultsKeyWise[key][TotalCostFIeld.FieldId] /
                (mainTemplateQuotation[key][PerCost.FieldId]["value"] || 0)) *
              validQuantity;
          } else {
            totalValue =
              EvalResultsKeyWise[key][TotalCostFIeld.FieldId] * validQuantity;
          }
        }
        basicValue += itemValue;
        if (totalValue) {
          value += parseFloat(totalValue);
        }
      });
      const mainTemplateQuotationMap = new Map(
        Object.entries(mainTemplateQuotation).map((item) => [item[0], item[1]])
      );
      let reorderedItemSequence = transactionItemSequence
        .filter((key) => mainTemplateQuotationMap.has(key)) // Filter keys that are present in map
        .map((key) => key);
      setReorderedMainTemplateQuotationItemSequence(reorderedItemSequence);
    }
    setTotalBasicValue(basicValue.toFixed(3));
    setTotalValue(value.toFixed(3));
    setRateKeyWise(rateKeyWise);
  };

  useEffect(() => {
    return () => {
      const resizeObserver = document.querySelector(".ResizeObserver");
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  }, []);

  // Call calculateTotals whenever the drawer is opened or the data changes
  useEffect(() => {
    if (isLandedCostRFQOpened.right) {
      calculateTotals();
    }
  }, [isLandedCostRFQOpened, data, mainTemplateQuotation]);
  useEffect(() => {
    setCurrencyIcon(
      // getSymbol(selectedCurrency ? selectedCurrency : currencyDetails)
      getSymbol(selectedCurrency)
    );
  }, [selectedCurrency, currencyDetails]);

  return (
    <LandedCostRFQRight
      anchor="right"
      open={isLandedCostRFQOpened.right}
      className="LandedCostRFQ-Sidebar"
      // sx={{zIndex:'1301'}}
    >
      <Card variant="outlined">
        <CardHeader
          title={
            <Typography
              variant="h6"
              fontSize="18px"
              fontWeight={500}
              color={"secondary.main"}
            >
              Total Cost Details
            </Typography>
          }
          action={
            <Tooltip title="Close">
              <IconButton
                onClick={isLandedCostRFQClosed}
                aria-label="close"
                size="small"
                sx={{ color: "var(--grey-500)" }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          }
          sx={{
            "& .MuiCardHeader-action": {
              alignSelf: "center",
            },
            "& .MuiCardHeader-avatar": {
              mr: 1,
            },
          }}
        />
        <Divider />
        <CardContent sx={{ p: 0 }}>
          <Box
            sx={{
              height: "calc(100vh - 62px)",
              width: "100%",
              overflowX: "auto",
              overflowY: "auto",
            }}
          >
            <Table
              sx={{
                "& .MuiTableCell-root ": {
                  padding: "10px",
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell width={220}>Item Name</TableCell>
                  <TableCell align="right" width={140}>
                    Quantity
                  </TableCell>
                  <TableCell align="right" width={140}>
                    Rate {currencyIcon && `(${currencyIcon})`}
                  </TableCell>
                  <TableCell align="right" width={200}>
                    Value
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {Object.entries(mainTemplateQuotation || {}).map( */}
                {Object.entries(
                  reorderedMainTemplateQuotationItemSequence || {}
                ).map(([item, itemId]) => {
                  const itemName = data[itemId]?.name || "Unknown";
                  if (data[itemId]) {
                    const rate = parseFloat(RateKeyWise[itemId]);
                    const qty = parseFloat(mainTemplateQuotation[itemId]?.qty);
                    // Convert undefined, null, or NaN to zero
                    const validRate = isNaN(rate) ? 0 : rate;
                    const validQty = isNaN(qty) ? 0 : qty;

                    const subtotal = validRate * validQty;
                    return (
                      <TableRow key={itemId}>
                        <TableCell width={220}>
                          <Tooltip
                            title={_.unescape(itemName)}
                            placement="bottom"
                          >
                            <Typography
                              variant="body2"
                              sx={{ wordBreak: "break-word" }}
                            >
                              {_.unescape(itemName)}
                            </Typography>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="right" width={140}>
                          <Typography variant="body2" noWrap>
                            {parseFloat(validQty).toLocaleString("en-IN", {
                              minimumFractionDigits: 3,
                              maximumFractionDigits: 3,
                            })}
                          </Typography>
                        </TableCell>
                        <TableCell align="right" width={140}>
                          <Typography variant="body2" noWrap>
                            {/* {currencyIcon} {validRate.toFixed(3)} */}
                            {currencyIcon}{" "}
                            {parseFloat(validRate).toLocaleString("en-IN", {
                              minimumFractionDigits: 3,
                              maximumFractionDigits: 3,
                            })}
                          </Typography>
                        </TableCell>
                        <TableCell align="right" width={200}>
                          <Typography variant="body2" noWrap>
                            {currencyIcon}{" "}
                            {parseFloat(subtotal).toLocaleString("en-IN", {
                              minimumFractionDigits: 3,
                              maximumFractionDigits: 3,
                            })}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  }
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell align="right" colSpan={3}>
                    <Typography
                      variant="body2"
                      fontWeight={500}
                      color="grey.500"
                    >
                      {vendorType === "Local"
                        ? "Total Basic Value"
                        : "Total Value"}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      variant="body2"
                      fontWeight={500}
                      color="text.primary"
                      noWrap
                    >
                      {currencyIcon}{" "}
                      {parseFloat(totalBasicValue).toLocaleString("en-IN", {
                        minimumFractionDigits: 3,
                        maximumFractionDigits: 3,
                      })}
                    </Typography>
                  </TableCell>
                </TableRow>

                {vendorType === "Local" && (
                  <TableRow>
                    <TableCell align="right" colSpan={3}>
                      <Typography
                        variant="body2"
                        fontWeight={500}
                        color="grey.500"
                        noWrap
                      >
                        Total Value
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        variant="body2"
                        fontWeight={500}
                        color="text.primary"
                        noWrap
                      >
                        {currencyIcon}{" "}
                        {parseFloat(totalValue).toLocaleString("en-IN", {
                          minimumFractionDigits: 3,
                          maximumFractionDigits: 3,
                        })}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableFooter>
            </Table>
          </Box>
        </CardContent>
      </Card>
    </LandedCostRFQRight>
  );
}
