import {
  Button,
  DialogActions,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { Component } from "react";
import { DialogContent, Dialog } from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { connect } from "react-redux";
import { setSelectedVendors } from "../../../redux/actions/vendorActions";
import { setPrRfqs } from "../../../redux/actions/prrfqactions";
import { resetCAT } from "../../../redux/resetApp";
import successimage from "../../../assets/vendxAdminPanelAsstes/assetsnew/rfq_submit.svg";

class RFQSubmit extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { open, handleClose } = this.props;
    let combinedPlant = [];
    if (
      new URLSearchParams(this.props.location.search)?.get("multiplant_rfq") ==
        1 &&
      this.props.location?.pathname.indexOf("/prcombination") > -1
    ) {
      combinedPlant = this.props.plantNames.split('###');
      
    }
    return (
      <Dialog
        scroll="body"
        maxWidth={"sm"}
        open={open}
        // onClose={() => {
        //   // this.props.resetApp();
        //   this.props.resetCAT();
        //   handleClose();
        // }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <IconButton
            sx={{ color: (theme) => theme.palette.bluegrey[500], ml: "auto" }}
            onClick={() => {
              // this.props.resetApp();
              // this.props.resetCAT();
              this.props.handleClose();
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div className="linkcategorydialog_main">
              <Grid container>
                <Grid
                  item
                  lg={12}
                  xl={12}
                  md={12}
                  sm={12}
                  className="text-center"
                >
                  <img src={successimage} alt="Category Link Icon" />
                  {window.location.pathname.indexOf("/prcombination") === -1 ? (
                    <>
                      <h3 className="success_title">Successfully</h3>
                      <p className="success_subtitle">
                        Your transaction successfully Floated
                      </p>
                      <p className="success_subtitle">
                        RFQ No {this.props.rfq_no}
                      </p>
                    </>
                  ) : (
                    <>
                      <h3 className="success_title">Successfully</h3>
                      
                      <p className="success_subtitle">
                        Your transaction successfully floated,
                        {/* RFQ for {this.props.selectedPrCategory.category.name}(
                      {combinedPlant.join(",")}) is saved */}
                      </p>
                      {combinedPlant.length > 0 ? (
                        <p>
                          with{" "}
                          {combinedPlant.length == 1
                            ? combinedPlant[0]
                            : `multiple destinations (${combinedPlant.length})`}{" "}
                          for
                        </p>
                      ) : (
                        <p>
                          with {this.props.selectedPrCategory.plant.name}-
                          {this.props.selectedPrCategory.plant.code}
                          for
                        </p>
                      )}
                      <p>
                        Category -{" "}
                        <Typography color="primary" component="span">
                          {this.props.selectedPrCategory.category.name}
                        </Typography>
                      </p>
                    </>
                  )}
                </Grid>
              </Grid>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            variant="contained"
            color="primary"
            // autoFocus
            onClick={(event) => {
              // this.props.resetCAT();
              handleClose();
              event.preventDefault();
            }}
          >
            Ok
          </Button>
          {/* <Button variant="contained" color="primary" 
              autoFocus
              onClick={() => {
                // this.props.resetApp();
                var rfq_arr = this.props.rfq_no.split("-");
                
                this.generate_qcs_plus(
                  rfq_arr.pop(),
                  this.props.userInfo?.division_id
                );
                handleClose();
              }}
            >Q+</Button> */}
        </DialogActions>
      </Dialog>
    );
  }
}
const mapStateToProps = (state) => ({
  selectedVendors: state.vendorReducer.selectedVendors || [],
  userInfo: state.userInfoReducer.userInfo,
});
export default connect(mapStateToProps, {
  setSelectedVendors,
  resetCAT,
  setPrRfqs,
})(RFQSubmit);
