import React, { Fragment, useState } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Avatar,
  Link,
  Menu,
  MenuItem,
  Divider,
  ListItemIcon,
  ListItemText,
  Typography,
  ListItemAvatar,
  Tooltip,
  ListItem,
  Popover,
  MenuList,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
//CSS
import "./VendorHeader.css";

import logo from "../../../assets/images/vendx-logo.png";
import companylogo from "../../../assets/images/mavevista-logo-new.png";

//ICONS
import {
  AppsRounded,
  NotificationsNoneRounded,
  InsightsRounded,
} from "@mui/icons-material";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";

import MenuIcon from "@mui/icons-material/Menu";
import { useEffect } from "react";
import ProfileIcon from "@mui/icons-material/AccountCircleOutlined";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import InsightsIcon from "@mui/icons-material/InsightsOutlined";
// import {ContractIcon} from '../../component/SvgIcon/SvgIcon';
import LogoutIcon from "@mui/icons-material/LogoutOutlined";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { IconMapping } from "../../../config/IconConfig";
import { menu } from "../../../components/appmenuVendor/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { HOSTMAP, Logout } from "../../../config/apiUrl";

export default function AppBarHeader(props) {
  const userInfo = useSelector((state) => state.userInfoReducer.userInfo);
  const headerMenus = useSelector(
    (state) => state.vendorReducer.vendorMenu.headerMenu
  );
  const HeaderMenuWithPermission =
    headerMenus?.map((item) => ({
      ...item,
      title: item.display_name == 'Switch Account' ? 'Home' : item.display_name,
      icon: IconMapping?.[item?.display_name],
      to: item.link_detail.match(/href="(.*?)"/)?.[1] || "",
      // Assuming the "to" value is within the href attribute and extracting it
      // Adjust the regular expression according to your data structure
      // ...
      // You can add more key-renaming pairs if needed
    })) || [];
  const sortedHeaderMenu = HeaderMenuWithPermission.slice().sort((a, b) => {
    // Assuming 'Home' is the display name for the home menu item
    if (a.title === 'Home') {
      return -1; // 'Home' comes first
    } else if (b.title === 'Home') {
      return 1; // 'Home' comes first
    } else {
      return 0; // Keep the order unchanged for other items
    }
  });
  function stringAvatar(name = "") {
    const value = name.split(" ");
    return name ? `${value[0][0]}${value[1] ? value?.[1][0] : ""}` : "";
  }
  const handleLogout = () => {
    axios.post(Logout, null, {
      headers: {
        "Authorization": `Bearer ${props?.session_id}`
      }
    }).then((resp) => {
      localStorage.removeItem("vendorListSession");
      window.parent.location.href = HOSTMAP
    }).catch((error) => {
      console.log(error)
    });
  };

  const dispatch = useDispatch()
  const body = document.body;

  // profile menu
  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const [IframeVisible, setIframeVisible] = React.useState(false);
  const [IframeContent, setIframeContent] = React.useState({});
  const open = Boolean(menuAnchor);
  const handleClick = (event) => {
    setMenuAnchor(event.currentTarget);
  };
  const handleClose = () => {
    setMenuAnchor(null);
  };
  const handleMenuClick = (menu) => {
    if (menu.display_name == "Settings") {
      setIframeVisible(true);
      setIframeContent(menu);
    } else {
      window.location.href = `${menu.to}`;
    }
  };

  const handleCloseModal = () => {
    setIframeVisible(false);
    setIframeContent({});
  };

  // app menu
  const [appAnchor, setAppAnchor] = React.useState(null);

  const appHandleClick = (event) => {
    setAppAnchor(event.currentTarget);
  };

  const appHandleClose = () => {
    setAppAnchor(null);
  };

  const appOpen = Boolean(appAnchor);

  return (
    <Fragment>
      <div>
        <Dialog
          open={IframeVisible}
          onClose={handleCloseModal}
          fullScreen
          tabIndex={0}
          sx={{ m: 4 }}
        >
          <DialogTitle sx={{ m: 0, padding: "16px !important" }}>
            {IframeContent.title}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{ position: "absolute", right: 8, top: 13 }}
            size="small"
          >
            {/* You can customize the close button, for example using CloseIcon */}
            <CloseIcon />
          </IconButton>
          <DialogContent
            dividers
            sx={{
              "&.MuiDialogContent-root > iframe": {
                height: "calc(100% - 10px)",
                border: "0"
              }
            }}
          >
            {/* Iframe content */}
            <iframe
              title="Your Iframe Title"
              src={`${IframeContent.to}&q=${props?.session_id}`}
              width="100%"
              height="400px"
              frameBorder="1"
              allowFullScreen
            ></iframe>
          </DialogContent>
        </Dialog>
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed" className="app-header">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{
                mr: 0,
                width: 50,
                height: 50,
                color: (theme) => theme.palette.grey[800],
              }}
              onClick={() => {
                if (body.classList.contains("open")) {
                  body.classList.remove("open");
                }
                else {
                  body.classList.add("open");
                }
              }
              }

            // onClick={() => {
            //   props.openchange(true);
            // }}
            >
              <MenuIcon />
            </IconButton>

            <div className="applogo">
              <img src={logo} alt="Vendx Logo" />
            </div>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: 1,
              }}
            >
              <DashboardOutlinedIcon
                sx={{ color: (theme) => theme.palette.grey[500], mr: 1 }}
              />
              <Typography
                color="grey.500"
                fontSize={"14px"}
                textTransform={"uppercase"}
                fontWeight={500}
              >
                E-SOURCING
              </Typography>
            </Box>

            <Box sx={{ flexGrow: 1 }} />
            <Box
              sx={{
                display: { xs: "none", md: "flex", alignSelf: "flex-center" },
              }}
            >
              {/* <IconButton
                  size="large"
                  edge="start"                  
                  sx={{ mr: 1 }}
                >
                  <InsightsRounded />
                </IconButton> */}

              {/* <IconButton
                  size="large"
                  edge="start"
                  sx={{ mr: 1 }}
                >
                  <NotificationsNoneRounded />
                </IconButton> */}

              {/* <IconButton
                  size="large"
                  edge="start"
                  color="secondary"
                  sx={{ mr: 1 }}
                  onClick={appHandleClick}
                >
                  <AppsRounded />
                </IconButton> */}
              <Popover
                className="AppMenu-List"
                open={appOpen}
                anchorEl={appAnchor}
                onClose={appHandleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                sx={{}}
              >
                <Typography
                  variant="subtitle1"
                  fontWeight={600}
                  color="var(--grey-900)"
                  sx={{ padding: "10px 16px" }}
                >
                  Services
                </Typography>
                <Divider />
                <MenuList>
                  <MenuItem>
                    <ListItemIcon sx={{ mr: 1 }}>
                      <AccountBalanceIcon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          variant="subtitle1"
                          fontWeight={600}
                          color="var(--grey-900)"
                          sx={{ lineHeight: "normal" }}
                        >
                          Auction
                        </Typography>
                      }
                      secondary="Best strategies for superior price discovery"
                    />
                  </MenuItem>
                  <MenuItem>
                    <ListItemIcon sx={{ mr: 1 }}>
                      <AccountBalanceIcon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          variant="subtitle1"
                          fontWeight={600}
                          color="var(--grey-900)"
                        >
                          OTG
                        </Typography>
                      }
                      secondary="Best strategies for superior price discovery"
                    />
                  </MenuItem>
                  <MenuItem>
                    <ListItemIcon sx={{ mr: 1 }}>
                      <AccountBalanceIcon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          variant="subtitle1"
                          fontWeight={600}
                          color="var(--grey-900)"
                        >
                          Quick DA
                        </Typography>
                      }
                      secondary="Best strategies for superior price discovery"
                    />
                  </MenuItem>
                  <MenuItem>
                    <ListItemIcon sx={{ mr: 1 }}>
                      <AccountBalanceIcon fontSize="medium" />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          variant="subtitle1"
                          fontWeight={600}
                          color="var(--grey-900)"
                        >
                          ERP
                        </Typography>
                      }
                      secondary="Best strategies for superior price discovery"
                    />
                  </MenuItem>
                </MenuList>
              </Popover>
            </Box>
            <Box className="use-profile">
              <Link
                // href="#"                
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                sx={{ textDecoration: "none", cursor: "pointer" }}
              >
                <div className="company-logo">
                  <img src={userInfo?.company_logo} alt="company-logo" width={70} height={38} />
                </div>
                <Avatar
                  sx={{ fontSize: 14, fontWeight: 500, width: 30, height: 30 }}
                >
                  {stringAvatar(userInfo?.UserName)}
                </Avatar>
              </Link>
            </Box>          

            <Menu
              id="basic-menu"
              sx={{width:'300px'}}
              anchorEl={menuAnchor}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              className="ProfileMenu"
            >
              <ListItem className="profileName" sx={{ outline: "none" }}>
                <ListItemAvatar>
                  <Avatar
                    alt={userInfo?.UserName}
                    sx={{
                      width: "40px",
                      height: "40px",
                      bgcolor: "var(--color-primary)",
                    }}
                  >
                    {stringAvatar(userInfo?.UserName)}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText>
                  <Tooltip title={userInfo?.UserName} placement="bottom" arrow>
                    <Typography
                      variant="subtitle2"
                      sx={{ lineHeight: "normal" }}
                      noWrap
                    >
                      {userInfo?.UserName}
                    </Typography>
                  </Tooltip>
                  <Tooltip
                    title={userInfo?.UserEmaiIID}
                    placement="bottom"
                    arrow
                  >
                    <Typography variant="caption" display="block" noWrap>
                      {userInfo?.UserEmaiIID}
                    </Typography>
                  </Tooltip>
                </ListItemText>
              </ListItem>
              {sortedHeaderMenu?.map((menu) => {
                return (
                  <MenuItem onClick={() => handleMenuClick(menu)}>
                    <ListItemIcon>
                      {/* <SettingsIcon fontSize="small" /> */}
                      {menu.icon}
                    </ListItemIcon>
                    <ListItemText>
                      <Typography variant="subtitle2">{menu.title}</Typography>
                    </ListItemText>
                  </MenuItem>
                );
              })}
              {/* <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <InsightsIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant="subtitle2">
                      Action Activity
                    </Typography>
                  </ListItemText>                  
                </MenuItem> */}
              <Divider />
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <LogoutIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="subtitle2">Sign Out</Typography>
                </ListItemText>
              </MenuItem>
            </Menu>

            {/* Start User Profile Future Code  */}
            {/* <Link
                // href="#"
                className="use-profile"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{textDecoration:'none', cursor: "pointer"}}
              >
                <div className="company-logo">
                  <img src={companylogo} alt="company-logo" />
                </div>
                <Avatar
                  sx={{ fontSize: 14, fontWeight: 500, width: 30, height: 30 }}
                >
                  {stringAvatar(userInfo?.UserName)}
                </Avatar>
              </Link>              
              <Menu
                id="basic-menu"
                anchorEl={menuAnchor}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                className="ProfileMenu"
              >
                <ListItem className="profileName">
                    <ListItemAvatar>
                      <Avatar alt={UserData?.UserName} sx={{width:'40px', height:'40px', bgcolor: 'var(--color-primary)' }}>{stringAvatar(UserData?.UserName)}</Avatar>
                    </ListItemAvatar>
                    <ListItemText>
                        <Tooltip title={UserData?.UserName} placement="bottom" arrow>
                          <Typography variant="subtitle2" sx={{lineHeight:'normal'}} noWrap>{UserData?.UserName}</Typography>
                        </Tooltip>
                        <Tooltip title={UserData?.UserEmaiIID} placement="bottom" arrow>
                          <Typography variant="caption" display="block" noWrap>{UserData?.UserEmaiIID}</Typography>
                        </Tooltip>
                    </ListItemText>
                </ListItem>
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <ProfileIcon fontSize="small" sx={{fontSize:'16px'}}  />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant="subtitle2">View Profile</Typography>
                  </ListItemText>                  
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <SettingsIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant="subtitle2">Account Setting</Typography>
                  </ListItemText>                  
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <InsightsIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant="subtitle2">
                      Action Activity
                    </Typography>
                  </ListItemText>                  
                </MenuItem>
                <Divider/>
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <LogoutIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant="subtitle2">
                      Sign Out
                    </Typography>
                  </ListItemText>                  
                </MenuItem>                              
              </Menu> */}
            {/* End User Profile Future Code  */}
          </Toolbar>
        </AppBar>
      </Box>
    </Fragment>
  );
}
