import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  FilledInput,
  FormControl,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import noSearchimg from "../../../assets/images/no-search.svg";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch, useSelector } from "react-redux";
import { SelctedUserId } from "../../../redux/actions/LinkUnlinkUser";

const useStyles = makeStyles((theme) => ({
  // SearchInput: {
  //   "& .css-1vbc0rj-MuiInputBase-input-MuiFilledInput-input": {
  //     padding: 0,
  //   },
  // },
}));

const UnlinkUserLists = ({ remainingUserData, onUserSelect, checkedUserIds }) => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const classes = useStyles();
  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };
  const selectedUserIds = useSelector(
    (state) => state.LinkUnlinkUserReducer.selectedUserIds
  );
  // dispatch(LinkunlinkUser(updatedFilteredData));


  const handleUserSelect = (userId) => {
    // Toggle the selection of the user
    if (selectedUserIds.includes(userId)) {
      const filteredData =  selectedUserIds.filter((id) => id !== userId)
      dispatch(SelctedUserId(filteredData));

     
    } else {
      dispatch(SelctedUserId([...selectedUserIds,userId]));
    }
  };

  const handleCloseSearch = () => {
    setSearchText("");
  };

  const filteredUserData = remainingUserData?.filter((user) => {
    return (
      user?.UserName?.toLowerCase()?.includes(searchText.toLowerCase()) ||
      user?.Email?.toLowerCase()?.includes(searchText.toLowerCase())
    );
  });
  const sortedUserData = [...filteredUserData].sort((a, b) => a.UserName.localeCompare(b.UserName));


  // Pass selectedUserIds to the parent component when they change
  useEffect(() => {
    onUserSelect(selectedUserIds);
  }, [selectedUserIds]);
  return (
    <Box>
      <Card className="LinkUnlinkCard" variant="outlined">
        <CardActions sx={{ display: { xs: "none", sm: "none", md: "flex" } }}>
          <Typography variant="h6" component="div" className="LinkCardTitle">
            USERS
          </Typography>
        </CardActions>
        <CardContent>
          <FormControl sx={{ width: "100%", p: 0 }}>
            <FilledInput
              className={classes.SearchInput}
              value={searchText}
              placeholder="Search"
              startAdornment={
                <InputAdornment position="start">
                  <SearchOutlinedIcon />
                </InputAdornment>
              }
              endAdornment={
                searchText !== "" && (
                  <IconButton
                    className="search-clear-btn"
                    size="small"
                    onClick={handleCloseSearch}
                  >
                    <ClearIcon fontSize="inherit" />
                  </IconButton>
                )
              }
              onChange={handleSearchChange}
            />
          </FormControl>
          <Box className="link-items-list">
            {sortedUserData.length === 0 ? (
              <Box className="no-search">
                <img src={noSearchimg} />
                <Typography component="p">
                  {searchText !== "" ? (
                    <>
                      There are no user like <b>{searchText}</b>
                    </>
                  ) : (
                    "There are no RFQ No"
                  )}
                </Typography>
              </Box>
            ) : (
              <List>
                {sortedUserData.map((user) => (
                  <ListItem
                    key={user.UserID}
                    alignItems="flex-start"
                    secondaryAction={
                      <Checkbox
                        edge="end"
                        checked={selectedUserIds.includes(user.UserID)}
                        onChange={() => handleUserSelect(user.UserID)}
                      />
                    }
                  >
                    <ListItemAvatar>
                      <Avatar
                        alt={user.UserName}
                        src="/static/images/avatar/1.jpg"
                        sx={{ background: "var(--color-primary)" }}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={user.UserName}
                      secondary={user.Email}
                    />
                  </ListItem>
                ))}
              </List>
            )}
          </Box>
        </CardContent>
      </Card>
      {/* Pass selectedUserIds to the parent component when needed */}
      {/* Example: <button onClick={() => onUserSelect(selectedUserIds)}>Select Users</button> */}
    </Box>
  );
};

export default UnlinkUserLists;
