import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { ViewDetailsRight } from "../ViewDetailsDrawer/style";
import CloseIcon from "@mui/icons-material/Close";
import UserIcon from "@mui/icons-material/AccountCircleOutlined";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { makeStyles } from "@mui/styles";
import axios from "axios";

const useStyles = makeStyles(() => ({
  MailIdMenu: {
    "& .MuiList-root .MuiButtonBase-root": {
      fontSize: "14px",
      color: "var(--grey-900)",
      paddingLeft: "12px",
      paddingRight: "12px",
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Box>
  );
}

export default function ViewDetailsDrawer({
  isViewDetailsDrawerOpened,
  isViewDetailsDrawerClosed,
  vendorDetails,
  buyerInfo,
}) {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const [vendorInfo, setVendorsInfo] = useState([]);
  const [anchorElMailOption, setAnchorElMailOption] = useState(null);

  const {name, code, address, designation, email, Number, industry_details, contact_person_details} = vendorDetails;

  const open = Boolean(anchorElMailOption);
  const id = open ? "simple-popover" : undefined;

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleClickMailIdOption = (event) => {
    setAnchorElMailOption(event.currentTarget);
  };

  const handleCloseMailOption = () => {
    setAnchorElMailOption(null);
  };

  return (
    <ViewDetailsRight
      anchor="right"
      open={isViewDetailsDrawerOpened.right}
      className="ViewDetails-Sidebar"
      // sx={{zIndex:'1301'}}
    >
      <Card>
        <CardHeader
          avatar={<UserIcon />}
          title={
            <Typography
              variant="h6"
              fontSize="18px"
              fontWeight={400}
              color={"secondary.main"}
            >
              Details
            </Typography>
          }
          action={
            <Tooltip title="Close">
              <IconButton
                onClick={isViewDetailsDrawerClosed}
                aria-label="close"
                size="small"
                sx={{ color: "var(--grey-500)" }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          }
          sx={{
            "& .MuiCardHeader-action": {
              alignSelf: "center",
            },
            "& .MuiCardHeader-avatar": {
              mr: 1,
            },
          }}
        />
        <Divider />
        <CardContent>
          <Box className="ViewDetails-Tabs">
            <Tabs
              value={tabValue}
              onChange={handleChangeTab}
              aria-label="basic tabs example"
            >
              <Tab label="Vendor Info" />
              <Tab label="Buyer Info" />
            </Tabs>
            <TabPanel value={tabValue} index={0} className="tabs-panel">
              <Box className="VendorInfo-tabs">
                <Typography variant="subtitle2" sx={{ pt: 2, pb: 2 }}>
                  General Details
                </Typography>

                <Grid container spacing={2}>
                  <Grid xs={12}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Avatar
                        sx={{
                          bgcolor: "primary.main",
                          fontSize: "12px",
                          fontWeight: "500",
                          width: 32,
                          height: 32,
                        }}
                      >
                        {name?.toUpperCase().slice(0, 2) ? name?.toUpperCase().slice(0, 2) : "N/A"}
                      </Avatar>
                      <Box>
                        <Typography
                          variant="subtitle2"
                          component="div"
                          color="secondary.600"
                        >
                          {name ? name : "N/A"}
                        </Typography>
                        <Typography variant="body2" color="grey.500">
                          {code ? code : "N/A"}
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid xs={7}>
                    <Typography variant="body2" color="secondary.600">
                      Mail I’D
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="body2" color="primary.main" noWrap>
                        {email? email : "N/A"}
                      </Typography>
                      <IconButton
                        aria-label="more"
                        size="small"
                        id="long-button"
                        aria-controls={open ? "long-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleClickMailIdOption}
                        sx={{ p: 0, ml: 1 }}
                        color="primary"
                      >
                        <ArrowDropDownIcon />
                      </IconButton>
                    </Box>
                    <Menu
                      id={id}
                      open={open}
                      anchorEl={anchorElMailOption}
                      onClose={handleCloseMailOption}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      className={classes.MailIdMenu} 
                    >
                      <MenuItem>{email}</MenuItem>
                    </Menu>
                  </Grid>
                  <Grid xs={5}>
                    <Typography variant="body2" color="secondary.600">
                      Phone No
                    </Typography>
                    <Typography variant="body2" color="secondary.900">
                      {industry_details?.Number ? industry_details?.Number : "N/A"}
                    </Typography>
                  </Grid>
                  <Grid xs={7}>
                    <Typography variant="body2" color="secondary.600">
                      Designation
                    </Typography>
                    <Typography variant="body2" color="secondary.900">
                      {designation ? designation : "N/A"}
                    </Typography>
                  </Grid>
                  <Grid xs={5}>
                    <Typography variant="body2" color="secondary.600">
                      Industry Type
                    </Typography>
                    <Typography variant="body2" color="secondary.900">
                      {industry_details?.type ? industry_details?.type : "N/A"}
                    </Typography>
                  </Grid>
                  <Grid xs={12}>
                    <Typography variant="body2" color="secondary.600">
                      Address
                    </Typography>
                    <Typography variant="body2" color="secondary.900">
                      {address?address:"N/A"}
                    </Typography>
                  </Grid>
                </Grid>

                <Divider sx={{ mt: 2, mb: 2 }} />

                <Typography variant="subtitle2" sx={{ pb: 2 }}>
                  Contact Person Details
                </Typography>

                <Grid container spacing={2}>
                  <Grid xs={5}>
                    <Typography variant="body2" color="secondary.600">
                      Contract Person
                    </Typography>
                    <Typography variant="body2" color="secondary.900">
                      {contact_person_details?.name ? contact_person_details?.name : "N/A"}
                    </Typography>
                  </Grid>
                  <Grid xs={7}>
                    <Typography
                      variant="body2"
                      display="block"
                      color="secondary.600"
                    >
                      Mail I’D
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="body2" color="primary.main" noWrap>
                        {contact_person_details?.email ? contact_person_details?.email : "N/A"}
                      </Typography>
                      <IconButton
                        aria-label="more"
                        size="small"
                        id="long-button"
                        aria-controls={open ? "long-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleClickMailIdOption}
                        sx={{ p: 0, ml: 1 }}
                        color="primary"
                      >
                        <ArrowDropDownIcon />
                      </IconButton>
                    </Box>
                    <Menu
                      id={id}
                      open={open}
                      anchorEl={anchorElMailOption}
                      onClose={handleCloseMailOption}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      className={classes.MailIdMenu}
                    >
                      <MenuItem> 
                        {contact_person_details?.email}
                      </MenuItem>
                    </Menu>
                  </Grid>
                  <Grid xs={5}>
                    <Typography variant="body2" color="secondary.600">
                      Phone No
                    </Typography>
                    <Typography variant="body2" color="secondary.900">
                      {contact_person_details?.phone ? contact_person_details?.phone : "N/A"}
                    </Typography>
                  </Grid>
                  <Grid xs={7}>
                    <Typography variant="body2" color="secondary.600">
                      Recipient
                    </Typography>
                    <Typography variant="body2" color="secondary.900">
                      {contact_person_details?.recipient ? contact_person_details?.recipient : "N/A"}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </TabPanel>
            <TabPanel value={tabValue} index={1} className="tabs-panel">
              <Box className="VendorInfo-tabs">
                <Typography variant="subtitle2" sx={{ pt: 2, pb: 2 }}>
                  General Details
                </Typography>
                <Grid container spacing={2}>
                  <Grid xs={12}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Avatar
                        sx={{
                          bgcolor: "primary.main",
                          fontSize: "12px",
                          fontWeight: "500",
                          width: 32,
                          height: 32,
                        }}
                      >
                        {buyerInfo?.acc_name?.toUpperCase().slice(0, 2)}
                      </Avatar>
                      <Box>
                        <Typography
                          variant="subtitle2"
                          component="div"
                          color="secondary.600"
                        >
                          {buyerInfo?.acc_name ? buyerInfo?.acc_name : "N/A"}
                        </Typography>
                        <Typography variant="body2" color="grey.500">
                          {buyerInfo?.acc_code ? buyerInfo?.acc_code : "N/A"}
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid xs={7}>
                    <Typography
                      variant="body2"
                      display="block"
                      color="secondary.600"
                    >
                      Mail I’D
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="body2" color="primary.main" noWrap>
                        {buyerInfo?.email ? buyerInfo?.email : "N/A"}
                      </Typography>
                      <IconButton
                        aria-label="more"
                        size="small"
                        id="long-button"
                        aria-controls={open ? "long-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={handleClickMailIdOption}
                        sx={{ p: 0, ml: 1 }}
                        color="primary"
                      >
                        <ArrowDropDownIcon />
                      </IconButton>
                    </Box>
                    <Menu
                      id={id}
                      open={open}
                      anchorEl={anchorElMailOption}
                      onClose={handleCloseMailOption}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      className={classes.MailIdMenu}
                    >
                      <MenuItem>
                      {buyerInfo?.Email ? buyerInfo?.Email : "N/A"}
                      </MenuItem>
                    </Menu>
                  </Grid>
                  <Grid xs={5}>
                    <Typography variant="body2" color="secondary.600">
                      Phone No
                    </Typography>
                    <Typography variant="body2" color="secondary.900">
                      {buyerInfo?.phone ? buyerInfo?.phone : "N/A"}
                    </Typography>
                  </Grid>
                  <Grid xs={7}>
                    <Typography variant="body2" color="secondary.600">
                      Designation
                    </Typography>
                    <Typography variant="body2" color="secondary.900">
                      {buyerInfo?.designation ? buyerInfo?.designation : "N/A"}
                    </Typography>
                  </Grid>
                  <Grid xs={5}>
                    <Typography variant="body2" color="secondary.600">
                      Account Name [Code]
                    </Typography>
                    <Typography variant="body2" color="secondary.900">
                      {`${buyerInfo?.name ? buyerInfo?.name : "N/A"} [${buyerInfo?.code}]`} 
                    </Typography>
                  </Grid>
                  <Grid xs={12}>
                    <Typography variant="body2" color="secondary.600">
                      Address
                    </Typography>
                    <Typography variant="body2" color="secondary.900">
                      {buyerInfo?.address ? buyerInfo?.address : "N/A"}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </TabPanel>
          </Box>
        </CardContent>
      </Card>
    </ViewDetailsRight>
  );
}
