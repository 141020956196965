import * as React from "react";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Chip,
  DialogContent,
  DialogTitle,
  Stack,
  Tab,
  Tabs,
  Tooltip
} from "@mui/material";
import VendorList from "../vendorList/vendorList";
import ItemLists from "../itemList/listOfItems";
import { BrowserView, MobileView } from "react-device-detect";
import { useSelector } from "react-redux";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Box>
  );
}

export default function ItemWiseVendor({
  sessionID,
  templateId,
  itemSpecificVendor,
  openDialogueBox,
  handleCloseDialogue,
  selectedId,
  linkUnlinkData,
  filterDataChip,
  filterLabels,
  vendorCancelQuote,
  statusOngoing
}) {

  const [value, setValue] = React.useState(0);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const Permisison = useSelector((state) => state.permissionsReducer);
  const handleSelectItem = (item) => {
    setSelectedItem(item);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const inquiryNumber = linkUnlinkData[0]?.inquiryNo;

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        sx={{
          margin: { xs: "0", sm: "0", md: "20px" }
        }}
        open={openDialogueBox}
        onClose={handleCloseDialogue}
        className="RFQ-VendorDialog"
      >
        <DialogTitle
          sx={{
            position: "sticky",
            top: 0,
            padding: "11px 15px !important"
          }}
        >
          <Stack
            className="VendorItem-Title"
            direction={{ sm: "column", md: "row" }}
            spacing={{ xs: 0, sm: 0, md: 1 }}
            alignItems={{ sm: "start", md: "center" }}
            sx={{
              flex: 1,
              "@media screen and (max-width:991px)": {
                width: "calc(100% - 25px)"
              }
            }}
          >
            <Tooltip title={inquiryNumber}>
              <Typography
                sx={{ color: "#000000" }}
                variant="body2"
                fontWeight={700}
                component="div"
                noWrap
              >
                {inquiryNumber}
              </Typography>
            </Tooltip>
            <Stack
              className="RFQStatus-Chip"
              direction="row"
              spacing={1}
              sx={{
                "& .MuiChip-root .MuiChip-label": {
                  fontWeight: "400"
                },
                "@media screen and (max-width:991px)": {
                  "&.RFQStatus-Chip": {
                    width: "100%",
                    overflow: "auto",
                    ".MuiChip-root": {
                      marginTop: "8px"
                    }
                  }
                }
              }}
            >
              {filterDataChip?.sealed === true && (
                <Chip
                  label={filterLabels?.sealed}
                  size="xsmall"
                  variant="filled"
                />
              )}
              {filterDataChip?.r2a === true && (
                <Chip
                  label={filterLabels?.r2a}
                  size="xsmall"
                  variant="filled"
                />
              )}
              {filterDataChip?.multi_plant === true && (
                <Chip
                  label={filterLabels?.multi_plant}
                  size="xsmall"
                  variant="filled"
                />
              )}
              {filterDataChip?.prioritized === true && (
                <Chip
                  label={filterLabels?.prioritized}
                  size="xsmall"
                  variant="filled"
                />
              )}
              {filterDataChip?.multiple_solution === true && (
                <Chip
                  label={filterLabels?.multiple_solution}
                  size="xsmall"
                  variant="filled"
                />
              )}
              {filterDataChip?.contract === true && (
                <Chip
                  label={filterLabels?.contract}
                  size="xsmall"
                  variant="filled"
                />
              )}
              {filterDataChip?.technical_bid === true  && Permisison?.accountModulePermission?.["Technical Bid"]?.module_activated == "Y" && (
                <Chip
                  label={filterLabels?.technical_bid}
                  size="xsmall"
                  variant="filled"
                />
              )}
            </Stack>
          </Stack>
          <IconButton
            edge="end"
            color="var(--bluegrey-500)"
            size="small"
            onClick={() => {
              handleCloseDialogue();
              setSelectedItem(null);
            }}
            aria-label="close"
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ p: 0 }} dividers>
          <React.Fragment>
            <BrowserView>
              {/* Start Desktop View */}
              <Box
                sx={{
                  display: { xs: "none", sm: "none", md: "flex" },
                  height: "100%"
                }}
              >
                <Box className="RFQ-ItemsList-Left">
                  <ItemLists
                    sessionID={sessionID}
                    selectedId={selectedId}
                    selectedItem={selectedItem}
                    handleSelectItem={handleSelectItem}
                  />
                </Box>
                <Box className="RFQ-VendorDataGrid">
                  <VendorList
                    itemSpecificVendor={itemSpecificVendor}
                    sessionID={sessionID}
                    templateId={templateId}
                    selectedId={selectedId}
                    inquiryNumber={inquiryNumber}
                    selectedItem={selectedItem}
                    vendorCancelQuote={vendorCancelQuote}
                    statusOngoing={statusOngoing}
                    setSelectedItem={setSelectedItem}
                    filterDataChip={filterDataChip}
                  />
                </Box>
              </Box>
              {/* End Desktop View */}
            </BrowserView>

            <MobileView>
              {/* Start Mobile View */}
              <Box
                className="itemWiseVendor-Tabs"
                sx={{
                  display: { xs: "block", sm: "block", md: "none" },
                  "&.itemWiseVendor-Tabs .tabs-panel > .MuiBox-root": {
                    padding: "0"
                  },
                  "&.itemWiseVendor-Tabs .tabs-panel": {
                    borderTop: "1px solid var(--bluegrey-500)"
                  }
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Items List" />
                  <Tab label="Vendors" />
                </Tabs>
                <TabPanel value={value} index={0} className="tabs-panel">
                  <Box className="RFQ-ItemsList-Left">
                    <ItemLists
                      sessionID={sessionID}
                      itemSpecificVendor={itemSpecificVendor}
                      selectedId={selectedId}
                      selectedItem={selectedItem}
                      handleSelectItem={handleSelectItem}
                    />
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={1} className="tabs-panel">
                  <Box className="RFQ-VendorDataGrid">
                    <VendorList
                      sessionID={sessionID}
                      templateId={templateId}
                      selectedId={selectedId}
                      inquiryNumber={inquiryNumber}
                      selectedItem={selectedItem}
                      vendorCancelQuote={vendorCancelQuote}
                      statusOngoing={statusOngoing}
                      setSelectedItem={setSelectedItem}
                      filterDataChip={filterDataChip}
                    />
                  </Box>
                </TabPanel>
              </Box>
              {/* End Mobile View */}
            </MobileView>
          </React.Fragment>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
