import axios from "axios";
import React, { Component } from "react";
import { openSnackbar } from "../../redux/actions/snackbarAction";
import UploadDynamic from "../Dialogs/Upload/UploadDynamic";
import {
  devTemplateURi,
  getDropdownOptions,
  templateURi,
  modifyRfqDetailUri,
} from "../../config/apiUrl";
import { connect } from "react-redux";
import CardTemplate from "../Card/CardTemplate";
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  Checkbox,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { Box } from "@mui/system";
import UploadIcon from "@mui/icons-material/CloudUploadRounded";
import ConfirmAlert from "../Dialogs/ConfirmAlert.js";
import _ from "lodash";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

class NewFields extends Component {
  state = {
    jsonTemplateloading: false,
    jsonTemplate: [],
    finalTemplate: {},
    errorArray: [],
    currentFieldId: null,
    dynamicFileUploadOpen: false,
    confirmDialogOpen: false,
    rfqSubmitLoading: false,
    shipment_mark: "",
    randomId: "",
  };

  componentDidMount() {
    this.fetchJSONTemplate();
    this.setState({ randomId: this.generateRandom(100000000, 900000000) });
  }

  generateRandom = (min, max) => {
    const timestring = new Date().getTime();
    const numstring = Math.floor(Math.random() * (max - min + 1)) + min;
    return timestring + "." + numstring;
  };

  fetchJSONTemplate = () => {
    const { vendorType, sessionID, inquiryDetails, division_id, companyInfo } =
      this.props;

    axios.defaults.headers.common["Authorization"] = `Bearer ${sessionID}`;

    let id = inquiryDetails.template_id;
    let batch_id = inquiryDetails.batch_id;

    this.setState({
      jsonTemplateloading: true,
    });
    if (process.env.NODE_ENV === "production") {
      console.log("production mode");
      let templateURL = templateURi(
        division_id,
        companyInfo.display_name,
        id,
        batch_id
      );
      axios
        .get(templateURL)
        .then((response) => {
          if (vendorType === "local" && response.data.LOCAL?.length) {
            this.setState(
              {
                jsonTemplate: response.data.LOCAL,
                jsonTemplateloading: false,
              },
              () => this.initializeState()
            );
          } else if (
            vendorType === "foreign" &&
            response.data.FOREIGN?.length
          ) {
            this.setState(
              {
                jsonTemplate: response.data.FOREIGN,
                jsonTemplateloading: false,
              },
              () => this.initializeState()
            );
          }
        })
        .catch((err) => {
          console.log(err, "main json err");
          this.props.openSnackbar("error", err.message);
          this.setState({
            jsonTemplateloading: false,
          });
        });
    } else {
      // devTemplateURi
      console.log("development mode");
      let templateURL = devTemplateURi(
        division_id,
        companyInfo.display_name,
        id,
        batch_id
      );

      axios
        .get(templateURL)
        .then((response) => {
          if (vendorType === "local" && response.data.LOCAL?.length) {
            this.setState(
              {
                jsonTemplate: response.data.LOCAL,
                jsonTemplateloading: false,
              },
              () => this.initializeState()
            );
          } else if (
            vendorType === "foreign" &&
            response.data.FOREIGN?.length
          ) {
            this.setState(
              {
                jsonTemplate: response.data.FOREIGN,
                jsonTemplateloading: false,
              },
              () => this.initializeState()
            );
          }
        })
        .catch((err) => {
          console.log(err, "main json err");
          this.props.openSnackbar("error", err.message);
          this.setState({
            jsonTemplateloading: false,
          });
        });
    }
  };

  initializeState = () => {
    let row = {};
    this.state.jsonTemplate?.forEach((cell) => {
      row[cell.FieldId] = {
        HTMLAttributeName: cell.HTMLAttributeName,
        value: "",
        Validation: cell.Validation,
        FieldLabel: cell.FieldLabel,
        error: false,
        ErrorMessage: cell.ErrorMessage,
        Identifier: cell.Identifier,
        ElementType: cell.ElementType,
      };
      if (cell.ElementType === "S") {
        if (cell.DropdownEdit === "Y") {
          row[cell.FieldId]["options"] = "loading";
          this.getOptionsFromApi(cell);
        } else {
          row[cell.FieldId]["options"] = cell.DefaultValue;
        }
      }
    });
    this.setState({
      finalTemplate: row,
    });
  };

  getOptionsFromApi = (cell) => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionID}`;
    const url = getDropdownOptions(
      cell.Identifier,
      this.props.inquiryDetails.template_id
    );
    axios
      .get(url)
      .then((res) => {
        if (
          res.data.dropdown_mapping_status !== false
        ) {
          this.setState((prevState) => ({
            ...prevState,
            finalTemplate: {
              ...prevState.finalTemplate,
              [cell.FieldId]: {
                ...prevState.finalTemplate[cell.FieldId],
                options: res.data?.data,
              },
            },
          }));
        } else {
          this.setState((prevState) => ({
            ...prevState,
            finalTemplate: {
              ...prevState.finalTemplate,
              [cell.FieldId]: {
                ...prevState.finalTemplate[cell.FieldId],
                options: cell.DefaultValue,
              },
            },
          }));
        }
      })
      .catch((err) => {
        console.log(err, "options error");
        this.setState((prevState) => ({
          ...prevState,
          finalTemplate: {
            ...prevState.finalTemplate,
            [cell.FieldId]: {
              ...prevState.finalTemplate[cell.FieldId],
              options: [],
            },
          },
        }));
      });
  };

  handleChange = (e, FieldId) => {
    const { value, type, checked } = e.target;
    this.setState((prevState) => ({
      ...prevState,
      finalTemplate: {
        ...prevState.finalTemplate,
        [FieldId]: {
          ...prevState.finalTemplate[FieldId],
          value: type !== "checkbox" ? value : checked,
        },
      },
    }));
  };

  finddependacy = (FieldId, validation) => {
    let field_ids = validation.match(/f(\d)*/g);
    let is_dependacy = 0;
    for (const field_id of field_ids){
      if( "f"+FieldId != field_id ){
         is_dependacy = 1;
         break;
      }
    }
    return is_dependacy;
  };

  validateInputLocal = (FieldId, submitting) => {
    if (submitting) {
      if (this.state.finalTemplate[FieldId]["error"] && !this.finddependacy(FieldId,this.state.finalTemplate[FieldId]["Validation"]) ) {
        if (
          !this.state.errorArray.includes(
            this.state.finalTemplate[FieldId]["HTMLAttributeName"]
          )
        ) {
          this.state.errorArray.push(
            this.state.finalTemplate[FieldId]["HTMLAttributeName"]
          );
        }
        return;
      }
    }
    if (this.state.finalTemplate[FieldId]["Validation"]) {
      let temp = this.state.finalTemplate[FieldId]["Validation"].replace(
        /f(\d)+/g,
        (match) => {
          if (this.state.finalTemplate[match.replace("f", "")]) {
            return `this.state.finalTemplate[${match.replace(
              "f",
              ""
            )}]["value"]`;
          } else {
            return null;
          }
        }
      );
      if (temp !== null) {
        if (eval(temp)) {
          this.setState((prevState) => ({
            ...prevState,
            finalTemplate: {
              ...prevState.finalTemplate,
              [FieldId]: {
                ...prevState.finalTemplate[FieldId],
                error: false,
              },
            },
          }));
          let index1 = this.state.errorArray.indexOf(
            this.state.finalTemplate[FieldId]["HTMLAttributeName"]
          );
          if (index1 !== -1) {
            this.state.errorArray.splice(index1, 1);
          }
        } else {
          this.setState((prevState) => ({
            ...prevState,
            finalTemplate: {
              ...prevState.finalTemplate,
              [FieldId]: {
                ...prevState.finalTemplate[FieldId],
                error: true,
              },
            },
          }));
          if (
            !this.state.errorArray.includes(
              this.state.finalTemplate[FieldId]["HTMLAttributeName"]
            )
          ) {
            this.state.errorArray.push(
              this.state.finalTemplate[FieldId]["HTMLAttributeName"]
            );
          }
        }
        this.setState({
          errorArray: this.state.errorArray,
        });
      } else {
        console.log("dependent field not found in template");
      }
    }
  };

  getField = (cell) => {
    let error =
      this.state.finalTemplate[cell.FieldId] &&
      this.state.finalTemplate[cell.FieldId]["error"];
    let ErrorMessage =
      this.state.finalTemplate[cell.FieldId] &&
      this.state.finalTemplate[cell.FieldId]["ErrorMessage"];
    let name =
      this.state.finalTemplate[cell.FieldId] &&
      this.state.finalTemplate[cell.FieldId]["HTMLAttributeName"];
    let value =
      this.state.finalTemplate[cell.FieldId] &&
      this.state.finalTemplate[cell.FieldId]["value"];

    let isLink = this.checkIsLinkLocal(cell);
    if (!isLink) {
      if (cell.ElementType === "T") {
        return <Typography>{cell.FieldLabel}</Typography>;
      } else if (cell.ElementType === "TB") {
        return (
          <TextField
            className="local_template_textfield"
            fullWidth
            variant="outlined"
            label={cell.FieldLabel}
            type="text"
            error={error}
            helperText={error && ErrorMessage}
            name={name}
            value={value}
            onChange={(e) => this.handleChange(e, cell.FieldId)}
            onBlur={() => this.validateInputLocal(cell.FieldId)}
          />
        );
      } else if (cell.ElementType === "TA") {
        return (
          <TextField
            className="local_template_textarea"
            fullWidth
            multiline
            variant="outlined"
            maxRows={3}
            label={cell.FieldLabel}
            error={error}
            helperText={error && ErrorMessage}
            name={name}
            onChange={(e) => this.handleChange(e, cell.FieldId)}
            onBlur={() => this.validateInputLocal(cell.FieldId)}
          />
        );
      } else if (cell.ElementType === "M") {
        return (
          <Checkbox
            color="primary"
            checked={!!value}
            name={name}
            onChange={(e) => {
              this.handleChange(e, cell.FieldId);
            }}
          />
        );
      } else if (cell.ElementType === "DT") {
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              renderInput={(props) => (
                <TextField
                  {...props}
                  fullWidth
                  error={error}
                  helperText={error && "Invalid Date"}
                />
              )}
              ampm={false}
              label={cell.FieldLabel}
              name={name}
              value={value || null}
              // onChange={(newVal) => {

              //   this.handleDateChange(newVal, cell.FieldId, "finalTemplate");
              // }}
            />
          </LocalizationProvider>
        );
      } else if (cell.ElementType === "F") {
        return (
          <FormControl error={error} sx={{ width: "100%" }}>
            <Box
              sx={{
                border: "solid 1px",
                borderColor: (theme) => theme.palette.bluegrey[500],
                borderRadius: "4px",
                minWidth: "100%",
                height: "56px",
                display: "flex",
                alignItems: "center",
                padding: "0 10px",
                position: "relative",
              }}
            >
              <InputLabel
                sx={{
                  color: "text.secondary",
                  backgroundColor: (theme) => theme.palette.grey[0],
                  position: "absolute",
                  left: 0,
                  top: 0,
                  paddingLeft: "5px",
                  paddingRight: "5px",
                  transformOrigin: "top left",
                  webkitTransform: " translate(14px, -9px) scale(0.75)",
                  transform: "translate(14px, -9px) scale(0.75)",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "calc(133% - 24px)",
                  zIndex: 1,
                }}
              >
                {cell.FieldLabel}
              </InputLabel>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                  this.setState({
                    currentFieldId: cell.FieldId,
                    dynamicFileUploadOpen: true,
                  });
                }}
              >
                <UploadIcon sx={{ marginRight: "10px" }} />
                Attachment
              </Button>
              <FormHelperText sx={{ ml: "auto" }}>
                Number Of Files {value?.length}
              </FormHelperText>
            </Box>
            <Typography variant="caption">
              {this.state.finalTemplate[cell.HTMLAttributeName]}
            </Typography>
            {error && <FormHelperText>{ErrorMessage}</FormHelperText>}
          </FormControl>
        );
      } else if (cell.ElementType === "S") {
        return (
          <FormControl fullWidth variant="outlined" error={error}>
            <InputLabel>{cell.FieldLabel}</InputLabel>
            <Tooltip title={value} disableInteractive placement="bottom-start">
              <Select
                // title={value}
                className="template_dropdown"
                label={cell.FieldLabel}
                name={name}
                value={value || ""}
                onChange={(e) => {
                  this.handleChange(e, cell.FieldId);
                }}
                onBlur={(e) => {
                  this.validateInputLocal(cell.FieldId);
                }}
                MenuProps = {{
                  PaperProps: {
                    style: {
                      maxHeight: 250,
                      width: 250
                    }
                  }
                }}
              >
                {this.state.finalTemplate[cell.FieldId] &&
                  this.state.finalTemplate[cell.FieldId]["options"] &&
                  (this.state.finalTemplate[cell.FieldId]["options"].length >
                  0 ? (
                    this.state.finalTemplate[cell.FieldId]["options"] ===
                    "loading" ? (
                      <MenuItem value="">Loading...</MenuItem>
                    ) : (
                      this.state.finalTemplate[cell.FieldId]["options"].map(
                        (option, id) => {
                          return (
                            <MenuItem
                              key={id}
                              value={
                                option.dropdown_element_name
                                  ? this.props.companyInfo
                                      ?.default_dropdown_type === "10" || cell.Identifier === "Destination" || cell.Identifier === "DestinationForeign"
                                    ? `${option.dropdown_element_name}-${option.dropdown_element_code}`
                                    : `${option.dropdown_element_code}-${option.dropdown_element_name}`
                                  : option.code
                              }
                            >
                              <Typography style={{ whiteSpace: "pre-line" }}>
                                {option.dropdown_element_name || option.name}
                              </Typography>
                            </MenuItem>
                          );
                        }
                      )
                    )
                  ) : (
                    <MenuItem value="">Not found</MenuItem>
                  ))}
              </Select>
            </Tooltip>
            {error && <FormHelperText>{ErrorMessage}</FormHelperText>}
          </FormControl>
        );
      } else {
        return cell.FieldLabel;
      }
    }
  };

  checkIsLinkLocal = (cell) => {
    //first check if the vendor master check is present or not
    if (
      this.props.inquiryDetails.is_payment_term === "Y" &&
      this.props.inquiryDetails.is_inco_term === "Y"
    ) {
      // check from both
      let str = this.props.companyInfo?.inco_term_identifier;
      let str1 = this.props.companyInfo?.payment_term_identifier;
      if (str) {
        let arr = str.split(";");
        if (arr.includes(cell.Identifier)) {
          return "inco";
        }
      }
      if (str1) {
        let arr1 = str1.split(";");
        if (arr1.includes(cell.Identifier)) {
          return "payment";
        }
      }
      return false;
    } else if (this.props.inquiryDetails.is_payment_term === "Y") {
      // paymentterm array
      if (!this.props.companyInfo?.payment_term_identifier) return false;
      let str = this.props.companyInfo?.payment_term_identifier;
      let arr = str.split(";");
      // let arr = ["PaymentType", "PaymentTypeForeign"];
      if (arr.includes(cell.Identifier)) {
        return "payment";
      }
      return false;
    } else if (this.props.inquiryDetails.is_inco_term === "Y") {
      // incoterm array
      if (!this.props.companyInfo?.inco_term_identifier) return false;
      let str = this.props.companyInfo?.inco_term_identifier;
      let arr = str.split(";");
      // let arr = ["DeliveryBasis", "DeliveryRemarks"];
      if (arr.includes(cell.Identifier)) {
        return "inco";
      }
      return false;
    }
    return false;
  };

  handleRFQSubmit = () => {
    for (const key in this.state.finalTemplate) {
      // if it is link then skip
      let result = this.checkIsLinkLocal(this.state.finalTemplate[key]);
      if (!result) {
        this.validateInputLocal(key, true);
      }
    }
    if (this.state.errorArray.length) {
      this.props.openSnackbar(
        "error",
        "There are errors in your form. Please resolve in order to continue"
      );
      return;
    }
    this.setState({
      confirmDialogOpen: true,
    });
  };

  rfqsubmitAPI = () => {
    // let temp = _.cloneDeep(this.state.finalTemplate);
    let payload = {};
    let finalArray = [];

    for (const key in this.state.finalTemplate) {
      const element = this.state.finalTemplate[key];

      finalArray.push({
        HTMLAttributeName: element.HTMLAttributeName,
        Validation: element.Validation,
        value: element.value,
        FormMasterId: key
      })

      // for (const key2 in element) {
      //   if (
      //     key2 !== "HTMLAttributeName" &&
      //     key2 !== "value" &&
      //     key2 !== "Validation"
      //   ) {
      //     delete element[key2];
      //   }
      // }
    }
    payload.vendorFields = finalArray;
    if (this.props.vendorType === "foreign") {
      // temp.shipment_mark = this.state.shipment_mark
      payload.shipment_mark = this.state.shipment_mark
    }
    console.log("payload", payload);
    this.setState({
      rfqSubmitLoading: true,
    });
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionID}`;
    const url = modifyRfqDetailUri(this.props.inquiryDetails.inquiry_id);
    axios
      .post(url, payload)
      .then((res) => {
        if (res.status == 200) {
          window.parent.postMessage("myevent", "*");
          this.props.openSnackbar(
            "success",
            res.data.message
              ? res.data.message
              : "Vendor(s) added successfully to the RFQ."
          );
          // message
          this.setState({
            rfqSubmitLoading: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          rfqSubmitLoading: false,
        });
        this.props.openSnackbar(
          "error",
          err.response?.data?.message
            ? err.response?.data?.message
            : "Something went wrong"
        );
        console.log(err, "modifyRfqDetailApi");
      });
  };

  handleShipmentMarkChange = (e) => {
    this.setState({
      shipment_mark: e.target.value,
    });
  };
  render() {
    return (
      <Box sx={{ m: 2 }}>
        <Backdrop
          style={{ color: "#fff", zIndex: "10000000000" }}
          open={this.state.jsonTemplateloading || this.state.rfqSubmitLoading}
        >
          <CircularProgress style={{ color: "#fff" }} />
        </Backdrop>
        <CardTemplate
          sendToNew
          title={
            this.props.vendorType === "local"
              ? "LOCAL RECIPIENTS"
              : "INTERNATIONAL RECIPIENTS"
          }
          data={this.state.jsonTemplate}
          getField={this.getField}
          shipment_mark={this.state.shipment_mark}
          handleShipmentMarkChange={this.handleShipmentMarkChange}
        />

        <div style={{ margin: "2rem 0" }}>
          <Button
            variant="contained"
            color="primary"
            className="Upload_file_btn"
            onClick={this.handleRFQSubmit}
          >
            Submit RFQ
          </Button>
        </div>

        {this.state.dynamicFileUploadOpen && (
          <UploadDynamic
            sessionID={this.props.sessionID}
            currentFieldId={this.state.currentFieldId}
            uploadedFiles={
              this.state.finalTemplate[this.state.currentFieldId] &&
              this.state.finalTemplate[this.state.currentFieldId]["value"]
            }
            handleUplaodedFileDelete={(index) => {
              let temp = this.state.finalTemplate;
              temp[this.state.currentFieldId]["value"].splice(index, 1);
              this.setState({
                finalTemplate: temp,
              });
            }}
            setAttachmentsValue={(value) => {
              this.setState((prevState) => ({
                ...prevState,
                finalTemplate: {
                  ...prevState.finalTemplate,
                  [this.state.currentFieldId]: {
                    ...prevState.finalTemplate[this.state.currentFieldId],
                    value: this.state.finalTemplate[this.state.currentFieldId][
                      "value"
                    ]
                      ? [
                          ...this.state.finalTemplate[
                            this.state.currentFieldId
                          ]["value"],
                          ...value,
                        ]
                      : value,
                  },
                },
              }));
            }}
            randomId={this.state.randomId}
            open={this.state.dynamicFileUploadOpen}
            handleClose={() => {
              this.setState({
                dynamicFileUploadOpen: false,
              });
            }}
          />
        )}

        <ConfirmAlert
          open={this.state.confirmDialogOpen}
          handleClose={() => {
            this.setState({
              confirmDialogOpen: false,
            });
          }}
          handleSubmit={() => {
            this.setState({
              confirmDialogOpen: false,
            });
            this.rfqsubmitAPI();
          }}
          msg="Are you sure you want to submit RFQ?"
          title="Submit?"
        />
      </Box>
    );
  }
}

export default connect(null, {
  openSnackbar,
})(NewFields);
