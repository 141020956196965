import { Button } from "@mui/material";
import styled from "styled-components";

export const Buttonicon = styled(Button)`
  &.MuiButtonBase-root {
    background-color: var(--thm-bg);
    padding: 8px 8px;
    min-width: 30px;
    box-shadow: none;
    outline: none;
    color: var(--thm-white);
  }

  span {
    color: var(--thm-white);
  }

  svg {
    color: var(--thm-white);
    font-size: 22px;
  }

  :hover {
    background-color: var(--thm-bg) !important;
    box-shadow: none;
    outline: none;
  }
  @media only screen and (max-width: 991.5px) {
    &.MuiButtonBase-root {
      margin-top: 10px;
    }
  }
`;

export const Buttonicon1 = styled(Button)`
  &.MuiButtonBase-root {
    background-color: transparent !important;
    box-shadow: none;
    padding: 10px 16px;
    min-width: 30px;
    border: 1px solid var(--thm-light-blue);
    border-radius: 5px;
  }

  span,
  :hover,
  :focus,
  svg,
  :hover,
  :focus {
    color: var(--thm-gray);
    outline: none;
  }

  :hover {
    background-color: transparent !important;
    box-shadow: none !important;
    outline: none;
    border-color: var(--thm-light-blue) !important;
  }

  :focus {
    box-shadow: none;
    outline: none;
    outline: none;
  }
`;

export const Buttontext = styled(Button)`
  &.MuiButtonBase-root {
    color: var(--thm-white);
    background-color: var(--thm-bg);
    /* padding: 10px 16px; */
    font-weight: 400;
    min-width: 100px;
    box-shadow: none;
    outline: none;
    border: 1px solid var(--thm-bg);
  }

  span {
    color: var(--thm-white);
    text-transform: uppercase;
    font-size: 14px;
  }

  svg {
    color: var(--thm-white);
  }

  :hover {
    background-color: var(--thm-bg) !important;
    box-shadow: none !important;;
    outline: none;
  }
`;

export const Buttontextborder = styled(Button)`
  &.MuiButtonBase-root {
    min-width: 30px;
    box-shadow: none;
    outline: none;
    background: var(--thm-white);
    border: 1px solid var(--border-outline);
    border-radius: 5px;
    padding: 5px 3px;
  }
  svg {
    color: var(--thm-gray);
    font-size: 35px;
  }
  span {
    color: var(--thm-gray);
    text-transform: capitalize;
    font-size: 14px;
  }

  :hover {
    background-color: var(--thm-gray-bg) !important;
    box-shadow: none !important;
    outline: none;
    border: 1px solid var(--border-outline) !important;
  }
  @media only screen and (max-width: 991.5px) {
    &.MuiButtonBase-root {
      margin-top: 10px;
    }
  }
`;

export const Withoutborderbutton = styled(Button)`
  &.MuiButtonBase-root {
    padding: 10px 16px;
    min-width: 30px;
    box-shadow: none;
    outline: none;
    background: transparent;
    border: none;
    color: var(--thm-gray);    
    text-transform: uppercase;
    font-size: calc(13.5px + (14 - 13) * ((100vw - 300px) / (1920 - 300)));
  }
  svg {
    color: var(--thm-bg);
    color: var(--thm-gray);
  }
  .MuiSvgIcon-root {
    font-size: calc(22px + (22 - 20) * ((100vw - 300px) / (1920 - 300))) !important;
  }
  img {
    width:  calc(14px + (14 - 12) * ((100vw - 300px) / (1920 - 300))) !important;
  }
  span {
    color: var(--thm-gray);
    text-transform: uppercase;
    font-size:  calc(14px + (14 - 12) * ((100vw - 300px) / (1920 - 300))) !important;
    display: flex;
    align-items: center;
    vertical-align: middle;
    font-weight: 700;
  }

  :hover {
    background-color: transparent !important;
    box-shadow: none;
    outline: none;
  }
  .MuiTouchRipple-root span {
    /* display: none; */
    background: unset;
    box-shadow: none;
  }
  .MuiTouchRipple-root {
    background: unset;
    box-shadow: none;
  }
`;
export const Withoutborderbuttonicon = styled(Button)`
  &.MuiButtonBase-root {
    padding: 10px 0px;
    min-width: 45px;
    box-shadow: none;
    outline: none;
    background: transparent;
    border: none;
  }
  svg {
    color: var(--thm-gray);
  }
  .MuiSvgIcon-root {
    font-size: 25px !important;
  }
  span {
    color: var(--thm-gray);
    text-transform: capitalize;
    font-size: 14px;
    align-items: flex-end;
  }
  :hover {
    background-color: transparent !important;
    box-shadow: none;
    outline: none;
  }
  .MuiTouchRipple-root span {
    background: unset;
    box-shadow: none;
  }
  .MuiTouchRipple-root {
    background: unset;
    box-shadow: none;
  }
`;

export const Buttontextlight = styled(Button)`
  &.MuiButtonBase-root {
    background-color: var(--thm-white);
    /* padding: 9px 20px; */
    font-weight: 600;
    min-width: 100px;
    box-shadow: none;
    outline: none;
    border: 1px solid var(--thm-bg);
    border-radius: 3px;
    color:var(--thm-bg);
  }

  span {
    color: var(--thm-bg);
    text-transform: uppercase;
    font-size: 14px;
  }

  svg {
    color: var(--thm-white);
  }

  :hover {
    background-color: var(--thm-white) !important;
    box-shadow: none;
    outline: none;
  }
  .MuiTouchRipple-root {
    display: none;
  }
`;
