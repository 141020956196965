import React, { Component } from "react";
import {
  CategoryEmptyCard,
  EmptyCategoryImage,
  EmptyCategoryTitle,
} from "./style";
import { Grid} from "@mui/material";
import { Box } from "@mui/system";
import emptycategoryicon from "../../assets/vendxAdminPanelAsstes/assetsnew/search_blank.svg"
import emptycarticon from "../../assets/vendxAdminPanelAsstes/assetsnew/cart_blank.svg"
import "../../pages/style.css";

export default class ShowEmpty extends Component {
  render() {
    return (
      <div className="page_content_main" style={{ padding: this.props.type === "category" ? "0 22px" : "0" }}      >
          <Grid container>
          <Grid item xs={12}>
            <CategoryEmptyCard elevation={0} cart={this.props.type === "category"} >

              <EmptyCategoryImage src={ this.props.type === "category" ? emptycategoryicon : emptycarticon} salt="icon" sx={{mb:2, }} />
              {/* <EmptyCategoryImage src={emptycarticon} salt="icon" className="mb-30" /> */}
              <EmptyCategoryTitle variant="h6">
                {this.props.type === "category"
                  ? "No category selected"
                  : "Your Cart is Empty"}
              </EmptyCategoryTitle>
              <EmptyCategoryTitle variant="h5" className="categorytext_heading">
                Get Started By Searching
              </EmptyCategoryTitle>
            </CategoryEmptyCard>
            {this.props.type !== "category" && 
            <Grid container>
              <Grid item xs={12}>
                <Box sx={{height:"60px", backgroundColor: (theme) => theme.palette.grey[0], borderTop:((theme)=> `1px solid ${theme.palette.bluegrey[500]}`), borderRadius:"0px 0px 5px 5px"}}></Box>
              </Grid> 
            </Grid>
            }
          </Grid>
          
        </Grid>
      </div>
    );
  }
}
