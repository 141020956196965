import { OPEN_SNACKBAR, SET_USER_INFO, SET_USER_INFO_ERROR } from "../constants/type";
import { accountvendorInfo, getUserInfo } from "../../config/apiUrl";
import axios from "axios";
import { setCompanyInfo } from "./setCompanyInfo";
import {
  setAccountLevelInfo,
  setAccountModulePermission,
  setFeaturePermission,
  setMasterPermission,
} from "./permissionsAction";
import {setEmptyPrCombination} from '../../redux/actions/prrfqactions';
export const setUserInfo = (sessionId) => async(dispatch) => {
  dispatch(setEmptyPrCombination(true))
  axios.defaults.headers["Authorization"] = `Bearer ${sessionId}`;
  const url = getUserInfo;
 await axios
    .get(url)
    .then((res) => {
      dispatch(setCompanyInfo(res.data.data.company_id, sessionId));
      dispatch(setMasterPermission(res.data.data.division_id, sessionId));
      dispatch(
        setAccountModulePermission(res.data.data.division_id, sessionId)
      );
      dispatch(setFeaturePermission(res.data.data.division_id, sessionId));
      dispatch(setAccountLevelInfo(res.data.data.division_id, sessionId));
      dispatch({
        type: SET_USER_INFO,
        payload: res.data,
      });
    })
    .catch((error) => {
      if(error?.response?.status===401){
        dispatch({
          type: OPEN_SNACKBAR,
          payload: {
            type: "error",
            msg: "Your Login session could not be validated. Please Login again...",
          },
        });
        dispatch({
          type: SET_USER_INFO_ERROR,
          payload: "Your Login session could not be validated. Please Login again...",
        });
      }else{
        dispatch({
          type: OPEN_SNACKBAR,
          payload: {
            type: "error",
            msg: error?.response?.data.message
              ? error?.response?.data.message
              : "Something went wrong",
          },
        });
        dispatch({
          type: SET_USER_INFO_ERROR,
          payload: error.message,
        });
      }
      dispatch(setEmptyPrCombination(false));  
    });
};

export const setVendorInfo = (sessionId) => async(dispatch) => {
  // dispatch(setEmptyPrCombination(true))
  axios.defaults.headers["Authorization"] = `Bearer ${sessionId}`;
  const url = accountvendorInfo;
 await axios
    .get(url)
    .then((res) => {
      dispatch(setCompanyInfo(res.data.data.company_id, sessionId));
      // dispatch(setMasterPermission(res.data.data.division_id, sessionId));
      dispatch(
        setAccountModulePermission(res.data.data.division_id, sessionId)
      );
      // dispatch(setFeaturePermission(res.data.data.division_id, sessionId));
      dispatch(setAccountLevelInfo(res.data.data.division_id, sessionId));
      dispatch({
        type: SET_USER_INFO,
        payload: res.data,
      });
    })
    .catch((error) => {
      if(error?.response?.status===401){
        dispatch({
          type: OPEN_SNACKBAR,
          payload: {
            type: "error",
            msg: "Your Login session could not be validated. Please Login again...",
          },
        });
        dispatch({
          type: SET_USER_INFO_ERROR,
          payload: "Your Login session could not be validated. Please Login again...",
        });
      }else{
        dispatch({
          type: OPEN_SNACKBAR,
          payload: {
            type: "error",
            msg: error?.response?.data.message
              ? error?.response?.data.message
              : "Something went wrong",
          },
        });
        dispatch({
          type: SET_USER_INFO_ERROR,
          payload: error.message,
        });
      }
      // dispatch(setEmptyPrCombination(false));  
    });
};