import styled from "styled-components";
import Alert from "@mui/material/Alert";
import { Snackbar } from "@mui/material";

export const CustomSnackbar = styled(Snackbar)`
  &.snackbar_bg {
    /* background-color: green; */
    border-radius: 8px;
  }
  &.snackbar_bg .MuiAlert-root {
    font-size: 14px;
  }
  &.snackbar_bg .MuiAlert-root,
  &.snackbar_bg .MuiAlert-root svg {
    color: var(--thm-white);
  }
  &.snackbar_bg .MuiAlert-filledError {
    background-color: var(--thm-red) !important;
  }
  &.snackbar_bg .MuiAlert-filledWarning {
    background-color: #ffb85e !important;
  }
  &.snackbar_bg .MuiAlert-filledSuccess {
    background-color: var(--thm-green) !important;
  }
  &.snackbar_bg .MuiAlert-filledInfo {
    background-color: var(--thm-bg) !important;
  }
  @media only screen and (max-width: 991.5px) {
  }
`;

export const CustomAlert = styled(Alert)`
  &.MuiAlert-root,
  &.MuiAlert-root svg {
    color: var(--thm-white) !important;
    align-items: center;
  }
  &.MuiAlert-filledError {
    background-color: var(--thm-red) !important;
  }
  &.MuiAlert-filledWarning {
    background-color: #ffb85e !important;
  }
  &.MuiAlert-filledSuccess {
    background-color: var(--thm-green) !important;
  }
  &.MuiAlert-filledInfo {
    background-color: var(--thm-bg) !important;
  }

  & .css-ki1hdl-MuiAlert-action {
  padding: 0 ;
}
`;
