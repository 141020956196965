import React from "react";
import Drawer from "@mui/material/Drawer";

//ICONS
// import AppMenu from "../../components/appmenu/AppMenu";
import { Box } from "@mui/system";
import AppMenu from "../../../components/appmenuVendor/AppMenu";
// import AppMenu from "../../../components/appmenu/AppMenu";

export default function VendorLeftSidebar(props) {
  return (
    <Drawer
      variant="permanent"
      className="leftSidebar"
      sx={{
        flexShrink: 0,
        //[`& .MuiDrawer-paper`]: { width: 64, boxSizing: 'border-box', marginTop:'64px', height: 'calc( 100vh - 64px)',},
        //['.open .leftSidebar .MuiDrawer-paper']: {width: 240,},
      }}
      open={props.open}>
      <Box sx={{ position: "static" }}>
        <Box
          sx={{
            position: "static",
            height: "100%",
            overflowY: "auto",
            overflowX: "hidden",
          }}>
          <Box component={"ul"} className="mainleft-menu" sx={{ p: 0 }}>
            <AppMenu open={props.open} />
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
}
