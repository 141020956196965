import React from "react";

//ICONS
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";

export const menu = [
  // {
  //   icon: <HomeOutlinedIcon />,
  //   title: "Dashboard",
  //   to: "/",
  // },
  {
    icon: <HomeOutlinedIcon />,
    title: "MUI Data Grid",
    to: "/Mui-data-grid",
  },

  {
    icon: <DashboardOutlinedIcon />,
    title: "Sourcing",
    items: [
      {
        // icon: <FiberManualRecordSharp sx={{ml: 2, width: 10}} />,
        title: "Transactions",
        items: [
          {
            // icon: <FiberManualRecordOutlined sx={{ml: 2, width: 10,}} />,
            title: "RFQ Float",
            to: "/rfqfloat",
          },
          {
            // icon: <FiberManualRecordOutlined sx={{ml: 2, width: 10,}} />,
            title: "PR Register",
            to: "/prregister",
          },
          {
            // icon: <FiberManualRecordOutlined sx={{ml: 2, width: 10,}} />,
            title: "Q Plus",
            to: "/qplus",
          },
        ],
      },
      {
        // icon: <FiberManualRecordSharp sx={{ml: 2, width: 10,}} />,
        title: "Reports",
        items: [
          {
            // icon: <FiberManualRecordOutlined sx={{ml: 2, width: 10,}} />,
            title: "Transaction History",
            to: "/TransactionHistory",
          },
          {
            // icon: <FiberManualRecordOutlined sx={{ml: 2, width: 10,}} />,
            title: "Negotiation Report",
            to: "/NegotiationReport",
          },
          {
            // icon: <FiberManualRecordOutlined sx={{ml: 2, width: 10,}} />,
            title: "Pending RFQs",
            to: "/PendingRFQs",
          },
          {
            // icon: <FiberManualRecordOutlined sx={{ml: 2, width: 10,}} />,
            title: "Pending Negotiations",
            to: "/PendingNegotiations",
          },
        ],
      },
      {
        // icon: <FiberManualRecordSharp sx={{ml: 2, width: 10,}} />,
        title: "Analytics",
        items: [
          {
            // icon: <FiberManualRecordOutlined sx={{ml: 2, width: 10,}} />,
            title: "Dashboard",
            to: "/dashboard",
          },
          {
            // icon: <FiberManualRecordOutlined sx={{ml: 2, width: 10,}} />,
            title: "Spend Analysis",
            to: "/SpendAnalysis",
          },
          {
            // icon: <FiberManualRecordOutlined sx={{ml: 2, width: 10,}} />,
            title: "R2I",
            to: "/r2i",
          },
          {
            // icon: <FiberManualRecordOutlined sx={{ml: 2, width: 10,}} />,
            title: "Audit Report",
            to: "/AuditReport",
          },
        ],
      },
    ],
  },
];
