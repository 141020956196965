import React, { Component } from "react";
import {
  Grid,
  Paper,
  Select,
  MenuItem,
  Card,
  CardContent,
  FormControl,
  Typography,
  Backdrop,
  CircularProgress,
  Button,
  Tooltip,
  Popover,
  InputLabel,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import "./style.css";
import ShowEmpty from "../ShowEmpty";
import { connect } from "react-redux";
import {
  setAvailableItem,
  setSelectedItem,
  unsetCheckValidTechnicalBidItems,
} from "../../redux/actions/itemActions";
import { getItemizedVendors } from "../../redux/actions/vendorActions";

import SendMailDialog from "../SendMailDialog/index";
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";
import AddItemDialog from "../Dialogs/BrowseItem/AddItem";
// import TBDialog from "../Dialogs/TBDialog/TBDialog";
import BrowseItem from "../Dialogs/BrowseItem/BrowseItem";
import AccountTemplate from "../AccountTemplate/AccountTemplate";
import CategoryVendors from "./Vendors/CategoryVendors";
import CategoryItem from "./Items/CategoryItem";
import ItemizeItems from "./Items/ItemizeItems";
import LinkItemsToCategory from "../Dialogs/LinkItemsToCategory";
import {
  getTemplates,
  getCategoryDetails,
  templateURi,
  devTemplateURi,
  linkUnlinkItem,
  activityTemplateListUri,
} from "../../config/apiUrl";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import LinkVendorsToItem from "../Dialogs/LinkVendorsToItem";
import "../../pages/style.css";
import CompanyToAccountImport from "../Dialogs/ComapanyToAccount/CompanyToAccountImport";
import AddVendor from "../Dialogs/AddVendor";
import { openSnackbar } from "../../redux/actions/snackbarAction";
import templateblack from "../../assets/vendxAdminPanelAsstes/assetsnew/template_blank.svg";
import ItemSearchPopper from "./ItemSearchPopper";
import { setSelectedVendors } from "../../redux/actions/vendorActions";
import LinkVendorToCategory from "../Dialogs/LinkVendorToCategory/Index";
import { PageContainerStyle } from "./style.js";
import ConfirmAlert from "../Dialogs/ConfirmAlert.js";
import IframeModel from "../Dialogs/BrowseItem/IframeModel";
import TBOptions from "../TechnicalBid/TBOptions";
import { removeItemFromCart } from "../Utils/removeItemFromCart";
import {
  handleItemsClick,
  handleItemsSelectAllClick,
  isItemSelected,
} from "../Utils/customSelectionMethods";
import _ from "lodash";
//import Template from '../../config/Template'
import PrLineItems from "../PRRFQ/PrLineItems";
import { setFileSpecification } from "../../redux/actions/categoryAction";
import {
  setIncludetbvalue,
  setRFQSettings,
  setActivityTemplate
} from "../../redux/actions/drawerActions";
import { validatePlant } from "../Utils/validatePlant";
import {
  setDisabledPrCategory,
  setPRselectedChange,
  setprcombinationItems,
  setEmptyPrCombination,
  setTemplateOptions,
  setJsonTemplates,
  setPlantCodes,
  setSelectedPrCategory,
  setPrLineItemsData,
  setPrCombinations,
  setDisplayOptions
} from "../../redux/actions/prrfqactions";
import { bindActionCreators } from "redux";
import ArrowDownIcon from '@mui/icons-material/ArrowDropDown';
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined';

class MainContent extends Component {
  state = {
    previously_selected_template: "",
    isDisabled: false,
    prevRFQ: [],
    prevRFQLoading: false,
    showSearchGird: false,
    item: "",
    clickedFirstTime: true,
    browseDialog: false,
    iframeDialog: false,
    mailDialog: false,
    addItemDialogOpen: false,
    linkUnlinkItemVendor: false,
    AddVendorDialogOpen: false,
    keyword: "",
    vendorsType: "category",
    CategoryVendorsKey: 0,
    ItemizeVendorsKey: 0,
    itmeizedVendorsItem: null,
    linkUnlinkItemOpen: false,
    companyToAccountOpen: false,
    template: "",
    activityTemplate: "",
    allowVendorFill:"",
    allowVendorFillUpdate : false,
    activityTemplateid: "",
    templateLoading: false,
    templateOptions: [],
    activityTemplateOptions: [],
    previousTemplate: null,
    jsonTemplate: null,
    jsonTemplateloading: false,
    currentVendorID: null,
    currentVendorName: null,
    accountTemplatKey: 1,
    openFromBrowsItem: false,
    linkVendorToCategoryOpen: false,
    clickfunction: function () {},
    cc_emails: "",
    confirmDialogOpen: false,
    loading: false,
    prevTemplateFetchedDone: false,
    templateTooltip: false,
    tb_attr: {},
    randomId: null,
    activity_randomId: null,
    freezeItem: false,
    includeTB: false,
    disabledtechnicalbid: false,
    itemizeTB: false,
    iframeDefaultLoading: false,
    checkedItems: [],
    categroyTBTemplate: {},
    categroyTBTemplatePreviousValue: {},
    TBItems: [],
    currentTBDetails: {
      currentAction: "",
      itemId: null,
      tb_id: null,
      pre_fetch_inquiry_id: null,
      oldItemId: null,
    },
    currentTBDetailsPR: {
      pr_details: "",
      item_pr_mapping: "",
    },
    defaultTBValuePermission: false,
    isCategoryTBUpdated: false,
    tbStageStatus: {}, // 0 key is used for category
    tb_data: {},
    activity_data: '',
    tbItemStatus: {},
    // checkedItems: [],
    setDeafaultRFQType: false,
    removerfqDialogOpen: false,
    setRfqFromPermission: true,
    confirmIncludeTB: false,
    action : ""
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.setState({ randomId: this.generateRandom(100000000, 900000000) });
    this.setState({ activity_randomId: this.generateRandom(100000000, 900000000) });
    this.addWindowMessageListener();
    //const queryParams = new URLSearchParams(this.props.location.search);
    this.fetchTempates();
    if(this.props.accountModulePermission["Service PR"]?.module_activated ==="Y"){
      this.fetchActivityTemplate();
    }
    if (window.location.search.indexOf("categoryID") == -1) {
      this.handleRfqType();
      // if (this.props.accountModulePermission["Not Allowed for Category Specific RFQ"]?.module_activated === "Y") {
      //   this.setState({
      //     vendorsType: "item",
      //     isDisabled: true
      //   })
      // } else {
      //   if (this.props.accountModulePermission["Itemize Vendor Specific RFQ"]?.module_activated === "Y") {
      //     if (this.props.featurePermission?.category_specific_rfq?.feature_value === "Y"){
      //       // set default value based on the feature-permission key
      //       this.setState({
      //         vendorsType: "category",
      //         isDisabled: false
      //       })
      //     } else {
      //       this.setState({
      //         vendorsType: "item",
      //         isDisabled: false
      //       })
      //     }
      //   } else {
      //     this.setState({
      //       vendorsType: "category",
      //       isDisabled: true
      //     })
      //   }
      // }
    }

    // if (this.props.accountModulePermission["Technical Bid"]?.module_activated !== "Y") {
    //   this.setState({
    //     includeTB: false
    //   })
    // }
    if (
      this.props.accountModulePermission["Technical Bid"]?.module_activated ===
        "Y" &&
      this.props.featurePermission?.category_specific_tb?.feature_value === "N"
    ) {
      this.setState({
        itemizeTB: true,
      });
    }

    // if (this.props.accountModulePermission["Technical Bid"]?.module_activated === "Y") {
    //   this.setState({
    //       includeTB: true
    //     })
    //     this.props.setIncludetbvalue(true);
    //   } else{
    //     this.setState({
    //       includeTB:false,
    //     })
    //     this.props.setIncludetbvalue(false);
    // }

    if (
      this.props.accountModulePermission["Technical Bid"]?.module_activated ===
        "Y" &&
      this.props.accountModulePermission["Technical Bid Default Values"]
        ?.module_activated === "Y" &&
      this.props.featurePermission?.tb_default_value?.feature_value === "Y"
    ) {
      this.setState({
        defaultTBValuePermission: true,
      });
    }

    if (this.props.rfqSettingValues?.template?.id !== undefined) {
      this.setState({
        template: JSON.stringify(this.props.rfqSettingValues.template),
      });
    }

    if (this.props.rfqSettingValues?.includeTechnicalBid === true) {
      this.setState({
        includeTB: true,
      });
    }
  }
  removedprlintemrelatedfiles = (removeitems) => {
    const { setpranditemvalue, fileSpecifications } = this.props;
    let temparray = [...fileSpecifications];

    if (setpranditemvalue != null && temparray.length > 0) {
      removeitems.forEach((items) => {
        const itemid = items.id;
        Object.keys(setpranditemvalue).forEach((prnumber) => {
          const items = Object.keys(setpranditemvalue[prnumber]);
          if (items.indexOf(itemid)) {
            const fileids = setpranditemvalue[prnumber][itemid];
            delete setpranditemvalue[prnumber][itemid];
            const items = Object.keys(setpranditemvalue[prnumber]);
            if (items.length == 0) {
              temparray = temparray.reduce((newfiles, file) => {
                if (fileids.indexOf(file.id.toString()) === -1) {
                  newfiles.push(file);
                  return newfiles;
                } else {
                  return newfiles;
                }
              }, []);
            }
          }
        });
      });
      this.props.setFileSpecification(temparray);
    }
  };
  plantSet = (data) => {
    this.props.setPlantCodes(data)
  }
  addWindowMessageListener = (event) => {
    window.addEventListener("message", (event) => {
      try {
        if (event.data) {
          let recievedDetails =
            typeof event.data == "object" ? event.data : JSON.parse(event.data);
          if (recievedDetails?.category_id) {
            if(this.state.currentTBDetails.currentAction === "activityImport"){
              if(recievedDetails.file_name != ""){
                let data = JSON.parse(recievedDetails.file_name);
                if( !data.service_error){
                  this.setState(
                    {
                      activity_data: recievedDetails.file_name,
                    }
                  );
                  var details  = recievedDetails.file_name;
                  try {
                    details = JSON.parse(details);
                    if(details?.postdata?.product_name){
                      this.setState({
                        freezeItem : true
                      })
                    }
                  } catch (error) {
                    console.log("error",error);
                  }
                }
              }
              this.setState(
                {
                  iframeDialog: false,
                }
              );
            }
            else{
              this.setState(
                {
                  tb_data: recievedDetails,
                },
                () => {
                  switch (this.state.currentTBDetails.currentAction) {
                    case "link":
                      {
                        this.setState({
                          isCategoryTBUpdated: false,
                        });
                      }
                      break;
                    case "linkItemizeTB":
                      {
                        // for updating the item TB details here.
                        this.setState({
                          tbItemStatus: {
                            ...this.state.tbItemStatus,
                            [this.state.currentTBDetails.itemId]: false,
                          },
                        });
                      }
                      break;
                    case "edit":
                      {
                        this.setState({
                          tbStageStatus: {
                            ...this.state.tbStageStatus,
                            [0]: this.state.tb_data.tb_stage,
                          },
                        });
                      }
                      break;
                    case "editItemizeTB":
                      {
                        this.setState({
                          tbStageStatus: {
                            ...this.state.tbStageStatus,
                            [this.state.currentTBDetails.itemId]:
                              this.state.tb_data.tb_stage,
                          },
                        });
                      }
                      break;
                    case "vendxRating":
                      {
                        window.location.reload();
                      }
                      break;
                    default:
                      console.log("No case matched...!");
                  }

                  if (this.state.tb_data?.close) {
                    this.setState({
                      iframeDialog: false,
                    });
                  }
                }
              );
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    });
  };

  setRfqTypeFromPermission = () => {
    if (
      this.props.accountModulePermission[
        "Not Allowed for Category Specific RFQ"
      ]?.module_activated === "Y"
    ) {
      this.setState({
        vendorsType: "item",
        isDisabled: true,
        setDeafaultRFQType: true,
      });
    } else {
      if (
        this.props.accountModulePermission["Itemize Vendor Specific RFQ"]
          ?.module_activated === "Y"
      ) {
        if (
          this.props.featurePermission?.category_specific_rfq?.feature_value ===
          "Y"
        ) {
          // set default value based on the feature-permission key
          this.setState({
            setDeafaultRFQType: true,
            vendorsType: "category",
            isDisabled: false,
          });
        } else {
          this.setState({
            setDeafaultRFQType: true,
            vendorsType: "item",
            isDisabled: false,
          });
        }
      } else {
        this.setState({
          setDeafaultRFQType: true,
          vendorsType: "category",
          isDisabled: true,
        });
      }
    }
  };
  setTemplate = () => {
    const { templateOptions } = this.state;
    const { previously_selected_template } = this.props.selectedCategory;
    if (templateOptions.length === 1) {
      this.handleTemplateChange(JSON.stringify(templateOptions[0]));
    } else if (this.props.defaultValues && this.props.defaultValues.template) {
      this.handleTemplateChange(
        JSON.stringify(this.props.defaultValues.template)
      );
    }
    // else if(previously_selected_template!=="" && previously_selected_template!=="0" ){
    else if (
      previously_selected_template != "" &&
      previously_selected_template !== "0" &&
      previously_selected_template !== 0
    ) {
      const id = previously_selected_template;
      templateOptions?.forEach((option) => {
        if (option.id === id) {
          this.handleTemplateChange(JSON.stringify(option));
        }
      });
    } else {
      this.handleTemplateChange();
      // this.handleTemplateChange(this.state.template);
    }
  };
  setDeafultLoading = (loading) => {
    this.setState({
      iframeDefaultLoading: loading,
    });
  };

  handleTBAction = (
    currentAction,
    itemId,
    oldItemId,
    tb_id,
    pre_fetch_inquiry_id
  ) => {
    // utilize this currentAction to determine the dialog action i.e add/edit

    // if last action !== current action then reset tb_data
    // else last item !== current item then reset tb_data
    var pr_details = {},item_pr_mapping = {};
    if (this.state.currentTBDetails?.currentAction !== currentAction) {
      this.setState({
        tb_data: {},
      });
    } else {
      if (this.state.currentTBDetails?.itemId !== itemId) {
        this.setState({
          tb_data: {},
        });
      }
    }
    if(currentAction == "activityImport" || currentAction == "activityPreview"){
      this.props.triggerTemplateIFrame(currentAction,tb_id,this.props.activityHash.pre_fetch_inquiry_id,0)
        if(currentAction == "activityImport"){
          itemId = [];
          let selectedItems = this.props.selectedItems;
          let selectedPR = this.props.pritems;
          selectedItems?.forEach((option) => {
            itemId.push(String(option.old_id));
            if(item_pr_mapping[option.old_id] === undefined || item_pr_mapping[option.old_id].length === 0 ){
              item_pr_mapping[option.old_id] = [];
            }
            if(window.location.pathname.indexOf("prcombination") !== -1){
              console.log(selectedPR,option);
              selectedPR[option.id].forEach((prItemData) => {
                item_pr_mapping[option.old_id].push(prItemData?.id);
                pr_details[prItemData?.id] = option.old_id;
              });
            }
          }); 
          var details  = this.state.activity_data;
          try {
            details = JSON.parse(details);
            if(details?.postdata?.activity_template_id !== tb_id){
              this.setState({ activity_randomId: this.generateRandom(100000000, 900000000) ,freezeItem : false,activity_data: ''});
            }
          } catch (error) {
            console.log("error",error);
          }
          pre_fetch_inquiry_id= this.state.activity_data;
          itemId = JSON.stringify(itemId);
          oldItemId = this.props.selectedCategory?.name;
          item_pr_mapping = JSON.stringify(item_pr_mapping);
          pr_details = JSON.stringify(pr_details);
        }
    }
    this.setState({
      iframeDialog: true,
      iframeDefaultLoading: true,
      currentTBDetails: {
        currentAction,
        itemId,
        tb_id,
        pre_fetch_inquiry_id,
        oldItemId,
      },
      currentTBDetailsPR: {
        pr_details,
        item_pr_mapping
      }
    });
  };

  removeIframeListener = () => {
    window.removeEventListener("message", function () {
      console.log("[REMOVED] Parent : Message Recieved..!");
    });
  };
  //  handleSubmittedData = ()=>{
  //   if(Object.keys(this.props.defaultValues).length !== 0){
  //     const {includeTB,itemizeTB,vendorsType,template} = this.props.defaultValues;
  //     this.setState({
  //       // includeTB,
  //       // itemizeTB,
  //       vendorsType,
  //     })
  //     if(this.state.previously_selected_template===""){
  //     this.setState({
  //       template
  //     })
  //     }
  //   }
  //   else{
  //     // this.setState({
  //     //   includeTB:false,
  //     //   itemizeTB:false,
  //     // })
  //    if (this.props.accountModulePermission["Not Allowed for Category Specific RFQ"]?.module_activated === "Y") {
  //         this.setState({
  //           vendorsType: "item",
  //           isDisabled: true,
  //           setDeafaultRFQType: true,
  //         })
  //       } else {
  //         if (this.props.accountModulePermission["Itemize Vendor Specific RFQ"]?.module_activated === "Y") {
  //           if (this.props.featurePermission?.category_specific_rfq?.feature_value === "Y"){
  //             // set default value based on the feature-permission key
  //             this.setState({
  //               setDeafaultRFQType: true,
  //               vendorsType: "category",
  //               isDisabled: false
  //             })
  //           } else {
  //             this.setState({
  //               setDeafaultRFQType: true,
  //               vendorsType: "item",
  //               isDisabled: false
  //             })
  //           }
  //         } else {
  //           this.setState({
  //             setDeafaultRFQType: true,
  //             vendorsType: "category",
  //             isDisabled: true
  //           })
  //         }
  //       }
  //   }
  //  }
  componentDidUpdate(prevProps) {
    if(this.props.accountModulePermission["Service PR"]?.module_activated ==="Y"){
      if(this.props.activityTemplateValues?.update === 1 && this.props.activityTemplateValues?.template_id > 0){
        let activityTemplateValues = this.props.activityTemplateValues;
        activityTemplateValues.update = 0;
        this.props.setActivityTemplate(activityTemplateValues);
        this.handleActivityTemplateChange(JSON.stringify(activityTemplateValues));
      }
      if(!this.props.rfqSettingValues.serviceRFQ && this.state.freezeItem){
        this.setState({
          freezeItem : false,
          activity_data: ''
        })
      }
      if(this.props.rfqSettingValues.allowVendorFill && (this.props.rfqSettingValues.allowVendorFillUpdate || ((this.state.allowVendorFill === "" || this.state.allowVendorFill === undefined )))){
        this.handleActivityTemplateVendorFill(this.props.rfqSettingValues.allowVendorFill);
      }
    }
    let RFqSettingValues = this.props.rfqSettingValues;
    if (
      RFqSettingValues.vendorMasterPaymentTerm !==
        prevProps.rfqSettingValues.vendorMasterPaymentTerm ||
      RFqSettingValues.vendorMasterIncoTerm !==
        prevProps.rfqSettingValues.vendorMasterIncoTerm
    ) {
      this.handleCategoryVendorsReload();
    }
    //setting apply to all values in current rfq
    if (
      Object.keys(this.props.selectedPrCategory).length > 0 &&
      _.isEqual(this.props.defaultValues, prevProps.defaultValues) === false
    ) {
      this.setState({
        vendorsType: this.props.defaultValues.rfqType,
        includeTB: this.props.defaultValues.includeTB,
        isCategoryTBUpdated: false,
      });
      if (this.state?.itemizeTB) {
        this.setState({
          TBItems: this.props.defaultValues.includeTB
            ? this.props.selectedItems
            : [],
        });
      }
      if (
        this.state.template === "" ||
        (this.props.defaultValues.template &&
          this.state.template !== "" &&
          this.props.defaultValues.template.name !==
            JSON.parse(this.state.template).name)
      ) {
        this.setTemplate();
      }
      if (
        (Object.keys(prevProps.defaultValues).length === 0 &&
          this.props.defaultValues?.selectAllVendors) ||
        (Object.keys(prevProps.defaultValues).length > 0 &&
          prevProps.defaultValues?.selectAllVendors !==
            this.props.defaultValues?.selectAllVendors)
      ) {
        this.handleCategoryVendorsReload();
      }
    }

    // let rfqSettingValues = this.props.rfqSettingValues;
    // if(
    //   this.props.accountModulePermission["Multiple Solutions"]
    //   ?.module_activated === "Y" &&
    //   this.props.featurePermission?.multiple_solutions_selection?.feature_value ===
    //   "Y"){
    //     rfqSettingValues.includeSlab = true;
    //     this.props.setRFQSettings(rfqSettingValues);
    // }
    ////setting values when rfq is changed
    if (
      Object.keys(this.props.selectedPrCategory).length > 0 &&
      _.isEqual(prevProps.pritems, this.props.pritems) ===
        false
    ) {
      //setting itemize tb from permission
      if (
        this.props.accountModulePermission["Technical Bid"]
          ?.module_activated === "Y" &&
        this.props.featurePermission?.category_specific_tb?.feature_value ===
          "N"
      ) {
        this.setState({
          itemizeTB: true,
        });
      } else {
        this.setState({
          itemizeTB: false,
        });
      }
      //setting include tb from permission when apply to all is not clicked
      if (Object.keys(this.props.defaultValues).length === 0) {
        this.setState({
          includeTB: false,
          TBItems: [],
          tbItemStatus: {},
        });
        if (
          this.props.accountModulePermission["TB Template Value Integration"]
            ?.module_activated === "Y" &&
          this.props.selectedPrCategory?.tb_flag == 1
        ) {
          this.setState({
            includeTB: true,
            itemizeTB: true,
            disabledtechnicalbid: true,
            TBItems: this.props.selectedItems,
          });
        }
        // else{
        //   this.setState({
        //     includeTB:false,
        //     // itemizeTB : false,
        //     // disabledtechnicalbid : false,
        //     // TBItems: []
        //   });
        //   this.props.setIncludetbvalue(false)
        //   this.props.unsetCheckValidTechnicalBidItems(this.props.selectedItems);
        // }
        this.setRfqTypeFromPermission();
      } else {
        this.setState({
          vendorsType: this.props.defaultValues.rfqType,
        });
      }
      // this.setState({
      // TBItems:[],
      // tbItemStatus:{}
      // })
      const { previously_selected_template, cc_emails } =
        this.props.selectedCategory;
      this.setState({
        isCategoryTBUpdated: false,
        tbStageStatus: {},
        previously_selected_template,
        cc_emails,
      });
      // this.handleSubmittedData();
      this.handleCategoryVendorsReload();
      this.setTemplate();
      this.handleActivityTemplateVendorFill();
      var activity_data_default = {
        template_id: "",
        template_name:"",
        display_name:"",
        template_code:"",
        update: 0
      };
      let rfqSettingValues = this.props.rfqSettingValues;
      rfqSettingValues.allowVendorFillUpdate = true;
      this.props.setRFQSettings(rfqSettingValues);
      let activityTemplateValues = this.props.activityTemplateValues;
      activityTemplateValues.update = 1;
      this.props.setActivityTemplate(activityTemplateValues);
      this.setState({ activity_randomId: this.generateRandom(100000000, 900000000),activity_data: '',freezeItem: false });
      this.handleActivityTemplateChange(JSON.stringify(activity_data_default),true);
      // if(RFqSettingValues.template !== undefined &&
      //   prevProps.rfqSettingValues.template?.id !==
      //   RFqSettingValues.template?.id) {
      //     this.handleTemplateChange(JSON.stringify(RFqSettingValues.template));
      //     this.setState({template:JSON.stringify(RFqSettingValues.template)})
      // }
      // else  if(this.state?.templateOptions.length===1){
      //   this.handleTemplateChange(JSON.stringify(this.state.templateOptions[0]));
      // } else if(Object.keys(this.props.defaultValues).length > 0 && this.props.defaultValues.template!==""){
      //   this.handleTemplateChange(JSON.stringify(this.props.defaultValues.template));
      // }
      // else if(previously_selected_template!==""){
      //   const id = previously_selected_template;
      //   this.state.templateOptions?.forEach((option) => {
      //     if (option.id === id) {
      //       console.log("template change")
      //         this.handleTemplateChange(JSON.stringify(option))
      //       return;
      //     }
      //   });
      // }
      // else{
      //   console.log("template change")
      //   this.handleTemplateChange();
      //   // this.handleTemplateChange(this.state.template);
      // }

      // this.setState((prevstate) => ({accountTemplatKey :prevstate.accountTemplatKey + 1 }))
    }
    if (Object.keys(this.props.defaultValues).length !== 0) {
      if (
        _.isEqual(prevProps.selectedItems, this.props.selectedItems) === false
      ) {
        this.setState({
          includeTB: this.props.defaultValues.includeTB,
        });
        if (this.state?.itemizeTB) {
          this.setState({
            TBItems: this.props.defaultValues.includeTB
              ? this.props.selectedItems
              : [],
          });
        }
      }
    }
    if (
      this.props.selectedExcelTemplate &&
      Object.keys(this.props.selectedExcelTemplate).length !== 0 &&
      !this.state.template
    ) {
      this.setState(
        {
          template: JSON.stringify(this.props.selectedExcelTemplate),
          fromExcel: 1,
        },
        () => {
          if (this.state.template) {
            this.fetchJSONTemplate();
          }
        }
      );
    } 
    if (prevProps.rfqType !== this.props.rfqtype && Object.keys(this.props.ImportExcelRfq).length != 0) {
      const newVendorsType =
        this.props.rfqtype === "Category Specific RFQ" ? "category" : "item";

      if (
        this.state.vendorsType !== newVendorsType &&
        this.state.action == ""
      ) {
        this.setState({
          vendorsType: newVendorsType,
        });
      }
    }
  }
  handleRfqType = () => {
    if (
      this.props.accountModulePermission[
        "Not Allowed for Category Specific RFQ"
      ]?.module_activated === "Y"
    ) {
      this.setState({
        vendorsType: "item",
        isDisabled: true,
      });
    } else {
      if (
        this.props.accountModulePermission["Itemize Vendor Specific RFQ"]
          ?.module_activated === "Y"
      ) {
        if (
          this.props.featurePermission?.category_specific_rfq?.feature_value ===
          "Y"
        ) {
          // set default value based on the feature-permission key
          this.setState({
            vendorsType: "category",
            isDisabled: false,
          });
        } else {
          this.setState({
            vendorsType: "item",
            isDisabled: false,
          });
        }
      } else {
        this.setState({
          vendorsType: "category",
          isDisabled: true,
        });
      }
    }
  };
  UNSAFE_componentWillReceiveProps(props) {
    if (
      Object.keys(this.props.accountModulePermission).length !== 0 &&
      window.location.search.indexOf("categoryID") !== -1
    ) {
      if (this.state.setRfqFromPermission === true) {
        this.handleRfqType();
        this.setState({
          setRfqFromPermission: false,
        });
      }
    }
    if (props.selectedItems?.length) {
      if (_.isEqual(props.selectedItems, this.props.selectedItems) === false) {
        this.props.unsetCheckValidTechnicalBidItems(this.props.selectedItems);
      }
      if (
        this.props.accountModulePermission["TB Template Value Integration"]
          ?.module_activated === "Y" &&
        this.props.selectedPrCategory?.tb_flag == 1
      ) {
        this.setState({
          includeTB: true,
          itemizeTB: true,
          disabledtechnicalbid: true,
          TBItems: this.props.selectedItems,
        });
      } else {
        this.setState({
          disabledtechnicalbid: false,
        });
      }
      if (
        !this.state.template &&
        !this.state.prevTemplateFetchedDone &&
        Object.keys(this.props.ImportExcelRfq).length == 0
      ) {
        this.getCategoryInfo();
      }
    } else {
      this.setState({
        prevTemplateFetchedDone: false,
      });
      if (props.selectedVendors?.length) {
        this.props.setSelectedVendors([]);
      }
    }
  }

  handleOpen = () => {
    this.props.setAvailableItem(
      this.props.selectedCategory,
      1,
      15,
      this.props.sessionId
    );
    this.setState({
      browseDialog: true,
      showSearchGird: false,
    });
  };

  handleClose = () => {
    this.setState({
      browseDialog: false,
    });
  };

  handleMailDialogClose = () => {
    this.setState({
      mailDialog: false,
    });
  };

  addItemDialogOnClose = () => {
    this.setState({
      addItemDialogOpen: false,
    });
  };

  addItemDialogOnOpen = (openFromBrowsItem = false) => {
    this.setState({
      openFromBrowsItem,
      addItemDialogOpen: true,
    });
  };

  handleCompanyToAccountOpen = () => {
    this.setState({
      companyToAccountOpen: true,
    });
  };

  handleTBChanges = (e) => {
    const { name, value, type, checked } = e.target;
    if (window.location.pathname.indexOf("prcombination") !== -1) {
      this.setState({
        [name]: type === "checkbox" ? checked : value,
      });
      if (name === "includeTB") {
        this.props.setIncludetbvalue(checked);
      }
    } else {
      if (name !== "includeTB") {
        this.setState({
          [name]: type === "checkbox" ? checked : value,
        });
      }
      if (name === "includeTB") {
        if (
          checked &&
          this.props.featurePermission.Multiplant_RFQ.feature_value === "Y"
        ) {
          this.setState({
            confirmIncludeTB: true,
          });
        } else {
          this.setState({
            includeTB: checked,
          });
          this.props.setIncludetbvalue(checked);
        }
      }
    }
    if (this.state?.itemizeTB && name === "includeTB") {
      this.setState({
        TBItems: checked ? this.props.selectedItems : [],
      });
    }

    if (name === "itemizeTB") {
      this.setState({
        TBItems: checked ? this.props.selectedItems : [],
      });
    }
  };

  handleCompanyToAccountClose = () => {
    this.setState({
      companyToAccountOpen: false,
    });
  };

  handleMainItemClick = (item) => {
    this.setState(
      {
        itmeizedVendorsItem: item,
      },
      () => {
        if (this.state.itmeizedVendorsItem) {
          //fetch new vendors for the selected item
          this.props.getItemizedVendors(
            item,
            this.props.selectedCategory?.id,
            1,
            this.props.sessionId
          );
        }
      }
    );
  };

  handleOpenLinkUnlinkItem = (openFromBrowsItem = false) => {
    this.setState({
      openFromBrowsItem,
      linkUnlinkItemOpen: true,
    });
  };

  handleCloseLinkUnlinkItem = () => {
    this.setState({
      linkUnlinkItemOpen: false,
    });
  };

  handleItemVendorLinkOpen = (openFromBrowsItem = false) => {
    this.setState({
      openFromBrowsItem,
      linkUnlinkItemVendor: true,
    });
  };

  handleItemVendorLinkClsoe = () => {
    this.setState({
      linkUnlinkItemVendor: false,
    });
  };

  handlelinkVendorToCategoryOpen = () => {
    this.setState({
      linkVendorToCategoryOpen: true,
    });
  };

  handleAddVendorDialogOpen = (vendorID, vendorName) => {
    this.setState({
      AddVendorDialogOpen: true,
      currentVendorID: vendorID,
      currentVendorName: vendorName,
    });
  };

  handleAddVendorDialogClsoe = () => {
    this.setState({
      AddVendorDialogOpen: false,
    });
  };

  handleCategoryVendorsReload = () => {
    this.setState({
      CategoryVendorsKey: this.state.CategoryVendorsKey + 1,
    });
    this.props.setSelectedVendors([]);
  };

  handleItemizeVendorsReload = () => {
    this.setState({
      ItemizeVendorsKey: this.state.ItemizeVendorsKey + 1,
    });
  };

  fetchTempates = async () => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;
    this.setState({
      templateLoading: true,
    });
    await axios
      .get(getTemplates)
      .then((res) => {
        // this.props.setEmptyPrCombination(false);
        //set template options in redux
        this.props.setTemplateOptions(res.data.data);
        this.setState({
          templateOptions: res.data.data,
          templateLoading: false,
        });
      })
      .catch((err) => {
        this.props.setEmptyPrCombination(false);
        this.setState({
          templateLoading: false,
        });
        this.props.openSnackbar("error", err.response.data.message);
      });
  };

  fetchActivityTemplate = async () => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;
    this.setState({
      templateLoading: true,
    });
    await axios
      .get(activityTemplateListUri)
      .then((res) => {
        this.setState({
          activityTemplateOptions: res.data.data,
          templateLoading: false,
        });
        if(!res.data.data?.length){
          this.props.openSnackbar("error", `No ${this.props.displayOptions.service_label} Templates Found`);
        }
      })
      .catch((err) => {
        this.props.setEmptyPrCombination(false);
        this.setState({
          templateLoading: false,
        });
        this.props.openSnackbar("error", err.response.data.message);
      });
  };
  
  getCategoryInfo = () => {
    this.setState({
      prevTemplateFetchedDone: true,
    });
    const url = getCategoryDetails(this.props.selectedCategory.id);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;
    this.setState({
      loading: true,
    });
    axios
      .get(url)
      .then((res) => {
        this.setState({
          previously_selected_template:
            res.data.data.previously_selected_template,
          cc_emails: res.data.data.cc_emails,
          loading: false,
        });
        if (this.state?.templateOptions.length === 1) {
          this.handleTemplateChange(
            JSON.stringify(this.state.templateOptions[0])
          );
        } else if (
          res.data.data.previously_selected_template
          // && this.props.selectedPrCategory?.category === undefined
        ) {
          let id = res.data.data.previously_selected_template;
          this.state.templateOptions?.forEach((option) => {
            if (option.id === id) {
              this.setState(
                {
                  template: JSON.stringify(option),
                  jsonTemplateloading: true,
                },
                () => {
                  this.fetchJSONTemplate();
                }
              );
              return;
            }
          });
        }
      })
      .catch((err) => {
        this.props.openSnackbar("error", err.response.data.message);
        this.setState({
          loading: false,
        });
        console.log(err, "category detials ajax error");
      });
  };

  handleTemplateChange = (evt = "") => {
    this.setState(
      {
        template: evt,
        jsonTemplateloading: true,
        accountTemplatKey: this.state.accountTemplatKey + 1,
        jsonTemplate: null,
      },
      () => {
        if (this.state.template) {
          this.fetchJSONTemplate();
        } else {
          this.setState({
            jsonTemplateloading: false,
          });
        }
      }
    );
  };
  handleActivityTemplateChange = (evt = "",isDefault = false) => {
    let data = JSON.parse(evt);
    let hash = {};
    this.state.activityTemplateOptions.map(
      (option, index) => {
        // console.log(option);
        if(option.template_id == data.template_id){
          data = option;
        }else if(option.template_id == data.id){
          data = option;
        }
      });
    hash = {
      'name' : data.display_name,
      'id' : data.template_id
    }
    delete data.update;
    if(this.state.activityTemplateid !== data.template_id){
      this.setState({ activity_randomId: this.generateRandom(100000000, 900000000)});
    }
    this.setState(
      {
        activityTemplate: (isDefault) ? "" : JSON.stringify(hash),
        activityTemplateid : (isDefault) ? "" : data.template_id, 
        activity_data : "",
        freezeItem : false
      }
    );
    if (window.location.pathname.indexOf("prcombination") === -1){
        setTimeout(() => {
          let activityTemplateValues = this.props.activityTemplateValues;
          activityTemplateValues = data;
          activityTemplateValues.update = 0;
          this.props.setActivityTemplate(activityTemplateValues);
        },800);
    }
  };
  handleActivityTemplateVendorFill = (evt = "") => {
    this.setState(
      {
        allowVendorFill: evt,
        allowVendorFillUpdate: (evt === "") ? true :false
      }
    );
    let rfqSettingValues = this.props.rfqSettingValues;
    if (window.location.pathname.indexOf("prcombination") === -1){
      rfqSettingValues.allowVendorFill = evt;
    }
    rfqSettingValues.allowVendorFillUpdate = false;
    this.props.setRFQSettings(rfqSettingValues);
  };

  fetchJSONTemplate = () => {
    let id = JSON.parse(this.state.template).id;
    let jsonTemplates = this.props.jsonTemplates;
    //getting template options data from redux if it is available else calling api
    if (
      Object.keys(jsonTemplates).length !== 0 &&
      Object.keys(jsonTemplates).some((val) => val === id)
    ) {
      // this.setState({
      //   jsonTemplate:jsonTemplates[id],
      //   jsonTemplateloading: false
      // });
      if (this.props.prplants.length) {
        validatePlant(this, jsonTemplates[id]);
      } else {
        this.setState({
          jsonTemplate: jsonTemplates[id].data,
          jsonTemplateloading: false,
        });
      }
    } else {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.sessionId}`;

      let batch_id = JSON.parse(this.state.template).batch_id;
      let templateURL = "";
      if (process.env.NODE_ENV === "production") {
        console.log("production mode");
        templateURL = templateURi(
          this.props.userInfo.division_id,
          this.props.companyInfo.display_name,
          id,
          batch_id
        );
      } else {
        templateURL = devTemplateURi(
          this.props.userInfo.division_id,
          this.props.companyInfo.display_name,
          id,
          batch_id
        );
      }
            axios
        .get(templateURL)
        .then((response) => {
          this.props.setJsonTemplates({ [id]: response });
          if (this.props.prplants.length) {
            validatePlant(this, response);
          } else {
            this.setState({
              jsonTemplate: response.data,
              jsonTemplateloading: false,
            });
          }
          console.log(this.state.jsonTemplate);
        })
        .catch((err) => {
                    console.log(err, "main json err");
          this.props.openSnackbar("error", err.response.data.message);
          this.setState({
            jsonTemplateloading: false,
            jsonTemplate: null,
          });
        });
    }
  };

  allItemsHasVendors = () => {
    let result = true;
    this.props.selectedItems.forEach((item) => {
      if (!item.vendor_count.total_count) {
        result = false;
      }
    });
    return result;
  };

  generateRandom = (min, max) => {
    const timestring = new Date().getTime();
    const numstring = Math.floor(Math.random() * (max - min + 1)) + min;
    return timestring + "." + numstring;
  };

  updateCartItemsDetails = () => {
    // const url = postItems;
    if (this.props.selectedItems?.length) {
      this.setState({
        loading: true,
      });
      let params = {
        pagination: false,
        id: this.props.selectedItems.map((item) => item.id),
        prlineitems_ids : []
      };
      if (
        this.props.accountModulePermission[
          "Plant Specific Categroy Item Vendor Linking"
        ].module_activated === "Y"
      ) {
        if (
          this.props.selectedCategory?.id ===
          this.props.selectedPrCategory?.category?.id
        ) {
          this.props.selectedItems.forEach((item) => {
            if (
              item.hasOwnProperty("prlineitems_ids")
            ) {

              params.prlineitems_ids = params.prlineitems_ids.concat(item.prlineitems_ids);
            }
          });
          params.plant_code = this.props.selectedPrCategory.plant.code;
        }
      }
      // let url = linkUnlinkItem(this.props.selectedCategory.id);
      axios
        .get(linkUnlinkItem(this.props.selectedCategory.id), {
          params,
        })
        .then((response) => {
          response.data.data.map((items, index) => {
            this.props.selectedItems.forEach((item) => {
              if (
                item.id === items.id &&
                item.hasOwnProperty("prlineitems_ids")
              ) {
                items["prlineitems_ids"] = item.prlineitems_ids;
              }
            });
          });
          this.props.setSelectedItem(response.data.data);
          this.setState({
            loading: false,
            checkedItems: [],
          });
        })
        .catch((err) => {
          this.props.openSnackbar("error", err.response.data.message);
          console.log(err);
          this.setState({
            loading: false,
          });
        });
    }


  };

  updateTechnicalBidItems = (action) => {
    let newSelecteds = [];
    let { checkedItems, TBItems } = this.state;

    if (checkedItems.length == 0) {
      this.props.openSnackbar("error", "Please select item(s) first.");
      return;
    }

    if (action === "enable") {
      let finalRows = [...checkedItems, ...TBItems];
      newSelecteds = this.uniqueFromArray(finalRows);
    } else {
      newSelecteds = TBItems.filter(
        (el) => !this.state.checkedItems.map((ob) => ob.id).includes(el.id)
      );
      this.props.unsetCheckValidTechnicalBidItems(this.state.checkedItems);
    }
    this.setState({
      TBItems: newSelecteds,
    });
  };

  uniqueFromArray = (finalRows) => {
    let jsonObject = finalRows.map(JSON.stringify);
    let uniqueSet = new Set(jsonObject);
    return Array.from(uniqueSet).map(JSON.parse);
  };

  addTBItems = (items) => {
    let newSelecteds = this.uniqueFromArray([...this.state.TBItems, ...items]);
    this.setState({
      TBItems: newSelecteds,
    });
  };

  addItemTB = (item) => {
    let newSelecteds = [...this.state.TBItems];
    if (!this.state.TBItems.some((cv) => cv.id === item.id)) {
      newSelecteds.push(item);
    }
    this.setState({
      TBItems: newSelecteds,
    });
  };

  removeItemTB = (item) => {
    let newSelecteds = [...this.state.TBItems];
    let filterdTBItems = newSelecteds.filter((i) => i.id !== item.id);
    this.props.unsetCheckValidTechnicalBidItems([item]);
    this.setState({
      TBItems: filterdTBItems,
    });
  };
  setprcombinationItemsandremoveitems = (
    updatedSelectedItems,
    removedItems
  ) => {
    const removedrows =
      this.props.selectedprcombination?.["removed_items"] || [];
    let finalRows = [...removedrows, ...removedItems];
    let jsonObject = finalRows.map(JSON.stringify);
    let uniqueSet = new Set(jsonObject);
    let uniqueRows = Array.from(uniqueSet).map(JSON.parse);

    this.props.setprcombinationItems(updatedSelectedItems, uniqueRows);
  };


  handlePopoverOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handlePopoverClose = () => {
    this.setState({
 
anchorEl: null });
  };
  setPrlineItmesPR = (data) => {
    this.props.setPrLineItemsData(data)
  }
  // setPritemObj =  (data) => {
  //   // this.props.setPrLineItemsData(data)
  // }
  render() {
    const { anchorEl } = this.state;
    if(this.props.activityHash?.allowOpen){
      this.props.triggerTemplateIFrame(this.props.activityHash.action,this.props.activityHash.tb_id,this.props.activityHash.pre_fetch_inquiry_id,0)
      this.handleTBAction(this.props.activityHash.action,null,null,this.props.activityHash.tb_id,this.props.activityHash.pre_fetch_inquiry_id)
    }
    return (
      <React.Fragment>
        {PageContainerStyle}
        {this.props.prlineItemDialog ? (
          <PrLineItems prOpen={this.props.prlineItemDialog}></PrLineItems>
        ) : null}

        <div className="page_container">
          <Backdrop
            style={{ color: "#fff", zIndex: "10000000000" }}
            open={this.state.loading || this.state.jsonTemplateloading}>
            <CircularProgress style={{ color: "#fff" }} />
          </Backdrop>

          {this.state.iframeDialog && (
            <IframeModel
              sessionId={this.props.sessionId}
              categoryId={this.props.selectedCategory?.old_id}
              randomId={this.state.randomId}
              activity_randomId={this.state.activity_randomId}
              open={this.state.iframeDialog}
              setDeafultLoading={this.setDeafultLoading}
              iframeDefaultLoading={this.state.iframeDefaultLoading}
              categroyTBTemplatePreviousValue={
                this.state.categroyTBTemplatePreviousValue
              }
              handleClose={() => {
                this.setState({
                  iframeDialog: false,
                });
              }}
              currentTBDetails={this.state.currentTBDetails}
              currentTBDetailsPR={this.state.currentTBDetailsPR}
              tb_data={this.state.tb_data}
              tbStageStatus={this.state.tbStageStatus}
              defaultTBValuePermission={this.state.defaultTBValuePermission}
              pr_has_tb={this.props.selectedPrCategory?.tb_flag}
            />
          )}
          {this.state.addItemDialogOpen && (
            <AddItemDialog
              open={this.state.addItemDialogOpen}
              handleClose={() => {
                this.addItemDialogOnClose();
                if (this.state.openFromBrowsItem) {
                  this.handleOpen();
                }
              }}
            />
          )}

          {/* {this.state.TBDialog && (
          <TBDialog
            open={this.state.TBDialog}
            handleClose={() => {
              this.setState({
                TBDialog: false
              })
            }}
          />
        )} */}

          {this.state.companyToAccountOpen && (
            <CompanyToAccountImport
              open={this.state.companyToAccountOpen}
              handleClose={this.handleCompanyToAccountClose}
              handleAddVendorDialogOpen={this.handleAddVendorDialogOpen}
              handleVendorsReload={
                this.state.vendorsType === "category"
                  ? this.handleCategoryVendorsReload
                  : this.handleItemizeVendorsReload
              }
            />
          )}
          {this.state.linkUnlinkItemOpen && (
            <LinkItemsToCategory
              open={this.state.linkUnlinkItemOpen}
              handleClose={() => {
                this.handleCloseLinkUnlinkItem();
                if (this.state.openFromBrowsItem) {
                  this.handleOpen();
                }
              }}
            />
          )}
          {this.state.browseDialog &&
            (window.location.pathname.indexOf("prcombination") !== -1 ? (
              <BrowseItem
                master_type={this.state.vendorsType}
                open={this.state.browseDialog}
                handleClose={this.handleClose}
                prcombination="true"
                location={this.props.location}
                prItems = {this.props.pritems}
                pr_dropdown_values={this.props.pr_dropdown_values}
                selectedPrCategory={this.props.selectedPrCategory}
                setCatgoryMainObj={this.setCatgoryMainObj}
                prCombinations={this.props.prCombinations}
                selctedIndex={this.props.selctedIndex}

              />
            ) : (
              <BrowseItem
                master_type={this.state.vendorsType}
                open={this.state.browseDialog}
                handleClose={this.handleClose}
                openAddItemDialog={() => {
                  this.addItemDialogOnOpen(true);
                }}
                handleOpenLinkUnlinkItem={() => {
                  this.handleOpenLinkUnlinkItem(true);
                }}
                handleItemVendorLinkOpen={() =>
                  this.handleItemVendorLinkOpen(true)
                }
                addTBItems={this.addTBItems}
                includeTB={this.state.includeTB}
                itemizeTB={this.state.itemizeTB}
              />
            ))}
          <SendMailDialog
            open={this.state.mailDialog}
            handleMailDialogClose={this.handleMailDialogClose}
          />
          {this.state.linkUnlinkItemVendor && (
            <LinkVendorsToItem
              updateCartItemsDetails={this.updateCartItemsDetails}
              open={this.state.linkUnlinkItemVendor}
              handleItemizeVendorsReload={this.handleItemizeVendorsReload}
              cartCheckedItems={this.state.checkedItems}
              handleClose={() => {
                this.handleItemVendorLinkClsoe();
                if (this.state.openFromBrowsItem) {
                  this.handleOpen();
                }
              }}
            />
          )}
          {this.state.linkVendorToCategoryOpen && (
            <LinkVendorToCategory
              open={this.state.linkVendorToCategoryOpen}
              handleCategoryVendorsReload={this.handleCategoryVendorsReload}
              updateCartItemsDetails={this.updateCartItemsDetails}
              master_type={this.state.vendorsType}
              handleClose={() => {
                this.setState({
                  linkVendorToCategoryOpen: false,
                });
              }}
              isVendorSelected={!!this.props.selectedVendors?.length}
            />
          )}
          {this.state.AddVendorDialogOpen && (
            <AddVendor
              open={this.state.AddVendorDialogOpen}
              currentVendorID={this.state.currentVendorID}
              currentVendorName={this.state.currentVendorName}
              handleClose={this.handleAddVendorDialogClsoe}
              handleVendorsReload={
                this.state.vendorsType === "category"
                  ? this.handleCategoryVendorsReload
                  : this.handleItemizeVendorsReload
              }
              isVendorSelected={!!this.props.selectedVendors?.length}
            />
          )}
          <Grid container>
            <Grid item lg={12} md={12} sm={12}>
              <Box
                className="cart-section"
                sx={{
                  border: (theme) => `1px solid ${theme.palette.bluegrey[500]}`,
                  borderRadius: "4px",
                }}>
                <Paper className="card_height">
                  <Box
                    sx={{
                      borderBottom: (theme) =>
                        `1px solid ${theme.palette.bluegrey[500]}`,
                    }}>
                    <Grid container className="card_content">
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        {window.location.pathname.indexOf("prcombination") ==
                        -1 ? (
                          <div className="item-search-wrap">
                            <ItemSearchPopper
                              search_disable={this.state.freezeItem}
                              openAddItemDialog={this.addItemDialogOnOpen}
                              handleOpenLinkUnlinkItem={
                                this.handleOpenLinkUnlinkItem
                              }
                              master_type={this.state.vendorsType}
                            />

                            <Button
                              className="browse-item-btn"
                              onClick={() => {
                                this.setState({
                                  browseDialog: true,
                                  showSearchGird: false,
                                });
                              }}
                              disabled={this.state.freezeItem}
                              startIcon={<OpenInBrowserIcon />}>
                              <Box
                                sx={{
                                  display: {
                                    xs: "block",
                                    xs: "none",
                                    sm: "none",
                                    md: "block",
                                    marginTop: "2px",
                                  },
                                }}>
                                Browse Linked Items
                              </Box>
                            </Button>
                          </div>
                        ) : (
                          <div
                            className="item-search-wrap"
                            style={{ height: "100%" }}>
                            <Typography
                              sx={{
                                fontWeight: "700",
                              }}>
                              VENDOR SELECT
                            </Typography>

                            {this.props?.selectedprcombination?.removed_items
                              ?.length > 0 && (
                              <Button
                                className="browse-item-btn"
                                onClick={() => {
                                  this.setState({
                                    browseDialog: true,
                                    showSearchGird: false,
                                  });
                                }}
                                startIcon={<OpenInBrowserIcon />}>
                                <Box
                                  sx={{
                                    display: {
                                      xs: "block",
                                      xs: "none",
                                      sm: "none",
                                      md: "block",
                                      marginTop: "2px",
                                    },
                                  }}>
                                  Browse deleted items
                                </Box>
                              </Button>
                            )}
                          </div>
                        )}
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Grid
                          container
                          spacing={2}
                          style={{
                            justifyContent: "end",
                            justifyContent: "flex-end",
                          }}>
                          {this.props.accountModulePermission["Technical Bid"]
                            ?.module_activated === "Y" &&
                            this.props.selectedCategory && (
                              <Grid item lg={"auto"} md={"auto"} sm={6} xs={12}>
                                <TBOptions
                                  tbStage={this.state.tbStageStatus[0]}
                                  setIsUpdated={(newVal) =>
                                    this.setState({
                                      isCategoryTBUpdated: newVal,
                                    })
                                  }
                                  location={this.props.location}
                                  isUpdated={this.state.isCategoryTBUpdated}
                                  handleTBAction={this.handleTBAction}
                                  selectedCategory={this.props.selectedCategory}
                                  includeTB={this.state.includeTB}
                                  disabledtechnicalbid={
                                    this.state.disabledtechnicalbid
                                  }
                                  categroyTBTemplate={
                                    this.state.categroyTBTemplate
                                  }
                                  itemizeTB={this.state.itemizeTB}
                                  handleChange={this.handleTBChanges}
                                  defaultTBValuePermission={
                                    this.state.defaultTBValuePermission
                                  }
                                />
                              </Grid>
                            )}
                          <Grid item lg={"auto"} md={"auto"} sm={6} xs={12}>
                            <Select
                              fullWidth
                              size="small"
                              variant="outlined"
                              value={this.state.vendorsType}
                              // open={false}
                              disabled={this.state.isDisabled}
                              onChange={(e) => {
                                // add logic here to check the items with no vendor exist or not
                                this.props.setSelectedVendors([]);
                                this.setState({
                                  vendorsType: e.target.value,
                                  action : "onChange"
                                });
                                // if (
                                //   (e.target.value === "item" &&
                                //     this.allItemsHasVendors()) ||
                                //   e.target.value === "category"
                                // ) {
                                //   this.props.setSelectedVendors([]);
                                //   this.setState({
                                //     vendorsType: e.target.value,
                                //   });
                                // } else {
                                //   this.setState({
                                //     confirmDialogOpen: true,
                                //   });
                                // }
                              }}>
                              <MenuItem value="category">
                                Category Specific RFQ
                              </MenuItem>
                              <MenuItem value="item">
                                Item Specific RFQ
                              </MenuItem>
                            </Select>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>

                  {!this.props.selectedItems.length ? (
                    <ShowEmpty />
                  ) : (
                    <div className="card_inner_content">
                      <Grid container>
                        <Grid
                          item
                          xl={this.state.vendorsType === "category" ? 4 : 12}
                          lg={this.state.vendorsType === "category" ? 4 : 12}
                          md={this.state.vendorsType === "category" ? 6 : 12}
                          sm={12}
                          xs={12}
                          style={{ zIndex: 2 }}>
                          {this.state.vendorsType === "category" ? (
                            <div className="card_left_content">
                              <CategoryItem
                                key={this.props.categoryIndex}
                                tbStageStatus={this.state.tbStageStatus}
                                tbItemStatus={this.state.tbItemStatus}
                                search_disable={this.state.freezeItem}
                                setIsUpdated={(itemId, newVal) => {
                                  this.setState({
                                    tbItemStatus: {
                                      ...this.state.tbItemStatus,
                                      [itemId]: newVal,
                                    },
                                  });
                                }}
                                openAddItem={this.addItemDialogOnOpen}
                                checkedItems={this.state.checkedItems}
                                removeItemFromCart={() =>
                                  removeItemFromCart(this)
                                }
                                handleItemsSelectAllClick={(event) =>
                                  handleItemsSelectAllClick(this, event)
                                }
                                handleItemsClick={(row) =>
                                  handleItemsClick(this, row)
                                }
                                isItemSelected={(row) =>
                                  isItemSelected(this, row)
                                }
                                handleOpenLinkUnlinkItem={
                                  this.handleOpenLinkUnlinkItem
                                }
                                defaultTBValuePermission={
                                  this.state.defaultTBValuePermission
                                }
                                includeTB={this.state.includeTB}
                                itemizeTB={this.state.itemizeTB}
                                handleAction={this.handleTBAction}
                                updateTechnicalBidItems={
                                  this.updateTechnicalBidItems
                                }
                                addItemTB={this.addItemTB}
                                removeItemTB={this.removeItemTB}
                                TBItems={this.state.TBItems}
                                disabledtechnicalbid={
                                  this.state.disabledtechnicalbid
                                }
                                TBTemplateValueIntegration={
                                  this.props.accountModulePermission[
                                    "TB Template Value Integration"
                                  ]?.module_activated
                                }
                                pr_has_tb={
                                  this.props.selectedPrCategory?.tb_flag
                                }
                              />
                            </div>
                          ) : (
                            <ItemizeItems
                              tbStageStatus={this.state.tbStageStatus}
                              tbItemStatus={this.state.tbItemStatus}
                              setIsUpdated={(itemId, newVal) => {
                                this.setState({
                                  tbItemStatus: {
                                    ...this.state.tbItemStatus,
                                    [itemId]: newVal,
                                  },
                                });
                              }}
                              checkedItems={this.state.checkedItems}
                              updateCartItemsDetails={
                                this.updateCartItemsDetails
                              }
                              ItemizeVendorsKey={this.state.ItemizeVendorsKey}
                              removeItemFromCart={() =>
                                removeItemFromCart(this)
                              }
                              handleItemsSelectAllClick={(event) =>
                                handleItemsSelectAllClick(this, event)
                              }
                              handleItemsClick={(row) =>
                                handleItemsClick(this, row)
                              }
                              isItemSelected={(row) =>
                                isItemSelected(this, row)
                              }
                              handleItemVendorLinkOpen={
                                this.handleItemVendorLinkOpen
                              }
                              defaultTBValuePermission={
                                this.state.defaultTBValuePermission
                              }
                              openAddItem={this.addItemDialogOnOpen}
                              itmeizedVendorsItem={
                                this.state.itmeizedVendorsItem
                              }
                              handleMainItemClick={this.handleMainItemClick}
                              handleOpenLinkUnlinkItem={
                                this.handleOpenLinkUnlinkItem
                              }
                              handlelinkVendorToCategoryOpen={
                                this.handlelinkVendorToCategoryOpen
                              }
                              handleCompanyToAccountOpen={
                                this.handleCompanyToAccountOpen
                              }
                              handleAddVendorDialogOpen={
                                this.handleAddVendorDialogOpen
                              }
                              includeTB={this.state.includeTB}
                              itemizeTB={this.state.itemizeTB}
                              handleAction={this.handleTBAction}
                              updateTechnicalBidItems={
                                this.updateTechnicalBidItems
                              }
                              addItemTB={this.addItemTB}
                              removeItemTB={this.removeItemTB}
                              TBItems={this.state.TBItems}
                              disabledtechnicalbid={
                                this.state.disabledtechnicalbid
                              }
                              TBTemplateValueIntegration={
                                this.props.accountModulePermission[
                                  "TB Template Value Integration"
                                ]?.module_activated
                              }
                              pr_has_tb={this.props.selectedPrCategory?.tb_flag}
                            />
                          )}
                        </Grid>

                        {this.state.vendorsType === "category" && (
                          <Grid item xl={8} lg={8} md={6} sm={12} xs={12}>
                            <div className="card_right_content">
                              <CategoryVendors
                                key={this.state.CategoryVendorsKey}
                                handleCategoryVendorsReload={
                                  this.handleCategoryVendorsReload
                                }
                                handlelinkVendorToCategoryOpen={
                                  this.handlelinkVendorToCategoryOpen
                                }
                                handleCompanyToAccountOpen={
                                  this.handleCompanyToAccountOpen
                                }
                                handleAddVendorDialogOpen={
                                  this.handleAddVendorDialogOpen
                                }
                              />
                            </div>
                          </Grid>
                        )}
                      </Grid>
                      <Grid container>
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              height: "60px",
                              backgroundColor: (theme) => theme.palette.grey[0],
                              borderTop: (theme) =>
                                `1px solid ${theme.palette.bluegrey[500]}`,
                              borderRadius: "0px 0px 5px 5px",
                              display: "flex",
                              alignItems: "center",
                              p: 1,
                            }}>
                            {this.state.vendorsType === "category" && (
                              <Typography
                                fontSize="13px"
                                className="grid_footer_text">
                                Note: Vendor(s) not having Email ID or Payment
                                Terms and Inco Terms or both are shown without
                                checkbox in Vendor List.
                              </Typography>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    </div>
                  )}
                </Paper>
              </Box>
            </Grid>
          </Grid>
          <div>
            {this.props.selectedItems.length > 0 &&
              (this.state.vendorsType === "category" ? (
                <>
                  <Card
                    variant="outlined"
                    className={`template-card  ${
                      this.state.template !== "" ? "template_border" : ""
                    }`}
                    sx={{
                      marginTop: "25px",
                      marginBottom: this.state.template !== "" ? 0 : "25px",
                      borderBottom: this.state.template !== "" && 0,
                      borderBottomRightRadius: this.state.template !== "" && 0,
                      borderBottomLeftRadius: this.state.template !== "" && 0,
                    }}>
                    <CardContent
                      sx={{
                        paddingBottom: this.state.template !== "" ? 0 : "16px",
                        borderBottom: this.state.template !== "" ? 0 : 1,
                        color: (theme) => theme.palette.bluegrey[500],
                        "&.MuiCardContent-root:last-child": {
                          paddingBottom: 2,
                        },
                      }}>
                      <Grid container alignItems="center">
                        <Grid
                          item
                          lg={6}
                          md={6}
                          sm={6}
                          xs={12}
                          className="local_recepients_card_left template_tile">
                          <Typography
                            color="grey.600"
                            fontWeight={600}
                            sx={{
                              marginTop: "3px",
                              textTransform: "uppercase",
                            }}>
                            Template
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          lg={6}
                          md={6}
                          sm={6}
                          xs={12}
                          className="text-right template_dropdown_main">
                          {" "}
                          {Object.keys(this.props.selectedPrCategory).length ===
                            0 && (
                            <>
                              <Tooltip
                                title="Fetch values from previous RFQ"
                                disableInteractive
                                placement="bottom-start">
                                <Button
                                  size="medium"
                                  variant="icon-outline"
                                  color="default"
                                  onClick={() => {
                                    if (this.state.template === "") {
                                      this.props.openSnackbar(
                                        "error",
                                        "Select Template to fetch Previous RFQ values"
                                      );
                                      return;
                                    }
                                    this.state.clickfunction();
                                  }}
                                  sx={{
                                    minWidth: "40px",
                                    minHeight: "40px",
                                    marginRight: 2,
                                  }}>
                                  <SkipPreviousIcon />
                                </Button>
                              </Tooltip>
                            </>
                          )}
                          <FormControl variant="outlined">
                            <Tooltip
                              title={
                                this.state.template
                                  ? JSON.parse(this.state.template)?.name
                                  : ""
                              }
                              disableInteractive
                              placement="bottom-start"
                              open={this.state.templateTooltip}>
                              <Select
                                onMouseEnter={() => {
                                  this.setState({
                                    templateTooltip: true,
                                  });
                                }}
                                onMouseLeave={() => {
                                  this.setState({
                                    templateTooltip: false,
                                  });
                                }}
                                onOpen={() => {
                                  this.setState({
                                    templateTooltip: false,
                                  });
                                }}
                                className="template_dropdown_menu"
                                value={this.state.template}
                                onChange={(e) => {
                                  this.handleTemplateChange(e.target.value);
                                }}
                                displayEmpty
                                sx={{
                                  textAlign: "left",
                                  minWidth: "250px",
                                  maxWidth: "250px",
                                  minHeight: "40px",
                                  "& .MuiOutlinedInput-input": {
                                    padding: "8.5px 14px",
                                  },
                                }}>
                                <MenuItem value={""}>Select Template</MenuItem>
                                {this.state.templateLoading && (
                                  <MenuItem value={""}>Loading...</MenuItem>
                                )}
                                {this.state.templateOptions &&
                                  this.state.templateOptions.map(
                                    (option, index) => (
                                      <MenuItem
                                        key={index}
                                        value={JSON.stringify(option)}
                                        selected={
                                          this.state.template?.id === option.id
                                        }>
                                        {option.name}
                                      </MenuItem>
                                    )
                                  )}
                              </Select>
                            </Tooltip>
                          </FormControl>
                          {/* {(this.props.accountModulePermission["Service PR"]?.module_activated ==="Y" || window.location.pathname.indexOf("prcombination") === -1) && this.props.rfqSettingValues.serviceRFQ && ( */}
                          {this.props.accountModulePermission["Service PR"]?.module_activated === "Y" && 
                            this.props.rfqSettingValues.serviceRFQ && 
                            (window.location.pathname.indexOf("prcombination") === -1 ||                         
                            (window.location.pathname.indexOf("prcombination") > -1 && this.props.displayOptions.service_from_pr === "1")) && (
                            <>
                              <Tooltip title={this.props.displayOptions.service_label +" Template"}>
                                <Button
                                  sx={{
                                    color: 'text.primary',
                                    borderColor: 'bluegrey.500',
                                    fontWeight:400,
                                    textTransform: 'none',
                                    paddingTop:"7px",
                                    paddingBottom:"6px",
                                    ml: 2,
                                  }}
                                  variant="outlined"
                                  color="primary"
                                  onClick={this.handlePopoverOpen}
                                  endIcon={<ArrowDownIcon />}
                                >
                                  {this.props.displayOptions.service_label} Template       
                                </Button>
                              </Tooltip>
                              <Popover
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={this.handlePopoverClose}
                                className="ActivityPopover"
                                sx={{
                                  "&.ActivityPopover":{
                                    overflow: "visible",                                
                                    mt: 1,
                                    pt:1,
                                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                    ".MuiPaper-root":{
                                      width: 350,
                                      boxShadow:"none"
                                    }
                                  }                              
                                }}
                                transformOrigin={{ horizontal: "right", vertical: "top" }}
                                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                              >
                                <Box sx={{p:2}}>
                                  <Typography variant="body1" sx={{mb:2}} noWrap>{this.props.displayOptions.service_label} Template</Typography>
                                  <FormControl size="small" sx={{mb:2}} fullWidth >
                                    <InputLabel id="demo-simple-select-label">{this.props.displayOptions.service_label} Template</InputLabel>
                                    <Select
                                      label={this.props.displayOptions.service_label+" Template"}
                                      value={this.state.activityTemplate}
                                      className="template_dropdown_menu"
                                      onChange={(e) => {
                                        this.handleActivityTemplateChange(e.target.value);
                                      }}
                                    >
                                      {this.state.templateLoading && (
                                        <MenuItem value={""}>Loading...</MenuItem>
                                      )}
                                      {this.state.activityTemplateOptions &&
                                        this.state.activityTemplateOptions.map(
                                          (option, index) => (
                                            <MenuItem
                                              key={index}
                                              value={JSON.stringify({ name: option.display_name, id: option.template_id })}
                                              >
                                              {option.display_name}
                                            </MenuItem>
                                          )
                                        )}
                                    </Select>
                                  </FormControl>
                                  <FormControl size="small" sx={{mb:1}} fullWidth>              
                                    <InputLabel id="demo-simple-select-label">Allow Vendor To Fill {this.props.displayOptions.service_label} Template</InputLabel>                  
                                    <Select
                                      // value={age}
                                      label={"Allow Vendor To Fill "+this.props.displayOptions.service_label+" Template"}
                                      onChange={(e) => {
                                        this.handleActivityTemplateVendorFill(e.target.value);
                                      }}
                                      value={this.state.allowVendorFill}
                                      className="template_dropdown_menu"
                                    >
                                      <MenuItem value={10}>Partial</MenuItem>
                                      <MenuItem value={20}>Complete</MenuItem>
                                    </Select>
                                  </FormControl>
                                  <Box sx={{display:"flex", gap:"10px", alignItems:"center", pb:2,cursor: 'pointer'}}>
                                    <Button
                                      startIcon={<VisibilityIcon />}
                                      onClick={(e) =>
                                        { 
                                          if ( this.state.activityTemplateid == -1 || this.state.activityTemplateid == "") {
                                            this.props.openSnackbar("error", `Please select the ${this.props.displayOptions.service_label} Template`);
                                            return;
                                          }
                                          else{
                                            this.handleTBAction(
                                              "activityPreview",
                                              null, 
                                              null, 
                                              this.state.activityTemplateid,
                                              0
                                            )
                                          }
                                        }
                                      }
                                    >
                                      Preview
                                    </Button>
                                  </Box>
                                  <Button 
                                      onClick={() =>
                                        {
                                          if ( this.state.activityTemplateid === -1 || this.state.activityTemplateid === "") {
                                            this.props.openSnackbar("error", `Please select the ${this.props.displayOptions.service_label} Template`);
                                            return;
                                          }
                                          else{
                                            this.handleTBAction(
                                              "activityImport",
                                              null,
                                              null,
                                              this.state.activityTemplateid,
                                              0
                                            )
                                          }
                                        }
                                      }
                                    variant="contained" 
                                    fullWidth
                                  >IMPORT {this.props.displayOptions.service_label}</Button>
                                </Box>
                              </Popover>
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </CardContent>
                    {this.state.template === "" && (
                      <CardContent className="position-relative empty_template_div">
                        <div className="vendor_not_available_main">
                          <img src={templateblack} alt="Template Icon" />
                          <h5> "Select Template"</h5>
                        </div>
                      </CardContent>
                    )}
                  </Card>
                </>
              ) : (
                <>
                  <Card
                    variant="outlined"
                    className="template-card"
                    sx={{
                      marginTop: "25px",
                      marginBottom: this.state.template !== "" ? 0 : "25px",
                      borderBottom: this.state.template !== "" && 0,
                      borderBottomRightRadius: this.state.template !== "" && 0,
                      borderBottomLeftRadius: this.state.template !== "" && 0,
                    }}>
                    <CardContent
                      sx={{
                        paddingBottom: this.state.template !== "" ? 0 : "16px",
                        borderBottom: this.state.template !== "" ? 0 : 1,
                        color: (theme) => theme.palette.bluegrey[500],
                      }}>
                      <Grid container alignItems="center">
                        <Grid
                          item
                          lg={6}
                          md={6}
                          sm={12}
                          xs={12}
                          className="search_link_vendor">
                          <Grid Container alignItems="center">
                            <Grid
                              item
                              lg={6}
                              className="local_recepients_card_left template_tile"
                              md={12}
                              sm={12}
                              xs={12}>
                              <Typography
                                color="grey.600"
                                fontWeight={600}
                                sx={{
                                  marginTop: "3px",
                                  textTransform: "uppercase",
                                }}>
                                Template
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          lg={6}
                          md={6}
                          sm={12}
                          xs={12}
                          className="text-right template_dropdown_main">
                          {Object.keys(this.props.selectedPrCategory).length ===
                            0 && (
                            <>
                              <Tooltip
                                title="Fetch values from previous RFQ"
                                disableInteractive
                                placement="bottom-start">
                                <Button
                                  size="medium"
                                  variant="icon-outline"
                                  color="default"
                                  onClick={() => {
                                    if (this.state.template === "") {
                                      this.props.openSnackbar(
                                        "error",
                                        "Select Template to fetch Previous RFQ values"
                                      );
                                      return;
                                    }
                                    this.state.clickfunction();
                                  }}
                                  sx={{
                                    minWidth: "40px",
                                    minHeight: "40px",
                                    marginRight: 2,
                                  }}>
                                  <SkipPreviousIcon />
                                </Button>
                              </Tooltip>
                            </>
                          )}
                          <FormControl variant="outlined">
                            <Tooltip
                              title={
                                this.state.template
                                  ? JSON.parse(this.state.template)?.name
                                  : ""
                              }
                              disableInteractive
                              placement="bottom-start"
                              open={this.state.templateTooltip}>
                              <Select
                                onMouseEnter={() => {
                                  this.setState({
                                    templateTooltip: true,
                                  });
                                }}
                                onMouseLeave={() => {
                                  this.setState({
                                    templateTooltip: false,
                                  });
                                }}
                                onOpen={() => {
                                  this.setState({
                                    templateTooltip: false,
                                  });
                                }}
                                // title={this.state.template? JSON.parse(this.state.template)?.name : ""}
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                className="template_dropdown_menu"
                                value={this.state.template}
                                onChange={(e) => {
                                  this.handleTemplateChange(e.target.value);
                                }}
                                displayEmpty
                                sx={{
                                  textAlign: "left",
                                  minWidth: "250px",
                                  maxWidth: "250px",
                                  minHeight: "40px",
                                  "& .MuiOutlinedInput-input": {
                                    padding: "8.5px 14px",
                                  },
                                }}>
                                <MenuItem value={""}>Select Template</MenuItem>
                                {this.state.templateLoading && (
                                  <MenuItem value={""}>Loading...</MenuItem>
                                )}
                                {this.state.templateOptions &&
                                  this.state.templateOptions.map(
                                    (option, index) => (
                                      <MenuItem
                                        key={index}
                                        value={JSON.stringify(option)}>
                                        {option.name}
                                      </MenuItem>
                                    )
                                  )}
                              </Select>
                            </Tooltip>
                          </FormControl>
                          {/* {this.props.accountModulePermission["Service PR"]?.module_activated ==="Y" && this.props.rfqSettingValues.serviceRFQ && ( */}
                          {this.props.accountModulePermission["Service PR"]?.module_activated === "Y" && 
                            this.props.rfqSettingValues.serviceRFQ && 
                            (window.location.pathname.indexOf("prcombination") === -1 ||                         
                            (window.location.pathname.indexOf("prcombination") > -1 && this.props.displayOptions.service_from_pr === "1")) && (
                            <>
                              <Tooltip title={this.props.displayOptions.service_label +" Template"}>
                                <Button
                                  sx={{
                                    color: 'text.primary',
                                    borderColor: 'bluegrey.500',
                                    fontWeight:400,
                                    textTransform: 'none',
                                    paddingTop:"7px",
                                    paddingBottom:"6px",
                                    ml: 2,
                                  }}
                                  variant="outlined"
                                  color="primary"
                                  onClick={this.handlePopoverOpen}
                                  endIcon={<ArrowDownIcon />}
                                >
                                  {this.props.displayOptions.service_label} Template       
                                </Button>
                              </Tooltip>
                              <Popover
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={this.handlePopoverClose}
                                className="ActivityPopover"
                                sx={{
                                  "&.ActivityPopover":{
                                    overflow: "visible",                                
                                    mt: 1,
                                    pt:1,
                                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                    ".MuiPaper-root":{
                                      width: 350,
                                      boxShadow:"none"
                                    }
                                  }                              
                                }}
                                transformOrigin={{ horizontal: "right", vertical: "top" }}
                                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                              >
                                <Box sx={{p:2}}>
                                  <Typography variant="body1" sx={{mb:2}} noWrap>{this.props.displayOptions.service_label} Template</Typography>
                                  <FormControl size="small" sx={{mb:2}} fullWidth >
                                    <InputLabel id="demo-simple-select-label">{this.props.displayOptions.service_label} Template</InputLabel>
                                    <Select
                                      label={this.props.displayOptions.service_label + " Template"}
                                      value={this.state.activityTemplate}
                                      className="template_dropdown_menu"
                                      onChange={(e) => {
                                        this.handleActivityTemplateChange(e.target.value);
                                      }}
                                    >
                                      {this.state.templateLoading && (
                                        <MenuItem value={""}>Loading...</MenuItem>
                                      )}
                                      {this.state.activityTemplateOptions &&
                                        this.state.activityTemplateOptions.map(
                                          (option, index) => (
                                            <MenuItem
                                              key={index}
                                              value={JSON.stringify({ name: option.display_name, id: option.template_id })}
                                              >
                                              {option.display_name}
                                            </MenuItem>
                                          )
                                        )}
                                    </Select>
                                  </FormControl>
                                  <FormControl size="small" sx={{mb:1}} fullWidth>              
                                    <InputLabel id="demo-simple-select-label">Allow Vendor To Fill {this.props.displayOptions.service_label} Template</InputLabel>                                    
                                    <Select
                                      // value={age}
                                      label={"Allow Vendor To Fill "+this.props.displayOptions.service_label+" Template"}
                                      value={this.state.allowVendorFill}
                                      // onChange={handleChange}
                                      onChange={(e) => {
                                        this.handleActivityTemplateVendorFill(e.target.value);
                                      }}
                                      className="template_dropdown_menu"
                                    >
                                      <MenuItem value={10}>Partial</MenuItem>
                                      <MenuItem value={20}>Complete</MenuItem>
                                    </Select>
                                  </FormControl>
                                  <Box sx={{display:"flex", gap:"10px", alignItems:"center", pb:2,cursor: 'pointer'}}>
                                    <Button
                                      startIcon={<VisibilityIcon />}
                                      onClick={(e) =>
                                        { 
                                          if ( this.state.activityTemplateid == -1 || this.state.activityTemplateid == "") {
                                            this.props.openSnackbar("error", `Please select the ${this.props.displayOptions.service_label} Template`);
                                            return;
                                          }
                                          else{
                                            this.handleTBAction(
                                              "activityPreview",
                                              null, 
                                              null, 
                                              this.state.activityTemplateid,
                                              0
                                            )
                                          }
                                        }
                                      }
                                    >Preview</Button>
                                  </Box>
                                  <Button 
                                      onClick={() =>
                                        {
                                          if ( this.state.activityTemplateid == -1 || this.state.activityTemplateid == "") {
                                            this.props.openSnackbar("error", `Please select the ${this.props.displayOptions.service_label} Template`);
                                            return;
                                          }
                                          else{
                                            this.handleTBAction(
                                              "activityImport",
                                              null,
                                              null,
                                              this.state.activityTemplateid,
                                              0
                                            )
                                          }
                                        }
                                      }
                                    variant="contained" 
                                    fullWidth
                                  >IMPORT {this.props.displayOptions.service_label}</Button>
                                </Box>
                              </Popover>
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </CardContent>
                    {this.state.template === "" && (
                      <CardContent className="position-relative empty_template_div">
                        <div className="vendor_not_available_main">
                          <img src={templateblack} alt="Template Icon" />
                          <h5> "Select Template"</h5>
                        </div>
                      </CardContent>
                    )}
                  </Card>
                </>
              ))}
            {this.props.selectedItems.length > 0 &&
              this.state.template !== "" &&
              this.state.jsonTemplate !== null && (
                <AccountTemplate
                  setClick={(click) => {
                    this.setState({
                      clickfunction: click,
                    });
                  }}
                  resetSettingDrawer={this.props.resetSettingDrawer}
                  key={this.state.accountTemplatKey}
                  master_type={this.state.vendorsType}
                  jsonTemplateloading={this.state.jsonTemplateloading}
                  jsonTemplate={this.state.jsonTemplate}
                  selectedItems={this.props.selectedItems}
                  template={this.state.template}
                  cc_emails={this.state.cc_emails}
                  randomId={this.state.randomId}
                  includeTB={this.state.includeTB}
                  itemizeTB={this.state.itemizeTB}
                  TBItems={this.state.TBItems}
                  handleTBAction={this.handleTBAction}
                  props={this.props}
                  location={this.props.location} 
                  match={this.props.location}
                  activityHash={this.props.activityHash}
                  activity_data={this.state.activity_data}
                  allowVendorFill={this.state.allowVendorFill}
                />
              )}
          </div>
        </div>
        <ConfirmAlert
          open={this.state.confirmDialogOpen}
          handleClose={() => {
            this.setState({
              confirmDialogOpen: false,
            });
          }}
          handleSubmit={() => {
            // logic to remove item from the cart with no vendors
            let removingItems = this.props.selectedItems.filter(
              (item) => item.vendor_count.total_count === 0
            );

            removingItems?.forEach((item) => handleItemsClick(this, item));
            let updatedSelectedItems = [];
            this.props.selectedItems.forEach((cartItem) => {
              if (
                !removingItems.some((element) => {
                  return element.id === cartItem.id;
                })
              ) {
                updatedSelectedItems.push(cartItem);
              }
            });
            this.props.setSelectedItem(updatedSelectedItems);
            this.props.setSelectedVendors([]);
            this.setState({
              vendorsType: "item",
              confirmDialogOpen: false,
            });
          }}
          msg="Some of the cart item(s) do not have any vendor(s). By clicking Accept that item(s) will be removed from the cart."
          title="Remove item(s) with no vendor?"
        />
        <ConfirmAlert
          open={this.state.removerfqDialogOpen}
          handleClose={() => {
            this.setState({
              removerfqDialogOpen: false,
            });
          }}
          handleSubmit={() => {
            if (window.location.pathname.indexOf("prcombination") !== -1) {
              this.props.disablePrCategory({
                [this.props.categoryIndex]: true,
              });
              this.setprcombinationItemsandremoveitems(
                [],
                this.state.checkedItems
              );
            }
            this.setState({
              removerfqDialogOpen: false,
              checkedItems: [],
            });
          }}
          msg="This cart will be empty and RFQ will be removed."
          title="Are you sure want to remove item(s)?"
        />
        <ConfirmAlert
          open={this.state.confirmIncludeTB}
          handleClose={() => {
            this.setState({
              confirmIncludeTB: false,
            });
          }}
          handleSubmit={() => {
            this.props.setIncludetbvalue(true);
            this.props.setRFQSettings({
              ...this.props.rfqSettingValues,
              allowMultiPlant: false,
            });
            this.setState({
              includeTB: true,
              confirmIncludeTB: false,
            });
          }}
          msg="This action will disable Multiplant selection"
          title="Are you sure want to IncludeTB?"
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedCategory: state.categoryReducer.selectedCategory,
  selectedVendors: state.vendorReducer.selectedVendors,
  selectedItems: state.itemReducer.selectedItems,
  sessionId: state.sessionReducer.sessionId,
  vendors: state.vendorReducer.vendors,
  accountModulePermission: state.permissionsReducer.accountModulePermission,
  featurePermission: state.permissionsReducer.featurePermission,
  rfqSettingValues: state.drawerValuesReducer.rfqSettingValues,
  userInfo: state.userInfoReducer.userInfo,
  companyInfo: state.companyInfoReducer.companyInfo,
  prlineItemDialog: state.prrfqreducer.prDialogOpen,
  selectedPrCategory: state.prrfqreducer.selectedPrCategory,
  setpranditemvalue: state.prrfqreducer.setpranditemvalue,
  fileSpecifications: state.categoryReducer.fileSpecifications,
  prplants: state.prrfqreducer.plantcodes,
  selectedprcombination:
    state.prrfqreducer.prCombinations[state.prrfqreducer.disableIndex],
  defaultValues: state.prrfqreducer.defaultValues,
  categoryIndex: state.prrfqreducer.disableIndex,
  pritems: state.prrfqreducer.setPrLineItemsData,
  jsonTemplates: state.prrfqreducer.jsonTemplates,
  pr_dropdown_values: state.prrfqreducer.pr_dropdown_values,
  prCombinations:state.prrfqreducer.prCombinations,

  activityTemplateValues: state.drawerValuesReducer.activityTemplateValues,
  displayOptions: state.prrfqreducer.displayOptions,
  ImportExcelRfq: state.ImportExcelReducer.excelResponse

});

const mapDispatchToPops = (dispatch) => ({
  disablePrCategory: (data) => {
    dispatch(setDisabledPrCategory(data));
    dispatch(setPRselectedChange());
  },
  ...bindActionCreators(
    {
      setAvailableItem,
      setSelectedItem,
      getItemizedVendors,
      unsetCheckValidTechnicalBidItems,
      setFileSpecification,
      setIncludetbvalue,
      openSnackbar,
      setSelectedVendors,
      setprcombinationItems,
      setEmptyPrCombination,
      setTemplateOptions,
      setJsonTemplates,
      setRFQSettings,
      setPlantCodes,
      setSelectedPrCategory,
      setPrLineItemsData,
      setPrCombinations,
      setDisplayOptions,
      setActivityTemplate 
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToPops)(MainContent);
