import { styled } from '@mui/material/styles';
import Drawer from "@mui/material/Drawer";

export const LandedCostRFQRight = styled(Drawer)( ({ theme }) => ({
    '&.LandedCostRFQ-Sidebar':{
        '& .MuiPaper-root':{
            boxShadow: 'none',
            borderRadius: '0',
            border:'none'
        },
        '& .MuiDrawer-paper': {
            width: '700px',     
            boxSizing: 'border-box',
            [theme.breakpoints.down("sm")]: {
                width: '100%',
            }
        },
        "& .MuiTable-root": {
            borderCollapse: "separate",
            tableLayout:"fixed"
        },
        "& .MuiTableHead-root":{
            position: "sticky",
            top: "0",
        },
        "& .MuiTableHead-root tr th": {
            padding: "6px 10px",
        },
        "& .MuiTableFooter-root": {
            position: "sticky",
            bottom: "0",
            background: "#fff",
            boxShadow: "0px -1px 0 var(--bluegrey-500)"
        }
         
    },
}))