import styled1 from "styled-components";
import Drawer from "@mui/material/Drawer";

export const styles = theme => ({
  RFQSettingSlide: {
    '& .MuiDrawer-paper': {
      width: '760px',     
      boxSizing: 'border-box',

      '@media only screen and (max-width: 480px)': {
        width: '80%',
      }
    },
    '& .MuiToolbar-root': {
      padding: '0 20px',
      minHeight: '40px',      
    },
    '& .MuiToolbar-root .drawer-title': {
      fontSize: 'calc(16px - (16 - 18) * ((100vw - 300px) / (1920 - 300)))',
      color: theme.palette.grey[900],
      fontWeight: 500,
    },
    '& .drawr-bottom': {
      background: theme.palette.grey[0], 
      padding: '15px 20px',      
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    '& .drawerinner_content': { 
      padding: '0 20px',      
      height: 'calc(100vh - 90px)',
      display: 'inline-block !important',
      overflowY: 'auto',
    },
    '& .gridhover:hover': { 
      backgroundColor:'#ACCEF7'
    },
    '& .prdrawerinner_content': { 
      padding: '0px 10px',      
      height: 'calc(100vh - 90px)',
      display: 'inline-block !important',
      overflowY: 'auto',
    },
    '& .drawr-bottom button': {
      width: '50%',
    },
    '& .drawr-bottom button:first-child': {
      marginRight: '20px',
    },
  }
});

export const CustomDrawer = styled1(Drawer)`
  .MuiDrawer-paperAnchorRight {
    background: var(--thm-white);;
    width: 360px;
    @media only screen and (max-width: 480px) {
      width: 80%;
    }
  }
  .drawerinner_content {
    padding: 10px 20px;
    margin-top: 20px;
    height: calc(100vh - 20px);
    display: inline-block !important;
    overflow-y: scroll;
  }
  /* .drawerinner_content
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    border: 1px solid var(--border-outline) !important;
    border-radius: 3px !important;
  } */
  .drawerinner_content .custom_labels {
    text-align: left;
    letter-spacing: 0.42px;
    color: var(--small-text-ceolor);
    font-weight: 400;
  }
  .custom_main_heading {
    font-size: calc(18px + (18 - 16) * ((100vw - 300px) / (1920 - 300)));
    color: var(--black-textcolor);
    text-align: left;
    letter-spacing: 0.36px;
    font-weight: 600;
    margin-left:10px;
  }
  .divider_line {
    border: 0.2px solid var(--border-outline);
  }
  .drawerinner_content .MuiFormControlLabel-label {
    text-align: left;
    letter-spacing: 0px;
    color: var(--text-color);
    font-size: 14px;
    font-weight: 400;
  }
  .drawerinner_content .MuiFormControlLabel-root {
    margin-left: 0 !important;
    margin-right: 0 !important;
    justify-content: space-between;
    display: flex;
    flex-direction: row-reverse;
  }
  .drawerinner_content .sidebar_lable_text {
    text-align: left;
    font-size: 15.5px;
    font-weight: 600;
    letter-spacing: 0.42px;
    color: var(--small-text-ceolor);
    text-transform: uppercase;
  }
  .drawerinner_content .buttonbottom_helper_text span {
    text-align: left;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.6px;
    color: #999999;
  }

  .MuiIconButton-root.MuiIconButton-edgeEnd svg
  {
    color: var(--black-textcolor);
  }
  .MuiInputBase-root.MuiInputBase-colorPrimary fieldset,
  .MuiFormControl-fullWidth.MuiTextField-root fieldset
  {
    border-color: var(--border-outline);
    border-width: 2px;
  }
  .MuiInputBase-root.MuiInputBase-colorPrimary.Mui-focused fieldset,
  .MuiFormControl-fullWidth.MuiTextField-root.Mui-focused fieldset
  {
    border-color: var(--thm-bg);
  }
  .MuiInputBase-root.MuiInputBase-colorPrimary.Mui-error fieldset,
  .MuiFormControl-fullWidth.MuiTextField-root.Mui-error fieldset
  {
    border-color: var(--thm-red);
  }
  .MuiInputAdornment-root svg
  {
    color: var(--black-textcolor);
  }
`;
