import { SET_COMPANY_INFO, SET_COMPANY_INFO_ERROR } from "../constants/type";
const initialState = {
  companyInfo: {},
  companyInfoError: "",
};

const companyInfoReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_COMPANY_INFO:
      return { companyInfo: payload.data, companyInfoError: "" };
    case SET_COMPANY_INFO_ERROR:
      return { companyInfo: [], companyInfoError: payload };
    default:
      return state;
  }
};

export default companyInfoReducer;
