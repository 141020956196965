export const removeItemFromCart = (ref) => {
  //remove the checkedItems from the selecteditems

  if (ref.state.checkedItems.length) {
    if (
      ref.props.selectedItems.length - ref.state.checkedItems.length <= 0 &&
      ref.props.prplants.length
    ) {
      ref.setState({
        removerfqDialogOpen: true,
      });
    } else {
      let updatedSelectedItems = [];
      let removedItems = [];
      ref.props.selectedItems.forEach((cartItem) => {
        if (
          !ref.state.checkedItems.some((element) => {
            return element.id === cartItem.id;
          })
        ) {
          updatedSelectedItems.push(cartItem);
        } else {
          removedItems.push(cartItem);
        }
      });
      if (Object.keys(ref.props.selectedPrCategory).length > 0) {
        ref.setprcombinationItemsandremoveitems(
          updatedSelectedItems,
          removedItems
        );
        ref.removedprlintemrelatedfiles(removedItems);       

      }
      ref.props.setSelectedItem(updatedSelectedItems);
      ref.props.unsetCheckValidTechnicalBidItems(removedItems);
   
      if (ref.itemizeTB) {
        ref.setState({
          checkedItems: [],
          TBItems: updatedSelectedItems,
        });
      } else {
        ref.setState({
          checkedItems: [],
        });
      }
    }
  }
};
