import React, { Component } from "react";
import {
  Checkbox,
  Typography,
  Tooltip,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Switch,
  Badge
} from "@mui/material";
import { Box } from "@mui/system";
import "../../pages/style.css";
import _, { debounce } from "lodash";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { ToggleButton } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { ResizableBox } from "react-resizable";
import "./resize.style.css";
import ItemizeField from "./ItemizeField";
import More from "../More/More";
import Upload from "../Dialogs/Upload/Upload";
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import AttachFileIcon from '@mui/icons-material/AttachFile';
export default class ItemizeSectionTable extends Component {
  constructor() {
    super();
    this.state = {
      name: "React",
      SlabTableToggle: false,
      rows: [],
    };
    // this.hideComponent = this.hideComponent.bind(this);
  }

  // slabAddRow = () => {
  //   this.setState((prevState, props) => {
  //     const row = {};
  //     return { rows: [...prevState.rows, row] };
  //   });
  // };

  // slabRemoveRow = () => {
  //   this.setState((prevState, props) => {
  //     return { rows: prevState.rows.slice(1) };
  //   });
  // };

  // hideComponent(name) {
  //   switch (name) {
  //     case "SlabTableToggle":
  //       this.setState({ SlabTableToggle: !this.state.SlabTableToggle });
  //       break;
  //     default:
  //   }
  // }

  state = {
    isScrollActive: false,
    currentItemIndex: null,
    anchorEl: null,
    qty: "",
    rate: "",
    estimatedValueOpen: false,
    specificationsOpen: false,
    updateOnce: false
  };
  getTableBody = () => {
    let itemsToRender;
    const {SlabTableToggle} = this.state;
    if (this.props.selectedItems) {
      itemsToRender = this.props.selectedItems?.map((item, index) => {
        this.props.data[index]?.map((cell) => {
            if(cell.Identifier === 'ProductQuantity'){
                if(this.props.mainTemplate[item.id]){
                    for (const [key, value] of Object.entries(this.props.mainTemplate[item.id])) {
                        if(value?.Identifier == 'ProductQuantity'){
                            if(this.state.updateOnce && !this.props.rfqSettingValues.serviceRFQ){
                                let evt = {};
                                evt.target = {};
                                evt.target.value = '';
                                evt.target.type = 'text';
                                evt.target.checked = false;
                                this.props.handleChange(
                                    evt,
                                    index,
                                    key,
                                    'ProductQuantity'
                                );
                                this.setState({updateOnce:false});
                            }
                            else if(value.value != 1 && this.props.rfqSettingValues.serviceRFQ){
                                this.setState({updateOnce : true});
                                let evt = {};
                                evt.target = {};
                                if(this.props.rfqSettingValues.serviceRFQ){
                                    evt.target.value = 1;
                                }
                                evt.target.type = 'text';
                                evt.target.checked = false;
                                this.props.handleChange(
                                    evt,
                                    index,
                                    key,
                                    value.Identifier
                                )
                            }
                        }
                    }
                }
            }
        });
        return (
          <>
          <TableRow key={index}>
            <TableCell
              sx={{
                WebkitBoxShadow:
                  this.state.isScrollActive && "11px 0 19px -2px #bebebe54",
                boxShadow:
                  this.state.isScrollActive && "11px 0 19px -2px #bebebe54",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
               {this.props.rfqSettingValues.includeSlab && 
               <Tooltip
                  title={`Include ${this.props?.displayOptions["label"]}`}
                  placement="bottom-start"
                  disableInteractive
               >
               <Switch 
              //  onClick={() => this.hideComponent("SlabTableToggle")} 
               checked={this.props.slab[item.id] && this.props.slab[item.id].isOpen?true:false}
               onChange={(evt) =>{
                this.props.handleSlab(evt,item)
               }
                // this.props.handleChange(
                //   evt,
                //   index,
                //   item,
                //   Identifier
                // )
              }
               />
                </Tooltip>
               }
                <Tooltip
                  title="Specification"
                  placement="bottom-start"
                  disableInteractive
               >
               <Badge 
               badgeContent={this.props.itemSpecificFiles[this.props.selectedItems[index]?.id]?.length}
               color="primary">
                {/* <More
                  setCurrentItemIndex={() => {
                    this.setState({
                      currentItemIndex: index,
                    });
                  }}
                  handleSpecificationsOpen={() => {
                    this.setState({
                      specificationsOpen: true,
                    });
                  }}
                /> */}
                <AttachFileIcon onClick={()=>{
                  this.setState({
                    currentItemIndex: index,
                  },()=>{
                    this.setState({
                      specificationsOpen: true,
                    });
                  });
                }}
                sx={{cursor:"pointer"}}
                />
                </Badge>
               </Tooltip>
                <Tooltip
                  title={_.unescape(item.name)}
                  placement="bottom-start"
                  disableInteractive
                >
                  <Typography
                    noWrap
                    color="text.primary"
                    fontWeight={500}
                    sx={{
                      width: `${
                        this.state.itemCellWidth > 350
                          ? this.state.itemCellWidth
                          : 350
                      }px`,
                     marginLeft:"10px"
                    }}
                  >
                    {_.unescape(item.name)}
                  </Typography>
                </Tooltip>
              </div>
            </TableCell>
            <TableCell>
              <Tooltip
                title={_.unescape(item.code)}
                placement="bottom-start"
                disableInteractive
              >
                <Typography noWrap color="primary" fontWeight={500}>
                  {_.unescape(item.code)}
                </Typography>
              </Tooltip>
            </TableCell>
            {this.props.data[index]?.map((cell) => {
              if (cell.VisibilityFlag === "Y") {
                if (
                  !(
                    cell.Identifier === "Product" ||
                    cell.Identifier === "ProductCode" ||
                    cell.Identifier === "ProductForeign"
                  )
                ) {
                  return (
                    <TableCell key={cell.FieldId} sx={{ minWidth: "120px" }}>
                      <ItemizeField
                        batch_id={this.props.batch_id}
                        LATEST={this.props.LATEST}
                        upd_budget_master={
                          this.props.mainTemplate[item.id] &&
                          this.props.mainTemplate[item.id][cell?.FieldId] &&
                          this.props.mainTemplate[item.id][cell?.FieldId][
                            `upd_budget_master_${item.id}`
                          ]
                        }
                        qty={
                          this.props.mainTemplate[item.id] &&
                          this.props.mainTemplate[item.id]["qty"]
                        }
                        rate={
                          this.props.mainTemplate[item.id] &&
                          this.props.mainTemplate[item.id]["rate"]
                        }
                        readOnly = {this.props.mainTemplate[item.id]?.[cell?.FieldId]?.readOnly}
                        cell={cell}
                        serviceRFQ = {this.props.rfqSettingValues.serviceRFQ}
                        item={item}
                        index={index}
                        FieldState={
                          this.props.mainTemplate[item.id] &&
                          this.props.mainTemplate[item.id][cell?.FieldId]
                        }
                        base_currency={this.props.base_currency}
                        handleUpdateInMasterClick={
                          this.props.handleUpdateInMasterClick
                        }
                        updateDeliveryScheduleFieldValue={
                          this.props.updateDeliveryScheduleFieldValue
                        }
                        handleChange={(evt, FieldId, Identifier) =>
                          this.props.handleChange(
                            evt,
                            index,
                            FieldId,
                            Identifier
                          )
                        }
                        handleDateChange={(newVal, FieldId, Validation) =>
                          this.props.handleDateChange(
                            newVal,
                            FieldId,
                            "mainTemplate",
                            index,
                            Validation
                          )
                        }
                        default_dropdown_type={this.props.default_dropdown_type}
                        destination={this.props.destination}
                      />
                    </TableCell>
                  );
                }
              }
            })}
          </TableRow>
          {this.props.slab[item.id] && this.props.slab[item.id].isOpen &&
              <TableRow key={item.id}>
                <TableCell>&nbsp;</TableCell>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0,  }} colSpan={200}>              
                    <Table
                      className="SlabDetailsForm"
                      sx={{
                        width:"360px",
                        borderLeft:"1px solid #a6c1d3",
                        borderRight:"1px solid #a6c1d3",
                        "&.SlabDetailsForm tr td:first-of-type, &.SlabDetailsForm tr th:first-of-type": {
                          minWidth: 'auto',
                          position: 'static'
                        },
                        "&.SlabDetailsForm .MuiTableBody-root tr td, &.SlabDetailsForm .MuiTableHead-root tr th": {
                          padding: "8px",
                        },
                        '&.SlabDetailsForm .MuiTableBody-root tr:last-of-type td': {
                          borderBottom: 'none'
                        },
                        '& .MuiTableCell-root .MuiFormControl-root':{
                          maxWidth: '160px',
                        }
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell align="right">&nbsp;</TableCell>
                          <TableCell align="right">From</TableCell>
                          <TableCell align="right">
                           {this.props.firstActiveSlab && this.props.firstActiveSlab.id ==item.id && <Tooltip title="Apply To All">
                              <Checkbox 
                              checked={this.props.applyToAllSlab?true:false}
                              onClick={(e)=>{
                                  if (!this.props.applyToAllSlab) {
                                    this.props.handleApplyToAllSlab(e,index,item.id)
                                  }
                                }}/>
                            </Tooltip> }
                            To
                          </TableCell>                          
                          <TableCell align="right">&nbsp;</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                          
                          {/* <TableRow>             
                            <TableCell align="left">
                              <IconButton aria-label="Add" size="small" onClick={this.slabAddRow}>
                                <AddCircleIcon color="primary"/>
                              </IconButton>                              
                            </TableCell> 
                            <TableCell align="right">
                              <Typography fontWeight={500}>1</Typography>
                            </TableCell>
                            <TableCell align="right">
                              <TextField
                                id="qty"
                                defaultValue="500"
                                fullWidth
                                size="small"
                                sx={{ 
                                  '& .MuiInputBase-input':{textAlign:'right'},
                                  '&.MuiFormControl-root .MuiInputBase-root':{
                                    pr:1
                                  }
                                }}
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">QTY</InputAdornment>,
                                }}
                              />       
                            </TableCell>
                            <TableCell align="left">&nbsp;</TableCell>                            
                          </TableRow> */}

                          {this.props.mainTemplate[item.id]["slab"].map((row,i) => 
                          { 
                            let currentSlab = this.props.mainTemplate[item.id]["slab"];
                            let value = ""
                            if(row.start=="More Than"){
                              value = currentSlab[i-1].end 
                            }else{
                              value = row.end
                            }
                            return  <TableRow key={row.id}>             
                            <TableCell align="left">
                              {currentSlab.length-2 == i &&  <IconButton aria-label="Add" size="small" onClick={()=>{this.props.slabAddRow(i,index)}}>
                              <Tooltip
                              title={`Add ${this.props?.displayOptions["label"]}`}
                              placement="bottom-start"
                              disableInteractive
                              >
                              <AddCircleIcon color="primary"/>
                              </Tooltip>

                              </IconButton>}
                              {i>0 && currentSlab.length-1 != i && (
                                <IconButton aria-label="delete" size="small"  sx={{color:'grey.600'}} onClick={(e)=>this.props.slabRemoveRow(i,index)}>
                                  <Tooltip
                                    title={`Remove ${this.props?.displayOptions["label"]}`}
                                    placement="bottom-start"
                                    disableInteractive
                                  >
                                    <DeleteIcon/>
                                 </Tooltip>
                                </IconButton>
                              )}
                            </TableCell> 
                            <TableCell align="right">
                              <Typography fontWeight={500}>{row.start}</Typography>
                            </TableCell>
                            <TableCell  id={row.id} align="right">
                              {/* {row.start!=="more than"?<TextField
                                key={i}
                                id="qty"
                                // defaultValue={row.end}
                                defaultValue={value}
                                onChange={(e)=>{
                                  this.props.handleRowInput(e,row,i,index)
                                }}
                                fullWidth
                                error={row.error?true:false}
                                size="small"
                                sx={{ 
                                  '& .MuiInputBase-input':{textAlign:'right'},
                                  '&.MuiFormControl-root .MuiInputBase-root':{
                                    pr:1
                                  }
                                }}
                                // InputProps={{
                                //   endAdornment: <InputAdornment position="end">QTY</InputAdornment>,
                                // }}
                                InputProps={{
                                  startAdornment: row.error && <InputAdornment position="start"><Tooltip title="Less Value Then The Above Qty"><WarningAmberIcon color="error"/></Tooltip></InputAdornment>,
                                  endAdornment: <InputAdornment position="end">QTY</InputAdornment>,
                                }}
                              />
                            :
                            <Typography fontWeight={500}>{value}</Typography>
                            } */}
                            {currentSlab.length-1 == i?
                            <Typography align="right" sx={{pr:1}}><span>{value}</span><span style={{paddingLeft:"8px"}}>{item.unit}</span></Typography>
                            :<TextField
                              id="qty"
                              disabled={currentSlab.length-1 == i}
                              type="number"
                              // defaultValue={row.end}
                              defaultValue={value}
                              onChange={(e)=>{
                                this.props.handleRowInput(e,row,i,index)
                              }}
                              // onBlur={()=>{
                              //   if(!row.error && row.end!=""){
                              //     this.props.handleKey(index);
                              //   }
                              // }}
                              fullWidth
                              error={row.error?true:false}
                              size="small"
                              sx={{ 
                                '& .MuiInputBase-input':{textAlign:'right'},
                                '&.MuiFormControl-root .MuiInputBase-root':{
                                  pr:1
                                }
                              }}
                              // InputProps={{
                              //   endAdornment: <InputAdornment position="end">QTY</InputAdornment>,
                              // }}
                              InputProps={{
                                startAdornment: row.error && <InputAdornment position="start"><Tooltip title="Less Value Than The Above Qty"><WarningAmberIcon color="error"/></Tooltip></InputAdornment>,
                                endAdornment: <InputAdornment position="end">{item.unit}</InputAdornment>,
                              }}
                            />       
                          }
                            </TableCell>
                            <TableCell align="left">&nbsp;</TableCell>                            
                        </TableRow>
                          })}

                          
                          {/* <TableRow>              
                            <TableCell align="left">&nbsp;</TableCell> 
                            <TableCell align="right">
                              <Typography fontWeight={500}>More Than</Typography>
                            </TableCell>
                            <TableCell align="right">
                              <TextField
                                // onChange={(e)=>{
                                //   this.props.handleRowInput(e)
                                // }}
                                error
                                id="qty"
                                defaultValue={this.props.mainTemplate[item.id]["slab"][this.props.mainTemplate[item.id]["slab"].length-1].end}
                                fullWidth
                                size="small"                                
                                sx={{ 
                                  '& .MuiInputBase-input':{textAlign:'right'},
                                  '&.MuiFormControl-root .MuiInputBase-root':{
                                    pr:1
                                  }
                                }}
                                InputProps={{
                                  startAdornment: <InputAdornment position="start"><Tooltip title="Less Value Then The Above Qty"><WarningAmberIcon color="error"/></Tooltip></InputAdornment>,
                                  endAdornment: <InputAdornment position="end">QTY</InputAdornment>,
                                }}
                              />       
                            </TableCell> 
                            <TableCell align="left">&nbsp;</TableCell>
                          </TableRow> */}
                      </TableBody>
                    </Table>
                </TableCell>
              </TableRow>
            }

            
          </>
        );
      });
    } else itemsToRender = "loading...";
    return <TableBody>{itemsToRender}</TableBody>;
  };

  render() {
    return (
      <>
        <TableContainer
          className="items_template_table"
          onScroll={debounce((event) => {
            if (event.target.scrollLeft === 0 && this.state.isScrollActive) {
              this.setState({
                isScrollActive: false,
              });
            } else if (
              event.target.scrollLeft !== 0 &&
              !this.state.isScrollActive
            ) {
              this.setState({
                isScrollActive: true,
              });
            }
          }, 200)}
          sx={{
            maxHeight: "400px",
            overflowY: "auto",
            border: "solid 1px",
            borderColor: (theme) => theme.palette.bluegrey[500],
            borderBottomRightRadius: "4px",
            borderBottomLeftRadius: "4px",
            ".items_template_table .MuiTableHead-root": {
              backgroundColor: (theme) => theme.palette.bluegrey[500],
            },
            "& .MuiTableHead-root ": {},
            "& .MuiTableBody-root": {
              backgroundColor: (theme) => theme.palette.grey[0],
            },
            "& tr": {
              whiteSpace: "nowrap",
            },
            "& .MuiTableHead-root tr th": {
              padding: "15px 8px",
            },
            "& .MuiTableBody-root tr td": {
              padding: "5px 8px",
            },
            ".MuiTableBody-root tr td:first-of-type, .MuiTableHead-root tr th:first-of-type ":
              {
                position: "sticky",
                left: "0",
                minWidth: "380px",
              },
            "& .MuiTableHead-root tr th:first-of-type": {
              zIndex: "3",
            },
            "& .MuiTableBody-root tr td:first-of-type": {
              backgroundColor: (theme) => theme.palette.grey[0],
              zIndex: "1",
            },
            '& .css-17hdxs-MuiTypography-root':{
              fontSize:"14px"
            }
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    WebkitBoxShadow:
                      this.state.isScrollActive && "11px 0 19px -2px #bebebe54",
                    boxShadow:
                      this.state.isScrollActive && "11px 0 19px -2px #bebebe54",
                  }}
                >
                  <Box
                    className=""
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox sx={{ visibility: "hidden" }} />
                    <Tooltip
                      title={
                        this.props.data[0]?.find(
                          (obj) => obj.Identifier === "Product" || obj.Identifier === "ProductForeign"
                        )?.FieldLabel
                      }
                    >
                      <Box
                        sx={{
                          minWidth: "350px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography fontWeight={500}>
                          {
                            this.props.data[0]?.find(
                              (obj) => obj.Identifier === "Product" || obj.Identifier === "ProductForeign"
                            )?.FieldLabel
                          }
                        </Typography>

                        <ResizableBox
                          axis="x"
                          height={20}
                          width={20}
                          onResizeStop={(e, data) => {
                            this.setState({
                              itemCellWidth: data.size.width,
                            });
                          }}
                          style={{
                            textAlign: "left",
                            width: "100%",
                          }}
                          minConstraints={[20, 20]}
                        ></ResizableBox>
                      </Box>
                    </Tooltip>
                  </Box>
                </TableCell>

                <TableCell>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Tooltip
                      title={
                        this.props.data[0]?.find(
                          (obj) => obj.Identifier === "ProductCode"
                        )?.FieldLabel
                      }
                    >
                      <Typography fontWeight={500}>
                        {
                          this.props.data[0]?.find(
                            (obj) => obj.Identifier === "ProductCode"
                          )?.FieldLabel
                        }
                      </Typography>
                    </Tooltip>
                    <ResizableBox
                      axis="x"
                      height={20}
                      width={20}
                      style={{
                        textAlign: "left",
                        width: "100%",
                      }}
                      minConstraints={[20, 20]}
                    ></ResizableBox>
                  </Box>
                </TableCell>

                {this.props.data[0]?.map((cell, index) => {
                  if (cell.VisibilityFlag === "Y") {
                    if (
                      !(
                        cell.Identifier === "Product" ||
                        cell.Identifier === "ProductForeign" ||
                        cell.Identifier === "ProductCode"
                      )
                    ) {
                      return (
                        <TableCell key={cell.FieldId}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                         <Tooltip
                            title="Apply to All"
                            placement="bottom-start"
                            disableInteractive
                         >
                            <ToggleButton
                              value="check"
                              selected={
                                this.props.applyToAll[
                                  `${cell.FieldId}_applyToAll`
                                ]
                              }
                              disabled={cell.Identifier === "EstimatedValue" || (this.props.rfqSettingValues.serviceRFQ && cell.Identifier === 'ProductQuantity')}
                              // title="Apply to All"
                              onClick={() => {
                                //for not doing apply to all when field is read only
                                let isReadOnly = false;
                                if(!isReadOnly){
                                  Object.keys(this.props.mainTemplate).map((key,i)=>{
                                    let element = this.props.mainTemplate[key];
                                    Object.keys(element).map((key1,i)=>{
                                      if(key1==cell.FieldId && typeof element[key1] === 'object' && element[key1].readOnly){
                                        isReadOnly = key1
                                      }
                                    })
                                  })
                                }
                                if (
                                  !this.props.applyToAll[
                                    `${cell.FieldId}_applyToAll`
                                  ] && isReadOnly != cell.FieldId
                                ) {
                                  this.props.handleApplyToAllClick(
                                    cell.FieldId,
                                    cell.ElementType === "TB" &&
                                      cell.ElementType === "TA",
                                    cell.Identifier
                                  );
                                }
                              }}
                              sx={{
                                p: 0,
                                mr: 1,
                                border: 0,
                              }}
                            >

                              {
                              !['T', 'D','L'].includes(cell.ElementType) ?
                              this.props.applyToAll[
                                `${cell.FieldId}_applyToAll`
                              ] ? (
                                <CheckBoxIcon
                                  sx={{
                                    color: (theme) =>
                                      theme.palette.primary.main,
                                  }}
                                />
                              ) : (
                                <CheckBoxOutlineBlankIcon
                                  sx={{
                                    color: (theme) =>
                                      theme.palette.bluegrey[500],
                                  }}
                                />
                              ) : ""}
                            </ToggleButton>
                         </Tooltip>
                            <Tooltip title={cell.FieldLabel}>
                              {/* <p>{cell.FieldLabel}</p> */}
                              <Typography fontWeight={500}>
                                  {cell.FieldLabel}
                               </Typography>
                            </Tooltip>
                            <ResizableBox
                              axis="x"
                              height={20}
                              width={20}
                              style={{
                                textAlign: "left",
                                width: "100%",
                              }}
                              minConstraints={[20, 20]}
                            ></ResizableBox>
                          </div>
                        </TableCell>
                      );
                    }
                  } else {
                    return "";
                  }
                })}
              </TableRow>
            </TableHead>
            {this.getTableBody()}
          </Table>
        </TableContainer>
        {this.state.specificationsOpen && (
          <Upload
            currentItemIndex={this.state.currentItemIndex}
            product_id={
              this.props.selectedItems[this.state.currentItemIndex]?.id
            }
            randomId={this.props.randomId}
            master_type={"item"}
            handleUplaod={this.props.handleUplaod}
            open={this.state.specificationsOpen}
            handleClose={() => {
              this.setState({
                specificationsOpen: false,
              });  
            }}
            Alreadyexistingfiles = {this.props.itemSpecificFiles[this.props.selectedItems[this.state.currentItemIndex]?.id] || []}
          />
        )}
      </>
    );
  }
}
