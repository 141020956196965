import React, { useEffect, useState } from "react";
import Home from "./layout/Home";
import { styled } from "@mui/material/styles";
import MainHomePage from "./pages/MainHomePage";
import "./assets/css/main.css";
import { CssBaseline } from "@mui/material";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { ThemeProvider } from "@mui/system";
import SendToNew from "./pages/SendToNew";
import RfqDataGrid from "./pages/RFQList/rfqGridView/rfqDataGrid";
import LinkVendorItem from "./pages/RFQList/LinkVendorToItem/linkVendorItem";
import VendorViewGrid from "./pages/RFQList/VendorGridView/vendorViewGrid";
import ShowCombination from "./pages/ShowCombination";
import QuotationDetails from "./pages/QuotationForm/quotationForm";
import withClearCache from "./ClearCache";
import { Routes, Route, useLocation, useParams } from "react-router-dom";
import theme from "./theme/theme";
import { AppMainStyle } from "./theme/AppMainStyle";

import Header from "./layout/header/Header";
import LeftSidebar from "./layout/sidebar/LeftSidebar";
import Footer from "./layout/footer/Footer";
const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

function MainApp(props) {
    const location = useLocation();
  const params = useParams();
  const match = { params };

  useEffect(() => {
    LicenseInfo.setLicenseKey(
      "0e719ddd0cef8d9c99873b65f16810bcTz04Mzg1NCxFPTE3Mzg5OTI5MTQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
    );
  }, []);

  // This code for Sidebar Open close

  var body = document.body;
  const [open,setOpen] = useState(false);
  const onchange =()=>{
    setOpen((e)=>!e)
  };
  React.useEffect(() => {
    if(open){
      body.classList.add("open");
    }
    else{
      body.classList.remove("open");
    }
  }, [open])

  // End This code for Sidebar Open close
  const MainLayoutDiv = styled("div")(({ theme }) => ({
    display: "flex",
    flex: "1 1 auto",
    maxWidth: "100%",
    //paddingTop: 'var(--headerHeight)', // Use with Header
    paddingTop: 0, // Use without Header
    paddingLeft: 0, // Use without Sidebar
    //paddingLeft: 64, // Use with Sidebar
    flexGrow: 1,
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
  }));

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        {AppMainStyle}
        {/* <Header openchange={onchange}/> */}
        {/* <LeftSidebar open={open}/> */}

        <Home>
          <MainLayoutDiv className="main-layout">
            <Routes>
              <Route
                exact
                path="/"
                element={
                  <MainHomePage location={location} match={match} {...props} />
                }
              />
              <Route path="rfq-list-data-grid" element={<RfqDataGrid />} />
              <Route
                path="vendor-view-data-grid"
                element={<VendorViewGrid />}
              />
              <Route
                exact
                path="/send-to-new-vendor"
                element={
                  <SendToNew location={location} match={match} {...props} />
                }
              />
              <Route
                exact
                path="/prcombination"
                element={
                  <ShowCombination
                    location={location}
                    match={match}
                    {...props}
                  />
                }
              />
              <Route
                path="/quotation-form"
                element={<QuotationDetails />}
                location={location}
                match={match}
                {...props}
              />
              <Route path="link-vendor-item" element={<LinkVendorItem />} />
            </Routes>
          </MainLayoutDiv>
        </Home>
        {location?.pathname !== "/quotation-form" && (<Footer/>)}
      </CssBaseline>
    </ThemeProvider>
  );
}

export default App;
