import { styled } from '@mui/material/styles';
import Drawer from "@mui/material/Drawer";

export const ViewDetailsRight = styled(Drawer)( ({ theme }) => ({
    '&.ViewDetails-Sidebar':{
        '& .MuiPaper-root':{
            boxShadow: 'none',
            borderRadius: '0',
            border:'none'
        },
        '& .MuiDrawer-paper': {
            width: '500px',     
            boxSizing: 'border-box',
            [theme.breakpoints.down("sm")]: {
                width: '100%',
            }
        },
        '& .ViewDetails-Tabs':{
            '& .tabs-panel':{
                height: 'calc(100vh - 126px)',
                overflow: 'hidden',
                overflowY: 'auto'
            },
            '& .MuiTabs-root':{
                '.MuiTabs-flexContainer': {
                    // border: '1px solid #A6C1D3',
                    // borderRadius: '5px'
                },
                '& .MuiButtonBase-root': {
                    width: '50%',
                    minHeight: '36px',
                    border: '1px solid #A6C1D3',
                    borderRadius: '5px',
                    color:'secondary.600',
                    textTransform:'capitalize',
                    fontWeight:'400',
                    '&:first-child':{
                        borderTopRightRadius:'0',
                        borderBottomRightRadius:'0'
                    },
                    '&:last-child':{
                        borderTopLeftRadius:'0',
                        borderBottomLeftRadius:'0'
                    }
                },
                '& .Mui-selected':{
                    border:'1px solid var(--color-primary)',
                    background:'rgba(9, 107, 239, 0.20)',
                    color:'secondary.600',
                },
                '& .MuiTabs-indicator':{
                    display:'none'
                },
            },
            '& .tabs-panel .MuiBox-root': {
                padding: '0'
            }
        }
         
    },
}))