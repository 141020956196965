import {
  Typography,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Tooltip,
} from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getItemizedVendors,
  filterItemizedVendors,
} from "../../../redux/actions/vendorActions";
import { MoreVert } from "@mui/icons-material";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import RefreshIcon from "@mui/icons-material/Refresh";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ItemSpecificVendorsGrid from "./ItemSpecificVendorsGrid";

class ItemizedVendors extends Component {
  state = {
    anchorEl: null,
    VendorSearchInput: "",
    vendorSearchDebounceDelay: false,
    expanded: 'panel0'
  };

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  handleReload = () => {
    this.props.handleItemizeVendorsReload();
    this.handleClose();
  };

  handleSearchChange = (evt) => {
    this.setState({
      VendorSearchInput: evt.target.value,
    });
  };

  handleChange = (panel) => (event, isExpanded) => {
    this.setState({
      expanded: isExpanded ? panel : false
    })
  };

  render() {
    const {isRecipients} = this.props;
    let items = this.props.selectedItems;
    if (isRecipients) {
      items = items?.filter(item => {
        if (isRecipients === "local") {
          return parseInt(item.vendor_count.local_count) > 0
        } else {
          return parseInt(item.vendor_count.internation_count) > 0
        }
      })
    }
    return (
      <>
        { !isRecipients && <div className="card_top_bg">
           <Grid container>
            <Grid item lg={6} md={6} sm={10} xs={10}>
              <Typography>
                ITEMIZE VENDORS
              </Typography>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              sm={2}
              xs={2}
              className="top_right_content"
            >
              <Tooltip title="More">
                <IconButton onClick={this.handleClick} size="large">
                  <MoreVert />
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={this.state.anchorEl}
                keepMounted
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem
                  onClick={() => {
                    this.handleReload();
                  }}
                >
                  <RefreshIcon />
                  Reload
                </MenuItem>
                {(this.props.userInfo?.is_super_user === "Y" ||
                  this.props.masterPermission.vendor_master === "Y") && (
                  <MenuItem
                    onClick={() => {
                      if (this.props.companyInfo?.common_vendor_login === "Y") {
                        this.props.handleCompanyToAccountOpen();
                      } else {
                        this.props.handleAddVendorDialogOpen();
                      }

                      this.handleClose();
                    }}
                  >
                    <AddCircleOutlinedIcon />
                    Add Vendor
                  </MenuItem>
                )}
                {(this.props.userInfo?.is_super_user === "Y" ||
                  this.props.masterPermission.linking === "Y") && (
                  <MenuItem
                    onClick={() => {
                      // this.props.handleItemVendorLinkOpen();
                      this.props.handlelinkVendorToCategoryOpen();
                      this.handleClose();
                    }}
                  >
                    <InsertLinkIcon />
                    Link Unlink Vendor(s)
                  </MenuItem>
                )}
              </Menu>
            </Grid>
          </Grid>
        </div> }
        <Box sx={{ height: 400, overflowY: "auto" }}>
          {items &&
            items.map((item, index) => {
              return (
                <Accordion
                  expanded={this.state.expanded === `panel${index}`} onChange={this.handleChange(`panel${index}`)}
                  key={item.id}
                  TransitionProps={{ unmountOnExit: true }}
                  disableGutters
                  sx={{
                    ".MuiAccordionDetails-root": { p: 0 },
                    ".MuiDataGrid-root": { border: "none" },
                  }}
                >
                  <AccordionSummary
                    sx={{
                      backgroundColor: (theme) => theme.palette.grey[600],
                      color: (theme) => theme.palette.grey[100],
                    }}
                    expandIcon={
                      <ExpandMoreIcon
                        sx={{
                          color: (theme) => theme.palette.grey[100],
                        }}
                      />
                    }
                    aria-controls="panel1a-content"
                  >
                    <Tooltip title={item.name}>
                      <Typography noWrap>{item.name}</Typography>
                    </Tooltip>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ItemSpecificVendorsGrid
                      item={item}
                      selectedCategory={this.props.selectedCategory}
                      sessionId={this.props.sessionId}
                      handleAddVendorDialogOpen={
                        this.props.handleAddVendorDialogOpen
                      }
                      isRecipients={isRecipients}
                    />
                  </AccordionDetails>
                </Accordion>
              );
            })}
        </Box>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedCategory: state.categoryReducer.selectedCategory,
  selectedItems: state.itemReducer.selectedItems,
  sessionId: state.sessionReducer.sessionId,
  userInfo: state.userInfoReducer.userInfo,
  companyInfo: state.companyInfoReducer.companyInfo,
  masterPermission: state.permissionsReducer.masterPermission,
});

export default connect(mapStateToProps, {
  getItemizedVendors,
  filterItemizedVendors,
})(ItemizedVendors);
