import React from "react";
import axios from "axios";
import {
  Card,
  CardContent,
  Grid,
  Divider,
  TextField,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  CardHeader,
  Tooltip,
  InputAdornment,
  InputLabel,
  Autocomplete,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { Box } from "@mui/system";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import UploadIcon from "@mui/icons-material/CloudUploadRounded";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import "../../pages/style.css";
import moment from "moment";
import { CloudDownloadOutlined } from "@mui/icons-material/";
import { downloadmaterialDescription } from "../../config/apiUrl";
import { OPEN_SNACKBAR } from "../../redux/constants/type";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format, setHours, setMinutes, isAfter, isSameDay } from "date-fns"; // Import necessary functions from date-fns

const RFQStaticTemplate = ({
  data,
  getField,
  handleRFQStaticChange,
  handleDateChange,
  RFQStatic,
  setLock,
  setStar,
  isLockVisible,
  isLockDisable,
  isPriorityDisable,
  isRFQValidityDisable,
  categorySpecification,
  categorySpecificationFiles,
  termsAndConditions,
  termsAndConditionsTotalFiles,
  isContractRfqVisible,
  handleRFQvalidtyTimeChange,
  handleTBvalidtyTimeChange,
  includeTB,
  destination,
  userId,
  items,
  openSetting,
  isCCMailVisible,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setisLoading] = React.useState(false);
  const sessionID = useSelector((state) => state.sessionReducer.sessionId);
 
  const handleDatePickerOpen = () => {
    if (RFQStatic.RFQValidity.value && RFQStatic.RFQValidity.value._d < new Date()) {
      handleDateChange(
        moment(),
        RFQStatic.RFQValidity.HTMLAttributeName,
        "RFQStatic"
      );
    }
  };
  const handleDatepickerClick = () => {
    if (isRFQValidityDisable) {
      openSetting(true);
    }
  };
  var code = [];
  items.map((item) => {
    code.push(item.code);
  });
  const download = () => {
    setisLoading(true);
    const dest = destination?.split("-");
    if (destination == "") {
      dispatch({
        type: OPEN_SNACKBAR,
        payload: {
          type: "error",
          msg: "Please select destination first",
        },
      });
      setisLoading(false);

      return false;
    }
    axios({
      url: downloadmaterialDescription,
      method: "post",
      data: {
        buyerID: userId,
        pr_dest_code: dest[dest.length - 1],
        items: code,
      },
      Authorization: `Bearer ${sessionID}`,
      responseType: "blob",
    })
      .then((response) => {
        setisLoading(false);
        const href = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "Material Description.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((err) => {
        setisLoading(false);
        dispatch({
          type: OPEN_SNACKBAR,
          payload: {
            type: "error",
            msg: err?.response?.data.message
              ? err?.response?.data.message
              : err?.response?.statusText
              ? err?.response?.statusText
              : "Something went wrong!",
          },
        });
      });
  };

  return (
    <Card
      variant="outlined"
      className="template-card rfq_form_content local_recepients_card">
      <CardHeader
        title="RFQ FORM"
        titleTypographyProps={{
          variant: "h5",
          component: "h5",
          fontSize: "16px",
          fontWeight: "600",
          color: `grey.600`,
        }}
        action={
          <div>
            <Tooltip
              title={`${
                RFQStatic.star ? "Remove Priority RFQ" : "Set Priority RFQ"
              }`}
              placement="bottom">
              <Button
                size="medium"
                variant="icon-outline"
                style={{
                  marginRight: "16px",
                }}
                color={`${!RFQStatic.star ? "default" : "primary"}`}
                onClick={() => {
                  if (!isPriorityDisable) {
                    setStar((preVal) => !preVal);
                  }
                }}>
                {RFQStatic.star ? (
                  <StarIcon color="primary" />
                ) : (
                  <StarBorderIcon />
                )}
              </Button>
            </Tooltip>
            {isLockVisible && (
              <Tooltip
                title={`${
                  RFQStatic.lock ? "Remove Sealed RFQ" : "Set Sealed RFQ"
                }`}
                placement="bottom">
                <Button
                  size="medium"
                  variant="icon-outline"
                  style={{ marginRight: "16px" }}
                  color={`${!RFQStatic.lock ? "default" : "primary"}`}
                  onClick={() => {
                    if (!isLockDisable) {
                      setLock((preVal) => !preVal);
                    }
                  }}>
                  {RFQStatic.lock ? (
                    <LockIcon color="primary" />
                  ) : (
                    <LockOpenIcon />
                  )}
                </Button>
              </Tooltip>
            )}
          </div>
        }
      />
      <Divider sx={{ marginBottom: "0" }} />
      <CardContent>
        <Grid container spacing={3} className="local_recepients_card_content">
          <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
            <Box
              sx={{ display: "flex", justifyContent: "space-between" }}
              onClick={handleDatepickerClick}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                openTo="day" // Open the DatePicker to the day view directly
                open={null} // Set open prop to null to control the DatePicker dialog manually
                onOpen={handleDatePickerOpen} // Handle the click on date picker icon
                onClose={() => {}} // Keep the onClose empty
                  minDate={new Date()}
                  disabled={isRFQValidityDisable}
                  
                  sx={{ width: "100%" }}
                  format="dd/MM/yyyy"
                  slotProps={{
                    textField: {
                      size: "fullWidth",
                      required: true,
                      className: "rfq_template_Date_picker",
                      error: RFQStatic.RFQValidity.error,
                      label: "RFQ Validity",
                      helperText:
                        RFQStatic.RFQValidity.error &&
                        (RFQStatic.RFQValidity.value
                          ? RFQStatic.RFQValidity.ErrorMessage
                          : "required"),
                    },
                  }}
                  ampm="false"
                  name={RFQStatic.RFQValidity.HTMLAttributeName}
                  value={RFQStatic.RFQValidity.value ? new Date(RFQStatic.RFQValidity.value) : null}
                  onChange={(newVal) => {
                    handleDateChange(
                      moment(newVal),
                      RFQStatic.RFQValidity.HTMLAttributeName,
                      "RFQStatic"
                    );
                  }}
                />
                <Autocomplete
                  disableClearable
                  options={timeSlots}
                  disabled={
                    !RFQStatic.RFQValidity.value || isRFQValidityDisable
                  }
                  value={RFQStatic.RFQValidityTime.value || null}
                  onChange={(e, newVal) => handleRFQvalidtyTimeChange(newVal)}
                  getOptionDisabled={(option) => {
                    if (RFQStatic.RFQValidity.value) {
                      if (isSameDay(RFQStatic.RFQValidity.value, new Date())) {
                        let beginningTime = setMinutes(
                          setHours(new Date(), option.split(":")[0]),
                          option.split(":")[1]
                        );
                        let endTime = new Date();
                        return isAfter(endTime, beginningTime);
                      }
                    }
                  }}
                  sx={{ ml: 2, width: "50%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={RFQStatic.RFQValidityTime.error}
                      label="Validity Time (IST)"
                      fullWidth
                      helperText={
                        RFQStatic.RFQValidityTime.error &&
                        RFQStatic.RFQValidityTime.ErrorMessage
                      }
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
            {parseInt(RFQStatic.RFQValidity.maxDays) > 0 && (
              <Typography variant="caption" color="grey.500">
                Maximum RFQ validity{" "}
                {moment()
                  .add(RFQStatic.RFQValidity.maxDays, "days")
                  .format("DD/MM/YYYY")}
              </Typography>
            )}
          </Grid>
          {RFQStatic.MaterialDescription && (
            <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <TextField
                  disabled
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography>
                          Material Description (All Items)
                        </Typography>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          sx={{
                            mr: 1,
                            color: (theme) => theme.palette.grey[600],
                          }}
                          size="small"
                          onClick={() => {
                            download();
                          }}>
                          {isLoading ? (
                            <CircularProgress color="inherit" size="1rem" />
                          ) : (
                            <CloudDownloadOutlined color="primary" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Grid>
          )}
          {includeTB && (
            <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    minDate={new Date()}
                    format="dd/MM/yyyy"
                    slotProps={{
                      textField: {
                        size: "fullWidth",
                        sx: { width: "50%" },
                        required: true,
                        className: "rfq_template_Date_picker",
                        error: RFQStatic.TBValidity.error,
                        helperText:
                          RFQStatic.TBValidity.error &&
                         ( RFQStatic.TBValidity.value
                            ? RFQStatic.TBValidity.ErrorMessage
                            : "required"),
                      },
                    }}
                    // ampm={false}
                    label="TB Validity date"
                    name={RFQStatic.TBValidity.HTMLAttributeName}
                    value={RFQStatic.TBValidity.value ? new Date(RFQStatic.TBValidity.value) : null}
                    onChange={(newVal) => {
                      handleDateChange(
                        moment(newVal),
                        RFQStatic.TBValidity.HTMLAttributeName,
                        "RFQStatic"
                      );
                    }}
                  />
                </LocalizationProvider>
                <Autocomplete
                  disableClearable
                  options={timeSlots}
                  disabled={!RFQStatic.TBValidity.value}
                  value={RFQStatic.TBValidityTime.value || null}
                  onChange={(e, newVal) => handleTBvalidtyTimeChange(newVal)}
                  getOptionDisabled={(option) => {
                    if (RFQStatic.TBValidity.value) {
                      if (
                        moment(RFQStatic.TBValidity.value).isSame(
                          moment(),
                          "day"
                        )
                      ) {
                        let beginningTime = moment();
                        
                        beginningTime.set("hour", option.split(":")[0]);
                        beginningTime.set("minute", option.split(":")[1]);
                        let endTime = moment();
                        return endTime.isAfter(beginningTime);
                      }
                    }
                  }}
                  sx={{ ml: 2, width: "50%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={RFQStatic.TBValidityTime.error}
                      label="TB Validity Time (IST)"
                      fullWidth
                      helperText={
                        RFQStatic.TBValidityTime.error &&
                        RFQStatic.TBValidityTime.ErrorMessage
                      }
                    />
                  )}
                />
              </Box>
            </Grid>
          )}
          {data &&
            data.map((cell, i) => {
              if (cell.VisibilityFlag === "Y") {
                return (
                  <Grid item xl={3} lg={4} md={6} sm={6} xs={12} key={i}>
                    {getField(cell)}
                  </Grid>
                );
              }
            })}
          <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
            <Box
              sx={{
                border: "solid 1px",
                borderColor: (theme) => theme.palette.bluegrey[500],
                borderRadius: "4px",
                minWidth: "100%",
                height: "56px",
                display: "flex",
                alignItems: "center",
                padding: "0 10px",
                position: "relative",
              }}>
              <InputLabel
                sx={{
                  color: "text.secondary",
                  backgroundColor: (theme) => theme.palette.grey[0],
                  position: "absolute",
                  left: 0,
                  top: 0,
                  paddingLeft: "5px",
                  paddingRight: "5px",
                  transformOrigin: "top left",
                  webkitTransform: " translate(14px, -9px) scale(0.75)",
                  transform: "translate(14px, -9px) scale(0.75)",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "calc(133% - 24px)",
                  zIndex: 1,
                }}>
                Specifications
              </InputLabel>
              <Button
                variant="contained"
                color="primary"
                onClick={categorySpecification}
                size="small">
                <UploadIcon sx={{ marginRight: "10px" }} />
                Attachment
              </Button>
              {/* { categorySpecificationFiles?.length > 0 &&  */}
              <span
                style={{
                  marginLeft: "auto",
                  fontSize: "12px",
                  fontWeight: 400,
                  textTransform: "capitalize",
                }}>
                Number Of Files {categorySpecificationFiles?.length}
              </span>
              {/* } */}
            </Box>
            {/* { categorySpecificationFiles?.length > 0 && 
                <FormHelperText>
                  Number Of Files {categorySpecificationFiles?.length}
                </FormHelperText>
              } */}
          </Grid>
          {isCCMailVisible && (
            <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
              <TextField
                fullWidth
                className="local_template_textfield"
                multiline
                variant="outlined"
                maxRows={3}
                label="CC Users' mails"
                name={RFQStatic.RFQCCUsersmails.HTMLAttributeName}
                onBlur={handleRFQStaticChange}
                error={RFQStatic.RFQCCUsersmails.error}
                helperText={
                  RFQStatic.RFQCCUsersmails.error
                    ? RFQStatic.RFQCCUsersmails.ErrorMessage
                    : "(Use coma (,) to enter multiple email ids)"
                }
                InputLabelProps={{ shrink: RFQStatic.RFQCCUsersmails.value?.length > 0 || undefined }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Update CC Users' In Masters">
                        <Checkbox
                          color="primary"
                          onChange={handleRFQStaticChange}
                          name={
                            RFQStatic.RFQUpdateCCUsersCheck.HTMLAttributeName
                          }
                          checked={RFQStatic.RFQUpdateCCUsersCheck.value}
                          disabled={!RFQStatic.RFQCCUsersmails.value}
                        />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          )}
          {/* <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
            <FormControl
              component="fieldset"
             
            >
              <FormControlLabel
              sx={{ '& .MuiTypography-root':{ fontWeight:500,} }}
                control={
                  <Checkbox
                    color="primary"
                    onChange={handleRFQStaticChange}
                    name={RFQStatic.RFQUpdateCCUsersCheck.HTMLAttributeName}
                    checked={RFQStatic.RFQUpdateCCUsersCheck.value}
                  />
                }
                label="Update CC Users' In Masters:"
              />
             
            </FormControl>            
          </Grid> */}
          {isContractRfqVisible && (
            <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
              <FormControl component="fieldset">
                <FormControlLabel
                  sx={{ "& .MuiTypography-root": { fontWeight: 500 } }}
                  control={
                    <Checkbox
                      color="primary"
                      onChange={handleRFQStaticChange}
                      name={RFQStatic.RFQContactCheck.HTMLAttributeName}
                      checked={RFQStatic.RFQContactCheck.value}
                    />
                  }
                  label="Contract RFQ"
                />
              </FormControl>
              <FormHelperText className="checkbox_label_text">
                (if checkbox is selected then, this Rfq is Consider as Contract
                Rfq)
              </FormHelperText>
            </Grid>
          )}
          <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
            <Box
              sx={{
                border: "solid 1px",
                borderColor: (theme) => theme.palette.bluegrey[500],
                borderRadius: "4px",
                minWidth: "100%",
                height: "56px",
                display: "flex",
                alignItems: "center",
                padding: "0 10px",
                position: "relative",
              }}>
              <InputLabel
                sx={{
                  color: "text.secondary",
                  backgroundColor: (theme) => theme.palette.grey[0],
                  position: "absolute",
                  left: 0,
                  top: 0,
                  paddingLeft: "5px",
                  paddingRight: "5px",
                  transformOrigin: "top left",
                  webkitTransform: " translate(14px, -9px) scale(0.75)",
                  transform: "translate(14px, -9px) scale(0.75)",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "calc(133% - 24px)",
                  zIndex: 1,
                }}>
                Terms and Condition
              </InputLabel>
              <Button
                variant="contained"
                color="primary"
                onClick={termsAndConditions}
                size="small">
                <UploadIcon sx={{ marginRight: "10px" }} />
                Attachment
              </Button>
              <span
                style={{
                  marginLeft: "auto",
                  fontSize: "12px",
                  fontWeight: 400,
                  textTransform: "capitalize",
                }}>
                Number Of Files {termsAndConditionsTotalFiles}
              </span>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
// One time slot every 30 minutes.
const timeSlots = Array.from(new Array(24 * 2)).map(
  (_, index) =>
    `${index < 20 ? "0" : ""}${Math.floor(index / 2)}:${
      index % 2 === 0 ? "00" : "30"
    }`
);

export default RFQStaticTemplate;
