import React from "react";
import {Skeleton} from "@mui/material";
import { Box } from "@mui/system";

export default function CategorySkeleton() {
  return (
    <div>
    {[...Array(3).keys()].map((cv) => 
      <Box key={cv} sx={{display: 'flex', alignItems: 'center', width:'100%', padding: '8px 15px 8px 15px',}}>
        <Skeleton variant="rectangular" width={30} height={25} animation="wave" sx={{borderRadius: '4px',}} />
        <Box sx={{ml:'10px', width:'90%', }}>
          <Skeleton animation="wave" />        
        </Box>
      </Box> 
    )}
  </div>    
  );
}
