import React, { Fragment, useEffect, useState } from "react";
import {
  get_All_Inquiries_Data,
  get_Vendors_Data,
  multiple_inquiry_jqgrid
} from "../../../../config/apiUrl";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  Alert,
  Autocomplete,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Popover,
  Select,
  Snackbar,
  TextField
} from "@mui/material";
import {
  MenuProps,
  PrOptions,
  QcsOptions,
  useStyles
} from "../../../../utils/RFQList/utils";
import axios from "axios";
import { setRfqListInfo } from "../../../../redux/actions/rfqListAction";
import { useDispatch,useSelector } from "react-redux";

const RFQSearchModal = ({
  sessionID,
  showModal,
  CloseSearchFilterModal,
  onFilterChange,
  From = '',
  sendFlagToParent,
  value,
  FilteredListDatas
}) => {

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [childFlag, setChildFlag] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [isVendorLoading, setIsVendorLoading] = useState(false);
  const [optionsData, setOptionsData] = useState([]);
  const [optionsVendorData, setOptionsVendorData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isVendorOpen, setIsVendorOpen] = useState(false);
  const [vendorName, setVendorName] = useState("");
  const [itemName, setItemName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [itemNameCode, setItemNameCode] = useState([]);
  const [vendorNameCode, setVendorNameCode] = useState([]);
  const [prNo, setPrNo] = useState("");
  const [prLine, setPrLine] = useState("");
  const [prStatus, setPrStatus] = useState([]);
  const [qcsStatus, setQcsStatus] = useState([]);
  const [selectedVendorNameCode, setSelectedVendorNameCode] = useState([]);
  const [selectedItemNameCode, setSelectedItemNameCode] = useState([]);
  const [selectedVendorOptions, setSelectedVendorOptions] = useState({
    vendorNameCode: []
  });
  const userInfo = useSelector((state) => state.userInfoReducer.userInfo);
  const period  = value ? value[0] : {};

  const [selectedOptions, setSelectedOptions] = useState({
    itemNameCode: []
  });

  const handleCloseSnackbar = () => {
    setSnackbar(false);
  };

  const configAxios = {
    headers: { Authorization: `Bearer ${sessionID}` }
  };
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const classes = useStyles();
  let fetchTimeout = null;

  const isAllSelectedQCS =
    QcsOptions.length > 0 && qcsStatus.length === QcsOptions.length;
  const isAllSelectedPR =
    PrOptions.length > 0 && prStatus.length === PrOptions.length;

  const handleChangeQcsStatus = (event) => {
    const selectedValues = event.target.value;
    if (selectedValues.includes("all")) {
      if (selectedValues.length === QcsOptions.length + 1) {
        // If all options are already selected, deselect all
        setQcsStatus([]);
      } else {
        // Select all options
        const allOptionValues = QcsOptions.map((option) => option.value);
        setQcsStatus(allOptionValues);
      }
    } else {
      // Remove "all" from selected values (if present)
      const filteredValues = selectedValues.filter((value) => value !== "all");
      setQcsStatus(filteredValues);
    }
  };

  const handleChangePrStatus = (event) => {
    const selectedValues = event.target.value;

    if (selectedValues.includes("all")) {
      if (selectedValues.length === PrOptions.length + 1) {
        // If all options are already selected, deselect all
        setPrStatus([]);
      } else {
        // Select all options
        const allOptionValues = PrOptions.map((option) => option.value);
        setPrStatus(allOptionValues);
      }
    } else {
      // Remove "all" from selected values (if present)
      const filteredValues = selectedValues.filter((value) => value !== "all");
      setPrStatus(filteredValues);
    }
  };

  const handleApplyClick = async () => {
    sendFlagToParent(true);

    const prStatusParam =
      prStatus?.length > 0
        ? prStatus.map((status) => `pr_status[]=${status}`).join("&")
        : "";
    const qcsStatusParam =
      qcsStatus?.length > 0
        ? qcsStatus.map((status) => `qcs_status[]=${status}`).join("&")
        : "";

    const itemNameCodeParam =
      itemNameCode?.length > 0
        ? itemNameCode.map((status) => `item_ids[]=${status}`).join("&")
        : "";

    const vendorNameCodeParam =
      vendorNameCode?.length > 0
        ? vendorNameCode.map((status) => `vendor_ids[]=${status}`).join("&")
        : "";

    const queryParams = [
      prStatusParam,
      qcsStatusParam,
      itemNameCodeParam,
      vendorNameCodeParam,
      prNo && `pr_no=${prNo}`,
      prLine && `pr_line=${prLine}`,

    ]
      .filter((param) => param)
      .join("&");


    let apiUrl = get_All_Inquiries_Data + `?${queryParams}&start_date=${period.start}&end_date=${period.end}`;

    if(!queryParams){
      handleClickCancel();
    }

    // if (From == 'Supplier') {
    //   apiUrl = `${apiUrl}&vendor_ids[]=${userInfo?.UserID}&embed[]=vendor`;
    // }


    let response;

    if (From === 'Supplier') {
      apiUrl = `${apiUrl}&vendor_ids[]=${userInfo?.UserID}&embed[]=vendor`;
      response = await axios.get(apiUrl, configAxios);
    } else {
      response = "filter data";
    }

    try {  
      if (response.data && response.data.inquires) {
        const filteredData = response.data.inquires;
        onFilterChange(filteredData);
        FilteredListDatas(queryParams)
        setChildFlag(true);
        CloseSearchFilterModal();
        sendFlagToParent(false);
      } else if(response = "filter data"){
        FilteredListDatas(queryParams)
        setChildFlag(true);
        CloseSearchFilterModal();
        sendFlagToParent(false);

      }else {
        // Handle empty data case
        onFilterChange([]);
        CloseSearchFilterModal();
        setSnackbar(true);
        sendFlagToParent(false);
      }  
    } catch (error) {
      console.error("Error fetching data:", error);
      onFilterChange([]);
      CloseSearchFilterModal();
      setSnackbar(true);
      sendFlagToParent(false);
      setErrorMessage("Filtered Data Not Found");
    }
  };

  // A function to clear the textFields & close the modal...
  const handleClickCancel = async() => {
    if (From != 'Supplier') {
      // dispatch(setRfqListInfo(sessionID
      FilteredListDatas([]);
        }
    setVendorName("");
    setItemName("");
    setItemNameCode([]);
    setVendorNameCode([]);
    setPrLine("");
    setPrNo("");
    setIsLoading(false);
    setIsVendorLoading(false);
    setIsOpen(false);
    setQcsStatus([]);
    setPrStatus([]);
    // setSelectedOptions([]);
    setOptionsData([]);
    setOptionsVendorData([]);
    CloseSearchFilterModal();
    setSelectedVendorNameCode([]);
    setSelectedItemNameCode([]);
    onFilterChange([]);
  };

  const fetchCategories = () => {
    if (!itemName || itemName.length < 2) {
      setOptionsData([]); // Clear the options if itemName is undefined or too short
      setIsLoading(false); // Set to false when the text field is empty
      return;
    }

    setIsLoading(true); // Set to true when data is being fetched

    const params = new URLSearchParams({
      action: 30,
      name_startsWith: itemName,
      type: "item_name_code",
      // sessionID: sessionID, //It will set current Set current session ID
      logged_in_user_type: From != 'Supplier' ? "buyer" : 'vendor'
    });

    const apiUrl = multiple_inquiry_jqgrid + `?${params}`;

    axios
      .get(apiUrl, configAxios)
      .then((response) => {
        const resArr = response.data;

        const availableOptions = resArr.filter(
          (option) => !selectedOptions.itemNameCode.includes(option.item_id)
        );

        setOptionsData(availableOptions);
      })
      .catch((error) => {
        console.log("err", error);
        // Handle error here
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchVendorName = () => {
    if (!vendorName || vendorName.length < 2) {
      setOptionsVendorData([]); // Clear the options if vendorName is undefined or too short
      setIsVendorLoading(false); // Set to false when the text field is empty
      return;
    }

    setIsVendorLoading(true); // Set to true when data is being fetched

    const url =
      get_Vendors_Data +
      `?pagination=false&vendor_code[like]=${vendorName}%&or[vendor_name][like]=${vendorName}%`;

    axios
      .get(url, configAxios)
      .then((response) => {
        const resArr = response?.data?.data;
        const availableOptions = resArr.filter(
          (option) =>
            !selectedVendorOptions?.vendorNameCode?.includes(option?.vendor_id)
        );

        setOptionsVendorData(availableOptions);
      })
      .catch((error) => {
        console.log("err", error);
        // Handle error here
      })
      .finally(() => {
        setIsVendorLoading(false); // Set to false when data fetching is complete
      });
  };

  useEffect(() => {
    clearTimeout(fetchTimeout); // Clear the previous timeout if present
    if (itemName?.length > 2) {
      fetchTimeout = setTimeout(fetchCategories, 500); // Set a new timeout
    }
    if (vendorName?.length > 2) {
      fetchTimeout = setTimeout(fetchVendorName, 500); // Set a new timeout
    }
    // Clear the timeout when component unmounts
    return () => clearTimeout(fetchTimeout);
  }, [itemName, vendorName]);

  return (
    <Fragment>
      <Popover
        open={showModal}
        // hideBackdrop={true}
        className="SearchFltr-Popover"
        sx={{
          top: "135px",
          left:"64px",
          ".open &.SearchFltr-Popover":{
            left: "240px"
          },
          "&.SearchFltr-Popover > .MuiPaper-root": {
            boxShadow: "none",
            border: "1px solid var(--bluegrey-500)",
            width: "480px",
            "@media screen and (max-width:1239px)":{
              width: "400px",
            },
            "@media screen and (max-width:899px)":{
              width: "100%",
            }
          }
        }}
        onClose={CloseSearchFilterModal}
      >
        <Card
          sx={{
            // maxWidth: "480px",
            boxShadow: "none",
            "@media only screen and (max-width: 991.5px)": {
              maxWidth: "100%"
            }
          }}
        >
          <CardContent>
         {From == 'Supplier'?
          <Grid item xs={12} sm={5} md={5}>
          <Autocomplete
            multiple
            limitTags={2}
            id="checkboxes-tags-demo"
            options={optionsData}
            freeSolo={itemName?.length > 2 ? false : true}
            loading={itemName?.length > 2 ? isLoading : ""}
            disableCloseOnSelect
            value={selectedItemNameCode}
            onChange={(e, newValue) => {
              setSelectedItemNameCode(newValue); // Update selected options
              setItemNameCode(
                newValue.map((option) => option.item_id)
              );
              setIsLoading(false);
            }}
            onInputChange={(e, newValue, reason) => {
              if (newValue.trim() == "" && reason == "input") {
                setOptionsData([]);
                setIsLoading(false); // Set to false when the autocomplete textfield is empty
              } else {
                setItemName(e.target.value);
                setIsOpen(true);
                setIsLoading(true); // Set to true when data fetching starts
              }
            }}
            getOptionLabel={(option) =>
              `${option.item_name}`
            }
            renderOption={(props, option, { selected }) => (
              (
                <li
                  {...props}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}
                >
                  <span>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      checked={selected}
                    />
                    {option.item_name ||
                      option.item_code ||
                      option.key}
                  </span>
                  {option.type === "no_data" ? (
                    <span>No Records Found</span>
                  ) : null}
                </li>
              )
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Item Name/Code"
                placeholder="Item Name/Code"
                size="small"
              />
            )}
          />
        </Grid>
         : <Grid container spacing={2}>
              <Grid item xs={12} sm={5} md={5}>
                <Autocomplete
                  multiple
                  limitTags={2}
                  id="checkboxes-tags-demo"
                  options={optionsVendorData}
                  freeSolo={vendorName?.length > 2 ? false : true}
                  loading={vendorName?.length > 2 ? isVendorLoading : ""}
                  disableCloseOnSelect
                  value={selectedVendorNameCode}
                  onChange={(e, newValue) => {
                    setSelectedVendorNameCode(newValue); // Update selected options
                    setVendorNameCode(
                      newValue.map((option) => option.vendor_id)
                    );
                    setIsVendorLoading(false);
                  }}
                  onInputChange={(e, newValue, reason) => {
                    if (newValue.trim() == "" && reason == "input") {
                      setOptionsVendorData([]);
                      setIsVendorLoading(false); // Set to false when the autocomplete textfield is empty
                    } else {
                      setVendorName(e.target.value);
                      setIsVendorOpen(true);
                      setIsVendorLoading(true); // Set to true when data fetching starts
                    }
                  }}
                  getOptionLabel={(option) => 
                    `${option.vendor_name} [${option.vendor_code}]`
                  }
                  renderOption={(props, option, { selected }) => (
                    (
                      <li
                        {...props}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between"
                        }}
                      >
                        <span>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            checked={selected}
                          />
                          {`${option?.vendor_name} [${option?.vendor_code}]`}
                          
                        </span>
                        {option.type === "no_data" ? (
                          <span>No Records Found</span>
                        ) : null}
                      </li>
                    )
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Vendor Name/Code"
                      placeholder="Vendor Name/Code"
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={7} md={7}>
                <FormControl
                  className={classes.formControl}
                  fullWidth
                  size="small"
                >
                  <InputLabel id="mutiple-select-label">PR Status</InputLabel>
                  <Select
                    labelId="mutiple-select-label"
                    multiple
                    fullWidth
                    value={prStatus}
                    onChange={handleChangePrStatus}
                    placeholder="PR Status"
                    input={<OutlinedInput label="PR Status" />}
                    renderValue={(selected) =>
                      selected
                        .map(
                          (value) =>
                            PrOptions.find((option) => option.value === value)
                              ?.label
                        )
                        .join(", ")
                    }
                    MenuProps={MenuProps}
                  >
                    <MenuItem
                      value="all"
                      classes={{
                        root: isAllSelectedPR ? classes.selectedAll : ""
                      }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          sx={{ color: "#096bef" }}
                          checked={isAllSelectedPR}
                          indeterminate={
                            prStatus.length > 0 &&
                            prStatus.length < PrOptions.length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: classes.selectAllText }}
                        primary="Select All"
                      />
                    </MenuItem>
                    {PrOptions.map((option) => (
                      <MenuItem key={option.id} value={option.value}>
                        <ListItemIcon>
                          <Checkbox
                            checked={prStatus.indexOf(option.value) > -1}
                            sx={{ color: "#096bef" }}
                          />
                        </ListItemIcon>
                        <ListItemText primary={option.label} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={5} md={5}>
                <Autocomplete
                  multiple
                  limitTags={2}
                  id="checkboxes-tags-demo"
                  options={optionsData}
                  freeSolo={itemName?.length > 2 ? false : true}
                  loading={itemName?.length > 2 ? isLoading : ""}
                  disableCloseOnSelect
                  value={selectedItemNameCode}
                  onChange={(e, newValue) => {
                    setSelectedItemNameCode(newValue); // Update selected options
                    setItemNameCode(
                      newValue.map((option) => option.item_id)
                    );
                    setIsLoading(false);
                  }}
                  onInputChange={(e, newValue, reason) => {
                    if (newValue.trim() == "" && reason == "input") {
                      setOptionsData([]);
                      setIsLoading(false); // Set to false when the autocomplete textfield is empty
                    } else {
                      setItemName(e.target.value);
                      setIsOpen(true);
                      setIsLoading(true); // Set to true when data fetching starts
                    }
                  }}
                  getOptionLabel={(option) =>
                    `${option.item_name}`
                  }
                  renderOption={(props, option, { selected }) => (
                    (
                      <li
                        {...props}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between"
                        }}
                      >
                        <span>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            checked={selected}
                          />
                          {option.item_name ||
                            option.item_code ||
                            option.key}
                        </span>
                        {option.type === "no_data" ? (
                          <span>No Records Found</span>
                        ) : null}
                      </li>
                    )
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Item Name/Code"
                      placeholder="Item Name/Code"
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={7} md={7}>
                <FormControl
                  className={classes.formControl}
                  fullWidth
                  size="small"
                >
                  <InputLabel id="mutiple-select-label">QCS Status</InputLabel>
                  <Select
                    labelId="mutiple-select-label"
                    multiple
                    fullWidth
                    value={qcsStatus}
                    onChange={handleChangeQcsStatus}
                    placeholder="QCS Status"
                    input={<OutlinedInput label="QCS Status" />}
                    renderValue={(selected) =>
                      selected
                        .map(
                          (value) =>
                            QcsOptions.find((option) => option.value === value)
                              ?.label
                        )
                        .join(", ")
                    }
                    MenuProps={MenuProps}
                  >
                    <MenuItem
                      value="all"
                      classes={{
                        root: isAllSelectedQCS ? classes.selectedAll : ""
                      }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          sx={{ color: "#096bef" }}
                          checked={isAllSelectedQCS}
                          indeterminate={
                            qcsStatus.length > 0 &&
                            qcsStatus.length < QcsOptions.length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: classes.selectAllText }}
                        primary="Select All"
                      />
                    </MenuItem>
                    {QcsOptions.map((option) => (
                      <MenuItem key={option.id} value={option.value}>
                        <ListItemIcon>
                          <Checkbox
                            checked={qcsStatus.indexOf(option.value) > -1}
                            sx={{ color: "#096bef" }}
                          />
                        </ListItemIcon>
                        <ListItemText primary={option.label} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={5} md={5}>
                <TextField
                  id="outlined-basic"
                  label="PR No"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={prNo}
                  onChange={(e) => setPrNo(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={7} md={7}>
                <TextField
                  id="outlined-basic"
                  label="PR Line"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={prLine}
                  onChange={(e) => setPrLine(e.target.value)}
                />
              </Grid>
            </Grid>}
          </CardContent>
          <CardActions sx={{ p: 2, pt: 0, justifyContent: "flex-end" }}>
            {/* <Button variant="outlined"> */}
            <Button
              onClick={() => handleClickCancel()}
              variant="outlined"
              size="small"
            >
              Reset
            </Button>
            <Button
              onClick={() => {
                handleApplyClick();
              }}
              variant="contained"
              size="small"
            >
              Apply
            </Button>
          </CardActions>
        </Card>
      </Popover>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={3000}
        open={snackbar}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity="error" variant="filled">
          {errorMessage}
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default RFQSearchModal;