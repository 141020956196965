import { Box, Popover, Tooltip, Typography } from "@mui/material";
import React from "react";

const JustificationPopOver = ({
  open,
  onClose,
  anchorEl,
  justificationTitle,
  justificationDetails,
}) => {
  return (
    <Box>
      <Popover
        id="mouse-over-popover"
        open={open}
        onClose={onClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          '& .MuiPaper-root':{
            maxWidth:"365px",
            p:2,
            '@media only screen and (max-width: 600px)':{
              maxWidth:"265px",
              p:1,
            }
          }          
        }}
        disableRestoreFocus>
        
        <Typography variant="subtitle2" sx={{mb:1}}>
          {justificationTitle}
        </Typography>
        
        <Typography variant="body2">
          {justificationDetails}
        </Typography>
      </Popover>
    </Box>
  );
};
export default JustificationPopOver;
