import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Autocomplete, IconButton, TextField, Checkbox, InputAdornment, Tooltip } from "@mui/material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { ErrorOutline } from "@mui/icons-material";


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function ContactPersonDetails({
  rows,
  handleTab2Change,
  handleTab2Blur,
  handleDelete,
  destinationOptions
}) {
  return (
    <TableContainer component={Paper} sx={{border:1, borderColor: (theme) => theme.palette.bluegrey[500], borderRadius: '4px 4px 0 0', boxShadow:'none',}}>

      <form id="novalidatedform" noValidate>
        <Table sx={{ minWidth: 650 }} >
          <TableHead>
            <TableRow>
              <TableCell>DESTINATION</TableCell>
              <TableCell>CONTACT PERSON NAME</TableCell>
              <TableCell>CONTACT PERSON EMAIL</TableCell>
              <TableCell>CONTACT NO.</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={index}
                hover
                sx={{ "&:last-child td, &:last-child th": { border: 0 }, "& td": {padding:'10px 16px'} }}
              >
                <TableCell component="td" scope="row" sx={{width: '360px'}}>
                  {/* {row.name} */}
                  <Autocomplete
                    disablePortal
                    multiple
                    size="small"
                    options={destinationOptions}
                    fullWidth
                    limitTags={1}
                    name="destination"
                    getOptionLabel={(option) => option.dropdown_element_name}
                    value={row.destination["value"]}
                    sx={{ '& .MuiOutlinedInput-root': {paddingRight: '30px !important'}, }}
                    onChange={(e, newValue) => {
                      // console.log(e.target.name,e.target.name )
                      let event = {target : {name: 'destination', value:newValue }}
                      handleTab2Change(event, index, newValue);
                    }}
                    
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.dropdown_element_name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Destination"                     
                        error={row.destination["error"]}
                        onBlur={() => {
                          // console.log(e.target.name,e.target.name )
                          let event = {target : {name: 'destination', value:row.destination["value"] }}
                          handleTab2Blur(event, index, row.destination["value"]);
                        }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {row.destination["error"] ? 
                                <Tooltip title={"Please Enter Value for Destination."}>
                                  <ErrorOutline style={{ color: "red" }} />
                                </Tooltip> : null
                              }
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                  {/* <OutlineTextfieldLabel
                    className="template_Date_picker"
                    type="date"
                    name="destination"
                    value={row.destination}
                    onChange={(e) => {
                      handleTab2Change(e, index);
                    }}
                    // InputProps={{
                    //   endAdornment: (
                    //     <InputAdornment position="end">
                    //       <IconButton
                    //         type="submit"
                    //         size="large"
                    //       >
                    //         <EventIcon />
                    //       </IconButton>
                    //     </InputAdornment>
                    //   ),
                    // }}
                  /> */}
                </TableCell>
                <TableCell align="right">
                  <TextField
                    className="local_template_textfield"
                    label="Name"
                    name="contactPersonName"
                    size="small"
                    value={row.contactPersonName["value"]}
                    onChange={(e) => {
                      handleTab2Change(e, index);
                    }}
                    error={row.contactPersonName["error"]}
                    onBlur={(e) => {
                      handleTab2Blur(e, index);
                    }}
                    variant="outlined"
                    required
                    // form="novalidatedform"
                    fullWidth
                    margin="normal"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" >
                          {row.contactPersonName["error"] && 
                            <Tooltip title={row.contactPersonName["value"] ? "Name of Contact Person should be aleast 3 characters." : "Please Enter Value for Contact Person Name."}>
                              <ErrorOutline style={{ color: "red" }} />
                            </Tooltip>
                          }
                        </InputAdornment>
                      ),
                    }}
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    className="local_template_textfield"
                    label="Email"
                    size="small"
                    name="contactPersonEmail"
                    value={row.contactPersonEmail["value"]}
                    error={row.contactPersonEmail["error"]}
                    onChange={(e) => {
                      handleTab2Change(e, index);
                    }}
                    onBlur={(e) => {
                      handleTab2Blur(e, index);
                    }}
                    variant="outlined"
                    required
                    fullWidth
                    margin="normal"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" > 
                          {row.contactPersonEmail["error"] && 
                            <Tooltip title={row.contactPersonEmail["value"] ? "Please enter valid Email Id of the Contact Person." : "Please Enter Value for Contact Person Email."}>
                              <ErrorOutline style={{ color: "red" }} />
                            </Tooltip>
                          }
                        </InputAdornment>
                      ),
                    }}
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    size="small"
                    className="local_template_textfield"
                    label="Contact"
                    name="contactNumber"
                    value={row.contactNumber["value"]}
                    error={row.contactNumber["error"]}
                    onChange={(e) => {
                      handleTab2Change(e, index);
                    }}
                    onBlur={(e) => {
                      handleTab2Blur(e, index);
                    }}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" > 
                          {row.contactNumber["error"] && 
                            <Tooltip title={"Contact Person Mobile Number should be numeric and can contain (+) & should be atleast 10 digit in length and Max. 15 digit in length"}>
                              <ErrorOutline style={{ color: "red" }} />
                            </Tooltip>
                          }
                        </InputAdornment>
                      ),
                    }}
                  />
                </TableCell>
                <TableCell align="right">
                  <IconButton
                    onClick={() => {
                      handleDelete(index);
                      // this.state.contactPersonDetails.splice(index, 1);
                      // this.setState({
                      //   contactPersonDetails: this.state.contactPersonDetails,
                      // });
                    }}
                    size="large"
                  >
                    <RemoveCircleIcon style={{ color: "var(--thm-red)" }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </form>
    </TableContainer>
  );
}


