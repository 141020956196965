import { styled } from '@mui/material/styles';
import Drawer from "@mui/material/Drawer";

export const QuatationDestinationDrawerRight = styled(Drawer)( ({ theme }) => ({
    "&.SidebarDrawer":{        
        '& .MuiPaper-root':{
            boxShadow: 'none',
            borderRadius: '0',
        },
        "& .MuiDrawer-paper": {
            width: '550px',
            [theme.breakpoints.down("sm")]: {
                width: '100%',
            }
        },
    },    
})
)