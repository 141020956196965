import { SET_USER_INFO, SET_USER_INFO_ERROR } from "../constants/type";
const initialState = {
  userInfo: [],
  userInfoError: "",
};

const userInfoReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_USER_INFO:
      return { userInfo: payload.data, userInfoError: "" };
    case SET_USER_INFO_ERROR:
      return { userInfo: [], userInfoError: payload };
    default:
      return state;
  }
};

export default userInfoReducer;
