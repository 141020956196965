import React, { useState, useEffect, useCallback } from "react";
import Button from "@mui/material/Button";
import {
  Chip,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popper,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { Paper, DialogContent, DialogActions } from "@mui/material";
import { debounce } from "lodash";
import { searchCategoriesByNameOrCode } from "../../config/apiUrl";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedCategory } from "../../redux/actions/categoryAction";
import AddCategoryDialog from "../Dialogs/AddCategory";
import AddIcon from "@mui/icons-material/Add";
import { useStyles } from "./style";
import { resetItems } from "../../redux/actions/itemActions";
import {
  resetFilteredVendors,
  resetVendors,
} from "../../redux/actions/vendorActions";
import { resetReminderSettings } from "../../redux/actions/drawerActions";
import ConfirmAlert from "../Dialogs/ConfirmAlert.js/index.js";
import { OPEN_SNACKBAR } from "../../redux/constants/type";
import { resetCAT } from "../../redux/resetApp";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import AddBoxIcon from '@mui/icons-material/AddBox';
import {useNavigate} from 'react-router-dom'
import CategorySkeleton from "../Loaders/CategorySkeleton";

export default function CategoryPopper({resetSettingDrawer, isSuperUser, categoryMasterPermission}) { 
  const classes = useStyles(); 
  const navigate = useNavigate();
  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [inputVal, setInputVal] = useState("");
  const [categoryItems, setCategoryItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  // const [newCategory, setnewCategory] = useState(null);
  const [addCatDialog, setAddCatDialog] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const { sessionId } = useSelector((reducer) => reducer.sessionReducer);
  const { selectedCategory } = useSelector(
    (reducer) => reducer.categoryReducer
  );

  useEffect(() => {
    if (selectedCategory) {
      setInputVal(selectedCategory?.name);
      setSelected(selectedCategory);
    } else {
      setSelected(null);
    }
  }, [selectedCategory]);

  const handleScrollend = () => {
    if (currentPage !== lastPage) {
      setPaginationLoading(true);
      fetchCategories(inputVal.trim(), currentPage + 1);
    }
  }

  const loader = React.useRef(handleScrollend);

  const observer = React.useRef(
    new IntersectionObserver(
      entries => {
        const first = entries[0];
        if (first.isIntersecting) {
          loader.current();
        }
      },
      { threshold: 1 }
    )
  );
  const [element, setElement] = React.useState(null);

  React.useEffect(() => {
    const currentElement = element;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [element]);

  useEffect(() => {
    loader.current = handleScrollend;
  },[handleScrollend]);

  const dispatch = useDispatch();

  const handleClick = (category) => {
    setOpen((prevOpen) => !prevOpen);
    setInputVal(category.name);
    setSelected(category);
    dispatch(setSelectedCategory(category));
    dispatch(resetItems());
    dispatch(resetVendors());
    dispatch(resetFilteredVendors());
    dispatch(resetReminderSettings());
  };
  const handleChange = (evt) => {
    setInputVal(evt.target.value);
    setCategoryItems([]);
    if (evt.target.value.trim() === "") {
      setOpen(false);
    } else {
      setOpen(true);
      setLoading(true);
      setCurrentPage(1);
      setLastPage(1);
      getApi(evt.target.value.trim(), 1);
    }
  };
  const getApi = useCallback(
    debounce((value, page) => {
      fetchCategories(value, page);
    }, 1000),
    [sessionId]
  );

  const fetchCategories = (value, page) => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${sessionId}`;
    const url = searchCategoriesByNameOrCode(value)
    axios
      .get(`${url}&page=${page}`)
      .then((response) => {

        if(page === 1){
          setCategoryItems(response.data.data);
          setLastPage(response.data.meta.last_page)
        } else {
          setCategoryItems( (preCat) => [...preCat, ...response.data.data]);
        }
        setCurrentPage(response.data.meta.current_page)
        setLoading(false);
        setPaginationLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setPaginationLoading(false);
        dispatch({
          type: OPEN_SNACKBAR,
          payload: {
            type: "error",
            msg: error?.response?.data.message
              ? error?.response?.data.message
              : "Something went wrong",
          },
        });
        setCategoryItems([]);
      });
  }

  
  const handleDelete = () => {
    setSelected(null);
    setInputVal("");
    dispatch(resetItems());
    dispatch(resetVendors());
    dispatch(resetFilteredVendors());
    dispatch(setSelectedCategory(null));
    setConfirmDialogOpen(false);
    resetSettingDrawer();
    dispatch(resetCAT());
    // navigate(`/`)
  };
  return (
    <>
      {addCatDialog && <AddCategoryDialog
        open={addCatDialog}
        handleClose={() => setAddCatDialog(false)}
      />}
      <ConfirmAlert
        open={confirmDialogOpen}
        handleClose={() => {
          setConfirmDialogOpen(false);
        }}
        handleSubmit={handleDelete}
        msg="Changing category will clear existing data added in the form. Are
        you sure you want to continue?"
        title="Change category?"
        // newCategory={newCategory}
      />
      <ClickAwayListener
        onClickAway={() => {
          setOpen(false);
          if (selected === null) {
            setInputVal("");
          } else {
            setInputVal(selected.name);
          }
        }}
      >
        <div className="main_input_outline category-Search" id="main_input_outline">
          {selected === null ? (
            <form id="novalidatedform" noValidate autoComplete="off" onSubmit={(e) => e.preventDefault()}>
              <TextField size="small"              
                className={classes.CategorySearchInput}
                placeholder="Search By Category"
                ref={anchorRef}
                value={inputVal}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {/* <Search /> */}
                      <SearchOutlinedIcon />
                      {/* <img src={searchicon} alt="Search Icon" /> */}
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {inputVal && (
                        <IconButton
                          onClick={() => {
                            setInputVal("");
                            setOpen(false);
                            //reset category, cart, templates
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </form>
          ) : (
            <Chip
              component="a"
              className={classes.categoryNameChip}             
              label={`${selected.name} [${selected.code}]`}
              onDelete={() => setConfirmDialogOpen(true)}
            />
          )}
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            placement="bottom-start"
            disablePortal={false}
            style={{
              zIndex: "1000",
            }}
            modifiers={[
              {
                name: "flip",
                enabled: true,
                options: {
                  altBoundary: true,
                  rootBoundary: "document",
                  padding: 8,
                },
              },
              {
                name: "preventOverflow",
                enabled: true,
                options: {
                  altAxis: true,
                  altBoundary: true,
                  tether: true,
                  rootBoundary: "document",
                  padding: 8,
                },
              },
            ]}
          >
            {/* <ClickAwayListener onClickAway={handleClose}> */}
            <Paper
              className="main_input_outline1 category-result"
              sx={{
                marginTop: "1rem",
                boxShadow: "0px 10px 8px 0px rgb(0 0 0 / 16%)",
                border: 1,
                borderColor: `bluegrey.500`,
                overflow: 'hidden'
              }}
            >
              <DialogContent
                className="category_dropdownlist"
                sx={{ 
                  maxHeight: "170px",
                  overflowY: "auto",
                  padding: 0,
                }}
              >
                <List sx={{minWidth: '325px', maxWidth: '500px', }}>
                  {loading ? (
                    <CategorySkeleton /> 
                  ) : categoryItems && categoryItems.length > 0 ? (
                    categoryItems.map((category, index) => (
                      <ListItem disablePadding key={index}>
                        <ListItemButton
                          sx={{
                            padding: "5px 15px 0",
                          }}
                          onClick={() => {
                            if (selected === null) {
                              handleClick(category);
                            } else {
                              // setnewCategory(category);
                              setConfirmDialogOpen(true);
                            }
                          }}
                        >
                          <ListItemIcon>
                            <Chip
                              label="C"
                              color="primary"
                              sx={{
                                borderRadius: "3px",
                                height: "23px",
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={`${category.name} [${category.code}]`}
                          />
                        </ListItemButton>
                      </ListItem>
                    ))
                  ) : (
                    <ListItem>
                      {/* <ListItemButton> */}
                        <ListItemText sx={{
                            padding: "0px 15px",
                          }}>
                         There are no result for <b>{inputVal}</b>
                         </ListItemText>                         
                      {/* </ListItemButton> */}
                    </ListItem>
                  )}
                  {currentPage !== lastPage &&
                    (!paginationLoading ? (
                      <ListItem ref={setElement} disablePadding>
                        <ListItemButton
                          sx={{
                            padding: "5px 15px 0",
                          }}
                        >
                          <ListItemIcon>
                            <AddBoxIcon />
                          </ListItemIcon>
                          <ListItemText primary={`Load more`} />
                        </ListItemButton>
                      </ListItem>
                    ) : (
                    <CategorySkeleton />
                    ))}
                </List>
              </DialogContent>

              {(isSuperUser || categoryMasterPermission) && (
                <DialogActions
                  className="main_category_bottom"
                  sx={{
                    justifyContent: "flex-start",
                  }}
                >
                  <Button variant="text" onClick={() => setAddCatDialog(true)}>
                    <AddIcon />
                    Add Category
                  </Button>
                </DialogActions>
              )}
            </Paper>
            {/* </ClickAwayListener> */}
          </Popper>
        </div>
      </ClickAwayListener>
    </>
  );
}
