import { createFilterOptions, Grid, TextField, CircularProgress, Backdrop, Button, IconButton, Box, Autocomplete, Dialog, Typography } from "@mui/material";
import React, { Component, createRef } from "react";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from '@mui/icons-material/Close';

import axios from "axios";
import {
  getCategories,
  postItems,
  getAllUnits,
} from "../../../../config/apiUrl";
import {
  openSnackbar,
  closeSnackbar,
} from "../../../../redux/actions/snackbarAction";
import { connect } from "react-redux";
import ItemImport from "../ItemImport/ItemImport";
import Virtualize from "./VirtualizedAutocomplete";

const colorOptions = ["BLUE", "GREEN", "VIOLET", "PINK", "YELLOW"];

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: (option) => option.name,
});
class AddItemDialog extends Component {
  constructor(props) {
    super(props);
    this.descriptionElementRef = createRef(null);
  }
  state = {
    submitLoading: false,
    checked: false,
    loading: false,
    categorySearchInput:"",
    categories: [],
    page: 1,
    lastPage: 1,
    units: [],
    unitsLoading: false,
    unitOpen: false,
    error: {
      name: '',
      code: '',
      unit: '',
      hsnCode: '',
      sacCode: '',
    },
    formFields: {
      name: "",
      code: "",
      price: "",
      specification: "",
      hsnCode: "",
      sacCode: "",
      category: null,
      unit: null,
      color: null,
    },
    importItemOpen:false,
    item_detail:{}
  };
  componentDidMount() {
    this.fetchUnits();
    this.fetchOptions("", true);
    // in order to wait for the element to be actually drawn, using setTimeout
    setTimeout(() => {
      if (this.descriptionElementRef.current !== null) {
        this.descriptionElementRef.current.focus();
      }
    }, 0);
  }
  handleChange = (e) => {
    const { name, value } = e.target;
    const { formFields } = this.state;
    formFields[name] = value;
    this.setState({
      formFields,
    });
  };
  handleBlur = (e) => {
    const { name, value } = e.target;
    const { error } = this.state;
    if (name === "hsnCode" || name === "sacCode") {
      if (value){
        if (value.length >= 4 && value.length <= 10) {
          error[name] = '';
        } else {
          let fieldMsg =  name == 'hsnCode' ? 'HSN code' : 'SAC code';
          error[name]  = 'Item ' + fieldMsg + ' must have length between 4-10 characters';
        }
      } else {
        error[name] = '';
      }
    }
    else if(name == 'code'){
      if(value.match(/[\?\~\=\`\!\|\<\>\\\$\^\;]/)){
        error[name] = '(?, ~, =, `, !, |, <, >, \, $, ^, ;) Characters are not allowed';
      }
      else if(!value.length && this.props.accountModulePermission["Auto generation of code for coded items"]?.module_activated !== "Y"){
        error[name] = 'Required';
      } else {
        error[name] = '';
      }
    } 
    else {
      if (!value.length) {
        error[name] = 'Required';
      } else {
        error[name] = '';
      }
    }
    this.setState({ error });
  };

  fetchOptions = (keyword, init) => {
    if (keyword?.length || init) {
      this.setState({
        loading: true,
      });
      //apicall with keyword
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.sessionId}`;
      const url = init ? `${getCategories}?embed=division&pagination=false` : `${getCategories}?embed=division&name[like]=${keyword}%25&pagination=false`
      axios
        .get(url)
        .then((res) => {
          this.setState({
            categories:res.data.data.filter(option => {
              return !this.state.formFields.category?.filter(selected => selected.id === option.id).length > 0;
            }),
            loading: false,
          })
          // if (this.state.page !== 1) {
          //   this.setState({
          //     categories: [...this.state.categories, ...res.data.data].filter(option => {
          //       return !this.state.formFields.category?.filter(selected => selected.id === option.id).length > 0;
          //     }),
          //     loading: false,
          //   });
          // } else {
          //   this.setState({
          //     categories: res.data.data.filter(option => {
          //       return !this.state.formFields.category?.filter(selected => selected.id === option.id).length > 0;
          //     }),
          //     lastPage: res.data.meta.last_page,
          //     loading: false,
          //   });
          // }
        })
        .catch((err) => {
          this.setState({
            loading: false,
            categories: [],
          });
          console.log(err);
        });
    } else {
      this.setState({
        loading: false,
        categories: [],
      });
    }
  };

  fetchUnits = () => {
    this.setState({
      unitsLoading: true,
    });
    //apicall with keyword
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;
    axios
      .get(`${getAllUnits}`)
      .then((res) => {
        // console.log(res.data, "sdfun");
        this.setState({
          units: res.data.data,
          unitsLoading: false,
        });
      })
      .catch((err) => {
        this.setState({
          unitsLoading: false,
          units: [],
        });
        // console.log(err, "sdfun");
      });
  };
  handleSubmit = () => {
    const {
      name,
      code,
      category,
      color,
      hsnCode,
      sacCode,
      specification,
      unit,
      price
    } = this.state.formFields;

    let flag = false;
    if (!this.state.formFields.name.length) {
      flag = true;
      this.setState((prevState) => ({
        ...prevState,
        error: {
          ...prevState.error,
          name: "Required",
        },
      }));
      // this.state.error["name"] = true;
    }

    // if (this.props.accountModulePermission["Auto generation of code for coded items"]?.module_activated === "Y" && !this.state.formFields.code.length){

    // }
    if (!this.state.formFields.code.length && this.props.accountModulePermission["Auto generation of code for coded items"]?.module_activated !== "Y") {
      flag = true;
      this.setState((prevState) => ({
        ...prevState,
        error: {
          ...prevState.error,
          code: "Required",
        },
      }));
      // this.state.error["code"] = true;
    } else if (this.state.formFields.code.match(/[\?\~\=\`\!\|\<\>\\\$\^\;]/)) {
      flag = true;
      this.setState((prevState) => ({
        ...prevState,
        error: {
          ...prevState.error,
          code: '(?, ~, =, `, !, |, <, >, \, $, ^, ;) Characters are not allowed',
        },
      }));
    }
    // this.props.accountModulePermission["Auto generation of code for coded items"]?.module_activated !== "Y"

    if (!this.state.formFields.unit) {
      flag = true;
      this.setState((prevState) => ({
        ...prevState,
        error: {
          ...prevState.error,
          unit: "Required",
        },
      }));
      // this.state.error["unit"] = true;
    }

    if (this.state.formFields.hsnCode) {
      if (!(this.state.formFields.hsnCode.length >= 4 && this.state.formFields.hsnCode.length <= 10)) {
        flag = true;
        this.setState((prevState) => ({
          ...prevState,
          error: {
            ...prevState.error,
            hsnCode: "Item HSN code must have length between 4-10 characters",
          },
        }));
      } 
    }
    
    if (this.state.formFields.sacCode) {
      if (!(this.state.formFields.sacCode.length >= 4 && this.state.formFields.sacCode.length <= 10)) {
        flag = true;
        this.setState((prevState) => ({
          ...prevState,
          error: {
            ...prevState.error,
            sacCode: "Item HSN code must have length between 4-10 characters",
          },
        }));
      } 
    }
    if (flag) {
      this.props.openSnackbar("error", "Please resolve errors");
      return;
    }

    this.setState({
      submitLoading: true,
    });

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;
    axios
      .post(postItems, {
        name: name,
        code: code,
        color: color,
        hsn_code: hsnCode,
        sac_code: sacCode,
        categories: category ? category.map((cat) => cat.id) : [],
        unit: unit ? unit.name : "",
        specifications: specification,
        estimated_cost: price,
      })
      .then((res) => {
        this.props.openSnackbar("success", "item added successfully", true);
        this.setState({
          submitLoading: false,
        });
        this.handleClose();
      })
      .catch((err) => {
        this.setState({
          submitLoading: false,
        });

        if (err.response?.data?.action_required === "Y"){
          // console.log("action_required")
          this.setState({
            importItemOpen: true,
            item_detail: err.response.data.item_detail
          })
          return;
        }
        if (err.response?.data?.validation_errors) {
          let errorObj = err.response.data.validation_errors;
          let errorMsg = "";
          for (const key in errorObj) {
            errorObj[key].forEach((msg) => {
              errorMsg = `${errorMsg && `${errorMsg}, `}${msg}`;
            });
          }
          this.props.openSnackbar("error", errorMsg);
        } else {
          this.props.openSnackbar("error", "something went wrong!");
        }
      });
  };

  handleClose = () => {
    this.setState({
      submitLoading: false,
      checked: false,
      loading: false,
      categorySearchInput:"",
      categories: [],
      page: 1,
      lastPage: 1,
      units: [],
      unitsLoading: false,
      unitOpen: false,
      error: {
        name: '',
        code: '',
        unit: '',
        hsnCode: '',
        sacCode: '',
      },
      formFields: {
        name: "",
        code: "",
        price: "",
        specification: "",
        hsnCode: "",
        sacCode: "",
        category: null,
        unit: null,
        color: null,
      },
      importItemOpen:false,
      item_detail:{}
    }, () => {
      this.props.handleClose();
    })
  }
  
  render() {
    return (
      <div>
        <Backdrop
          style={{ color: "#fff", zIndex: "10000000000" }}
          open={this.state.submitLoading}
          // onClick={handleClose}
        >
          <CircularProgress style={{ color: "#fff" }} />
        </Backdrop>
        <Dialog
          // fullScreen={false}
          scroll="body"
          maxWidth={"md"}
          open={this.props.open}
          // onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle
            id="responsive-dialog-title"            
            sx={{
              '&:focus-visible': {
                border: 'none',
                outline: 'none'
              }
            }}
            ref={this.descriptionElementRef}
            tabIndex={-1}
          >
            Add New Item 
            <Box className="dialog-title-action">
              <IconButton
                sx={{
                  color: (theme) => theme.palette.bluegrey[500],}}
                  onClick={this.handleClose}
              >
                <CloseIcon />
              </IconButton>
            </Box>         
          </DialogTitle>
          <DialogContent sx={{ pt: (this.props.userInfo?.is_super_user === "Y" || this.props.masterPermission?.linking === "Y") ? "0" : '24px !important' }}>            
                {(this.props.userInfo?.is_super_user === "Y" ||
                  this.props.masterPermission?.linking === "Y") && 
                <Typography component="p" color="grey.500" sx={{ my:'16px'}}>
                  Note: If category(s) selected from category list then newly added item will be linked to that selected category(s).
                </Typography>}
              <Grid container spacing={3}>
                {/* <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="custom_label_dropdown"
                >
                  <label className="custom_labels ">Category List</label>
                  <OutlineTextfieldDropdown
                    className="model_dropdwon_custom "
                    multiple
                    open={this.state.checked}
                    onOpen={() => {
                      this.setState({
                        checked: true,
                      });
                    }}
                    onClose={() => {
                      this.setState({
                        checked: false,
                      });
                    }}
                    onChange={(e, newVal) => {
                      // this.state.formFields.category = newVal;
                      this.setState((prevState) => ({
                        ...prevState,
                        formFields: {
                          ...prevState.formFields,
                          category: newVal,
                        },
                      }));
                      // this.setState({
                      //   formFields: this.state.formFields,
                      // });
                    }}
                    loading={this.state.loading}
                    options={this.state.categories}
                    getOptionLabel={(option) => option.name}
                    onInputChange={(event) => {
                      this.fetchOptions(event.target.value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="dialog_textfield"
                        {...params}
                        label="Category"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {this.state.loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid> */}
                <Grid item lg={6} md={6} sm={6} xs={12} xs={12} className="">
                  <TextField
                    className="local_template_textfield"
                    label="Item Name"
                    required
                    variant="outlined"
                    name="name"
                    error={this.state.error.name.length}
                    value={this.state.formFields.name}
                    onChange={(e) => this.handleChange(e)}
                    onBlur={(e) => this.handleBlur(e)}
                    helperText={this.state.error.name}
                    fullWidth
                     
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12} className="">
                  {/* <OutlineTextfieldLabel
                    label="Item Code"
                    required
                    error={this.state.error.code}
                    className="dropdownwithtext dialog_textfield"
                    helperText={this.state.error.code ? "required" : ""}
                    onChange={(e) => this.handleChange(e)}
                    onBlur={(e) => this.handleBlur(e)}
                    name="code"
                    fullWidth
                     
                    InputLabelProps={{
                      shrink: true,
                    }}
                  /> */}
                  <TextField
                    className="local_template_textfield"
                    label="Item Code"
                    required={this.props.accountModulePermission["Auto generation of code for coded items"]?.module_activated !== "Y"}
                    variant="outlined"
                    name="code"
                    value={this.state.formFields.code}
                    error={this.state.error.code.length}
                    helperText={this.state.error.code ? this.state.error.code : this.props.accountModulePermission["Auto generation of code for coded items"]?.module_activated === "Y" ? "If item code is not added, system will generate an auto code for the same." : ""}
                    onChange={(e) => this.handleChange(e)}
                    onBlur={(e) => this.handleBlur(e)}
                    fullWidth
                     
                  />
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  className="custom_label_dropdown"
                >
                  {/* <label className="custom_labels ">Item Unit</label> */}
                  <Autocomplete
                    open={this.state.unitOpen}
                    onOpen={() => {
                      this.setState({
                        unitOpen: true,
                      });
                    }}
                    onClose={() => {
                      this.setState({
                        unitOpen: false,
                      });
                    }}
                    options={this.state.units}
                    loading={this.state.unitsLoading}
                    getOptionLabel={(option) => option.name}
                    value={this.state.formFields.unit}
                    onChange={(event, newVal) => {
                      // this.state.formFields.unit = newVal;
                      this.setState((prevState) => ({
                        ...prevState,
                        formFields: {
                          ...prevState.formFields,
                          unit: newVal,
                        },
                      }));
                      // this.setState({
                      //   formFields: this.state.formFields,
                      // });
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="dialog_textfield"
                        {...params}
                        required
                        name="unit"
                        error={this.state.error.unit.length}
                        helperText={this.state.error.unit}
                        onBlur={(e) => this.handleBlur(e)}
                        variant="outlined"
                        label="Item Unit"
                        fullWidth
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {this.state.unitsLoading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    type="number"
                    className="local_template_textfield"
                    label="Item Budget Price"
                    name="price"
                    variant="outlined"
                    value={this.state.formFields.price}
                    onChange={(e) => this.handleChange(e)}
                    onBlur={(e) => this.handleBlur(e)}
                    fullWidth
                     
                  />
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  className="custom_label_dropdown"
                >
                  {/* <label className="custom_labels ">Item Color</label> */}
                  <Autocomplete                    
                    options={colorOptions}
                    getOptionLabel={(option) => option}
                    name="color"
                    value={this.state.formFields.color}
                    onChange={(event, newVal) => {
                      this.setState((prevState) => ({
                        ...prevState,
                        formFields: {
                          ...prevState.formFields,
                          color: newVal,
                        },
                      }));
                      // this.state.formFields.color = newVal;
                      // this.setState({
                      //   formFields: this.state.formFields,
                      // });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Item Color"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                {(this.props.userInfo?.is_super_user === "Y" ||
                  this.props.masterPermission?.linking === "Y") && 
                  <Grid
                    item
                    xl={6}
                    lg={6}
                    md={6}
                    sm={6}
                    xs={12}
                    className="custom_label_dropdown"
                  >
                    <Virtualize 
                      loading={this.state.loading}
                      options={this.state.categories}
                      onChange={(e, newVal) => {
                        this.state.formFields.category = newVal;
                        this.setState({
                          formFields: this.state.formFields,
                        });
                      }}
                    />
                  </Grid>
                }
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    className="local_template_textfield"
                    label="HSN Code"
                    name="hsnCode"
                    variant="outlined"
                    error={this.state.error.hsnCode.length}
                    helperText={this.state.error.hsnCode}
                    value={this.state.formFields.hsnCode}
                    onChange={(e) => this.handleChange(e)}
                    onBlur={(e) => this.handleBlur(e)}
                    fullWidth
                     
                    // InputProps={{
                    //   endAdornment: <IconButton title='Find HSN Code'> <Search /> </IconButton>,
                    // }}
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                  {/* <OutlineTextfieldLabel
                    className="dialog_textfield"
                    label="SAC No"
                    name="sacCode"
                    value={this.state.formFields.sacCode}
                    onChange={(e) => this.handleChange(e)}
                    onBlur={(e) => this.handleBlur(e)}
                    style={{ margin: 8 }}
                    fullWidth
                     
                    InputLabelProps={{
                      shrink: true,
                    }}
                  /> */}
                  <TextField
                    className="local_template_textfield"
                    label="SAC code"
                    name="sacCode"
                    variant="outlined"
                    error={this.state.error.sacCode.length}
                    helperText={this.state.error.sacCode}
                    value={this.state.formFields.sacCode}
                    onChange={(e) => this.handleChange(e)}
                    onBlur={(e) => this.handleBlur(e)}
                    fullWidth
                     
                    // InputProps={{
                    //   endAdornment: <IconButton title='Find SAC Code'> <Search /> </IconButton>,
                    // }}
                  />
                </Grid>
                
                <Grid
                  item
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  className="custom_label_textarea"
                >
                  <TextField
                    className="local_template_textfield"
                    label="Item Specification"
                    name="specification"
                    variant="outlined"
                    value={this.state.formFields.specification}
                    onChange={(e) => this.handleChange(e)}
                    fullWidth
                     
                    // helperText="(Please enter synonyms seperated by semi-colon ' ; ')"
                  />
                  {/* <label className="custom_labels ">Item Specification</label> */}
                  {/* <CustomTextarea
                    className="dialog_textfield"
                    name="specification"
                    value={this.state.formFields.specification}
                    onChange={(e) => this.handleChange(e)}
                    label="Product Code *"
                    aria-label="empty textarea"
                    placeholder="Empty"
                    helperText="(Please enter synonyms seperated by semi-colon ' ; ')"
                  /> */}
                </Grid>
              </Grid>
            
          </DialogContent>
          <DialogActions>
            <Button 
            variant='outlined'
            onClick={this.handleClose}>
              Cancel
            </Button>
            <Button
            variant='contained'

              onClick={() => {
                this.handleSubmit();
              }}
              autoFocus
            >
              Add Item
            </Button>
          </DialogActions>
        </Dialog>

        <ItemImport 
          open={this.state.importItemOpen}
          handleClose={() => {
            this.setState({
              importItemOpen: false
            })
          }}
          item_detail={this.state.item_detail}
          // code={this.state.formFields.code}
          // unit={this.state.formFields.unit}
          sessionId={this.props.sessionId}
          onSuccess = {() => {
            this.props.openSnackbar("success", "Item successfully imported to the current division!" )
            this.handleClose();
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sessionId: state.sessionReducer.sessionId,
  snackbarOpen: state.snackbarReducer.open,
  accountModulePermission: state.permissionsReducer.accountModulePermission,
  masterPermission: state.permissionsReducer.masterPermission,
  userInfo: state.userInfoReducer.userInfo,
});
export default connect(mapStateToProps, {
  openSnackbar,
  closeSnackbar,
})(AddItemDialog);
