import {
    Button,
    DialogActions,
    Grid,
    IconButton,
  } from "@mui/material";
  import React, { Component } from "react";
  import {DialogContent, Dialog} from "@mui/material";
  import DialogContentText from "@mui/material/DialogContentText";
  import DialogTitle from "@mui/material/DialogTitle";
  import CloseIcon from "@mui/icons-material/Close";
  import { connect } from "react-redux";
  import errorimage from "../../assets/vendxAdminPanelAsstes/assetsnew/rfq_error.svg"
  
  class EmptyPRcombination extends Component {
    constructor(props){
      super(props)
    }
    render() {
      const { open, handleClose } = this.props;
      return (
          <Dialog
            scroll="body"
            maxWidth={"sm"}
            open={open}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle
              id="responsive-dialog-title"            
            >
            <IconButton sx={{color: (theme) => theme.palette.bluegrey[500], ml: 'auto', }}
              onClick={() => {
                    handleClose();
              }}
            >
              <CloseIcon />
            </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <div className="linkcategorydialog_main">
                  <Grid container>
                    <Grid
                      item
                      lg={12}
                      xl={12}
                      md={12}
                      sm={12}
                      className="text-center"
                    >
                      <img src={errorimage} alt="Category Link Icon" />
                      <h3 className="success_title">OOPS!</h3>
                      <p className="success_subtitle">
                      Not a single PR combination left yet
                      </p>
                    </Grid>
                  </Grid>
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{justifyContent:'center'}}>
              <Button variant="contained" color="primary"
                // autoFocus
                onClick={(event) => {
                  handleClose();                
                  event.preventDefault();
                }}
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
      );
    }
  }
  const mapStateToProps = (state) => ({
    selectedVendors: state.vendorReducer.selectedVendors || [],
    userInfo: state.userInfoReducer.userInfo
  });
  export default connect(mapStateToProps, {})(EmptyPRcombination);
  