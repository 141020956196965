import styled1 from "styled-components";
import Dropzone from "react-dropzone";
import { Dialog } from "@mui/material";
import {styled} from "@mui/system";


export const CustomsmallDialog = styled(Dialog)(({ theme }) => ({
  '& .drop_zone_area': {    
    margin: '0 auto',
    backgroundColor: theme.palette.grey[50],
    border: '1px dashed',
    borderColor: theme.palette.bluegrey[500],
    borderRadius: 5,
    minHeight: '95px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  '& .drop_zone_area .MuiTypography-root': {
    display: 'flex',
  },
  '& .drop_zone_area .MuiTypography-root span':{
    color:theme.palette.primary.main,
    fontWeight: 500,
    padding: '0 4px',
  },
  '& .uploadfile_list':{
    marginTop: '16px',
  },
  '& .uploadfile_list li': {
    background: theme.palette.grey[50],    
    padding: '10px 15px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    marginBottom: '16px',
    overflow: 'hidden',
  },
  '& .uploadfile_list li.newfile': {
    background: theme.palette.bluegrey[0], 
    border: '1px dashed', 
    borderColor: theme.palette.grey[500],
  },
  '& .uploadfile_list .close_icon_file svg': {
    fontSize: '25px',
    margin: 0,
  },
  '& .filename-text': {
    fontSize: 'calc(14px + (16 - 14) * ((100vw - 300px) / (1920 - 300)))',
    // textTransform: 'capitalize',
    fontWeight: '500',
    width:'70%',
  },
  '& .filesize-text': {  
    color: theme.palette.grey[500],    
  },
  '& .MuiLinearProgress-colorPrimary': {
    backgroundColor: theme.palette.grey[50],
    borderRadius: '5px',
    height: '8px',
  },
  '& .MuiLinearProgress-bar': {
    borderRadius: '5px',
    height: '8px',
  },
  '& .after_upload_icon': {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'flex-end',
  },
  '& .upload_file_scroll': {
    maxHeight: '280px',
    overflowY:'auto',
    padding: '20px 15px 20px 15px',
  }, 
  '& .MuiDialogActions-root': {
    padding: '15px 20px 25px',
  },
}));

export const CustomDropzone = styled1(Dropzone)(({ theme }) => ({
  

}));