import styled1 from "styled-components";
import {styled} from "@mui/system";
import { Dialog } from "@mui/material";

export const LinkItemDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogTitle-root': {
    fontSize:'16px',
    padding: '16px',
    color:'var(--grey-700)'
  },
  '& .MuiDialogContent-root, & .MuiDialogActions-root': {
    background: 'var(--mainbg-gray)',
    paddingRight:'16px',
    paddingLeft:'16px',
  },
  '& .MuiCardActions-root':{
    padding:'12px 16px'
  },
  '& .MuiInputBase-root':{
    width: '100%',
    height: '38px',
    background: 'var(--grey-100)',
    borderRadius: '0px',
    borderWidth:'1px 0',
    borderStyle:'solid',
    borderColor:'var(--bluegrey-500)',
    '& .MuiInputBase-input':{
      paddingTop:'8px'
    },
    '& .MuiInputAdornment-root':{
      color:'var(--grey-600)'
    },
    '&:before, &:after':{
      display:'none'
    },
  },
  '& .MuiDialog-paperWidthSm': {
    [theme.breakpoints.up("md")]: {
      maxWidth: '950px',
      width: '950px'
    }
  }, 
  // '@media only screen and (max-width: 1363px)': {
  '@media only screen and (max-width: 1199px)': {
    '& .MuiDialog-paperWidthMd': {
      minWidth: 'calc(100% - 64px)',
    },    
  },
  '&.RFQ-linkDialog .MuiDialog-container .MuiPaper-root':{
    boxShadow:'none'
  },
  '& .MuiCardContent-root': {
    padding:'0 !important',
  },
  '& .MuiList-root': {
    padding:0
  },
  '& .LinkUnlinkCard .LinkCardTitle': {
    fontSize: '16px',
    fontWeight: '500',  
    color: 'var(--grey-700)',
    textTransform: 'uppercase',
  },
  '& .LinkUnlinkCard .link-items-list': {
    height: '350px',
    minHeight: '200px',
    overflowY: 'auto',
    overflowX: 'hidden',
    position: 'relative'
  },
  '& .LinkUnlinkCard .link-items-list ul li': {   
      paddingRight:'100px',
      paddingBottom: '4px',
      paddingTop: '4px',
      alignItems:'center'
  },
  '& .LinkUnlinkCard .RFQNo-ItemList .MuiCollapse-root':{
    background:'var(--grey-50)',
  },
  '& .LinkUnlinkCard .link-items-list .MuiListItemButton-root, .LinkUnlinkCard .link-items-list.selected-list li': {
      padding:'8px 16px 8px 16px',
  },
  '& .link-items-list  .MuiCheckbox-root': {
    marginLeft: 0,
  },
    '& .link-items-list  .MuiListItemAvatar-root': {
      minWidth:'40px',
      marginTop:0,
      marginRight:'8px',
    },
  '& .link-items-list  .MuiListItemText-primary': {
    fontSize: '0.875rem',      
    fontWeight: '500',
    color: theme.palette.text.primary,
    margin: 0,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  '& .link-items-list  .MuiListItemText-secondary': {
    fontSize: '0.75rem', 
    fontWeight: '400',
    color: theme.palette.text.secondary,
    margin: '-3px 0 0 0',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  '& .link-items-list  .MuiChip-root':{
    borderRadius:'5px',
    background:'var(--grey-100)',
    color:'rgba(82, 100, 132, 0.6)',
  },
  '& .RFQNo-ItemList .MuiAccordionSummary-content .RemoveBtn' :{
    visibility: 'hidden',
    padding:'0',
    width: '20px',
    height: '20px',
    [theme.breakpoints.down("md")]: {
      visibility: 'visible',
    }
  },
  '& .RFQNo-ItemList .MuiAccordionSummary-content:hover .RemoveBtn': {
    visibility: 'visible'
  },
  '& .link-items-list .MuiAccordionSummary-root':{
    minHeight:'auto',
    '& .MuiAccordionSummary-content':{      
      alignItems:'center',
      width:'calc(100% - 32px)',
      padding:'12px 0',
      margin:'0'
    }
  },
  '& .link-items-list .MuiAccordion-root:before':{
    display:'none'
  },
  '& .link-items-list .MuiAccordionSummary-root.Mui-expanded':{
    minHeight:'auto',
  },
  '& .link-items-list .MuiAccordion-root.Mui-expanded':{
    minHeight:'auto',
    margin:'0'
  },
  // Mobile Tabs View CSS Link Unlink User 
  '& .LinkUser-Tabs .tabs-panel .MuiBox-root':{
    padding:'0',
    '& .MuiPaper-root':{
      border:'none'
    }
  },
  '&.RFQ-linkDialog':{
    [theme.breakpoints.down("md")]: {
      '& .MuiDialogContent-root': {        
        paddingRight:'0',
        paddingLeft:'0',
      },
      '& .LinkUnlinkCard .link-items-list': {
        height: 'calc(100vh - 212px)',
        minHeight: 'auto',
      },
      '& .MuiDialog-container .MuiPaper-root':{
        borderRadius:'0'
      },
      // '& .LinkUser-Tabs .MuiTabs-root, & .LinkUser-Tabs .MuiButtonBase-root':{
      //   minHeight:'40px'
      // }
    }
  },
  '& .no-search': {
    minWidth:'100%',
    position: 'absolute', 
    top: '50%', 
    left: '50%', 
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',  
  },
  '& .no-search p': {
    color: theme.palette.text.primary,
  }
}));