import React from "react";

//ICONS
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import { useSelector } from "react-redux";
import ListIcon from '@mui/icons-material/List';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import GavelOutlinedIcon from '@mui/icons-material/GavelOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import WysiwygOutlinedIcon from '@mui/icons-material/WysiwygOutlined';
import otgIcon from '../../assets/vendxAdminPanelAsstes/OTG_ICON.svg'
// const Permisison = useSelector((state) => state.permissionsReducer)

export const menu = [
  // {
  //   icon: <HomeOutlinedIcon />,
  //   title: "Dashboard",
  //   to: "/",
  // },
  // {
  //   icon: <HomeOutlinedIcon />,
  //   title: "MUI Data Grid",
  //   to: "/Mui-data-grid",
  // },

  {
    icon: <ViewListOutlinedIcon  />,
    title: "Transaction Register",
    to: "/transaction-register",
    permission: "Transaction Access"
  },
  {
    icon: <img src={otgIcon} />,
    title: "OTG",
    to: '#',
    permission: "OTG"

  
  },
  {
    icon: <GavelOutlinedIcon />,
    title: "Auction",
    to: '#',
    permission: "Auction"

  },
  {
    icon: <ReceiptLongOutlinedIcon />,
    title: "Visit Reports",
    to: '#',
    permission: "Vendor Visit Report"

  },
  {
    icon: <WysiwygOutlinedIcon />,
    title: "View Action Plan",
    to: '#',
    permission: "Vendor Visit Report"
  },
];
