import React, { Component } from "react";
import { setSessionId } from "../redux/actions/sessionAction";
import { connect } from "react-redux";
import MainContent from "../components/MainContent/MainContent";
import { setUserInfo } from "../redux/actions/setUserInfo";
import Header from "../components/BreadCrumb/Header";
import ShowEmpty from "../components/ShowEmpty";
import { setSelectedCategory } from "../redux/actions/categoryAction";
import { getCategoryDetails } from "../config/apiUrl";
import axios from "axios";
import { Backdrop, CircularProgress, Grid } from "@mui/material";
import { openSnackbar } from "../redux/actions/snackbarAction";
import { CategoryEmptyCard, EmptyCategoryTitle } from "../components/ShowEmpty/style";
import NoAccountsOutlinedIcon from '@mui/icons-material/NoAccountsOutlined';
import SendToNew from "./SendToNew";

export class MainHomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sessionID: "",
      submitBtn: "",
      loading: false,
      TemporaryDrawerKey: 1,
      activityHash : {
        action : "",
        tb_id  : -1,
        pre_fetch_inquiry_id : 0,
        allowOpen : 0
      },
      selectedExcelTemplate:{},
      rfqtype : ""
    };
  }

  componentDidMount() {

    const queryParams = new URLSearchParams(this.props.location.search);
    let SessionID = queryParams.get('q');
    let categoryID= queryParams.get('categoryID');

    if(this.props.match.params.categoryID){
      categoryID = this.props.match.params.categoryID;
    }

    this.setState({
      sessionID: SessionID,
    });

    this.props.setSessionId(SessionID);
    // localStorage.removeItem("SessionID");
    this.props.setUserInfo(SessionID);
    // console.log(this.props.match.params.categoryID, "selected category");
    if (categoryID) {
      this.setState({
        loading: true,
      });
      axios
        .get(getCategoryDetails(categoryID))
        .then((response) => {
          this.props.setSelectedCategory(response.data.data);
          // localStorage.removeItem("categoryID");
          this.setState({
            loading: false,
          });
        })
        .catch((err) => {
          console.log("Provided CAtegory id is not valid", err);
          this.props.openSnackbar(
            "error",
            err.response?.data?.message
              ? err.response?.data?.message
              : "Provided Category id is not valid"
          );
          this.setState({
            loading: false,
          });
        });
      // setSelectedCategory(category)
    }
  }

  resetSettingDrawer = () => {
    this.setState({
      TemporaryDrawerKey: this.state.TemporaryDrawerKey + 1,
    });
  };
  triggerTemplateIFrame = (action,tb_id,pre_fetch_inquiry_id,allowOpen) => {
    this.setState({
      activityHash : {
        action : action,
        tb_id  : tb_id || -1,
        pre_fetch_inquiry_id : pre_fetch_inquiry_id || 0,
        allowOpen :allowOpen
      } 
    });
  };

  excelTemplate = (data) => {
    this.setState({
      selectedExcelTemplate: data,
    });
  }

  setRfqType = (data) => {
    this.setState({
      rfqtype: data,
    });
  }

  rfqPermission = () => {
    let errorMessage = [];
    if (this.props.userInfo?.can_float_inquiry === "N") {
      errorMessage.push("You do not have permission to float an RFQ.");
    }
    if (this.props.userInfo?.MPSD_module_exists === "N") {
      errorMessage.push("The MPSD module is not enabled for your account.");
    }
    if (this.props.userInfo?.suspended === "Y") {
      errorMessage.push("Your account is suspended.");
    }
    if (this.props.userInfo?.transaction_limit_exceeded === "Y") {
      errorMessage.push("You have used all your transaction limits.");
    }
    if (this.props.userInfo?.transaction_period_limit_exceeded === "Y") {
      errorMessage.push("Your transaction period time is over.");
    }
    return errorMessage;
  };
  
  render() {
    if (new URLSearchParams(this.props.location.search).get('for') === "send-to-new-vendor") {
      return <SendToNew {...this.props}/>
    }
    return (
      <>
        <Backdrop
          style={{ color: "#fff", zIndex: "10000000000" }}
          open={this.state.loading}
        >
          <CircularProgress style={{ color: "#fff" }} />
        </Backdrop>

        {this.rfqPermission().length > 0 ? (
          <div
            className="page_content_main"
            style={{ padding: this.props.type === "category" ? "0 22px" : "0" }}
          >
            <Grid container>
              <Grid item xs={12}>
                <CategoryEmptyCard
                  elevation={0}
                  cart={this.props.type === "category"}
                >
                  <NoAccountsOutlinedIcon
                    color="error"
                    sx={{
                      margin: "0 auto 15px auto",
                      fontSize: "175px",
                    }}
                  />
                  {this.rfqPermission().map((msg) => {
                    return (
                      <EmptyCategoryTitle
                        variant="h5"
                        className="categorytext_heading"
                      >
                        {msg}
                      </EmptyCategoryTitle>
                    );
                  })}
                </CategoryEmptyCard>
              </Grid>
            </Grid>
          </div>
        ) : (
          <>
            <Header
              sessionID={this.state.sessionID}
              TemporaryDrawerKey={this.state.TemporaryDrawerKey}
              resetSettingDrawer={this.resetSettingDrawer}
              triggerTemplateIFrame={this.triggerTemplateIFrame}
              selectedTemplateDataFromExcel={this.excelTemplate}
              setRfqType={this.setRfqType}
            />
            {this.props.selectedCategory ? (
              <MainContent
                resetSettingDrawer={this.resetSettingDrawer}
                location={this.props.location}
                match={this.props.location}
                activityHash={this.state.activityHash}
                triggerTemplateIFrame={this.triggerTemplateIFrame}
                selectedExcelTemplate={this.state.selectedExcelTemplate}
                rfqtype={this.state.rfqtype}
              />
            ) : (
              <ShowEmpty type="category" />
            )}
          </>
        )}
      </>
    );
  }

}

const mapStateToProps = (state) => ({
  selectedCategory: state.categoryReducer.selectedCategory,
  userInfo: state.userInfoReducer.userInfo,
});

export default connect(mapStateToProps, {
  setSessionId,
  setUserInfo,
  setSelectedCategory,
  openSnackbar,
})(MainHomePage);
