import { Switch, FormControlLabel } from "@mui/material";
import {styled} from "@mui/system";


export const CustomSwitch = styled(Switch) (({ theme }) => ({
  '&.MuiSwitch-root': {
    width: '60px',
    height: '36px',
    padding: '5px',
    border: 'none',
  },
  '.MuiSwitch-thumb': {
    backgroundColor: theme.palette.bluegrey[500],
    boxShadow: 'none',
    width: '18px',
    height: '18px',
  },
  '.MuiSwitch-switchBase.Mui-checked': {
    transform: 'translateX(25px)',
  },
  '& .Mui-checked+.MuiSwitch-track': {
    opacity: '1',
  },
 '&.MuiSwitch-root .MuiSwitch-track': {
    backgroundColor: 'transparent',
    borderRadius: '50px',    
    border: '1px solid',
    borderColor: theme.palette.bluegrey[500],
    opacity: '1',    
  },
  '.MuiSwitch-colorPrimary.Mui-checked +.MuiSwitch-track': {
    backgroundColor: theme.palette.primary.main,
    border: theme.palette.primary.main,
    opacity: '1',    
  },
  '.MuiSwitch-colorPrimary.Mui-checked .MuiSwitch-thumb': {
    backgroundColor: theme.palette.grey[0],
  },
  '.MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
    backgroundColor: theme.palette.primary.main,
    border: '1px solid transparent',
  },
  '.MuiFormControlLabel-root': {
    border: 'none',
    backgroundColor: 'transparent',
  },
  '.MuiSwitch-colorSecondary.Mui-checked .MuiSwitch-thumb': {
    background: theme.palette.grey[0],
  },
  '.Mui-disabled.Mui-checked+.MuiSwitch-track, .Mui-disabled+.MuiSwitch-track, .Mui-disabled .MuiSwitch-thumb': {
    opacity: '0.4',
    cursor: 'not-allowed',
  },
}));

export const CustomSwitchLabel = styled(FormControlLabel) (({ theme }) => ({
  '.MuiSwitch-root': {
    width: '60px',
    height: '36px',
    padding: '5px',
    border: 'none',
  },
  '.MuiSwitch-thumb': {
    backgroundColor: theme.palette.bluegrey[500],
    boxShadow: 'none',
    width: '18px',
    height: '18px',
  },
  '.MuiSwitch-switchBase.Mui-checked': {
    transform: 'translateX(25px)',
  },
  '& .Mui-checked+.MuiSwitch-track': {
    opacity: '1',
  },
 '.MuiSwitch-root span.MuiSwitch-track': {
    backgroundColor: 'transparent',
    borderRadius: '50px',    
    border: '1px solid',
    borderColor: theme.palette.bluegrey[500],    
  },
  '.MuiSwitch-colorPrimary.Mui-checked +.MuiSwitch-track': {
    backgroundColor: theme.palette.primary.main,
    border: theme.palette.primary.main,    
  },
  '.MuiSwitch-colorPrimary.Mui-checked .MuiSwitch-thumb': {
    backgroundColor: theme.palette.grey[0],
  },
  '.MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
    backgroundColor: theme.palette.primary.main,
    border: '1px solid transparent',
  },
  '.MuiFormControlLabel-root': {
    border: 'none',
    backgroundColor: 'transparent',
  },
  '.MuiSwitch-colorSecondary.Mui-checked .MuiSwitch-thumb': {
    background: theme.palette.grey[0],
  },
  '.Mui-disabled.Mui-checked+.MuiSwitch-track, .Mui-disabled+.MuiSwitch-track, .Mui-disabled .MuiSwitch-thumb': {
    opacity: '0.4',
    cursor: 'not-allowed',
  },
}));
