import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers/index";
import logger from "redux-logger";

//import { composeWithDevTools } from 'redux-devtools-extension';

const middleWare = [thunk];
const initialState = {};

const store = createStore(
  rootReducer,
  initialState,
  compose(
    // applyMiddleware(...middleWare, logger)
    applyMiddleware(...middleWare)

    //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);
export default store;
