import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Box,
  Typography,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

import { menu } from "./Menu";
import { hasChildren } from "./utils";

const MenuStyle = makeStyles((theme) => ({
  MenuMainLink: {
    color: "#5a6778",
    "& $MenuItemIcon": {
      color: "#3c4d62",
    },
    "&:hover": {
      color: theme.palette.primary.main,
      "& $MenuItemIcon": {
        color: theme.palette.primary.main,
      },
    },
  },
  MenuItemIcon: {
    minWidth: 30,
    height: 24,
  },
  MenuArrowICon: {
    color: "#a6c1d3",
    width: 19,
  },
}));

export default function AppMenu(props) {
  React.useEffect(() => { }, [props]);
  return menu.map((item, key) => (
    <MenuItem key={key} item={item} open={props.open} />
  ));
}
const MenuItem = ({ item, open, ispopup = 0 }) => {
  const Component = hasChildren(item) ? (
    <MultiLevel item={item} open={open} ispopup={ispopup} />
  ) : (
    <SingleLevel item={item} open={open} />
  );
  // const Component = <MultiLevel item={item} open={open} />

  // return <Component item={item} />;
  return Component;
};

const SingleLevel = ({ item, open }) => {
  const classes = MenuStyle();

  //const navigate = useNavigate();
  //const { href, icon, title, to, ...others } = props;
  //const location = useLocation();

  return (
    <ListItem component="li" className={classes.MenuMainLink} disablePadding>
      <ListItemButton
        component="a"
        to={item.to}
      // onClick={() => {
      //    navigate(`${item.to}`);
      //   }}
      >
        <ListItemIcon className={classes.MenuItemIcon}>
          {item.icon}
        </ListItemIcon>
        <ListItemText primary={item.title} />
      </ListItemButton>
    </ListItem>
  );
};

const MultiLevel = ({ item, open, ispopup }) => {
  const { items: children } = item;
  const [openDropdown, setOpenDropdown] = useState(false);

  const handleClick = () => {
    setOpenDropdown((prev) => !prev);
  };

  const classes = MenuStyle();

  //support Popover menu
  const [anchorMenu, setanchorMenu] = React.useState(null);

  const handleMenuClick = (event) => {
    setanchorMenu(event.currentTarget);
  };

  const handleMenuClose = () => {
    setanchorMenu(null);
  };

  const openMenu = Boolean(anchorMenu);
  const id = openMenu ? "simple-popover" : undefined;

  return (
    <React.Fragment>
      <ListItem
        component="li"
        onClick={handleClick}
        className={classes.MenuMainLink}
        disablePadding
        selected={openDropdown}>
        <ListItemButton
          component="a"
          to={item.to}
          onClick={handleMenuClick}
          selected={anchorMenu}>
          <ListItemIcon className={classes.MenuItemIcon}>
            {item.icon}
          </ListItemIcon>
          <ListItemText primary={item.title} />
          {openDropdown ? (
            <ExpandLess className={classes.MenuArrowICon} />
          ) : (
            <ExpandMore className={classes.MenuArrowICon} />
          )}
        </ListItemButton>
      </ListItem>
      {open || ispopup ? (
        <Collapse in={openDropdown} timeout="auto" unmountOnExit>
          <List component="ul" disablePadding>
            {children.map((child, key) => (
              <MenuItem component="a" key={key} item={child} open={open} />
            ))}
          </List>
        </Collapse>
      ) : (
        <Popover
          id={id}
          open={openMenu}
          anchorEl={anchorMenu}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          className="popover-menu">
          <List component="ul" disablePadding>
            {children.map((child, key) => (
              <MenuItem
                component="a"
                key={key}
                item={child}
                open={open}
                ispopup={true}
              />
            ))}
          </List>
        </Popover>
      )}
    </React.Fragment>
  );
};
