import * as React from "react";
import { createSvgIcon } from "@mui/material/utils";

export const LinkVendorIcon = createSvgIcon(
  <g>
    <path
      d="M12.837,13.025C12.563,13.013,12.287,13,12,13a16.1,16.1,0,0,0-8.263,2.275A3.653,3.653,0,0,0,2,18.438V20.5a1.254,1.254,0,0,0,1.25,1.25H13.575a8.7,8.7,0,0,1-1.575-5A8.84,8.84,0,0,1,12.837,13.025Z"
      transform="translate(-2 -1.75)"
    />
    <circle cx="5" cy="5" r="5" transform="translate(5.25)" />
    <g transform="translate(10.863 17.234) rotate(-47)">
      <path d="M9.243,0H7.395a.616.616,0,0,0,0,1.232H9.243a1.849,1.849,0,1,1,0,3.7H7.395a.616.616,0,0,0,0,1.232H9.243A3.081,3.081,0,1,0,9.243,0ZM3.7,3.081a.618.618,0,0,0,.616.616h3.7a.616.616,0,0,0,0-1.232h-3.7A.618.618,0,0,0,3.7,3.081ZM4.93,4.93H3.081a1.849,1.849,0,1,1,0-3.7H4.93A.616.616,0,0,0,4.93,0H3.081a3.081,3.081,0,1,0,0,6.162H4.93a.616.616,0,1,0,0-1.232Z" />
    </g>
  </g>
);

export const LinkVendorError = createSvgIcon(
  <g>
    <g id="Group_2344" data-name="Group 2344">
      <path id="Path_638" data-name="Path 638" d="M0,0H24V24H0Z" fill="none" />
    </g>
    <g id="Group_2346" data-name="Group 2346" transform="translate(2 4)">
      <g id="Group_2345" data-name="Group 2345">
        <path
          id="Path_639"
          data-name="Path 639"
          d="M4,18v-.65a.946.946,0,0,1,.41-.81A10.889,10.889,0,0,1,10,15a.22.22,0,0,1,.08.01,6.886,6.886,0,0,1,.59-1.98c-.22-.02-.44-.03-.67-.03a12.876,12.876,0,0,0-6.61,1.82A2.922,2.922,0,0,0,2,17.35V20h9.26a7.132,7.132,0,0,1-.97-2Z"
          transform="translate(-2 -4)"
        />
        <path
          id="Path_640"
          data-name="Path 640"
          d="M10,12A4,4,0,1,0,6,8,4,4,0,0,0,10,12Zm0-6A2,2,0,1,1,8,8,2.006,2.006,0,0,1,10,6Z"
          transform="translate(-2 -4)"
        />
        <path
          id="error_black_24dp"
          d="M7,2a5,5,0,1,0,5,5A5,5,0,0,0,7,2Zm.5,7.5h-1v-1h1Zm0-2h-1v-3h1Z"
          transform="translate(8 5)"
        />
      </g>
    </g>
  </g>
);

export const EditNote = createSvgIcon(
  <g>
    <rect fill="none" height="24" width="24" />
    <path d="M3,10h11v2H3V10z M3,8h11V6H3V8z M3,16h7v-2H3V16z M18.01,12.87l0.71-0.71c0.39-0.39,1.02-0.39,1.41,0l0.71,0.71 c0.39,0.39,0.39,1.02,0,1.41l-0.71,0.71L18.01,12.87z M17.3,13.58l-5.3,5.3V21h2.12l5.3-5.3L17.3,13.58z" />
  </g>
);

export const PrIcon = createSvgIcon(
  <g
    id="PR-icon"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <rect
      id="Rectangle_606"
      data-name="Rectangle 606"
      width="24"
      height="24"
      fill="none"
    />
    <path
      id="Path_685"
      data-name="Path 685"
      d="M13.17,4,18,8.83V20H6V4h7.17M14,2H6A2.006,2.006,0,0,0,4,4V20a2.006,2.006,0,0,0,2,2H18a2.006,2.006,0,0,0,2-2V8L14,2Z"
      fill="#3c4d62"
    />
    <text
      id="PR"
      transform="translate(7 16)"
      fill="#3c4d62"
      font-size="7"
      font-family="Roboto-Bold, Roboto"
      font-weight="700"
      letter-spacing="0.05em"
    >
      <tspan x="0" y="0">
        PR
      </tspan>
    </text>
  </g>
);

export const AddDatePicker = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" height="24" fill="#3c4d62" viewBox="0 -960 960 960" width="24">
    <path d="M200-640h560v-80H200v80Zm0 0v-80 80Zm0 560q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v227q-19-9-39-15t-41-9v-43H200v400h252q7 22 16.5 42T491-80H200Zm520 40q-83 0-141.5-58.5T520-240q0-83 58.5-141.5T720-440q83 0 141.5 58.5T920-240q0 83-58.5 141.5T720-40Zm67-105 28-28-75-75v-112h-40v128l87 87Z"/>
  </svg>
);

export const CaseStudyIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" height="24" fill="#3c4d62" viewBox="0 -960 960 960" width="24">
    <path d="M200-800v241-1 400-640 200-200Zm80 400h140q9-23 22-43t30-37H280v80Zm0 160h127q-5-20-6.5-40t.5-40H280v80ZM200-80q-33 0-56.5-23.5T120-160v-640q0-33 23.5-56.5T200-880h320l240 240v100q-19-8-39-12.5t-41-6.5v-41H480v-200H200v640h241q16 24 36 44.5T521-80H200Zm460-120q42 0 71-29t29-71q0-42-29-71t-71-29q-42 0-71 29t-29 71q0 42 29 71t71 29ZM864-40 756-148q-21 14-45.5 21t-50.5 7q-75 0-127.5-52.5T480-300q0-75 52.5-127.5T660-480q75 0 127.5 52.5T840-300q0 26-7 50.5T812-204L920-96l-56 56Z"/>
  </svg>
);

export const ExcelIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g
      id="Group_1714"
      data-name="Group 1714"
      transform="translate(-1292 -472.146)"
    >
      <path
        id="Path_2626"
        data-name="Path 2626"
        d="M19.873,0A19.873,19.873,0,1,1,0,19.873,19.873,19.873,0,0,1,19.873,0Z"
        transform="translate(1284 464.146)"
        fill="#3c4d62"
        opacity="0"
      />
      <g id="excel" transform="translate(1293.5 473.519)">
        <path
          id="Path_2634"
          data-name="Path 2634"
          d="M12.227.5.5,2.755V18.992l11.727,2.255Zm0,2.706h9.02V18.541h-9.02"
          fill="none"
          stroke="#3c4d62"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Path_2635"
          data-name="Path 2635"
          d="M12.618,5.5h2.706v.9H12.618Zm4.51,0h2.706v.9H17.129Zm-4.51,2.706h2.706v.9H12.618Zm4.51,0h2.706v.9H17.129Zm-4.51,2.706h2.706v.9H12.618Zm4.51,0h2.706v.9H17.129Zm-4.51,2.706h2.706v.9H12.618Zm4.51,0h2.706v.9H17.129Zm-4.51,2.706h2.706v.9H12.618Zm4.51,0h2.706v.9H17.129ZM4.5,8.206l3.608,7.216m-3.608,0L8.108,8.206"
          transform="translate(-0.392 -0.49)"
          fill="none"
          stroke="#3c4d62"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </g>
  </svg>
);
export const HexagonIcon = createSvgIcon(
  <g>
    <path d="M17 3H7L2 12L7 21H17L22 12L17 3ZM15.8942 19H8.10577L4.22115 12L8.10577 5H15.8846L19.7692 12L15.8942 19Z" fill=""/>
    <path d="M8 12C8 9.792 9.792 8 12 8C14.208 8 16 9.792 16 12C16 14.208 14.208 16 12 16C9.792 16 8 14.208 8 12ZM12 14.4C13.324 14.4 14.4 13.324 14.4 12C14.4 10.676 13.324 9.6 12 9.6C10.676 9.6 9.6 10.676 9.6 12C9.6 13.324 10.676 14.4 12 14.4Z" fill=""/>
  </g>
);
export const R2AReportsIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="R2A" transform="translate(-19 21)">
      <path id="Path_4368" data-name="Path 4368" d="M0,0H24V24H0Z" transform="translate(19 -21)" fill="none"/>
      <g id="R2A-2" data-name="R2A" transform="translate(18.969 -21)">
        <path id="Path_10640" data-name="Path 10640" d="M1.031,4.113H3.973a4.172,4.172,0,0,1,1.691.305,2.244,2.244,0,0,1,1.056.9,2.724,2.724,0,0,1,.366,1.459A2.487,2.487,0,0,1,6.836,7.9a2.377,2.377,0,0,1-.708.854,3.124,3.124,0,0,1-1.1.519L4.7,9.4H1.935l-.012-.958H4.01a2.268,2.268,0,0,0,1.056-.22,1.545,1.545,0,0,0,.635-.6,1.671,1.671,0,0,0,.214-.836,1.907,1.907,0,0,0-.2-.9,1.345,1.345,0,0,0-.635-.592,2.473,2.473,0,0,0-1.1-.214H2.209V13H1.031ZM6.226,13,4.065,8.972l1.227-.006,2.191,3.961V13Z" fill="#3c4d62"/>
        <path id="Path_10641" data-name="Path 10641" d="M19.321,11.9,16.379,20h-1.2l3.388-8.887h.775ZM21.787,20l-2.948-8.1-.018-.787H19.6L23,20Zm-.153-3.29v.964H16.642V16.71Z" fill="#3c4d62"/>
        <path id="Path_10642" data-name="Path 10642" d="M13.5,7.5C11.979,5.005,8.862,3.35,4,1.5A43.987,43.987,0,0,1,11,2a14.973,14.973,0,0,1,5,2l3-3v9.5L10,10Z" fill="#3c4d62"/>
        <path id="Path_10643" data-name="Path 10643" d="M8.5,17c1.521,2.495,4.638,4.15,9.5,6a30.431,30.431,0,0,1-7,0c-2.5-.5-3-1.5-5-2.5l-3,3V14h8.5Z" fill="#3c4d62"/>
      </g>
    </g>
  </svg>
);
export const MultipleSolutionIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="Multiple_Solutions" data-name="Multiple Solutions" transform="translate(-2 21)">
      <path id="Path_4368" data-name="Path 4368" d="M0,0H24V24H0Z" transform="translate(2 -21)" fill="none"/>
      <g id="Multiple_Solutions-2" data-name="Multiple Solutions" transform="translate(2 -21)">
        <line id="Line_1223" data-name="Line 1223" x2="3.017" y2="5.648" transform="translate(13.933 10.441)" fill="none" stroke="#8091a7" stroke-width="1"/>
        <line id="Line_1224" data-name="Line 1224" y1="5.648" x2="3.017" transform="translate(7.559 9.765)" fill="none" stroke="#8091a7" stroke-width="1"/>
        <line id="Line_1225" data-name="Line 1225" x1="6.403" transform="translate(9.307 18.324)" fill="none" stroke="#8091a7" stroke-width="1"/>
        <circle id="Ellipse_233" data-name="Ellipse 233" cx="4" cy="4" r="4" transform="translate(8 3)" fill="#3c4d62"/>
        <circle id="Ellipse_234" data-name="Ellipse 234" cx="4" cy="4" r="4" transform="translate(14 14)" fill="#3c4d62"/>
        <circle id="Ellipse_235" data-name="Ellipse 235" cx="4" cy="4" r="4" transform="translate(2 14)" fill="#3c4d62"/>
      </g>
    </g>
  </svg>
);

export const ExtendValidityThread = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24px"
    viewBox="0 0 24 24"
    width="24px"
    fill="#3c4d62"
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M7 11h2v2H7v-2zm14-5v14c0 1.1-.9 2-2 2H5c-1.11 0-2-.9-2-2l.01-14c0-1.1.88-2 1.99-2h1V2h2v2h8V2h2v2h1c1.1 0 2 .9 2 2zM5 8h14V6H5v2zm14 12V10H5v10h14zm-4-7h2v-2h-2v2zm-4 0h2v-2h-2v2z" />
  </svg>
);

export const ItemSpecificVendorIcon = createSvgIcon(
<svg xmlns="http://www.w3.org/2000/svg" id="ITEMIZED_ICON" data-name="ITEMIZED ICON" width="24" height="24" viewBox="0 0 24 24">
  <g id="Group_7612" data-name="Group 7612">
    <rect id="Rectangle_3394" data-name="Rectangle 3394" width="24" height="24" fill="none"/>
  </g>
  <g id="Group_7614" data-name="Group 7614" transform="translate(2.333 4)">
    <g id="Group_7613" data-name="Group 7613">
      <path id="Path_2982" data-name="Path 2982" d="M20,4H4A2,2,0,0,0,2,6V18a2,2,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V6A2.006,2.006,0,0,0,20,4Zm0,14H4V6H20Z" transform="translate(-2 -4)" fill="#526484"/>
    </g>
  </g>
  <g id="Group_7615" data-name="Group 7615" transform="translate(7 6)">
    <text id="I" transform="translate(0 10)" fill="#526484" font-size="10" font-family="Roboto-Black, Roboto" font-weight="800"><tspan x="0" y="0">I</tspan></text>
    <text id="V" transform="translate(4 10)" fill="#526484" font-size="10" font-family="Roboto-Black, Roboto" font-weight="800"><tspan x="0" y="0">V</tspan></text>
  </g>
</svg>);

export const QuotationThread = createSvgIcon(
  <svg width="14" height="14" viewBox="0 -2 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group">
      <g id="Group_2">
        <path id="Vector" d="M9 12.5H3V14.5H9V12.5Z" fill="#50657E"/>
        <path id="Vector_2" d="M15 4.5H9V6.5H15V4.5Z" fill="#50657E"/>
        <path id="Vector_3" d="M12 8.5H6V10.5H12V8.5Z" fill="#50657E"/>
        <path id="Vector_4" d="M16 0.5H2C0.9 0.5 0 1.4 0 2.5V16.5C0 17.6 0.9 18.5 2 18.5H16C17.1 18.5 18 17.6 18 16.5V2.5C18 1.4 17.1 0.5 16 0.5ZM16 16.5H2V2.5H16V16.5Z" fill="#50657E"/>
      </g>
    </g>
  </svg>
);
