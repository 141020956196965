import {
  SET_ACCOUNT_LEVEL_INFO,
  SET_ACCOUNT_LEVEL_INFO_ERROR,
  SET_ACCOUNT_MODULE_PERMISSION,
  SET_ACCOUNT_MODULE_PERMISSION_ERROR,
  SET_FEATURE_PERMISSION,
  SET_FEATURE_PERMISSION_ERROR,
  SET_MASTER_PERMISSION,
  SET_MASTER_PERMISSION_ERROR,
} from "../constants/type";
const initialState = {
  accountlevelInfo: [],
  accountlevelInfoError: "",
  accountModulePermission: [],
  accountModulePermissionError: "",
  masterPermission: [],
  masterPermissionError: "",
  featurePermission: [],
  featurePermissionError: "",
};

const permissionsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ACCOUNT_LEVEL_INFO:
      return { ...state, accountlevelInfo: payload.data, accountlevelInfoError: "" };
    case SET_ACCOUNT_LEVEL_INFO_ERROR:
      return { ...state, accountlevelInfo: [], accountlevelInfoError: payload };
    case SET_ACCOUNT_MODULE_PERMISSION:
      return {
        ...state,
        accountModulePermission: payload.data,
        accountModulePermissionError: "",
      };
    case SET_ACCOUNT_MODULE_PERMISSION_ERROR:
      return {
        ...state,
        accountModulePermission: [],
        accountModulePermissionError: payload,
      };
    case SET_MASTER_PERMISSION:
      return {
        ...state,
        masterPermission: payload.data,
        masterPermissionError: "",
      };
    case SET_MASTER_PERMISSION_ERROR:
      return { ...state, masterPermission: [], masterPermissionError: payload };
    case SET_FEATURE_PERMISSION:
      return {
        ...state,
        featurePermission: payload.data,
        featurePermissionError: "",
      };
    case SET_FEATURE_PERMISSION_ERROR:
      return {
        ...state,
        featurePermission: [],
        featurePermissionError: payload,
      };
    default:
      return state;
  }
};

export default permissionsReducer;
