import React, { Component } from "react";
import {
  Select,
  FormControl,
  MenuItem,
  Button,
  Checkbox,
  Typography,
  InputLabel,
  TextField,
  FormHelperText,
  Backdrop,
  CircularProgress,
  Tooltip,
  Chip,
  Autocomplete,
  FormGroup,
  FormControlLabel,
  Stack,
  DialogContent,
  DialogTitle,
  Dialog,
  IconButton,
  Grid,
  DialogContentText,
  DialogActions,
  InputAdornment,
} from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { parseISO } from "date-fns";
import { withStyles } from "@mui/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  fetchPreviousValues,
  getDropdownOptions,
  getIdentifiersDynamicValues,
  rfqSubmit,
  getFileSpecifications,
  getTermsAndConditions,
  updateFileSpecifications,
  sendMailUri,
  prRfqSubmit,
  updateFileSpecificationswithmultiplefiles,
  pr_status_integration,
  modify_edit_qcs_values,
  quotationSubmit,
  industry_type,
  q_plus_screen,
  sendMailUriQuotation,
  postFileSpecifications,
  HOSTMAP,
  sendMailDataWriter,
  downloadmaterialDescription,
  // insertPrRfq,
} from "../../../config/apiUrl";
import axios from "axios";
import { styles } from "./style.js";
// import "../../../pages/style.css";
import CardTemplate from "../../Card/CardTemplate";
import VendorRecipient from "../../Dialogs/VendorRecipints/VendorRecipient";
import RFQStatic from "../../Card/RFQStaticTemplate";
import AlarmIcon from "@mui/icons-material/Alarm";
import UploadIcon from "@mui/icons-material/CloudUploadRounded";
// import ReminderTemporaryDrawer from "../ReminderTemporaryDrawer";
import { OpenInNew } from "@mui/icons-material";
import Details from "../../Dialogs/Details/Details";
import { openSnackbar } from "../../../redux/actions/snackbarAction";
import _ from "lodash";
import UploadFileDialog from "../../Dialogs/Upload/Upload";
import RFQSubmit from "../../Dialogs/RFQSubmit";
import {
  setReminderSettings,
  resetReminderSettings,
  setRFQSettings,
} from "../../../redux/actions/drawerActions";
import { setPrRfqs } from "../../../redux/actions/prrfqactions";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import NotificationAddIcon from "@mui/icons-material/NotificationAdd";
import UploadDynamic from "../../Dialogs/Upload/UploadDynamic";
import { resetCAT } from "../../../redux/resetApp";
import ConfirmAlert from "../../Dialogs/ConfirmAlert.js";
import TermsAndConditions from "../../Dialogs/Upload/TermsAndConditions";
import { setFileSpecification } from "../../../redux/actions/categoryAction";
import { setItemSpecifications } from "../../../redux/actions/itemActions";
import {
  setPrlinktoitemsforspecification,
  setPRselectedChange,
  setDefaultValues,
  setTemplateValues,
  setTermsAndConditions,
  setFileSpecificationFromApi,
} from "../../../redux/actions/prrfqactions";
import { ImportQuotation } from "../../../redux/actions/ImportQuotation";
import PrRFQSettings from "../../PRRFQ/RfqSettings";
import axiosInstance from "../../../interceptor/axiosInstance";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import QuotationFormSectionTable from "../QuotationTemplateSectionTable/QuotationTemplateSectionTable.js";
import QuotationCardTemplate from "../../Card/QuotationCardTemplate.js";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import QuotationDetailsSection from "../QuotationDetailsSection/QuotationDetailsSection";
import dayjs from "dayjs";
import successimage from "../../../assets/vendxAdminPanelAsstes/assetsnew/rfq_submit.svg";
import UploadDynamicQuotation from "../../Dialogs/Upload/uploadDynamicQuotation";
import UploadDynamicView from "../../Dialogs/Upload/UploadDynamicView";
import DestinationDetailsDialog from "../DestinationDetailsDialog/DestinationDialog";
import { getSymbol } from "../../AccountTemplate/CurrencySymbol";

let Date_Time_Not_Null = function (value1) {
  //return value1 && value1.isValid() ? true : false;
  //let date1 = new Date(value1);
  return typeof value1 == "object"
    ? moment(value1).isValid()
    : !isNaN(new Date(value1));
};

let Greater_Than_Date_Time = function (value1, value2) {
  let date1 = new Date(value1);
  let date2 = new Date(value2);

  return date1 > date2;
};

let Greater_Than_Current_Date_Time = function (value1) {
  let date1 = new Date(value1);
  let Today = new Date();

  return date1 > Today;
};
let prconfig = {};

let slab = {
  //has to be dynamic
  58: {
    isOpen: false,
  },
};

class QuotationTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: "",
      template: "",
      loading: false,
      quotationInitializeFlag: true,
      mainTemplateQuotation: {},
      msmainTemplateQuotation: {},
      notFilteredMainTemplateQuotation: {},
      errorArray: [],
      additionalInfoTemplate: {},
      IntlTemplate: {},
      shipment_mark: "",
      MaterialDescription: false,

      RFQStatic: {
        lock: false,
        star: false,
        MaterialDescription: false,
        RFQValidity: {
          HTMLAttributeName: "RFQValidity",
          FieldLabel: "RFQValidity",
          Validation: "",
          error: false,
          ErrorMessage: "Invalid Date",
          value: "",
          maxDays: 0,
        },
        RFQValidityTime: {
          HTMLAttributeName: "RFQValidityTime",
          FieldLabel: "RFQValidityTime",
          Validation: "",
          error: false,
          ErrorMessage: "Invalid Time",
          value: "23:30",
        },
        TBValidity: {
          HTMLAttributeName: "TBValidity",
          FieldLabel: "TBValidity",
          Validation: "",
          error: false,
          ErrorMessage: "Invalid TB Date",
          value: "",
        },
        TBValidityTime: {
          HTMLAttributeName: "TBValidityTime",
          FieldLabel: "TBValidityTime",
          Validation: "",
          error: false,
          ErrorMessage: "Invalid TB Time",
          value: "23:30",
        },
        RFQspecifications: {
          HTMLAttributeName: "RFQspecifications",
          FieldLabel: "RFQspecifications",
          Validation: "",
          error: false,
          ErrorMessage: "RFQspecifications is required",
          value: "",
        },
        RFQCCUsersmails: {
          HTMLAttributeName: "RFQCCUsersmails",
          FieldLabel: "RFQCCUsersmails",
          Validation: "",
          error: false,
          ErrorMessage: "Invalid!",
          value: "",
        },
        RFQContactCheck: {
          HTMLAttributeName: "RFQContactCheck",
          FieldLabel: "RFQContactCheck",
          Validation: "",
          error: false,
          ErrorMessage: "",
          value: false,
        },
        RFQUpdateCCUsersCheck: {
          HTMLAttributeName: "RFQUpdateCCUsersCheck",
          FieldLabel: "RFQUpdateCCUsersCheck",
          Validation: "",
          error: false,
          ErrorMessage: "",
          value: false,
        },
      },
      RFQDynamic: {},
      vendorRecipintOpen: false,
      vendorRecipintType: "",
      reminderDrawerOpen: false,
      specificationsOpen: false,
      RFQSubmitDialogOpen: false,
      rfqSubmitMsg: "",
      startDate: "",
      startDateTB: true,
      qty: "",
      rate: "",
      unit: "",
      rfqSubmitLoading: false,
      loaderArray: [],
      vendorType: "",
      vendorTerms: "",
      vendorTermsOpen: false,
      currentFieldID: "",
      incoTermError: false,
      paymentTermError: false,
      dynamicFileUploadOpen: false,
      currentFieldId: null,
      currentTemplate: null,
      categorySpecificationFiles: [],
      jsonTemplateloading: false,
      prevRFQLoading: false,
      prevQuotationFormLoading: false,
      prevRFQ: {},
      randomId: null,
      usedAttributesId: [],
      confirmDialogOpen: false,
      confirmAlertMsg: "",
      confirmAlertTitle: "",
      tandcDialogOpen: false,
      termsAndConditionsData: {},
      applyToAll: {},
      commonOptions: {},
      localPaymentFields: [],
      localIncoFields: [],
      intlPaymentFields: [],
      intlIncoFields: [],
      submitDisable: true,
      templateValue: {},
      slab: {},
      slabKey: 1,
      applyToAllSlab: false,
      quotationTemplateJson: [],
      openConfirmBox: false,
      selectedCurrency: "",
      mainSelectedInitiallyN: false,
      termsAndConditionsModalOpen: false,
      dataFromQuotationDetails: {},
      offlineDateError: "",
      offlineAttachment: "",
      offlineFieldError: "",
      industryTypeError: "",
      offlineReferenceError: "",
      industryTypeErrorMessage: "",
      remainTemplateQuotation: {},
      quotationSubmitSuccess: false,
      quotationSubmitSuccessMessage: "",
      quotationChecked: false,
      quotationSubmitLoading: false,
      industryTypeValidator: false,
      msmeValidator: false,
      industryType: this.props?.industryDetails?.type,
      msmeNumber: this.props?.industryDetails?.Number,
      switchState: false,
      fileSpecificationsOpen: false,
      specification: [],
      fileSpecLoading: true,
      fileAttachmentName: "",
      attachmentLoading: false,
      attachments: {},
      quotationImported: false,
      isDestinationDetailsDialogOpen: false,
      selectedCurrencyValidator: false,
      selectedCurrencyErrorMessage: "",
      rearrangedArray: [],
      allItemsTemplate: [],
      EvalResults: {},
      intialMaintemplateQuotation: {},
      disabledProps: {}
    };
    this.setIsDestinationDetailsDialogOpen =
      this.setIsDestinationDetailsDialogOpen.bind(this);
    this.setRearrangedTemplate = this.setRearrangedTemplate.bind(this);
    this.setItemsTemplate = this.setItemsTemplate.bind(this);
    this.disabledProps =
      this.disabledProps.bind(this);
    this._isMounted = false;
  }

  componentDidMount() {
    if (!this.props.quotationFormFlag) {
      this.props.quotationJsonTemplate.ITEMIZE[0]?.map((cell, index) => {
        if (cell.VisibilityFlag === "Y") {
          if (cell.Identifier === "matspecghclfile") {
            let RFQStaticUpdate = this.state.RFQStatic;
            RFQStaticUpdate.MaterialDescription = true;
          }
        }
      });
      // if (Object.keys(this.props.defaultValues).length !== 0) {
      //   let RFQStaticUpdate = this.state.RFQStatic;
      //   const { validityDate, priorityRfq, sealedGroupRfq, validityTime } =
      //     this.props.defaultValues;
      //   if ((validityDate._d || validityDate) && validityDate !== null) {
      //     RFQStaticUpdate.RFQValidity.value = validityDate;
      //     RFQStaticUpdate.RFQValidityTime.value = validityTime;

      //     RFQStaticUpdate.TBValidity.value = validityDate;
      //     RFQStaticUpdate.TBValidityTime.value = validityTime;
      //   }
      //   RFQStaticUpdate.lock = sealedGroupRfq;
      //   RFQStaticUpdate.star = priorityRfq;
      //   this.setState({
      //     RFQStatic: RFQStaticUpdate,
      //   });
      //   // let RFQStaticUpdate = this.state.RFQStatic
      //   // // const {RFQValidity,TBValidity,lock,star,RFQValidityTime,TBValidityTime} = this.props.defaultValues.RFQStatic;
      //   // const {RFQValidity,lock,star,RFQValidityTime} = this.props.defaultValues.RFQStatic;
      //   // const {startDate} = this.props.defaultValues.remiderDrawer;
      //   //  RFQStaticUpdate.RFQValidity.value = RFQValidity.value;
      //   //  RFQStaticUpdate.RFQValidityTime.value = RFQValidityTime.value;
      //   // //  RFQStaticUpdate.TBValidity.value = TBValidity.value;
      //   // //  RFQStaticUpdate.TBValidityTime.value = TBValidityTime.value;
      //   //  RFQStaticUpdate.lock = lock;
      //   //  RFQStaticUpdate.star = star;
      //   //  this.setState({
      //   //   RFQStatic : RFQStaticUpdate,
      //   //   startDate : startDate
      //   //  })
      // }
      this._isMounted = true;
      // if (this.props.prplants.length)
      //   prconfig = this.props?.pr_dropdown_values.prconfig;
      // this.props.setClick(this.fetchPrevious);
      this.initializeState();
      // this.initializeLocalRecipientsState();
      // this.initializeIntlRecipientsState();
      this.initializeRFQDynamicState();
      this.fetchTermsAndCondtions();

      if (this.props?.cc_emails) {
        let RFQStaticUpdate = { ...this.state.RFQStatic };
        RFQStaticUpdate.RFQCCUsersmails.value = this.props?.cc_emails;
        this.setState(
          {
            RFQStatic: RFQStaticUpdate,
          },
          () => {
            if (document.getElementsByName("RFQCCUsersmails")[0]) {
              document.getElementsByName("RFQCCUsersmails")[0].value =
                this.props?.cc_emails;
            }
          }
        );
      }

      if (this.props.featurePermission?.sealed_rfq?.feature_value === "Y") {
        let RFQStaticUpdate = this.state.RFQStatic;
        RFQStaticUpdate.lock = true;
        this.setState({
          RFQStatic: RFQStaticUpdate,
        });
      }

      if (
        this.props.featurePermission?.max_rfq_validity_days?.feature_value ===
        "Y"
      ) {
        let days =
          this.props.featurePermission?.max_rfq_validity_days
            ?.feature_permission_value;
        let RFQStaticUpdate = { ...this.state.RFQStatic };
        RFQStaticUpdate.RFQValidity.maxDays = days;
        this.setState({
          RFQStatic: RFQStaticUpdate,
        });
      }
      //  let fileSpecificationFromApi = this.props.fileSpecificationFromApi;
      let categoryId = this.props.selectedCategory?.id;
      this.setState({ randomId: this.props?.randomId }, () => {
        if (Object.keys(this.props?.prlineitems).length !== 0) {
          //getting flile specification data from redux if it is available else calling api
          // if(Object.keys(fileSpecificationFromApi).length !==0 && fileSpecificationFromApi[categoryId]){
          //   this.props.setFileSpecification(fileSpecificationFromApi[categoryId]);
          // }else{
          this.fetchprlineitemsfiles(); // fetching category specs here to show count
          // }
        } else {
          this.fetchCategorySpecs((files) => {
            let selected_category_idres = this.props.selectedCategory?.id;
            this.setState({
              categorySpecificationFiles:
                files?.[selected_category_idres] || [],
            });
            this.props.setFileSpecification(
              files?.[selected_category_idres] || []
            );
          });
        }
      });

      if (this.props.rfqSettingValues.priorityRfq === true) {
        this.setState({
          RFQStatic: {
            ...this.state.RFQStatic,
            star: this.props.rfqSettingValues.priorityRfq,
          },
        });
      }
    } else {
      this.props.quotationJsonTemplate.ITEMIZE[0]?.map((cell, index) => {
        if (cell.VisibilityFlag === "Y") {
          if (cell.Identifier === "matspecghclfile") {
            this.setState({
              MaterialDescription: true,
            });
          }
        }
      });
      this.props.setClick(this.fetchPrevious);
      this.initializeState();
      // this.initializeLocalRecipientsState();
      // this.initializeIntlRecipientsState();
      this.initializeRFQDynamicState();
      // this.fetchTermsAndCondtions();
    }
  }

  setDataFromQuotationChild = (data) => {
    this.setState({ dataFromQuotationDetails: data });
  };

  // handleCurrencyChange = (event) => {
  //   this.setState({ selectedCurrency: event.target.value });
  // };

  handleCurrencyChange = (event) => {
    this.setState({
      selectedCurrency: event.target.value,
      selectedCurrencyValidator: !event.target.value, // Update validator state based on selection
    });
  };

  handleIndustryTypeChange = (value) => {
    this.setState({ industryType: value });
  };

  handleMsmeNumberChange = (value) => {
    this.setState({ msmeNumber: value });
  };

  handleConfirmBoxOpen = () => {
    this.setState({
      openConfirmBox: true,
    });
  };

  toggleSwitch = (key) => {
    this.setState((prevState) => ({
      switchState: {
        ...prevState.switchState,
        [key]: !prevState.switchState[key],
      },
    }));
  };

  handleConfirmBoxClose = () => {
    this.setState({
      openConfirmBox: false,
    });
  };

  downloadTransactionExcel = (format) => {
    // Implement your downloadTransactionExcel function logic here
  };

  validateFileExistance = () => {
    // Implement your validateFileExistance function logic here
  };

  handleImport = () => {
    // Implement your import logic here
    // ...
    this.handleConfirmBoxOpen();
    // Close the dialog after handling the import
    // this.handleConfirmBoxClose();
  };

  //slab functions start
  handleSlab = (evt, item) => {
    this.setState({
      slab: { ...this.state.slab, [item.id]: { isOpen: evt.target.checked } }, //set is open for item
    });
    if (this.state.applyToAllSlab == true) {
      this.setState({
        applyToAllSlab: false,
      });
    }
    //remove value if slab is closed
    if (!evt.target.checked) {
      this.setState((prevState) => ({
        ...prevState,
        mainTemplate1: {
          ...prevState.mainTemplateQuotation,
          [item.id]: {
            ...prevState.mainTemplateQuotation[item.id],
            slab: [
              { id: 1, start: "1", end: "", error: false },
              { id: 2, start: "More Than", end: "", error: false },
            ],
          },
        },
      }));
    }
  };
  //add row in slab
  slabAddRow = (idx, index) => {
    const re = /((\b|\+|-)(0|([1-9][0-9]*))(\.[0-9]+)?)\b/;
    let allSlab =
      this.state.mainTemplateQuotation[this.props.selectedItems[index].id][
      "slab"
      ];
    if (!re.test(allSlab[allSlab.length - 1].end)) {
      this.props.openSnackbar(
        "error",
        ` Please provide a valid ${this.props?.displayOptions["label"]} for ${this.props.selectedItems[index].code}`
      );
      return;
    } else if (
      parseFloat(allSlab[allSlab.length - 2].start) >=
      parseFloat(allSlab[allSlab.length - 2].end)
    ) {
      this.props.openSnackbar(
        "error",
        `  Please provide a valid ${this.props?.displayOptions["label"]} for ${this.props.selectedItems[index].code}`
      );
      return;
    } else if (allSlab.some((obj) => obj.end == "" || obj.error == true)) {
      this.props.openSnackbar(
        "error",
        `Please provide a valid ${this.props?.displayOptions["label"]} for${this.props.selectedItems[index].code}.`
      );
      return;
    } else if (
      allSlab.length ==
      parseInt(this.props.rfqSettingValues?.allowed_slab_limit)
    ) {
      this.props.openSnackbar(
        "error",
        `Maximum ${this.props.rfqSettingValues.allowed_slab_limit} ${this.props?.displayOptions["label"]} allowed per item`
      );
      return;
    }
    let value = allSlab[idx].end;
    let val = allSlab[idx].end.toString().split(".")[1];
    let isAllZero = val && val.split("").every((char) => char == "0");
    if (val && val.length && !isAllZero) {
      if (val.length == 1) {
        value = parseFloat(value) + 0.1;
        value = value.toFixed(1);
      } else if (val.length == 2) {
        value = parseFloat(value) + 0.01;
        value = value.toFixed(2);
      } else if (val.length == 3) {
        value = parseFloat(value) + 0.001;
        value = value.toFixed(3);
      }
    } else {
      value = parseInt(value) + 1;
    }
    let newSlab = {
      id: Math.random().toString(36).slice(2),
      start: `${parseFloat(value)}`,
      end: "",
      error: false,
    };
    let popped = allSlab.pop(); //remove last element from array
    popped = { ...popped, end: "" };
    let updateSlab = [...allSlab, newSlab];
    updateSlab.push(popped);
    this.setState(
      (prevState) => ({
        ...prevState,
        mainTemplateQuotation: {
          ...prevState.mainTemplateQuotation,
          [this.props.selectedItems[index].id]: {
            ...prevState.mainTemplateQuotation[
            this.props.selectedItems[index].id
            ],
            slab: updateSlab,
          },
        },
      }),
      () => {
        this.handleKey(index);
      }
    );
  };
  //remove row from slab
  slabRemoveRow = (idx, index) => {
    if (this.state.applyToAllSlab == true) {
      this.setState({
        applyToAllSlab: false,
      });
    }
    let allSlab =
      this.state.mainTemplateQuotation[this.props.selectedItems[index].id][
      "slab"
      ];
    let updateSlab = allSlab.filter((row, i) => i != idx);
    let popped = updateSlab.pop();
    popped = { ...popped, end: updateSlab[updateSlab.length - 1].end };
    if (updateSlab[idx]) {
      updateSlab[idx] = { ...updateSlab[idx], start: allSlab[idx].start };
    }
    updateSlab.push(popped);
    this.setState(
      (prevState) => ({
        ...prevState,
        mainTemplateQuotation: {
          ...prevState.mainTemplateQuotation,
          [this.props.selectedItems[index].id]: {
            ...prevState.mainTemplateQuotation[
            this.props.selectedItems[index].id
            ],
            slab: updateSlab,
          },
        },
      }),
      () => {
        this.handleKey(index);
      }
    );
  };
  //handle input in row
  handleRowInput = (evt, row, id, index) => {
    if (this.state.applyToAllSlab == true) {
      this.setState({
        applyToAllSlab: false,
      });
    }
    let allSlab =
      this.state.mainTemplateQuotation[this.props.selectedItems[index].id][
      "slab"
      ];
    let popped = allSlab.pop();
    let updateSlab = allSlab.map((obj, i) => {
      let newObj = { ...obj };
      const re = /^[0-9]{1,11}(?:\.[0-9]{1,3})?$/;
      if (i == id) {
        if (
          parseFloat(evt.target.value) <= parseFloat(newObj.start) ||
          !re.test(evt.target.value)
        ) {
          newObj.error = true;
          newObj.end = "";
        } else {
          newObj.end = parseFloat(evt.target.value);
          newObj.error = false;
        }
      } else if (i == id + 1) {
        if (evt.target.value != "" && re.test(evt.target.value)) {
          let value = evt.target.value;
          let val = evt.target.value.toString().split(".")[1];
          let isAllZero = val && val.split("").every((char) => char == "0");
          if (val && val.length && !isAllZero) {
            if (val.length == 1) {
              value = parseFloat(value) + 0.1;
              value = value.toFixed(1);
            } else if (val.length == 2) {
              value = parseFloat(value) + 0.01;
              value = value.toFixed(2);
            } else if (val.length == 3) {
              value = parseFloat(value) + 0.001;
              value = value.toFixed(3);
            }
          } else {
            value = parseInt(value) + 1;
          }
          newObj.start = `${parseFloat(value)}`;
          if (parseFloat(newObj.end) <= parseFloat(newObj.start)) {
            newObj.error = true;
          } else {
            newObj.error = false;
          }
        } else if (evt.target.value == "") {
          newObj.start = "";
        }
      }
      return newObj;
    });
    popped = { ...popped, end: updateSlab[updateSlab.length - 1].end };
    updateSlab.push(popped);
    this.setState((prevState) => ({
      ...prevState,
      mainTemplateQuotation: {
        ...prevState.mainTemplateQuotation,
        [this.props.selectedQuotedItems[index].id]: {
          ...prevState.mainTemplateQuotation[
          this.props.selectedQuotedItems[index].id
          ],
          slab: updateSlab,
        },
      },
    }));
  };
  //for apply to all in slab
  handleApplyToAllSlab = (evt, index, itemId) => {
    var found = this.props.selectedItems.find((element) => {
      if (this.state.slab[element.id].isOpen) {
        return element;
      }
    });
    let allSlab = this.state.mainTemplateQuotation[found.id]["slab"];
    if (allSlab.some((obj) => obj.error == true || obj.end == "")) {
      this.props.openSnackbar(
        "error",
        `Please provide a valid ${this.props?.displayOptions["label"]} for ${found.code}.`
      );
      return;
    }
    this.setState({
      applyToAllSlab: evt.target.checked,
    });
    this.props.selectedItems.forEach((item) => {
      if (this.state.slab[item.id].isOpen) {
        this.setState((prevState) => ({
          ...prevState,
          mainTemplateQuotation: {
            ...prevState.mainTemplateQuotation,
            [item.id]: {
              ...prevState.mainTemplateQuotation[item.id],
              slab: allSlab.map((obj, i) => {
                return { ...obj, id: Math.random().toString(36).slice(2) };
              }),
            },
          },
        }));
      }
    });
  };
  //change key for render
  handleKey = (index) => {
    let allSlab =
      this.state.mainTemplateQuotation[this.props.selectedItems[index].id][
      "slab"
      ];
    let updateSlab = allSlab.map((obj, i) => {
      return { ...obj, id: Math.random().toString(36).slice(2) };
    });
    this.setState((prevState) => ({
      ...prevState,
      mainTemplateQuotation: {
        ...prevState.mainTemplateQuotation,
        [this.props.selectedItems[index].id]: {
          ...prevState.mainTemplateQuotation[
          this.props.selectedItems[index].id
          ],
          slab: updateSlab,
        },
      },
    }));
  };

  handleItemizeInputChange = (
    evt,
    index,
    FieldId,
    Identifier,
    ElementType,
    key
  ) => {
    const { value, type, checked } = evt.target;
    const { mainTemplateQuotation } = this.state;

    if (mainTemplateQuotation[key][FieldId]["value"] !== value) {
      this.setState({
        applyToAll: {
          ...this.state.applyToAll,
          [`${FieldId}_applyToAll`]: false,
          [`${FieldId}_applyToAll`]: false,
          [`${FieldId}_applyToAll`]: false,
        },
      });
    }
    this.setState(
      (prevState) => ({
        ...prevState,
        mainTemplateQuotation: {
          ...prevState.mainTemplateQuotation,
          [key]: {
            ...prevState.mainTemplateQuotation[key],
            [FieldId]: {
              ...prevState.mainTemplateQuotation[key][FieldId],
              value:
                Identifier == "GSTValue"
                  ? value
                  : type !== "checkbox"
                    ? //Number.isInteger(parseFloat(evt.target.value))
                    !isNaN(evt.target.value) && evt.target.value != ""
                      ? parseFloat(evt.target.value, 10)
                      : evt.target.value
                    : checked,
              error:
                type !== "checkbox"
                  ? false
                  : prevState.mainTemplateQuotation[key][FieldId].error,
            },
          },
        },
      }),
      () => {
        this.validateInput(FieldId, index);
        if (
          Identifier === "Budgetedrate" ||
          Identifier === "rate" ||
          Identifier === "CIFRate" ||
          Identifier === "Rate"
        ) {
          this.setState((prevState) => ({
            ...prevState,
            mainTemplateQuotation: {
              ...prevState.mainTemplateQuotation,
              [key]: {
                ...prevState.mainTemplateQuotation[key],
                rate: value,
              },
            },
          }));
        } else if (Identifier === "ProductQuantity") {
          this.setState((prevState) => ({
            ...prevState,
            mainTemplateQuotation: {
              ...prevState.mainTemplateQuotation,
              [key]: {
                ...prevState.mainTemplateQuotation[key],
                qty: value,
              },
            },
          }));
        }
      }
    );

    window.localStorage.setItem(
      "mainTemplateQuotation",
      JSON.stringify(this.state.mainTemplateQuotation)
    );
  };
  handleMSData = (msdata, key, rowindex) => {
    let msmainTemplateQuotationdata = msdata;
    msmainTemplateQuotationdata[key][rowindex]["mainData"] =
      this.state.mainTemplateQuotation[key];
    this.setState((prevState) => ({
      ...prevState,
      msmainTemplateQuotation: msmainTemplateQuotationdata,
    }));
  };
  fetchprlineitemsfiles = () => {
    const { prlineitems, selectedItems } = this.props;
    let prno_items_mapping = {};
    let categoryarray = [];
    selectedItems.forEach((items, item_index) => {
      const itemid = items.id;
      categoryarray = this.Fetchprlineitemspecificationfiles(
        prlineitems[itemid],
        itemid,
        prno_items_mapping,
        categoryarray,
        item_index
      );
    });

    // if(categoryarray?.length > 0){
    this.getprlineitemspecificationfiles(categoryarray, "category");
    // }
    this.props.setPrlinktoitemsforspecification(prno_items_mapping);
  };
  Fetchprlineitemspecificationfiles = (
    pr_lineitemdata,
    itemid,
    prno_items_mapping,
    categoryarray,
    item_index
  ) => {
    let itemarray = [];
    pr_lineitemdata.forEach((object, i) => {
      const items_attechment = object.attachment || [];
      const category_attechment = object.pr?.attachment || [];
      if (category_attechment.length !== 0) {
        if (prno_items_mapping.hasOwnProperty(object.pr.number)) {
          if (!prno_items_mapping[object.pr.number].hasOwnProperty(itemid)) {
            prno_items_mapping[object.pr.number][itemid] = category_attechment;
          }
        } else {
          prno_items_mapping[object.pr.number] = {};
          prno_items_mapping[object.pr.number][itemid] = [];
          prno_items_mapping[object.pr.number][itemid] = category_attechment;
        }
      }
      if (items_attechment) itemarray = [...itemarray, ...items_attechment];
      if (category_attechment)
        categoryarray = [...categoryarray, ...category_attechment];
    });
    this.getprlineitemspecificationfiles(
      itemarray,
      "items",
      itemid,
      item_index
    );
    return categoryarray;
  };
  getprlineitemspecificationfiles = async (
    files_ids,
    type,
    itemid,
    item_index
  ) => {
    let response = {};
    if (files_ids.length > 0) {
      const url = getFileSpecifications(files_ids);
      response = await axiosInstance.get(url);
    }
    if (type === "category") {
      this.fetchCategorySpecs((files) => {
        let selected_category_idres = this.props.selectedCategory?.id;
        const categoryarray = response.data?.data || [];
        const old_files = files?.[selected_category_idres] || [];
        let all_files = [...categoryarray, ...old_files];
        this.props.setFileSpecification(all_files || []);
        //setting flile specification data into redux
        // this.props.setFileSpecificationFromApi({[selected_category_idres]:all_files || []})
      });
    } else {
      let itemmasterfiles = {};

      this.getitemspecificfiles(item_index, (files) => {
        itemmasterfiles = files;
        const itemarray = response.data?.data || [];
        const old_files = files?.[itemid] || [];
        let all_files = [...itemarray, ...old_files];
        this.props.setItemSpecifications({ [itemid]: all_files });
      });
    }
  };
  getitemspecificfiles = async (item_index, callback) => {
    const url = updateFileSpecificationswithmultiplefiles(
      [this.props.selectedItems[item_index]],
      "items"
    );
    let response = await axiosInstance.get(url);
    const nearresponse = response.data?.data;
    callback(nearresponse);
  };

  fetchCategorySpecs = async (callback) => {
    const url = updateFileSpecifications(
      this.props.selectedCategory?.id,
      "categories"
    );

    let response = await axios.get(url);
    const nearresponse = response.data?.data;
    callback(nearresponse);
  };

  generateRandom = (min, max) => {
    const timestring = new Date().getTime();
    const numstring = Math.floor(Math.random() * (max - min + 1)) + min;
    return timestring + "." + numstring;
  };

  extractAndFormat = (inputString) => {
    const pattern = /(\d+)\.\w\.\((f\d+)\)/g;
    const seen = new Set(); // Set to keep track of seen values
    let matches;
    const results = [];

    while ((matches = pattern.exec(inputString)) !== null) {
      const value = `${matches[1]}.${matches[2]}`;
      if (!seen.has(value)) {
        // Check if value is not already in the set
        results.push(value);
        seen.add(value); // Add value to the set
      }
    }

    return results;
  };
  handleFileSpecificationsOpen = (value, name) => {
    this.setState({ fileSpecificationsOpen: true });
    this.setState({ fileAttachmentName: name });
    this.getExistingFiles(value);
  };

  handleUploadClose = () => {
    this.setState({
      fileSpecificationsOpen: false,
      specification: [],
      fileSpecLoading: true,
    });
  };

  getExistingFiles(file_id) {
    const queryString = file_id
      .map((file) => `file_ids[]=${file.id}`)
      .join("&");
    const url = `${postFileSpecifications}?${queryString}`;

    axios
      .get(url)
      .then((res) => {
        this.setState({ specification: res.data });
        this.setState({ fileSpecLoading: false });
      })
      .catch((err) => {
        console.log(err, " downloadFile error");
        this.props.openSnackbar(
          "error",
          err.response.data?.message
            ? err.response.data?.message
            : "Something went wrong!"
        );
        this.setState({ fileSpecLoading: false });
      });
  }

  getExistingFilesForAttachment = (file_id) => {
    this.setState({ attachmentLoading: true });
    const queryString = file_id
      .map((file) => `file_ids[]=${file.id}`)
      .join("&");
    const url = `${postFileSpecifications}?${queryString}`;

    axios
      .get(url)
      .then((res) => {
        this.setState({ attachments: res.data.data });
        this.setState({
          dynamicFileUploadOpen: true,
          attachmentLoading: false,
        });
      })
      .catch((err) => {
        console.log(err, " downloadFile error");
        this.props.openSnackbar(
          "error",
          err.response.data?.message
            ? err.response.data?.message
            : "Something went wrong!"
        );
        this.setState({ attachmentLoading: false });
      });
  };
  isValidJsonString = (str) => {
    try {
      const parsed = JSON.parse(str);

      return typeof parsed === "object" && parsed !== null;
    } catch (e) {
      return false;
    }
  };
  finalFormulaFunction = (
    formula,
    placeholders,
    // basicPriceRate,
    fieldId,
    key,
    value,
    rowdata,
    fromRD = false
  ) => {
    let mainData = this.state.mainTemplateQuotation[key];
    placeholders.forEach((placeholder) => {
      const matchId = placeholder.slice(2, -1);
      let matchValue =
        (mainData && mainData[matchId] && mainData[matchId].value) || undefined;
      if (matchValue === undefined) {

        let fieldCell =
          // this.props.quotationJsonTemplate &&
          // this.props.quotationJsonTemplate.ITEMIZE &&
          // this.props.quotationJsonTemplate.ITEMIZE[0] && //Refactor
          // this.props.quotationJsonTemplate.ITEMIZE[0][fieldId] &&
          // this.props.quotationJsonTemplate.ITEMIZE[0][fieldId].find(
          //   (cell) => cell.FieldId === matchId
          // );
          // if (fieldCell) {
          //   matchValue = "(" + fieldCell.DefaultValue.Formula + ")";
          //   let TemperoraryPlaceholders = "";
          //   if (
          //     fieldCell.DefaultValue.Formula &&
          //     fieldCell.DefaultValue.Formula !== ""
          //   ) {
          //     TemperoraryPlaceholders =
          //       fieldCell.DefaultValue.Formula.match(/\(f\d+\)/g);
          //   } else if (
          //     fieldCell.DefaultFormula &&
          //     fieldCell.DefaultFormula !== ""
          //   ) {
          //     TemperoraryPlaceholders =
          //       fieldCell.DefaultFormula.match(/\(f\d+\)/g);
          //   }
          //   if (TemperoraryPlaceholders != "") {
          //     let data_hash = this.finalFormulaFunction(
          //       fieldCell.DefaultValue.Formula,
          //       TemperoraryPlaceholders,
          //       // basicPriceRate,
          //       matchId,
          //       key,
          //       0,
          //       rowdata,
          //       fromRD
          //     );
          //     if (data_hash.eval) {
          //       matchValue = eval(data_hash.formula);
          //     } else {
          //       matchValue = data_hash.value;
          //     }
          //   } else {
          //     matchValue = fieldCell.DefaultValue || 0;
          //   }
          // } else {
          matchValue =
          (this.state.additionalInfoTemplate &&
            this.state.additionalInfoTemplate[matchId] &&
            this.state.additionalInfoTemplate[matchId].value) ||
          0;
        if (
          this.state.additionalInfoTemplate &&
          this.state.additionalInfoTemplate[matchId] &&
          this.state.additionalInfoTemplate[matchId].Identifier === "GSTType"
        ) {
          matchValue = "'" + matchValue + "'";
        }

        if (
          this.state.additionalInfoTemplate &&
          this.state.additionalInfoTemplate[matchId] &&
          this.state.additionalInfoTemplate[matchId].Identifier === "GSTValue"
        ) {
          matchValue = "'" + (matchValue - 0.0) + "'";
        }
        fieldCell = this.state.additionalInfoTemplate[0]?.find(
          (cell) => cell.FieldId === matchId
        );
        // }
      }
      // if (matchValue === "") {
      //   matchValue = '';
      // }
      if (matchValue !== undefined) {
        // If value is defined, update the result
        if (
          mainData &&
          mainData[matchId] &&
          mainData[matchId].Identifier === "GSTType"
        ) {
          matchValue = "'" + matchValue + "'";
        }
        if (
          mainData &&
          mainData[matchId] &&
          mainData[matchId].Identifier === "GSTValue"
        ) {
          matchValue = "'" + (matchValue - 0.0) + "'";
        }
        // "Identifier": "GSTValue",
        // if (basicPriceRate.FieldId === fieldId) {
        //   value = matchValue || 0;
        //   return { formula: formula, value: value, eval: 0 };
        // } else {
        formula = formula.replace(placeholder, matchValue);
        // }
      }
    });
    return { formula: formula, value: value, eval: 1 };
  };

  fetchPrevious = () => {
    const { quotationJsonTemplate, lastTransactionData } = this.props;
    if (quotationJsonTemplate?.ITEMIZE?.length && lastTransactionData) {
      this.setState({
        prevQuotationFormLoading: true,
      });
      let notFilteredMainTemplateQuotation = {};
      let mainTemplateQuotation = _.cloneDeep(this.state.mainTemplateQuotation);
      let remainTemplateQuotation = _.cloneDeep(
        this.state.remainTemplateQuotation
      );
      let additionalInfoTemplate;
      if (this.props.vendorType == "Local") {
        additionalInfoTemplate = this.initializeLocalRecipientsState();
      } else {
        additionalInfoTemplate = this.initializeIntlRecipientsState();
      }
      /**
       * old code in case issue is there open this and commit
       */
      for (const key in mainTemplateQuotation) {
        const currentItem = mainTemplateQuotation[key];

        for (const key2 in currentItem) {
          const currentField = currentItem[key2];
          if (
            currentField?.Identifier === "GSTType" &&
            currentField?.["DefaultFormula"] == "" &&
            this.props.selectedQuotedItems.vendors[0]?.gst_type
          ) {
            if (
              !this.props.selectedQuotedItems.vendors[0]?.gst_type.includes(
                "###"
              )
            ) {
              let substring =
                this.props.selectedQuotedItems.vendors[0]?.gst_type.split(
                  "_"
                )[0];

              const filteredData = currentField.options.find(
                (item) =>
                  item.code.includes(substring) && !item.code.includes("UT")
              );
              currentField.value = filteredData?.code;
            } else if (
              this.props.selectedQuotedItems.vendors[0]?.gst_type.includes(
                "###"
              )
            ) {
              let optionArray = [];
              let subArray =
                this.props.selectedQuotedItems.vendors[0]?.gst_type.split(
                  "###"
                );
              subArray.map((element) => {
                const filteredData = currentField.options.find((item) => {
                  let substring = element.split("_")[0];
                  return (
                    item.code.includes(substring) && !item.code.includes("UT")
                  );
                });
                optionArray.push(filteredData?.code);
              });
              currentField.value = optionArray.join("###");
            }
          }
          const defaultFormula = currentField?.["DefaultFormula"];
          if (currentField && currentField.hasOwnProperty("DefaultFormula")) {
            const defaultFormula = currentField["DefaultFormula"];
            if (defaultFormula) {
              const formattedResult = this.extractAndFormat(defaultFormula);

              if (formattedResult && formattedResult.length >= 1) {
                const valuesFound = formattedResult
                  .map((identifier, index) => {
                    const attributeKey = identifier.split(".");
                    const subStringAttrKey = attributeKey[1].substring(1);
                    // Check if the solution array exists and has the identifier
                    const valuesInSolution = lastTransactionData.itemize[
                      key
                    ]?.solution
                      ?.map((obj) => obj?.data[0]?.[subStringAttrKey])
                      ?.filter((value) => value !== undefined);
                    // Fallback to checking in common if valuesInSolution is empty or undefined
                    const fallbackValue =
                      lastTransactionData.common?.[subStringAttrKey];
                    const fetchTxn = this.props.fetchedTxn?.[
                      attributeKey[0]
                    ]?.itemize[key]?.solution
                      ?.map((obj) => obj?.data[0]?.[subStringAttrKey])
                      ?.filter((value) => value !== undefined);
                    if (valuesInSolution && valuesInSolution.length > 0) {
                      return valuesInSolution;
                    } else if (
                      valuesInSolution &&
                      valuesInSolution.length == 0 &&
                      fetchTxn &&
                      fetchTxn.length > 0
                    ) {
                      return fetchTxn;
                    } else if (fallbackValue !== undefined) {
                      return [fallbackValue];
                    } else {
                      return [];
                    }
                  })
                  .flat();

                let finalValue = null;
                // Evaluate conditional statements in DefaultFormula
                for (let i = 0; i < valuesFound.length; i++) {
                  // if (valuesFound[i] !== null) {
                  finalValue = valuesFound[i];
                  break;
                  // }
                }
                if (finalValue !== null) {
                  const integerPattern = /^\d+$/;
                  currentItem[key2]["value"] =
                    integerPattern.test(finalValue) &&
                      currentItem[key2].Identifier != "GSTValue"
                      ? parseInt(finalValue, 10)
                      : finalValue;
                  // currentItem[key2]["value"] = finalValue;

                  currentItem[key2]["error"] = false;

                  if (currentItem[key2]["Identifier"] === "ProductQuantity") {
                    currentItem["qty"] = finalValue;
                  }

                  if (
                    currentItem[key2]["Identifier"] === "EstimatedCost" ||
                    currentItem[key2]["Identifier"] ===
                    "EstimatedCostForeign" ||
                    currentItem[key2]["Identifier"] === "Budgetedrate" ||
                    currentItem[key2]["Identifier"] === "Rate" ||
                    currentItem[key2]["Identifier"] === "CIFRate" ||
                    currentItem[key2]["Identifier"] === "rate"
                  ) {
                    currentItem["rate"] = finalValue;
                  }

                  const attributeName = currentItem[key2]["HTMLAttributeName"];
                  const index1 = this.state.errorArray.indexOf(attributeName);

                  if (index1 !== -1) {
                    this.setState((prevState) => ({
                      errorArray: prevState.errorArray.filter(
                        (item, index) => index !== index1
                      ),
                    }));
                  }
                }
              }
            }
          } else {
            // console
            //   .log
            //   // "Skipping currentField because DefaultFormula is undefined."
            //   ("currentFieldValue", currentField);
          }
        }
      }

      // Additional Info state management for Additional Info Section

      Object.entries(additionalInfoTemplate).forEach(([key, currentField]) => {
        if (
          currentField.Identifier === "GSTType" &&
          currentField?.["DefaultFormula"] == "" &&
          this.props.selectedQuotedItems.vendors[0]?.gst_type
        ) {
          if (
            !this.props.selectedQuotedItems.vendors[0]?.gst_type?.includes(
              "###"
            )
          ) {
            let substring =
              this.props.selectedQuotedItems.vendors[0]?.gst_type.split("_")[0];
            const filteredData = currentField.options.find(
              (item) =>
                item.code.includes(substring) && !item.code.includes("UT")
            );
            currentField.value = filteredData?.code;
          } else if (
            this.props.selectedQuotedItems.vendors[0]?.gst_type.includes("###")
          ) {
            {
              let optionArray = [];
              let subArray =
                this.props.selectedQuotedItems.vendors[0]?.gst_type.split(
                  "###"
                );
              subArray.map((element) => {
                const filteredData = currentField.options.find((item) => {
                  let substring = element.split("_")[0];
                  return (
                    item.code.includes(substring) && !item.code.includes("UT")
                  );
                });
                optionArray.push(filteredData?.code);
              });
              currentField.value = optionArray.join("###");
            }
          }
        }
        const attributeNameWithoutF = currentField.HTMLAttributeName
          ? currentField.HTMLAttributeName.replace("f", "")
          : null;
        if (
          attributeNameWithoutF &&
          lastTransactionData.common.hasOwnProperty(attributeNameWithoutF)
        ) {
          // Set the value to the currentField
          currentField.value =
            lastTransactionData.common[attributeNameWithoutF];
          currentField.error = false;

          // Remove field from errorArray if present
          const index1 = this.state.errorArray.indexOf(attributeNameWithoutF);
          if (index1 !== -1) {
            this.state.errorArray.splice(index1, 1);
          }

          // Set value to corresponding HTML element if it exists
          const element = document.getElementsByName(attributeNameWithoutF)[0];
          if (element) {
            element.value = currentField.value;
          }
        } else if (
          currentField.DefaultFormula &&
          currentField.DefaultFormula.trim() != ""
        ) {
          // Extract attribute codes from DefaultFormula
          const attributeCodes =
            currentField.DefaultFormula.match(/f\d+/g) || [];
          const formattedResult = this.extractAndFormat(
            currentField.DefaultFormula
          );
          // Iterate over each attribute code
          formattedResult.forEach((attributeCode) => {
            // Extract attribute key from attributeCode
            const attributeKey = attributeCode.split(".");
            const subStringAttrKey = attributeKey[1].substring(1);
            // Check if the attribute key exists in lastTransactionData.common
            if (lastTransactionData.common.hasOwnProperty(subStringAttrKey)) {
              // Set the value to the currentField
              if (
                this.isValidJsonString(
                  lastTransactionData.common[subStringAttrKey]
                ) &&
                lastTransactionData.common[subStringAttrKey] &&
                currentField.ElementType !== "F"
              ) {
                let element = JSON.parse(
                  lastTransactionData.common[subStringAttrKey]
                )[this.props.selectedQuotedItems.vendors[0].id];

                currentField.value =
                  this.props.companyInfo?.default_dropdown_type == 10
                    ? `${element.element_name}-${element.element_code}`
                    : `${element.element_code}-${element.element_name}`;
              } else {
                currentField.value =
                  lastTransactionData.common[subStringAttrKey];
              }
              currentField.error = false;
              // Remove field from errorArray if present
              const index2 = this.state.errorArray.indexOf(
                currentField.HTMLAttributeName
              );
              if (index2 !== -1) {
                this.state.errorArray.splice(index2, 1);
              }

              // Set value to corresponding HTML element if it exists
              const element = document.getElementsByName(
                currentField.HTMLAttributeName
              )[0];
              if (element) {
                element.value = currentField.value;
              }
            } else if (
              this.props.fetchedTxn?.[attributeKey[0]]?.common.hasOwnProperty(
                subStringAttrKey
              )
            ) {
              currentField.value =
                this.props.fetchedTxn?.[attributeKey[0]]?.common[
                subStringAttrKey
                ];
              currentField.error = false;

              const index2 = this.state.errorArray.indexOf(
                currentField.HTMLAttributeName
              );
              if (index2 !== -1) {
                this.state.errorArray.splice(index2, 1);
              }

              const element = document.getElementsByName(
                currentField.HTMLAttributeName
              )[0];
              if (element) {
                element.value = currentField.value;
              }
            }
          });
        } else if (
          currentField.DefaultFormula == "" &&
          currentField.DefaultValue.length > 0 &&
          currentField.ElementType == "T"
        ) {
          currentField.value = currentField.DefaultValue;
        }
      });
      // Filter out items not present in inquiry_item_ids
      if (this.props.itemSpecific === true) {
        const filteredMainTemplateQuotation = Object.fromEntries(
          Object.entries(mainTemplateQuotation).filter(([key]) =>
            this.props.inquiryItemsId.includes(parseInt(key))
          )
        );
        const NotfilteredMainTemplateQuotation = Object.fromEntries(
          Object.entries(mainTemplateQuotation)
            .filter(
              ([key]) => !this.props.inquiryItemsId.includes(parseInt(key))
            )
            .map(([key, value]) => {
              return [key, { ...value, selected: "N" }];
            })
        );
        mainTemplateQuotation = filteredMainTemplateQuotation;
        notFilteredMainTemplateQuotation = NotfilteredMainTemplateQuotation;
      }
      // Replace mainTemplateQuotation with filteredMainTemplateQuotation
      this.setState({
        remainTemplateQuotation,
        mainTemplateQuotation,
        intialMaintemplateQuotation: mainTemplateQuotation,
        additionalInfoTemplate,
        notFilteredMainTemplateQuotation,
        prevQuotationFormLoading: false, // Assuming loading is finished after updating the state
      });
    }
  };

  handleSave = (savedValues) => {
    const mainTemplateQuotation = _.cloneDeep(this.state.mainTemplateQuotation);
    let mainFound = 0;
    for (const key in mainTemplateQuotation) {
      const currentItem = mainTemplateQuotation[key];

      for (const key2 in currentItem) {
        if (currentItem[key2].Identifier === "GSTType") {
          mainFound = 1;
          currentItem[key2].value = savedValues;
        }
      }
    }
    if (mainFound != 1) {
      const additionalInfoTemplate = _.cloneDeep(
        this.state.additionalInfoTemplate
      );
      for (const key in additionalInfoTemplate) {
        if (additionalInfoTemplate[key].Identifier == "GSTType") {
          additionalInfoTemplate[key].value = savedValues;
        }
      }
      this.setState({ additionalInfoTemplate });
    } else {
      this.setState({ mainTemplateQuotation });
    }
  };

  handleCheckboxChange = (event, key) => {
    // Ensure that mainTemplateQuotation and lastTransactionTypeInThread exist before accessing their properties
    if (
      this.state.mainTemplateQuotation &&
      this.props.lastTransactionInThread &&
      this.state.mainTemplateQuotation[key] &&
      this.props.lastTransactionInThread[key]
    ) {
      const isNegotiation =
        this.props.lastTransactionTypeInThread === "Negotiation";
      const lastSelected =
        this.props.lastTransactionInThread[key].selected === "Y";
      const mainSelected =
        this.state.mainTemplateQuotation[key].selected === "Y";
      const is_deleted_from_master =
        this.props.items[key].is_deleted_from_master;
      const is_unlink_from_category =
        this.props.items[key].is_unlink_from_category;
      // Check if filteredSelectedNotData contains the key and its selected value is 'N'
      const filteredSelectedNotDataContainsKey = this.props.filteredSelectedNotData && this.props.filteredSelectedNotData[key] && this.props.filteredSelectedNotData[key].selected
      // Check if either is_deleted_from_master or is_unlink_from_category is true, if so, disable input fields
      if (is_deleted_from_master || is_unlink_from_category) {
        const updatedQuotation = {
          ...this.state.mainTemplateQuotation,
          [key]: {
            ...this.state.mainTemplateQuotation[key],
            selected: "N",
            readOnly: true, // Set readOnly to true to disable input fields
          },
        };
        this.setState({ mainTemplateQuotation: updatedQuotation });
      } else if (this.props.lastTransactionTypeInThread === "Inquiry") {
        if (!event.target.checked) {
          const updatedQuotationinitial = {
            ...this.state.mainTemplateQuotation,
            [key]: {
              ...this.state.intialMaintemplateQuotation[key],
              selected: event.target.checked ? "Y" : "N",
              readOnly: true, // Reset readOnly to false to enable input fields
            },
          };
          this.setState({ mainTemplateQuotation: updatedQuotationinitial });
        } else {
          const updatedQuotation = {
            ...this.state.mainTemplateQuotation,
            [key]: {
              ...this.state.mainTemplateQuotation[key],
              selected: event.target.checked ? "Y" : "N",
              readOnly: true, // Reset readOnly to false to enable input fields
            },
          };
          this.setState({ mainTemplateQuotation: updatedQuotation });
        }
      } else if (isNegotiation && lastSelected) {
        // Disable input fields if the transaction is negotiation and the last transaction selected the item
        const updatedQuotation = {
          ...this.state.mainTemplateQuotation,
          [key]: {
            ...this.state.mainTemplateQuotation[key],
            selected: "Y",
            readOnly: true, // Set readOnly to true to disable input fields
          },
        };

        this.setState({ mainTemplateQuotation: updatedQuotation });
      }
      else if (isNegotiation && !lastSelected && filteredSelectedNotDataContainsKey == 'N' && mainSelected) {
        const updatedQuotation = {
          ...this.state.mainTemplateQuotation,
          [key]: {
            ...this.state.mainTemplateQuotation[key],
            selected: event.target.checked ? "Y" : "N",
            readOnly: false, // Set readOnly to true to disable input fields
          },
        };

        this.setState({ mainTemplateQuotation: updatedQuotation });
      } else if (isNegotiation && !lastSelected && filteredSelectedNotDataContainsKey == 'N' && !mainSelected) {
        const updatedQuotation = {
          ...this.state.mainTemplateQuotation,
          [key]: {
            ...this.state.mainTemplateQuotation[key],
            selected: event.target.checked ? "Y" : "N",
            readOnly: false, // Set readOnly to true to disable input fields
          },
        };

        this.setState({ mainTemplateQuotation: updatedQuotation });
      }
      else if (isNegotiation && !lastSelected && filteredSelectedNotDataContainsKey !== 'N') {
        const updatedQuotation = {
          ...this.state.mainTemplateQuotation,
          [key]: {
            ...this.state.mainTemplateQuotation[key],
            selected: "Y",
            readOnly: true, // Set readOnly to true to disable input fields
          },
        };

        this.setState({ mainTemplateQuotation: updatedQuotation });
      }
      else if (mainSelected && !lastSelected) {
        // for issue TU2-I269
        if (!event.target.checked) {
          const updatedQuotationInitial = {
            ...this.state.mainTemplateQuotation,
            [key]: {
              ...this.state.intialMaintemplateQuotation[key],
              selected: event.target.checked ? "Y" : "N",
              readOnly: false, // Reset readOnly to false to enable input fields
            },
          };

          this.setState({ mainTemplateQuotation: updatedQuotationInitial });
        } else {
          const updatedQuotation = {
            ...this.state.mainTemplateQuotation,
            [key]: {
              ...this.state.mainTemplateQuotation[key],
              selected: event.target.checked ? "Y" : "N",
              readOnly: false, // Reset readOnly to false to enable input fields
            },
          };

          this.setState({ mainTemplateQuotation: updatedQuotation });
        }
      } else if (!mainSelected) {
        // Allow toggling between 'Y' and 'N' when mainSelected is 'N'
        const updatedQuotation = {
          ...this.state.mainTemplateQuotation,
          [key]: {
            ...this.state.mainTemplateQuotation[key],
            selected: event.target.checked ? "Y" : "N",
            readOnly: false, // Reset readOnly to false to enable input fields
          },
        };

        this.setState({ mainTemplateQuotation: updatedQuotation });
      }
      // else if (mainSelected) {
      //   console.log("mainSelected",mainSelected);
      //   console.log("enter 4");
      //   // Allow toggling between 'Y' and 'N' when mainSelected is 'N'
      //   const updatedQuotation = {
      //     ...this.state.mainTemplateQuotation,
      //     [key]: {
      //       ...this.state.mainTemplateQuotation[key],
      //       selected: event.target.checked ? "Y" : "N",
      //       readOnly: true, // Reset readOnly to false to enable input fields
      //     },
      //   };
      //   console.log("updatedQuotation4", updatedQuotation);
      //   this.setState({ mainTemplateQuotation: updatedQuotation });
      // }
      else {
        // Check if filteredSelectedNotData contains the key and its selected value is 'N'
        const filteredSelectedNotDataContainsKeyFlag =
          this.props.filteredSelectedNotData &&
          this.props.filteredSelectedNotData[key] &&
          this.props.filteredSelectedNotData[key].selected === "N";

        // If filteredSelectedNotData contains the key and its selected value is 'N', set selected to 'N'
        const selectedStatus = filteredSelectedNotDataContainsKeyFlag ? "N" : "Y";
        const updatedQuotation = {
          ...this.state.mainTemplateQuotation,
          [key]: {
            ...this.state.mainTemplateQuotation[key],
            selected: selectedStatus,
            readOnly: false, // Reset readOnly to false to enable input fields
          },
        };
        this.setState({ mainTemplateQuotation: updatedQuotation });
      }
    } else {
      if (
        this.state.mainTemplateQuotation[key].added_after_rfq_created == true
      ) {
        const updatedQuotation = {
          ...this.state.mainTemplateQuotation,
          [key]: {
            ...this.state.mainTemplateQuotation[key],
            selected: event.target.checked ? "Y" : "N",
            readOnly: false, // Reset readOnly to false to enable input fields
          },
        };
        this.setState({ mainTemplateQuotation: updatedQuotation });
      }
      console.error(
        `Template with key ${key} does not exist in either mainTemplateQuotation or lastTransactionInThread.`
      );
    }
  };

  handleQuotationCheckboxChange = (e) => {
    const { name, value, type, checked } = e.target;
    this.setState({ quotationChecked: checked });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    //handle multiplant on of condition
    let updateRFQDynamic = this.state.RFQDynamic;
    if (
      this.props.location?.pathname.indexOf("/prcombination") == -1 &&
      this.props.rfqSettingValues.allowMultiPlant !=
      nextProps.rfqSettingValues.allowMultiPlant
    ) {
      for (const key in updateRFQDynamic) {
        if (
          !nextProps.rfqSettingValues.allowMultiPlant &&
          updateRFQDynamic[key]["Identifier"] == "Destination"
        ) {
          updateRFQDynamic[key]["value"] = "";
          // updateRFQDynamic[key]["error"] = true;
        } else if (
          nextProps.rfqSettingValues.allowMultiPlant &&
          updateRFQDynamic[key]["Identifier"] == "Destination"
        ) {
          updateRFQDynamic[key]["value"] = [];
          // updateRFQDynamic[key]["error"] = true;
        }
      }
      this.setState({
        RFQDynamic: updateRFQDynamic,
      });
    }
    if (
      _.isEqual(nextProps.defaultValues, this.props.defaultValues) === false
    ) {
      let RFQStaticUpdate = this.state.RFQStatic;
      const { validityDate, priorityRfq, sealedGroupRfq, validityTime } =
        nextProps.defaultValues;
      if ((validityDate._d || validityDate) && validityDate !== null) {
        RFQStaticUpdate.RFQValidity.value = validityDate;
        RFQStaticUpdate.RFQValidityTime.value = validityTime;
        RFQStaticUpdate.TBValidity.value = validityDate;
        RFQStaticUpdate.TBValidityTime.value = validityTime;
      } else {
        RFQStaticUpdate.RFQValidity.value = "";
        RFQStaticUpdate.TBValidity.value = "";
      }
      RFQStaticUpdate.RFQValidity.error = false; //set rfqvalidity error false
      RFQStaticUpdate.lock = sealedGroupRfq;
      RFQStaticUpdate.star = priorityRfq;
      this.setState({
        RFQStatic: RFQStaticUpdate,
      });
    }
    if (nextProps.remiderDrawer?.startDate) {
      this.setState({
        startDate: nextProps.remiderDrawer?.startDate,
      });
    } else {
      this.setState({
        startDate: "",
      });
    }

    this.setState({
      startDateTB: nextProps.remiderDrawer?.includeReminderInterval,
    });

    if (nextProps.selectedItems?.length !== this.props.selectedItems?.length) {
      this.setState({
        errorArray: [],
      });
      if (nextProps.selectedItems.length > this.props.selectedItems.length) {
        //item added,
        let itemsToAdd = nextProps.selectedItems.filter(
          (newItem) => !this.props.selectedItems.includes(newItem)
        );

        for (let index = 0; index < itemsToAdd.length; index++) {
          const newItem = itemsToAdd[index];
          for (
            let j = 0;
            j < this.props.quotationJsonTemplate?.ITEMIZE.length;
            j++
          ) {
            const jsonRow = this.props.quotationJsonTemplate?.ITEMIZE[j];
            if (
              !this.state.usedAttributesId.includes(
                jsonRow[1]?.Child?.AttributeId
              )
            ) {
              // add item here and push it into the usedAttributesId
              this.state.usedAttributesId.push(jsonRow[1]?.Child?.AttributeId);
              this.setState(
                {
                  usedAttributesId: this.state.usedAttributesId,
                },
                () => {
                  let obj = {};
                  jsonRow.forEach((field) => {
                    if (this.state.applyToAll[`${field.FieldId}_applyToAll`]) {
                      this.setState({
                        applyToAll: {
                          ...this.state.applyToAll,
                          [`${field.FieldId}_applyToAll`]: false,
                        },
                      });
                    }
                    if (field.VisibilityFlag === "Y") {
                      if (
                        field.Identifier === "Product" ||
                        field.Identifier === "ProductForeign"
                      ) {
                        obj[field.FieldId] = {
                          HTMLAttributeName: field.HTMLAttributeName,
                          value: newItem.old_id,
                          Validation: "",
                          FieldLabel: field.FieldLabel,
                          error: false,
                          Identifier: field.Identifier,
                          ErrorMessage: "",
                        };
                        return;
                      }
                      if (field.Identifier === "ProductCode") {
                        obj[field.FieldId] = {
                          HTMLAttributeName: field.HTMLAttributeName,
                          value: newItem.code,
                          Validation: "",
                          FieldLabel: field.FieldLabel,
                          error: false,
                          Identifier: field.Identifier,
                          ErrorMessage: "",
                        };
                        return;
                      }
                      obj[field.FieldId] = {
                        HTMLAttributeName: field.HTMLAttributeName,
                        value: "",
                        Validation: field.Validation,
                        FieldLabel: field.FieldLabel,
                        error: false,
                        ErrorMessage: field.ErrorMessage,
                        Identifier: field.Identifier,
                        ElementType: field.ElementType,
                      };
                      if (
                        field.Identifier === "EstimatedCost" ||
                        field.Identifier === "EstimatedCostForeign" ||
                        field.Identifier === "Budgetedrate" ||
                        field.Identifier === "BudgetedrateForeign"
                      ) {
                        obj[field.FieldId][
                          `upd_budget_master_${newItem.id}`
                        ] = false;

                        if (
                          this.props.featurePermission
                            ?.Fetch_Budget_Value_in_RFQ_Form_from_Item_Master
                            ?.feature_value === "Y" &&
                          newItem?.budgeted_rate
                        ) {
                          if (parseFloat(newItem?.budgeted_rate)) {
                            obj[field.FieldId]["value"] = newItem.budgeted_rate;
                            obj["rate"] = newItem.budgeted_rate;
                          } else {
                            obj["rate"] = "";
                          }
                        } else {
                          obj["rate"] = "";
                        }
                      }
                      if (field.ElementType === "S") {
                        if (field.DropdownEdit === "Y") {
                          obj[field.FieldId]["options"] = "loading";
                          obj[field.FieldId]["options"] =
                            this.getItemizeOptions(field);
                        } else {
                          obj[field.FieldId]["options"] = field.DefaultValue;
                        }
                      }
                      if (
                        field.Identifier === "BasicHistoricalPrice" ||
                        field.Identifier === "LandedHistoricalPrice" ||
                        field.Identifier === "DateHP"
                      ) {
                        obj[field.FieldId]["DynamicDefaultValue"] = "loading";
                        this.getDynamicDefaultValues(
                          field,
                          index,
                          field.DefaultValue
                        );
                      }
                    }
                    if (field.Identifier === "EstimatedValue") {
                      obj[field.FieldId] = {
                        HTMLAttributeName: field.HTMLAttributeName,
                        value: "",
                        Validation: field.Validation,
                        FieldLabel: field.FieldLabel,
                        error: false,
                        ErrorMessage: field.ErrorMessage,
                        Identifier: field.Identifier,
                      };
                    }
                    if (
                      Object.keys(this.props.prlineitems).length &&
                      prconfig &&
                      prconfig[field.Identifier] !== undefined
                    ) {
                      let readOnly = "";
                      let value =
                        eval(this[prconfig[field.Identifier].function_name])(
                          field.Identifier,
                          newItem
                        ) || "";

                      if (
                        prconfig[field.Identifier]?.non_editable_function &&
                        prconfig[field.Identifier]?.non_editable_function !==
                        "0"
                      ) {
                        if (
                          prconfig[field.Identifier]?.non_editable_function ==
                          "1"
                        ) {
                          readOnly = true;
                        } else {
                          readOnly = eval(
                            this[
                            prconfig[field.Identifier]?.non_editable_function
                            ]
                          )(value);
                        }
                      }
                      obj[field.FieldId] = {
                        HTMLAttributeName: field.HTMLAttributeName,
                        value: value,
                        Validation: field.Validation,
                        FieldLabel: field.FieldLabel,
                        error: false,
                        ErrorMessage: field.ErrorMessage,
                        Identifier: field.Identifier,
                        ElementType: field.ElementType,
                        readOnly: readOnly,
                      };
                    }
                  });
                  obj.specification = "";
                  obj.qty = "";
                  if (!obj.hasOwnProperty("slab")) {
                    obj["slab"] = [
                      { id: 1, start: "1", end: "", error: false },
                      { id: 2, start: "More Than", end: "", error: false },
                    ];
                  }
                  this.setState((prevState) => ({
                    mainTemplateQuotation: {
                      ...prevState.mainTemplateQuotation,
                      [newItem.id]: obj,
                    },
                  }));
                  if (this.props.rfqSettingValues.includeSlab) {
                    this.setState((prevState) => ({
                      slab: {
                        ...prevState.slab,
                        [newItem.id]: { isOpen: true },
                      },
                    }));
                  } else {
                    this.setState((prevState) => ({
                      slab: {
                        ...prevState.slab,
                        [newItem.id]: { isOpen: false },
                      },
                    }));
                  }
                  if (this.state.applyToAllSlab == true) {
                    this.setState({
                      applyToAllSlab: false,
                    });
                  }
                }
              );
              break;
            }
          }
        }
      } else if (
        nextProps.selectedItems.length < this.props.selectedItems.length
      ) {
        //item removed,
        let itemsToRemove = this.props.selectedItems.filter(
          (newItem) => !nextProps.selectedItems.includes(newItem)
        );
        itemsToRemove.forEach((item) => {
          let index = this.state.usedAttributesId?.indexOf(
            this.state.mainTemplateQuotation[item.id][
              Object.keys(this.state.mainTemplateQuotation[item.id])[0]
            ]?.HTMLAttributeName?.split("_")[1]
          );
          this.state.usedAttributesId.splice(index, 1);
          this.setState({
            usedAttributesId: this.state.usedAttributesId,
          });
          delete this.state.mainTemplateQuotation[item.id];
          delete this.state.slab[item.id];
        });
        this.setState({
          mainTemplateQuotation: this.state.mainTemplateQuotation,
          slab: this.state.slab,
        });
      }
    }
    if (
      nextProps.selectedVendors?.length !== this.props.selectedVendors?.length
    ) {
      setTimeout(() => {
        for (const key in this.state.additionalInfoTemplate) {
          const element = this.state.additionalInfoTemplate[key];
          if (element.value) {
            if (document.getElementsByName(element.HTMLAttributeName)[0]) {
              document.getElementsByName(element.HTMLAttributeName)[0].value =
                element.value;
            }
          }
        }
        for (const key in this.state.IntlTemplate) {
          const element = this.state.IntlTemplate[key];
          if (element.value) {
            if (document.getElementsByName(element.HTMLAttributeName)[0]) {
              document.getElementsByName(element.HTMLAttributeName)[0].value =
                element.value;
            }
          }
        }
      });
    }
    //slab remove if not include slab
    // if(this.props.rfqSettingValues.includeSlab !=nextProps.rfqSettingValues.includeSlab){
    if (
      this.props.rfqSettingValues.includeSlab !=
      nextProps.rfqSettingValues.includeSlab &&
      this.props.location.pathname.indexOf("/prcombination") == -1
    ) {
      if (!nextProps.rfqSettingValues.includeSlab) {
        this.props.selectedItems.forEach((item) => {
          this.setState((prevState) => ({
            ...prevState,
            mainTemplateQuotation: {
              ...prevState.mainTemplateQuotation,
              [item.id]: {
                ...prevState.mainTemplateQuotation[item.id],
                slab: [
                  { id: 1, start: 1, end: "", error: false },
                  { id: 2, start: "More Than", end: "", error: false },
                ],
              },
            },
          }));
          this.setState((prevState) => ({
            ...prevState,
            slab: {
              ...prevState.slab,
              [item.id]: {
                ...prevState.slab[item.id],
                isOpen: false,
              },
            },
          }));
        });
      } else {
        this.props.selectedItems.forEach((item) => {
          this.setState((prevState) => ({
            ...prevState,
            slab: {
              ...prevState.slab,
              [item.id]: {
                ...prevState.slab[item.id],
                isOpen: true,
              },
            },
          }));
        });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if the quotationInitializeFlag has changed
    if (
      this.state.quotationInitializeFlag !== prevState.quotationInitializeFlag
    ) {
      // If it has changed, conditionally initialize the state
      if (this.state.quotationInitializeFlag) {
        this.initializeState();
      }
    }
    if (
      Object.keys(this.props.quotationExcelData).length > 0 &&
      !this.state.quotationImported
    ) {
      // set currecny
      if (
        this.props?.vendorType === "International" ||
        this.props?.vendorType === "Local Importer"
      ) {
        let currencyElement = this.state.allItemsTemplate.find((item) => {
          return item.Identifier === "Currency";
        });
        if (
          this.state.selectedCurrency !=
          this.props.quotationExcelData.result[
          currencyElement?.HTMLAttributeName
          ]
        ) {
          this.setState({
            selectedCurrency:
              this.props.quotationExcelData.result[
                currencyElement.HTMLAttributeName
              ] == "Select"
                ? ""
                : this.props.quotationExcelData.result[
                currencyElement.HTMLAttributeName
                ],
          });
        }
      }

      let mainTemplateQuotation = _.cloneDeep(this.state.mainTemplateQuotation);
      let updated = false;
      let updatedAdditionalInfo = false;
      if (
        this.state.industryType !=
        this.props.quotationExcelData.result.industry_type
      ) {
        this.setState({
          industryType: this.props.quotationExcelData.result.industry_type,
        });
      }
      if (
        this.state.msmeNumber !=
        this.props.quotationExcelData.result.industry_type_value
      ) {
        this.setState({
          msmeNumber: this.props.quotationExcelData.result.industry_type_value,
        });
      }

      for (const key in mainTemplateQuotation) {
        const currentItem = mainTemplateQuotation[key];

        for (const key2 in currentItem) {
          if (
            this.props.quotationExcelData.result.hasOwnProperty(
              currentItem[key2]["HTMLAttributeName"]
            )
          ) {
            const newValue =
              this.props.quotationExcelData.result[
              currentItem[key2]["HTMLAttributeName"]
              ];

            if (currentItem[key2]["value"] !== newValue) {

              if (currentItem[key2]["ElementType"] === "S") {
                currentItem[key2]["value"] = newValue == "Select" ? "" : newValue;
              } else {
                currentItem[key2]["value"] = !isNaN(newValue) && newValue != ""
                  ? parseFloat(newValue)
                  : newValue;
              }
              if (
                currentItem[key2]["Identifier"] === "Budgetedrate" ||
                currentItem[key2]["Identifier"] === "CIFRate" ||
                currentItem[key2]["Identifier"] === "rate" ||
                currentItem[key2]["Identifier"] === "Rate"
              ) {
                currentItem["rate"] = newValue;
              }
              updated = true;
            }
          } else if (
            this.props.quotationExcelData.result.hasOwnProperty(
              `datepicker${currentItem[key2]["HTMLAttributeName"]}`
            )
          ) {
            const newValue =
              moment(this.props.quotationExcelData.result[
                `datepicker${currentItem[key2]["HTMLAttributeName"]}`
              ], 'DD/MM/YYYY').format('YYYY-MM-DD')

            // console.log("newValue", newValue, currentItem);
            if (currentItem[key2]["value"] !== newValue) {
              currentItem[key2]["value"] = newValue;
              updated = true;
            }
          }
        }
      }

      let additionalInfoTemplate = _.cloneDeep(
        this.state.additionalInfoTemplate
      );

      Object.entries(additionalInfoTemplate).forEach(([key, currentField]) => {
        if (
          this.props.quotationExcelData.result[
          currentField["HTMLAttributeName"]
          ]
        ) {
          // if (
          //   currentField["ElementType"] == "DT" &&
          //   (currentField["Identifier"] == "ContractEnd" ||
          //     currentField["Identifier"] == "ContractTo")
          // )

          if (currentField["ElementType"] == "DT") {
            const newValueAdditional =
              this.props.quotationExcelData.result[
              currentField["HTMLAttributeName"]
              ];

            currentField["value"] = newValueAdditional
              .split("/")
              .reverse()
              .join("-");
            updatedAdditionalInfo = true;
          } else if (currentField["ElementType"] == "S") {

            let addionalSvalue = this.props.quotationExcelData.result[
              currentField["HTMLAttributeName"]
            ]
            if (currentField?.options?.length > 0 && currentField?.options[0].hasOwnProperty['name']) {
              let dropdownName = currentField?.options.filter(option => {
                return option.name === addionalSvalue
              })
              if (dropdownName.length > 0) {
                addionalSvalue = dropdownName[0]?.code
              }
            }
            if (currentField["value"] !== addionalSvalue) {
              currentField["value"] =
                addionalSvalue === "Select" ? "" : addionalSvalue;
              updatedAdditionalInfo = true;
            }

          } else {
            const newValueAdditional =
              this.props.quotationExcelData.result[
              currentField["HTMLAttributeName"]
              ];
            if (currentField["value"] !== newValueAdditional) {
              currentField["value"] =
                newValueAdditional === "Select" ? "" : newValueAdditional;
              updatedAdditionalInfo = true;
            }
          }
        }
      });
      if (updated) {
        this.setState({
          mainTemplateQuotation,
          quotationImported: true,
        });
      }
      if (updatedAdditionalInfo) {
        this.setState({
          additionalInfoTemplate,
          quotationImported: true,
        });
      }
    }
    if (this.state.mainTemplateQuotation || this.state.additionalInfoTemplate) {
      let EvalResults = {};

      for (const key in this.state.mainTemplateQuotation) {
        this.state.rearrangedArray.forEach((defaultIdentifier) => {
          let formula = defaultIdentifier.DefaultValue.Formula;
          let newFormula = this.arrangedEvalByKey(
            formula,
            key,
            EvalResults[key],
            defaultIdentifier.Identifier
          );

          if (
            newFormula &&
            newFormula.includes("ExchangeRate_SelectedCurrency")
          ) {
            let exchangeRate = this.props.currencyOptions.find(
              (currency) =>
                currency.Currency ===
                (this.state.selectedCurrency || this.props.quotedCurrency)
            );
            if (exchangeRate) {
              newFormula = newFormula.replace(
                /ExchangeRate_SelectedCurrency/g,
                exchangeRate.ExchangeRate
              );
            }
          }

          if (
            newFormula &&
            newFormula.includes("ExchangeRate_BuyerLocalCurrency")
          ) {
            let exchangeRate = this.props.currencyOptions.find(
              (currency) =>
                currency.Currency === (this.props.currencyDetails || "INR")
            );
            if (exchangeRate) {
              newFormula = newFormula.replace(
                /ExchangeRate_BuyerLocalCurrency/g,
                exchangeRate.ExchangeRate
              );
            }
          }

          // Now set the property
          if (newFormula && newFormula.includes("(f")) {
            const match = newFormula.match(/f\d+/);
            if (match) {
              newFormula = newFormula.replace(match[0], 0);
            }
          }

          if (!EvalResults[key]) {
            EvalResults[key] = {};
          }

          try {
            const evaluatedValue = new Function(`return ${newFormula}`)();
            EvalResults[key][defaultIdentifier.FieldId] =
              parseFloat(evaluatedValue).toFixed(3);
          } catch (error) {
            console.error("Error evaluating formula:", newFormula, error);
            EvalResults[key][defaultIdentifier.FieldId] = "NaN";
          }
        });
      }

      // Only update the state if EvalResults have changed
      if (
        JSON.stringify(this.state.EvalResults) !== JSON.stringify(EvalResults)
      ) {
        this.setState({ EvalResults });
      }
    }
  }

  toggleLoaderArray = (arg) => {
    let temp = this.state.loaderArray;
    let index = temp.indexOf(arg);
    if (index > -1) {
      temp.splice(index, 1);
    } else {
      temp.push(arg);
    }
    this.setState({
      loaderArray: temp,
    });
  };

  SUM = (field, item) => {
    let sum = 0;
    this.props.prlineitems[item.id].forEach((prlineitem, i) => {
      let expression_val = 0;
      try {
        expression_val = eval(
          "prlineitem" + "." + prconfig[field].response_key
        );
      } catch (e) { }
      sum += Number(expression_val);
    });
    return sum;
  };

  // LATEST_Plant = (field,item) => {
  //   let plant_code = "";
  //   plant_code = this.LATEST(field,item);
  //   let plant_name = this.props.pr_dropdown_values.plant[plant_code]['Dropdown_Element_Name'];
  //   let value = `${plant_code}-${plant_name}`;
  //   if( this.props.companyInfo?.default_dropdown_type === "10"  ){
  //     value = `${plant_name}-${plant_code}`;
  //   }
  //   return value;
  // };

  PRLongText = (field, item) => {
    let pritems = this.props.prlineitems[item.id];
    //let replacedStr= pritems[pritems.length-1][prconfig[field].response_key].replace(/\^/,"\n");
    return pritems[pritems?.length - 1][prconfig[field].response_key];
  };

  COMMA = (field, item) => {
    let str = "";
    this.props.prlineitems[item.id].forEach((prlineitem, i) => {
      let expression_val = "";
      try {
        expression_val = eval(
          "prlineitem" + "." + prconfig[field].response_key
        );
      } catch (e) { }
      str += expression_val + ",";
    });
    return str.slice(0, -1);
  };

  COMMA_WITH_SPACE = (field, item) => {
    let str = "";
    this.props.prlineitems[item.id].forEach((prlineitem, i) => {
      let expression_val = "";
      try {
        expression_val = eval(
          "prlineitem" + "." + prconfig[field].response_key
        );
      } catch (e) { }
      str += expression_val + ", ";
    });
    return str.slice(0, -2);
  };

  EstimatedCost = (field, item) => {
    let value = item.estimated_cost;
    if (
      this.props.featurePermission
        ?.Fetch_Budget_Value_in_RFQ_Form_from_Item_Master?.feature_value === "N"
    ) {
      value = this.LATEST(field, item);
    }
    return value;
  };

  MIN = (field, item) => {
    let value = 0;
    this.props.prlineitems[item.id].forEach((prlineitem, i) => {
      let expression_val = 0;
      try {
        expression_val = eval(
          "prlineitem" + "." + prconfig[field].response_key
        );
      } catch (e) { }

      if (expression_val < value) {
        value = expression_val;
      }
    });

    return value;
  };

  MIN_DATE = (field, item) => {
    let value = 0;

    this.props.prlineitems[item.id].forEach((prlineitem, i) => {
      let expression_val = 0;
      try {
        expression_val = eval(
          "prlineitem" + "." + prconfig[field].response_key
        );
        if (!value || moment(value).isAfter(moment(expression_val))) {
          value = expression_val;
        }
      } catch (e) { }

      if (expression_val < value) {
        value = expression_val;
      }
    });

    return value;
  };

  LATEST_Remove_Circumflex = (field, item) => {
    let value = this.LATEST(field, item);
    return value ? value.replace(/\^\^\^/g, "\n") : "";
  };

  PRLongText = (field, item) => {
    let pritems = this.props.prlineitems[item.id];
    return (
      this.LATEST_Remove_Circumflex(field, item) ||
      pritems[pritems.length - 1]["material_short_text"] ||
      ""
    );
  };

  DeliverySchedule = (field, item) => {
    let pritems = this.props.prlineitems[item.id];
    let pr_delivery_schedule = "";
    pritems.forEach(function (item, index) {
      let quantity = item.quantity;
      let delivery_date = item.delivery_date;
      let date_arr = delivery_date.split("-");
      date_arr = date_arr.reverse();
      //delivery_date = delivery_date.replace(/-/g, "\/");
      delivery_date = date_arr.join("-");
      let line_item = item.pr_line_item;
      let pr_number = item.pr.number;
      pr_delivery_schedule +=
        delivery_date +
        "," +
        quantity +
        "," +
        pr_number +
        "(" +
        line_item +
        ");";
    });
    pr_delivery_schedule = pr_delivery_schedule.slice(0, -1);
    return pr_delivery_schedule;
  };

  PRNoLineItem = (field, item) => {
    let str = "";
    this.props.prlineitems[item.id].forEach((prlineitem, i) => {
      str += prlineitem.pr.number + "(" + prlineitem.pr_line_item + "), ";
    });
    return str.slice(0, -2);
  };

  ReqDeliveryDate = (field, item) => { };

  LATEST = (field, item) => {
    let pritems = this.props.prlineitems[item.id];
    //return pritems[pritems?.length-1][prconfig[field].response_key] || '';
    let pr_line_item_object = pritems[pritems.length - 1];
    let expression = "pr_line_item_object" + "." + prconfig[field].response_key;
    let result = "";
    try {
      result = eval(expression);
    } catch (e) { }

    return result;
  };

  LATEST_PO_DATE = (field, item) => {
    let value = "";
    value = this.LATEST(field, item);
    if (value) {
      let pattern = /\b(\d{4})(\d{2})(\d{2})\b/;
      let result = value.match(pattern);
      if (result) {
        result.shift();
        value = result.join("-");
      }
    }
    return value;
  };

  QTY_Check = (qty) => {
    let $moq_permission =
      this.props.accountModulePermission[
        "Allow more Quantity in PR Qty Distribution"
      ]?.module_activated;
    let $service_rfq_permission =
      this.props.accountModulePermission["Service PR"]?.module_activated;
    let readOnly = true;
    if ($moq_permission === "Y" || $service_rfq_permission === "Y") {
      readOnly = false;
    }
    return readOnly;
  };

  COMPANY_CODE_Check = (company_code) => {
    return company_code ? true : false;
  };

  initializeState = async () => {
    let initialSlab = {};
    let finalState = {};
    if (this.props.quotationJsonTemplate?.ITEMIZE?.length) {
      for (
        let index = 0;
        index < Object.keys(this.props.selectedQuotedItems.items).length;
        index++
      ) {
        let obj = {};
        this.state.usedAttributesId.push(
          this.props.quotationJsonTemplate?.ITEMIZE[index][1]?.Child
            ?.AttributeId
        );
        this.setState({
          usedAttributesId: this.state.usedAttributesId,
        });
        for (
          let fieldIndex = 0;
          fieldIndex < this.props.quotationJsonTemplate?.ITEMIZE[index].length;
          fieldIndex++
        ) {
          const row =
            this.props.quotationJsonTemplate?.ITEMIZE[index][fieldIndex];
          if (row.VisibilityFlag === "Y") {
            if (
              row.Identifier === "Product" ||
              row.Identifier === "ProductForeign"
            ) {
              obj[row.FieldId] = {
                HTMLAttributeName: row.HTMLAttributeName,
                value: Object.keys(this.props.selectedQuotedItems.items)[index],
                Validation: "",
                FieldLabel: row.FieldLabel,
                error: false,
                Identifier: row.Identifier,
                ErrorMessage: "",
                DefaultFormula: row.DefaultFormula,
                DefaultValue: row.DefaultValue,
                CurrencyFormula: row.CurrencyFormula,
                UnitsFlag: row.UnitsFlag,
                InstructionText: row.InstructionText,
              };
              continue;
            }
            if (row.Identifier === "ProductCode") {
              obj[row.FieldId] = {
                HTMLAttributeName: row.HTMLAttributeName,
                value: Object.values(this.props.selectedQuotedItems.items)[
                  index
                ].code,
                Validation: "",
                FieldLabel: row.FieldLabel,
                error: false,
                Identifier: row.Identifier,
                ErrorMessage: "",
                DefaultFormula: row.DefaultFormula,
                DefaultValue: row.DefaultValue,
                CurrencyFormula: row.CurrencyFormula,
                UnitsFlag: row.UnitsFlag,
                InstructionText: row.InstructionText,
              };
              continue;
            }

            let value = "";
            if (row.Identifier === "MasterSpecification") {
              value =
                this.props.selectedQuotedItems.items[index]?.specifications;
            } else if (row.Identifier === "MasterHSNCode") {
              value =
                this.props.selectedQuotedItems.items[index]?.hsn_code || "";
            }

            let readOnly = false;
            if (
              Object.keys(this.props.prlineitems).length &&
              prconfig &&
              prconfig[row.Identifier] !== undefined
            ) {
              value =
                eval(this[prconfig[row.Identifier].function_name])(
                  row.Identifier,
                  this.props.items.selectedQuotedItems[index]
                ) || "";
              if (
                prconfig[row.Identifier]?.non_editable_function &&
                prconfig[row.Identifier]?.non_editable_function !== "0"
              ) {
                if (prconfig[row.Identifier]?.non_editable_function == "1") {
                  readOnly = true;
                } else {
                  readOnly = eval(
                    this[prconfig[row.Identifier]?.non_editable_function]
                  )(value);
                }
              }
            }
            obj[row.FieldId] = {
              HTMLAttributeName: row.HTMLAttributeName,
              value: value,
              Validation: row.Validation,
              FieldLabel: row.FieldLabel,
              error: false,
              ErrorMessage: row.ErrorMessage,
              Identifier: row.Identifier,
              ElementType: row.ElementType,
              readOnly: readOnly,
              DefaultFormula: row.DefaultFormula,
              DefaultValue: row.DefaultValue,
              CurrencyFormula: row.CurrencyFormula,
              UnitsFlag: row.UnitsFlag,
              InstructionText: row.InstructionText,
            };
            if (
              row.Identifier === "EstimatedCost" ||
              row.Identifier === "EstimatedCostForeign" ||
              row.Identifier === "Budgetedrate" ||
              row.Identifier === "BudgetedrateForeign"
            ) {
              obj[row.FieldId][
                `upd_budget_master_${this.props.selectedQuotedItems.items[index].id}`
              ] = false;

              if (
                this.props.featurePermission
                  ?.Fetch_Budget_Value_in_RFQ_Form_from_Item_Master
                  ?.feature_value === "Y" &&
                this.props.selectedQuotedItems.items[index]?.budgeted_rate
              ) {
                if (
                  parseFloat(
                    this.props.selectedQuotedItems.items[index]?.budgeted_rate
                  )
                ) {
                  obj[row.FieldId]["value"] =
                    this.props.selectedQuotedItems.items[index].budgeted_rate;
                  obj["rate"] =
                    this.props.selectedQuotedItems.items[index].budgeted_rate;
                } else {
                  obj["rate"] = "";
                }
              } else {
                obj["rate"] = value;
              }
            }

            if (row.ElementType === "S") {
              if (row.DropdownEdit === "Y") {
                obj[row.FieldId]["options"] = [];
                //getting options data from redux if it is available else calling api
                let optionsData = this.getOptionsFromRedux(row, "itemize");
                if (optionsData) {
                  obj[row.FieldId]["options"] = optionsData;
                  this.setState({
                    commonOptions: {
                      ...this.state.commonOptions,
                      [row.Identifier]: optionsData,
                    },
                  });
                } else {
                  obj[row.FieldId]["options"] = await this.getItemizeOptions(
                    row,
                    index
                  );
                }
              } else {
                obj[row.FieldId]["options"] = row.DefaultValue;
              }
            }
            if (
              row.Identifier === "BasicHistoricalPrice" ||
              row.Identifier === "LandedHistoricalPrice" ||
              row.Identifier === "DateHP"
            ) {
              obj[row.FieldId]["DynamicDefaultValue"] = "loading";
              this.getDynamicDefaultValues(row, index, row.DefaultValue);
            }
          }
          if (row.Identifier === "EstimatedValue") {
            obj[row.FieldId] = {
              HTMLAttributeName: row.HTMLAttributeName,
              value: "",
              Validation: row.Validation,
              FieldLabel: row.FieldLabel,
              error: false,
              ErrorMessage: row.ErrorMessage,
              Identifier: row.Identifier,
              DefaultFormula: row.DefaultFormula,
              DefaultValue: row.DefaultValue,
              CurrencyFormula: row.CurrencyFormula,
              UnitsFlag: row.UnitsFlag,
              InstructionText: row.InstructionText,
            };
          }
          // if ( row.Identifier === "ProductQuantity") obj.qty =  obj[row.FieldId].value
          if (row.Identifier === "ProductQuantity" && obj[row.FieldId])
            obj.qty = obj[row.FieldId].value;
        }
        obj.specification = "";
        const itemize = this.props.lastTransactionData.itemize;
        // const keys = Object.keys(itemize);
        // const key = keys[index]; // Get the key at the specified index
        const key = this.props.transactionItemSequence[index]; // Get the key at the specified index
        obj.added_after_rfq_created =
          this.props?.items[key]?.added_after_rfq_created;
        obj.is_deleted_from_master =
          this.props?.items[key]?.is_deleted_from_master;
        obj.is_unlink_from_category =
          this.props?.items[key]?.is_unlink_from_category;
        obj.selected =
          this.props?.items[key]?.is_deleted_from_master ||
            this.props?.items[key]?.is_unlink_from_category
            ? "N"
            : itemize[key]?.selected;
        // obj.formdetailRowId =
        //   this?.props?.quotationJsonTemplate?.ITEMIZE[
        //     index
        //   ][1]?.Child?.AttributeId;
        // console.log("items=", this.props.items);
        for (
          let i = 0;
          i < this?.props?.quotationJsonTemplate.ITEMIZE.length;
          i++
        ) {
          // if (
          //   this?.props?.quotationJsonTemplate?.ITEMIZE[i][1]?.Child
          //     ?.AttributeName ==
          //   this.props.lastTransactionData.itemize[key]?.attributeName
          // ) {

          if (
            this?.props?.quotationJsonTemplate?.ITEMIZE[i][1]?.Child
              ?.AttributeName == this.props.items[key]?.attributeName
          ) {
            obj.formdetailRowId =
              this?.props?.quotationJsonTemplate?.ITEMIZE[
                i
              ][1]?.Child?.AttributeId;

            // console.log(
            //   obj.formdetailRowId,
            //   "=",
            //   AttributeName,
            //   "=",
            //   this.props.lastTransactionData.itemize[key]
            // );
            break;
          }
        }

        // console.log("obj=", obj);
        // obj.itemBoxId = itemize[key]?.itemBoxId;

        if (!obj.hasOwnProperty("qty")) {
          obj.qty = "";
        }
        if (!obj.hasOwnProperty("slab")) {
          obj["slab"] = [
            { id: 1, start: "1", end: "", error: false },
            { id: 2, start: "More Than", end: "", error: false },
          ];
        }
        // finalState[Object.keys(this.props.selectedQuotedItems.items)[index]] = obj;
        finalState[this.props.transactionItemSequence[index]] = obj;
        if (this.props.rfqSettingValues.includeSlab) {
          initialSlab[
            // Object.keys(this.props.selectedQuotedItems.items)[index]
            this.props.transactionItemSequence[index]
          ] = {
            isOpen: true,
          };
        } else {
          initialSlab[
            // Object.keys(this.props.selectedQuotedItems.items)[index]
            this.props.transactionItemSequence[index]
          ] = {
            isOpen: false,
          };
        }
      }
      this.setState(
        {
          mainTemplateQuotation: finalState,
          slab: initialSlab,
        },
        () => {
          if (
            this.props.featurePermission?.prev_tran_value?.feature_value ===
            "Y" &&
            this.props.prplants.length === 0
          ) {
            this.fetchPrevious();
          } else {
            this.fetchPrevious();
          }
        }
      );
    }
  };

  initializeLocalRecipientsState = () => {
    let row = {};
    const combinedTemplate = this.props.quotationJsonTemplate?.COMMON.concat(
      this.props.quotationJsonTemplate?.LOCAL
    );
    combinedTemplate.forEach((cell) => {
      row[cell.FieldId] = {
        HTMLAttributeName: cell.HTMLAttributeName,
        value: "",
        Validation: cell.Validation,
        FieldLabel: cell.FieldLabel,
        error: false,
        ErrorMessage: cell.ErrorMessage,
        Identifier: cell.Identifier,
        ElementType: cell.ElementType,
        DefaultFormula: cell.DefaultFormula,
        DefaultValue: cell.DefaultValue,
        VisibilityFlag: cell.VisibilityFlag,
      };
      if (cell.ElementType === "S") {
        if (cell.DropdownEdit === "Y") {
          row[cell.FieldId]["options"] = "loading";
          //getting options data from redux if it is available else calling api
          let optionsData = this.getOptionsFromRedux(cell, "additionalInfo");
          if (optionsData) {
            row[cell.FieldId]["options"] = optionsData;
          } else {
            this.getOptionsFromApi(cell, "additionalInfo");
          }
        } else {
          row[cell.FieldId]["options"] = cell.DefaultValue;
        }
      } else {
        // row[cell.FieldId]["value"] = cell.DefaultValue || "";
        row[cell.FieldId]["value"] = "";
      }
    });
    // this.setState({
    //   additionalInfoTemplate: row,
    // });
    return row;
  };

  initializeIntlRecipientsState = () => {
    let row = {};

    const combinedTemplate = this.props.quotationJsonTemplate?.COMMON.concat(
      this.props.quotationJsonTemplate?.FOREIGN
    );

    combinedTemplate.forEach((cell) => {
      row[cell.FieldId] = {
        HTMLAttributeName: cell.HTMLAttributeName,
        value: "",
        Validation: cell.Validation,
        FieldLabel: cell.FieldLabel,
        error: false,
        ErrorMessage: cell.ErrorMessage,
        Identifier: cell.Identifier,
        ElementType: cell.ElementType,
        DefaultFormula: cell.DefaultFormula,
        DefaultValue: cell.DefaultValue,
        VisibilityFlag: cell.VisibilityFlag,
      };
      if (cell.ElementType === "S") {
        if (cell.DropdownEdit === "Y") {
          row[cell.FieldId]["options"] = "loading";
          //getting options data from redux if it is available else calling api
          let optionsData = this.getOptionsFromRedux(cell, "additionalInfo");
          if (optionsData) {
            row[cell.FieldId]["options"] = optionsData;
          } else {
            this.getOptionsFromApi(cell, "additionalInfo");
          }
        } else {
          row[cell.FieldId]["options"] = cell.DefaultValue || "";
        }
      }
    });
    this.setState({
      additionalInfoTemplate: row,
    });
    return row;
  };

  getItemizeOptions = async (cell, index) => {
    let templateId = this.props.quotationJsonTemplate?.PARAM[0]?.template_id;
    if (index !== 0) {
      return this.state.commonOptions[cell.Identifier];
    }
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.newSessionID}`;
    const url = getDropdownOptions(
      cell.Identifier,
      this.props.quotationJsonTemplate?.PARAM[0]?.template_id
    );
    this.toggleLoaderArray(
      index ? `${cell.Identifier}_${index}` : cell.Identifier
    );
    try {
      // setIsLoading(true);
      const res = await axios.get(url);
      this.toggleLoaderArray(
        index ? `${cell.Identifier}_${index}` : cell.Identifier
      );
      if (res.data.dropdown_mapping_status !== false) {
        this.setState({
          commonOptions: {
            ...this.state.commonOptions,
            [cell.Identifier]: res.data.data,
          },
        });
        //setting itemize data into redux
        this.setOptionsInRedux(
          templateId,
          cell.Identifier,
          res.data.data,
          "itemize"
        );
        return res.data.data;
      } else {
        this.setState({
          commonOptions: {
            ...this.state.commonOptions,
            [cell.Identifier]: cell.DefaultValue,
          },
        });
        this.setOptionsInRedux(
          templateId,
          cell.Identifier,
          cell.DefaultValue,
          "itemize"
        );
        return cell.DefaultValue;
      }
    } catch (err) {
      console.log(err, "options error");
      this.toggleLoaderArray(
        index ? `${cell.Identifier}_${index}` : cell.Identifier
      );
      return [];
    }
  };

  //setting options data into redux

  getOptionsFromApi = async (cell, template) => {
    try {
      let templateId = this.props.quotationJsonTemplate?.PARAM[0]?.template_id;
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.sessionId}`;
      const url = getDropdownOptions(
        cell.Identifier,
        this.props.quotationJsonTemplate?.PARAM[0]?.template_id
      );
      this.toggleLoaderArray(cell.Identifier);
      const res = await axios.get(url);
      if (template === "additionalInfo") {
        if (res.data.dropdown_mapping_status !== false) {
          this.setState((prevState) => ({
            ...prevState,
            additionalInfoTemplate: {
              ...prevState.additionalInfoTemplate,
              [cell.FieldId]: {
                ...prevState.additionalInfoTemplate[cell.FieldId],
                options: res.data?.data,
              },
            },
          }));
          this.setOptionsInRedux(
            templateId,
            cell.Identifier,
            res.data.data,
            template
          );
        } else {
          this.setState((prevState) => ({
            ...prevState,
            additionalInfoTemplate: {
              ...prevState.additionalInfoTemplate,
              [cell.FieldId]: {
                ...prevState.additionalInfoTemplate[cell.FieldId],
                options: cell.DefaultValue,
              },
            },
          }));
        }
      }
      this.toggleLoaderArray(cell.Identifier);
    } catch (error) {
      console.log(error, "options error");
      if (template === "additionalInfo") {
        this.setState((prevState) => ({
          ...prevState,
          additionalInfoTemplate: {
            ...prevState.additionalInfoTemplate,
            [cell.FieldId]: {
              ...prevState.additionalInfoTemplate[cell.FieldId],
              options: [],
            },
          },
        }));
      }
      this.toggleLoaderArray(cell.Identifier);
    }
  };

  updateAdditionalInfoFromCurrencyOptions = (cell, template) => {
    let templateId = this.props.quotationJsonTemplate?.PARAM[0]?.template_id;
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;
    const url = "http://192.168.1.161:8888/v1/dropdowns-currency-list";
    this.toggleLoaderArray(cell.Identifier);

    axios
      .get(url)
      .then((res) => {
        if (cell.Identifier === "Currency" && template === "additionalInfo") {
          // Assuming the response is an array of currency objects
          const options = res.data.map((currency) => ({
            value: currency.CurrencyID,
            label: currency.Currency,
          }));

          // Update state with the options
          this.setState((prevState) => ({
            ...prevState,
            additionalInfoTemplate: {
              ...prevState.additionalInfoTemplate,
              [cell.FieldId]: {
                ...prevState.additionalInfoTemplate[cell.FieldId],
                options: res.data,
              },
            },
          }));

          // Assuming setOptionsInRedux function is defined
          this.setOptionsInRedux(
            templateId,
            cell.Identifier,
            options,
            template
          );
        }
        this.toggleLoaderArray(cell.Identifier);
      })
      .catch((err) => {
        console.log(err, "options error");
        if (cell.Identifier === "Currency" && template === "additionalInfo") {
          this.setState((prevState) => ({
            ...prevState,
            additionalInfoTemplate: {
              ...prevState.additionalInfoTemplate,
              [cell.FieldId]: {
                ...prevState.additionalInfoTemplate[cell.FieldId],
                options: [],
              },
            },
          }));
        }
        this.toggleLoaderArray(cell.Identifier);
      });
  };

  setOptionsInRedux = (templateId, name, data, template) => {
    let tempObject = {
      data,
      template,
    };
    let updateTemplateValues = this.props.templateValues;
    if (Object.keys(updateTemplateValues).length !== 0) {
      if (Object.keys(updateTemplateValues).some((val) => val == templateId)) {
        if (
          !Object.keys(updateTemplateValues[templateId]).some(
            (item) => item == name
          )
        ) {
          let newTemplateValues = {
            ...updateTemplateValues[templateId],
            [name]: tempObject,
          };
          this.props.setTemplateValues({ [templateId]: newTemplateValues });
        }
      } else {
        this.props.setTemplateValues({ [templateId]: { [name]: tempObject } });
      }
    } else {
      this.props.setTemplateValues({ [templateId]: { [name]: tempObject } });
    }
  };

  //getting options data from redux
  getOptionsFromRedux = (cell, template) => {
    let templateId = this.props.quotationJsonTemplate?.PARAM[0]?.template_id;
    let updateTemplateValues = this.props.templateValues;
    if (Object.keys(updateTemplateValues).length !== 0) {
      if (updateTemplateValues[templateId]) {
        let cellData = updateTemplateValues[templateId][cell.Identifier];
        if (cellData && cellData.template == template) return cellData.data;
      }
    }
  };

  getDynamicDefaultValues = (cell, index, DefaultValue) => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;
    const url = getIdentifiersDynamicValues(
      cell.Identifier,
      this.props.selectedItems[index].id,
      this.props.selectedCategory.id
    );
    this.toggleLoaderArray(
      `${cell.Identifier}_getDynamicDefaultValues_${index}`
    );
    axios
      .get(url)
      .then((res) => {
        if (res.data) {
          this.setState((prevState) => ({
            ...prevState,
            mainTemplateQuotation: {
              ...prevState.mainTemplateQuotation,
              // [this.props.selectedItems[index]?.id]: {
              //   ...prevState.mainTemplateQuotation[this.props.selectedItems[index]?.id],
              //   [cell.FieldId]: {
              //     ...prevState.mainTemplateQuotation[
              //       this.props.selectedItems[index]?.id
              //     ][cell.FieldId],
              //     DynamicDefaultValue:
              //       res.data.path[0][0][
              //         `${
              //           cell.Identifier === "BasicHistoricalPrice"
              //             ? "last_basic_rate"
              //             : cell.Identifier === "LandedHistoricalPrice"
              //             ? "landed_cost"
              //             : cell.Identifier === "DateHP" && "update_timestamp"
              //         }`
              //       ],
              //   },
              // },
            },
          }));
        } else {
          this.setState((prevState) => ({
            ...prevState,
            mainTemplateQuotation: {
              ...prevState.mainTemplateQuotation,
              [this.props.selectedItems[index]?.id]: {
                ...prevState.mainTemplateQuotation[
                this.props.selectedItems[index]?.id
                ],
                [cell.FieldId]: {
                  ...prevState.mainTemplateQuotation[
                  this.props.selectedItems[index]?.id
                  ][cell.FieldId],
                  DynamicDefaultValue: DefaultValue,
                },
              },
            },
          }));
        }

        this.toggleLoaderArray(
          `${cell.Identifier}_getDynamicDefaultValues_${index}`
        );
      })
      .catch((err) => {
        console.log(err, "getDynamicDefaultValues");
        if (this.props.selectedItems) {
          if (this.props.selectedItems[index]) {
            this.setState((prevState) => ({
              ...prevState,
              mainTemplateQuotation: {
                ...prevState.mainTemplateQuotation,
                [this.props.selectedItems[index]?.id]: {
                  ...prevState.mainTemplateQuotation[
                  this.props.selectedItems[index]?.id
                  ],
                  [cell.FieldId]: {
                    ...prevState.mainTemplateQuotation[
                    this.props.selectedItems[index]?.id
                    ][cell.FieldId],
                    options: [],
                  },
                },
              },
            }));
          }
        }

        this.toggleLoaderArray(
          `${cell.Identifier}_getDynamicDefaultValues_${index}`
        );
      });
  };

  checkIsLinkLocal = (cell) => {
    //first check if the vendor master check is present or not
    if (
      this.props.rfqSettingValues?.vendorMasterPaymentTerm &&
      this.props.rfqSettingValues?.vendorMasterIncoTerm
    ) {
      // check from both
      let str = this.props.companyInfo?.inco_term_identifier;
      let str1 = this.props.companyInfo?.payment_term_identifier;
      if (str) {
        let arr = str.split(";");
        if (arr.includes(cell.Identifier)) {
          return "inco";
        }
      }
      if (str1) {
        let arr1 = str1.split(";");
        if (arr1.includes(cell.Identifier)) {
          return "payment";
        }
      }
      return false;
    } else if (this.props.rfqSettingValues?.vendorMasterPaymentTerm) {
      // paymentterm array
      if (!this.props.companyInfo?.payment_term_identifier) return false;
      let str = this.props.companyInfo?.payment_term_identifier;
      let arr = str.split(";");
      // let arr = ["PaymentType", "PaymentTypeForeign"];
      if (arr.includes(cell.Identifier)) {
        return "payment";
      }
      return false;
    } else if (this.props.rfqSettingValues?.vendorMasterIncoTerm) {
      // incoterm array
      if (!this.props.companyInfo?.inco_term_identifier) return false;
      let str = this.props.companyInfo?.inco_term_identifier;
      let arr = str.split(";");
      // let arr = ["DeliveryBasis", "DeliveryRemarks"];
      if (arr.includes(cell.Identifier)) {
        return "inco";
      }
      return false;
    }
    return false;
  };

  download = () => {
    // setisLoading(true);
    this.setState({
      isLoading: true,
    });
    let destination = this.getDestinationValue();
    const dest = destination?.split("-");
    const code = Object.values(this.props.items).map((item) => item.code);

    // this.props.items.map((item) => {
    //   code.push(item.code);
    // });
    if (destination == "") {
      // dispatch({
      //   type: OPEN_SNACKBAR,
      //   payload: {
      //     type: "error",
      //     msg: "Please select destination first",
      //   },
      // });
      this.setState({
        isLoading: false,
      });
      // setisLoading(false);

      return false;
    }
    axios
      .post(
        downloadmaterialDescription,
        {
          buyerID: this.props.userInfo.division_id,
          pr_dest_code: dest[dest.length - 1],
          items: code,
        },
        {
          responseType: "blob", // Move responseType option inside the configuration object
        }
      )
      .then((response) => {
        this.setState({
          isLoading: false,
        });
        const href = window.webkitURL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "Material Description.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        // dispatch({
        //   type: OPEN_SNACKBAR,
        //   payload: {
        //     type: "error",
        //     msg: err?.response?.data.message
        //       ? err?.response?.data.message
        //       : err?.response?.statusText
        //       ? err?.response?.statusText
        //       : "Something went wrong!",
        //   },
        // });
      });
  };

  getFieldLocal = (cell) => {
    let error =
      this.state.additionalInfoTemplate[cell?.FieldId] &&
      this.state.additionalInfoTemplate[cell?.FieldId]["error"];
    let ErrorMessage =
      this.state.additionalInfoTemplate[cell?.FieldId] &&
      this.state.additionalInfoTemplate[cell?.FieldId]["ErrorMessage"];
    let name =
      this.state.additionalInfoTemplate[cell?.FieldId] &&
      this.state.additionalInfoTemplate[cell?.FieldId]["HTMLAttributeName"];
    let value =
      this.state.additionalInfoTemplate[cell?.FieldId] &&
      this.state.additionalInfoTemplate[cell?.FieldId]["value"];
    let isLink = this.checkIsLinkLocal(cell);
    if (isLink) {
      let index1 = this.state.errorArray.indexOf(name);
      if (index1 !== -1) {
        this.state.errorArray.splice(index1, 1);
        this.setState({ errorArray: this.state.errorArray });
      }
      return (
        <React.Fragment>
          <Button
            className="local_template_textfield"
            fullWidth
            variant="outlined"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              border: "1x solid",
              borderColor: (theme) => theme.palette.bluegrey[500],
              padding: "16.5px 14px",
              color: "text.primary",
              fontWeight: 400,
              fontSize:
                "calc(14px - (14 - 16) * ((100vw - 300px) / (1920 - 300)))",
              lineHeight: 1,
              textTransform: "capitalize",
            }}
            onClick={() => {
              this.handleLocalVendorTermsOpen(isLink, cell.FieldId);
            }}
            endIcon={
              <OpenInNew
                sx={{ color: (theme) => theme.palette.primary.main }}
              />
            }
          >
            {cell.FieldLabel}
          </Button>
        </React.Fragment>
      );
    } else if (cell?.ElementType === "T" && !Array.isArray(value)) {
      const renderTextField = (value, error, ErrorMessage, name) => (
        <TextField
          key={this.state.prevRFQLoading}
          fullWidth
          value={value || ""}
          className="local_template_textfield"
          variant="outlined"
          label={
            cell.FieldLabel +
            (cell.InstructionText ? ` (${cell.InstructionText})` : "")
          }
          multiline
          maxRows={3}
          type="text"
          // error={error && !Boolean(value)}
          // helperText={error && !Boolean(value) && ErrorMessage}
          error={error}
          helperText={error && ErrorMessage}
          name={name}
        />
      );

      if (
        this.props.multiplePlantsFlag &&
        (cell.Identifier === "Destination" ||
          cell.Identifier === "DestinationForeign")
      ) {
        if (value && !value.includes("###") && !this.props.multiplePlantsFlag) {
          return renderTextField(value, error, ErrorMessage, name);
        }
      } else {
        if (value) {
          return renderTextField(value, error, ErrorMessage, name);
        } else {
          return <Typography>{cell.FieldLabel}</Typography>;
        }
      }

      // Do not render anything if the FieldLabel is "Destination" and value contains "###"
      return null;
    } else if (cell?.ElementType === "TB") {
      return (
        <TextField
          key={this.state.prevRFQLoading}
          className="local_template_textfield"
          fullWidth
          variant="outlined"
          // label={cell.FieldLabel}
          label={
            cell.FieldLabel +
            (cell.InstructionText ? ` (${cell.InstructionText})` : "")
          }
          type="text"
          // error={error && !Boolean(value)}
          // helperText={error && !Boolean(value) && ErrorMessage}
          error={error}
          helperText={error && ErrorMessage}
          name={name}
          value={value ? value : ""}
          onChange={(e) => {
            this.handleLocalChange(e, cell.FieldId);
            // this.validateInputLocal(cell.FieldId);
            this.validateInputAdditionalInfo(cell.FieldId);
          }}
          InputProps={
            (cell.Identifier === "FreightChargesForeign" ||
              cell.Identifier === "FreightChargesLocal" ||
              cell.Identifier === "LoadingCharges" ||
              cell.Identifier === "UpoadingCharges" ||
              (cell.CurrencyFlag === "1" && cell.CurrencyFormula != "") ||
              cell.Identifier === "TwoPointDelivery" ||
              cell.Identifier === "DetentionCharges") && {
              endAdornment: (
                <InputAdornment position="start">
                  {getSymbol(
                    this.props?.lastTransactionTypeInThread === "Inquiry" &&
                      (this.props?.vendorType === "International" ||
                        this.props.vendorType === "Local Importer")
                      ? this.state.selectedCurrency
                      : this.props.quotedCurrency
                  )}
                </InputAdornment>
              ),
            }
          }
        />
      );
    } else if (cell?.ElementType === "TA") {
      return (
        <TextField
          key={this.state.prevRFQLoading}
          className="local_template_textarea"
          fullWidth
          multiline
          variant="outlined"
          maxRows={3}
          // label={cell.FieldLabel}
          label={
            cell.FieldLabel +
            (cell.InstructionText ? ` (${cell.InstructionText})` : "")
          }
          // error={error && !Boolean(value)}
          // helperText={error && !Boolean(value) && ErrorMessage}
          error={error}
          helperText={error && ErrorMessage}
          name={name}
          value={value ? value : ""}
          onChange={(e) => {
            this.handleLocalChange(e, cell.FieldId);
            this.validateInputAdditionalInfo(cell.FieldId);
            // this.validateInputLocal(cell.FieldId);
          }}
        />
      );
    } else if (cell?.ElementType === "M") {
      return (
        <Checkbox
          color="primary"
          checked={!!value}
          name={name}
          onChange={(e) => {
            this.handleLocalChange(e, cell.FieldId);
          }}
        />
      );
    } else if (cell?.ElementType === "DT") {
      return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            format="dd/MM/yyyy"
            sx={{ width: "100%" }}
            slotProps={{
              textField: {
                size: "fullWidth",
                error: error,
                helperText: error && (cell.ErrorMessage || "Invalid Date"),
              },
            }}
            // label={cell.FieldLabel}
            label={
              cell.FieldLabel +
              (cell.InstructionText ? ` (${cell.InstructionText})` : "")
            }
            name={name}
            value={value ? new Date(value) : null}
            onChange={(newVal) => {
              this.handleDateChange(
                moment(newVal),
                cell.FieldId,
                "additionalInfoTemplate"
              );
            }}
          />
        </LocalizationProvider>
      );
    } else if (cell?.ElementType === "F") {
      return (
        // <FormControl error={error && !Boolean(value)} sx={{ width: "100%" }}>
        <FormControl error={error} sx={{ width: "100%" }}>
          <Box
            sx={{
              border: "solid 1px",
              borderColor: (theme) => theme.palette.bluegrey[500],
              borderRadius: "4px",
              minWidth: "100%",
              height: "56px",
              display: "flex",
              alignItems: "center",
              padding: "0 10px",
              position: "relative",
            }}
          >
            <InputLabel
              sx={{
                color: "text.secondary",
                backgroundColor: (theme) => theme.palette.grey[0],
                position: "absolute",
                left: 0,
                top: 0,
                paddingLeft: "5px",
                paddingRight: "5px",
                transformOrigin: "top left",
                webkitTransform: " translate(14px, -9px) scale(0.75)",
                transform: "translate(14px, -9px) scale(0.75)",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "calc(133% - 24px)",
                zIndex: 1,
              }}
            >
              {/* {cell.FieldLabel} */}
              {cell.FieldLabel +
                (cell.InstructionText ? ` (${cell.InstructionText})` : "")}
            </InputLabel>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                this.setState({
                  currentFieldId: cell.FieldId,
                  currentTemplate: "additionalInfoTemplate",
                });
                if (
                  value !== null &&
                  value !== undefined &&
                  value !== "" &&
                  value.length > 0 &&
                  value[0]?.id
                ) {
                  this.getExistingFilesForAttachment(value);
                } else {
                  this.setState({
                    dynamicFileUploadOpen: true,
                  });
                }
              }}
            >
              <UploadIcon sx={{ marginRight: "10px" }} />
              Attachment
            </Button>
            <FormHelperText sx={{ ml: "auto" }}>
              Number Of Files {value?.length ? value?.length : 0}
            </FormHelperText>
          </Box>
          <Typography variant="caption">
            {this.state.additionalInfoTemplate[cell.HTMLAttributeName]}
          </Typography>
          {error && <FormHelperText>{ErrorMessage}</FormHelperText>}
        </FormControl>
      );
    } else if (
      (this.props.PermissionInquiry?.is_payment_term === "Y" &&
        this.props.companyInfo.payment_term_identifier
          .split(";")
          .includes(cell.Identifier)) ||
      (this.props.PermissionInquiry?.is_inco_term === "Y" &&
        this.props.companyInfo.inco_term_identifier
          .split(";")
          .includes(cell.Identifier))
    ) {
      if (
        this.state.additionalInfoTemplate[cell.FieldId] &&
        this.state.additionalInfoTemplate[cell.FieldId]["options"] &&
        this.state.additionalInfoTemplate[cell.FieldId]["options"] !=
        "loading" &&
        this.state.additionalInfoTemplate[cell.FieldId]["options"].length > 0
      ) {
        let data = this.state.additionalInfoTemplate[cell.FieldId].options.map(
          (option) =>
            option.dropdown_element_name
              ? this.props.companyInfo?.default_dropdown_type === "10" ||
                cell.Identifier === "Destination" ||
                cell.Identifier === "DestinationForeign" ||
                cell.Identifier === "DeliveryBasis" ||
                // cell.Identifier === "PaymentType" ||
                cell.Identifier === "Currency"
                ? `${option.dropdown_element_name}-${option.dropdown_element_code}`
                : `${option.dropdown_element_code}-${option.dropdown_element_name}`
              : option.code
        );
        if (data.includes(value)) {
          return (
            <TextField
              key={this.state.prevRFQLoading}
              fullWidth
              value={value || ""}
              className="textfield"
              variant="outlined"
              label={
                cell.FieldLabel +
                (cell.InstructionText ? ` (${cell.InstructionText})` : "")
              }
              multiline
              maxRows={3}
              type="text"
              // error={error && !Boolean(value)}
              // helperText={error && !Boolean(value) && ErrorMessage}
              error={error}
              helperText={error && ErrorMessage}
              name={name}
            />
          );
        } else {
          return cell?.FieldLabel;
        }
      }
    } else if (cell?.ElementType === "S") {
      if (this.props.multiplePlantsFlag && cell.Identifier === "GSTType") {
        // GSTType will not shown in common in case of multiple plants
        return null;
      }

      return (
        <FormControl
          fullWidth
          variant="outlined"
          // error={error && !Boolean(value)}
          error={error}
        >
          <InputLabel>{cell.FieldLabel}</InputLabel>
          <Tooltip title={value} disableInteractive placement="top-start">
            <Select
              className="template_dropdown"
              // label={cell.FieldLabel}
              label={
                cell.FieldLabel +
                (cell.InstructionText ? ` (${cell.InstructionText})` : "")
              }
              name={name}
              value={value ? value : ""}
              onChange={(e) => {
                this.handleLocalChange(e, cell.FieldId);
              }}
              onBlur={(e) => {
                this.validateInputAdditionalInfo(cell.FieldId);
                // this.validateInputLocal(cell.FieldId);
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 250,
                    width: 250,
                  },
                },
              }}
            >
              <MenuItem value={""}>Select</MenuItem>
              {this.state.additionalInfoTemplate[cell.FieldId] &&
                this.state.additionalInfoTemplate[cell.FieldId]["options"] &&
                (this.state.additionalInfoTemplate[cell.FieldId]["options"]
                  .length > 0 ? (
                  this.state.additionalInfoTemplate[cell.FieldId]["options"] ===
                    "loading" ? (
                    <MenuItem value="">Loading...</MenuItem>
                  ) : (
                    this.state.additionalInfoTemplate[cell.FieldId][
                      "options"
                    ].map((option, id) => {
                      return (
                        <MenuItem
                          key={id}
                          value={
                            option.dropdown_element_name
                              ? this.props.companyInfo
                                ?.default_dropdown_type === "10" ||
                                cell.Identifier === "Destination" ||
                                cell.Identifier === "DestinationForeign" ||
                                cell.Identifier === "DeliveryBasis" ||
                                // cell.Identifier === "PaymentType" ||
                                cell.Identifier === "Currency"
                                ? `${option.dropdown_element_name}-${option.dropdown_element_code}`
                                : `${option.dropdown_element_code}-${option.dropdown_element_name}`
                              : option.code
                          }
                        >
                          <Typography style={{ whiteSpace: "pre-line" }}>
                            {option.dropdown_element_name
                              ? option.dropdown_element_code ===
                                option.dropdown_element_name
                                ? option.dropdown_element_name
                                : `${option.dropdown_element_code}-${option.dropdown_element_name}`
                              : option.name}
                          </Typography>
                        </MenuItem>
                      );
                    })
                  )
                ) : (
                  <MenuItem value="">Not found</MenuItem>
                ))}
            </Select>
          </Tooltip>
          {/* {error && !Boolean(value) && ( */}
          {error && <FormHelperText>{ErrorMessage}</FormHelperText>}
        </FormControl>
      );
    } else if (cell?.ElementType === "T" && Array.isArray(value)) {
      return (
        // <FormControl error={error && !Boolean(value)} sx={{ width: "100%" }}>
        <FormControl error={error} sx={{ width: "100%" }}>
          <Box
            sx={{
              border: "solid 1px",
              borderColor: (theme) => theme.palette.bluegrey[500],
              borderRadius: "4px",
              minWidth: "100%",
              height: "56px",
              display: "flex",
              alignItems: "center",
              padding: "0 10px",
              position: "relative",
            }}
          >
            <InputLabel
              sx={{
                color: "text.secondary",
                backgroundColor: (theme) => theme.palette.grey[0],
                position: "absolute",
                left: 0,
                top: 0,
                paddingLeft: "5px",
                paddingRight: "5px",
                transformOrigin: "top left",
                webkitTransform: " translate(14px, -9px) scale(0.75)",
                transform: "translate(14px, -9px) scale(0.75)",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "calc(133% - 24px)",
                zIndex: 1,
              }}
            >
              {/* {cell.FieldLabel} */}
              {cell.FieldLabel +
                (cell.InstructionText ? ` (${cell.InstructionText})` : "")}
            </InputLabel>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                this.handleFileSpecificationsOpen(value, cell.FieldLabel);
              }}
            >
              <UploadIcon sx={{ marginRight: "10px" }} />
              View Attachment
            </Button>
            <FormHelperText sx={{ ml: "auto" }}>
              Number Of Files {value?.length ? value?.length : 0}
            </FormHelperText>
          </Box>
          <Typography variant="caption">
            {this.state.additionalInfoTemplate[cell.HTMLAttributeName]}
          </Typography>
          {error && <FormHelperText>{ErrorMessage}</FormHelperText>}
        </FormControl>
      );
    } else {
      return cell?.FieldLabel;
    }
  };

  finddependacy = (FieldId, validation) => {
    let field_ids = validation.match(/f(\d)*/g);
    let is_dependacy = 0;
    for (const field_id of field_ids) {
      if ("f" + FieldId != field_id) {
        is_dependacy = 1;
        break;
      }
    }
    return is_dependacy;
  };

  validateInputRFQ = (FieldId, submitting) => {
    if (submitting) {
      if (
        this.state.RFQDynamic[FieldId]["error"] &&
        !this.finddependacy(
          FieldId,
          this.state.RFQDynamic[FieldId]["Validation"]
        )
      )
        return;
    }
    if (this.state.RFQDynamic[FieldId]?.["Validation"]) {
      let temp = this.state.RFQDynamic[FieldId]["Validation"].replace(
        /f(\d)*/g,
        (match) => {
          if (this.state.RFQDynamic[match.replace("f", "")]) {
            return `this.state.RFQDynamic[${match.replace("f", "")}]["value"]`;
          } else if (
            this.state.additionalInfoTemplate[match.replace("f", "")]
          ) {
            return `this.state.additionalInfoTemplate[${match.replace(
              "f",
              ""
            )}]["value"]`;
          } else if (this.state.IntlTemplate[match.replace("f", "")]) {
            return `this.state.IntlTemplate[${match.replace(
              "f",
              ""
            )}]["value"]`;
          } else {
            return null;
          }
        }
      );
      if (temp !== null) {
        if (this.state.RFQDynamic[FieldId]["ElementType"] == "DT") {
          this.state.RFQDynamic[FieldId]["value"] = moment(
            this.state.RFQDynamic[FieldId]["value"]
          ).format("YYYY-MM-DD");
        }
        if (eval(temp)) {
          this.setState((prevState) => ({
            ...prevState,
            RFQDynamic: {
              ...prevState.RFQDynamic,
              [FieldId]: {
                ...prevState.RFQDynamic[FieldId],
                error: false,
              },
            },
          }));

          let index1 = this.state.errorArray.indexOf(
            this.state.RFQDynamic[FieldId]["HTMLAttributeName"]
          );
          if (index1 !== -1) {
            this.state.errorArray.splice(index1, 1);
          }
        } else {
          this.setState((prevState) => ({
            ...prevState,
            RFQDynamic: {
              ...prevState.RFQDynamic,
              [FieldId]: {
                ...prevState.RFQDynamic[FieldId],
                error: true,
              },
            },
          }));

          if (
            !this.state.errorArray.includes(
              this.state.RFQDynamic[FieldId]["HTMLAttributeName"]
            )
          ) {
            this.state.errorArray.push(
              this.state.RFQDynamic[FieldId]["HTMLAttributeName"]
            );
          }
        }
        this.setState({
          errorArray: this.state.errorArray,
        });
      } else {
        console.log("dependent field not found in template");
      }
    }
  };

  validateInputLocal = (FieldId, submitting) => {
    //function name has to be change
    if (submitting) {
      if (this.state.additionalInfoTemplate[FieldId]["error"]) {
        if (
          !this.state.errorArray.includes(
            this.state.additionalInfoTemplate[FieldId]["HTMLAttributeName"]
          )
        ) {
          this.state.errorArray.push(
            this.state.additionalInfoTemplate[FieldId]["HTMLAttributeName"]
          );
        }
        return;
      }
    }
    // if (this?.state?.additionalInfoTemplate[FieldId]["Validation"]) {
    //   let temp = this.state?.additionalInfoTemplate[FieldId]["Validation"].replace(
    //     /f(\d)+/g,
    //     (match) => {
    //       if (this.state.additionalInfoTemplate[match.replace("f", "")]) {
    //         return `this.state.additionalInfoTemplate[${match.replace(
    //           "f",
    //           ""
    //         )}]["value"]`;
    //       } else if (this.state.IntlTemplate[match.replace("f", "")]) {
    //         return `this.state.IntlTemplate[${match.replace(
    //           "f",
    //           ""
    //         )}]["value"]`;
    //       } else {
    //         return null;
    //       }
    //     }
    //   );
    //   if (temp !== null) {
    //     if (eval(temp)) {
    //       this.setState((prevState) => ({
    //         ...prevState,
    //         additionalInfoTemplate: {
    //           ...prevState.additionalInfoTemplate,
    //           [FieldId]: {
    //             ...prevState.additionalInfoTemplate[FieldId],
    //             error: false,
    //           },
    //         },
    //       }));
    //       let index1 = this.state.errorArray.indexOf(
    //         this.state.additionalInfoTemplate[FieldId]["HTMLAttributeName"]
    //       );
    //       if (index1 !== -1) {
    //         this.state.errorArray.splice(index1, 1);
    //       }
    //     } else {
    //       this.setState((prevState) => ({
    //         ...prevState,
    //         additionalInfoTemplate: {
    //           ...prevState.additionalInfoTemplate,
    //           [FieldId]: {
    //             ...prevState.additionalInfoTemplate[FieldId],
    //             error: true,
    //           },
    //         },
    //       }));
    //       if (
    //         !this.state.errorArray.includes(
    //           this.state.additionalInfoTemplate[FieldId]["HTMLAttributeName"]
    //         )
    //       ) {
    //         this.state.errorArray.push(
    //           this.state.additionalInfoTemplate[FieldId]["HTMLAttributeName"]
    //         );
    //       }
    //     }
    //     this.setState({
    //       errorArray: this.state.errorArray,
    //     });
    //   } else {
    //     console.log("dependent field not found in template");
    //   }
    // }
  };

  validateAdditionalInfo = (FieldId, index, submitting) => {
    const { vendorType } = this.props;
    const { additionalInfoTemplate, errorArray } = this.state;
    const fieldTemplate = additionalInfoTemplate[FieldId];
    const fieldLabel = fieldTemplate?.FieldLabel;

    // if (vendorType === "Local" && fieldLabel === "Currency") {
    if (fieldLabel === "Currency") {
      return null; // Skip further processing for "Currency"
    }

    if (
      submitting &&
      fieldTemplate?.error &&
      !this.finddependacy(FieldId, fieldTemplate.Validation)
    ) {
      return;
    }
    const validation = fieldTemplate?.Validation;
    if (validation) {
      const temp = validation.replace(/f(\d)+/g, (match) => {
        const id = match.replace("f", "");
        const dependentField = additionalInfoTemplate[id];
        return dependentField
          ? dependentField.value === null
            ? '""'
            : `this.state.additionalInfoTemplate[${id}].value`
          : "";
      });

      if (temp !== null) {
        if (fieldTemplate.ElementType === "DT") {
          fieldTemplate.value =
            fieldTemplate.value && fieldTemplate.value != "Invalid Date"
              ? moment(fieldTemplate.value).format("YYYY-MM-DD")
              : "";
        }
        const isValid = eval(temp);
        const updatedFieldTemplate = {
          ...fieldTemplate,
          error: !isValid,
        };

        const updatedErrorArray = isValid
          ? errorArray.filter(
            (attrName) => attrName !== fieldTemplate.HTMLAttributeName
          )
          : [...new Set([...errorArray, fieldTemplate.HTMLAttributeName])];

        this.setState((prevState) => ({
          ...prevState,
          additionalInfoTemplate: {
            ...prevState.additionalInfoTemplate,
            [FieldId]: updatedFieldTemplate,
          },
          errorArray: updatedErrorArray,
        }));
      } else {
        console.log("dependent field not found in template");
      }
    }
  };

  initializeRFQDynamicState = () => {
    let row = {};
    let cellsToUpdate = [];
    this.props.quotationJsonTemplate?.COMMON?.forEach((cell) => {
      row[cell.FieldId] = {
        HTMLAttributeName: cell.HTMLAttributeName,
        value: "",
        Validation: cell.Validation,
        FieldLabel: cell.FieldLabel,
        Identifier: cell.Identifier,
        error: false,
        ErrorMessage: cell.ErrorMessage,
        ElementType: cell.ElementType,
        InstructionText: cell.InstructionText,
      };
      if (cell.ElementType === "S") {
        if (cell.DropdownEdit === "Y") {
          row[cell.FieldId]["options"] = "loading";
          cellsToUpdate.push(cell);
          let optionsData = this.getOptionsFromRedux(cell, "rfq");
          //getting options data from redux if it is not available then calling api
          if (!optionsData) {
            this.getOptionsFromApi(cell, "rfq");
          }
          // this.getOptionsFromApi(cell, "rfq");
        } else {
          row[cell.FieldId]["options"] = cell.DefaultValue;
        }
      } else {
        row[cell.FieldId]["value"] = cell.DefaultValue || "";
      }
      if (cell.ElementType === "T") {
        row[cell.FieldId]["value"] = cell.DefaultValue;
      }

      let readOnly = false;
      // let value = row[cell.FieldId]["value"];

      if (
        Object.keys(this.props.prlineitems).length &&
        prconfig &&
        prconfig[cell.Identifier] !== undefined
      ) {
        row[cell.FieldId]["value"] =
          eval(this[prconfig[cell.Identifier]?.function_name])(
            cell.Identifier,
            this.props.selectedItems[0]
          ) || "";
        if (cell.ElementType === "S" && cell.DropdownEdit !== "Y") {
          Object.keys(row[cell.FieldId]["options"]).map((key) => {
            const plant_arr =
              row[cell.FieldId]["options"][key]["code"].split("-");
            if (plant_arr[plant_arr.length - 1] == row[cell.FieldId]["value"]) {
              row[cell.FieldId]["value"] =
                row[cell.FieldId]["options"][key]["code"];
            }
          });
        }
        if (
          prconfig[cell.Identifier]?.non_editable_function &&
          prconfig[cell.Identifier]?.non_editable_function !== "0"
        ) {
          if (prconfig[cell.Identifier]?.non_editable_function == "1") {
            readOnly = true;
          } else {
            readOnly = eval(
              this[prconfig[cell.Identifier]?.non_editable_function]
            )(row[cell.FieldId]["value"]);
          }
        }
      }
      row[cell.FieldId]["readOnly"] = readOnly;
      row[cell.FieldId]["DefaultValue"] = cell["DefaultValue"];
    });
    this.setState(
      {
        RFQDynamic: row,
      },
      () => {
        //getting options data from redux if it is available setting rfq data
        cellsToUpdate.map((cell) => {
          let optionsData = this.getOptionsFromRedux(cell, "rfq");
          if (cell.ElementType === "S") {
            if (cell.DropdownEdit === "Y") {
              if (optionsData) {
                let value = this.state.RFQDynamic[cell.FieldId]["value"];
                if (value) {
                  if (optionsData) {
                    optionsData.forEach((option, id) => {
                      if (option.dropdown_element_code === value) {
                        value = `${option.dropdown_element_code}-${option.dropdown_element_name}`;
                        if (
                          this.props.companyInfo?.default_dropdown_type ===
                          "10" ||
                          cell.Identifier === "Destination" ||
                          cell.Identifier === "DestinationForeign"
                        ) {
                          value = `${option.dropdown_element_name}-${option.dropdown_element_code}`;
                        }
                      }
                    });
                    this.setState((prevState) => ({
                      ...prevState,
                      RFQDynamic: {
                        ...prevState.RFQDynamic,
                        [cell.FieldId]: {
                          ...prevState.RFQDynamic[cell.FieldId],
                          options: optionsData,
                          value,
                        },
                      },
                    }));
                  }
                } else {
                  this.setState((prevState) => ({
                    ...prevState,
                    RFQDynamic: {
                      ...prevState.RFQDynamic,
                      [cell.FieldId]: {
                        ...prevState.RFQDynamic[cell.FieldId],
                        options: optionsData,
                      },
                    },
                  }));
                }
              }
            }
          }
        });
      }
    );
  };

  validateInput = (FieldId, index, submitting) => {
    // Get the current item based on the index
    // let itemKey = this.props.transactionItemSequence[index];
    //  let itemKey = Object.keys(this.state.mainTemplateQuotation)[index];

    const mainTemplateQuotationSet = new Set(
      Object.keys(this.state.mainTemplateQuotation)
    );
    let filteredTransactionItemSequence =
      this.props.transactionItemSequence.filter((item) =>
        mainTemplateQuotationSet.has(item)
      );
    let itemKey = filteredTransactionItemSequence[index];

    const currentItem = this.state.mainTemplateQuotation[itemKey];

    if (currentItem?.selected === "N") {
      Object.keys(currentItem).forEach((field) => {
        if (currentItem[field] && currentItem[field]["HTMLAttributeName"]) {
          const errorIndex = this.state.errorArray.indexOf(
            currentItem[field]["HTMLAttributeName"]
          );
          if (errorIndex !== -1) {
            this.state.errorArray.splice(errorIndex, 1);
          }
        }
      });

      this.setState({
        errorArray: this.state.errorArray,
      });
      // If selected is "N", skip validation
      return;
    }

    if (submitting) {
      if (
        currentItem[FieldId] &&
        currentItem[FieldId]["error"] &&
        currentItem[FieldId]["Validation"] &&
        !this.finddependacy(FieldId, currentItem[FieldId]["Validation"])
      ) {
        if (
          !this.state.errorArray.includes(
            currentItem[FieldId]["HTMLAttributeName"]
          )
        ) {
          this.state.errorArray.push(currentItem[FieldId]["HTMLAttributeName"]);
          this.setState({
            errorArray: this.state.errorArray,
          });
          return;
        }
      }
    }

    if (currentItem[FieldId] && currentItem[FieldId]["Validation"]) {
      let temp = currentItem[FieldId]["Validation"].replace(
        /f(\d)+/g,
        (match) => {
          if (currentItem[match.replace("f", "")]) {
            return `this.state.mainTemplateQuotation[${itemKey}][${match.replace(
              "f",
              ""
            )}]["value"]`;
          } else if (
            this.state.additionalInfoTemplate[match.replace("f", "")]
          ) {
            return `this.state.additionalInfoTemplate[${match.replace(
              "f",
              ""
            )}]["value"]`;
          } else if (this.state.IntlTemplate[match.replace("f", "")]) {
            return `this.state.IntlTemplate[${match.replace(
              "f",
              ""
            )}]["value"]`;
          } else if (
            this.state.EvalResults[
            itemKey
            // Object.keys(this.state.mainTemplateQuotation)[index]
            ][match.replace("f", "")]
          ) {
            return `this.state.EvalResults[${
              // Object.keys(this.state.mainTemplateQuotation)[index]
              itemKey
              }][${match.replace("f", "")}]`;
          } else {
            return null;
          }
        }
      );
      if (temp !== null) {
        if (eval(temp)) {
          currentItem[FieldId]["error"] = false;

          this.setState((prevState) => ({
            ...prevState,
            mainTemplateQuotation: {
              ...prevState.mainTemplateQuotation,
              [itemKey]: {
                ...prevState.mainTemplateQuotation[itemKey],
                [FieldId]: {
                  ...prevState.mainTemplateQuotation[itemKey][FieldId],
                  error: false,
                },
              },
            },
          }));

          let index1 = this.state.errorArray.indexOf(
            currentItem[FieldId]["HTMLAttributeName"]
          );
          if (index1 !== -1) {
            this.state.errorArray.splice(index1, 1);
          }
        } else {
          currentItem[FieldId]["error"] = true;

          this.setState((prevState) => ({
            ...prevState,
            mainTemplateQuotation: {
              ...prevState.mainTemplateQuotation,
              [itemKey]: {
                ...prevState.mainTemplateQuotation[itemKey],
                [FieldId]: {
                  ...prevState.mainTemplateQuotation[itemKey][FieldId],
                  error: true,
                },
              },
            },
          }));

          if (
            !this.state.errorArray.includes(
              currentItem[FieldId]["HTMLAttributeName"]
            )
          ) {
            this.state.errorArray.push(
              currentItem[FieldId]["HTMLAttributeName"]
            );
          }
        }
        this.setState({
          mainTemplateQuotation: this.state.mainTemplateQuotation,
          errorArray: this.state.errorArray,
        });
      } else {
        console.log("dependent field not found in template");
      }
    }
  };

  validateInputAdditionalInfo = (FieldId, index, submitting) => {
    const additionalInfoTemplate = this.state.additionalInfoTemplate;
    const field = additionalInfoTemplate[FieldId];

    if (!field) {
      console.error(
        `Field with FieldId ${FieldId} not found in additionalInfoTemplate.`
      );
      return;
    }

    if (field["Identifier"] === "Currency") {
      // skip validation for currency
      return;
    }

    // Check if the field is required (if it has an asterisk in the FieldLabel)
    const isRequired = field["FieldLabel"]
      ? field["FieldLabel"].includes("*")
      : false;

    // Determine if we should validate this field
    const shouldValidate = isRequired || submitting;
    if (shouldValidate) {
      // Evaluate if field has dependencies that are satisfied
      const dependenciesSatisfied = this.findDependency(
        FieldId,
        field["Validation"]
      );

      if (!dependenciesSatisfied && !isRequired) {
        return;
      }

      // Proceed with validation if required or dependencies are satisfied
      if (field["Validation"]) {
        let temp = field["Validation"].replace(/f(\d)+/g, (match) => {
          const matchField = additionalInfoTemplate[match.replace("f", "")];
          return matchField
            ? `additionalInfoTemplate[${match.replace("f", "")}]["value"]`
            : null;
        });

        if (temp !== null) {
          // Log the actual values used in the validation expression
          const evalString = temp.replace(
            /additionalInfoTemplate\[(\d+)\]\["value"\]/g,
            (match, p1) => {
              return JSON.stringify(additionalInfoTemplate[p1]["value"]);
            }
          );

          let evalResult;
          try {
            evalResult = eval(evalString);
          } catch (error) {
            console.error("Error evaluating expression:", evalString, error);
            evalResult = false; // If evaluation fails, consider it as invalid
          }

          // Prepare updated errorArray
          // let newErrorArray = [...this.state.errorArray];
          if (evalResult) {
            // Validation passed, remove error
            field["error"] = false;
            // const errorIndex = newErrorArray.indexOf(
            //   field["HTMLAttributeName"]
            // );
            // if (errorIndex !== -1) {
            //   newErrorArray.splice(errorIndex, 1);
            // }
            let errorIndex = this.state.errorArray.indexOf(
              field["HTMLAttributeName"]
            );
            if (errorIndex !== -1) {
              this.state.errorArray.splice(errorIndex, 1);
            }
          } else {
            // Validation failed, add error
            field["error"] = true;
            // if (!newErrorArray.includes(field["HTMLAttributeName"])) {
            //   newErrorArray.push(field["HTMLAttributeName"]);
            // }
          }

          // Update state with new error status and errorArray
          this.setState((prevState) => ({
            additionalInfoTemplate: {
              ...prevState.additionalInfoTemplate,
              [FieldId]: {
                ...prevState.additionalInfoTemplate[FieldId],
                error: field["error"],
              },
            },
            // errorArray: newErrorArray,
          }));

          // return newErrorArray;
        } else {
          console.log("dependent field not found in template");
        }
      }
    }
  };

  // Helper function to find dependencies and check if they are satisfied
  findDependency = (FieldId, validation) => {
    let dependenciesSatisfied = true;
    const matches = validation?.match(/f(\d+)/g);
    if (matches) {
      matches.forEach((match) => {
        const dependentFieldId = match.replace("f", "");
        if (this.state.additionalInfoTemplate[dependentFieldId]) {
          let dependentFieldValue =
            this.state.additionalInfoTemplate[dependentFieldId]["value"];
          if (dependentFieldValue == null) {
            dependenciesSatisfied = false;
          }
          // if (!dependentFieldValue) {
          //   dependenciesSatisfied = false;
          // }
        }
      });
    }
    return dependenciesSatisfied;
  };

  validateInputRFQStatic = (FieldId) => {
    if (FieldId === "RFQValidity") {
      if (this.state.RFQStatic.RFQValidity.value === "") {
        this.setState((prevState) => ({
          ...prevState,
          RFQStatic: {
            ...prevState.RFQStatic,
            RFQValidity: {
              ...prevState.RFQStatic.RFQValidity,
              error: true,
            },
          },
        }));
        if (
          !this.state.errorArray.includes(
            this.state.RFQStatic.RFQValidity["HTMLAttributeName"]
          )
        ) {
          this.state.errorArray.push(
            this.state.RFQStatic.RFQValidity["HTMLAttributeName"]
          );
        }
      } else {
        this.setState((prevState) => ({
          ...prevState,
          RFQStatic: {
            ...prevState.RFQStatic,
            RFQValidity: {
              ...prevState.RFQStatic.RFQValidity,
              error: false,
            },
          },
        }));
        let index1 = this.state.errorArray.indexOf(
          this.state.RFQStatic.RFQValidity["HTMLAttributeName"]
        );
        if (index1 !== -1) {
          this.state.errorArray.splice(index1, 1);
        }
      }
    }
    if (FieldId === "TBValidity") {
      if (this.state.RFQStatic.TBValidity.value === "") {
        this.setState((prevState) => ({
          ...prevState,
          RFQStatic: {
            ...prevState.RFQStatic,
            TBValidity: {
              ...prevState.RFQStatic.TBValidity,
              error: true,
            },
          },
        }));
        if (
          !this.state.errorArray.includes(
            this.state.RFQStatic.TBValidity["HTMLAttributeName"]
          )
        ) {
          this.state.errorArray.push(
            this.state.RFQStatic.TBValidity["HTMLAttributeName"]
          );
        }
      } else {
        this.setState((prevState) => ({
          ...prevState,
          RFQStatic: {
            ...prevState.RFQStatic,
            TBValidity: {
              ...prevState.RFQStatic.TBValidity,
              error: false,
            },
          },
        }));
        let index1 = this.state.errorArray.indexOf(
          this.state.RFQStatic.TBValidity["HTMLAttributeName"]
        );
        if (index1 !== -1) {
          this.state.errorArray.splice(index1, 1);
        }
      }
    }
    this.setState({
      errorArray: this.state.errorArray,
    });
  };

  handleLocalChange = (e, FieldId) => {
    const { value, type, checked } = e.target;

    const { additionalInfoTemplate } = this.state;
    additionalInfoTemplate[FieldId]["value"] =
      type !== "checkbox" ? value : checked;
    // e.target.blur();
    this.setState({
      additionalInfoTemplate,
    });
  };

  setTermValue = (value, vendorType, FieldId) => {
    const { additionalInfoTemplate, IntlTemplate } = this.state;
    if (vendorType === true) {
      additionalInfoTemplate[FieldId]["value"] = "Hello";
      this.setState({
        additionalInfoTemplate,
      });
    } else {
      IntlTemplate[FieldId]["value"] = value;
      this.setState({
        IntlTemplate,
      });
    }
  };

  setTermError = () =>
    this.state.vendorTerms === "inco"
      ? this.setState({ incoTermError: true })
      : this.setState({ paymentTermError: true });

  setAttachmentsValue = (
    value,
    currentTemplate = null,
    currentFieldId = null
  ) => {
    this.setState((prevState) => ({
      ...prevState,
      [this.state.currentTemplate || currentTemplate]: {
        ...prevState[this.state.currentTemplate || currentTemplate],
        [this.state.currentFieldId || currentFieldId]: {
          ...prevState[this.state.currentTemplate || currentTemplate][
          this.state.currentFieldId || currentFieldId
          ],
          value: this.state[this.state.currentTemplate || currentTemplate][
            this.state.currentFieldId || currentFieldId
          ]["value"]
            ? [
              ...this.state[this.state.currentTemplate || currentTemplate][
              this.state.currentFieldId || currentFieldId
              ]["value"],
              ...value,
            ]
            : value,
        },
      },
    }));
  };
  setQuotationImport = (imported) => {
    this.setState({ quotationImported: imported });
  };
  handleIntlChange = (e, FieldId) => {
    const { value, type, checked } = e.target;
    const { IntlTemplate } = this.state;
    IntlTemplate[FieldId]["value"] = type !== "checkbox" ? value : checked;
    // e.target.blur();
    this.setState({
      IntlTemplate,
    });
  };
  //added val for autocomplete multiplant
  handleRFQDynamicChange = (e, FieldId, val) => {
    const { RFQDynamic } = this.state;
    if (val) {
      RFQDynamic[FieldId]["value"] = val;
    } else {
      const { value, type, checked } = e.target;
      RFQDynamic[FieldId]["value"] = type !== "checkbox" ? value : checked;
    }
    if (RFQDynamic[FieldId]["Identifier"] === "LDDApplicable") {
      for (const common_field_id in RFQDynamic) {
        if (RFQDynamic[common_field_id]["Identifier"] === "LateDeliveryC") {
          RFQDynamic[common_field_id]["value"] =
            RFQDynamic[FieldId]["value"] == "Yes"
              ? RFQDynamic[common_field_id]["DefaultValue"]
              : "";
          break;
        }
      }
    }
    // e.target.blur();
    this.setState(
      {
        RFQDynamic,
      },
      () => {
        this.validateInputRFQ(FieldId);
      }
    );
  };

  handleRFQStaticChange = (e) => {
    const { name, value, type, checked } = e.target;

    const { RFQStatic } = this.state;
    RFQStatic[name]["value"] = type !== "checkbox" ? value : checked;
    // e.target.blur();
    this.setState({
      RFQStatic,
    });
    if (name === "RFQCCUsersmails") {
      let flag = false;
      if (value?.trim() === "") {
        this.setState((prevState) => ({
          ...prevState,
          RFQStatic: {
            ...prevState.RFQStatic,
            RFQUpdateCCUsersCheck: {
              ...prevState.RFQStatic.RFQUpdateCCUsersCheck,
              value: false,
            },
          },
        }));
      } else {
        value
          ?.trim()
          .split(",")
          .forEach((email) => {
            if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
              flag = true;
            }
          });
      }

      this.setState((prevState) => ({
        ...prevState,
        RFQStatic: {
          ...prevState.RFQStatic,
          RFQCCUsersmails: {
            ...prevState.RFQStatic.RFQCCUsersmails,
            error: flag,
          },
        },
      }));

      if (flag) {
        if (!this.state.errorArray.includes("RFQCCUsersmails")) {
          this.state.errorArray.push("RFQCCUsersmails");
        }
      } else {
        let index1 = this.state.errorArray.indexOf("RFQCCUsersmails");
        if (index1 !== -1) {
          this.state.errorArray.splice(index1, 1);
        }
      }
      this.setState({
        errorArray: this.state.errorArray,
      });
    }
  };

  handleDateChange = (value, FieldId, state, index, Validation, key) => {
    if (state === "mainTemplateQuotation") {
      if (
        value &&
        this.state.mainTemplateQuotation[key][FieldId]["value"] !== value
      ) {
        this.setState({
          applyToAll: {
            ...this.state.applyToAll,
            [`${FieldId}_applyToAll`]: false,
          },
        });
      }
      this.setState((prevState) => ({
        mainTemplateQuotation: {
          ...prevState.mainTemplateQuotation,
          [key]: {
            ...prevState.mainTemplateQuotation[key],
            [FieldId]: {
              ...prevState.mainTemplateQuotation[key][FieldId],
              // value: value || "",
              // value: moment(value).format("DD/MM/YYYY") || "",
              value: moment(value).isValid()
                ? moment(value).format("YYYY-MM-DD") || ""
                : "",
            },
          },
        },
      }));
    } else {
      this.setState((prevState) => ({
        [state]: {
          ...prevState[state],
          [FieldId]: {
            ...prevState[state][FieldId],
            value:
              moment(value).isValid()
                ? moment(value).format("YYYY-MM-DD") || ""
                : "",
            // new Date(value) && new Date(value._d) && value._d != "Invalid Date" ? new Date(value._d) : "",
          },
        },
      }));
    }

    if (
      !(Validation?.trim() === "" && value === null) &&
      !(
        moment(value).isValid() &&
        (FieldId.match(/Validity/i)
          ? moment(value).isSame(moment(), "day") ||
          moment(value).isAfter(moment())
          : true)
      )
    ) {
      if (state === "mainTemplateQuotation") {
        this.setState((prevState) => ({
          mainTemplateQuotation: {
            ...prevState.mainTemplateQuotation,
            [key]: {
              ...prevState.mainTemplateQuotation[key],
              [FieldId]: {
                ...prevState.mainTemplateQuotation[key][FieldId],
                error: true,
              },
            },
          },
        }));

        if (
          !this.state.errorArray.includes(
            this.state.mainTemplateQuotation[key][FieldId]["HTMLAttributeName"]
          )
        ) {
          this.setState((prevState) => ({
            errorArray: [
              ...prevState.errorArray,
              this.state.mainTemplateQuotation[key][FieldId][
              "HTMLAttributeName"
              ],
            ],
          }));
        }
      } else {
        this.setState((prevState) => ({
          [state]: {
            ...prevState[state],
            [FieldId]: {
              ...prevState[state][FieldId],
              error: true,
            },
          },
        }));
      }
    } else {
      if (state === "mainTemplateQuotation") {
        this.setState((prevState) => ({
          mainTemplateQuotation: {
            ...prevState.mainTemplateQuotation,
            [key]: {
              ...prevState.mainTemplateQuotation[key],
              [FieldId]: {
                ...prevState.mainTemplateQuotation[key][FieldId],
                error: false,
              },
            },
          },
        }));

        const index1 = this.state.errorArray.indexOf(
          this.state.mainTemplateQuotation[key][FieldId]["HTMLAttributeName"]
        );
        if (index1 !== -1) {
          this.setState((prevState) => ({
            errorArray: prevState.errorArray.filter((_, i) => i !== index1),
          }));
        }
      } else {
        this.setState((prevState) => ({
          [state]: {
            ...prevState[state],
            [FieldId]: {
              ...prevState[state][FieldId],
              error: false,
            },
          },
        }));
      }
    }
  };

  handleLocalVendorRecipintsOpen = () => {
    this.setState({
      vendorRecipintType: "additionalInfo",
      vendorRecipintOpen: true,
    });
  };

  handleIntlVendorRecipintsOpen = () => {
    this.setState({
      vendorRecipintType: "foreign",
      vendorRecipintOpen: true,
    });
  };

  handleVendorRecipintsClose = () => {
    this.setState({
      vendorRecipintOpen: false,
    });
  };

  handleLocalVendorTermsOpen = (term, currentFieldID) => {
    this.setState({
      vendorType: "additionalInfo",
      vendorTerms: term,
      vendorTermsOpen: true,
      currentFieldID,
    });
  };

  handleIntlVendorTermsOpen = (term, currentFieldID) => {
    this.setState({
      vendorType: "foreign",
      vendorTerms: term,
      vendorTermsOpen: true,
      currentFieldID,
    });
  };

  handleVendorTermsClose = () => {
    this.setState({
      vendorTermsOpen: false,
      currentFieldID: "",
    });
  };

  allItemsHasVendors = () => {
    let result = true;
    this.props.selectedItems.forEach((item) => {
      if (!item.vendor_count?.total_count) {
        result = false;
      }
    });
    return result;
  };
  handleRegret = async () => {
    // convert this to api and then get response to open pop up
    let data = {
      action: "offline_regret_form",
      perform: "open_regret_form",
      vendor_id: this.props.vendorId,
      guid: this.props?.lastTransactionGuidInThread,
    };
    //   const form = document.createElement("form");
    //   form.action = q_plus_screen;
    //   form.method = "post";
    //   for (const key in data) {
    //     const value = data[key]; // Extracting the first element of the array
    //     const hiddenInput = document.createElement("input");
    //     hiddenInput.type = "hidden";
    //     hiddenInput.name = key;
    //     hiddenInput.value = value;
    //     form.appendChild(hiddenInput);
    // }
    //   document.body.appendChild(form);
    //   form.submit();
    axios
      .post(q_plus_screen, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          // You can add any other headers here if needed
        },
      })
      .then((res) => {
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  handleQuotationSubmit = (event) => {
    let newErr = [];
    this.setState({
      rfqSubmitLoading: true,
    });

    Object.keys(this.state.mainTemplateQuotation).forEach((item, index) => {
      for (const key in this.state.mainTemplateQuotation[item]) {
        this.validateInput(key, index, true);
      }
    });

    Object.keys(this.state.additionalInfoTemplate).forEach((item, index) => {
      // const newErrEle = this.validateInputAdditionalInfo(item, index, true);
      // if (newErrEle && newErrEle.length > 0) {
      //   newErr.push(newErrEle);
      // }
      if (this.state.additionalInfoTemplate[item]?.VisibilityFlag === "Y") {
        this.validateInputAdditionalInfo(item, index, true);
      }
    });

    Object.keys(this.state.additionalInfoTemplate).forEach((item, index) => {
      if (this.state.additionalInfoTemplate[item]?.VisibilityFlag === "Y") {
        this.validateAdditionalInfo(item, index, true);
      }
    });

    const { dataFromQuotationDetails } = this.state;
    let errorMessageDate = "";
    let errorMessageNumber = "";
    let errorMessageReference = "";
    let errorMessageAttachment = "";
    const queryParams = new URLSearchParams(window.location.search);
    const supplier = parseInt(queryParams.get("supplier"), 10);
    if (dataFromQuotationDetails) {
      const {
        offlineQuotationDate,
        offlineRequoteDate,
        offlineRequoteNo,
        offlineQuotationNo,
        reference,
        attachment,
      } = dataFromQuotationDetails;

      // Validation for offlineRequoteNo and offlineQuotationNo is always enabled
      if (!(offlineRequoteNo || offlineQuotationNo)) {
        errorMessageNumber = `Please provide offline ${this?.props?.transactionType === "Inquiry" ? "Quotation" : "Requote"
          } Number.`;
      }

      // Validation for offlineQuotationDate, offlineRequoteDate, and reference is conditional
      if (supplier !== 1) {
        if (
          !(offlineQuotationDate || offlineRequoteDate) &&
          this.props.featurePermission.offline_quote_requote_date_and_attachment
            ?.feature_value === "Y"
        ) {
          errorMessageDate = "Please provide offline quotation/Requote Date.";
        }
        if (!(offlineRequoteNo || offlineQuotationNo)) {
          errorMessageNumber = `Please provide offline ${this?.props?.transactionType === "Inquiry" ? "Quotation" : "Requote"
            } Number.`;
        }
        if (
          (!attachment || attachment.length === 0) &&
          this.props.featurePermission.offline_quote_requote_date_and_attachment
            ?.feature_value === "Y"
        ) {
          errorMessageAttachment = "Please provide an attachment.";
        }

        if (!reference) {
          errorMessageReference = "Please provide Offline Reference.";
        }
      }
    }

    const { industryType } = this.state;
    if (
      !industryType &&
      this.props.featurePermission?.industry_type_mandatory?.feature_value ===
      "Y"
    ) {
      this.setState({
        industryTypeValidator: true,
        industryTypeErrorMessage: "Please provide Industry Type.",
        error: "Please provide Industry Type.",
      });
    }
    if (!this.state.msmeNumber) {
      this.setState({
        msmeValidator: true,
      });
    }

    const { selectedCurrency } = this.state;

    let message = "";
    const allSelectedN = Object.values(this.state.mainTemplateQuotation).every(
      (item) => item.selected === "N"
    );
    if (allSelectedN) {
      message = "Please select atleast one Product Category.";
    }
    if (
      (this.props.vendorType === "International" ||
        this.props.vendorType === "Local Importer") &&
      this.props.transactionType === "Inquiry" &&
      !selectedCurrency
    ) {
      this.setState({
        selectedCurrencyValidator: true,
        selectedCurrencyErrorMessage: "Please select currency from dropdown.",
        error: "Please select currency from dropdown.",
      });
    }

    this.setState({
      offlineDateError: errorMessageDate,
      offlineFieldError: errorMessageNumber,
      offlineReferenceError: errorMessageReference,
      offlineAttachment: errorMessageAttachment,
      error:
        errorMessageDate ||
        errorMessageNumber ||
        errorMessageReference ||
        errorMessageAttachment ||
        (!industryType &&
          this.props.featurePermission?.industry_type_mandatory?.feature_value ===
          "Y"
          ? "Please provide Industry Type."
          : "") ||
        // (industryType ? "" : "Please provide Industry Type.") ||
        ((this.props.vendorType === "International" ||
          this.props.vendorType === "Local Importer") &&
          this.props.transactionType === "Inquiry" &&
          !selectedCurrency
          ? "Please select currency from dropdown."
          : ""),
    });
    setTimeout(() => {
      console.log('check------')
      console.log(' message', message)
      console.log(' errorMessageDate', errorMessageDate)
      console.log(' errorMessageNumber', errorMessageNumber)
      console.log(' errorMessageReference', errorMessageReference)
      console.log(' errorMessageAttachment', errorMessageAttachment)
      console.log(' this.state.errorArray.length', this.state.errorArray.length, this.state.errorArray)
      console.log(' this.state.additionalInfoTemplate', this.state.additionalInfoTemplate)
      console.log(' newErr.length', newErr.length, newErr)
      console.log(' industryType', industryType)
      console.log(' this.props.featurePermission?.industry_type_mandatory?.feature_value', this.props.featurePermission?.industry_type_mandatory?.feature_value)
      console.log(' this.state.msmeNumber', this.state.msmeNumber)
      console.log(' this.props.vendorType', this.props.vendorType)
      console.log(' this.props.transactionType', this.props.transactionType)
      console.log(' selectedCurrency', selectedCurrency)
      if (
        message != "" ||
        errorMessageDate ||
        errorMessageNumber ||
        errorMessageReference ||
        errorMessageAttachment ||
        this.state.errorArray.length ||
        Object.keys(this.state.additionalInfoTemplate).some(
          (key) => this.state.additionalInfoTemplate[key].error === true
        ) ||
        newErr.length ||
        (!industryType &&
          this.props.featurePermission?.industry_type_mandatory
            ?.feature_value === "Y") ||
        (industryType === "MSME" && !this.state.msmeNumber) ||
        ((this.props.vendorType === "International" ||
          this.props.vendorType === "Local Importer") &&
          this.props.transactionType === "Inquiry" &&
          !selectedCurrency)
      ) {
        this.setState({
          rfqSubmitLoading: false,
        });
        this.props.openSnackbar(
          "error",
          `There are errors in your form. Please resolve in order to continue.\n${message}`
        );

        // this.props.openSnackbar(
        //   "error",
        //   "There are errors in your form. Please resolve in order to continue, " +"</br>" + message
        // );
        return false;
      }

      // Determine the confirmation message based on the status, vendor type, and selected currency
      const status = queryParams.get("supplier") ? "Online" : "Offline";
      let confirmAlertMsg = "Are you sure want to submit this Form?";

      // added by sonali as message was also display with local vendor 
      if (this.props.vendorType === "International" || this.props.vendorType === "Local Importer") {

        if (
          (status === "Offline" || status === "Online") &&
          (this.props.vendorType === "International" ||
            this.props.vendorType === "Local Importer") &&
          (this.state.selectedCurrency || this.props.quotedCurrency)
        ) {
          let txnType = this?.props?.transactionType === "Inquiry"
            ? "Quotation"
            : "Requote"
          confirmAlertMsg = `An ${status === "Offline" ? "Offline" : ""
            } ${txnType} will be submitted with ${this.state.selectedCurrency || this.props.quotedCurrency
            }. Are you sure you want to submit this ${status === "Offline" ? "Offline" : ""
            } ${txnType}?`;
        }

      }

      this.setState({
        confirmDialogOpen: true,
        confirmAlertTitle: "Submission",
        confirmAlertMsg: confirmAlertMsg,
      });
    }, 500);
  };

  rfqsubmitAPI = () => {
    const {
      RFQStatic,
      RFQDynamic,
      mainTemplateQuotation,
      additionalInfoTemplate,
      IntlTemplate,
    } = this.state;
    let finalData = {};
    finalData.product_id = this.props.selectedCategory.id;
    finalData.master_type = this.props.master_type;
    finalData.random_form_id = this.state.randomId;
    finalData.valid_date = `${moment(RFQStatic.RFQValidity.value).format(
      "YYYY-MM-DD"
    )} ${RFQStatic.RFQValidityTime.value}:00`;
    let display_valid_date = `${moment(RFQStatic.RFQValidity.value).format(
      "DD-MM-YYYY"
    )} ${RFQStatic.RFQValidityTime.value}:00`;
    if (this.props.rfqSettingValues?.projects) {
      finalData.project_ids = this.props.rfqSettingValues?.projects.map(
        (project) => project.id
      );
    }

    if (this.props.master_type === "category") {
      if (this.handleVendorType()) {
        finalData.shipment_mark = this.state.shipment_mark;
      }
    } else {
      if (this.vendorTypeExist("internation_count")) {
        finalData.shipment_mark = this.state.shipment_mark;
      }
    }

    finalData.technical_bid_enabled = this.props.includeTB ? "Y" : "N";

    if (this.props.includeTB) {
      finalData.valid_date_tb = `${moment(RFQStatic.TBValidity.value).format(
        "YYYY-MM-DD"
      )} ${RFQStatic.TBValidityTime.value}:00`;
      finalData.technical_bid_type = this.props.itemizeTB ? "item" : "category";
      finalData.daily_interval_reminder_checked_flag = this.props.remiderDrawer
        ?.includeReminderInterval
        ? "Y"
        : "N";
      finalData.tb_reminder_interval_days =
        this.props.remiderDrawer?.remiderDaysTB || 1;
      finalData.tbm_item_rfq_mapping_hourly = this.props.remiderDrawer
        ?.enableHourlyReminderTB
        ? "Y"
        : "N";
      finalData.tbm_item_rfq_mapping_hourly_interval =
        this.props.remiderDrawer?.hourlyReminderValueTB || 1;
      finalData.tbm_item_rfq_mapping_sms = this.props.remiderDrawer
        ?.sendSMSWithHourlyReminderTB
        ? "Y"
        : "N";
      finalData.hourly_tb_reminder_checked_flag = this.props.remiderDrawer
        ?.enableHourlyReminderTB
        ? "Y"
        : "N";

      if (this.props.itemizeTB) {
        if (Object.keys(this.props.checkTechnicalBidItems).length > 0) {
          let itemize_tb_data = [];
          Object.keys(this.props.checkTechnicalBidItems).forEach((key, i) => {
            if (parseInt(key) > 0) {
              itemize_tb_data.push({
                item_id: key,
                tb_template_id: this.props.checkTechnicalBidItems[key],
              });
            }
          });
          finalData.technical_bid_template = itemize_tb_data;
        }
      } else {
        if (Object.keys(this.props.checkTechinicalBidCategory).length > 0) {
          Object.keys(this.props.checkTechinicalBidCategory).forEach((key) => {
            finalData.technical_bid_template =
              this.props.checkTechinicalBidCategory[key];
          });
        }
      }
    }

    finalData.auto_inquiry_flag = "Y"; //na
    finalData.auto_inquiry_start = ""; //YYYY-MM-DD H:i:s //na
    finalData.auto_inquiry_end = ""; //YYYY-MM-DD H:i:s //na
    finalData.repetition_days = ""; // integer | digits:10 //na
    finalData.auto_inquiry_email_id = ""; //na
    finalData.auto_inquiry_sms_flag = ""; // Y/N //na
    finalData.auto_inquiry_validity = ""; // integer | digits:10 //na
    finalData.reminder_for_quote_flag = this.props.remiderDrawer?.startDate
      ? "Y"
      : "N"; // Y/N //Y if date is selected
    finalData.reminder_for_quote_start = this.props.remiderDrawer?.startDate
      ? moment(this.props.remiderDrawer.startDate).format("YYYY-MM-DD")
      : ""; // YYYY-MM-DD // startdate in reminder drawer
    finalData.reminder_repetition_days =
      this.props.remiderDrawer?.intervalDays || ""; // integer | digits:10 //reminder days intervals (int)
    finalData.reminder_for_quote_sms_flag = this.props.remiderDrawer?.sendSMS
      ? "Y"
      : "N"; // Y/N //send sms 1st remider
    finalData.reminder_for_quote_start_days = ""; // YYYY-MM-DD //to be checked
    finalData.template_id =
      this.props.quotationJsonTemplate?.PARAM[0]?.template_id;
    finalData.batch_id = this.props.quotationJsonTemplate?.PARAM[0]?.batch_id; // batch id of selected template
    finalData.hourly_reminders = this.props.remiderDrawer?.enableHourlyReminder
      ? "20"
      : "10"; // 10 / 20 // enable horly in reminder
    finalData.hourly_reminders_value =
      this.props.remiderDrawer?.hourlyReminderValue; // 10 / 20 // enable horly in reminder
    finalData.hourly_reminders_sms = this.props.remiderDrawer
      ?.sendSMSWithHourlyReminder
      ? "20"
      : "10"; // 10 / 20 // send sms enable horly in reminder
    finalData.cc_user_enable_flag = RFQStatic.RFQUpdateCCUsersCheck.value
      ? "Y"
      : "N"; // Y / N // update cc user master
    finalData.cc_user_email_id = RFQStatic.RFQCCUsersmails.value; //cc user textarea
    if (
      this.props.accountModulePermission["Contract Rfq"]?.module_activated ===
      "Y"
    ) {
      finalData.rfq_contract_flag = RFQStatic.RFQContactCheck.value
        ? "20"
        : "10"; // 10 / 20 //rfq cehck in last
    }
    let tempDisplayOptions = this.props.displayOptions;
    let tempRfqSettingValues = this.props.rfqSettingValues;
    finalData.multiple_solution_flag = tempDisplayOptions.allowed_slab
      ? tempRfqSettingValues.includeSlab
        ? "Y"
        : "N"
      : tempRfqSettingValues?.allowMultipleSolution
        ? "Y"
        : "N"; // Y / N //allow multiple solution check in setting
    finalData.sealed_rfq = RFQStatic.lock ? "20" : "10"; // 10 / 20 //lock btn
    finalData.transaction_type = "Inquiry"; // 10 / 20 //lock btn
    finalData.transaction_status = "Inquiry"; // 10 / 20 //lock btn
    finalData.inquiry_rules_tb_auto_approval_flag = "N"; // in case of pr RFQ it might be Y
    finalData.multiplant_permission_flag = this.props.rfqSettingValues
      ?.allowMultiPlant
      ? "Y"
      : "N";
    finalData.is_payment_term = this.props.rfqSettingValues
      ?.vendorMasterPaymentTerm
      ? "Y"
      : "N"; // Y / N //in setting
    finalData.is_inco_term = this.props.rfqSettingValues?.vendorMasterIncoTerm
      ? "Y"
      : "N"; // Y / N //in setting
    finalData.rfq_payment_identifier =
      finalData.is_payment_term == "Y"
        ? this.props.companyInfo?.payment_term_identifier
        : ""; //forward from comInfo
    finalData.rfq_inco_identifier =
      finalData.is_inco_term == "Y"
        ? this.props.companyInfo?.inco_term_identifier
        : ""; //forward from comInfo
    if (
      this.props.rfqSettingValues?.basicRate &&
      this.props.rfqSettingValues?.landedCost
    ) {
      finalData.display_rank_to_vendor_on = "30";
    } else if (this.props.rfqSettingValues?.landedCost) {
      finalData.display_rank_to_vendor_on = "10";
    } else if (this.props.rfqSettingValues?.basicRate) {
      finalData.display_rank_to_vendor_on = "20";
    } else {
      finalData.display_rank_to_vendor_on = "40";
    }
    finalData.is_priority = RFQStatic.star ? "Y" : "N"; // Y / N //star btn

    finalData.specification_files = {
      category: [],
      item: {},
    };
    //add final data if slab in included from rfq settings
    if (
      tempDisplayOptions.hasOwnProperty("allowed_slab") &&
      tempRfqSettingValues.includeSlab
    ) {
      finalData.slab = {};
    }
    if (this.props.categorySpecFiles.length > 0) {
      this.props.categorySpecFiles.forEach((file, i) => {
        finalData.specification_files["category"].push(file.id);
      });
    }

    if (Object.keys(this.props.itemSpecificFiles).length > 0) {
      Object.keys(this.props.itemSpecificFiles).forEach((itemNo, i) => {
        if (this.props.itemSpecificFiles[itemNo].length > 0) {
          finalData.specification_files.item[itemNo] = [];
          this.props.itemSpecificFiles[itemNo].forEach((file, i) => {
            finalData.specification_files.item[itemNo].push(file.id);
          });
        }
      });
    }

    let dynamic_fields = {};
    let itemize = _.cloneDeep(mainTemplateQuotation);
    let common = _.cloneDeep(RFQDynamic);
    for (const key in itemize) {
      const element = itemize[key]; //every item
      for (const key2 in element) {
        const element2 = element[key2]; //every field
        if (key2 !== "slab") {
          for (const key3 in element2) {
            // const element3 = element2[key3];
            if (
              key3 !== "HTMLAttributeName" &&
              key3 !== "value" &&
              key3 !== "Validation" &&
              !key3.includes("upd_budget_master")
            ) {
              if (typeof element2 === "object") {
                if (element2["ElementType"] === "DT") {
                  const DateObj = element2["value"];
                  const momentObj = moment(DateObj, "DD/MM/YYYY");
                  element2["value"] =
                    DateObj != ""
                      ? moment(element2["value"]).isValid()
                        ? moment(element2["value"]).format("YYYY-MM-DD")
                        : momentObj.format("YYYY-MM-DD")
                      : "";
                }

                if (element2["Identifier"] === "EstimatedValue") {
                  element2["value"] =
                    parseFloat(element["rate"] ? element["rate"] : 0) *
                    parseFloat(element["qty"] ? element["qty"] : 0);
                }
                delete element2[key3];
              }
            }
          }
        } else {
          if (this.state.slab[key].isOpen) {
            //for sending old id in slab
            let currentItem = this.props.selectedItems.find((x) => x.id == key);
            let oldId = currentItem.old_id;
            finalData.slab[oldId] = element2.map((obj, i) => {
              let value = "";
              if (i == element2.length - 1) {
                value = `${obj.start} ${obj.end}`;
              } else {
                value = `${obj.start}-${obj.end}`;
              }
              return value;
            });
            delete element[key2];
          } else {
            delete element[key2];
          }
        }
      }
    }
    dynamic_fields.itemize = itemize;

    if (this.props.master_type === "category") {
      if (this.handleVendorType("additionalInfo")) {
        let additionalInfo = _.cloneDeep(additionalInfoTemplate);
        for (const key in additionalInfo) {
          const element = additionalInfo[key];
          for (const key2 in element) {
            if (
              key2 !== "HTMLAttributeName" &&
              key2 !== "value" &&
              key2 !== "Validation"
            ) {
              delete element[key2];
            }
          }
        }
        dynamic_fields.additionalInfo = additionalInfo;
      }

      if (this.handleVendorType()) {
        let foreign = _.cloneDeep(IntlTemplate);
        for (const key in foreign) {
          const element = foreign[key];
          for (const key2 in element) {
            if (
              key2 !== "HTMLAttributeName" &&
              key2 !== "value" &&
              key2 !== "Validation"
            ) {
              delete element[key2];
            }
          }
        }
        dynamic_fields.foreign = foreign;
      }
    } else {
      if (this.vendorTypeExist("local_count")) {
        let additionalInfo = _.cloneDeep(additionalInfoTemplate);
        for (const key in additionalInfo) {
          const element = additionalInfo[key];
          for (const key2 in element) {
            if (
              key2 !== "HTMLAttributeName" &&
              key2 !== "value" &&
              key2 !== "Identifier" &&
              key2 !== "Validation"
            ) {
              delete element[key2];
            }
          }
        }
        additionalInfo["PaymentType"] = this.state.localPaymentFields[0];
        additionalInfo["IncoType"] = this.state.localIncoFields[0];
        dynamic_fields.additionalInfo = additionalInfo;
      }

      if (this.vendorTypeExist("internation_count")) {
        let foreign = _.cloneDeep(IntlTemplate);
        for (const key in foreign) {
          const element = foreign[key];
          for (const key2 in element) {
            if (
              key2 !== "HTMLAttributeName" &&
              key2 !== "value" &&
              key2 !== "Identifier" &&
              key2 !== "Validation"
            ) {
              delete element[key2];
            }
          }
        }
        foreign["PaymentType"] = this.state.intlPaymentFields[0];
        foreign["IncoType"] = this.state.intlIncoFields[0];
        dynamic_fields.foreign = foreign;
      }
    }

    for (const key in common) {
      const element = common[key];
      if (element.ElementType === "D") {
        element["value"] = this.findFormulaValues(element, itemize);
      }
      //convert multiplant array to ### saperated string
      if (
        element.ElementType === "S" &&
        element.Identifier === "Destination" &&
        this.props.rfqSettingValues.allowMultiPlant
      ) {
        element["value"] = element["value"].join("###");
      }
      for (const key2 in element) {
        if (
          key2 !== "HTMLAttributeName" &&
          key2 !== "value" &&
          key2 !== "Validation"
        ) {
          delete element[key2];
        }
      }
    }
    dynamic_fields.common = common;

    let vendors = [];
    this.props.selectedVendors?.forEach((vendor) => {
      vendors.push(vendor.id);
    });

    finalData.dynamic_fields = dynamic_fields;
    if (this.props.master_type === "category") {
      finalData.vendors = vendors;
    }
    if (this.props.master_type !== "category") {
      finalData.items = this.props.selectedItems?.map((item) => item.id);
    }
    let submitApi = rfqSubmit;
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;
    this.toggleLoaderArray("rfqSubmit");
    let pr_ids_array = [];
    let pr_line_items_details = [];
    if (this.props.location?.pathname.indexOf("/prcombination") > -1) {
      finalData.inquiry_rules_tb_auto_approval_flag = "N";
      if (this.props.selectedPrCatagory?.auto_approval_flag === "Y") {
        finalData.inquiry_rules_tb_auto_approval_flag = "Y";
      }
      finalData.pr_has_tb_flag = 0;
      if (this.props.selectedPrCatagory?.tb_flag === 1) {
        finalData.pr_has_tb_flag = 1;
      }
      finalData.prs = {};

      this.props.selectedPrCatagory.items.forEach((item, i) => {
        finalData.prs[item.id] = item.prlineitems_ids;
        pr_line_items_details = [
          ...pr_line_items_details,
          ...this.props.prlineitems[item.id],
        ];
        pr_ids_array = [...pr_ids_array, ...item.prlineitems_ids];
      });

      if (
        finalData.master_type === "item" &&
        this.props.accountModulePermission[
          "Plant Specific Categroy Item Vendor Linking"
        ]?.module_activated === "Y"
      ) {
        finalData.plant = this.props.selectedPrCatagory.plant?.code;
      }
      submitApi = prRfqSubmit;
    }
    axios
      .post(submitApi, finalData)
      .then((res) => {
        this.setState({
          rfqSubmitLoading: false,
        });
        this.props.setPrRfqs({
          inquiry_id: res.data.inquiry_id,
          rfqNo: res.data.rfq_number,
          category: this.props.selectedPrCatagory.category,
          plant: this.props.selectedPrCatagory.plant,
          valid_date: display_valid_date,
          pr_line_items: pr_line_items_details,
          index: this.props.disableIndex,
        });
        // if(this.props.prplants.length){
        //   let formData = new FormData();
        //   formData.append('sessionID',this.props.sessionId);
        //   formData.append('inquriy_id',res.data.inquiry_id);
        //   axios.post(insertPrRfq,formData,{
        //     headers: []
        //   });
        // }
        if (
          this.props.prplants.length &&
          this.props.accountModulePermission["PR Status Integration"]
            ?.module_activated === "Y"
        ) {
          axios
            .get(
              pr_status_integration(
                this.props.userInfo?.division_id,
                this.props.companyInfo.business_gr_master_id,
                res.data.inquiry_id,
                pr_ids_array.join()
              )
            )
            .then((response) => { })
            .catch((err) => {
              //alert("error");
            });
        }
        axios.post(modify_edit_qcs_values(res.data.inquiry_id));
        this.toggleLoaderArray("rfqSubmit");

        if (res.data.mailBody) {
          delete axios.defaults.headers.common["Authorization"];
          let formData = new FormData();

          Object.keys(res.data.mailBody).forEach((key) => {
            formData.append(key, res.data.mailBody[key]);
          });
          formData.append("sessionID", this.props.sessionId);
          formData.append("q", this.props.sessionId);

          axios
            .post(sendMailUri, formData)
            .then(
              () =>
              (axios.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${this.props.sessionId}`)
            )
            .catch((err) => {
              axios.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${this.props.sessionId}`;
            });
        }
        this.handleRFQSubmitDialogOpen(res.data.rfq_number);
        //adding values of first submitted rfq in store
        // if(Object.keys(this.props.defaultValues).length === 0){
        //   const {includeTB,itemizeTB,master_type,rfqSettingValues,remiderDrawer,template,isAllVendorSelected}=this.props
        //   let payload={
        //     // includeTB:includeTB,
        //     // itemizeTB:itemizeTB,
        //     vendorsType:master_type,
        //     RFQStatic: this.state.RFQStatic,
        //     RFQDynamic:this.state.RFQDynamic,
        //     rfqSettingValues:rfqSettingValues,
        //     remiderDrawer:remiderDrawer,
        //     template:template,
        //     isAllVendorSelected:isAllVendorSelected,
        //   }
        //   this.props.setDefaultValues(payload);
        // }
      })
      .catch((err) => {
        this.setState({
          rfqSubmitLoading: false,
        });
        this.toggleLoaderArray("rfqSubmit");
        if (err.response) {
          if (err.response?.data?.validation_errors) {
            let errorObj = err.response.data.validation_errors;
            let errorMsg = "";
            for (const key in errorObj) {
              errorObj[key].forEach((msg) => {
                errorMsg =
                  errorMsg +
                  " " +
                  `${typeof msg === "object" ? msg.error : msg}`;
              });
            }
            this.props.openSnackbar("error", errorMsg);
          } else if (err.response.status === 500) {
            this.props.openSnackbar(
              "error",
              "Internal Server Error. Please try again later."
            );
          } else if (err.response.status === 503) {
            this.props.openSnackbar(
              "error",
              "Service temporarily unavailable. Please try again later."
            );
          } else {
            this.props.openSnackbar("error", `Something went wrong!`);
          }
        } else {
          this.props.openSnackbar("error", "Network Error");
        }

      });
  };

  findFormulaValues = (element, itemize) => {
    let hiddenLabelValue = 0;
    let itemFormula = element["value"].Formula;
    for (const key in itemize) {
      let object = itemize[key];
      for (const key1 in object) {
        if (itemFormula.includes(key1)) {
          hiddenLabelValue = hiddenLabelValue + Number(object[key1].value);
        }
      }
    }
    return hiddenLabelValue;
  };

  handleUplaod = (index, res) => {
    const { mainTemplateQuotation } = this.state;
    mainTemplateQuotation[this.props.selectedItems[index].id]["specification"] =
      res;
    this.setState({
      mainTemplateQuotation,
    });
  };

  handleRFQSubmitDialogOpen = (msg) => {
    this.setState({
      RFQSubmitDialogOpen: true,
      rfqSubmitMsg: msg,
    });
  };

  RFQSubmitDialogOnClose = () => {
    if (this.props.location?.pathname.indexOf("/prcombination") === -1) {
      this.props.resetSettingDrawer();
      this.props.resetReminderSettings();
      this.setState({
        RFQSubmitDialogOpen: false,
      });
      this.props.handleTBAction(
        "vendxRating",
        null, // itemId
        null, // oldItemId
        0,
        0
      );
    } else {
      this.setState({
        RFQSubmitDialogOpen: false,
      });
      this.props.setPRselectedChange();
    }
  };
  handleApplyToAllClick = (FieldId, textbox = false, Identifier) => {
    // let firstRowItemId = 0;
    // let updated = false;
    // for only selected start
    let firstRowItemId = null;
    let updated = false;
    const mainTemplateQuotationSet = new Set(
      Object.keys(this.state.mainTemplateQuotation)
    );
    let filteredTransactionItemSequence =
      this.props.transactionItemSequence.filter((item) =>
        mainTemplateQuotationSet.has(item)
      );
    // Object.keys(this.state.mainTemplateQuotation)
    // this.props.transactionItemSequence.forEach((itemids) => {
    filteredTransactionItemSequence.forEach((itemids) => {
      const isSelected =
        this.state.mainTemplateQuotation[itemids].selected === "Y";
      const inquiryItemsIdLen =
        this.props.inquiryItemsId.length > 0
          ? this.props.inquiryItemsId.includes(itemids - 0)
          : true;

      if (!updated && isSelected && inquiryItemsIdLen) {
        firstRowItemId = itemids;
        updated = true;
        return;
      }
    });

    // for only selected end
    if (!this.state.mainTemplateQuotation[firstRowItemId][FieldId]["value"]) {
      this.props.openSnackbar(
        "error",
        "please enter the value first handleApplyToAllClick "
      );
      return;
    }
    if (this.state.mainTemplateQuotation[firstRowItemId][FieldId]["error"]) {
      this.props.openSnackbar("error", "please reslove error first");
      return;
    }
    // Object.keys(this.state.mainTemplateQuotation).forEach((itemids) => {
    // this.props.transactionItemSequence.forEach((itemids) => {
    filteredTransactionItemSequence.forEach((itemids) => {
      let FieldIndex = this.state.errorArray.indexOf(
        this.state.mainTemplateQuotation[itemids][FieldId]["HTMLAttributeName"]
      );
      if (FieldIndex !== -1) {
        this.state.errorArray.splice(FieldIndex, 1);
      }
      // for only selected start
      if (this.state.mainTemplateQuotation[itemids].selected === "Y") {
        this.setState((prevState) => ({
          ...prevState,
          mainTemplateQuotation: {
            ...prevState.mainTemplateQuotation,
            [itemids]: {
              ...prevState.mainTemplateQuotation[itemids],
              [FieldId]: {
                ...prevState.mainTemplateQuotation[itemids][FieldId],
                error: false,
                value:
                  this.state.mainTemplateQuotation[firstRowItemId][FieldId][
                  "value"
                  ],
              },
            },
          },
        }));

        if (Identifier === "ProductQuantity") {
          // then set rate for all
          this.setState((prevState) => ({
            ...prevState,
            mainTemplateQuotation: {
              ...prevState.mainTemplateQuotation,
              [itemids]: {
                ...prevState.mainTemplateQuotation[itemids],
                qty: this.state.mainTemplateQuotation[firstRowItemId]["qty"],
              },
            },
          }));
        }
        if (
          Identifier === "Budgetedrate" ||
          Identifier === "CIFRate" ||
          Identifier === "rate" ||
          Identifier === "Rate"
        ) {
          // then set rate for all
          this.setState((prevState) => ({
            ...prevState,
            mainTemplateQuotation: {
              ...prevState.mainTemplateQuotation,
              [itemids]: {
                ...prevState.mainTemplateQuotation[itemids],
                rate: this.state.mainTemplateQuotation[firstRowItemId]["rate"],
              },
            },
            // for only selected end
          }));
        }
      }
    });

    this.setState({
      applyToAll: {
        ...this.state.applyToAll,
        [`${FieldId}_applyToAll`]:
          !this.state.applyToAll[`${FieldId}_applyToAll`],
      },
    });
  };

  handleVendorType = (type) => {
    return type == "additionalInfo"
      ? this.props.selectedVendors?.some(
        (e) =>
          e.country?.toUpperCase() ===
          this.props.accountlevelInfo?.country?.toUpperCase()
      )
      : this.props.selectedVendors?.some(
        (e) =>
          e.country?.toUpperCase() !==
          this.props.accountlevelInfo?.country?.toUpperCase()
      );
  };

  vendorTypeExist = (type) => {
    // internation_count
    // local_count
    let result = false;
    this.props.selectedItems.forEach((item) => {
      if (item?.vendor_count?.[type]) {
        result = true;
      }
    });
    return result;
  };

  fetchTermsAndCondtions = () => {
    this.toggleLoaderArray("TermsAndCondtions");
    let newTermsAndConditions = this.props.termsAndConditions;
    let categoryId = this.props.selectedCategory?.id;
    if (
      Object.keys(newTermsAndConditions).length !== 0 &&
      newTermsAndConditions[categoryId]
    ) {
      this.setState({
        termsAndConditionsData: newTermsAndConditions[categoryId],
      });
      this.toggleLoaderArray("TermsAndCondtions");
    } else {
      const url = getTermsAndConditions(categoryId);
      axios
        .get(url)
        .then((res) => {
          this.setState({
            termsAndConditionsData: res.data?.data,
          });
          this.props.setTermsAndConditions({ [categoryId]: res.data?.data });
          this.toggleLoaderArray("TermsAndCondtions");
        })
        .catch((err) => {
          console.log(err, "getfilespecs error");
          this.toggleLoaderArray("TermsAndCondtions");
        });
    }
  };

  getDestinationValue = () => {
    for (const key in this.state.additionalInfoTemplate) {
      const element = this.state.additionalInfoTemplate[key];
      if (
        element.Identifier === "Destination" ||
        element.Identifier === "DestinationForeign"
      ) {
        return element.value;
      }
    }
  };
  // getDestinationValue = () => {
  //   for (const key in this.state.RFQDynamic) {
  //     const element = this.state.RFQDynamic[key];
  //     if (
  //       element.Identifier === "Destination" ||
  //       element.Identifier === "DestinationForeign"
  //     ) {
  //       return element.value;
  //     }
  //   }
  // };

  handleUpdateInMasterClick = (item_id, FieldId) => {
    this.setState({
      mainTemplateQuotation: {
        ...this.state.mainTemplateQuotation,
        [item_id]: {
          ...this.state.mainTemplateQuotation[item_id],
          [FieldId]: {
            ...this.state.mainTemplateQuotation[item_id][FieldId],
            [`upd_budget_master_${item_id}`]:
              !this.state.mainTemplateQuotation[item_id][FieldId][
              `upd_budget_master_${item_id}`
              ],
          },
        },
      },
    });
  };

  updateDeliveryScheduleFieldValue = (value, item_id, FieldId) => {
    this.setState((prevState) => ({
      ...prevState,
      mainTemplateQuotation: {
        ...prevState.mainTemplateQuotation,
        [item_id]: {
          ...prevState.mainTemplateQuotation[item_id],
          [FieldId]: {
            ...prevState.mainTemplateQuotation[item_id]?.[FieldId],
            value,
          },
        },
      },
    }));
  };

  // Define sendMail function outside of quotationSubmitAPI
  sendMail = async (payloadData, responseData) => {
    const queryParams = new URLSearchParams(window.location.search);

    const formData = new FormData();

    // formData.append("vendx_action", "send_mail");
    // formData.append("file_path", filePath);

    // Sonali :: Start

    formData.append("ReverseAuction", "n");
    formData.append("userid", this.props.userInfo.user_id); // UserID = Who submit the quote/requote
    formData.append("buyerid", this.props.userInfo.division_id); // Buyer ID
    formData.append("LinkedBusinessGrID", this.props.userInfo.company_id); //Company ID

    formData.append("prevtranguid", this.props.CurrentTransaction.guid); // Guid Column value of TransactionLog table
    formData.append("activity_amend_mail", ""); // if service RFQ than 1 else blank
    formData.append("max_item_version_view", ""); // if service RFQ than 1 else blank
    formData.append("vendor_amended_item_ref", ""); // if service RFQ than 1 else blank
    formData.append("InquiryID", this.props.inquiryId); // inquiry.rowid
    formData.append("inquiry_id", this.props.inquiryId); // inquiry.rowid
    formData.append("VendorID", this.props.vendorId); // VendorID
    formData.append(
      "PrevTranNo",
      this.props.CurrentTransaction.no || this.props.selectedQuotedItems.ref_no
    ); // quote/requote  no or inquiry no
    formData.append(
      "SenderName",
      this.props.selectedQuotedItems.vendors[0].name
    ); // vendor code
    formData.append(
      "SenderCode",
      this.props.selectedQuotedItems.vendors[0].code
    ); // vendor name
    formData.append(
      "productid",
      this.props.selectedQuotedItems.categories[0].id
    ); // Category ID/Product ID

    formData.append(
      "LocationFlag",
      this.props.vendorType == "Local" ? "L" : "I"
    ); // L OR I

    formData.append(
      "local_currency",
      this.props.selectedQuotedItems.vendors[0].quoted_currency ||
      this.props.selectedQuotedItems.currency
    ); // Quotation currency

    if (queryParams.get("supplier")) {
      // vendor / Online
      formData.append("OfflineTransaction", "N"); // Y if online submitted else N
    } else {
      formData.append("OfflineTransaction", "Y"); // Y if online submitted else N
    }
    formData.append("RecepientIdList", 1); // Receiver ID -Vendor id if online quote else buyer id
    formData.append("RecepientName", this.props.accountlevelInfo.company_name); // account name
    formData.append("cdn_path", HOSTMAP); // domain
    formData.append("GroupRfqId", this.props.selectedQuotedItems.group_id); // group rfq no / when rfq generated from PR else blank

    // from submit response
    formData.append("TransactionGuid", responseData.mail_data.TransactionGUID); // latest value TransactionGuid Column value of TransactionLog table
    formData.append("latestTransGUID", responseData.mail_data.latestTransGUID);

    formData.append("NewTranNo", responseData.mail_data.NewTranNo); // quotation/requote ref no
    formData.append("TranTypeGuid", responseData.mail_data.TranTypeGuid); // latest value TransactionLog.TranTypeGUID
    if (responseData.mail_data.pr_flag == true) {
      // true
      formData.append("pr_flag", 1); // if rfq floated from PR than 1 else blank
    } else {
      // false
      formData.append("pr_flag", ""); // if rfq floated from PR than 1 else blank
    }

    formData.append("prevtranid", responseData.mail_data.prevtranid); // Value of TranTypeID  TranType = Inquiry TranStatus = Inquiry
    formData.append("master_type", responseData.mail_data.masterType); // category / product
    // from submit response

    // Payload
    formData.append("newtranstype", payloadData.tran_status); // Quotation|Requote
    if (queryParams.get("supplier")) {
      // vendor / Online
      formData.append("NewTranStatus", payloadData.tran_status); // 1. Offline Quotation 2. Quotation
    } else {
      formData.append("NewTranStatus", `Offline ${payloadData.tran_status}`); // 1. Offline Quotation 2. Quotation
    }

    formData.append(
      "datepicker",
      payloadData.validity_date
      // moment(payloadData.validity_date).format("YYYY-MM-DD")
    ); // Quote/Requote - validity date

    // Sonali :: END

    this.setState({
      quotationSubmitLoading: false,
      quotationSubmitSuccess: true, // Reset success state
    });
    try {
      const response = await axios.post(sendMailDataWriter, formData);
      // Handle the response data as needed
      // if (response.data?.file_path) {
      //   const formDataCgi = new FormData();

      //   formDataCgi.append("vendx_action", "send_mail");
      //   formDataCgi.append("file_path", response.data?.file_path);
      //   // const responseCgi = await axios.post(sendMailUriQuotation, formDataCgi);
      //   // this.setState({
      //   //   quotationSubmitLoading: false,
      //   //   quotationSubmitSuccess: true, // Reset success state
      //   // });
      // } else {
      //   console.log("sendMailDataWriter responseErr", response);
      // }
      // console.log("Mail response", response.data);
    } catch (error) {
      console.error("Error sending mail:", error);
    }
  };
  getSumOfField = (data, fieldId) => {
    let sum = 0;
    for (let key in data) {
      if (data[key][fieldId]) {
        sum += parseFloat(data[key][fieldId].value);
      }
    }
    return sum;
  };
  arrangedEvalByKey = (formula, key, result, identifier) => {
    if (typeof formula != "undefined") {
      let pattern = /f(\d+)/g;
      const matches = [...formula.matchAll(pattern)];
      const matchedIdentifiersFormula = matches.map((match) => match[1]);
      matchedIdentifiersFormula.forEach((formulaIdentifier) => {
        if (result?.hasOwnProperty(formulaIdentifier)) {
          formula = formula.replace(
            `f${formulaIdentifier}`,
            result[formulaIdentifier]
              ? !isNaN(parseFloat(result[formulaIdentifier]))
                ? parseFloat(result[formulaIdentifier])
                : `"${result[formulaIdentifier]}"`
              : 0
          );
        } else if (
          this.state.additionalInfoTemplate.hasOwnProperty(formulaIdentifier)
        ) {
          if (
            typeof this.state.additionalInfoTemplate[formulaIdentifier]
              ?.value === "string" &&
            this.state.additionalInfoTemplate[
              formulaIdentifier
            ]?.value.includes("###")
          ) {
            formula = formula.replace(
              `f${formulaIdentifier}`,
              `"${this.state.additionalInfoTemplate[
                formulaIdentifier
              ]?.value.split("###")[0]
              }"`
            );
          } else {
            formula = formula.replace(
              `f${formulaIdentifier}`,
              this.state.additionalInfoTemplate[formulaIdentifier]?.value
                ? !isNaN(
                  parseFloat(
                    this.state.additionalInfoTemplate[formulaIdentifier]
                      ?.value
                  )
                )
                  ? parseFloat(
                    this.state.additionalInfoTemplate[formulaIdentifier]
                      ?.value
                  )
                  : `"${this.state.additionalInfoTemplate[formulaIdentifier]?.value}"`
                : 0
            );
          }
        } else {
          if (
            !this.state.mainTemplateQuotation[key].hasOwnProperty(
              formulaIdentifier
            )
          ) {
            let defValue = this.state.allItemsTemplate.find((item) => {
              return item.FieldId == formulaIdentifier;
            });
            if (
              defValue &&
              defValue?.ElementType != "D" &&
              !defValue.DefaultValue.hasOwnProperty("Formula") &&
              !defValue.DefaultValue.includes("(f")
            ) {
              formula = formula.replace(
                `f${formulaIdentifier}`,
                parseFloat(defValue.DefaultValue) || 0
              );
            }
          } else {
            if (
              typeof this.state.mainTemplateQuotation[key]?.[formulaIdentifier]
                ?.value === "string" &&
              this.state.mainTemplateQuotation[key]?.[
                formulaIdentifier
              ]?.value?.includes("###")
            ) {
              formula = formula.replace(
                `f${formulaIdentifier}`,
                `"${this.state.mainTemplateQuotation[key]?.[
                  formulaIdentifier
                ]?.value.split("###")[0]
                }"`
              );
            } else {
              if (
                identifier == "TotalReqQty" ||
                identifier == "TotalReqQtyFor"
              ) {
                const sumOfFieldQty = this.getSumOfField(
                  this.state.mainTemplateQuotation,
                  formulaIdentifier
                );
                formula = formula.replace(
                  `f${formulaIdentifier}`,
                  sumOfFieldQty
                );
              } else {
                let replaceValue = this.state.mainTemplateQuotation[key]?.[
                  formulaIdentifier
                ]?.value
                  ? !isNaN(
                    parseFloat(
                      this.state.mainTemplateQuotation[key]?.[
                        formulaIdentifier
                      ]?.value
                    )
                  ) &&
                    isFinite(
                      this.state.mainTemplateQuotation[key]?.[formulaIdentifier]
                        ?.value
                    )
                    ? parseFloat(
                      this.state.mainTemplateQuotation[key]?.[
                        formulaIdentifier
                      ]?.value
                    )
                    : `"${this.state.mainTemplateQuotation[key]?.[formulaIdentifier]?.value}"`
                  : 0;
                formula = formula.replace(
                  `f${formulaIdentifier}`,
                  replaceValue
                );
              }
            }
          }
        }
      });
      return formula;
    }
  };
  arrangedEval = (formula, key, result, identifier) => {
    if (typeof formula != "undefined") {
      let pattern = /f(\d+)/g;
      const matches = [...formula.matchAll(pattern)];
      const matchedIdentifiersFormula = matches.map((match) => match[1]);
      matchedIdentifiersFormula.forEach((formulaIdentifier) => {
        if (result.hasOwnProperty(formulaIdentifier)) {
          formula = formula.replace(
            `f${formulaIdentifier}`,
            result[formulaIdentifier]
              ? !isNaN(parseFloat(result[formulaIdentifier]))
                ? parseFloat(result[formulaIdentifier])
                : `"${result[formulaIdentifier]}"`
              : 0
          );
        } else if (
          this.state.additionalInfoTemplate.hasOwnProperty(formulaIdentifier)
        ) {
          if (
            typeof this.state.additionalInfoTemplate[formulaIdentifier]
              ?.value === "string" &&
            this.state.additionalInfoTemplate[
              formulaIdentifier
            ]?.value.includes("###")
          ) {
            formula = formula.replace(
              `f${formulaIdentifier}`,
              `"${this.state.additionalInfoTemplate[
                formulaIdentifier
              ]?.value.split("###")[0]
              }"`
            );
          } else {
            formula = formula.replace(
              `f${formulaIdentifier}`,
              this.state.additionalInfoTemplate[formulaIdentifier]?.value
                ? !isNaN(
                  parseFloat(
                    this.state.additionalInfoTemplate[formulaIdentifier]
                      ?.value
                  )
                )
                  ? parseFloat(
                    this.state.additionalInfoTemplate[formulaIdentifier]
                      ?.value
                  )
                  : `"${this.state.additionalInfoTemplate[formulaIdentifier]?.value}"`
                : 0
            );
          }
        } else {
          if (
            !this.state.mainTemplateQuotation[key]?.hasOwnProperty(
              formulaIdentifier
            )
          ) {
            let defValue = this.state.allItemsTemplate.find((item) => {
              return item.FieldId == formulaIdentifier;
            });
            if (
              defValue &&
              defValue?.ElementType != "D" &&
              !defValue.DefaultValue.hasOwnProperty("Formula") &&
              !defValue.DefaultValue.includes("(f")
            ) {
              formula = formula.replace(
                `f${formulaIdentifier}`,
                parseFloat(defValue?.DefaultValue) || 0
              );
            }
          } else {
            if (
              typeof this.state.mainTemplateQuotation[key]?.[formulaIdentifier]
                ?.value === "string" &&
              this.state.mainTemplateQuotation[key]?.[
                formulaIdentifier
              ]?.value?.includes("###")
            ) {
              formula = formula.replace(
                `f${formulaIdentifier}`,
                `"${this.state.mainTemplateQuotation[key]?.[
                  formulaIdentifier
                ]?.value.split("###")[0]
                }"`
              );
            } else {
              if (
                identifier == "TotalReqQty" ||
                identifier == "TotalReqQtyFor"
              ) {
                const sumOfFieldQty = this.getSumOfField(
                  this.state.mainTemplateQuotation,
                  formulaIdentifier
                );
                formula = formula.replace(
                  `f${formulaIdentifier}`,
                  sumOfFieldQty
                );
              } else {
                let replaceValue = this.state.mainTemplateQuotation[key]?.[
                  formulaIdentifier
                ]?.value
                  ? !isNaN(
                    parseFloat(
                      this.state.mainTemplateQuotation[key]?.[
                        formulaIdentifier
                      ]?.value
                    )
                  ) &&
                    isFinite(
                      this.state.mainTemplateQuotation[key]?.[formulaIdentifier]
                        ?.value
                    )
                    ? parseFloat(
                      this.state.mainTemplateQuotation[key]?.[
                        formulaIdentifier
                      ]?.value
                    )
                    : `"${this.state.mainTemplateQuotation[key]?.[formulaIdentifier]?.value}"`
                  : 0;
                formula = formula.replace(
                  `f${formulaIdentifier}`,
                  replaceValue
                );
              }
            }
          }
        }
      });
      return formula;
    }
  };
  sumFieldValue = (data, fieldId) =>
    Object.values(data)
      .map(item => parseFloat(item[fieldId]) || 0)
      .reduce((acc, value) => acc + value, 0);

  prepareQuotationPayload = () => {
    let {
      additionalInfoTemplate,
      mainTemplateQuotation,
      msmainTemplateQuotation,
      remainTemplateQuotation,
    } = this.state;
    const { lastTransactionData, quotationJsonTemplate } = this.props;
    if (this.props.itemSpecific) {
      mainTemplateQuotation = {
        ...mainTemplateQuotation,
        ...this.state.notFilteredMainTemplateQuotation,
      };
    }
    let finalRemainingQuotation = {};
    let finalRemainingCommonQuotation = {};

    // Prepare itemize object
    let item_id_arr =
      this.props.inquiryItemsId.length > 0
        ? this.props.inquiryItemsId
        : Object.keys(mainTemplateQuotation);
    // item_id_arr.forEach((item_id) => {
    let EvalResultWithItem = {}
    this.props.transactionItemSequence.forEach((item_id) => {
      let arr = [];
      let obj = {};
      let key = item_id;
      let EvalResults = {};
      this.state.rearrangedArray.forEach((defaultIdentifier) => {
        let formula = defaultIdentifier.DefaultValue.Formula;
        let newFormula = this.arrangedEval(
          formula,
          key,
          EvalResults,
          defaultIdentifier.Identifier
        );
        if (
          newFormula &&
          newFormula.includes("ExchangeRate_SelectedCurrency")
        ) {
          let exchangeRate = this.props.currencyOptions.find(
            (currency) =>
              currency.Currency ==
              (this.state.selectedCurrency || this.props.quotedCurrency)
          );
          if (exchangeRate) {
            newFormula = newFormula.replace(
              /ExchangeRate_SelectedCurrency/g,
              exchangeRate.ExchangeRate
            );
          }
        }

        if (
          newFormula &&
          newFormula.includes("ExchangeRate_BuyerLocalCurrency")
        ) {
          let exchangeRate = this.props.currencyOptions.find(
            (currency) =>
              currency.Currency === (this.props.currencyDetails || "INR")
          );
          if (exchangeRate) {
            newFormula = newFormula.replace(
              /ExchangeRate_BuyerLocalCurrency/g,
              exchangeRate.ExchangeRate
            );
          }
        }

        if (newFormula && newFormula.includes("(f")) {
          const match = newFormula.match(/f\d+/)[0];
          newFormula = newFormula.replace(match, 0);
        }
        // Now set the property
        if (!EvalResultWithItem[item_id]) {
          EvalResultWithItem[item_id] = {}
        }

        EvalResultWithItem[item_id][defaultIdentifier.FieldId] = parseFloat(
          eval(newFormula)
        ).toFixed(3);
        EvalResults[defaultIdentifier.FieldId] = parseFloat(
          eval(newFormula)
        ).toFixed(3);
      });

      Object.entries(this.props.quotationJsonTemplate.ITEMIZE[0]).forEach(
        (value, index) => {
          let field_id = value[1]?.FieldId;
          if (!obj[key]) {
            obj[key] = [];
            // obj[key][0] = {};
          }
          if (this.props.itemBoxId !== field_id) {
            if (
              this.state.switchState[key] &&
              this.props.multipleSolutionFlag &&
              msmainTemplateQuotation[key]
            ) {
              if (!obj[key][0]) {
                obj[key][0] = {};
              }
              // Object.entries(msmainTemplateQuotation[key]).forEach(
              //   ([rowindex, rowdata]) => {
              //     let msmaintemp = rowdata["mainData"];
              //     if (!obj[key][rowindex]) {
              //       obj[key][rowindex] = {};
              //     }
              //     if (msmaintemp[field_id]) {
              //       obj[key][rowindex][field_id] =
              //         msmaintemp[field_id]?.value ||
              //         // mainTemp[field_id]?.DefaultValue ||
              //         "";
              //       arr.push(field_id);
              //       return;
              //     }
              //   }
              // );
              Object.entries(msmainTemplateQuotation[key]).forEach(
                ([rowindex, rowdata]) => {
                  let msmaintemp = rowdata["mainData"];
                  if (!obj[key][rowindex + 1]) {
                    obj[key][rowindex + 1] = {};
                  }
                  if (msmaintemp[field_id]) {
                    obj[key][rowindex + 1][field_id] =
                      msmaintemp[field_id]?.value ||
                      // mainTemp[field_id]?.DefaultValue ||
                      "";
                    arr.push(field_id);
                    return;
                  }
                }
              );
            } else {
              if (!obj[key][0]) {
                obj[key][0] = {};
              }
              Object.entries(mainTemplateQuotation).forEach(
                ([itemId, mainTemp]) => {
                  if (mainTemp[field_id] && itemId === key) {
                    obj[key][0][field_id] =
                      mainTemp[field_id]?.value ||
                      // mainTemp[field_id]?.DefaultValue ||
                      "";
                    arr.push(field_id);
                    return;
                  }
                }
              );
            }
          }
        }
      );
      obj[key].forEach((rowdata, rowindex) => {
        Object.entries(quotationJsonTemplate.ITEMIZE[0]).forEach(
          ([index, currentField]) => {
            // if (
            // this.state.mainTemplateQuotation[key].hasOwnProperty(
            //   currentField.FieldId
            // ) &&
            // currentField.Identifier != "Product" &&
            // currentField.ElementType != "M"
            // ) {
            if (
              this.state.mainTemplateQuotation &&
              this.state.mainTemplateQuotation[key] &&
              this.state.mainTemplateQuotation[key].hasOwnProperty(
                currentField.FieldId
              ) &&
              currentField.Identifier !== "Product" &&
              currentField.ElementType !== "M"
            ) {
              // if (this.state.mainTemplateQuotation[key]?.selected === "N") {
              //   obj[key][rowindex][currentField.FieldId] = ""

              // } else {

              if (currentField.ElementType === "D") {
                obj[key][rowindex][currentField.FieldId] =
                  EvalResults[currentField.FieldId];
              } else {
                obj[key][rowindex][currentField.FieldId] =
                  this.state.mainTemplateQuotation[key][
                    currentField.FieldId
                  ]?.value;
              }
              // }
            } else {
              // else {

              if (arr.indexOf(currentField.FieldId) === -1) {
                let formula =
                  currentField.DefaultFormula ||
                  currentField.DefaultValue.Formula;
                if (this.props.itemBoxId !== currentField.FieldId) {
                  // if (this.state.mainTemplateQuotation[key]?.selected === "N") {
                  //   obj[key][rowindex][currentField.FieldId] = ""
                  // } else {

                  let value = 0;

                  if (formula && formula !== "") {
                    let placeholders = formula.match(/\(f\d+\)/g);
                    if (placeholders) {
                      try {
                        if (
                          currentField.ElementType === "D" &&
                          this.state.mainTemplateQuotation[key]?.selected ===
                          "Y"
                        ) {
                          if (
                            EvalResults.hasOwnProperty(currentField.FieldId)
                          ) {
                            value = EvalResults[currentField.FieldId];
                          }
                        } else {
                          let defaultFormula = currentField.DefaultFormula;
                          if (defaultFormula) {
                            const formattedResult =
                              this.extractAndFormat(defaultFormula);

                            if (
                              formattedResult &&
                              formattedResult.length >= 1
                            ) {
                              const valuesFound = formattedResult
                                .map((identifier, index) => {
                                  const attributeKey = identifier.split(".");
                                  const subStringAttrKey =
                                    attributeKey[1].substring(1);
                                  // Check if the solution array exists and has the identifier
                                  const valuesInSolution =
                                    lastTransactionData.itemize[key]?.solution
                                      ?.map(
                                        (obj) =>
                                          obj?.data[0]?.[subStringAttrKey]
                                      )
                                      ?.filter((value) => value !== undefined);

                                  // Fallback to checking in common if valuesInSolution is empty or undefined
                                  const fallbackValue =
                                    lastTransactionData.common?.[
                                    subStringAttrKey
                                    ];
                                  const fetchTxn = this.props.fetchedTxn?.[
                                    attributeKey[0]
                                  ]?.itemize[key]?.solution
                                    ?.map(
                                      (obj) => obj?.data[0]?.[subStringAttrKey]
                                    )
                                    ?.filter((value) => value !== undefined);
                                  if (
                                    valuesInSolution &&
                                    valuesInSolution.length > 0
                                  ) {
                                    return valuesInSolution;
                                  } else if (
                                    valuesInSolution &&
                                    valuesInSolution.length == 0 &&
                                    fetchTxn &&
                                    fetchTxn.length > 0
                                  ) {
                                    return fetchTxn;
                                  } else if (fallbackValue !== undefined) {
                                    return [fallbackValue];
                                  } else {
                                    return [];
                                  }
                                })
                                .flat();

                              let finalValue = null;
                              // Evaluate conditional statements in DefaultFormula
                              for (let i = 0; i < valuesFound.length; i++) {
                                if (valuesFound[i] !== null) {
                                  finalValue = valuesFound[i];
                                  break;
                                }
                              }
                              value = finalValue;
                            }
                          }
                        }
                      } catch (error) {
                        console.log("error in eval", error);
                      }
                    }
                  } else {
                    if (typeof currentField.DefaultValue == "object") {
                      value = 0;
                    } else {
                      value = currentField.DefaultValue || 0;
                    }
                  }
                  obj[key][rowindex][currentField.FieldId] = value || 0;
                  // }
                }
              }
              // }
            }
          }
        );
        if (!finalRemainingQuotation[key]) {
          finalRemainingQuotation[key] = [];
          finalRemainingQuotation[key][rowindex] = {};
        }
        finalRemainingQuotation[key][rowindex] = obj[key][rowindex];
      });
    });
    const common = {};
    const commonDElemnts = {};
    Object.keys(additionalInfoTemplate).map((fieldId, index) => {
      let fieldValue = additionalInfoTemplate[fieldId]?.value;
      if (additionalInfoTemplate[fieldId].VisibilityFlag === "Y") {
        const isAttachmentField =
          additionalInfoTemplate[fieldId].Identifier === "Attachment" ||
          additionalInfoTemplate[fieldId].Identifier === "AttachmentForeign";

        if (isAttachmentField) {
          if (Array.isArray(fieldValue)) {
            common[fieldId] = fieldValue.length > 0 ? fieldValue : "";
          } else {
            common[fieldId] = "";
          }
        } else {
          common[fieldId] = fieldValue != null ? fieldValue : "";
        }
      } else {
        let commonDelements = {}
        if (additionalInfoTemplate[fieldId]?.ElementType === "D") {
          if (
            additionalInfoTemplate[fieldId].Identifier == "TotalReqQty" ||
            additionalInfoTemplate[fieldId].Identifier == "TotalReqQtyFor"
          ) {
            let exactIndex = Object.keys(this.state.mainTemplateQuotation)[0]
            common[fieldId] = EvalResultWithItem[exactIndex][fieldId]
          } else {
            if (additionalInfoTemplate[fieldId].DefaultValue.hasOwnProperty("Formula") && additionalInfoTemplate[fieldId].DefaultValue.Formula != "") {
              let pattern = /f(\d+)/g;
              let formula =
                additionalInfoTemplate[fieldId].DefaultValue?.Formula;
              const matches = [...formula.matchAll(pattern)];
              const matchedIdentifiersFormula = matches.map(
                (match) => match[1]
              );
              matchedIdentifiersFormula.forEach((formulaIdentifier) => {
                let FromItemize = this.state.allItemsTemplate.find((field) => {
                  return (
                    field.FieldId === formulaIdentifier &&
                    field.HTMLAttributeName.includes("_")
                  );
                });
                if (FromItemize) {
                  if (FromItemize?.ElementType === "D") {

                    const sum = this.sumFieldValue(
                      EvalResultWithItem,
                      formulaIdentifier
                    );
                    formula = formula.replace(
                      `f${formulaIdentifier}`,
                      sum
                        ? !isNaN(parseFloat(sum))
                          ? parseFloat(sum)
                          : `"${sum}"`
                        : 0
                    );
                  } else {
                    const sumOfFieldQty = this.getSumOfField(
                      mainTemplateQuotation,
                      formulaIdentifier
                    );
                    formula = formula.replace(
                      `f${formulaIdentifier}`,
                      sumOfFieldQty
                    );
                  }
                } else if (commonDElemnts.hasOwnProperty(formulaIdentifier)) {
                  formula = formula.replace(
                    `f${formulaIdentifier}`,
                    parseFloat(commonDElemnts[formulaIdentifier])
                  );
                } else {
                  let exactIndex = Object.keys(this.state.mainTemplateQuotation)[0]
                  let replacedValue = EvalResultWithItem[exactIndex][formulaIdentifier]
                  if (EvalResultWithItem[exactIndex].hasOwnProperty(formulaIdentifier)) {
                    formula = formula.replace(
                      `f${formulaIdentifier}`,
                      replacedValue
                        ? !isNaN(
                          parseFloat(
                            replacedValue
                          )
                        )
                          ? parseFloat(
                            replacedValue
                          )
                          : `"${replacedValue
                          }"`
                        : 0
                    );
                  } else {
                    formula = formula.replace(
                      `f${formulaIdentifier}`,
                      this.state.additionalInfoTemplate[formulaIdentifier]?.value
                        ? !isNaN(
                          parseFloat(
                            this.state.additionalInfoTemplate[formulaIdentifier]?.value
                          )
                        )
                          ? parseFloat(
                            this.state.additionalInfoTemplate[formulaIdentifier]?.value
                          )
                          : `"${this.state.additionalInfoTemplate[formulaIdentifier]?.value
                          }"`
                        : 0
                    );
                  }

                }
              });
              common[fieldId] = parseFloat(
                eval(formula)
              ).toFixed(3);
              commonDElemnts[fieldId] = parseFloat(
                eval(formula)
              ).toFixed(3)
            } else {
              if (typeof additionalInfoTemplate[fieldId].DefaultValue == "object") {
                common[fieldId] = 0;
              } else {
                common[fieldId] = additionalInfoTemplate[fieldId].DefaultValue || 0;
              }
            }
          }
        } else {
          common[fieldId] = fieldValue != null ? fieldValue : "";
        }
      }
    });

    // for itemize field
    const itemize = {};
    // for (const itemId in finalRemainingQuotation) {
    this.props.transactionItemSequence.forEach((itemId) => {
      let solution = [];
      for (const rowindex in finalRemainingQuotation[itemId]) {
        let obj = {
          // currency: item.currency,
          data: [finalRemainingQuotation[itemId][rowindex]],
        };
        solution.push(obj);
      }
      if (mainTemplateQuotation.hasOwnProperty(itemId)) {
        const item = mainTemplateQuotation[itemId];
        const itemData = {
          selected: item.selected,
          formdetailRowId: item.formdetailRowId,
          itemBoxId: this.props.itemBoxId,
          solution: solution,
        };
        itemize[itemId] = itemData;
      }
      // }
    });
    // Construct payload
    const queryParams = new URLSearchParams(window.location.search);
    const supplier = parseInt(queryParams.get("supplier"), 10);
    const quotationDate = dayjs(
      this?.state?.dataFromQuotationDetails?.offlineQuotationDate ||
      this?.state?.dataFromQuotationDetails?.offlineRequoteDate
    );

    const formattedDate = quotationDate.isValid()
      ? quotationDate.format("YYYY-MM-DD HH:mm:ss IST")
      : null;

    // Use formattedDate wherever needed
    const payload = {
      inquiry_id: this.props.inquiryId,
      quotation_number:
        this?.state?.dataFromQuotationDetails?.offlineRequoteNo ||
        this?.state?.dataFromQuotationDetails?.offlineQuotationNo,
      validity_date: dayjs(
        this?.state?.dataFromQuotationDetails?.requoteValidity ||
        this?.state?.dataFromQuotationDetails?.quotationValidity
      ).format("YYYY-MM-DD HH:mm:ss IST"),
      tran_status:
        this?.props?.transactionType === "Inquiry" ? "Quotation" : "Requote",
      // currency:
      //   this.props.vendorType === "Local"
      //     ? this.props.transactionType === "Inquiry"
      //       ? this.props.currencyDetails
      //       : this.props.quotedCurrency || this.props.currencyDetails
      //     : this.props.transactionType === "Inquiry"
      //       ? this.state.selectedCurrency
      //       : this.props.quotedCurrency || this.props.currencyDetails,
      currency: this.state.selectedCurrency || this.props.quotedCurrency,
      terms_and_conditions_text: "",
      location_flag: this.props.vendorType === "Local" ? "L" : "I",
      shipment_mark: this.props.CurrentTransaction.shipment_mark || "",
      template: {
        itemize: itemize,
        common: common,
      },
      ...(supplier !== 1 && {
        offline: {
          reference: this?.state?.dataFromQuotationDetails?.reference,
          vendor_id: this.props.vendorId,
          vendor_quotation_date: dayjs(
            this?.state?.dataFromQuotationDetails?.offlineQuotationDate ||
            this?.state?.dataFromQuotationDetails?.offlineRequoteDate
          ).format("YYYY-MM-DD HH:mm:ss IST"),
          vendor_quotation_date: formattedDate,
          attachment: this?.state?.dataFromQuotationDetails?.attachment,
        },
      }),
    };
    return payload;
  };

  quotationSubmitAPI = async () => {
    const formData = this.prepareQuotationPayload();
    try {
      this.setState({
        quotationSubmitLoading: true,
        // quotationSubmitSuccess: false, // Reset success state
      });
      const response = await axios.post(quotationSubmit, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      this.setState({
        // quotationSubmitLoading: false,
        // quotationSubmitSuccess: true,
        quotationSubmitSuccessMessage: response.data.message,
      });
      if (response.data.status === "success") {
        // if (this.state.industryType === "MSME") {
        const formData = {
          vendorId: this.props.vendorId,
          type: this.state.industryType,
          value:
            this.state.industryType === "MSME" ? this.state.msmeNumber : "",
        };
        axios
          .patch(industry_type, formData, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.props.newSessionID}`,
            },
          })
          .then((response) => {
          })
          .catch((error) => {
            console.error(
              "There was a problem with your axios operation:",
              error
            );
          });
        // }

        //send mail function here
        // this.sendMail(response.data.path);
        // Send mail function here

        try {
          await this.sendMail(this.prepareQuotationPayload(), response.data);
        } catch (error) {
          console.error("Error sending mail:", error);
        }
      }
    } catch (error) {
      // catch (error) {
      //   // Handle error
      //   console.error("Error submitting quotation:", error);
      //   // Turn off loading state
      //   this.setState({
      //     quotationSubmitLoading: false,
      //     quotationSubmitSuccess: false,
      //     quotationSubmitErrorMessage:
      //       "Failed to submit quotation. Please try again later.",
      //   });
      //   this.props.openSnackbar(
      //     "error",
      //     error.response.data?.message
      //       ? error.response.data?.message
      //       : "Something went wrong!"
      //   );
      // }

      // Handle error
      console.error("Error submitting quotation:", error);
      // Turn off loading state
      this.setState({
        quotationSubmitLoading: false,
        quotationSubmitSuccess: false,
        quotationSubmitErrorMessage:
          "Failed to submit quotation. Please try again later.",
      });

      // Extract error messages from the response
      let errorMessages = [];
      // if (error.response && error.response.data && error.response.data.error) {
      //   const errors = error.response.data.error || error.response.data.message;
      //   console.log("errors", errors);
      //   errorMessages = Object.keys(errors).map((key) => errors[key].join(" "));
      // }
      if (error?.response && error?.response?.data && error?.response?.data?.error) {
        const errors = error?.response?.data?.error;
        errorMessages = Object.keys(errors).map((key) => {
          if (Array.isArray(errors[key])) {
            return errors[key].join(" ");
          } else {
            return errors; // or handle it appropriately if it's not an array
          }
        });
      }

      // Fallback error message
      if (errorMessages.length === 0) {
        errorMessages.push(
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : "Something went wrong!"
        );
      }

      // Show the error messages in MUI Snackbar
      errorMessages.forEach((message) => {
        this.props.openSnackbar("error", message);
      });
    }
  };

  handleCloseDestinationDialog() {
    this.setState({ isDestinationDetailsDialogOpen: false });
  }

  handleOpenDestinationDialog() {
    this.setState({ isDestinationDetailsDialogOpen: true });
  }

  setIsDestinationDetailsDialogOpen(isOpen) {
    this.setState({ isDestinationDetailsDialogOpen: isOpen });
  }
  disabledProps(disabledItems) {
    // console.log("disabledItems", disabledItems);
    this.setState({ disabledProps: disabledItems })
  }

  setRearrangedTemplate(data) {
    this.setState({ rearrangedArray: data });
  }

  setItemsTemplate(data) {
    this.setState({ allItemsTemplate: data });
  }

  render() {
    return (
      <React.Fragment>
        <Backdrop
          style={{ color: "#fff", zIndex: "10000000000" }}
          open={
            this.state.loaderArray.length > 0 ||
            this.state.prevRFQLoading ||
            this.props.jsonTemplateloading ||
            this.state.quotationSubmitLoading ||
            this.state.attachmentLoading
          }
        >
          <CircularProgress style={{ color: "#fff" }} />
        </Backdrop>
        <React.Fragment>
          <form
            id="novalidatedform"
            noValidate
            autoComplete="off"
            onSubmit={(event) => {
              if (
                this.props.location?.pathname.indexOf("/prcombination") > -1
              ) {
                event.preventDefault();
              }
            }}
          >
            {/* -----------------------Quotation Details----------------------- */}
            {/* <div className="template_local_recipients"> */}
            <Box mb={2} id="QuotationDetails">
              <QuotationDetailsSection
                title="Quotation Details"
                data={this.props.selectedQuotedItems}
                categoryId={this.props.categoryId}
                Alreadyexistingfiles={this.props.categorySpecFiles}
                getField={this.getFieldLocal}
                foreignData={this?.props?.quotationJsonTemplate?.COMMON?.concat(
                  this?.props?.quotationJsonTemplate?.FOREIGN
                )}
                itemizeData={this.state.mainTemplateQuotation}
                headerLabels={this.props?.headerLabels}
                commonData={this?.props?.quotationJsonTemplate?.COMMON}
                localData={this?.props?.quotationJsonTemplate?.COMMON?.concat(
                  this?.props?.quotationJsonTemplate?.LOCAL
                )}
                currencyOptions={this.props.currencyOptions}
                currencyDetails={this.props.currencyDetails}
                fileSpecificationData={this.props.fileSpecificationData}
                handleCurrencyChange={this.handleCurrencyChange}
                vendorType={this.props.vendorType}
                lastTransactionTypeInThread={
                  this.props.lastTransactionTypeInThread
                }
                setAttachmentsValue={(value) => {
                  this.setAttachmentsValue(value);
                }}
                dataFromQuotationDetails={this.setDataFromQuotationChild}
                offlineDateError={this.state.offlineDateError}
                offlineAttachmentError={this.state.offlineAttachment}
                offlineFieldError={this.state.offlineFieldError}
                offlineReferenceError={this.state.offlineReferenceError}
                featurePermissionDetails={this.props.featurePermission}
                industryDetails={this.props.industryDetails}
                industryType={this.state.industryType}
                msmeNumber={this.state.msmeNumber}
                onIndustryTypeChange={this.handleIndustryTypeChange}
                onMsmeNumberChange={this.handleMsmeNumberChange}
                industryTypeValidator={this.state.industryTypeValidator}
                selectedCurrencyValidator={this.state.selectedCurrencyValidator}
                msmeValidator={this.state.msmeValidator}
                industryTypeErrorMessage={this.state.industryTypeErrorMessage}
                selectedCurrencyErrorMessage={
                  this.state.selectedCurrencyErrorMessage
                }
                selectedCurrency={this.state.selectedCurrency}
                additionalInfo={this.state.additionalInfoTemplate}
                inquiryId={this.props.inquiryId}
                CurrentTransaction={this.props.CurrentTransaction}
                setquotationImported={(value) => {
                  this.setQuotationImport(value);
                }}
                quotedCurrency={this.props.quotedCurrency}
                transactionItemSequence={this.props.transactionItemSequence}
                allItemsTemplate={this.state.allItemsTemplate}
                base_currency={this.props.currencyDetails}
                PermissionInquiry={this.props.PermissionInquiry}
                disabledProps={this.state.disabledProps}
              />
            </Box>
            {/* </div> */}

            {/* -----------------------Template Section----------------------- */}
            <Box mb={2} id="Template">
              <QuotationFormSectionTable
                inquiryId={this.props.inquiryId}
                randomId={this.state.randomId}
                additionalInfoTemplate={this.state.additionalInfoTemplate}
                categoryId={this.props.categoryId}
                batch_id={
                  this.props.quotationJsonTemplate?.PARAM &&
                  this.props.quotationJsonTemplate?.PARAM[0]?.batch_id
                }
                currencyOptions={this.props.currencyOptions}
                msmainTemplateQuotation={this.state.msmainTemplateQuotation}
                data={this.props.quotationJsonTemplate?.ITEMIZE || []}
                commonData={this?.props?.quotationJsonTemplate?.COMMON || []}
                mainTemplateQuotation={this.state.mainTemplateQuotation}
                handleOpenDestinationDialog={this.handleOpenDestinationDialog}
                setIsDestinationDetailsDialogOpen={
                  this.setIsDestinationDetailsDialogOpen
                }
                setRearrangedTemplate={this.setRearrangedTemplate}
                setItemsTemplate={this.setItemsTemplate}
                selectedQuotedItems={this.props.selectedQuotedItems.items}
                // base_currency={this.props.accountlevelInfo?.base_currency}
                base_currency={this.props.currencyDetails}
                handleUplaod={this.handleUplaod}
                applyToAll={this.state.applyToAll}
                handleApplyToAllClick={this.handleApplyToAllClick}
                handleDateChange={this.handleDateChange}
                handleUpdateInMasterClick={this.handleUpdateInMasterClick}
                handleChange={this.handleItemizeInputChange}
                handleMSData={this.handleMSData}
                handleCheckboxChange={this.handleCheckboxChange}
                handleSwitchBoxChange={this.handleSwitchBoxChange}
                default_dropdown_type={
                  this.props.companyInfo?.default_dropdown_type
                }
                destination={this.getDestinationValue()}
                updateDeliveryScheduleFieldValue={
                  this.updateDeliveryScheduleFieldValue
                }
                itemSpecificFiles={this.props.itemSpecificFiles}
                LATEST={this.LATEST}
                handleSlab={this.handleSlab}
                // slab={this.state.slab}
                slab={slab}
                // rfqSettingValues={this.props.rfqSettingValues}
                // rfqSettingValues={rfqSettingValues}
                // slabRows={this.state.slabRows}
                slabAddRow={this.slabAddRow}
                slabRemoveRow={this.slabRemoveRow}
                handleRowInput={this.handleRowInput}
                handleApplyToAllSlab={this.handleApplyToAllSlab}
                handleKey={this.handleKey}
                slabKey={this.state.slabKey}
                applyToAllSlab={this.state.applyToAllSlab}
                firstActiveSlab={this.props.selectedItems?.find((element) => {
                  if (
                    this.state?.slab[element?.id] &&
                    this.state?.slab[element?.id].isOpen
                  ) {
                    return element;
                  }
                })}
                displayOptions={this.props.displayOptions}
                selectedCurrency={this.state.selectedCurrency}
                lastTransactionInThread={this.props.lastTransactionInThread}
                lastTransactionTypeInThread={
                  this.props.lastTransactionTypeInThread
                }
                filteredSelectedNotData={this.props.filteredSelectedNotData}
                vendorType={this.props.vendorType}
                currencyDetails={this.props.currencyDetails}
                items={this.props.items}
                inquiryItemsId={this.props.inquiryItemsId}
                itemSpecific={this.props.itemSpecific}
                multipleSolutionFlag={this.props.multipleSolutionFlag}
                multiplePlantsFlag={this.props.multiplePlantsFlag}
                toggleSwitch={this.toggleSwitch}
                switchState={this.state.switchState}
                errorArray={this.state.errorArray}
                quotedCurrency={this.props.quotedCurrency}
                transactionItemSequence={this.props.transactionItemSequence}
                template={this.props.quotationJsonTemplate}
                EvalResultsKeyWise={this.state.EvalResults}
                disabledProps={this.disabledProps}
              />
            </Box>
            {/*----------------------- Additional Info -----------------------*/}
            <>
              <Box mb={2} id="AdditionalInfo">
                <QuotationCardTemplate
                  title="Additional Info"
                  state={this?.state?.lastTransactionData?.common}
                  foreignData={this?.props?.quotationJsonTemplate?.COMMON?.concat(
                    this?.props?.quotationJsonTemplate?.FOREIGN
                  )}
                  localData={this?.props?.quotationJsonTemplate?.COMMON?.concat(
                    this?.props?.quotationJsonTemplate?.LOCAL
                  )}
                  MaterialDescription={this.state.MaterialDescription}
                  materialDescriptionAll={this.download}
                  getField={this.getFieldLocal}
                  handleChange={this.handleLocalChange}
                  vendorType={this.props.vendorType}
                  categoryId={this.props.categoryId}
                  fileSpecificationData={this.props.fileSpecificationData}
                  CurrentTransaction={this.props.CurrentTransaction}
                  multiplePlantsFlag={this.props.multiplePlantsFlag}
                  category={this.props.selectedQuotedItems.categories[0]}
                />
              </Box>

              {/* -----------------------Submit Quotation Form Button----------------------- */}

              <Box className="Footer-QuotedForm" sx={{ mb: 2 }}>
                <FormGroup
                  sx={{
                    display: "inline-flex",
                    "& .MuiTypography-root": {
                      color: "var(--primary)",
                      textDecoration: "underline",
                      fontWeight: "500",
                    },
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.quotationChecked}
                        onChange={(event) =>
                          this.handleQuotationCheckboxChange(event)
                        }
                        color="primary"
                      />
                    }
                    label={
                      <Typography
                        variant="subtitle1"
                        component="a"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            tandcDialogOpen: true,
                          });
                        }}
                      >
                        Accept Terms and Conditions
                      </Typography>
                    }
                  />
                </FormGroup>
                {/* {this.props.selectedQuotedItems._internal.current_user_id && ( */}
                {/* <div style={{ display: "flex", alignItems: "center" }}>
                  <Checkbox
                    checked={this.state.quotationChecked}
                    onChange={(event) =>
                      this.handleQuotationCheckboxChange(event)
                    }
                    color="primary"
                  />
                  <Typography
                    onClick={() => {
                      this.setState({
                        tandcDialogOpen: true,
                      });
                    }}
                    variant="body2"
                    style={{
                      color: "blue",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    Accept Terms and Conditions
                  </Typography>
                </div> */}
                {/* )} */}
                <Stack direction="row" spacing={2} mt={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    className="Upload_file_btn"
                    onClick={(event) => this.handleQuotationSubmit(event)}
                    disabled={!this.state.quotationChecked}
                  >
                    Submit{" "}
                    {this?.props?.transactionType === "Inquiry"
                      ? "Quotation"
                      : "Requote"}
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    sx={{ visibility: "hidden" }}
                  >
                    Regret
                  </Button>
                </Stack>
              </Box>
            </>
          </form>
        </React.Fragment>

        {/* Modals */}

        {/* Additional Info Specification */}
        {this.state.specificationsOpen && (
          <UploadFileDialog
            product_id={this.props.selectedCategory?.id}
            randomId={this.state.randomId}
            master_type="category"
            handleUplaod={this.handleUplaod}
            open={this.state.specificationsOpen}
            Alreadyexistingfiles={this.props.categorySpecFiles}
            updateCategorySpecificationFiles={(newFiles) => {
              this.setState({
                categorySpecificationFiles: newFiles,
              });
            }}
            handleClose={() => {
              this.setState({
                specificationsOpen: false,
              });
            }}
          />
        )}

        {/* Additional Info Attachment */}

        {(this.state.attachments !== null ||
          this.state.attachments !== undefined) &&
          this.state.dynamicFileUploadOpen && (
            <UploadDynamicQuotation
              sessionID={this.props.sessionId}
              currentFieldId={this.state.currentFieldId}
              uploadedFiles={
                this.state[this.state.currentTemplate] &&
                this.state[this.state.currentTemplate][
                this.state.currentFieldId
                ] &&
                this.state[this.state.currentTemplate][
                this.state.currentFieldId
                ]["value"]
              }
              handleUploadedFileDelete={(index) => {
                let temp = this.state[this.state.currentTemplate];
                temp[this.state.currentFieldId]["value"].splice(index, 1);
                this.setState({ [this.state.currentTemplate]: temp });
              }}
              setAttachmentsValue={(value) => {
                this.setAttachmentsValue(value);
              }}
              randomId={this.state.randomId}
              open={this.state.dynamicFileUploadOpen}
              handleClose={() => {
                this.setState({ dynamicFileUploadOpen: false });
              }}
              uploadedFilesAttachment={this.state.attachments} // Pass attachments here
              categoryId={this.props.categoryId}
            />
          )}

        {/* Terms & Conditions Modal */}
        {this.state.tandcDialogOpen && (
          <TermsAndConditions
            category_id={this.props.categoryId}
            open={this.state.tandcDialogOpen}
            termsAndConditionsData={this.props.termsAndConditionsDetails}
            handleClose={() => {
              this.setState({
                tandcDialogOpen: false,
              });
            }}
          />
        )}

        {this.state.fileSpecificationsOpen && (
          <UploadDynamicView
            Loading={this.state.fileSpecLoading}
            title={
              this.state.fileAttachmentName
                ? this.state.fileAttachmentName
                : "View Transaction Specification"
            }
            uploadedFiles={this.state.specification?.data}
            open={this.state.fileSpecificationsOpen}
            handleClose={this.handleUploadClose}
          />
        )}

        <Dialog
          scroll="body"
          maxWidth={"sm"}
          open={this.state.quotationSubmitSuccess}
          aria-labelledby="responsive-dialog-title"
          sx={(theme) => ({
            "&.MuiDialog-root .MuiPaper-root": {
              width: "500px",
              boxSizing: "border-box",
              [theme.breakpoints.down("sm")]: {
                width: "100%",
              },
            },
          })}
        >
          {/* <DialogTitle id="responsive-dialog-title">
            <IconButton
              sx={{ color: (theme) => theme.palette.bluegrey[500], ml: "auto" }}
              onClick={() => {
                this.setState({
                  quotationSubmitSuccess: false,
                });
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle> */}
          <DialogContent>
            <DialogContentText>
              <div className="linkcategorydialog_main">
                <Grid container>
                  <Grid
                    item
                    lg={12}
                    xl={12}
                    md={12}
                    sm={12}
                    className="text-center"
                  >
                    <img src={successimage} alt="Category Link Icon" />
                    <>
                      <h3 className="success_title">Successfully</h3>
                      <p className="success_subtitle">
                        {this.state.quotationSubmitSuccessMessage}
                      </p>
                      {/* <p className="success_subtitle">
                         RFQ No {this.props.rfq_no}
                      </p> */}
                    </>
                  </Grid>
                </Grid>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button
              variant="contained"
              color="primary"
              // autoFocus
              onClick={(event) => {
                this.setState({
                  quotationSubmitSuccess: false,
                });
                const queryParams = new URLSearchParams(window.location.search);

                if (queryParams.get("supplier")) {
                  window.top.location.reload();
                }
                window.parent.postMessage("Quotation_close_iframe", "*");
                event.preventDefault();
              }}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>

        {/* Quotation Form Submit Confirmation */}
        <ConfirmAlert
          open={this.state.confirmDialogOpen}
          handleClose={() => {
            this.setState({
              confirmDialogOpen: false,
            });
          }}
          handleSubmit={() => {
            this.setState({
              confirmDialogOpen: false,
            });
            this.quotationSubmitAPI();
          }}
          msg={this.state.confirmAlertMsg}
          title={this.state.confirmAlertTitle}
        />

        <Dialog
          open={this.state.openConfirmBox}
          onClose={this.handleConfirmBoxClose}
        >
          {/* <DialogTitle>Import {tranType} Form via Excel</DialogTitle> */}
          <DialogTitle>Import Form via Excel</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={this.handleConfirmBoxClose}
            sx={{ position: "absolute", right: 8, top: 13 }}
            size="small"
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <Typography variant="h6">Download File Format:</Typography>
            {/* {masterType === 'category' ? ( */}
            {true ? (
              <>
                <Button onClick={() => this.downloadTransactionExcel(1)}>
                  Vertical
                </Button>
                <Button onClick={() => this.downloadTransactionExcel(2)}>
                  Horizontal
                </Button>
              </>
            ) : (
              <Button onClick={() => this.downloadTransactionExcel(1)}>
                Excel
              </Button>
            )}
            <br />
            <br />
            <Button
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
            >
              {/* Upload file */}
              <input type="file" />
            </Button>
          </DialogContent>
        </Dialog>

        <DestinationDetailsDialog
          handleClose={this.handleCloseDestinationDialog}
          open={this.state.isDestinationDetailsDialogOpen}
          lastTransactionInThreadCommon={
            this.props.lastTransactionInThreadCommon
          }
          setIsDestinationDetailsDialogOpen={
            this.setIsDestinationDetailsDialogOpen
          }
          itemizeData={this?.props?.quotationJsonTemplate?.ITEMIZE?.[0]}
          foreignData={this?.props?.quotationJsonTemplate?.COMMON?.concat(
            this?.props?.quotationJsonTemplate?.FOREIGN
          )}
          localData={this?.props?.quotationJsonTemplate?.COMMON?.concat(
            this?.props?.quotationJsonTemplate?.LOCAL
          )}
          vendorType={this.props.vendorType}
          handleSave={this.handleSave}
          fetchedTxn={this.props.fetchedTxn}
          additionalInfo={this.state.additionalInfoTemplate}
          mainTemplateInfo={this.state.mainTemplateQuotation}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedCategory: state.categoryReducer.selectedCategory,
  sessionId: state.sessionReducer.sessionId,
  companyInfo: state.companyInfoReducer.companyInfo,
  rfqSettingValues: state.drawerValuesReducer.rfqSettingValues,
  // reminderDrawer: state.drawerValuesReducer.reminderDrawer,
  selectedVendors: state.vendorReducer.selectedVendors,
  accountModulePermission: state.permissionsReducer.accountModulePermission,
  accountlevelInfo: state.permissionsReducer.accountlevelInfo,
  featurePermission: state.permissionsReducer.featurePermission,
  remiderDrawer: state.drawerValuesReducer.remiderDrawer,
  checkTechnicalBidItems: state.itemReducer.checkTechnicalBidItems,
  checkTechinicalBidCategory: state.categoryReducer.checkTechinicalBidCategory,
  categorySpecFiles: state.categoryReducer.fileSpecifications,
  itemSpecificFiles: state.itemReducer.itemSpecifications,
  prlineitems: state.prrfqreducer.setPrLineItemsData,
  prplants: state.prrfqreducer.plantcodes,
  selectedPrCatagory: state.prrfqreducer.selectedPrCategory,
  rfqSubmittedPrs: state.prrfqreducer.rfqSubmittedPrs,
  prCombinations: state.prrfqreducer.prCombinations,
  pr_dropdown_values: state.prrfqreducer.pr_dropdown_values,
  defaultValues: state.prrfqreducer.defaultValues,
  userInfo: state.userInfoReducer.userInfo,
  isAllVendorSelected: state.vendorReducer.isAllVendorSelected,
  disableIndex: state.prrfqreducer.disableIndex,
  templateValues: state.prrfqreducer.templateValues,
  termsAndConditions: state.prrfqreducer.termsAndConditions,
  fileSpecificationFromApi: state.prrfqreducer.fileSpecificationFromApi,
  selectedItems: state.itemReducer.selectedItems,
  displayOptions: state.prrfqreducer.displayOptions,
  quotationExcelData: state.ImportQuotationReducer.quotation,
});

// export default connect(mapStateToProps, {
//   openSnackbar,
//   setReminderSettings,
//   resetReminderSettings,
//   resetCAT,
//   setFileSpecification,
//   setItemSpecifications,
//   setPrlinktoitemsforspecification,
//   setPrRfqs,
//   setPRselectedChange,
//   setDefaultValues,
//   setTemplateValues,
//   setTermsAndConditions,
//   setFileSpecificationFromApi,
//   setRFQSettings,
// })(AccountTemplate);

const mapDispatchToProps = {
  openSnackbar,
  setReminderSettings,
  resetReminderSettings,
  resetCAT,
  setFileSpecification,
  setItemSpecifications,
  setPrlinktoitemsforspecification,
  setPrRfqs,
  setPRselectedChange,
  setDefaultValues,
  setTemplateValues,
  setTermsAndConditions,
  setFileSpecificationFromApi,
  setRFQSettings,
  ImportQuotation,
};

const ConnectedQuotationTemplate = connect(
  mapStateToProps,
  mapDispatchToProps
)(QuotationTemplate);

const StyledAccountTemplate = withStyles(styles)(ConnectedQuotationTemplate);

export default StyledAccountTemplate;
