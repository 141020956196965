import { LINKUNLINKUSER, SELECTEDUSERIDS } from "../constants/type"

export const LinkunlinkUser = (data) =>(dispatch) => {
    dispatch({
      type:LINKUNLINKUSER,
      payload:data
    })
}

export const SelctedUserId = (data) => (dispatch) => {
  dispatch({
    type:SELECTEDUSERIDS,
    payload:data
  })
}