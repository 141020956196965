import { IconButton } from "@mui/material";
import { makeStyles, styled } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    width: 280,
  },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

const options = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

const PrOptions = [
  { id: 1, value: 10, label: "Quantity Not Distributed" },
  { id: 2, value: 20, label: "Partially Distributed" },
  { id: 3, value: 30, label: "Distributed" },
  { id: 4, value: 40, label: "PR Released" },
];

const QcsOptions = [
  { id: 1, value: "NS", label: "Not Saved" },
  { id: 2, value: "Saved", label: "Saved" },
  { id: 3, value: "SentForApproval", label: "Sent For Approval" },
  { id: 4, value: "Initiated", label: "Initiated" },
  { id: 5, value: "Approved", label: "Approved" },
  { id: 6, value: "Rejected", label: "Rejected" },
  { id: 7, value: "Authorized", label: "Authorized" },
  { id: 8, value: "RemovedFromGroupQCS", label: "Removed From Group QCS" },
  { id: 9, value: "Deleted", label: "Deleted" },
];

export { useStyles, MenuProps, options, PrOptions, QcsOptions };

export const CustomIconButton = styled(IconButton)(({ theme }) => ({
  "&.MuiButtonBase-root": {
    borderRadius: "50%",
    padding: theme.spacing(1),
    transition: "background-color 0.2s",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
}));
