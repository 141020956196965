import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Box,
  Typography,
  Tooltip,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

import { menu } from "./Menu";
import { hasChildren } from "./utils";
import { useDispatch,useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { IconMapping } from "../../config/IconConfig";
import { setQuotationInfo } from "../../redux/actions/QuotationInfoAction";

const MenuStyle = makeStyles((theme) => ({
  MenuMainLink: {
    color: "#5a6778",
    whiteSpace:"nowrap",
    width: "100%",
    "& $MenuItemIcon": {
      color: "#3c4d62",
    },
    "&:hover": {
      color: theme.palette.primary.main,
      "& $MenuItemIcon": {
        color: theme.palette.primary.main,
      },
    },
  },
  MenuItemIcon: {
    minWidth: 30,
    height: 24,
  },
  MenuArrowICon: {
    color: "#a6c1d3",
    width: 19,
  },
}));
export default function AppMenu(props) {
  const MenuVendorWithPermission  = useSelector((state) => state.vendorReducer.vendorMenu.leftsideMenu)
  const transformedData = MenuVendorWithPermission?.map(item => ({
    ...item,
    title: item.display_name,
    icon: IconMapping?.[item.display_name],
    to: item.link_detail.match(/href="(.*?)"/)?.[1] || '',
    // Assuming the "to" value is within the href attribute and extracting it
    // Adjust the regular expression according to your data structure
    // ...
    // You can add more key-renaming pairs if needed
})) || [];
  const dispatch = useDispatch();
  // menu = transformedData
  // const permissions = useSelector((state) => state.permissionsReducer);
  // Filter the menu based on permissions
  const filteredMenu = transformedData
  React.useEffect(() => { }, [props]);
  const handleSideMenuClick = (shouldOpenInNewWindow) => {
    if(!shouldOpenInNewWindow){
      dispatch(setQuotationInfo({}));
    }
  }
  return filteredMenu.map((item, key) => (
    <MenuItem key={key} item={item} open={props.open} handleSideMenuClick = {handleSideMenuClick}/>
  ));
}
const MenuItem = ({ item, open, ispopup = 0,handleSideMenuClick }) => {
  const Component = hasChildren(item) ? (
    <MultiLevel item={item} open={open} ispopup={ispopup} />
  ) : (
    <SingleLevel item={item} open={open} handleSideMenuClick={handleSideMenuClick}/>
  );
  // const Component = <MultiLevel item={item} open={open} />

  // return <Component item={item} />;
  return Component;
};


const SingleLevel = ({ item, open,handleSideMenuClick }) => {
  const classes = MenuStyle();
  const shouldOpenInNewWindow = item.link_detail.includes('target="_blank"');
  //const navigate = useNavigate();
  //const { href, icon, title, to, ...others } = props;
  //const location = useLocation();

  return (
    <ListItem component="li" className={classes.MenuMainLink} disablePadding onClick={() => handleSideMenuClick(shouldOpenInNewWindow)}>
      <Link style={{textDecoration:'none'}} to={item.to} className={classes.MenuMainLink}  target={shouldOpenInNewWindow ? "_blank" : "_self"} 
>
        <Tooltip title={item.title}>        
          <ListItemButton>
              <ListItemIcon className={classes.MenuItemIcon}>
                {item.icon}
              </ListItemIcon>
            <ListItemText primary={item.title} />
          </ListItemButton>
        </Tooltip>
      </Link>
    </ListItem>
  );
};

const MultiLevel = ({ item, open, ispopup }) => {
  const { items: children } = item;
  const [openDropdown, setOpenDropdown] = useState(false);

  const handleClick = () => {
    setOpenDropdown((prev) => !prev);
  };

  const classes = MenuStyle();

  //support Popover menu
  const [anchorMenu, setanchorMenu] = React.useState(null);

  const handleMenuClick = (event) => {
    setanchorMenu(event.currentTarget);
  };

  const handleMenuClose = () => {
    setanchorMenu(null);
  };

  const openMenu = Boolean(anchorMenu);
  const id = openMenu ? "simple-popover" : undefined;

  return (
    <React.Fragment>
      <ListItem
        component="li"
        onClick={handleClick}
        className={classes.MenuMainLink}
        disablePadding
        selected={openDropdown}>
        <ListItemButton
          component="a"
          to={item.to}
          onClick={handleMenuClick}
          selected={anchorMenu}>
          <ListItemIcon className={classes.MenuItemIcon}>
            {item.icon}
          </ListItemIcon>
          <ListItemText primary={item.title} />
          {openDropdown ? (
            <ExpandLess className={classes.MenuArrowICon} />
          ) : (
            <ExpandMore className={classes.MenuArrowICon} />
          )}
        </ListItemButton>
      </ListItem>
      {open || ispopup ? (
        <Collapse in={openDropdown} timeout="auto" unmountOnExit>
          <List component="ul" disablePadding>
            {children.map((child, key) => (
              <MenuItem component="a" key={key} item={child} open={open} />
            ))}
          </List>
        </Collapse>
      ) : (
        <Popover
          id={id}
          open={openMenu}
          anchorEl={anchorMenu}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          className="popover-menu">
          <List component="ul" disablePadding>
            {children.map((child, key) => (
              <MenuItem
                component="a"
                key={key}
                item={child}
                open={open}
                ispopup={true}
              />
            ))}
          </List>
        </Popover>
      )}
    </React.Fragment>
  );
};
