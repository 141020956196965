import {
  Button,
  DialogActions,
  Grid,
  IconButton, 
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Dialog, DialogContent } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { importItem } from "../../../../config/apiUrl";
import axios from "axios";
import { Box } from "@mui/system";

const ItemImport = ({ open, sessionId, handleClose, onSuccess, item_detail }) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    // importItem
    setLoading(true);
    axios.defaults.headers.common["Authorization"] = `Bearer ${sessionId}`;
    const url = importItem(item_detail.code);
    axios
      .post(url, {
        "action": "import",
        "unit": item_detail.unit
      })
      .then(() => {
        setLoading(false);
        onSuccess();
        handleClose();
        // setItemData(response.data?.data[0]);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err, "Import Item error");
      });

  }
  return (
    <div>
      <Dialog
        scroll="body"
        fullWidth
        open={open}
        // onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Confirm
          <IconButton
            onClick={handleClose}
            sx={{ ml: "auto", color: (theme) => theme.palette.bluegrey[500] }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{p:0,}}>
            <Box>
            <Typography sx={{mb:1, textAlign: 'center'}}>
              Item <Box component="span" sx={{ color: (theme) => theme.palette.primary.main, fontWeight: 500 }} >{item_detail.code}</Box> already exists. Would you like to import it?
            </Typography>
            <Typography sx={{mb: 1, textAlign: 'center', fontWeight: 500,}}> Existing Details </Typography>
            </Box>
            <div className="linkcategorydialog_main">          
              <Grid
                container
                sx={{
                  borderBottom: 1,
                  borderColor: "bluegrey.500",
                  padding: "5px 16px",
                }}
              >
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <Typography fontSize={14} sx={{ textTransform: "uppercase" }}>
                    Item name
                  </Typography>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                  style={{ textAlign: "right" }}
                >
                  <Typography fontSize={14} color="secondary.main" sx={{ fontWeight: "700" }}>
                    {item_detail.name}
                    {/* {itemData && itemData.name} */}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{
                  borderBottom: 1,
                  borderColor: "bluegrey.500",
                  padding: "5px 16px",
                }}
              >
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <Typography fontSize={14} sx={{ textTransform: "uppercase" }}>
                    Item code
                  </Typography>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                  style={{ textAlign: "right" }}
                >
                  <Typography fontSize={14} color="secondary.main"  sx={{ fontWeight: "700" }}>
                    {item_detail.code}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container  
              sx={{
                borderBottom: 1,
                borderColor: "bluegrey.500",
                padding: "5px 16px",
              }}>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <Typography fontSize={14} sx={{ textTransform: "uppercase" }}>
                    Item Budget 
                  </Typography>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                  style={{ textAlign: "right" }}
                >
                  <Typography fontSize={14} color="secondary.main"  sx={{ fontWeight: "700" }}>
                    {item_detail.estimated_cost}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container 
              sx={{
                borderBottom: 1,
                borderColor: "bluegrey.500",
                padding: "5px 16px",
              }}
              >
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <Typography fontSize={14}sx={{ textTransform: "uppercase" }}>
                    Coded
                  </Typography>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                  style={{ textAlign: "right" }}
                >
                  <Typography fontSize={14}  color="secondary.main"  sx={{ fontWeight: "700" }}>
                    {item_detail.is_coded ? "Coded" : "-"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container 
              sx={{
                borderBottom: 1,
                borderColor: "bluegrey.500",
                padding: "5px 16px",
              }}
              >
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <Typography fontSize={14} sx={{ textTransform: "uppercase" }}>
                    Specifications
                  </Typography>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                  style={{ textAlign: "right" }}
                >
                  <Typography fontSize={14} color="secondary.main"  sx={{ fontWeight: "700" }}>
                    {item_detail.description}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container sx={{
                borderBottom: 1,
                borderColor: "bluegrey.500",
                padding: "5px 16px",
              }}>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <Typography fontSize={14} sx={{ textTransform: "uppercase" }}>
                    Item Unit
                  </Typography>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                  style={{ textAlign: "right" }}
                >
                  <Typography fontSize={14} color="secondary.main"  sx={{ fontWeight: "700" }}>
                    {item_detail.unit}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container sx={{
                borderBottom: 1,
                borderColor: "bluegrey.500",
                padding: "5px 16px",
              }}>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <Typography fontSize={14} sx={{ textTransform: "uppercase" }}>
                    Item HSN
                  </Typography>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                  style={{ textAlign: "right" }}
                >
                <Typography fontSize={14} color="secondary.main"  sx={{ fontWeight: "700" }}>
                  {item_detail.hsn_code}
                </Typography>
                </Grid>
              </Grid>
              <Grid container sx={{
                borderBottom: 1,
                borderColor: "bluegrey.500",
                padding: "5px 16px",
              }}>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <Typography fontSize={14} sx={{ textTransform: "uppercase" }}>
                    Item SAC
                  </Typography>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                  style={{ textAlign: "right" }}
                >
                  <Typography fontSize={14} color="secondary.main" sx={{ fontWeight: "700" }}>
                    {item_detail.sac_code}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>No</Button>
          <Button variant="contained" onClick={handleSubmit} disabled={loading}>Yes</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ItemImport;
