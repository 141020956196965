import React, { useState, useCallback } from "react";
import { IconButton, InputAdornment, Popper } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { Paper } from "@mui/material";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  OPEN_SNACKBAR,
} from "../../redux/constants/type";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { CusotmsearchInput } from "../TextField";
import ItemSearchResult from "../SearchDataGrid/ItemSearchResult";
import { searchLinkedItemByNameOrCode } from "../../config/apiUrl";
import axios from "axios";

export default function ItemSearchPopper({search_disable,openAddItemDialog,handleOpenLinkUnlinkItem,master_type }) {
  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [inputVal, setInputVal] = useState("");
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentpage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1)
  const { sessionId } = useSelector((reducer) => reducer.sessionReducer);
  const { selectedCategory } = useSelector(
    (reducer) => reducer.categoryReducer
  );
  const { selectedItems } = useSelector(
    (reducer) => reducer.itemReducer
  );

  const dispatch = useDispatch();
  const handleChange = (evt) => {
    setInputVal(evt.target.value);
    setItems([]);
    if (evt.target.value.trim() === "") {
      setOpen(false);
    } else {
      setOpen(true);
      setLoading(true);
      setCurrentPage(1);
      setLastPage(1);
      getApi(evt.target.value.trim(), 1);
    }
  };
  const getApi = useCallback(
    debounce((value, page) => {
      fetchItems(value, page);
    }, 1000),
    [sessionId, selectedItems]
  );

  const fetchItems = (value, page) => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${sessionId}`;
    // const url = searchLinkedItem(selectedCategory.id, value.trim(), page);
    const url = searchLinkedItemByNameOrCode(selectedCategory.id, value.trim(), page);
    axios
      .get(url)
      .then((response) => {
        let finalRows;
        if (selectedItems?.length) {
          const newRows = [];
          response.data?.data?.forEach((avail) => {
            if (
              !selectedItems.some((element) => {
                return element.id === avail.id;
              })
            ) {
              //rows will contain unique items only
              newRows.push(avail);
            }
          });
          finalRows = newRows;
        } else {
          finalRows = response.data?.data;
        }
        if(page === 1){
          setItems(finalRows);
          setLastPage(response.data.meta.last_page)
        } else {
          setItems((preItems) => [...preItems, ...finalRows]);
        }
        setCurrentPage(response.data.meta.current_page)
        setLoading(false);
        // setPaginationLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        // setPaginationLoading(false);
        dispatch({
          type: OPEN_SNACKBAR,
          payload: {
            type: "error",
            msg: error?.response?.data.message
              ? error?.response?.data.message
              : "Something went wrong",
          },
        });
        setItems([]);
      });
  }

  const handleScrollend = () => {
    if (currentpage !== lastPage) {
      setLoading(true);
      fetchItems(inputVal.trim(), currentpage + 1);
    }
  }
  const hideSearchGrid = () => {
    setInputVal("");
    setOpen(false);
  };
  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          setOpen(false);
          setInputVal("");
        }}
      >
        <div className="cart-search-wrap " id="main_input_outline1">
          <CusotmsearchInput 
          disabled={search_disable}
            className="item-search"          
            placeholder="Search link item and Code"
            ref={anchorRef}
            value={inputVal}
            onChange={handleChange}
            endAdornment={
              <InputAdornment position="start">
                <IconButton type="submit" size="small">
                  <SearchOutlinedIcon />
                </IconButton>
              </InputAdornment>
            }
          />
          <Popper
           className="item-result"  
            open={open}
            anchorEl={anchorRef.current}
            placement="bottom-end"
            disablePortal={false}
            style={{
              zIndex: "11",
            }}
            
            modifiers={[
              {
                name: "flip",
                enabled: true,
                options: {
                  altBoundary: true,
                  rootBoundary: "document",
                  padding: 8,
                },
              },
              {
                name: "preventOverflow",
                enabled: false,
                options: {
                  altAxis: true,
                  altBoundary: true,
                  tether: true,
                  rootBoundary: "document",
                  padding: 8,
                },
              },
            ]}
          >
            <Paper
                           
              sx={{
                marginTop: "1rem",
                boxShadow: "0px 10px 8px 0px rgb(0 0 0 / 16%)",                                
                overflow: 'hidden', minWidth: '650px', maxWidth: '650px', 
              }}
            >
              <ItemSearchResult
                searchVal={inputVal}
                master_type={master_type}
                handleClose={hideSearchGrid}
                openAddItemDialog={openAddItemDialog}
                handleOpenLinkUnlinkItem={handleOpenLinkUnlinkItem}
                handleScrollend={handleScrollend}
                rows={items}
                loading={loading}
              />
            </Paper>
          </Popper>
        </div>
      </ClickAwayListener>
    </>
  );
}
