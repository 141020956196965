import { OPEN_SNACKBAR, CLOSE_SNACKBAR } from "../constants/type";

export const openSnackbar = (type, msg, isMasterAction) => (dispatch) => {
  dispatch({
    type: OPEN_SNACKBAR,
    payload: { type, msg, isMasterAction },
  });
};
export const openSnackbarfunc = (type, msg, isMasterAction) => {
  return({
    type: OPEN_SNACKBAR,
    payload: { type, msg, isMasterAction },
  });
};
export const closeSnackbar = () => (dispatch) => {
  dispatch({
    type: CLOSE_SNACKBAR,
  });
};
