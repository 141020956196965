import React, { useState, useEffect } from "react";
import _, { debounce } from "lodash";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LandedCostRFQRight } from "../LandedCostRFQDrawer/style";
import { makeStyles } from "@mui/styles";
import { getSymbol } from "../../AccountTemplate/CurrencySymbol";
import { styled } from "@mui/material/styles";

const drawerWidth = 350;

const useStyles = makeStyles(() => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
}));
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
export default function MultiplantQty({ open, close, itemData, Unit, item }) {
  const classes = useStyles();
  //  const handleClose = () => {
  //   close()
  //  }

  return (
    <React.Fragment>
      <Dialog
        onClose={close}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle
          component="div"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "2px 16px",
          }}
        >
          <Typography variant="subtitle1" fontWeight={500} component="h6">Required Quantity</Typography>
          <IconButton
            edge="end"
            aria-label="close"
            onClick={close}
            sx={{ color: (theme) => theme.palette.bluegrey[500] }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>        
        <DialogContent dividers>
          {/* Display item.code below */}
          <Typography variant="body2" mb={1}>Item Name : {item.name}</Typography>
          <Typography variant="body2" mb={1}>Item Code : {item.code}</Typography>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell width={130}>DESTINATION</TableCell>
                <TableCell width={130}>ITEM QTY</TableCell>
                <TableCell width={130}>UNIT</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {itemData.map((item, key) => {
                return (
                  <TableRow key={key}>
                    <TableCell>
                      <Tooltip
                        title={_.unescape(item.plant)}
                        placement="bottom"
                      >
                        <Typography
                          variant="body2"
                          sx={{ wordBreak: "break-word" }}
                        >
                          {_.unescape(item.plant)}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Tooltip
                        title={_.unescape(parseFloat(item.quantity).toFixed(2))}
                        placement="bottom"
                      >
                        <Typography
                          variant="body2"
                          sx={{ wordBreak: "break-word" }}
                        >
                          {_.unescape(parseFloat(item.quantity).toFixed(2))}
                        </Typography>
                      </Tooltip>
                    </TableCell>

                    <TableCell>
                      <Tooltip title={Unit} placement="bottom">
                        <Typography
                          variant="body2"
                          sx={{ wordBreak: "break-word" }}
                        >
                          {Unit}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </DialogContent>
        {/* <DialogActions>
        <Button onClick={close} color="primary">
          Close
        </Button>
      </DialogActions> */}
      </Dialog>
    </React.Fragment>
  );
}
