import {
  Grid,
  IconButton,
  InputAdornment,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Dialog,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import React, { Component } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { connect } from "react-redux";
import { setSelectedVendors } from "../../../redux/actions/vendorActions";
import { openSnackbar } from "../../../redux/actions/snackbarAction";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import moment from "moment";
import { DatePicker, MobileDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Calendar from '@mui/icons-material/Event';
class DeliveryScheduleDetails extends Component {
  state = {
    deliveryScheduleDetails: [
      {
        deliveryDate: "",
        desiredQuantity: "",
        instructionText: "",
      },
    ],
  };
  handleChange = (e, index) => {
    const { name, value } = e.target;
    const { deliveryScheduleDetails } = this.state;
    deliveryScheduleDetails[index][name] = value;
    this.setState({
      deliveryScheduleDetails,
    });
  };

  handleSubmit = () => {
    const { deliveryScheduleDetails } = this.state;
    let value = [];
    let deliveryDateError = false;
    let desiredQuantityError = false;
    deliveryScheduleDetails.forEach((row) => {
      if (row.deliveryDate && row.desiredQuantity) {
        value.push(
          `${moment(row.deliveryDate).format("DD-MM-YYYY")},${
            row.desiredQuantity
          },${row.instructionText}`
        );
      } else {
        if (!row.deliveryDate) {
          deliveryDateError = true;
        }
        if (!row.desiredQuantity) {
          desiredQuantityError = true;
        }
        return;
      }
    });

    if (
      desiredQuantityError &&
      deliveryDateError &&
      deliveryScheduleDetails?.length === 1
    ) {
      this.props.updateFieldValue("");
      this.props.handleClose();
      return;
    }
    if (desiredQuantityError || deliveryDateError) {
      this.props.openSnackbar(
        "error",
        `Enter All Fields for ${deliveryDateError ? "Delivery Date" : ""} ${
          desiredQuantityError ? "and Desired Quantity" : ""
        }!`
      );
      return;
    }
    this.props.updateFieldValue(value.join(";"));
    this.props.handleClose();
  };
  componentDidMount() {
    if (this.props.value?.length) {
      let deliveryScheduleDetails = this.props.value.split(";").map((str) => {
        let obj = {};
        let words = str.split(",");
        obj.deliveryDate = moment(words[0], "DD-MM-YYYY");
        obj.desiredQuantity = words[1];
        obj.instructionText = words.slice(2, words.length).join(",");
        return obj;
      });
      this.setState({
        deliveryScheduleDetails,
      });
    }
  }

  render() {
    const { open, handleClose, unit, readonly } = this.props;
    return (
      <div>
        <Dialog
          maxWidth={"md"}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          sx={{
            "& .MuiDialog-container":{
              alignItems:"start"
            }
          }}
        >
          <DialogTitle id="responsive-dialog-title">
            Delivery Schedule Details
            <Box className="dialog-title-action">
              <IconButton
                onClick={this.props.handleClose}
                sx={{ color: (theme) => theme.palette.bluegrey[500] }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <div className="delivery_schedule_details_content">
              <Grid container spacing={4}>
                <Grid item lg={12} md={12} sm={12} xs={12} className="mb-3">
                  <TableContainer
                    component={Paper}
                    sx={{
                      border: 1,
                      borderColor: (theme) => theme.palette.bluegrey[500],
                      borderRadius: "4px 4px 0 0",
                      boxShadow: "none",
                    }}
                  >
                    <form id="novalidatedform" noValidate>
                      <Table sx={{ minWidth: 650 }}>
                        <TableHead>
                          <TableRow>
                            <TableCell>Delivery Date</TableCell>
                            <TableCell>Desired Quantity</TableCell>
                            <TableCell>Instruction Text</TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.deliveryScheduleDetails?.map(
                            (row, index) => (
                              <TableRow
                                key={index}
                                hover
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                  "& td": { padding: "10px 16px" },
                                }}
                              >
                                <TableCell
                                  component="td"
                                  scope="row"
                                  sx={{ width: "360px" }}
                                >
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                  >
                                    <MobileDatePicker
                                      minDate={new Date()}
                                      format="dd/MM/yyyy"
                                      name="deliveryDate"
                                      closeOnSelect="true"
                                      slots={{
                                        actionBar: "false",
                                      }}
                                      slotProps={{
                                        toolbar: {
                                          hidden:"true"
                                        },
                                        textField: {
                                          size: "small",
                                          sx: {
                                            marginTop: "16px",
                                            marginBottom: "8px",
                                          },
                                          InputProps: { endAdornment: <Calendar sx={{color:(theme) => theme.palette.grey[500]}} /> }
                                        },
                                      }}
                                      value={
                                        row.deliveryDate
                                          ? new Date(row.deliveryDate)
                                          : null
                                      }
                                      onChange={(newVal) => {
                                        let deliveryScheduleDetails =
                                          this.state.deliveryScheduleDetails;
                                        deliveryScheduleDetails[index][
                                          "deliveryDate"
                                        ] = moment(newVal);
                                        this.setState({
                                          deliveryScheduleDetails,
                                        });
                                      }}
                                    />
                                  </LocalizationProvider>
                                </TableCell>
                                <TableCell align="right">
                                  <TextField
                                    className="local_template_textfield"
                                    size="small"
                                    name="desiredQuantity"
                                    value={row.desiredQuantity}
                                    onChange={(e) =>
                                      this.handleChange(e, index)
                                    }
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    inputProps={{ type: "number" }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          {unit}
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </TableCell>
                                <TableCell align="right">
                                  <TextField
                                    className="local_template_textfield"
                                    size="small"
                                    multiline
                                    maxRows={3}
                                    name="instructionText"
                                    value={row.instructionText}
                                    onChange={(e) =>
                                      this.handleChange(e, index)
                                    }
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                  />
                                </TableCell>
                                <TableCell align="right">
                                  <IconButton
                                    onClick={() => {
                                      this.state.deliveryScheduleDetails.splice(
                                        index,
                                        1
                                      );
                                      this.setState(
                                        {
                                          deliveryScheduleDetails:
                                            this.state.deliveryScheduleDetails,
                                        },
                                        () => {
                                          if (
                                            !this.state.deliveryScheduleDetails
                                              .length
                                          ) {
                                            this.state.deliveryScheduleDetails.push(
                                              {
                                                deliveryDate: "",
                                                desiredQuantity: "",
                                                instructionText: "",
                                              }
                                            );
                                            this.setState({
                                              deliveryScheduleDetails:
                                                this.state
                                                  .deliveryScheduleDetails,
                                            });
                                          }
                                        }
                                      );
                                    }}
                                    size="large"
                                  >
                                    {!readonly && (
                                      <RemoveCircleIcon
                                        style={{ color: "var(--thm-red)" }}
                                      />
                                    )}
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </form>
                  </TableContainer>
                </Grid>
              </Grid>
              <Grid container spacing={4} className="add_more_row_btn">
                <Grid item sm={12}>
                  {!readonly && (
                    <Button
                      variant="text"
                      color="primary"
                      onClick={() => {
                        this.state.deliveryScheduleDetails.push({
                          deliveryDate: "",
                          desiredQuantity: "",
                          instructionText: "",
                        });
                        this.setState({
                          deliveryScheduleDetails:
                            this.state.deliveryScheduleDetails,
                        });
                      }}
                      startIcon={<AddCircleIcon />}
                    >
                      ADD More
                    </Button>
                  )}
                </Grid>
              </Grid>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="primary"
              autoFocus
              onClick={() => {
                this.props.handleClose();
              }}
            >
              Cancel
            </Button>
            {!readonly && (
              <Button
                autoFocus
                variant="contained"
                color="primary"
                onClick={this.handleSubmit}
              >
                Submit
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  selectedCategory: state.categoryReducer.selectedCategory,
  selectedVendors: state.vendorReducer.selectedVendors || [],
});
export default connect(mapStateToProps, {
  setSelectedVendors,
  openSnackbar,
})(DeliveryScheduleDetails);
