import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { CancelOutlined } from "@mui/icons-material";
import JustificationDialog from "../../common/RFQList/justificationModal";
import VendXConnect from "../../assets/RFQList/images/vendxConnect.png";
import {
  Alert,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItemText,
  Snackbar,
} from "@mui/material";
import axios from "axios";
import {
  get_Inquiries_data,
  prviewTB,
  technicalBid,
  vendx_connect,
} from "../../config/apiUrl";
import $ from "jquery";
import jQuery from "jquery";
import { useSelector } from "react-redux";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { URL_MAPPING } from "../../config/host-mapping";
import CloseIcon from "@mui/icons-material/Close";
import InnerHTML from "dangerously-set-html-content";
import ViewTbBandge from "../../assets/vendxAdminPanelAsstes/View_TB_Badge_Icon.svg";
const currentHost = window.location.host;
const parentHostURI =
  URL_MAPPING[currentHost]["parent_host"] ||
  URL_MAPPING["default"]["parent_host"];

export function loadScripts(sources) {
  sources.forEach((src) => {
    var script = document.createElement("script");
    script.src = src;
    script.async = false; //<-- the important part
    document.body.appendChild(script); //<-- make sure to append to body instead of head
  });
}
export function loadStyle(sources) {
  sources.forEach((src) => {
    var linkElement = document.createElement("link");
    linkElement.setAttribute("rel", "stylesheet");
    linkElement.setAttribute("type", "text/css");
    linkElement.setAttribute("href", src);
    document.head.appendChild(linkElement);
  });
}

export function removeJSFile(filename, filetype) {
  var targetelement =
    filetype == "js" ? "script" : filetype == "css" ? "link" : "none"; //determine element type to create nodelist from
  var targetattr =
    filetype == "js" ? "src" : filetype == "css" ? "href" : "none"; //determine corresponding attribute to test for
  var allsuspects = document.getElementsByTagName(targetelement);
  for (var i = allsuspects.length; i >= 0; i--) {
    //search backwards within nodelist for matching elements to remove
    if (
      allsuspects[i] &&
      allsuspects[i].getAttribute(targetattr) != null &&
      allsuspects[i].getAttribute(targetattr).indexOf(filename) != -1
    )
      allsuspects[i].parentNode.removeChild(allsuspects[i]); //remove element by calling parentNode.removeChild()
  }
}
export default function VendorMenuItems({
  sessionID,
  openMenu,
  handleClose,
  anchorEl,
  menulabels,
  // vendorsDetails,
  cancelQuote,
  selectedMenuId,
  selectedId,
  selectedQuoteId,
  inquiryNumber,
  From = "",
  vendorCancelQuote,
  InquiryData,
  handleRefreshGrid,
}) {
  const [openModal, setOpenModal] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState(false);
  const [cancelQuotesnackbar, setCancelQuotesnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const Permisison = useSelector((state) => state.permissionsReducer);
  const [loader, setLoader] = React.useState(true);
  const [anchorElTB, setAnchorElTB] = React.useState(false);
  const [anchorElTBIframe, setAnchorElTBIframe] = React.useState(false);

  const [Tbpreview, setTbpreview] = React.useState({
    __html: "",
  });
  const configAxiosPost = {
    headers: { Authorization: `Bearer ${sessionID}` },
    "Content-Type": "application/json",
  };
  const configAxios = {
    headers: { Authorization: `Bearer ${sessionID}` },
  };

  React.useEffect(() => {
    window.$ = $;
    window.jQuery = jQuery;
    window.jq = jQuery;
  }, []);

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const handleCloseQuote = () => {
    setOpenModal(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbar(false);
  };

  const handleCancelQuoteSubmit = (justification) => {
    const inquiryNo = selectedId;
    const vendor_id = selectedMenuId;
    const quote_id = selectedQuoteId;

    const url =
      get_Inquiries_data +
      `${inquiryNo}/vendors/${vendor_id}/quotes/${quote_id}/cancel_quotes`;

    if (
      inquiryNo === undefined ||
      vendor_id === undefined ||
      quote_id === undefined
    ) {
      setSnackbarMessage("Some required values are undefined.");
      setSnackbarSeverity("error"); // Set the severity to error
      setSnackbar(true);
      return;
    }

    axios
      .patch(url, { justification }, configAxios)
      .then((response) => {
        if (response.status === 200) {
          setSnackbarMessage("Cancel Quote Successfully");
          setSnackbarSeverity("success"); // Set the severity to success
          setSnackbar(true);
          handleCloseQuote();
        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          const errorMessage = error.response.data.error[0];
          setSnackbarMessage(`${errorMessage}`);
        } else {
          setSnackbarMessage(`${error.message}`);
        }
        setSnackbarSeverity("error"); // Set the severity to error
        setSnackbar(true);
        handleCloseQuote();
      });
  };

  const openVendXScreen = (inqId) => {
    const form = document.createElement("form");
    form.target = "_blank";
    form.action = vendx_connect;
    form.method = "post";

    const hiddenInput = document.createElement("input");
    hiddenInput.type = "hidden";
    hiddenInput.name = "inquiry_id";
    hiddenInput.value = inqId;

    const sessionid = document.createElement("input");
    sessionid.type = "hidden";
    sessionid.name = "sessionID";
    sessionid.value = sessionID;

    const hiddenInputAction = document.createElement("input");
    hiddenInputAction.type = "hidden";
    hiddenInputAction.name = "action";
    hiddenInputAction.value = "display_communication_chain";
    form.appendChild(hiddenInput);
    form.appendChild(sessionid);

    form.appendChild(hiddenInputAction);
    document.body.appendChild(form);
    form.submit();
  };

  const handleTBPreview = async () => {
    const formData = new FormData();
    formData.append("from_react", "1");
    formData.append("inquiry_id", selectedId);
    formData.append("tran_type_guid", InquiryData?.vendor?.guid);
    formData.append("tb_master_type", "category");
    formData.append(
      "product_category_name",
      InquiryData?.inquiry_categories?.name
    );
    formData.append("product_id", InquiryData?.inquiry_categories?.id);
    formData.append("master_action", "technical_bid");
    formData.append("oper", "vendor_preview");
    formData.append("vendor_flag", "1");
    // formData.append("pr_flag", '1');


    loadStyle([
      parentHostURI + "/plugins/font-awesome-4.7.0/css/font-awesome.min.css",
      parentHostURI + "/css/ajax-dynamic-list.css",
      parentHostURI + "/pr/bootstrap/css/bootstrap-toggle.min.css",
      parentHostURI + "/css/bluestar_blue.css",
      parentHostURI + "/css/masters/admin-jquery-ui.min.css",
      parentHostURI + "/css/masters/jquery-ui-1.8.20.custom.css",
      parentHostURI + "/css/masters/new_masters.css",
      parentHostURI + "/css/masters/technical_bid_template.css",
      parentHostURI + "/css/masters/colorbox_custom/theme3/colorbox.css",
      parentHostURI + "/css/ui-kits/number-align.css",
      parentHostURI + "/css/ui-kits/react-custom.css",
    ]);
    loadScripts([
      parentHostURI + "/js/masters/jquery_grid/jquery-1.7.2.min.js",
      parentHostURI + "/js/masters/masters_auto_minimized.js",
    ]);
    handleRefreshGrid(false);
    setAnchorElTB(true);
    setLoader(true);
    try {
      const response = await axios.post(prviewTB, formData, configAxiosPost);
      if (response) {
        console.log("response", response);
        setTbpreview(response.data);
        setLoader(false);
      } else {
        removeJSFile(parentHostURI + "/css/bluestar_blue.css", "css");
        // Handle empty data case
      }
    } catch (error) {
      removeJSFile(parentHostURI + "/css/bluestar_blue.css", "css");
      console.error("Error fetching data:", error);
    }
  };

  const handleTechnicalBid = async () => {
    handleRefreshGrid(false);

    setAnchorElTBIframe(true);
  };
  const handleTbClose = () => {
    handleRefreshGrid(true);
    setAnchorElTB(false);
    setTbpreview({ __html: "" });
    removeJSFile(
      parentHostURI + "/js/masters/jquery_grid/jquery-1.7.2.min.js",
      "js"
    );
    removeJSFile(parentHostURI + "/js/masters/masters_auto_minimized.js", "js");
  };

  const handleTbCloseIframe = () => {
    handleRefreshGrid(true);
    setAnchorElTBIframe(false);
  };

  return (
    <React.Fragment>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openMenu}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 20,
              mr: 1,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        <MenuItem
          onClick={(e) => {
            openVendXScreen(selectedId);
          }}
        >
          <ListItemIcon>
            <img src={VendXConnect} alt="VendXConnect" />
          </ListItemIcon>
          Vendx Connect
        </MenuItem>
        {!vendorCancelQuote && cancelQuote === "N" && From != "Supplier" && (
          <MenuItem onClick={handleModalOpen}>
            <ListItemIcon>
              <CancelOutlined fontSize="small" />
            </ListItemIcon>
            Cancel Quote
          </MenuItem>
        )}
        {Permisison?.accountModulePermission?.["Technical Bid"]
          ?.module_activated == "Y" &&
          From == "Supplier" &&
          InquiryData?.valid_date_tb !== null && (
            <MenuItem onClick={handleTBPreview}>
              <ListItemIcon>
                <VisibilityOutlinedIcon fontSize="small" />
              </ListItemIcon>
              {/* <ListItemText>Technical Bid Preview</ListItemText> */}
              <ListItemText>{menulabels.technical_bid_preview}</ListItemText>
            </MenuItem>
          )}
        {Permisison?.accountModulePermission?.["Technical Bid"]
          ?.module_activated == "Y" &&
          From == "Supplier" &&
          InquiryData?.valid_date_tb !== null && (
            <MenuItem onClick={handleTechnicalBid}>
              <ListItemIcon>
                <img src={ViewTbBandge} />
              </ListItemIcon>
              {/* <ListItemText>Technical Bid Preview</ListItemText> */}
              <ListItemText>{menulabels.technical_bid}</ListItemText>
            </MenuItem>
          )}
      </Menu>
      <JustificationDialog
        open={openModal}
        onClose={handleCloseQuote}
        title={inquiryNumber}
        subtitle="Close The Quote in one Click"
        onSubmit={handleCancelQuoteSubmit}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={3000}
        open={cancelQuotesnackbar}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          variant="filled"
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={3000}
        open={snackbar}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          variant="filled"
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {From == "Supplier" && (
        <Dialog
          open={anchorElTB}
          onClose={handleTbClose}
          fullScreen
          sx={{ m: 4 }}
        >
          <DialogTitle sx={{ m: 0, padding: "16px !important" }}>
            {menulabels.technical_bid_preview}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleTbClose}
            sx={{ position: "absolute", right: 8, top: 13 }}
            size="small"
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            {loader === true ? (
              <CircularProgress
                sx={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              />
            ) : (
              <InnerHTML html={Tbpreview} />
            )}
          </DialogContent>
        </Dialog>
      )}

      {From == "Supplier" && (
        <Dialog
          open={anchorElTBIframe}
          onClose={handleTbCloseIframe}
          fullScreen
          className="TBid-Dialog"
          sx={{
            m: 4,
            "&.TBid-Dialog .MuiDialogContent-root iframe": {
              height: "calc(100% - 8px)",
            },
          }}
        >
          <DialogTitle sx={{ m: 0, padding: "16px !important" }}>
            {menulabels.technical_bid}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleTbCloseIframe}
            sx={{ position: "absolute", right: 8, top: 13 }}
            size="small"
          >
            <CloseIcon />
          </IconButton>
          <DialogContent
            dividers
            sx={{
              "&": {
                p: 0,
              },
            }}
          >
            <iframe
              title="Your Iframe Title"
              src={`${technicalBid}?inquiry_id=${selectedId}&tb_quotation_id=${
                InquiryData?.vendor?.quotation?.id
                  ? InquiryData?.vendor?.quotation?.id
                  : ""
              }&tran_log_guid=${
                InquiryData?.vendor?.quotation?.latest_TLGUID
                  ? InquiryData?.vendor?.quotation?.latest_TLGUID
                  : ""
              }&product_id=${
                InquiryData?.inquiry_categories?.id
              }&master_action=technical_bid&oper=fill_up_detail_of_tbt_for_vendor&vendor_flag=1&tb_master_type=${
                InquiryData?.item_specific_vendor ? "item" : "category"
              }&rfq_mapping_type=20&online_quote_flag=10&q=${sessionID}&from_react=1`}
              width="100%"
              height="100%"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </DialogContent>
        </Dialog>
      )}
    </React.Fragment>
  );
}
