import React, { Component } from "react";
import { setSessionId } from "../redux/actions/sessionAction";
import { connect } from "react-redux";
import { setUserInfo } from "../redux/actions/setUserInfo";
import ShowEmpty from "../components/ShowEmpty";
import { setSelectedCategory } from "../redux/actions/categoryAction";
import { Backdrop, Box, CircularProgress, Grid, Typography } from "@mui/material";
import { openSnackbar } from "../redux/actions/snackbarAction";
import { CategoryEmptyCard, EmptyCategoryTitle } from "../components/ShowEmpty/style";
import NoAccountsOutlinedIcon from '@mui/icons-material/NoAccountsOutlined';
import PRHeader from "../components/PRRFQ/PRHeader";
import MainContent from "../components/MainContent/MainContent";
//import {  geteditable_fields_json } from "../config/apiUrl";
//import axios from 'axios'
export class ShowCombinations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sessionID: "",
      submitBtn: "",
      loading: false,
      TemporaryDrawerKey: 1,
      GroupListOpen:true,
      prCombininations:[],
      rfqLoading:false,
      selctedIndex: 0,
      activityHash : {
        action : "",
        tb_id  : -1,
        pre_fetch_inquiry_id : 0,
        allowOpen : 0
      },
      rfqLoading:false
    };
  }

  componentDidMount() {

    const queryParams = new URLSearchParams(this.props.location.search);
    let SessionID = queryParams.get('q');
    let prs= queryParams.get('prs');
    // console.log(SessionID);
    console.log("prs ==",prs)
    if(this.props.match.params.pr){
      prs = this.props.match.params.prs;
    }

    this.setState({
      sessionID: SessionID,
    });

    this.props.setSessionId(SessionID);
    // localStorage.removeItem("SessionID");
    this.props.setUserInfo(SessionID);
    
    if (prs) {
      this.setState({
        loading: true,
      });
    

      // setSelectedCategory(category)
    }

    // axios.get(`${geteditable_fields_json}`).then((res) =>{
    //     console.log("result =",res);
    // }).catch(Error => {

    // });

  }
  setSelctedPrKey = (ID) => {
    this.setState({selctedIndex:ID})

  }
  resetSettingDrawer = () => {
    this.setState({
      TemporaryDrawerKey: this.state.TemporaryDrawerKey + 1,
    });
  };

  triggerTemplateIFrame = (action,tb_id,pre_fetch_inquiry_id,allowOpen) => {
    this.setState({
      activityHash : {
        action : action,
        tb_id  : tb_id || -1,
        pre_fetch_inquiry_id : pre_fetch_inquiry_id || 0,
        allowOpen :allowOpen
      } 
    });
  };
  rfqPermission = () => {
    let errorMessage = [];

    if (this.props.userInfo?.can_float_inquiry === "N") {
      errorMessage.push("You do not have permission to float an RFQ.");
    }
    if (this.props.userInfo?.MPSD_module_exists === "N") {
      errorMessage.push("The MPSD module is not enabled for your account.");
    }
    if (this.props.userInfo?.suspended === "Y") {
      errorMessage.push("Your account is suspended.");
    }
    if (this.props.userInfo?.transaction_limit_exceeded === "Y") {
      errorMessage.push("You have used all your transaction limits.");
    }
    if (this.props.userInfo?.transaction_period_limit_exceeded === "Y") {
      errorMessage.push("Your transaction period time is over.");
    }
    return errorMessage;
  };

  render() {
    return (
      <>
          <Backdrop
                        style={{ color: "#fff", zIndex: "10000000000" }}
                        open={this.props.rfqLoading}
                    >
                        <CircularProgress style={{ color: "#fff" }} />
                    </Backdrop>
        {this.rfqPermission().length > 0 ? (
        <div className="page_content_main" style={{ padding: this.props.type === "category" ? "0 22px" : "0" }}      >
            <Grid container>
            <Grid item xs={12}>
              <CategoryEmptyCard elevation={0} cart={this.props.type === "category"} >
                <NoAccountsOutlinedIcon color='error' sx= {{ 
                  margin: "0 auto 15px auto",
                  fontSize: "175px",
                }}/>
                {this.rfqPermission().map(msg => {
                  return <EmptyCategoryTitle variant="h5" className="categorytext_heading">
                  {msg}
                </EmptyCategoryTitle>
                })}
              </CategoryEmptyCard>
              
            </Grid>
          </Grid>
        </div>
        ) : (
          <>
           <Box sx={{display:(this.props.prCombinations.length || this.props.userInfoError || Object.keys(this.props.userInfo).length !== 0) ? "block" : "none"}}>
           <PRHeader
              sessionID={this.state.sessionID}
              TemporaryDrawerKey={this.state.TemporaryDrawerKey}
              resetSettingDrawer={this.resetSettingDrawer}
              setSelctedPrKey = {this.setSelctedPrKey}
              triggerTemplateIFrame={this.triggerTemplateIFrame}
            />
           </Box>
            {this.props.selectedPrCategory.category ? (
              //  <PrMainContent></PrMainContent>
                <MainContent resetSettingDrawer={this.resetSettingDrawer}  location={this.props.location} 
                match={this.props.location} selctedIndex={this.state.selctedIndex} activityHash={this.state.activityHash} triggerTemplateIFrame={this.triggerTemplateIFrame}/>
            ) : (
              <>
              {!this.props.isEmptyPrCombination && <ShowEmpty type="category" />}
              <Backdrop
              sx={{color: (theme) => theme.palette.grey[0],zIndex: "10000000000", backgroundColor:(theme) => theme.palette.grey[0],display:"flex",flexDirection:"column",justifyContent:"center"}}
              open={this.props.isEmptyPrCombination}
            >
              <CircularProgress  sx={{color: (theme) => theme.palette.primary}} />
              <Typography variant="body2" sx={{color: (theme) => theme.palette.grey[900],paddingLeft:"5px",fontSize:"18px"}}>Please wait a while we are processing...</Typography>
            </Backdrop>
            </>
            )}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedCategory: state.categoryReducer.selectedCategory,
  userInfo: state.userInfoReducer.userInfo,
  userInfoError:state.userInfoReducer.userInfoError,
  selectedPrCategory: state.prrfqreducer.selectedPrCategory,
  isEmptyPrCombination:state.prrfqreducer.isEmptyPrCombination,
  prCombinations:state.prrfqreducer.prCombinations,
  rfqLoading:state.prrfqreducer.rfqLoading,
});

export default connect(mapStateToProps, {
  setSessionId,
  setUserInfo,
  setSelectedCategory,
  openSnackbar,
})(ShowCombinations);
