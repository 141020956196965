import axios from "axios";
import cookie from "react-cookies";
import { URL_MAPPING } from "../config/host-mapping";
import { useLocation } from "react-router-dom";

const currentHost = window.location.host;
const urlParams = new URLSearchParams(window.location.search);

const ApiUrlString =
  URL_MAPPING[currentHost]["api_host"] || URL_MAPPING["default"]["api_host"];
const ApiObject = {
  Api: ApiUrlString,
  env: "development",
};
let sessionId =
  cookie.load("sessionID") ||
  urlParams.get("q");

axios.defaults.headers["Authorization"] = `Bearer ${sessionId}`;

const axiosInstance = axios.create({
  baseURL: ApiObject.Api,
});

axiosInstance.interceptors.request.use(
  (req) => {
    // Add configurations here
    req.headers["Content-Type"] = "application/json";
    req.headers["Authorization"] = `Bearer ${sessionId}`;
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    return error;
  }
);

export default axiosInstance;
