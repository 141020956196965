import {
  Grid,
  Checkbox,
  Button,
  LinearProgress,
  List,
  ListItem,
  Box,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Tooltip,
  IconButton,
  CircularProgress,
} from "@mui/material";
import noSearchimg from "../../../assets/images/no-search.svg";
import _ from "lodash";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

import React, { Component } from "react";

export default class ItemList extends Component {
  render() {
    const {
      data,
      isItemSelected,
      handleItemClick,
      lastPage,
      currentPage,
      loading,
      loadMore,
      type,
      searchValue,
      showSelectedLinked,
    } = this.props;

    if (showSelectedLinked) {
      return (
        <>
          {data?.length > 0 && (
            <Box className="LinkUnlinkCard">
              <Box className="link-items-list selected-list">
                <List sx={{ width: "100%" }}>
                  {data.map((item, id) => {
                    return (
                      <>
                        <ListItem key={id} disablePadding>
                          <ListItemIcon>
                            <IconButton
                              className="selected-remove-btn"
                              onClick={(event) =>
                                handleItemClick(event, item, type)
                              }
                            >
                              <RemoveCircleIcon />
                            </IconButton>
                          </ListItemIcon>
                          <ListItemText
                            multiline
                            primaryTypographyProps={{
                              component: "p",
                              variant: "body1",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                            primary={
                              <>
                                <Tooltip
                                  title={`Item Name: ${_.unescape(item.name)}`}
                                  disableInteractive
                                  placement="bottom-start"
                                >
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      fontWeight: 500,
                                      width: "100%",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {_.unescape(item.name)}
                                  </Typography>
                                </Tooltip>
                              </>
                            }
                            secondary={
                              <>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Tooltip
                                    title={`Item Code: ${_.unescape(
                                      item.code
                                    )}`}
                                    disableInteractive
                                    placement="bottom-start"
                                  >
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        width: "50%",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                      }}
                                    >
                                      {_.unescape(item.code)}
                                    </Typography>
                                  </Tooltip>
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      color: (theme) => theme.palette.grey[500],
                                    }}
                                  >
                                    ITEM UNIT <b> {_.unescape(item.unit)} </b>
                                  </Typography>
                                </Box>
                              </>
                            }
                          />
                        </ListItem>
                      </>
                    );
                  })}
                </List>
              </Box>
            </Box>
          )}
        </>
      );
    }
    return (
      <>
        {loading && <LinearProgress />}

        {data?.length > 0 ? (
          <List sx={{ width: "100%" }}>
            {data.map((item, id) => {
              const ItemSelected = isItemSelected(item, type);
              return (
                <>
                  <ListItem key={id} disablePadding sx={{ display: "block" }}>
                    <ListItemButton
                      role={undefined}
                      dense
                      onClick={(event) => handleItemClick(event, item, type)}
                    >
                      <ListItemIcon>
                        <Checkbox edge="start" checked={ItemSelected} />
                      </ListItemIcon>
                      <ListItemText
                        multiline
                        primaryTypographyProps={{
                          component: "p",
                          variant: "body1",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                        primary={
                          <>
                            <Tooltip
                              title={`Item Name: ${_.unescape(item.name)}`}
                              disableInteractive
                              placement="bottom-start"
                            >
                              <Typography
                                variant="body1"
                                sx={{
                                  fontWeight: 500,
                                  width: "100%",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                }}
                              >
                                {_.unescape(item.name)}
                              </Typography>
                            </Tooltip>
                          </>
                        }
                        secondary={
                          <>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Tooltip
                                title={`Item Code: ${_.unescape(item.code)}`}
                                disableInteractive
                                placement="bottom-start"
                              >
                                <Typography
                                  variant="body2"
                                  sx={{
                                    width: "50%",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                  }}
                                >
                                  {_.unescape(item.code)}
                                </Typography>
                              </Tooltip>
                              <Typography
                                sx={{
                                  fontSize: 14,
                                  color: (theme) => theme.palette.grey[500],
                                }}
                              >
                                ITEM UNIT <b> {_.unescape(item.unit)} </b>
                              </Typography>
                            </Box>
                          </>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                </>
              );
            })}
          </List>
        ) : (
          !loading && (
            <>
              <Box className="no-search">
                <img src={noSearchimg} />
                <Typography component="p">
                  {searchValue ? (
                    <>
                      There are no Item(s) like <b>{searchValue}</b>
                    </>
                  ) : (
                    "There are no Item(s)"
                  )}
                </Typography>
              </Box>
            </>
          )
        )}

        {lastPage !== currentPage && data.length > 0 && (
          <Grid
            container
            alignItems="center"
            spacing={2}
            className="categorydialog_card_content card_content_border"
          >
            <Grid item xs={12}>
              <Box sx={{ textAlign: "center" }}>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  sx={{
                    m: "16px auto",
                  }}
                  onClick={loadMore}
                  endIcon={loading && <CircularProgress size={15} />}
                >
                  Load More
                </Button>
              </Box>
            </Grid>
          </Grid>
        )}
      </>
    );
  }
}
