import {
  SET_ACCOUNT_LEVEL_INFO,
  SET_ACCOUNT_LEVEL_INFO_ERROR,
  SET_ACCOUNT_MODULE_PERMISSION,
  SET_ACCOUNT_MODULE_PERMISSION_ERROR,
  SET_FEATURE_PERMISSION,
  SET_FEATURE_PERMISSION_ERROR,
  SET_MASTER_PERMISSION,
  SET_MASTER_PERMISSION_ERROR,
} from "../constants/type";
import {
  getAccountLevelInfo,
  getAccountModulePermission,
  getFeaturePermission,
  getMasterPermission,
} from "../../config/apiUrl";
import axios from "axios";

export const setAccountLevelInfo = (division_id, sessionId) => (dispatch) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${sessionId}`;
  const url = getAccountLevelInfo(division_id);
  axios
    .get(url)
    .then((res) => {
      dispatch({
        type: SET_ACCOUNT_LEVEL_INFO,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: SET_ACCOUNT_LEVEL_INFO_ERROR,
        payload: err.message,
      });
    });
};

export const setAccountModulePermission =
  (division_id, sessionId) => (dispatch) => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${sessionId}`;
    const url = getAccountModulePermission(division_id);
    axios
      .get(url)
      .then((res) => {
        dispatch({
          type: SET_ACCOUNT_MODULE_PERMISSION,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: SET_ACCOUNT_MODULE_PERMISSION_ERROR,
          payload: err.message,
        });
      });
  };

export const setMasterPermission = (division_id, sessionId) => (dispatch) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${sessionId}`;
  const url = getMasterPermission(division_id);
  axios
    .get(url)
    .then((res) => {
      dispatch({
        type: SET_MASTER_PERMISSION,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: SET_MASTER_PERMISSION_ERROR,
        payload: err.message,
      });
    });
};

export const setFeaturePermission = (division_id, sessionId) => (dispatch) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${sessionId}`;
  const url = getFeaturePermission(division_id);
  axios
    .get(url)
    .then((res) => {
      dispatch({
        type: SET_FEATURE_PERMISSION,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: SET_FEATURE_PERMISSION_ERROR,
        payload: err.message,
      });
    });
};
