// import { Grid, Paper } from "@mui/material";
import React, { Component } from "react";
import { connect } from "react-redux";
// import Appbar from "../components/Appbar/Appbar";
import Snackbar from "../components/Snackbar/Snackbar";


export class Home extends Component {
  render() {
    return (
      <div>
        {/* <Appbar /> */}
        <Snackbar />
        {/* <VerticalBlueSection /> */}

        {this.props.children}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  // sessionId: state.sessionReducer.sessionId,
  // categoryItems: state.categoryReducer.categoryItems,
  // loading: state.categoryReducer.loading,
  // noDataFound: state.categoryReducer.noDataFound,
  // error: state.categoryReducer.error,
  selectedItem: state.itemReducer.selectedItem,
});
export default connect(mapStateToProps)(Home);
