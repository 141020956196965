import { Grid, TextField, Backdrop, CircularProgress,IconButton, Box, Button, Dialog} from "@mui/material";
import React, { Component, createRef } from "react";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import CloseIcon from '@mui/icons-material/Close';
import { postCategory } from "../../../config/apiUrl";
import {
  openSnackbar,
  closeSnackbar,
} from "../../../redux/actions/snackbarAction";
import { connect } from "react-redux";
import axios from "axios";
import CategoryImport from "./CategoryImport";

class AddCategoryDialog extends Component {
  constructor(props) {
    super(props);
    this.descriptionElementRef = createRef(null);
  }
  state = {
    error: {
      name: false,
      code: false,
      CCUserEmailAdrresses: false,
    },
    formFields: {
      name: "",
      code: "",
      specification: "",
      CCUserEmailAdrresses: "",
      tandc: "",
    },
    loading: false,
    importCategoryOpen: false,
    categoryDetails: {}
  };
  componentDidMount() {
    // in order to wait for the element to be actually drawn, using setTimeout
    setTimeout(() => {
      if (this.descriptionElementRef.current !== null) {
        this.descriptionElementRef.current.focus();
      }
    }, 0);
  }
  handleChange = (e) => {
    const { name, value } = e.target;
    const { formFields } = this.state;
    formFields[name] = value;
    this.setState({
      formFields,
    });
  };
  handleBlur = (e) => {
    const { name, value } = e.target;
    const { error } = this.state;
    if (!value.length) {
      error[name] = true;
      // console.log(name, "error");
    } else {
      error[name] = false;
      // console.log(name, "clear");
    }
    if (name === "CCUserEmailAdrresses"){
      let flag = false;
      if (value){
        value
          .trim()
          .split(",")
          .forEach((email) => {
            if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
              flag = true;
            }
          });
      }
      error[name] = flag;
    }
    this.setState({ error });
  };
  handleSubmit = () => {
    const { name, code, specification, CCUserEmailAdrresses, tandc } =
      this.state.formFields;

    let flag = false;

    if (!this.state.formFields.name.length) {
      flag = true;
      
      this.setState((prevState) => ({
        ...prevState,
        error: {
          ...prevState.error,
          name:true
        },
      }));
      // this.state.error["name"] = true;
    }

    if (!this.state.formFields.code.length) {
      flag = true;
      // this.state.error["code"] = true;
      this.setState((prevState) => ({
        ...prevState,
        error: {
          ...prevState.error,
          code:true
        },
      }));
    }

    if (this.state.formFields.CCUserEmailAdrresses.length){
      this.state.formFields.CCUserEmailAdrresses
        .trim()
        .split(",")
        .forEach((email) => {
          if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            flag = true;
            this.setState((prevState) => ({
              ...prevState,
              error: {
                ...prevState.error,
                CCUserEmailAdrresses:true
              },
            }));
          }
        });
    }

    // this.setState({
    //   error: this.state.error,
    // });

    if (flag) {
      this.props.openSnackbar("error", "Please resolve errors");
      return;
    }

    this.setState({
      loading: true,
    });

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;
    axios
      .post(postCategory, {
        name: name,
        code: code,
        specifications: specification,
        cc_emails: CCUserEmailAdrresses,
        terms_and_condition: tandc,
      })
      .then((res) => {
        this.setState({
          loading: false,
          formFields: {
            name: "",
            code: "",
            specification: "",
            CCUserEmailAdrresses: "",
            tandc: "",
          },
          error: {
            name: false,
            code: false,
          },
        });
        this.props.openSnackbar("success", "category added successfully", true);
        this.props.handleClose();
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });

        if (err.response?.data?.action_required === "Y"){
          // console.log("action_required")
          this.setState({
            importCategoryOpen: true,
            categoryDetails: err.response.data.category_detail
          })
          return;
        }
        
        if (err.response?.data?.validation_errors) {
          let errorObj = err.response.data.validation_errors;
          let errorMsg = "";
          for (const key in errorObj) {
            errorObj[key].forEach((msg) => {
              errorMsg = `${errorMsg && `${errorMsg}, `}${msg}`;
            });
          }
          this.props.openSnackbar("error", errorMsg);
        } else {
          this.props.openSnackbar("error", "something went wrong!");
        }
      });
  };
  render() {
    const { open, handleClose } = this.props;
    return (
      <div>
        
        <Dialog          
          scroll="body"
          fullWidth
          maxWidth="md"
          open={open}
          // onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <Backdrop
            style={{ color: "#fff", zIndex: "10000000000" }}
            open={this.state.loading}
          >
            <CircularProgress style={{ color: "#fff" }} />
          </Backdrop>
          <DialogTitle
            id="responsive-dialog-title"            
            sx={{
              '&:focus-visible': {
                border: 'none',
                outline: 'none'
              }
            }}
            ref={this.descriptionElementRef}
            tabIndex={-1}
          >
            Add Category
            <Box className="dialog-title-action">
              <IconButton size="small" onClick={this.props.handleClose} sx={{ color: (theme) => theme.palette.bluegrey[500]}}>
                <CloseIcon />
              </IconButton>
            </Box> 
          </DialogTitle>
          <DialogContent sx={{paddingTop: '20px !important'}}>
            
              <Grid container spacing={2}>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <TextField
                    className="local_template_textfield"
                    label="Category Name"
                    name="name"
                    inputProps={{
                      maxLength:100
                    }}
                    required
                    variant="outlined"
                    error={this.state.error.name}
                    value={this.state.formFields.name}
                    onChange={(e) => this.handleChange(e)}
                    onBlur={(e) => this.handleBlur(e)}
                    helperText={this.state.error.name ? "Required" : ""}
                    fullWidth                    
                  />
                  {/* <OutlineTextfieldLabel
                    label="Category Name"
                    name="name"
                    value={this.state.formFields.name}
                    onChange={(e) => this.handleChange(e)}
                    onBlur={(e) => this.handleBlur(e)}
                    fullWidth
                    required
                    error={this.state.error.name}
                    helperText={this.state.error.name ? "required" : ""}
                    
                    InputLabelProps={{
                      shrink: true,
                    }}
                  /> */}
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <TextField
                    className="local_template_textfield"
                    label="Category Code"
                    name="code"
                    inputProps={{
                      maxLength:25
                    }}
                    value={this.state.formFields.code}
                    onChange={(e) => this.handleChange(e)}
                    onBlur={(e) => this.handleBlur(e)}
                    fullWidth
                    required
                    error={this.state.error.code}
                    helperText={this.state.error.code ? "Required" : ""}
                    
                  />
                  {/* <OutlineTextfieldLabel
                    label="Category Code"
                    name="code"
                    value={this.state.formFields.code}
                    onChange={(e) => this.handleChange(e)}
                    onBlur={(e) => this.handleBlur(e)}
                    fullWidth
                    required
                    error={this.state.error.code}
                    helperText={this.state.error.code ? "Required" : ""}
                    
                    InputLabelProps={{
                      shrink: true,
                    }}
                  /> */}
                </Grid>
                <Grid
                  item
                  xl={6}
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  className="custom_label_textarea"
                >
                  <TextField
                    className="local_template_textfield"
                    fullWidth
                    name="specification"
                    inputProps={{
                      maxLength:200
                    }}
                    value={this.state.formFields.specification}
                    onChange={(e) => this.handleChange(e)}
                    label="Category Specifications "
                    aria-label="empty textarea"
                  />
                  {/* <label className="custom_labels ">
                    Category Specifications
                  </label>
                  <CustomTextarea
                    name="specification"
                    value={this.state.formFields.specification}
                    onChange={(e) => this.handleChange(e)}
                    label="Product Code "
                    aria-label="empty textarea"
                    placeholder="Empty"
                  /> */}
                </Grid>
                <Grid
                  item
                  xl={6}
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  className="custom_label_textarea"
                >
                   <TextField
                    className="local_template_textfield"
                    fullWidth
                    name="CCUserEmailAdrresses"
                    value={this.state.formFields.CCUserEmailAdrresses}
                    onBlur={this.handleBlur}
                    error={this.state.error.CCUserEmailAdrresses}
                    onChange={(e) => this.handleChange(e)}
                    label="CC Users Email Addresses"
                    aria-label="empty textarea"
                    helperText="(Please enter multiple cc users email addresses seperated by comma ' , ')"
                  />
                  {/* <label className="custom_labels ">
                    CC Users Email Addresses
                  </label>
                  <CustomTextarea
                    name="CCUserEmailAdrresses"
                    value={this.state.formFields.CCUserEmailAdrresses}
                    onChange={(e) => this.handleChange(e)}
                    helperText="(Please enter synonyms seperated by semi-colon ' ; ')"
                  />*/}
                </Grid> 
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="custom_label_textarea"
                >
                  <TextField
                    className="local_template_textfield"
                    name="tandc"
                    multiline
                    maxRows={3}
                    inputProps={{
                      maxLength:200
                    }}
                    value={this.state.formFields.tandc}
                    onChange={(e) => this.handleChange(e)}
                    label="Terms & Conditions"
                    aria-label="empty textarea"
                    // placeholder="Empty"
                    fullWidth
                    
                  />
                  {/* <label className="custom_labels ">Terms & Conditions</label>
                  <CustomTextarea
                    name="tandc"
                    value={this.state.formFields.tandc}
                    onChange={(e) => this.handleChange(e)}
                    label="Product Code *"
                    aria-label="empty textarea"
                    placeholder="Empty"
                  /> */}
                </Grid>
              </Grid>            
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose} variant="outlined" color="primary">
              Cancel
            </Button>
            <Button variant="contained" color="primary"
              onClick={() => {
                this.handleSubmit();
              }}
              autoFocus
            >
              Add Category
            </Button>
          </DialogActions>
        </Dialog>

        <CategoryImport 
          open={this.state.importCategoryOpen}
          handleClose={() => {
            this.setState({
              importCategoryOpen: false
            })
          }}
          categoryDetails={this.state.categoryDetails}
          sessionId={this.props.sessionId}
          onSuccess = {() => {
            this.props.openSnackbar("success", "Category successfully imported to the current division!" )
            handleClose();
          }}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  sessionId: state.sessionReducer.sessionId,
  snackbarOpen: state.snackbarReducer.open,
});
export default connect(mapStateToProps, {
  openSnackbar,
  closeSnackbar,
})(AddCategoryDialog);
