import { OutlinedInput, TextareaAutosize, TextField } from "@mui/material";
import styled from "styled-components";
import {Autocomplete} from "@mui/material";

export const OutlineTextfieldLabel = styled(TextField)`
  &.MuiFormControl-fullWidth {
    margin: 0 !important;
  }
  .MuiInputBase-input {
    height: 20px;
    border: none;
    background: var(--thm-white);
    border: 1.5px solid var(--thm-gray);
    border: 1.5px solid var(--border-outline);
    border-radius: 4px;
    padding: 12px 10px;
  }
  .MuiInput-underline:before {
    border: none;
  }
  .MuiInput-underline:after,
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border: none;
  }
  label {
    font-size: 18px;
    line-height: 17px;
    text-align: left;
    letter-spacing: 0.7px;
    color: var(--text-color);
    font-weight: 400;
  }
  label + .MuiInput-formControl {
    margin-top: 20px;
  }
  .MuiFormLabel-root.Mui-focused {
    color: var(--text-color);
  }
`;

export const OutlineTextfieldDropdown = styled(Autocomplete)`
  .MuiFormControl-fullWidth {
  }
  .MuiInputBase-root {
    border: none;
    background: var(--thm-white);
    border-radius: 4px;
  }
  .fieldset {
    border: none;
    outline: none;
  }
  .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    min-width: 155px;
  }
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
    .MuiAutocomplete-input {
    padding: 7.5px 4px;
    font-size: 14px;
  }
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color:solid var(--border-outline);
  }
  .MuiFormLabel-root.Mui-focused {
    color: var(--text-color);
  }
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 5px;
    /* border: 1.5px solid var(--thm-gray); */
  }
  .MuiOutlinedInput-notchedOutline {
    /* border: none; */
  }
  .MuiIconButton-root svg {
    color: var(--border-outline);
  }
`;

export const CustomTextarea = styled(TextareaAutosize)`
  border: none !important;
  background: var(--thm-white);
  border-radius: 3px;
  outline: none;
  border: 1.5px solid var(--thm-gray) !important;
  width: 100%;
  height: 47px !important;
  padding: 10px;
 
`;

export const CusotmsearchInput = styled(OutlinedInput)`
  &.MuiOutlinedInput-root {
    height: 40px;    
    padding: 12px 0 10px;
    width: 260px;
    max-width:260px;
    background: var(--color-white);
    border-radius: 4px;
  }
  &.MuiOutlinedInput-root input{
    font-size: 14px;    
  } 
`;

export const FlatsearchInput = styled(OutlinedInput)`
  &.MuiOutlinedInput-root {
    width: 100%;
    height: 38px;
    background: var(--grey-100);
    border-radius: 0;    
  }
  & .MuiOutlinedInput-notchedOutline { 
    border: 0;
    border-radius: 0;
  }
  &.MuiOutlinedInput-root .search-clear-btn{
    color: var(--grey-500);
  }
  &.MuiOutlinedInput-root .search-clear-btn svg{
    width: 18px;
    height: 18px;    
  } 
  &.MuiOutlinedInput-root .MuiInputAdornment-root svg{
    fill: var(--grey-600);
  }
`;

export const CusotmInputWithoutborder = styled(OutlinedInput)`
  &.MuiOutlinedInput-root {
    height: 47px;
    border: none !important;
    background: transparent;
    border: none !important;
    border-radius: 4px;
    padding: 12px 10px;
    width: 100%;
    color: var(--thm-gray);
  }
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
  .MuiOutlinedInput-notchedOutline,
  &.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;
