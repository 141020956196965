export const resetApp = () => (dispatch) => {
  dispatch({
    type: "RESET_APP",
  });
};

export const resetCAT = () => (dispatch) => {
  dispatch({
    type: "RESET_CAT",
  });
};
