/* for react build and serve */
import React from "react";
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import App from "./App";
import "./App.css";
import store from "./redux/store";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {
	createBrowserHistory
} from 'history';
import VendorApp from "./VendorApp";

// To solve routing issues in S3 (static webhost)
const replaceHashPath = () => {
	console.log("Inside replaceHashPath")
	const history = createBrowserHistory()
	const hash = history.location.hash
	if (hash) {
		const path = hash.replace(/^#/, '')
		if (path) {
			history.replace(path)
		}
	}
}
replaceHashPath();
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<Provider store={store}>
		<React.StrictMode>
			<BrowserRouter basename="/purchase-product">
				<App />
			</BrowserRouter>
			<BrowserRouter basename="/vendors">
				<VendorApp />
			</BrowserRouter>
		</React.StrictMode>
	</Provider>
);

serviceWorkerRegistration.register();


/* for webpack build */
// import * as React from "react";
// import { useState } from "react";

// import { createRoot } from "react-dom/client";
// import { AppMainStyle } from "./theme/AppMainStyle";
// import theme from "./theme/theme";
// import "./App.css";
// import { CssBaseline, ThemeProvider } from "@mui/material";
// import { LicenseInfo } from "@mui/x-data-grid-pro";
// import store from "./redux/store";
// import { Provider } from "react-redux";
// import RfqDataGrid from "./pages/RFQList/rfqGridView/rfqDataGrid";
// import Footer from "./layout/footer/Footer";

// export default class contract_utilization {
// 	constructor() {
// 		LicenseInfo.setLicenseKey(
// 			"b1049892cfa98210c595cfcb9e00d60aTz02MDY1MCxFPTE3MDg4NTY0ODc4NjYsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
// 		);
// 	}

// 	rfqGrid(props, htmlElementId = null) {
// 		const componentEle = (
// 			<Provider store={store}>
// 				<ThemeProvider theme={theme}>
// 					<div className="App">
// 						<CssBaseline />
// 						{AppMainStyle}
// 						<RfqDataGrid {...props} />
// 					</div>
// 					<Footer />
// 				</ThemeProvider>
// 			</Provider>
// 		);

// 		let element = document.getElementById(htmlElementId);
// 		if (htmlElementId && element) {
// 			return createRoot(element).render(componentEle);
// 		}
// 		return componentEle;
// 	}
// }
