import { Component, createRef } from "react";
import { Dialog, CircularProgress, Backdrop } from "@mui/material";
import _ from "lodash";
import { TBIframeUri } from "../../../config/apiUrl";

class IframeModel extends Component {
  constructor(props) {
    super(props);
    this.descriptionElementRef = createRef(null);
    this.state = { iframeParam: "",scrolling: "auto" };
  }

  componentDidMount() {
    let {
      currentAction,
      tb_id,
      pre_fetch_inquiry_id,
      itemId,
      oldItemId
    } = this.props.currentTBDetails;
   
    let {
      sessionId,
      randomId,
      activity_randomId,
      categoryId,
      defaultTBValuePermission,
      tb_data,
      pr_has_tb
    } = this.props;
    
    let {
      pr_details,
      item_pr_mapping
    } = this.props.currentTBDetailsPR;
    let current_stage_status = 'none';

    if(this.props?.tbStageStatus){
      current_stage_status = itemId ? this.props?.tbStageStatus[itemId] : this.props?.tbStageStatus[0];
    }

    let params = `from_iframe=1&sessionID=${sessionId}&productid=${categoryId}&random_number=${randomId}&tb_stage=${
      current_stage_status || "none"
    }`;
    if(currentAction === "activityPreview" || currentAction === "activityImport"){
      params = `from_iframe=1&sessionID=${sessionId}&productid=${categoryId}&random_number=${activity_randomId}&tb_stage=${
        current_stage_status || "none"
      }`;
    }
    
    if(pre_fetch_inquiry_id){
      params = params + `&pre_fetch_inquiry_id=${pre_fetch_inquiry_id}`;
    }

    if(defaultTBValuePermission){
      params = params + `&default_value_fetch_from_master=1`;
    }

    if (currentAction === "edit") {
      params =
        params + `&action=20&tb_id=${tb_id || 0}`;
    } else if (currentAction === "link") {
      params =
        params + `&action=10&tb_id=${tb_id || 0}`;
    } else if (currentAction === "editItemizeTB") {
      params =
        params + `&action=20&tb_id=${tb_id || 0}&item_id=${oldItemId}`;
    } else if (currentAction === "linkItemizeTB") {
      params =
        params + `&action=10&tb_id=${tb_id || 0}&item_id=${oldItemId}`;
    } else if (currentAction === "PreviewtechnicalBid") {
        params =
          params + `&action=40&tb_id=${tb_id || 0}&item_id=${oldItemId}`;
        if( pr_has_tb ) params += `&pr_has_tb_flag=${pr_has_tb}`;
    }else if (currentAction === "vendxRating") {
      params =
        params + `&action=30`;
        this.setState({
          scrolling : "no"
        });
    }else if (currentAction === "activityPreview") {
      params =
        params + `&action=50&tb_id=${tb_id || 0}`;
    }
    else if (currentAction === "activityImport") {
      params =
        params + `&action=60&tb_id=${tb_id || 0}&vendx_common=${pre_fetch_inquiry_id}&itemId=${itemId}&category_name=${oldItemId}&pr_details=${pr_details}&item_pr_mapping=${item_pr_mapping}`;
    }
    this.setState({
      iframeParam: params,
    });
  }
  onLoadIfrmae = () => {
    this.props.setDeafultLoading(false);
    if (!document.getElementById("myForm")) {
      const iframe = document.getElementById("tb-iframe");
      iframe.contentWindow.postMessage(
        "[IframeModel.js][tb-iframe][postMessage] : message from react code",
        "*"
      );
    }
  };

  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          fullWidth={true}
          maxWidth="false"
          hideBackdrop={this.props.iframeDefaultLoading}
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
              height:"100%"
            },
          }}
        >
          <Backdrop
            style={{ color: "#fff", zIndex: "10000000000" }}
            open={this.props.iframeDefaultLoading}
          >
            <CircularProgress style={{ color: "#fff" }} />
          </Backdrop>
          <iframe
            id="tb-iframe"
            name="tb-iframe"
            onLoad={(e) => {
              this.onLoadIfrmae();
            }}
            src={`${TBIframeUri}?${this.state.iframeParam}`}
            scrolling={`${this.state.scrolling}`}
            width="100%"
            height="100%"
            style={{ height: "100%", border: "none" }}
            // sandbox="allow-scripts"
          />
        </Dialog>
      </div>
    );
  }

}

export default IframeModel;