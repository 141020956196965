import React, { Fragment } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Box,
  Divider,
} from "@mui/material";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import { Close as CloseIcon } from "@mui/icons-material";

const DestinationDetailsDialog = ({ open, handleClose, plantData }) => {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "2px 15px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <RoomOutlinedIcon />
          <Typography
            variant="body1"
            sx={{ fontWeight: "bold", paddingLeft: "8px" }}
          >
            View Destination
          </Typography>
        </Box>
        <IconButton
          edge="end"
          sx={{ color: (theme) => theme.palette.bluegrey[500] }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: "0px" }} dividers>
        {plantData.slice(1).map((item, index) => (
          <Fragment key={index}>
            <Box sx={{ padding: "5px 16px" }}>
              <Typography variant="body2">{item.plant_name}</Typography>
              <Typography variant="caption" display="block" color="text.secondary">
                {item.plant_code}
              </Typography>
            </Box>
            {index !== plantData.length - 1 && <Divider variant="fullWidth" />}
          </Fragment>
        ))}
      </DialogContent>
    </Dialog>
  );
};

export default DestinationDetailsDialog;
