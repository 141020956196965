import { 
  SET_RFQ_SETTINGS, 
  REMIDER_DRAWER, 
  RESET_REMINDER_DRAWER,
  SET_INCLUDE_TB,
  SET_ACTIVITY_TEMPLATE
} from "../constants/type";

export const setRFQSettings = (rfqSettingValues) => (dispatch) => {
  dispatch({
    type: SET_RFQ_SETTINGS,
    payload: rfqSettingValues,
  });
};
export const setActivityTemplate = (activityTemplateValues) => (dispatch) => {
  dispatch({
    type: SET_ACTIVITY_TEMPLATE,
    payload: activityTemplateValues,
  });
};

export const setReminderSettings = (remiderDrawer) => (dispatch) => {
  dispatch({
    type: REMIDER_DRAWER,
    payload: remiderDrawer,
  });
};

export const resetReminderSettings = () => (dispatch) => {
  console.log("Resetting reminder drawer actions ..!");
  dispatch({
    type: RESET_REMINDER_DRAWER,
  });
}

export const setIncludetbvalue = (value)=>(dispatch) => {
  console.log("Resetting reminder drawer actions ..!");
  dispatch ({
    type: SET_INCLUDE_TB,
    payload: value,
  });
}