import React from "react";
import { makeStyles } from "@mui/styles";
import { useSelector, useDispatch } from "react-redux";
import { closeSnackbar } from "../../redux/actions/snackbarAction";
import { CustomAlert, CustomSnackbar } from "./style";
import { Link } from '@mui/material';
import { masterHistoryRedirectUri } from '../../config/apiUrl';
import "./style";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

export default function SnackbarComponent(props) {
  const classes = useStyles();
  const [masterLink, setMasterLink] = React.useState("")
  const dispatch = useDispatch();
  const { open, type, msg, isMasterAction } = useSelector((reducer) => reducer.snackbarReducer);
  const { userInfo } = useSelector((reducer) => reducer.userInfoReducer);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      dispatch(closeSnackbar());
    }
    dispatch(closeSnackbar());
  };

  React.useEffect(() => {
    // setMasterLink(`${window.location.hostname}/cgi-bin/vendx/masters/masters_index.cgi?vendx_action=masterconfig&vendx_master_action=logmaster&buyerid=${userInfo?.division_id}&accountid=1`)
    setMasterLink(masterHistoryRedirectUri(userInfo?.division_id))
  }, [userInfo.division_id])
  
  const action = (
    <React.Fragment>
      <Link
        target="_blank"
        rel="noopener noreferrer"
        variant="body2"
        href={masterLink}
        sx={{ ml:1, fontWeight:600}}
        color="grey.900"
      >
        View Log
      </Link>
    </React.Fragment>
  );

  return (
    <div className={classes.root}>
      <CustomSnackbar
        open={open}
        autoHideDuration={4000}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
        onClose={handleClose}
        // className="snackbar_bg"
      >
        {<CustomAlert onClose={handleClose} severity={type} variant='filled'>
          {msg}
          {type === "success" && isMasterAction && action}
        </CustomAlert>}
      </CustomSnackbar>
    </div>
  );
}
