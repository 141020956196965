export const styles = theme => ({
    TemplateDataGrid: {
        backgroundColor: theme.palette.grey[0],

        '& .MuiDataGrid-pinnedColumnHeaders': {
            backgroundColor: theme.palette.grey[50],
        },
        '& .MuiDataGrid-pinnedColumns': {
            backgroundColor: theme.palette.grey[0],
        },        
    },
    '& .notes_dialogs_templates': {
        fontSize: 'calc(12px + (14 - 12) * ((100vw - 300px) / (1920 - 300))) !important',
        backgroundColor: 'red',
    }
});