import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
// import "../../../pages/style.css";
import _, { debounce } from "lodash";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Alert, Badge, Fab, Modal, ToggleButton, Tooltip } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { ResizableBox } from "react-resizable";
// import "./resize.style.css";
// import More from "../More/More";
import Upload from "../../Dialogs/Upload/Upload";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import QuotationFormField from "../QuotationFormField";
import { styled } from "@mui/material/styles";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  List,
  ListItem,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { Close as CloseIcon } from "@mui/icons-material";
import LandedCostRFQDrawer from "../LandedCostRFQDrawer/LandedCostRFQDrawer";
import UploadDynamicView from "../../Dialogs/Upload/UploadDynamicView";
import { postFileSpecifications } from "../../../config/apiUrl";
import axios from "axios";
import { getSymbol } from "../../AccountTemplate/CurrencySymbol";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

// const PageToolbar = styled("div")(({ theme }) => ({

// export default class QuotationFormSectionTable extends Component {
export default function QuotationFormSectionTable(props) {
  const [disabledProps, setDisabledProps] = useState({});
  const [switchDisabledProps, setSwitchDisabledProps] = useState({});
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [updatedFileValue, setUpdatedFileValue] = useState([]);
  const [popHash, setPopHash] = useState({});
  const [modalData, setModalData] = useState([]);
  const [fileSpecificationsOpen, setFileSpecificationsOpen] = useState(false);
  const [landedCostRFQ, setLandedCostRFQ] = useState({
    // Start Total Landed Cost For RFQ Drawer script
    right: false,
  });
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [state, setState] = useState({
    isScrollActive: false,
    currentItemIndex: null,
    anchorEl: null,
    qty: "",
    rate: "",
    estimatedValueOpen: false,
  });
  const [Loading, setLoading] = useState(true);
  const [AllItemsTemplate, setAllItemsTemplate] = useState([]);
  const [specification, setSpecification] = useState([]);
  const [SpecificationText, setSpecificationText] = useState("");
  const [unit, setUnit] = useState("");
  const [templateData, setTemplateData] = useState({});
  const [rows, setRows] = useState(props.msmainTemplateQuotation || {}); // Initial state with one row
  const [editableValues, setEditableValues] = useState({});
  const [destinationCount, setDestinationCount] = useState(0);
  const [Errors, setErrors] = useState([]);
  const [RearrangedArray, setRearrangedArray] = useState([]);
  const [TotalCostFIeld, setTotalCostFIeld] = useState({});
  const [PerCost, setPerCost] = useState({});
  const [RateField, setRateFIeld] = useState({});
  const [FilteredMainTemplate, setFilteredMainTemplate] = useState({});
  const [hoverTooltip, setHoverTooltip] = useState(false);

  useEffect(() => {
    if (props.destination) {
      const destinationsArray = props.destination.split("###");
      setDestinationCount(destinationsArray?.length);
    }
  }, [props.destination]);

  // Handler to update editable values
  const handleInputChange = (e, key, headerRows, id) => {
    const newValue = e.target.value;
    setEditableValues((prevState) => ({
      ...prevState,
      [key]: newValue, // Store the new value for the corresponding row key
    }));
    setRows(headerRows);
  };

  const addRow = (key, headerRows, solutions) => {
    headerRows[key].push({
      id: headerRows[key].length + 1,
      data: solutions,
      name: "",
      mainData: props.mainTemplateQuotation[key],
    });
    // setRows({ ...rows, [key]: [...rows[key], headerRows[key][headerRows[key].length - 1]] });
    setRows(headerRows);
  };

  const deleteRow = (id, key, headerRows) => {
    if (id !== 1) {
      // Prevent deletion of the first row
      headerRows[key] = headerRows[key].filter((row) => row.id !== id);
      setRows(headerRows);
    }
  };

  useEffect(() => {
    const filterMainTemplate = {};

    Object.keys(props.mainTemplateQuotation).forEach((key) => {
      if (props.mainTemplateQuotation[key]?.selected === "Y") {
        filterMainTemplate[key] = props.mainTemplateQuotation[key];
      }
    });
    setFilteredMainTemplate(filterMainTemplate);
  }, [props.mainTemplateQuotation]);

  useEffect(() => {
    if (props.data && props.data[0]) {
      // Convert templateJson to key-value pairs
      const keyValueData = {};
      props.data[0].forEach((item) => {
        keyValueData[item.FieldId] = item;
      });
      // Update state with key-value data
      setTemplateData(keyValueData);
    }
  }, [props.data]);

  function rearrangeArray(array) {
    // Step 1: Build a mapping of FieldId to their respective objects
    let fieldMap = {};
    array.forEach((item) => {
      fieldMap[item.FieldId] = item;
    });

    // Step 2: Initialize an empty array to store the rearranged items
    let rearranged = [];

    // Step 3: Helper function to recursively resolve dependencies
    const resolveDependency = (identifier, visited) => {
      if (visited.has(identifier)) {
        throw new Error(
          "Circular dependency detected for FieldId: " + identifier
        );
      }

      visited.add(identifier);

      // Get the item for the current identifier
      const item = fieldMap[identifier];

      // Resolve dependencies recursively
      const matches = item.DefaultValue?.Formula?.match(/\(f\d+\)/g);
      if (matches) {
        matches.forEach((dependency) => {
          const depIdentifier = dependency.substring(2, dependency.length - 1);
          if (fieldMap.hasOwnProperty(depIdentifier)) {
            resolveDependency(depIdentifier, visited);
          }
        });
      }

      // Add the item to the rearranged array if it's not already added
      if (!rearranged.some((i) => i.FieldId === item.FieldId)) {
        rearranged.push(item);
      }

      visited.delete(identifier);
    };

    // Step 4: Iterate over the array to start resolving dependencies
    array.forEach((item) => {
      if (!rearranged.some((i) => i.FieldId === item.FieldId)) {
        resolveDependency(item.FieldId, new Set());
      }
    });

    // Return the rearranged array
    return rearranged;
  }

  const getSumOfField = (data, fieldId) => {
    let sum = 0;
    for (let key in data) {
      if (data[key][fieldId]) {
        sum += parseFloat(data[key][fieldId].value);
      }
    }
    return sum;
  };

  const arrangedEval = (formula, key, result, identifier) => {
    if (typeof formula != "undefined") {
      let pattern = /f(\d+)/g;
      const matches = [...formula.matchAll(pattern)];
      const matchedIdentifiersFormula = matches.map((match) => match[1]);
      matchedIdentifiersFormula.forEach((formulaIdentifier) => {
        if (result.hasOwnProperty(formulaIdentifier)) {
          formula = formula.replace(
            `f${formulaIdentifier}`,
            result[formulaIdentifier]
              ? !isNaN(parseFloat(result[formulaIdentifier]))
                ? parseFloat(result[formulaIdentifier])
                : `"${result[formulaIdentifier]}"`
              : 0
          );
        } else if (
          props.additionalInfoTemplate.hasOwnProperty(formulaIdentifier)
        ) {
          if (
            typeof props.additionalInfoTemplate[formulaIdentifier]?.value ===
            "string" &&
            props.additionalInfoTemplate[formulaIdentifier]?.value.includes(
              "###"
            )
          ) {
            formula = formula.replace(
              `f${formulaIdentifier}`,
              `"${props.additionalInfoTemplate[formulaIdentifier]?.value.split(
                "###"
              )[0]
              }"`
            );
          } else {
            formula = formula.replace(
              `f${formulaIdentifier}`,
              props.additionalInfoTemplate[formulaIdentifier]?.value
                ? !isNaN(
                  parseFloat(
                    props.additionalInfoTemplate[formulaIdentifier]?.value
                  )
                )
                  ? parseFloat(
                    props.additionalInfoTemplate[formulaIdentifier]?.value
                  )
                  : `"${props.additionalInfoTemplate[formulaIdentifier]?.value}"`
                : 0
            );
          }
        } else {
          if (
            !props.mainTemplateQuotation[key].hasOwnProperty(formulaIdentifier)
          ) {
            let defValue = AllItemsTemplate.find((item) => {
              return item.FieldId == formulaIdentifier;
            });
            if (
              defValue &&
              defValue.ElementType != "D" &&
              !defValue.DefaultValue.hasOwnProperty("Formula") &&
              !defValue.DefaultValue.includes("(f")
            ) {
              formula = formula.replace(
                `f${formulaIdentifier}`,
                parseFloat(defValue.DefaultValue) || 0
              );
            }
          } else {
            if (
              typeof props.mainTemplateQuotation[key]?.[formulaIdentifier]
                ?.value === "string" &&
              props.mainTemplateQuotation[key]?.[
                formulaIdentifier
              ]?.value?.includes("###")
            ) {
              formula = formula.replace(
                `f${formulaIdentifier}`,
                `"${props.mainTemplateQuotation[key]?.[
                  formulaIdentifier
                ]?.value.split("###")[0]
                }"`
              );
            } else {
              if (identifier == "TotalReqQty" || identifier == "TotalReqQtyFor") {
                const sumOfFieldQty = getSumOfField(props.mainTemplateQuotation, formulaIdentifier);
                formula = formula.replace(`f${formulaIdentifier}`, sumOfFieldQty);

              } else {

                let replaceValue = props.mainTemplateQuotation[key]?.[
                  formulaIdentifier
                ]?.value
                  ? !isNaN(
                    parseFloat(
                      props.mainTemplateQuotation[key]?.[formulaIdentifier]
                        ?.value
                    )
                  ) &&
                    isFinite(
                      props.mainTemplateQuotation[key]?.[formulaIdentifier]?.value
                    )
                    ? parseFloat(
                      props.mainTemplateQuotation[key]?.[formulaIdentifier]
                        ?.value
                    )
                    : `"${props.mainTemplateQuotation[key]?.[formulaIdentifier]?.value}"`
                  : 0;
                formula = formula.replace(`f${formulaIdentifier}`, replaceValue);
              }
            }
          }
        }
      });
      return formula;
    }
  };
  useEffect(() => {
    setTooltipOpen(true);
  }, []);

  useEffect(() => {
    let allItems = [];
    let templateData = props.template;
    let elementTypes = [];

    for (let section in templateData) {
      if (section !== "PARAM") {
        if (section === "ITEMIZE") {
          allItems = allItems.concat(templateData[section][0]);
        } else {
          allItems = allItems.concat(templateData[section]);
        }
      }
    }
    props.setItemsTemplate(allItems);
    setAllItemsTemplate(allItems);
    allItems.forEach((items) => {
      if (items.ElementType === "D") {
        elementTypes.push(items);
      }
    });
    let rearrangedArray = rearrangeArray(elementTypes);
    setRearrangedArray(rearrangedArray);
    props.setRearrangedTemplate(rearrangedArray);
    // console.log("resultinEffect", result);
  }, [props.template]);

  const handleDetailsClick = (key, unitFlag) => {
    let EvalResults = {};
    let additionalInfoElement = [];
    let formulaElementKey = [];
    RearrangedArray.forEach((defaultIdentifier) => {
      let formula = defaultIdentifier.DefaultValue.Formula;
      let newFormula = arrangedEval(formula, key, EvalResults, defaultIdentifier.Identifier);
      if (newFormula && newFormula.includes("ExchangeRate_SelectedCurrency")) {
        let exchangeRate = props.currencyOptions.find(
          (currency) =>
            currency.Currency ==
            (props.selectedCurrency || props.quotedCurrency)
        );
        if (exchangeRate) {
          newFormula = newFormula.replace(
            /ExchangeRate_SelectedCurrency/g,
            exchangeRate.ExchangeRate
          );
        }
      }

      if (
        newFormula &&
        newFormula.includes("ExchangeRate_BuyerLocalCurrency")
      ) {
        let exchangeRate = props.currencyOptions.find(
          (currency) => currency.Currency === (props.base_currency || "INR")
        );
        if (exchangeRate) {
          newFormula = newFormula.replace(
            /ExchangeRate_BuyerLocalCurrency/g,
            exchangeRate.ExchangeRate
          );
        }
      }
      // Now set the property
      if (newFormula && newFormula.includes("(f")) {
        const match = newFormula.match(/f\d+/)[0];
        newFormula = newFormula.replace(match, 0);
      }
      EvalResults[defaultIdentifier.FieldId] = parseFloat(
        eval(newFormula)
      ).toFixed(3);
      let pattern = /f(\d+)/g;
      if (formula) {
        let matches = [...formula.matchAll(pattern)];
        let matchedIdentifiersFormula = matches.map((match) => match[1]);
        formulaElementKey = formulaElementKey.concat(matchedIdentifiersFormula)
      }

      // result[key][defaultIdentifier.FieldId] = parseFloat(eval(newFormula)).toFixed(3)
    });



    if (props.mainTemplateQuotation && key !== undefined) {
      const rowData = props.mainTemplateQuotation[key];
      const RateCell = props.data[0].find(
        (cell) =>
          cell.Identifier === "Rate" ||
          cell.Identifier === "CIFRate" ||
          cell.Identifier === "RatePerLot"
      );
      const gstTypeCell =
        props.data[0].find((cell) => cell.Identifier === "GSTType") ||
        props?.commonData.find((cell) => cell.Identifier === "GSTType");

      const gstValueCell = props.data[0].find(
        (cell) => cell.Identifier === "GSTValue"
      );
      let errorMsg = [];
      let placeholderPattern = /f(\d+)/g;

      if (props.mainTemplateQuotation[key][RateCell?.FieldId]) {
        errorMsg.push(props.items[key].name);
        if (RateCell.Validation != "") {

          let replacedRate = RateCell.Validation.replace(
            placeholderPattern,
            (match, p1) => {
              // p1 is the captured group (the digits following 'f')
              return props.mainTemplateQuotation[key][match.replace("f", "")]
                .value !== undefined
                ? props.mainTemplateQuotation[key][match.replace("f", "")]
                  .value == ""
                  ? 0
                  : props.mainTemplateQuotation[key][match.replace("f", "")].value
                : match;
            }
          );
          if (!eval(replacedRate)) {
            errorMsg.push(RateCell.ErrorMessage);
          }
        }
      }

      if (props.mainTemplateQuotation[key][gstTypeCell?.FieldId]) {
        if (gstTypeCell.Validation != "") {

          let replacedGstType = gstTypeCell.Validation.replace(
            placeholderPattern,
            (match, p1) => {
              return props.mainTemplateQuotation[key][match.replace("f", "")]
                ?.value !== undefined
                ? props.mainTemplateQuotation[key][match.replace("f", "")]
                  ?.value == ""
                  ? "''"
                  : `"${props.mainTemplateQuotation[key][match.replace("f", "")]
                    ?.value
                  }"`
                : match;
            }
          );
          if (!eval(replacedGstType)) {
            errorMsg.push(gstTypeCell.ErrorMessage);
          }
        }
      } else if (props.additionalInfoTemplate[gstTypeCell?.FieldId]) {
        if (gstTypeCell?.Validation != "") {
          let replacedGstType = gstTypeCell.Validation.replace(
            placeholderPattern,
            (match, p1) => {
              // p1 is the captured group (the digits following 'f')
              return props.additionalInfoTemplate[match.replace("f", "")]
                .value !== undefined
                ? props.additionalInfoTemplate[match.replace("f", "")]?.value ==
                  ""
                  ? "''"
                  : `"${props.additionalInfoTemplate[match.replace("f", "")]?.value
                  }"`
                : match;
            }
          );
          if (!eval(replacedGstType)) {
            errorMsg.push(gstTypeCell.ErrorMessage);
          }
        }
      }
      if (props.mainTemplateQuotation[key][gstValueCell?.FieldId]) {
        if (gstValueCell.Validation != "") {

          let replacedGst = gstValueCell.Validation.replace(
            placeholderPattern,
            (match, p1) => {
              // p1 is the captured group (the digits following 'f')
              return props.mainTemplateQuotation[key][match.replace("f", "")]
                .value !== undefined
                ? props.mainTemplateQuotation[key][match.replace("f", "")]
                  .value == ""
                  ? "''"
                  : `"${props.mainTemplateQuotation[key][match.replace("f", "")]
                    .value
                  }"`
                : match;
            }
          );
          if (!eval(replacedGst)) {
            errorMsg.push(gstValueCell.ErrorMessage);
          }
        }
      }

      // for additional field validation
      let uniqueFormulaElementKey = [...new Set(formulaElementKey)];
      additionalInfoElement = uniqueFormulaElementKey.filter(item => item in props.additionalInfoTemplate);
      if (additionalInfoElement.length > 0) {
        additionalInfoElement.map((id) => {
          let additionalInfoCell = props.template.LOCAL.find((cell) => cell.FieldId === id)
          if (additionalInfoCell) {
            if (additionalInfoCell.Validation != '') {
              let additionalInfoCellFormula = additionalInfoCell.Validation.replace(
                placeholderPattern,
                (match, p1) => {
                  return props.additionalInfoTemplate[match.replace("f", "")]
                    .value !== undefined
                    ?
                    props.additionalInfoTemplate[match.replace("f", "")]?.value ==
                      "" || props.additionalInfoTemplate[match.replace("f", "")]?.value == null
                      ? "''"
                      : !isNaN(props.additionalInfoTemplate[match.replace("f", "")]?.value) ? parseFloat(props.additionalInfoTemplate[match.replace("f", "")]?.value) : `"${props.additionalInfoTemplate[match.replace("f", "")]?.value
                        }"`
                    : match;
                }
              );
              if (!eval(additionalInfoCellFormula) && additionalInfoCell.VisibilityFlag === "Y") {
                errorMsg.push(additionalInfoCell.ErrorMessage);
              }
            }
          }
        })
      }

      const unit = props.items[key]?.unit || "";

      // Extract FieldLabels and FieldIds from TotalCost template formula
      const totalCostCell = props.data[0].find(
        (cell) => cell.DefaultValue?.Button === "Y"
      );
      const fieldIds = totalCostCell.DefaultValue.Formula.match(
        /\(f\d+\)/g
      ).map((id) => id.slice(2, -1));
      // console.log("fieldIds", fieldIds, result, totalCostCell);
      let values = [];
      const fieldLabels = fieldIds.map((id) => {
        const correspondingCell = props.data[0].find(
          (cell) => cell.FieldId === id
        );
        if (EvalResults.hasOwnProperty(correspondingCell.FieldId)) {
          values.push({
            label: correspondingCell.FieldLabel,
            value: EvalResults[correspondingCell.FieldId],
            unit,
          });

          // values[correspondingCell.FieldLabel] = result[correspondingCell.FieldId]
        } else if (
          props.additionalInfoTemplate.hasOwnProperty(correspondingCell.FieldId)
        ) {
          values.push({
            label: correspondingCell.FieldLabel,
            value:
              props.additionalInfoTemplate[correspondingCell.FieldId]?.value ||
              0,
            unit,
          });

          // values[correspondingCell.FieldLabel] = props.additionalInfoTemplate[correspondingCell.FieldId].value
        } else {
          values.push({
            label: correspondingCell.FieldLabel,
            value:
              props.mainTemplateQuotation[key][correspondingCell.FieldId]
                ?.value || 0,
            unit,
          });
        }
      });

      values.push({
        label: totalCostCell.FieldLabel,
        value: EvalResults[totalCostCell.FieldId],
        unit,
      });

      setModalOpen(true);
      setSelectedRowData(rowData);
      setModalData(values);
      setUnit(unitFlag == 3 ? "Pack" : unit);
      setErrors(errorMsg);
    } else {
      console.error("mainTemplateQuotation or key is null or undefined.");
    }
  };

  const finalFormulaFunction = (
    formula,
    placeholders,
    basicPriceRate,
    fieldId,
    key,
    value
  ) => {
    placeholders.forEach((placeholder) => {
      const matchId = placeholder.slice(2, -1);
      let matchValue = props.mainTemplateQuotation[key][matchId]?.value;
      if (matchValue === undefined) {
        let fieldCell = props.data[0].find((cell) => cell.FieldId === matchId);
        if (fieldCell) {
          matchValue = "(" + fieldCell.DefaultValue.Formula + ")";
          let TemperoraryPlaceholders = "";
          if (
            fieldCell.DefaultValue.Formula &&
            fieldCell.DefaultValue.Formula !== ""
          ) {
            TemperoraryPlaceholders =
              fieldCell.DefaultValue.Formula.match(/\(f\d+\)/g);
          } else if (
            fieldCell.DefaultFormula &&
            fieldCell.DefaultFormula !== ""
          ) {
            TemperoraryPlaceholders =
              fieldCell.DefaultFormula.match(/\(f\d+\)/g);
          }
          if (TemperoraryPlaceholders != "") {
            let data_hash = finalFormulaFunction(
              fieldCell.DefaultValue.Formula,
              TemperoraryPlaceholders,
              basicPriceRate,
              matchId,
              key,
              0
            );
            if (data_hash.eval) {
              matchValue = eval(data_hash.formula);
            } else {
              matchValue = data_hash.value;
            }
          } else {
            matchValue = fieldCell.DefaultValue || 0;
          }
        } else {
          matchValue = props.additionalInfoTemplate[matchId]?.value || 0;
          if (props.additionalInfoTemplate[matchId]?.Identifier === "GSTType") {
            matchValue = "'" + matchValue + "'";
          }

          if (
            props.additionalInfoTemplate[matchId]?.Identifier === "GSTValue"
          ) {
            matchValue = "'" + (matchValue - 0.0) + "'";
          }
          // fieldCell = props.additionalInfoTemplate[0].find(
          //   (cell) => cell.FieldId === matchId
          // );
        }
      }
      if (matchValue === "") {
        matchValue = 0;
      }
      if (matchValue !== undefined) {
        // If value is defined, update the result
        if (
          props.mainTemplateQuotation[key][matchId]?.Identifier === "GSTType"
        ) {
          matchValue = "'" + matchValue + "'";
        }
        if (
          props.mainTemplateQuotation[key][matchId]?.Identifier === "GSTValue"
        ) {
          matchValue = "'" + (matchValue - 0.0) + "'";
        }
        // "Identifier": "GSTValue",
        if (basicPriceRate.FieldId === fieldId) {
          value = matchValue || 0;
          return { formula: formula, value: value, eval: 0 };
        } else {
          formula = formula.replace(placeholder, matchValue);
        }
      }
    });
    return { formula: formula, value: value, eval: 1 };
  };

  const handleUplaodSpecificationOpen = (
    index,
    specifications,
    specificationText
  ) => {
    setUpdatedFileValue(specifications);
    setFileSpecificationsOpen(!fileSpecificationsOpen);
    setPopHash({ index: index, type: "specification" });
    getExistingFiles(specifications);
    setSpecificationText(specificationText);
  };
  const getExistingFiles = (file_id) => {
    const queryString = file_id
      .map((file) => `file_ids[]=${file.id}`)
      .join("&");
    const url = `${postFileSpecifications}?${queryString}`;
    axios
      .get(url)
      .then((res) => {
        setSpecification(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, " downloadFile error");
        this.props.openSnackbar(
          "error",
          err.response.data?.message
            ? err.response.data?.message
            : "Something went wrong!"
        );
        setLoading(false);
      });
  };
  const handleUploadClose = () => {
    setFileSpecificationsOpen(false);
    setSpecification([]);
    setLoading(true);
    setSpecificationText("");
  };

  const updateDisabledProps = () => {
    const disabledProps = {};
    for (const key in props.mainTemplateQuotation) {
      const isNegotiation = props.lastTransactionTypeInThread === "Negotiation";
      const lastSelected = props.lastTransactionInThread[key]?.selected === "Y";
      const mainSelected = props.mainTemplateQuotation[key]?.selected === "Y";
      const is_deleted_from_master =
        props.mainTemplateQuotation[key]?.is_deleted_from_master;
      const is_unlink_from_category =
        props.mainTemplateQuotation[key]?.is_unlink_from_category;
      const filteredSelectedNotDataSelected =
        props.filteredSelectedNotData[key]?.selected;

      // Check if either is_deleted_from_master or is_unlink_from_category is true, if so, disable the checkbox
      if (is_deleted_from_master || is_unlink_from_category) {
        disabledProps[key] = true;
      } else if (
        props.lastTransactionTypeInThread === "Inquiry" &&
        !mainSelected
      ) {
        disabledProps[key] = true;
      } else {
        // Your existing conditions
        if (isNegotiation) {
          if (lastSelected) {
            if (mainSelected) {
              disabledProps[key] = false;
            }
          } else {
            if (filteredSelectedNotDataSelected == "N") {
              if (mainSelected) {
                disabledProps[key] = false;
              } else {
                disabledProps[key] = true;
              }
            } else {
              disabledProps[key] = true;
            }
          }
        }
        else {
          if (!mainSelected && filteredSelectedNotDataSelected == "N") {
            // Allow toggling between 'Y' and 'N' when mainSelected is 'N' and
            // filteredSelectedNotDataSelected is 'N'
            disabledProps[key] = true;
          }
          else if (!mainSelected && filteredSelectedNotDataSelected !== "N") {
            disabledProps[key] = true;
          } else if (!mainSelected && filteredSelectedNotDataSelected !== "N") {
            disabledProps[key] = true;
          } else if (!mainSelected) {
            // quotation = N , RFQ = Y
            // Enable item if mainSelected matches filteredSelectedNotDataSelected
            disabledProps[key] = false;
          }
          //    else if (mainSelected) {
          // console.log("entered in else if 4");
          //     // quotation = N , RFQ = Y
          //     // Enable item if mainSelected matches filteredSelectedNotDataSelected
          //     disabledProps[key] = true;
          //   }
          else if (mainSelected && filteredSelectedNotDataSelected == "N") {
            // quotation = N , RFQ = Y
            // Enable item if mainSelected matches filteredSelectedNotDataSelected
            disabledProps[key] = false;
          } else {
            disabledProps[key] = false;
          }
        }
      }
    }
    props.disabledProps(disabledProps)
    setDisabledProps(disabledProps);
  };

  const handleSwitchBoxChange = (event, key) => {
    const { mainTemplateQuotation, lastTransactionInThread, items } = props;

    if (
      !mainTemplateQuotation ||
      !lastTransactionInThread ||
      !mainTemplateQuotation[key] ||
      !lastTransactionInThread[key]
    ) {
      return;
    }

    setSwitchDisabledProps((prevState) => {
      return {
        ...prevState,
        [key]: event.target.checked,
      };
    });
  };

  useEffect(() => {
    updateDisabledProps();
  }, [
    props.lastTransactionTypeInThread,
    props.lastTransactionInThread,
    props.mainTemplateQuotation,
  ]);

  const getTableBody = () => {
    let itemsToRender;
    const { SlabTableToggle } = state;

    if (props.selectedQuotedItems || props.selectedQuotedItems[0]) {
      const { mainTemplateQuotation } = props;
      let filteredItems = {};
      if (props.itemSpecific) {
        filteredItems = Object.entries(props.selectedQuotedItems)
          .filter(([key, value]) =>
            props.inquiryItemsId.includes(parseInt(key))
          )
          .reduce((obj, [key, value]) => {
            obj[key] = value;
            return obj;
          }, {});
      } else {
        filteredItems = props.selectedQuotedItems;
      }
      const filteredItemsMap = new Map(
        Object.entries(filteredItems).map((item) => [item[0], item[1]])
      );
      const reorderedTransactionItemSequence = props?.transactionItemSequence
        .filter((key) => filteredItemsMap.has(key)) // Filter keys that are present in map
        .map((key) => [key, filteredItemsMap.get(key)]);
      // itemsToRender = Object.entries(filteredItems)
      //   .sort(([, a], [, b]) => a.name.localeCompare(b.name))
      itemsToRender = reorderedTransactionItemSequence.map(
        ([key, value], index) => {
          return (
            <>
              <TableRow key={key}>
                <TableCell
                  sx={{
                    WebkitBoxShadow:
                      state.isScrollActive && "11px 0 19px -2px #bebebe54",
                    boxShadow:
                      state.isScrollActive && "11px 0 19px -2px #bebebe54",
                  }}
                >
                  <Box display="flex" alignItems="center" gap="16px">
                    <Checkbox
                      checked={mainTemplateQuotation[key]?.selected === "Y"} // Assuming 'selected' indicates whether the item is selected
                      onChange={(event) =>
                        props.handleCheckboxChange(event, key)
                      } // Call a function to handle checkbox change
                    />
                    {props.multipleSolutionFlag && (
                      <Tooltip
                        title={"Multiple Solution"}
                        placement="bottom-start"
                        disableInteractive
                      >
                        <Switch
                          checked={props.switchState[key]}
                          onChange={(event) => {
                            props.toggleSwitch(key);
                            handleSwitchBoxChange(event, key);
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </Tooltip>
                    )}
                    <Tooltip // This is for Attachments specific files
                      title="Specification"
                      placement="bottom-start"
                      disableInteractive
                    >
                      <Badge
                        // badgeContent={value?.files?.length}
                        badgeContent={`${value?.files?.length}`}
                        color="primary"
                      >
                        <AttachFileIcon
                          onClick={() =>
                            value?.files?.length > 0 &&
                            handleUplaodSpecificationOpen(
                              key,
                              value.files,
                              value.specification_text
                            )
                          }
                          sx={{ cursor: "pointer" }}
                        />
                      </Badge>
                    </Tooltip>
                    <Tooltip //Item name header starts from here
                      title={_.unescape(value.name)}
                      placement="bottom-start"
                      disableInteractive
                    >
                      <Typography
                        noWrap
                        variant="body2"
                        color="text.primary"
                        fontWeight={500}
                        sx={{
                          width: {
                            lg: `${state.itemCellWidth > 300
                              ? state.itemCellWidth
                              : 300
                              }px`,
                            md: `${state.itemCellWidth > 200
                              ? state.itemCellWidth
                              : 200
                              }px`,
                            sm: `${state.itemCellWidth > 200
                              ? state.itemCellWidth
                              : 200
                              }px`,
                            xs: `${state.itemCellWidth > 125
                              ? state.itemCellWidth
                              : 125
                              }px`,
                          },
                          // width: `${
                          //   state.itemCellWidth > 350
                          //     ? state.itemCellWidth
                          //     : 350
                          // }px`,
                          marginLeft: "10px",
                        }}
                      >
                        {_.unescape(value.name)}
                      </Typography>
                      <Typography sx={{ color: "error.main", marginLeft: "10px" }}>
                        {props.mainTemplateQuotation[key]
                          ?.is_deleted_from_master &&
                          "This item is deleted from item master."}
                      </Typography>
                      <Typography sx={{ color: "red", marginLeft: "10px" }}>
                        {props.mainTemplateQuotation[key]
                          ?.is_unlink_from_category &&
                          "This item is unlinked from category."}
                      </Typography>

                      {props.mainTemplateQuotation[key]
                        ?.added_after_rfq_created && (
                          <Chip label="New" size="small" color="primary" sx={{ marginLeft: "10px", height: "16px" }} />
                        )}

                    </Tooltip>
                  </Box>
                </TableCell>
                {props.data[0]?.find(
                  (obj) =>
                    obj.Identifier === "ProductCode" &&
                    obj.VisibilityFlag === "Y"
                ) && (
                    <TableCell
                    // sx={{
                    //   WebkitBoxShadow:
                    //     state.isScrollActive && "11px 0 19px -4px #bebebe54",
                    //   boxShadow:
                    //     state.isScrollActive && "11px 0 19px -4px #bebebe54",
                    // }}
                    >
                      <Tooltip //Item code header starts from here
                        title={_.unescape(value.code)}
                        placement="bottom-start"
                        disableInteractive
                      >
                        <Typography
                          noWrap
                          variant="body2"
                          color="text.primary"
                          fontWeight={500}
                          sx={{
                            width: {
                              lg: `${state.itemCellWidth > 300
                                ? state.itemCellWidth
                                : 250
                                }px`,
                              md: `${state.itemCellWidth > 200
                                ? state.itemCellWidth
                                : 200
                                }px`,
                              sm: `${state.itemCellWidth > 200
                                ? state.itemCellWidth
                                : 200
                                }px`,
                              xs: `${state.itemCellWidth > 125
                                ? state.itemCellWidth
                                : 125
                                }px`,
                            },
                          }}
                        >
                          {_.unescape(value.code)}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                  )}
                {props.multipleSolutionFlag && (
                  <TableCell>
                    <Tooltip //Item code header starts from here
                      title={props.lastTransactionInThread[key]?.name}
                      placement="bottom-start"
                      disableInteractive
                    >
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        type="text"
                        value={_.unescape(value.code)} // Use editable value from state or default value
                        // onChange={(e) => handleInputChange(e, key)} // Pass key to identify which row is being updated
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Tooltip>
                  </TableCell>
                )}
                {props?.data[index]?.map((cell) => {
                  const DefaultFormula = cell.DefaultFormula;

                  // Regular expression to extract field IDs
                  const regex = /f(\d+)/g;
                  let match;
                  const fieldIDs = [];

                  // Extract field IDs from DefaultFormula
                  while ((match = regex.exec(DefaultFormula)) !== null) {
                    fieldIDs.push(match[1]);
                  }

                  // Assuming you always have two field IDs in the DefaultFormula
                  const [field1, field2] = fieldIDs;

                  // Initialize FieldState
                  const FieldState = {};

                  // If field IDs are found, set data in FieldState
                  if (field1 && field2) {
                    // Assuming num1 and num2 are the values you want to set
                    //  const num1 = /* Your value for field1 */;
                    //  const num2 = /* Your value for field2 */;
                    // Setting data in FieldState for both field1 and field2
                    //  const num1 = FieldState[field1];
                    //  const num2 = FieldState[field2];
                  }
                  // All I/P fields comes from there in the template section table
                  if (
                    cell.VisibilityFlag === "Y" ||
                    (cell.DefaultValue &&
                      cell.DefaultValue.Button === "Y" &&
                      cell.ElementType === "D")
                  ) {
                    if (
                      props.multiplePlantsFlag &&
                      cell.Identifier === "GSTType" &&
                      cell.FieldLabel === "GST Transition Type *"
                    ) {
                      return null;
                    }
                    if (
                      !(
                        cell.Identifier === "Product" ||
                        cell.Identifier === "ProductCode" ||
                        cell.Identifier === "ProductForeign"
                      )
                    ) {
                      return (
                        <TableCell
                          key={cell.FieldId}
                          // sx={{ minWidth: "120px" }}
                          sx={{ minWidth: "180px", maxWidth: "180px" }}
                        >
                          <QuotationFormField
                            inquiryId={props.inquiryId}
                            batch_id={props.batch_id}
                            LATEST={props.LATEST}
                            vendorType={props?.vendorType}
                            // upd_budget_master={
                            //   props.mainTemplateQuotation[item.id] &&
                            //   props.mainTemplateQuotation[item.id][cell?.FieldId] &&
                            //   props.mainTemplateQuotation[item.id][cell?.FieldId][
                            //     `upd_budget_master_${item.id}`
                            //   ]
                            // }
                            // qty={
                            //   props.mainTemplateQuotation[item.id] &&
                            //   props.mainTemplateQuotation[item.id]["qty"]
                            // }
                            // rate={
                            //   props.mainTemplateQuotation[item.id] &&
                            //   props.mainTemplateQuotation[item.id]["rate"]
                            // }
                            // readOnly={
                            //   props.mainTemplateQuotation[item.id]?.[cell?.FieldId]
                            //     ?.readOnly
                            // }
                            cell={cell}
                            item={value}
                            index={index}
                            // FieldState={
                            //   props.selectedQuotedItems.vendors[0]
                            //     .transaction_thread[0].template.itemize[key]
                            //     .solution[0] &&
                            //   (props.selectedQuotedItems.vendors[0]
                            //     .transaction_thread[0].template.itemize[key]
                            //     .solution[0].data[0][field1] ||
                            //     props.selectedQuotedItems.vendors[0]
                            //       .transaction_thread[0].template.itemize[key]
                            //       .solution[0].data[0][field2])
                            // }
                            //Important: there I'll be sending the mainTemplateQuotation doing this just for the static data
                            // props.selectedQuotedItems
                            //   .transaction_thread[0].template.itemize[
                            //   item.id
                            // ][cell.FieldId].value}
                            // FieldState={
                            //   mainTemplateQuotation[key] &&
                            //   mainTemplateQuotation[key][cell?.FieldId]
                            // }
                            FieldState={
                              mainTemplateQuotation[key] &&
                              mainTemplateQuotation[key][cell?.FieldId]
                            }
                            base_currency={props?.base_currency}
                            selectedCurrency={
                              (props.lastTransactionTypeInThread === "Inquiry" && (props.vendorType === "International" ||
                                props.vendorType === "Local Importer")) ? props?.selectedCurrency : (props?.selectedCurrency || props.quotedCurrency)
                              // props.lastTransactionTypeInThread === "Inquiry" &&
                              // (props.vendorType === "International" ||
                              //   props.vendorType === "Local Importer")
                              //   ? props.selectedCurrency
                              //   : Object.values(
                              //       props.lastTransactionInThread
                              //     )[0].solution[0]?.currency
                            }
                            // handleUpdateInMasterClick={
                            //   props.handleUpdateInMasterClick
                            // }
                            updateDeliveryScheduleFieldValue={
                              props.updateDeliveryScheduleFieldValue
                            }
                            handleChange={(
                              evt,
                              FieldId,
                              Identifier,
                              FieldLabel,
                              ElementType,
                              key
                            ) =>
                              props.handleChange(
                                evt,
                                index,
                                FieldId,
                                Identifier,
                                FieldLabel,
                                ElementType,
                                key
                              )
                            }
                            handleDateChange={(newVal, FieldId, Validation) =>
                              props.handleDateChange(
                                newVal,
                                FieldId,
                                "mainTemplateQuotation",
                                index,
                                Validation,
                                key
                              )
                            }
                            default_dropdown_type={props.default_dropdown_type}
                            destination={props.destination}
                            SlabTableToggle={SlabTableToggle}
                            disabled={
                              Object.keys(disabledProps).length > 0 &&
                              disabledProps[key]
                            }
                            readOnlyForSwitch={switchDisabledProps[key]}
                            keys={key.length > 0 && key}
                            handleDetailsClick={handleDetailsClick}
                            mainTemplateQuotation={props.mainTemplateQuotation}
                            multiplePlantsFlag={props.multiplePlantsFlag}
                          />
                        </TableCell>
                      );
                    }
                  }
                })}
              </TableRow>
              {props.slab[key] && props.slab[key].isOpen && (
                <TableRow>
                  <TableCell style={{ padding: 0 }} colSpan={100}>
                    <TableContainer
                      className="quotation_template_table slab_range_table"
                      sx={{
                        maxHeight: "400px",
                        overflowY: "auto",
                        borderTop: "solid 1px",
                        borderColor: (theme) => theme.palette.bluegrey[500],
                        borderBottomRightRadius: "4px",
                        borderBottomLeftRadius: "4px",
                      }}
                      onScroll={debounce((event) => {
                        if (
                          event.target.scrollLeft === 0 &&
                          state.isScrollActive
                        ) {
                          setState({
                            isScrollActive: false,
                          });
                        } else if (
                          event.target.scrollLeft !== 0 &&
                          !state.isScrollActive
                        ) {
                          setState({
                            isScrollActive: true,
                          });
                        }
                      }, 200)}
                    >
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              sx={{
                                WebkitBoxShadow:
                                  state.isScrollActive &&
                                  "11px 0 19px -2px #bebebe54",
                                boxShadow:
                                  state.isScrollActive &&
                                  "11px 0 19px -2px #bebebe54",
                              }}
                            >
                              <Box
                                className=""
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Checkbox sx={{ visibility: "hidden" }} />
                                <Tooltip
                                  title={
                                    props.data[0]?.find(
                                      (obj) =>
                                        obj.Identifier === "Product" ||
                                        obj.Identifier === "ProductForeign"
                                    )?.FieldLabel
                                  }
                                >
                                  <Box
                                    sx={{
                                      minWidth: "350px",
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Typography fontWeight={500}>
                                      {
                                        props.data[0]?.find(
                                          (obj) =>
                                            obj.Identifier === "Product" ||
                                            obj.Identifier === "ProductForeign"
                                        )?.FieldLabel
                                      }
                                    </Typography>

                                    <ResizableBox
                                      axis="x"
                                      height={20}
                                      width={20}
                                      onResizeStop={(e, data) => {
                                        setState({
                                          itemCellWidth: data.size.width,
                                        });
                                      }}
                                      style={{
                                        textAlign: "left",
                                        width: "100%",
                                      }}
                                      minConstraints={[20, 20]}
                                    ></ResizableBox>
                                  </Box>
                                </Tooltip>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Tooltip
                                  title={
                                    props.data[0]?.find(
                                      (obj) => obj.Identifier === "ProductCode"
                                    )?.FieldLabel
                                  }
                                >
                                  <Typography fontWeight={500}>
                                    {
                                      props.data[0]?.find(
                                        (obj) =>
                                          obj.Identifier === "ProductCode"
                                      )?.FieldLabel
                                    }
                                  </Typography>
                                </Tooltip>
                                <ResizableBox
                                  axis="x"
                                  height={20}
                                  width={20}
                                  style={{
                                    textAlign: "left",
                                    width: "100%",
                                  }}
                                  minConstraints={[20, 20]}
                                ></ResizableBox>
                              </Box>
                            </TableCell>

                            {props.data[0]?.map((cell, index) => {
                              if (
                                props.multiplePlantsFlag === true &&
                                cell.Identifier === "GSTType"
                              ) {
                                return null;
                              }
                              if (
                                cell.VisibilityFlag === "Y" ||
                                (cell.DefaultValue &&
                                  cell.DefaultValue.Button === "Y" &&
                                  cell.ElementType === "D")
                              ) {
                                if (
                                  !(
                                    cell.Identifier === "Product" ||
                                    cell.Identifier === "ProductForeign" ||
                                    cell.Identifier === "ProductCode"
                                  )
                                ) {
                                  return (
                                    <TableCell key={cell.FieldId}>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Tooltip
                                          title="Apply to All"
                                          placement="bottom-start"
                                          disableInteractive
                                        >
                                          <ToggleButton
                                            value="check"
                                            selected={
                                              props.applyToAll[
                                              `${cell.FieldId}_applyToAll`
                                              ]
                                            }
                                            disabled={
                                              cell.Identifier ===
                                              "EstimatedValue"
                                            }
                                            // title="Apply to All"
                                            onClick={() => {
                                              //for not doing apply to all when field is read only
                                              let isReadOnly = false;
                                              if (!isReadOnly) {
                                                Object.keys(
                                                  props.mainTemplateQuotation
                                                ).map((key, i) => {
                                                  let element =
                                                    props.mainTemplateQuotation[
                                                    key
                                                    ];
                                                  Object.keys(element).map(
                                                    (key1, i) => {
                                                      if (
                                                        key1 == cell.FieldId &&
                                                        typeof element[key1] ===
                                                        "object" &&
                                                        element[key1].readOnly
                                                      ) {
                                                        isReadOnly = key1;
                                                      }
                                                    }
                                                  );
                                                });
                                              }
                                              if (
                                                !props.applyToAll[
                                                `${cell.FieldId}_applyToAll`
                                                ] &&
                                                isReadOnly != cell.FieldId
                                              ) {
                                                props.handleApplyToAllClick(
                                                  cell.FieldId,
                                                  cell.ElementType === "TB" &&
                                                  cell.ElementType === "TA",
                                                  cell.Identifier
                                                );
                                              }
                                            }}
                                            sx={{
                                              p: 0,
                                              mr: 1,
                                              border: 0,
                                            }}
                                          >
                                            {!["T", "D", "L"].includes(
                                              cell.ElementType
                                            ) ? (
                                              props.applyToAll[
                                                `${cell.FieldId}_applyToAll`
                                              ] ? (
                                                <CheckBoxIcon
                                                  sx={{
                                                    color: (theme) =>
                                                      theme.palette.primary
                                                        .main,
                                                  }}
                                                />
                                              ) : (
                                                <CheckBoxOutlineBlankIcon
                                                  sx={{
                                                    color: (theme) =>
                                                      theme.palette
                                                        .bluegrey[500],
                                                  }}
                                                />
                                              )
                                            ) : (
                                              ""
                                            )}
                                          </ToggleButton>
                                        </Tooltip>
                                        <Tooltip
                                          title={
                                            cell?.InstructionText === ""
                                              ? cell?.FieldLabel
                                              : cell?.InstructionText
                                          }
                                        >
                                          {/* <p>{cell.FieldLabel}</p> */}
                                          <Typography fontWeight={500}>
                                            {cell.FieldLabel}
                                          </Typography>
                                        </Tooltip>
                                        <ResizableBox
                                          axis="x"
                                          height={20}
                                          width={20}
                                          style={{
                                            textAlign: "left",
                                            width: "100%",
                                          }}
                                          minConstraints={[20, 20]}
                                        ></ResizableBox>
                                      </div>
                                    </TableCell>
                                  );
                                }
                              } else {
                                return "";
                              }
                            })}
                          </TableRow>
                        </TableHead>
                        {/* {getSecondTableBody()} */}
                      </Table>
                    </TableContainer>
                  </TableCell>
                </TableRow>
              )}
              {props.multipleSolutionFlag &&
                getHeaders(
                  props.lastTransactionInThread[key]?.solution,
                  key,
                  value
                )}
            </>
          );
        }
      );
    } else {
      itemsToRender = "loading...";
    }

    return <TableBody>{itemsToRender}</TableBody>;
  };

  const LandedCostRFQOpen = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (AllItemsTemplate.length > 0) {
      let totalCost = AllItemsTemplate.find((items) => {
        return (
          items.DefaultValue?.Button == "Y" || items.Identifier === "TotalCost"
        );
      });
      let rateCell = AllItemsTemplate.find((items) => {
        return (
          (items.Identifier === "EstimatedCost" ||
            items.Identifier === "EstimatedCostForeign" ||
            items.Identifier == "Budgetedrate" ||
            items.Identifier === "Rate" ||
            items.Identifier === "CIFRate" ||
            items.Identifier == "rate") &&
          items.ElementType === "D"
        );
      });
      let PerCost = AllItemsTemplate.find((items) => {
        return (
          items.Identifier === "PER"
        );
      });
      setPerCost(PerCost)
      setTotalCostFIeld(totalCost);
      setRateFIeld(rateCell);
    }
    setLandedCostRFQ({ ...landedCostRFQ, [anchor]: open });
  };

  function isInErrorArray(attributeName) {
    return props.errorArray.includes(attributeName);
  }

  // Function to render the error message based on condition
  function renderErrorMessage(attribute, identifier, errorDisplayedFlag) {
    if (
      identifier === "GSTType" &&
      isInErrorArray(attribute) &&
      !errorDisplayedFlag.errorDisplayed
    ) {
      errorDisplayedFlag.errorDisplayed = true; // Set the flag to true once the error is displayed
      return (
        <Alert variant="text" sx={{ color: "error.main", p: 0 }} severity="error">Please Select GST Transition Type.</Alert>

        // <Typography color={"error.main"} key={attribute}>
        //   {" "}
        //   {/* Added a key for list rendering */}
        //   <ErrorOutlineIcon />
        //   Please Select GST Transition Type
        // </Typography>
      );
    }
    return null; // Return null if no error condition is met or if error is already displayed
  }
  const getHeaders = (solutions, key, value) => {
    let itemid = key;
    let headerRows = { ...rows };
    if (!headerRows[key]) {
      headerRows[key] = [
        {
          id: 0,
          data: solutions,
          name: "",
          mainData: props.mainTemplateQuotation[key],
        },
      ];
    }

    if (!props.switchState[key]) {
      return null;
    }

    return (
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={100}>
          <Table
            className="MultiSolution-Table SlabDetailsForm"
            sx={{
              // "&.MultiSolution-Table tr td:first-of-type, &.MultiSolution-Table tr th:first-of-type": {
              //   minWidth: "100px",
              //   position:"static",
              //   left: "0",
              // },
              "&.MultiSolution-Table tr td:first-of-type, &.MultiSolution-Table tr th:first-of-type":
              {
                minWidth: {
                  lg: "498px",
                  md: "434px",
                },
                position: {
                  md: "sticky",
                  sm: "static",
                },
                left: "0",
                boxShadow: {
                  md: "inset -1px 0px 0 #a6c1d3",
                  sm: "none",
                },
              },
              "&.MultiSolution-Table .MuiTableBody-root tr:last-of-type td": {
                borderBottom: "none",
              },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell key={"M2"} align="left">
                  <Tooltip title="MULTIPLE SOLUTION">
                    <Typography
                      variant="body2"
                      color={"grey.600"}
                      fontWeight={500}
                      textAlign={{ sm: "left", md: "right" }}
                    >
                      MULTIPLE SOLUTION
                    </Typography>
                  </Tooltip>
                </TableCell>
                {props.data[0]?.map((cell) => {
                  if (
                    cell.VisibilityFlag === "Y" ||
                    (cell.DefaultValue &&
                      cell.DefaultValue.Button === "Y" &&
                      cell.ElementType === "D")
                  ) {
                    if (
                      !(
                        cell.Identifier === "Product" ||
                        cell.Identifier === "ProductForeign" ||
                        cell.Identifier === "ProductCode"
                      )
                    ) {
                      return (
                        <TableCell key={cell.FieldId}>
                          <Tooltip
                            title={
                              cell?.InstructionText === ""
                                ? cell?.FieldLabel
                                : cell?.InstructionText
                            }
                          >
                            <Typography fontWeight={500}>
                              {cell.FieldLabel}
                            </Typography>
                          </Tooltip>
                        </TableCell>
                      );
                    }
                  }
                  return "";
                })}
              </TableRow>
            </TableHead>
            {/* {console.log(headerRows[key],"headerRows[key]")} */}
            <TableBody>
              {headerRows[key].map((row, rowIndex) => (
                <TableRow key={row.id}>
                  {/* Render other row content here */}

                  {row["data"].map((solution, index) => {
                    const solutionCellData = props?.data[index];
                    const visibleCells = solutionCellData?.filter(
                      (cell) =>
                        (cell.VisibilityFlag === "Y" ||
                          (cell.DefaultValue &&
                            cell.DefaultValue.Button === "Y" &&
                            cell.ElementType === "D")) &&
                        !(
                          cell.Identifier === "Product" ||
                          cell.Identifier === "ProductForeign" ||
                          cell.Identifier === "ProductCode"
                        )
                    );
                    const nonVisibleCells = solutionCellData?.filter(
                      (cell) =>
                        !(
                          cell.VisibilityFlag === "Y" ||
                          (cell.DefaultValue &&
                            cell.DefaultValue.Button === "Y" &&
                            cell.ElementType === "D")
                        ) &&
                        !(
                          cell.Identifier === "Product" ||
                          cell.Identifier === "ProductForeign" ||
                          cell.Identifier === "ProductCode"
                        )
                    );
                    const fieldIds = visibleCells?.map((cell) => cell.FieldId);
                    const nonVisibleCellIds = nonVisibleCells?.map(
                      (cell) => cell.FieldId
                    );

                    return (
                      <React.Fragment key={index}>
                        <TableCell>
                          <Box display="flex" gap="8px">
                            <Box sx={{ width: "106px" }}>
                              {rowIndex === headerRows[key].length - 1 ? ( // Render add button only if it's the last row
                                <IconButton
                                  color="primary"
                                  aria-label="add"
                                  onClick={() =>
                                    addRow(key, headerRows, solutions)
                                  }
                                >
                                  <AddCircleIcon />
                                </IconButton>
                              ) : (
                                // Render add button only if it's the last row
                                <IconButton
                                  color="primary"
                                  aria-label="add"
                                  onClick={() => addRow(key, headerRows)}
                                  sx={{ visibility: "hidden" }}
                                >
                                  <AddCircleIcon />
                                </IconButton>
                              )}
                              {rowIndex !== 0 && ( // Render delete button only if not the first row
                                <IconButton
                                  aria-label="delete"
                                  onClick={() =>
                                    deleteRow(row.id, key, headerRows)
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              )}
                            </Box>
                            <Tooltip //Item code header starts from here
                              title={solution.name}
                              placement="bottom-start"
                              disableInteractive
                            >
                              <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                type="text"
                                value={row.name} // Use editable value from state or default value
                                onChange={(e) =>
                                  handleInputChange(e, key, headerRows, row.id)
                                } // Pass key to identify which row is being updated
                                InputProps={{
                                  readOnly: false,
                                }}
                              />
                            </Tooltip>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ display: "none" }}>
                          <Tooltip
                            title={solution.name}
                            placement="bottom-start"
                            disableInteractive
                          >
                            {/* <Typography noWrap>{solution.name} {index} {rowIndex}</Typography> */}
                            <Typography noWrap>{solution.name}</Typography>
                          </Tooltip>
                        </TableCell>
                        {fieldIds?.map((fieldId) => {
                          const cell = visibleCells?.find(
                            (cell) => cell.FieldId === fieldId
                          );
                          const DefaultFormula = cell.DefaultFormula;
                          const regex = /f(\d+)/g;
                          let match;
                          const fieldIDs = [];

                          while (
                            (match = regex.exec(DefaultFormula)) !== null
                          ) {
                            fieldIDs.push(match[1]);
                          }

                          const [field1, field2] = fieldIDs;
                          const FieldState = {};

                          if (field1 && field2) {
                          }
                          if (
                            props.multiplePlantsFlag &&
                            cell.Identifier === "GSTType" &&
                            cell.FieldLabel === "GST Transition Type *"
                          ) {
                            return null;
                          }
                          return (
                            <TableCell
                              key={cell.FieldId}
                              sx={{ minWidth: "120px" }}
                            >
                              <QuotationFormField
                                batch_id={props.batch_id}
                                LATEST={props.LATEST}
                                cell={cell}
                                item={value}
                                index={index}
                                vendorType={props?.vendorType}
                                // FieldState={
                                //   props.mainTemplateQuotation[key] &&
                                //   props.mainTemplateQuotation[key][
                                //     cell?.FieldId
                                //   ]
                                // }
                                FieldState={
                                  headerRows[key][rowIndex].mainData[
                                  cell?.FieldId
                                  ]
                                }
                                base_currency={props?.base_currency}
                                // selectedCurrency={
                                //   Object.values(
                                //     props.lastTransactionInThread
                                //   )[0].solution[0].currency
                                // }
                                selectedCurrency={
                                  props?.selectedCurrency ||
                                  props.quotedCurrency

                                  // props.lastTransactionTypeInThread ===
                                  //   "Inquiry" &&
                                  // (props.vendorType === "International" ||
                                  //   props.vendorType === "Local Importer")
                                  //   ? props.selectedCurrency
                                  //   : Object.values(
                                  //       props.lastTransactionInThread
                                  //     )[0].solution[0].currency
                                }
                                handleChange={(
                                  evt,
                                  FieldId,
                                  Identifier,
                                  FieldLabel,
                                  ElementType,
                                  key,
                                  rowIndex
                                ) => {
                                  props.handleMSChange(
                                    evt,
                                    index,
                                    FieldId,
                                    Identifier,
                                    FieldLabel,
                                    ElementType,
                                    key,
                                    rowIndex
                                  );
                                  // setTimeout(() => {
                                  //   props.handleMSData(
                                  //     headerRows,
                                  //     itemid,
                                  //     rowIndex
                                  //   );
                                  // }, 2000);
                                }}
                                disabled={
                                  Object.keys(disabledProps).length > 0 &&
                                  disabledProps[key]
                                }
                                updateDeliveryScheduleFieldValue={
                                  props.updateDeliveryScheduleFieldValue
                                }
                                default_dropdown_type={
                                  props.default_dropdown_type
                                }
                                rowIndex={rowIndex}
                                keys={key.length > 0 && key}
                                handleDetailsClick={handleDetailsClick}
                                multiplePlantsFlag={props.multiplePlantsFlag}
                              />
                            </TableCell>
                          );
                        })}
                        {nonVisibleCellIds?.map((cellId) => {
                          return <TableCell key={cellId} />;
                        })}
                      </React.Fragment>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      <Card variant="outlined" sx={{ mb: 2, mt: 3 }} id="Template">
        <CardHeader
          title={
            <Typography variant="subtitle2" component="h5" color={"grey.900"}>
              TEMPLATE
            </Typography>
          }
          action={
            props.multiplePlantsFlag && (
              <Box display="flex" gap="8px">
                {(() => {
                  let gstTypeCell = AllItemsTemplate.find(item => {
                    return item.Identifier == "GSTType"
                  })
                  let errorDisplayedFlag = { errorDisplayed: false }; // Initialize the error display flag for the whole iteration
                  if (gstTypeCell?.HTMLAttributeName.includes("_")) {
                    return Object.values(props.mainTemplateQuotation).map(
                      (item) => {
                        const attributes = Object.values(item);
                        return (
                          <div key={item.formdetailRowId}>
                            {" "}
                            {/* Assuming formdetailRowId is unique */}
                            {attributes.map((attribute) =>
                              renderErrorMessage(
                                attribute?.HTMLAttributeName,
                                attribute?.Identifier,
                                errorDisplayedFlag
                              )
                            )}
                          </div>
                        );
                      }
                    );
                  } else {
                    if (
                      gstTypeCell &&
                      Object.keys(props.additionalInfoTemplate).length > 0 &&
                      props.additionalInfoTemplate.hasOwnProperty(
                        gstTypeCell.FieldId
                      )
                    ) {
                      if (
                        props.additionalInfoTemplate[gstTypeCell.FieldId]?.error
                      ) {
                        return (
                          <Alert
                            variant="text"
                            sx={{ color: "error.main", p: 0 }}
                            severity="error"
                          >
                            Please Select GST Transition Type.
                          </Alert>
                        );
                      }
                    }
                  }
                })()}
                <Tooltip
                  placement="top"
                  title="Review Auto generation of GST transition type"
                  arrow
                  open={tooltipOpen || hoverTooltip}
                  onOpen={() => setHoverTooltip(true)}
                  onClose={() => setHoverTooltip(false)}
                >
                  <Badge
                    badgeContent={destinationCount}
                    color="primary"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    sx={{ mr: 2 }}
                  >
                    <Button
                      variant="text"
                      size="small"
                      startIcon={<LocationOnOutlinedIcon />}
                      onClick={() => {
                        setTooltipOpen(false);
                        props.setIsDestinationDetailsDialogOpen(true);
                      }}
                      sx={{ backgroundColor: "var(--primary-50)" }}
                    >
                      Destination
                    </Button>
                  </Badge>
                </Tooltip>
              </Box>
            )
          }
        />
        <CardContent sx={{ p: 0 }}>
          <TableContainer
            className="quotation_template_table"
            sx={{
              maxHeight: "400px",
              overflowY: "auto",
              borderTop: "solid 1px",
              borderColor: (theme) => theme.palette.bluegrey[500],
              borderBottomRightRadius: "4px",
              borderBottomLeftRadius: "4px",
            }}
            onScroll={debounce((event) => {
              if (event.target.scrollLeft === 0 && state.isScrollActive) {
                setState({
                  isScrollActive: false,
                });
              } else if (
                event.target.scrollLeft !== 0 &&
                !state.isScrollActive
              ) {
                setState({
                  isScrollActive: true,
                });
              }
            }, 200)}
          // sx={{
          //   maxHeight: "400px",
          //   overflowY: "auto",
          //   borderTop: "solid 1px",
          //   borderColor: (theme) => theme.palette.bluegrey[500],
          //   borderBottomRightRadius: "4px",
          //   borderBottomLeftRadius: "4px",
          //   ".items_template_table .MuiTableHead-root": {
          //     backgroundColor: (theme) => theme.palette.bluegrey[500],
          //   },
          //   "& .MuiTableHead-root ": {},
          //   "& .MuiTableBody-root": {
          //     backgroundColor: (theme) => theme.palette.grey[0],
          //   },
          //   "& tr": {
          //     whiteSpace: "nowrap",
          //   },
          //   "& .MuiTableHead-root tr th": {
          //     padding: "15px 8px",
          //   },
          //   "& .MuiTableBody-root tr td": {
          //     padding: "5px 8px",
          //   },
          //   ".MuiTableBody-root tr td:first-of-type, .MuiTableHead-root tr th:first-of-type ":
          //     {
          //       // position: "sticky",
          //       left: "0",
          //       minWidth: "380px",
          //     },
          //   "& .MuiTableHead-root tr th:first-of-type": {
          //     zIndex: "3",
          //   },
          //   "& .MuiTableBody-root tr td:first-of-type": {
          //     backgroundColor: (theme) => theme.palette.grey[0],
          //     zIndex: "1",
          //   },
          //   "& .css-17hdxs-MuiTypography-root": {
          //     fontSize: "14px",
          //   },
          // }}
          >
            <Table stickyHeader>
              <TableHead className="Itemize_headers">
                <TableRow>
                  <TableCell
                    sx={{
                      WebkitBoxShadow:
                        state.isScrollActive && "11px 0 19px -2px #bebebe54",
                      boxShadow:
                        state.isScrollActive && "11px 0 19px -2px #bebebe54",
                    }}
                  >
                    <Box
                      className=""
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Checkbox sx={{ visibility: "hidden" }} />
                      <Tooltip
                        title={
                          props.data[0]?.find(
                            (obj) =>
                              obj.Identifier === "Product" ||
                              obj.Identifier === "ProductForeign"
                          )?.FieldLabel
                        }
                      >
                        <Box
                          sx={{
                            minWidth: "350px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography fontWeight={500}>
                            {
                              props.data[0]?.find(
                                (obj) =>
                                  obj.Identifier === "Product" ||
                                  obj.Identifier === "ProductForeign"
                              )?.FieldLabel
                            }
                          </Typography>

                          <ResizableBox
                            axis="x"
                            height={20}
                            width={20}
                            onResizeStop={(e, data) => {
                              setState({
                                itemCellWidth: data.size.width,
                              });
                            }}
                            style={{
                              textAlign: "left",
                              width: "100%",
                            }}
                            minConstraints={[20, 20]}
                          ></ResizableBox>
                        </Box>
                      </Tooltip>
                    </Box>
                  </TableCell>
                  {props.data[0]?.find(
                    (obj) =>
                      obj.Identifier === "ProductCode" &&
                      obj.VisibilityFlag === "Y"
                  ) && (
                      <TableCell>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Tooltip
                            title={
                              props.data[0]?.find(
                                (obj) => obj.Identifier === "ProductCode"
                              )?.FieldLabel
                            }
                          >
                            <Typography fontWeight={500}>
                              {
                                props.data[0]?.find(
                                  (obj) => obj.Identifier === "ProductCode"
                                )?.FieldLabel
                              }
                            </Typography>
                          </Tooltip>
                          <ResizableBox
                            axis="x"
                            height={20}
                            width={20}
                            style={{
                              textAlign: "left",
                              width: "100%",
                            }}
                            minConstraints={[20, 20]}
                          ></ResizableBox>
                        </Box>
                      </TableCell>
                    )}

                  {props.multipleSolutionFlag && (
                    <TableCell key={"M2"} align="left">
                      <Tooltip title={""}>
                        {/* <p>{cell.FieldLabel}</p> */}
                        <Typography fontWeight={500}>
                          MULTIPLE SOLUTION
                        </Typography>
                      </Tooltip>
                    </TableCell>
                  )}
                  {props.data[0]?.map((cell, index) => {
                    if (
                      props.multiplePlantsFlag === true &&
                      cell.Identifier === "GSTType"
                    ) {
                      return null;
                    }
                    if (
                      props.vendorType === "Local" ||
                        props.vendorType === "International" ||
                        props.vendorType === "Local Importer"
                        ? cell.VisibilityFlag === "Y" ||
                        (cell.DefaultValue &&
                          cell.DefaultValue.Button === "Y" &&
                          cell.ElementType === "D")
                        : cell.VisibilityFlag === "Y"
                    ) {
                      if (
                        !(
                          cell.Identifier === "Product" ||
                          cell.Identifier === "ProductForeign" ||
                          cell.Identifier === "ProductCode"
                        )
                      ) {
                        return (
                          <TableCell key={cell.FieldId}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Tooltip
                                title="Apply to All"
                                placement="bottom-start"
                                disableInteractive
                              >
                                <ToggleButton
                                  value="check"
                                  selected={
                                    props.applyToAll[
                                    `${cell.FieldId}_applyToAll`
                                    ]
                                  }
                                  disabled={
                                    cell.Identifier === "EstimatedValue"
                                  }
                                  // title="Apply to All"
                                  onClick={() => {
                                    //for not doing apply to all when field is read only
                                    let isReadOnly = false;
                                    if (!isReadOnly) {
                                      Object.keys(
                                        props.mainTemplateQuotation
                                      ).map((key, i) => {
                                        let element =
                                          props.mainTemplateQuotation[key];
                                        Object.keys(element).map((key1, i) => {
                                          if (
                                            key1 == cell.FieldId &&
                                            typeof element[key1] === "object" &&
                                            element[key1].readOnly
                                          ) {
                                            isReadOnly = key1;
                                          }
                                        });
                                      });
                                    }
                                    if (
                                      !props.applyToAll[
                                      `${cell.FieldId}_applyToAll`
                                      ] &&
                                      isReadOnly != cell.FieldId
                                    ) {
                                      props.handleApplyToAllClick(
                                        cell.FieldId,
                                        cell.ElementType === "TB" &&
                                        cell.ElementType === "TA",
                                        cell.Identifier
                                      );
                                    }
                                  }}
                                  sx={{
                                    p: 0,
                                    mr: 1,
                                    border: 0,
                                  }}
                                >
                                  {!["T", "D", "L"].includes(
                                    cell.ElementType
                                  ) ? (
                                    props.applyToAll[
                                      `${cell.FieldId}_applyToAll`
                                    ] ? (
                                      <CheckBoxIcon
                                        sx={{
                                          color: (theme) =>
                                            theme.palette.primary.main,
                                        }}
                                      />
                                    ) : (
                                      <CheckBoxOutlineBlankIcon
                                        sx={{
                                          color: (theme) =>
                                            theme.palette.bluegrey[500],
                                        }}
                                      />
                                    )
                                  ) : (
                                    ""
                                  )}
                                </ToggleButton>
                              </Tooltip>
                              <Tooltip
                                title={
                                  cell?.InstructionText === ""
                                    ? cell?.FieldLabel
                                    : cell?.InstructionText
                                }
                              >
                                <Typography fontWeight={500}>
                                  {cell.FieldLabel}
                                </Typography>
                              </Tooltip>
                              <ResizableBox
                                axis="x"
                                height={20}
                                width={20}
                                style={{
                                  textAlign: "left",
                                  width: "100%",
                                }}
                                minConstraints={[20, 20]}
                              ></ResizableBox>
                            </div>
                          </TableCell>
                        );
                      }
                    } else {
                      return "";
                    }
                  })}
                </TableRow>
              </TableHead>
              {getTableBody()}
            </Table>
          </TableContainer>
        </CardContent>
        <CardActions
          sx={{
            justifyContent: "end",
            alignItems: "center",
            padding: "8px 16px",
          }}
          disableSpacing
        >
          <Typography
            id="Total_val_basic_rate"
            variant="body2"
            fontWeight={500}
            color="secondary.main"
          >
            Total Value
          </Typography>
          <IconButton onClick={LandedCostRFQOpen("right", true)}>
            <InfoIcon fontSize="small" color="primary" />
          </IconButton>
        </CardActions>
      </Card>

      {state.specificationsOpen && (
        <Upload
          currentItemIndex={state.currentItemIndex}
          product_id={props.selectedQuotedItems[state.currentItemIndex]?.id}
          randomId={props.randomId}
          master_type={"item"}
          handleUplaod={props.handleUplaod}
          open={state.specificationsOpen}
          handleClose={() => {
            setState({
              specificationsOpen: false,
            });
          }}
          Alreadyexistingfiles={
            props.itemSpecificFiles[
            props.selectedQuotedItems[state.currentItemIndex]?.id
            ] || []
          }
        />
      )}
      {fileSpecificationsOpen && (
        <UploadDynamicView
          Loading={Loading}
          title="Specifications"
          SpecificationText={SpecificationText}
          uploadedFiles={specification?.data}
          open={fileSpecificationsOpen}
          handleClose={handleUploadClose}
        />
      )}
      <LandedCostRFQDrawer
        isLandedCostRFQOpened={landedCostRFQ}
        data={props.selectedQuotedItems}
        isLandedCostRFQClosed={() => setLandedCostRFQ(false)}
        mainTemplateQuotation={FilteredMainTemplate}
        //  mainTemplateQuotation={props.mainTemplateQuotation}
        // selectedCurrency={props?.selectedCurrency || props.quotedCurrency}
        selectedCurrency={
          props.lastTransactionTypeInThread === "Inquiry" &&
            (props.vendorType === "International" ||
              props.vendorType === "Local Importer")
            ? props?.selectedCurrency
            : props?.selectedCurrency || props.quotedCurrency
        }
        currencyDetails={props?.currencyDetails}
        transactionItemSequence={props?.transactionItemSequence}
        vendorType={props?.vendorType}
        TotalCostFIeld={TotalCostFIeld}
        RateField={RateField}
        PerCost={PerCost}
        EvalResultsKeyWise={props.EvalResultsKeyWise}
      />
      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle
          component="div"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "2px 16px",
          }}
        >
          <Typography variant="subtitle1" fontWeight={500} component="h6">
            {Errors.length > 1 ? "Total Cost Errors" : "Total Cost Details"}
          </Typography>
          <IconButton
            edge="end"
            sx={{ color: (theme) => theme.palette.bluegrey[500] }}
            onClick={() => setModalOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Table size="small">
            {Errors.length > 1 ? (
              <TableBody>
                Item: {Errors[0]}
                <br />
                <br />
                {Errors.slice(1)?.map((error, index) => (
                  // <TableRow key={error}>
                  <Typography>{`${index + 1}. ${error}`}</Typography>
                ))}
              </TableBody>
            ) : (
              <TableBody>
                {modalData?.map(({ label, value }) => (
                  // console.log("2124=", parseFloat(value).toFixed(3)),
                  <TableRow key={label}>
                    <TableCell>{label}</TableCell>
                    <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
                      {getSymbol(
                        props.selectedCurrency || props.quotedCurrency
                      )}{" "}
                      &nbsp;
                      {parseFloat(value).toLocaleString("en-IN", {
                        minimumFractionDigits: 3,
                        maximumFractionDigits: 3,
                      })}
                      {" per "} {unit}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </DialogContent>
      </Dialog>
    </>
  );
}
