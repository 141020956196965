import { SET_QUOTATION_INFO } from "../constants/type";
const initialState = {
  quotationListInfo: {},
  iframeVisible: ''
};

const quotationInfoReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_QUOTATION_INFO:
      return { ...state, quotationListInfo: payload};
    default:
      return state;
  }
};

export default quotationInfoReducer;
