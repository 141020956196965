import React, { useEffect, useState } from "react";
import Home from "./layout/Home";
import { styled } from "@mui/material/styles";
import MainHomePage from "./pages/MainHomePage";
import "./assets/css/main.css";
import { Box, CircularProgress, CssBaseline, Typography } from "@mui/material";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { ThemeProvider } from "@mui/system";
import SendToNew from "./pages/SendToNew";
import RfqDataGrid from "./pages/RFQList/rfqGridView/rfqDataGrid";
import VendorViewGrid from "./pages/RFQList/VendorGridView/vendorViewGrid";
import ShowCombination from "./pages/ShowCombination";
import withClearCache from "./ClearCache";
import { Routes, Route, useLocation, useParams } from "react-router-dom";
import theme from "./theme/theme";
import { AppMainStyle } from "./theme/AppMainStyle";

import Header from "./layout/header/Header";
import LeftSidebar from "./layout/sidebar/LeftSidebar";
import Footer from "./layout/footer/Footer";
import RFQListVendor from "./pages/RFQListVendor/RFQListVendor";
import { setUserInfo } from "./redux/actions/setUserInfo";
import { setQuotationInfo } from "./redux/actions/QuotationInfoAction";
import { useDispatch, useSelector } from "react-redux";
import { HOSTMAP, accountvendorInfo, getAccountModulePermission, vendorMenu } from "./config/apiUrl";
import VendorLeftSidebar from "./layout/sidebar/RFQListVendor/VendorLeftSidebar";
import VendorHeader from "./layout/header/RFQListVendor/VendorHeader"
import axios from 'axios'
import { OPEN_SNACKBAR, SET_ACCOUNT_MODULE_PERMISSION, SET_ACCOUNT_MODULE_PERMISSION_ERROR, SET_USER_INFO } from "./redux/constants/type";
import { setVendorMenu } from "./redux/actions/vendorActions";
import { setAccountModulePermission } from "./redux/actions/permissionsAction";
const ClearCacheComponent = withClearCache(MainApp);

function VendorApp() {
  return <ClearCacheComponent />;
}

function MainApp(props) {
  const location = useLocation();
  const params = useParams();
  const match = { params };

  useEffect(() => {
    LicenseInfo.setLicenseKey(
      "0e719ddd0cef8d9c99873b65f16810bcTz04Mzg1NCxFPTE3Mzg5OTI5MTQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
    );

  }, []);

  const queryParams = new URLSearchParams(window?.location?.search);
  const vendorListSession = queryParams.get("q");
  const multipleSolution = queryParams.get("multiple_solution");
  let paramsObj = Object.fromEntries(queryParams.entries());
  delete paramsObj?.multiple_solution;

  // console.log('vendorListSession', vendorListSession,);
  const userInfo = useSelector((state) => state.userInfoReducer.userInfo);
  const dispatch = useDispatch();
  const [Loading, setLoading] = useState(false);
  if (vendorListSession && multipleSolution) {
    localStorage.setItem("vendorListSession", vendorListSession);
    localStorage.setItem("multipleSolution", multipleSolution);
    if(paramsObj?.frommail){
      dispatch(setQuotationInfo(paramsObj));
    }
    window.history.replaceState({}, document.title, window.location.pathname);
  }
  // useEffect(() => {

  //   if (Object.keys(userInfo).length > 0) {
  //     setLoading(false);
  //   } else {
  //     // Set a timeout to redirect after 5 seconds if userInfo is still empty
  //     // timeoutId = setTimeout(() => {
  //     //   window.parent.location.href = HOSTMAP;
  //     // }, 7000); // 5000 milliseconds = 5 seconds
  //   }

  // }, [userInfo])
  const storedSessionID = localStorage.getItem("vendorListSession");
  React.useEffect(() => {
    if (storedSessionID) {
      // dispatch(setUserInfo(storedSessionID));
      const url = accountvendorInfo;
      setLoading(true);
      // axios.defaults.headers.common[
      //   "Authorization"
      // ] = `Bearer ${storedSessionID}`;
      axios
        .get(url, {
          headers: {
            "Authorization": `Bearer ${storedSessionID}`
          }
        })
        .then((res) => {
          // const values = res.data.data;
          dispatch({
            type: SET_USER_INFO,
            payload: res.data,
          });
          const permissionUrl = getAccountModulePermission(res?.data?.data?.division_id);
          axios
            .get(permissionUrl, {
              headers: {
                'Authorization' : `Bearer ${storedSessionID}`
              }
            })
            .then((res) => {
              dispatch({
                type: SET_ACCOUNT_MODULE_PERMISSION,
                payload: res.data,
              });
            })
            .catch((err) => {
              dispatch({
                type: SET_ACCOUNT_MODULE_PERMISSION_ERROR,
                payload: err.message,
              });
            });
          axios.get(vendorMenu, {
            headers: {
              "Authorization": `Bearer ${storedSessionID}`
            }
          }).then((res) => {
            dispatch(setVendorMenu(res.data))
            axios.defaults.headers.common["Authorization"] = `Bearer ${storedSessionID}`;

           
            setLoading(false);

          }).catch((err) => {

            setLoading(false);
          })

          // setUserdetails(values)
          // dispatch(setuserdetails(values));
        })
        .catch((err) => {
          console.log("err", err.response.data.code);
          setLoading(false);
          if (err.response.data.code === 401) {
            window.parent.location.href = HOSTMAP;
          }
          dispatch({
            type: OPEN_SNACKBAR,
            payload: {
              type: "error",
              msg: err.response.data.message
                ? err.response.data.message
                : "something went wrong",
            },
          });
        });

    }
  }, [storedSessionID]);




  const MainLayoutDiv = styled("div")(({ theme }) => ({
    display: "flex",
    flex: "1 1 auto",
    maxWidth: "100%",
    paddingTop: 'var(--headerHeight)', // Use with Header
    // paddingTop: 0, // Use without Header
    // paddingLeft: 0, // Use without Sidebar
    paddingLeft: 64, // Use with Sidebar
    flexGrow: 1,
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
  }));
  return  Loading ? (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <CircularProgress className="loader" />
        <br></br>
        <Typography>Please wait a while we are processing...</Typography>
      </Box>
    ) : (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        {AppMainStyle}
        
         <VendorHeader session_id={storedSessionID} />
         <VendorLeftSidebar /> 
        <Home>
          <MainLayoutDiv className="main-layout">
            <Routes>

              <Route path="transaction-register" element={<RFQListVendor session_id={storedSessionID} />} />

            </Routes>
          </MainLayoutDiv>
        </Home>
        <Footer sidebar='Active'/>
      </CssBaseline>
    </ThemeProvider>
  );
}

export default VendorApp;
