import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Snackbar,
  Tooltip,
  Typography
} from "@mui/material";
import {
  GridOverlay,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter
} from "@mui/x-data-grid-pro";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import {
  account_all_users,
  get_Inquiries_data
} from "../../../../config/apiUrl";
import moment from "moment";
import CommonDataGridPro from "../../commonDataGridPro/commonDataGridPro";
import axios from "axios";

const useStyles = makeStyles(() => ({
  RFQDrawer: {
    "&.MuiPaper-root": {
      boxShadow: "none",
      borderRadius: "0"
    },
    "& .DataGFullScreen-drawer": {
      height: "calc(100vh - 62px)",
      width: "100%"
    }
  }
}));

const QcsGridDrawer = ({
  sessionID,
  setOpenQcsDrawer,
  selectedId,
  linkUnlinkData
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [QcsStatusData, setQcsStatusData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [snackbar, setSnackbar] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0
  });

  const configAxios = {
    headers: { Authorization: `Bearer ${sessionID}` }
  };

  const CustomNoRowsOverlay = () => (
    <GridOverlay>
      <Typography>No data available</Typography>
    </GridOverlay>
  );

  const handleDrawerClose = () => {
    setOpenQcsDrawer(false);
  };

  // const handleCloseSnackbar = () => {
  //   setSnackbar(false);
  // };

  function CustomGridToolbar() {
    return (
      <React.Fragment>
        <GridToolbarContainer className="datagrid-toolbar">
          <Grid
            container
            spacing={0}
            sx={{
              width: "100%",
              "&.MuiGrid2-root": {
                paddingTop: { xs: "0", sm: "0", md: "0", alignItems: "center" }
              }
            }}
          >
            <Grid xs={12} sm={12} md={3} xl={4}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <GridToolbarQuickFilter
                  className="DataGrid-SearchBar"
                  sx={{ pb: 0 }}
                />
              </Box>
            </Grid>
            <Grid
              xs={12}
              sm={12}
              md={9}
              xl={8}
              sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
            >
              <Box className="toolbar-right">
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  &nbsp;
                </Box>
                <Box className="dg-filter">
                  <GridToolbarColumnsButton />
                  <GridToolbarExport
                    printOptions={{ disableToolbarButton: true }}
                    csvOptions={{
                      fileName: "QCS Details Report"
                    }}
                  />
                  <GridToolbarFilterButton />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </GridToolbarContainer>
        <GridToolbarContainer
          className="datagrid-toolbar"
          sx={{ display: { sm: "flex", md: "none" } }}
        >
          <Grid
            container
            spacing={0}
            sx={{ width: "100%", alignItems: "center" }}
          >
            <Grid xs={2} md={6}>
              <Box>&nbsp;</Box>
            </Grid>
            <Grid
              xs={10}
              md={6}
              sx={{ textAlign: { xs: "right", sm: "right" } }}
            >
              <Box className="dg-filter">
                <GridToolbarColumnsButton />
                <GridToolbarExport
                  printOptions={{ disableToolbarButton: true }}
                  csvOptions={{
                    fileName: "QCS Details Report"
                  }}
                />
                <GridToolbarFilterButton />
              </Box>
            </Grid>
          </Grid>
        </GridToolbarContainer>
      </React.Fragment>
    );
  }

  const qcsCreateDateCell = (params) => {
    const { value } = params;
    const formatDate = moment(value, "YYYY-MM-DD HH:mm:ss").format(
      "DD/MM/YYYY hh:mm:ss a"
    );
    const splittedArray = formatDate?.split(" ");
    return (
      <Box>
        <Typography variant="body2" component="div">
          {splittedArray[0]}
        </Typography>
        <Typography variant="caption" component="div">
          {splittedArray[1] + " " + "IST"}
        </Typography>
      </Box>
    );
  };

  const qcsSavedUserName = (params) => {
    return (
      <Box sx={{ width: "100%" }}>
        <Tooltip title={params.row.created_by}>
          <Typography variant="body2" component="div" noWrap>
            {params.row.created_by}
          </Typography>
        </Tooltip>
      </Box>
    );
  };

  const qcsLastActionUserName = (params) => {
    return (
      <Box sx={{ width: "100%" }}>
        <Tooltip title={params.row.last_action_by}>
          <Typography variant="body2" component="div" noWrap>
            {params.row.last_action_by}
          </Typography>
        </Tooltip>
      </Box>
    );
  };

  const fetchQCSDetails = async () => {
    if (selectedId) {
      let url = get_Inquiries_data + selectedId + "/qcses";
      try {
        let response = await axios.get(url, configAxios);
        if (response?.status === 200 && response?.data) {
          const userResponse = await axios.get(account_all_users, configAxios);
          if (userResponse.status === 200) {
            const accountUserData = userResponse.data.users;
            const userIdMap = {};
            accountUserData.forEach((user) => {
              userIdMap[user.UserID] = user.UserName;
            });

            const filteredQcsStatusData = response.data.qcses.map((qcs) => ({
              ...qcs,
              created_by: userIdMap[qcs.created_by] || qcs.created_by,
              last_action_by:
                qcs.last_action_by != null
                  ? userIdMap[qcs.last_action_by]
                  : userIdMap[qcs.created_by]
            }));
            setQcsStatusData(filteredQcsStatusData);
            setLoading(false);
          } else {
            throw new Error("Network response for user data was not ok");
          }
        } else {
          setQcsStatusData([]);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // setErrorMessage(error);
        // setSnackbar(true);
        setLoading(false); // Stop the loader here
      }
    }
  };

  useEffect(() => {
    fetchQCSDetails();
  }, [selectedId]);

  const valueGetterForDate = (params) => {
    const { value } = params;
    // Assuming the date format is "YYYY-MM-DD HH:MM:SS IST"
    const dateParts = value.split(" ");
    if (dateParts.length === 3) {
      const [datePart, timePart, timeZone] = dateParts;
      const iso8601DateTime = `${datePart}T${timePart}`;
      const parsedDate = new Date(iso8601DateTime);

      // Check if the parsed date is a valid date object
      if (!isNaN(parsedDate)) {
        return parsedDate;
      }
    }
    return null; // Return null for invalid date strings
  };

  const columns = [
    {
      field: "number",
      headerName: "QCS NO",
      width: 100
    },
    {
      field: "created_at",
      headerName: "QCS CREATION DATE",
      width: 150,
      type: "date",
      renderCell: qcsCreateDateCell,
      valueGetter: valueGetterForDate,
      valueFormatter: (params) => {
        const { value } = params;
        return (
          moment(value, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY hh:mm:ss") +
          " IST"
        );
      }
    },
    {
      field: "status",
      headerName: "QCS STATUS",
      width: 150
    },
    {
      field: "created_by",
      headerName: "SAVED BY",
      width: 150,
      renderCell: qcsSavedUserName
    },
    {
      field: "last_action_by",
      headerName: "LAST ACTION BY",
      width: 150,
      renderCell: qcsLastActionUserName
    }
  ];

  const generateRowId = (row) => {
    const id = row?.number;
    return id;
  };

  return (
    <Card className={classes.RFQDrawer}>
      <CardHeader
        action={
          <Tooltip title="Close">
            <IconButton
              onClick={handleDrawerClose}
              aria-label="close"
              size="small"
              sx={{ color: "var(--grey-500)" }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        }
        title={
          <Typography
            variant="subtitle1"
            fontWeight={700}
            color={"secondary.main"}
            noWrap
          >
            QCS Details
          </Typography>
        }
        subheader={
          <Typography
            variant="subtitle2"
            fontWeight={500}
            color={"text.primary"}
            component="div"
            sx={{ marginTop: "-5px" }}
            noWrap
          >
            {linkUnlinkData[0]?.inquiryNo}
          </Typography>
        }
        sx={{
          borderBottom: "1px solid var(--bluegrey-500)",
          pt: 1,
          pb: 1,
          "& .MuiCardHeader-action": {
            alignSelf: "center"
          }
        }}
      />

      <CardContent sx={{ p: "0 !important" }}>
        <Box className="DataGFullScreen-drawer">
          <CommonDataGridPro
            loading={loading}
            rows={QcsStatusData}
            getRowId={generateRowId}
            columns={columns}
            columnHeaderHeight={40}
            pagination={true}
            paginationModel={paginationModel}
            NoRowsOverlay={CustomNoRowsOverlay}
            onPaginationModelChange={setPaginationModel}
            Toolbar={CustomGridToolbar}
            className="fullScreenGrid"
            sx={{
              "&.MuiDataGrid-root": {
                border: 0
              },
              "& .MuiDataGrid-virtualScrollerContent--overflowed .MuiDataGrid-row--lastVisible .MuiDataGrid-cell":
              {
                borderBottomColor: "var(--bluegrey-500)"
              }
            }}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default QcsGridDrawer;
