import GlobalStyles from "@mui/material/GlobalStyles";

export const PageContainerStyle = (
  <GlobalStyles
  styles={(theme) => ({
    '.page_container': {    
      margin: '0 auto',
      width: '100%',
      padding: '0 22px 24px 22px',
    },
    '.page_content_main': {
      padding: '0',
    },          
    '.card_inner_content': {
      minHeight: '400px',
      background: theme.palette.grey[50],
    },
    '.card_left_content': {
      minHeight: '110px',
      borderRight: `1px solid ${theme.palette.bluegrey[500]}`,      
    },
    '.card_top_bg': {
      height: '50px',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      padding: '0 10px 0 5px',
      borderBottom: `1px solid ${theme.palette.bluegrey[500]}`,       
    },
    '.card_right_content': {
      minHeight: '110px',
    },
    '.itemize_vendor_card .MuiDataGrid-row, .category_vendor_items .MuiDataGrid-row': {
      background: theme.palette.bluegrey[0],
      marginBottom: '8px',
      borderRadius: '3px',
      width: '99% !important',
    },
    '.itemize_vendor_card .MuiDataGrid-row:hover, .category_vendor_items .MuiDataGrid-row:hover': {
      background: theme.palette.bluegrey[0],
    },
   '.itemize_vendor_card .MuiDataGrid-row [class*="MuiDataGrid-cell"]:first-of-type, .category_vendor_items .MuiDataGrid-row [class*="MuiDataGrid-cell"]:first-of-type': {
      borderLeft: `1px solid ${theme.palette.bluegrey[500]}`,       
      borderTopLeftRadius: '4px',
      borderBottomLeftRadius: '4px',
      padding: '0 6px',
    },
    '.itemize_vendor_card .MuiDataGrid-row [class*="MuiDataGrid-cell"]:last-child, .category_vendor_items .MuiDataGrid-row [class*="MuiDataGrid-cell"]:last-child': {
      borderRight: `1px solid ${theme.palette.bluegrey[500]}`,
      borderTopRightRadius: '4px',
      borderBottomRightRadius: '4px',
    },
    '.itemize_vendor_card .MuiDataGrid-row [class*="MuiDataGrid-cell"], .category_vendor_items .MuiDataGrid-row [class*="MuiDataGrid-cell"]': {
      borderTop: `1px solid ${theme.palette.bluegrey[500]}`,
      borderBottom: `1px solid ${theme.palette.bluegrey[500]}`,
    },
    '.category_vendor_items .MuiDataGrid-columnHeader:first-of-type': {
      padding:0, 
    },
    '.category_vendor_items .MuiDataGrid-root .MuiDataGrid-columnHeader:first-of-type .MuiDataGrid-columnHeaderTitleContainer': {
      padding: '0 6px',
    },
    '.vendor_not_available_main': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
    },
    '.vendor_not_available_main h5': {      
      fontWeight: '600',      
      color: theme.palette.grey[900],
      marginBottom: '5px',
      textAlign: 'center',
    },
    '.vendor_not_available_main span': {
      fontSize: 'calc(14px + (15 - 13) * ((100vw - 300px) / (1920 - 300)))',
      lineHeight: '17px',
      fontWeight: '400',
      textAlign: 'left',
      letterSpacing: '0px',
      color: theme.palette.text.primary,
      marginBottom: '10px',
    },
    '.vendor_not_available_main span span': {
      color: theme.palette.primary.main,
      fontWeight: '500',
      cursor: 'pointer',
    }
  
  })}

  />
);
    