import {
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Divider,
  Tooltip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  alpha,
  Button,
  Autocomplete,
} from "@mui/material";
import { Box } from "@mui/system/";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import HelpIcon from "@mui/icons-material/Help";
import moment from "moment";
import SwitchLables from "../SwitchLabels/SwitchLables";
import { CustomSwitchLabel } from "../Switches";
import { useLocation } from "react-router-dom";
import axiosInstance from "../../interceptor/axiosInstance";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {activityTemplateListUri} from "../../config/apiUrl";
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined';
import axios from "axios";

// One time slot every 30 minutes.
const timeSlots = Array.from(new Array(24 * 2)).map(
  (_, index) =>
    `${index < 20 ? "0" : ""}${Math.floor(index / 2)}:${
      index % 2 === 0 ? "00" : "30"
    }`
);

const Reminders = (props) => {
  const [error, setError] = useState("");
  const { includeTB } = useSelector((reducer) => reducer.drawerValuesReducer);
  const location = useLocation();
  const [projectList, setProjectList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [selectedProjectValue, setSelectedProjectValue] = useState(props.settings.projects || []);
  const [selectedActivityTemplate, setActivityTemplate] = useState(props?.settings?.activityTemplate || []);
  const [selectedallowVendorFill, setAllowVendorFill] = useState(props?.settings?.allowVendorFill  || "");
  const [activityTemplateList,setActivityTemplateList] = useState([]);
  const [serviceRFQ,setServiceRFQ] = useState(props?.settings.serviceRFQ || "default");
  const [allowMultipleSolution,setAllowMultipleSolution] = useState(props?.settings.includeSlab || "default");
  const [activityTemplateid,setActivityTemplateid] = useState(-1);
  const [validityDate,setValidityDate] = useState(props.settings.validityDate || "");
  const [validityTime,setValidityTime] = useState(props.settings.validityTime || "23:30")
  const [template,setTemplate] = useState((props.settings.template?.id) ? props.settings.template : '');
  
  const [selectAllVendors, setSelectAllVendors] = useState(props.settings.selectAllVendors || false);
  const [priorityRfq,setPriorityRfq] = useState(props.settings.priorityRfq || false);
  const [sealedGroupRfq , setSealedGroupRfq] = useState(props.settings.sealedGroupRfq || false);
  const [vendorMasterPaymentTermReminder,setVendorMasterPaymentTermReminder] = useState(props.settings.vendorMasterPaymentTermReminder || false)
  const [selectedIncludeTB,setSelectedIncludeTB] = useState(props.settings.includeTB || false)
  const [vendorMasterIncoTermReminder,setVendorMasterIncoTermReminder] = useState(props.settings.vendorMasterIncoTermReminder || false);
  const [basicRateReminder,setBasicRateReminder] = useState(props.settings.basicRateReminder || false)
  const [landedCostReminder , setLandedCostReminder] = useState(props.settings.landedCostReminder|| false);
  const [sendSMS,setSendSMS] = useState(props.settings.sendSMS || false)
  const [enableHourlyReminder,setEnableHourlyReminder] = useState(props.settings.enableHourlyReminder || false);
  const [sendSMSWithHourlyReminder,setSendSMSWithHourlyReminder] = useState(props.settings.sendSMSWithHourlyReminder || false);
  const [enableHourlyReminderTB,setEnableHourlyReminderTB] = useState(props.settings.enableHourlyReminderTB || false);
  const [sendSMSWithHourlyReminderTB,setSendSMSWithHourlyReminderTB]= useState(props.settings.sendSMSWithHourlyReminderTB || false);
  const [includeReminderInterval,setIncludeReminderInterval] = useState(props.settings.includeReminderInterval || false);
  const [includeSlab,setIncludeSlab] = useState(props?.settings.includeSlab || "default");
  
  if(window.location.pathname.indexOf("prcombination") > -1){
    if( props.accountModulePermission["Service PR"]
    ?.module_activated === "Y" && props.service_from_pr === "1" && !(serviceRFQ && !allowMultipleSolution)){
      setAllowMultipleSolution(false);
      setServiceRFQ(true);
      let buttonClick = {};
      buttonClick.target = {};
      buttonClick.target.value = "on";
      buttonClick.target.type = 'checkbox';
      if(!props?.settings.serviceRFQ){
        buttonClick.target.name = "serviceRFQ";
        buttonClick.target.checked = serviceRFQ;
        props.handleChange(buttonClick);
      }

      if(props?.settings.allowMultipleSolution){
        buttonClick.target.name = "allowMultipleSolution";
        buttonClick.target.checked = false;
        props.handleChange(buttonClick);
      }
    }else{
      if(allowMultipleSolution != props?.settings.allowMultipleSolution && allowMultipleSolution != 'default'){

        let buttonClick = {};
        buttonClick.target = {};
        buttonClick.target.value = "on";
        buttonClick.target.type = 'checkbox';
        buttonClick.target.name = "allowMultipleSolution";
        buttonClick.target.checked = allowMultipleSolution;
        props.handleChange(buttonClick);
      }
    }
  }
  const validityDateError = props.settings.validityDateError;
  useEffect(() => {
    fetchProjectsOptions();
    if(props.accountModulePermission["Service PR"]?.module_activated === "Y"){
      fetchActivityTemplateOptions();
    }
    return () => {};
    //eslint-disable-next-line
  }, []);

  const handleProjects = (event, value) => {
    let e = {};
    e.target = {};
    e.target.name = "projects";
    e.target.value = value;
    props.handleChange(e);
    if(event != null){
      setSelectedProjectValue(value);
    }
  };
  if(selectedProjectValue == props.settings.projects){
  }else{
   if(selectedProjectValue?.length){
     handleProjects(null,selectedProjectValue);
   }
  }
  if(selectedActivityTemplate == props.settings.activityTemplate){
  }else{
   if(selectedActivityTemplate?.length){
     handleActivityTemplate(null,selectedActivityTemplate);
   }
  }
  const fetchProjectsOptions = () => {
    axiosInstance
      .get("/v1/projects")
      .then((res) => {
        setProjectList(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        props.openSnackbar("error", "Failed to fetch Projects");
        setLoading(true);
      });
  };

  const fetchActivityTemplateOptions = () => {
    axios.defaults.headers.common[
     "Authorization"
   ] = `Bearer ${props.sessionId}`;

   axios
     .get(activityTemplateListUri)
     .then((res) => {
       setActivityTemplateList( res.data.data);
       if(!res.data.data?.length){
         props.openSnackbar("error", `No ${props.service_label} Templates Found`);
       }
       setLoading(false);
     })
     .catch((err) => {
       props.openSnackbar("error", `Failed to fetch ${props.service_label} templates`);
       setLoading(true);
     });
 };
  const handleActivityTemplateVendorFill =(event,value) =>{
    let e = {};
    e.target = {};
    e.target.name = "allowVendorFill";
    if(event != null){
      e.target.value = value?.props?.value || value;
      setAllowVendorFill(e.target.value)
    }
    else{
      e.target.value = value;
    }
    props.handleChange(e);
   }
   if(selectedallowVendorFill != props?.settings?.allowVendorFill && selectedallowVendorFill != ""){
    handleActivityTemplateVendorFill(null,selectedallowVendorFill);
   }
   const handleActivityTemplate =(event,value) =>{
    let e = {};
    e.target = {};
    e.target.name = "activityTemplate";
    if(!value){
      value = {
        template_id: "",
        template_name:"",
        display_name:'',
        template_code:"",
        update: 0
      };
    }
    e.target.value = value;
    
    if(e &&  e.target?.template_id != activityTemplateid){
      setActivityTemplateid(e.target?.template_id)
      setActivityTemplateid(value?.template_id || -1);
      props.handleChange(e);
      value.update = 1;
    }
    if(event != null){
      setActivityTemplate(value)
    }
   }
   const handleButtonToggle =(name,value) =>{
    let e = {};
    e.target = {};
    e.target.name = name;
    e.target.value = value;
    props.handleChange(e);
   }
   const handleValidityDate =(event,newVal) => {
    let obj = {};
    obj.target = {};
    obj.target.name = "validityDate";
    obj.target.value = newVal;
    props.handleChange(obj);
    if(!event){
      setValidityDate(newVal);
    }
  }
  const handleValidityTime= (event, newVal) => {
    let obj = {};
    obj.target = {};
    obj.target.name = "validityTime";
    obj.target.value = newVal;
    props.handleChange(obj);
    if(!event){
      setValidityTime(newVal)
    }
  }

  if(template?.id != props.settings.template?.id && template != ""){
    let obj = {};
    obj.target = {};
    obj.target.name = "template";
    obj.target.value = template;
    props.handleChange(obj)
  }
  if(validityDate != props?.settings?.validityDate && validityDate != ""){
    handleValidityDate(true,validityDate);
   }
   if(validityTime != props?.settings?.validityTime){
    if(validityTime != "23:30"){
      handleValidityTime(true,validityTime);
    }else{
      setValidityTime(props?.settings?.validityTime);
    }
   }
  return (
    <React.Fragment>
      <Grid item lg={12} md={12} sm={12} xs={12} className="mt-25">
        {location.pathname.indexOf("prcombination") > -1 && (
          <Box
            sx={{
              backgroundColor: "white",
              border: "1px solid",
              borderColor: (theme) => theme.palette.bluegrey[500],
              padding: "16px",
              borderRadius: "4px",
            }}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  minDate={new Date()}
                  format="dd/MM/yyyy"
                  sx={{ width: "100%" }}
                  slotProps={{
                    textField: {
                      className: "template_Date_picker",
                      size: "fullWidth",
                      // title:{error ? moment(value).isValid() ? "Delivery date should be greater than or equal to current date" : "invalid date" : ""},
                      placeholder: "Date",
                      label: "Validity Date",
                      error: validityDateError,
                      helperText: validityDateError ? "invalid" : "",
                    },
                  }}
                  // ampm={false}
                  name={"validityDate"}
                  value={validityDate || null}
                  onChange={(val) => handleValidityDate(false,val)}
                />
              </LocalizationProvider>
              {parseInt(props.settings.validityMaxDays) > 0 && (
                <Typography variant="caption" color="grey.500">
                  Maximum RFQ validity{" "}
                  {moment()
                    .add(props.settings.validityMaxDays, "days")
                    .format("DD/MM/YYYY")}
                </Typography>
              )}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} className="mt-25">
              <Autocomplete
                disableClearable
                options={timeSlots}
                disabled={!validityDate}
                value={validityTime}
                onChange={(event, newVal) =>handleValidityTime(false,newVal)}
                // onChange={(e, newVal) => handleRFQvalidtyTimeChange(newVal)}
                getOptionDisabled={(option) => {
                  if (props.settings.validityDate) {
                    if (
                      moment(props.settings.validityDate).isSame(
                        moment(),
                        "day"
                      )
                    ) {
                      let beginningTime = moment();
                      beginningTime.set("hour", option.split(":")[0]);
                      beginningTime.set("minute", option.split(":")[1]);
                      let endTime = moment();
                      return endTime.isAfter(beginningTime);
                    }
                  }
                }}
                // sx={{ ml: 2, width: "50%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Validity Time (IST)"
                    fullWidth
                    name="validityTime"
                    // helperText={
                    //   RFQStatic.RFQValidityTime.error &&
                    //    RFQStatic.RFQValidityTime.ErrorMessage
                    // }
                  />
                )}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} className="mt-25">
              {location.pathname.indexOf("prcombination") > -1 ? (
                // && props.applyFlag === true
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Select Template</InputLabel>
                  <Select
                    fullWidth
                    label="Select Template"
                    name={"template"}
                    value={template}
                    onChange={(event) => {setTemplate(event.target.value);props.handleChange(event)}}>
                    <MenuItem value={""}>Select Template</MenuItem>
                    {props.settings.loading && (
                      <MenuItem value={20}>Loading...</MenuItem>
                    )}
                    {props.templateOptions &&
                      props.templateOptions.map((option, index) => (
                        <MenuItem key={option.id} value={option}>
                          {option.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              ) : null}
            </Grid>

            {props.accountModulePermission?.["Project"]?.module_activated ===
              "Y" && (
              <Grid item lg={12} md={12} sm={12} xs={12} className="mt-25">
                <Autocomplete
                  options={projectList}
                  value={selectedProjectValue}
                  onChange={(event, value) => handleProjects(event, value)}
                  // loading={this.state.paymentTermsCodeLoading}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  multiple
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Select Projects"
                      fullWidth
                      name="projectList"
                    />
                  )}
                />
              </Grid>
            )}
            {window.location.pathname.indexOf("prcombination") > -1 && props.accountModulePermission["Service PR"]
            ?.module_activated === "Y" && props.service_from_pr === '1'
             && (<Box sx={{
            backgroundColor: "white",
            border: "1px solid",
            borderColor: (theme) => theme.palette.bluegrey[500],  
            padding: '16px',
            borderRadius: '4px',
            mt: 2
          }}>
            { (
            <>
              <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt: 1,}}>
                <CustomSwitchLabel
                sx={{ml:0, display: 'flex', justifyContent: 'space-between' }}
                  control={
                    <Switch
                    onChange={(event,value)=>{
                      if(value && (allowMultipleSolution && allowMultipleSolution != "default")){
                        props.openSnackbar("error", `${props.service_label} and ${props.label} can't be activited together`);
                      }
                      handleButtonToggle("serviceRFQ",value);
                      props.handleChange(event)
                    }}
                    name="serviceRFQ"
                    />
                  }
                  checked={(serviceRFQ != "default" ? serviceRFQ : false) && props.service_from_pr === "1" && !(allowMultipleSolution != "default" ? allowMultipleSolution : false)}
                  disabled = {window.location.pathname.indexOf("prcombination") > -1 && props.accountModulePermission["Service PR"]
                ?.module_activated === "Y" }
                  labelPlacement="start"
                  label="Service RFQ"
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt: 1,}}>
                <FormControl fullWidth>
                  <Autocomplete
                    disabled = {!(serviceRFQ != "default" ? serviceRFQ : false)}
                    options={activityTemplateList}
                    value={selectedActivityTemplate}
                    onChange={(event, value) => handleActivityTemplate(event,value)}
                    getOptionLabel={(option) => option.display_name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={"Select " + props.service_label +" Template"}
                        fullWidth
                        name="activityTemplateList"
                        />
                        )}
                    />
                </FormControl>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt: 2,}}>
                <FormControl fullWidth>              
                  <InputLabel id="demo-simple-select-label">Allow Vendor To Fill {props.service_label} Template</InputLabel>                  
                  <Select
                    disabled = {!(serviceRFQ != "default" ? serviceRFQ : false)}
                    // value={age}
                    value={selectedallowVendorFill}
                    label={"Allow Vendor To Fill " + props.service_label +" Template"}
                    onChange={(event, value) => handleActivityTemplateVendorFill(event,value)}
                    className="template_dropdown_menu"
                  >
                    <MenuItem value={10}>Partial</MenuItem>
                    <MenuItem value={20}>Complete</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt: 1}}>
                <Box sx={{display:"flex", gap:"10px", alignItems:"center", pb:1}} disabled = {!(serviceRFQ != "default" ? serviceRFQ : false)}>
                <Button
                  startIcon={<VisibilityIcon />}
                  disabled = {!(serviceRFQ != "default" ? serviceRFQ : false)}
                  onClick={(e) =>
                    { 
                      if((serviceRFQ)){
                        if ( activityTemplateid == -1) {
                          props.openSnackbar("error", `Please select the ${props.service_label} Template`);
                          setLoading(true);
                        }
                        else{
                          props.triggerTemplateIFrame(
                            "activityPreview",
                            activityTemplateid,
                            0,
                            1,
                          )
                        }
                      }
                    }
                  }
                >
                  Preview
                </Button>
                </Box>
                {/* <Divider textAlign="center">OR</Divider> */}
              </Grid>
            </>
            )}
            {/* <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt: 1,}}>
              <CustomSwitchLabel
              sx={{ml:0, display: 'flex', justifyContent: 'space-between' }}
                control={
                  <Switch
                    onChange={(event,value)=>{
                      if(value && serviceRFQ && serviceRFQ != "default"  && props.accountModulePermission["Service PR"]?.module_activated === "Y" ){
                        props.openSnackbar("error", `${props.service_label} and ${props.label} can't be activited together`);
                        let activityHash = {};
                        activityHash.id = "";
                        activityHash.name = '';
                        handleActivityTemplate(1,activityHash)
                        handleActivityTemplateVendorFill(1,'');
                      }
                      if(! props.accountModulePermission["Service PR"]?.module_activated !== "Y" ){
                        setAllowMultipleSolution(value);;
                        setIncludeSlab(value);
                      }
                      handleButtonToggle("allowMultipleSolution",value);
                      props.handleChange(event)
                    }}
                  />
                }
                checked={!(serviceRFQ != "default" ? serviceRFQ : false) && (allowMultipleSolution != "default" ? allowMultipleSolution : false)}
                disabled = {window.location.pathname.indexOf("prcombination") > -1 && props.accountModulePermission["Service PR"]
                ?.module_activated === "Y" }
                labelPlacement="start"
                label={`Allow ${props.label}`}
              />
            </Grid> */}
          </Box>)
          }
            {location.pathname.indexOf("prcombination") > -1 ? (
              <Grid item lg={12} md={12} sm={12} xs={12} className="mt-25">
                <FormControl
                  disabled={props.settings.isDisabled}
                  fullWidth
                  variant="outlined">
                  <InputLabel>Select RFQ Type</InputLabel>
                  <Select
                    fullWidth
                    label="Select RFQ Type"
                    name={"rfqType"}
                    value={props.settings.rfqType}
                    onChange={(event) => props.handleChange(event)}>
                    <MenuItem value="category" selected>
                      Category Specific RFQ
                    </MenuItem>
                    <MenuItem value="item">Item Specific RFQ</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            ) : null}
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
              <CustomSwitchLabel
                sx={{ ml: 0, display: "flex", justifyContent: "space-between" }}
                control={
                  <Switch
                    onChange={(event,value) => {setSelectAllVendors(value);props.handleChange(event)}}
                    disabled={
                      props.featurePermission
                        .restrict_users_to_deselect_linked_vendors_while_floating_rfq
                        ?.feature_value === "Y"
                    }
                    name="selectAllVendors"
                    checked={selectAllVendors}
                  />
                }
                labelPlacement="start"
                label={"Select All Vendors"}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 1 }}>
              <CustomSwitchLabel
                sx={{ ml: 0, display: "flex", justifyContent: "space-between" }}
                control={
                  <Switch
                    onChange={(event,value) => {setPriorityRfq(value);props.handleChange(event)}}
                    name="priorityRfq"
                    checked={priorityRfq}
                  />
                }
                labelPlacement="start"
                label={"Priority RFQ"}
              />
            </Grid>
            {props.accountModulePermission?.SealedRFQ?.module_activated ===
              "Y" && (
              <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 1 }}>
                <CustomSwitchLabel
                  sx={{
                    ml: 0,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  control={
                    <Switch
                      onChange={(event,value) => {setSealedGroupRfq(value);props.handleChange(event)}}
                      name="sealedGroupRfq"
                      checked={sealedGroupRfq}
                      disabled={
                        props.featurePermission?.sealed_rfq?.feature_value ===
                        "Y"
                      }
                    />
                  }
                  labelPlacement="start"
                  label={"Sealed Group RFQ"}
                />
              </Grid>
            )}
            {props.accountModulePermission["Technical Bid"]
              ?.module_activated === "Y" && (
              <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 1 }}>
                <CustomSwitchLabel
                  sx={{
                    ml: 0,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  control={
                    <Switch
                      onChange={(event,value) => {setSelectedIncludeTB(value);props.handleChange(event)}}
                      name="includeTB"
                      checked={selectedIncludeTB}
                      disabled={props.settings.disabledtechnicalbid}
                    />
                  }
                  labelPlacement="start"
                  label={"Include Technical Bid"}
                />
              </Grid>
            )}
            {props.accountModulePermission?.["Multiple Solutions"]
              ?.module_activated === "Y" &&
              props.settings.allowed_slab != 1 && (
                <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 1 }}>
                  <CustomSwitchLabel
                    sx={{
                      ml: 0,
                      display: "flex",
                      justifyContent: "space-between",
                    }}

                    control={
                      <Switch
                        onChange={(event) => props.handleChange(event)}
                        name="allowMultipleSolution"
                        checked={props.settings.allowMultipleSolution}
                      />
                    }
                    labelPlacement="start"
                    label={`Allow ${props.label}`}
                  />
                </Grid>
              )}
            {props.accountModulePermission?.["Fetch Payment Term"]
              ?.module_activated === "Y" && (
              <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 1 }}>
                <CustomSwitchLabel
                  sx={{
                    ml: 0,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  control={
                    <Switch
                      disabled={
                        props.masterPermission?.update_payment_terms === "N"
                      }
                      onChange={(event,value) => {setVendorMasterPaymentTermReminder(value);props.handleChange(event)}}
                      name="vendorMasterPaymentTermReminder"
                      checked={vendorMasterPaymentTermReminder}
                    />
                  }
                  labelPlacement="start"
                  label={"Vendor Master Payment Term"}
                />
              </Grid>
            )}
            {props.accountModulePermission?.["Fetch Inco Term"]
              ?.module_activated === "Y" && (
              <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 1 }}>
                <CustomSwitchLabel
                  sx={{
                    ml: 0,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  control={
                    <Switch
                      disabled={
                        props.masterPermission?.update_inco_terms === "N"
                      }
                      onChange={(event,value) => {setVendorMasterIncoTermReminder(value);props.handleChange(event)}}
                      name="vendorMasterIncoTermReminder"
                      checked={vendorMasterIncoTermReminder}
                    />
                  }
                  labelPlacement="start"
                  label={"Vendor Master Inco Term"}
                />
              </Grid>
            )}
          </Box>
        )}
        {location.pathname.indexOf("prcombination") > -1 &&
          props.featurePermission?.display_vendors_rank?.feature_value ===
            "Y" && (
            <React.Fragment>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography
                  fontWeight={500}
                  sx={{
                    textTransform: "uppercase",
                    color: "grey.500",
                    mt: 2,
                    mb: 2,
                  }}>
                  Display Rank to Vendor on
                </Typography>
              </Grid>
              <Box
                sx={{
                  backgroundColor: "white",
                  border: "1px solid",
                  borderColor: (theme) => theme.palette.bluegrey[500],
                  padding: "16px",
                  borderRadius: "4px",
                }}>
                <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 1 }}>
                  <CustomSwitchLabel
                    sx={{
                      ml: 0,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    control={
                      <Switch
                        onChange={(event,value) => {setBasicRateReminder(value);props.handleChange(event)}}
                        name="basicRateReminder"
                        checked={basicRateReminder}
                      />
                    }
                    labelPlacement="start"
                    label="Basic Rate"
                  />
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 1 }}>
                  <CustomSwitchLabel
                    sx={{
                      ml: 0,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    control={
                      <Switch
                        onChange={(event,value) => {setLandedCostReminder(value);props.handleChange(event)}}
                        name="landedCostReminder"
                        checked={landedCostReminder}
                      />
                    }
                    labelPlacement="start"
                    label="Landed Cost"
                  />
                </Grid>
              </Box>
            </React.Fragment>
          )}
        <Typography
          fontWeight={500}
          sx={{ textTransform: "uppercase", color: "grey.500", mt: 2, mb: 2 }}>
          QUOTATION REMINDERS
        </Typography>
        <Box
          sx={{
            backgroundColor: "white",
            border: "1px solid",
            borderColor: (theme) => theme.palette.bluegrey[500],
            padding: "16px",
            borderRadius: "4px",
          }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              minDate={new Date().setDate((new Date()).getDate() + 1)}
              format="dd/MM/yyyy"
              sx={{ width: "100%" }}
              slotProps={{
                textField: {
                  className: "template_Date_picker",
                  size: "fullWidth",
                  placeholder: "Date",
                  label: "Start Date",
                  error: error,
                  helperText: error && "Invalid Date",
                  // title: error
                  //   ? moment(value).isValid()
                  //     ? "Delivery date should be greater than or equal to current date"
                  //     : "invalid date"
                  //   : "",
                },
              }}
              // ampm={false}
              name={"startDate"}
              value={props.settings.startDate ? new Date(props.settings.startDate) : null}
              onChange={(newVal) => {
                let obj = {};
                obj.target = {};
                obj.target.name = "startDate";
                obj.target.value = newVal;
                props.handleChange(obj);
                // if (!newVal || !newVal?._isValid || !(newVal.isAfter(moment()))) {
                // props.handleDateChanges()
                //}
              }}
            />
          </LocalizationProvider>
          <Grid item lg={12} md={12} sm={12} xs={12} className="mt-25">
            <Box>
              <Typography fontSize={14}>
                Intervals(in days ) at which new reminder has to be set
              </Typography>
            </Box>
            <TextField
              fullWidth
              variant="outlined"
              type="number"
              value={props.settings.intervalDays}
              disabled={
                !props.settings.startDate ||
                !moment(props.settings.startDate).isAfter(moment())
              }
              error={!!props.settings.intervalDaysError}
              helperText={props.settings.intervalDaysError}
              name="intervalDays"
              onChange={(e) => props.handleChange(e)}
              onBlur={(event) => {
                if (parseInt(props.settings.intervalDays) <= 0) {
                  props.setinervalDaysError(
                    "Interval Days cannot be negative or 0"
                  );
                } else {
                  props.setinervalDaysError();
                }
              }}
              onKeyPress={(event) => {
                const keyValue = event.key;
                if (keyValue === "-" || keyValue === "+") {
                  event.preventDefault();
                }
              }}
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*", min: 1 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title="(eg. Enter 7 if 1 reminder has to be sent after every 7 days.The first reminder will be sent on the start date. Reminders will be sent until the validity date expires.)">
                      <HelpIcon
                        sx={{ color: (theme) => theme.palette.grey[600] }}
                      />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
            <Box sx={{ mt: 2 }}>
              <SwitchLables
                checked={sendSMS}
                masterPermission={props.masterPermission}
                handleChange={props.handleChange}
                setChange={setSendSMS}
                name={"sendSMS"}
                label="Send SMS Reminders"
                disabled={
                  !props.settings.startDate ||
                  !moment(props.settings.startDate).isAfter(moment())
                }></SwitchLables>
            </Box>
          </Grid>
          {props.featurePermission["hourly_quotation_reminders"][
            "feature_value"
          ] === "Y" && (
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              // sx={{backgroundColor:'#ebeef2'}}
              sx={{
                mt: 2,
                ml: "-20px",
                mr: "-20px",
                padding: "16px 12px",
                backgroundColor: (theme) =>
                  alpha(theme.palette.grey[50], "0.5"),
                // borderTop: `1px solid`,
                // borderBottom: `1px solid`,
                // borderColor: (theme)=> theme.palette.bluegrey[500],
                maxWidth: "inherit !important",
              }}
              className="mt-25">
              <Box sx={{ p: 1 }}>
                <SwitchLables
                  disabled={false}
                  checked={enableHourlyReminder}
                  name={"enableHourlyReminder"}
                  handleChange={props.handleChange}
                  setChange = {setEnableHourlyReminder}
                  label="Enable Hourly Reminders"></SwitchLables>
              </Box>

              <Box sx={{ p: 1 }}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Hourly Reminders</InputLabel>
                  <Select
                    fullWidth
                    disabled={!enableHourlyReminder}
                    label="Hourly Reminder"
                    name={"hourlyReminderValue"}
                    value={props.settings.hourlyReminderValue}
                    onChange={(event) => props.handleChange(event)}>
                    <MenuItem value="">
                      <em>select</em>
                    </MenuItem>
                    {[...Array(11).keys()].map((cv) => (
                      <MenuItem key={cv} value={cv + 1}>
                        {cv + 1} Hour
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Box sx={{ p: 1 }}>
                <SwitchLables
                  checked={sendSMSWithHourlyReminder}
                  handleChange={props.handleChange}
                  name="sendSMSWithHourlyReminder"
                  sx={{ mr: "-5px" }}
                  setChange={setSendSMSWithHourlyReminder}
                  disabled={!enableHourlyReminder}
                  label="Send SMS Hourly Reminders"
                />
              </Box>
            </Grid>
          )}
        </Box>
      </Grid>
      {props.accountModulePermission["Technical Bid"]?.module_activated ===
        "Y" &&
        (location.pathname.indexOf("prcombination") > -1
          ? selectedIncludeTB
          : includeTB) && (
          <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 1 }}>
            <Typography
              fontWeight={500}
              sx={{
                textTransform: "uppercase",
                color: "grey.500",
                mt: 2,
                mb: 2,
              }}>
              TECHNICAL BID REMINDERS
            </Typography>
            <Box
              sx={{
                backgroundColor: "white",
                border: "1px solid",
                borderColor: (theme) => theme.palette.bluegrey[500],
                padding: "16px",
                borderRadius: "4px",
              }}>
              <Box sx={{ mt: 2 }}>
                <SwitchLables
                  checked={includeReminderInterval}
                  handleChange={props.handleChange}
                  setChange={setIncludeReminderInterval}
                  name="includeReminderInterval"
                  label="Include Reminder Interval"></SwitchLables>
              </Box>
              <TextField
                name="remiderDaysTB"
                type="number"
                value={props.settings.remiderDaysTB}
                disabled={!props.settings.includeReminderInterval}
                error={!!props.settings.remiderDaysTBError}
                helperText={props.settings.remiderDaysTBError}
                onChange={props.handleChange}
                id="standard-full-width"
                // placeholder="1"
                fullWidth
                margin="normal"
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*", min: 1 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">DAY</InputAdornment>
                  ),
                }}
                onBlur={(event) => props.setRemiderDaysTBError(event)}
                onKeyPress={(event) => {
                  const keyValue = event.key;
                  if (keyValue === "-" || keyValue === "+") {
                    event.preventDefault();
                  }
                }}
              />
              <Box sx={{ mt: 2 }}>
                <SwitchLables
                  checked={enableHourlyReminderTB}
                  name={"enableHourlyReminderTB"}
                  setChange={setEnableHourlyReminderTB}
                  handleChange={props.handleChange}
                  label="Enable Hourly Reminders"></SwitchLables>
              </Box>
              <Box sx={{ mt: 2 }}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Hourly Reminders</InputLabel>
                  <Select
                    fullWidth
                    label="Hourly Reminder"
                    disabled={!enableHourlyReminderTB}
                    margin="normal"
                    name={"hourlyReminderValueTB"}
                    value={props.settings.hourlyReminderValueTB}
                    onChange={props.handleChange}>
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {[...Array(23).keys()].map((cv, i) => (
                      <MenuItem key={i} value={cv + 1}>
                        {cv + 1} Hour
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ mt: 1 }}>
                <SwitchLables
                  checked={sendSMSWithHourlyReminderTB}
                  setChange={setSendSMSWithHourlyReminderTB}
                  name={"sendSMSWithHourlyReminderTB"}
                  disabled={!enableHourlyReminderTB}
                  handleChange={props.handleChange}
                  label="Send SMS Hourly Reminders"></SwitchLables>
              </Box>
            </Box>
            {/* </Grid> */}
          </Grid>
        )}
    </React.Fragment>
  );
};

export default Reminders;
