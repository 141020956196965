import {
  Collapse,
  DialogActions,
  DialogContent,  
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import React, { Component } from "react";
import {
  Buttontext,
  Buttontextlight,
  Withoutborderbuttonicon,
} from "../Buttons";
import { CustomMailDialog } from "./style";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { OutlineTextfieldDropdown, OutlineTextfieldLabel } from "../TextField";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
const currencies = [
  {
    value: "USD",
    label: "$",
  },
  {
    value: "EUR",
    label: "€",
  },
  {
    value: "BTC",
    label: "฿",
  },
  {
    value: "JPY",
    label: "¥",
  },
];

class SendMailDialog extends Component {
  state = {
    expand: true,
  };
  handleExpand = () => {
    this.setState({
      expand: !this.state.expand,
    });
  };
  render() {
    return (
      <div>
        <Grid container>
          <Grid item lg={12}>
            <CustomMailDialog
              open={this.props.open}
              onClose={this.props.handleMailDialogClose}
              scroll="body"
              maxWidth={"md"}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogTitle>
                <Grid container>
                  <Grid item sm={6}>
                    <span>New Message</span>
                  </Grid>
                  <Grid
                    item
                    sm={6}
                    className="dialogcard_header_right text-right"
                  >
                    <div>
                      <Withoutborderbuttonicon
                        onClick={this.props.handleMailDialogClose}
                      >
                        <HighlightOffIcon />
                      </Withoutborderbuttonicon>
                    </div>
                  </Grid>
                </Grid>
              </DialogTitle>
              <DialogContent className="mt-10">
                
                  <Grid container>
                    <Grid item xs={12}>
                      <label className="custom_labels ">Start Date</label>
                      <OutlineTextfieldDropdown
                        className="mt-10"
                        options={currencies}
                        getOptionLabel={(option) => option.label}
                        // value={"USD"}
                        // onChange={(event, value) =>
                        //   modalData.handleProcesSelect(
                        //     "process_details",
                        //     "sub_department",
                        //     value
                        //   )
                        // }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Enter Sub Department 2000"
                            // error={errors.includes("sub_department")}
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} className="mt-20">
                      <OutlineTextfieldLabel
                        id="standard-full-width"
                        label="Subject"
                        style={{ margin: 8 }}
                        // placeholder="Subject"
                        // helperText="Full width!"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} className="mt-20">
                      hii
                    </Grid>
                    <Grid item xs={12} className="mt-20">
                      <div className="sender_details_collapse">
                        <Grid container>
                          <Grid item xs={10}>
                            <p>SENDER DETAILS</p>
                          </Grid>
                          <Grid item xs={2} className="text-right">
                            <IconButton
                              onClick={() => {
                                this.handleExpand();
                              }}
                              size="large">
                              {this.state.expand ? (
                                <ExpandLessIcon />
                              ) : (
                                <ExpandMoreIcon />
                                // <ExpandLessIcon />
                              )}
                            </IconButton>
                          </Grid>
                        </Grid>
                      </div>
                      <div className="sender_details_collapse open">
                        <div className="sender_details_info">
                          <Collapse
                            in={this.state.expand}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Grid container className="mt-10">
                              <Grid item lg={6} md={6} xs={12}>
                                <p>COMPANY NAME</p>
                                <span>Powered by VendX</span>
                              </Grid>
                              <Grid item lg={6} md={6} xs={12}>
                                <p>COMPANY NAME</p>
                                <span>Powered by VendX</span>
                              </Grid>
                            </Grid>
                            <Grid container className="mt-10">
                              <Grid item lg={6} md={6} xs={12}>
                                <p>EMAIL</p>
                                <span>rakesh.common@gmail.com</span>
                              </Grid>
                              <Grid item lg={6} md={6} xs={12}>
                                <p>PHONE</p>
                                <span>(O) (M)919000000000</span>
                              </Grid>
                            </Grid>
                            <Grid container className="mt-10">
                              <Grid item lg={6} md={6} xs={12}>
                                <p>ACCOUNT CODE</p>
                                <span>A001</span>
                              </Grid>
                              <Grid item lg={6} md={6} xs={12}>
                                <p>DESIGNATION</p>
                                <span>Vendx User</span>
                              </Grid>
                            </Grid>
                          </Collapse>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                
              </DialogContent>
              <DialogActions>
                <Buttontextlight
                  autoFocus
                  onClick={this.props.handleMailDialogClose}
                >
                  Cancel
                </Buttontextlight>
                <Buttontext
                  autoFocus
                  onClick={this.props.handleMailDialogClose}
                >
                  Send
                </Buttontext>
              </DialogActions>
            </CustomMailDialog>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default SendMailDialog;
