import React, { Component } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  DialogActions,
  Popover,
  List,
  ListItem,
  Divider,
  ListItemText,
  ListSubheader,
  Link,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { connect } from "react-redux";
import { DataGridPro, GridOverlay } from "@mui/x-data-grid-pro";
import { Customgrid } from "./style";
class Grouprfqitem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: "",
      anchorElName: null,
      anchorElCode: null,

    };
  }
  handlePopoverOpen = (event) => {
    this.setState({
      anchorElName: event.currentTarget,
    });
  };

  handlePopoverClose = () => {
    this.setState({ anchorElName: null });
  };
  handlePopoverOpenCode = (event) => {
    this.setState({
      anchorElCode: event.currentTarget,
    });
  };

  handlePopoverCloseCode = () => {
    this.setState({ anchorElCode: null });
  };

  handleChange(panelNo) {
    if (this.state.expanded === panelNo) {
      this.setState({ expanded: "" });
    } else {
      this.setState({ expanded: panelNo });
    }
  }

  render() {
    const { i } = this.props;
    const openPopoverName = Boolean(this.state.anchorElName);
    const openPopovercode = Boolean(this.state.anchorElCode);

    const idPlantName = openPopoverName ? "simple-popover" : undefined;
    const idPlantCode = openPopovercode ? "simple-popover" : undefined;

    return (
      <Box
        sx={{
          height: "auto",
          overflowY: "auto",
        }}
      >
        <Accordion
          expanded={this.state.expanded === "panel1"}
          // onChange={() => this.handleChange(`panel1`)}
          TransitionProps={{
            unmountOnExit: true,
          }}
          disableGutters
          sx={{
            ".MuiAccordionDetails-root": {
              p: 0,
            },
            ".MuiDataGrid-root": {
              border: "none",
            },
            marginBottom: "10px",
          }}
        >
          <AccordionSummary
            sx={{
              backgroundColor: (theme) => theme.palette.grey[600],
              color: (theme) => theme.palette.grey[100],
            }}
            expandIcon={
              <ExpandMoreIcon
                sx={{
                  color: (theme) => theme.palette.grey[100],
                }}
                onClick={() => this.handleChange(`panel1`)}
              />
            }
            aria-controls="panel1a-content"
          >
            <Customgrid
              container
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Grid item sx={{ width: "300px !important" }}>
                <Typography style={{ fontSize: 12, color: "#8091A7" }}>
                  RFQ NO
                </Typography>
                <Tooltip title={this.props.rfqSubmittedPrs[i]["rfqNo"]}>
                  <Typography noWrap>
                    {this.props.rfqSubmittedPrs[i]["rfqNo"]}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid item>
                <Typography style={{ fontSize: 12, color: "#8091A7" }}>
                  CATAGORY NAME
                </Typography>
                <Typography noWrap>{this.props.obj?.category.name}</Typography>
              </Grid>
              <Grid item>
                <Typography style={{ fontSize: "12px", color: "#8091A7" }}>
                  CATAGORY CODE
                </Typography>
                <Typography noWrap>{this.props.obj?.category.code}</Typography>
              </Grid>
              {console.log('this.props.rfqSubmittedPrs', this.props.rfqSubmittedPrs[i].plant)}
              {Array.isArray(this.props.rfqSubmittedPrs[i].plant.name) &&
              this.props.rfqSubmittedPrs[i].plant.name.length > 1 ? (
                <Grid item>
                  <Typography style={{ fontSize: 12, color: "#8091A7" }}>
                    DESTINATION NAME
                  </Typography>
                  <Link
                    aria-describedby={idPlantName}
                    onClick={this.handlePopoverOpen}
                    noWrap
                    sx={{
                      textDecorationColor:"var(--color-white)",
                      color:"var(--color-white)"
                    }}
                  >
                    {" "}
                    View +{this.props.rfqSubmittedPrs[i].plant.name.length}
                  </Link>
                  <Popover
                    id={idPlantName}
                    open={openPopoverName}
                    anchorEl={this.state.anchorElName}
                    onClose={this.handlePopoverClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <List
                      sx={{
                        width: "100%",
                        width: "400px",
                        bgcolor: "background.paper",
                        paddingBottom:"0",
                        "& .MuiListItem-root:nth-child(2)": {
                          paddingTop: "0"
                        }
                      }}
                      subheader={
                        <ListSubheader
                          component="li"   
                          sx={{
                            '&.MuiListSubheader-root':{
                              lineHeight:'36px',
                              color:' var(--grey-700)',
                              fontWeight: '400'
                            }
                          }}                       
                        >
                          View Destination
                        </ListSubheader>
                      }
                    >
                      {this.props.rfqSubmittedPrs[i].plant.name.map((plantname, index) => {
                        return (
                          <>
                            <ListItem alignItems="flex-start">
                              <ListItemText
                                sx={{
                                  '&.MuiListItemText-root':{
                                    m:0
                                  }
                                }}
                                primary={plantname}
                                secondary={
                                  <React.Fragment>
                                    <Typography
                                      sx={{ display: "inline" }}
                                      component="span"
                                      variant="caption"
                                      color="primary"
                                    >
                                      {this.props.rfqSubmittedPrs[i].plant.code[index]}
                                    </Typography>
                                  </React.Fragment>
                                }
                              />
                            </ListItem>
                            <Divider variant="fullWidth" />
                          </>
                        );
                      })}
                    </List>
                  </Popover>
                </Grid>
              ) : (
                <Grid item>
                  <Typography style={{ fontSize: 12, color: "#8091A7" }}>
                    DESTINATION NAME
                  </Typography>
                  <Typography noWrap> {this.props.obj?.plant.name}</Typography>
                </Grid>
              )}
              {Array.isArray(this.props.rfqSubmittedPrs[i].plant.code) &&
              this.props.rfqSubmittedPrs[i].plant.code.length > 1 ? (
                <Grid item>
                  <Typography style={{ fontSize: 12, color: "#8091A7" }}>
                    DESTINATION CODE
                  </Typography>
                  <Link
                    aria-describedby={idPlantCode}
                    onClick={this.handlePopoverOpenCode}
                    noWrap                    
                    sx={{
                      textDecorationColor:"var(--color-white)",
                      color:"var(--color-white)"
                    }}
                  >
                    {" "}
                    View +{this.props.rfqSubmittedPrs[i].plant.code.length}
                  </Link>
                  <Popover
                    id={idPlantCode}
                    open={openPopovercode}
                    anchorEl={this.state.anchorElCode}
                    onClose={this.handlePopoverCloseCode}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <List
                      sx={{
                        width: "100%",
                        width: "400px",
                        bgcolor: "background.paper",
                        paddingBottom:"0",
                        "& .MuiListItem-root:nth-child(2)": {
                          paddingTop: "0"
                        }
                      }}
                      subheader={
                        <ListSubheader
                          component="li"   
                          sx={{
                            '&.MuiListSubheader-root':{
                              lineHeight:'36px',
                              color:' var(--grey-700)',
                              fontWeight: '400'
                            }
                          }}                       
                        >
                          View Destination
                        </ListSubheader>
                      }
                    >
                      {this.props.rfqSubmittedPrs[i].plant.name.map((plantname, index) => {
                        return (
                          <>
                            <ListItem alignItems="flex-start">
                              <ListItemText
                                sx={{
                                  '&.MuiListItemText-root':{
                                    m:0
                                  }
                                }}
                                primary={plantname}
                                secondary={
                                  <React.Fragment>
                                    <Typography
                                      sx={{ display: "inline" }}
                                      component="span"
                                      variant="caption"
                                      color="primary"
                                    >
                                      {this.props.rfqSubmittedPrs[i].plant.code[index]}
                                    </Typography>
                                  </React.Fragment>
                                }
                              />
                            </ListItem>
                            <Divider variant="fullWidth" />
                          </>
                        );
                      })}
                    </List>
                  </Popover>
                </Grid>
              ) : (
                <Grid item>
                  <Typography style={{ fontSize: 12, color: "#8091A7" }}>
                    DESTINATION CODE
                  </Typography>
                  <Typography noWrap> {this.props.obj?.plant.code}</Typography>
                </Grid>
              )}

              <Grid item>
                <Typography style={{ fontSize: 12, color: "#8091A7" }}>
                  RFQ VALIDITY
                </Typography>
                <Typography noWrap>
                  {this.props.rfqSubmittedPrs[i]["valid_date"]}
                </Typography>
              </Grid>
            </Customgrid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Grid
                item
                lg={12}
                md={12}
                sm={10}
                xs={10}
                sx={{ height: "200px" }}
              >
                <DataGridPro
                  sx={{
                    borderLeft: 0,
                    borderRight: 0,
                    borderRadius: 0,
                    "& .Mui-disabled": {
                      visibility: "hidden",
                    },
                    ".MuiDataGrid-columnHeader:first-of-type": {
                      padding: 0,
                    },
                    "& .MuiDataGrid-columnHeaderTitleContainer, .MuiDataGrid-cell:first-of-type":
                      {
                        padding: "0 6px",
                      },
                  }}
                  columns={this.props.columns}
                  rows={this.props.rfqSubmittedPrs[i]["pr_line_items"]}
                  slots={{
                    noRowsOverlay: () => {
                      return (
                        <GridOverlay>
                          <Typography>No item(s) found</Typography>
                        </GridOverlay>
                      );
                    },
                  }}
                  rowHeight={48}
                  disableColumnSelector
                  disableColumnReorder
                  disableColumnPinning
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  }
}
const mapStateToProps = (state) => ({
  rfqSubmittedPrs: state.prrfqreducer.rfqSubmittedPrs,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Grouprfqitem);
