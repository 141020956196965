// vendx theme version 2.0

import { createTheme } from "@mui/material/styles";
//import { green, purple } from "./colors";

export const white = "#FFFFFF";
const text = {
  primary: "#50657e", //"#5A6778"
  secondary: "#8091a7",
  disabled: "rgba(0, 0, 0, 0.38)",
  hint: "rgba(0, 0, 0, 0.50)",
};
const primary = {
  light: "#3a88f2",
  main: "#096bef",
  dark: "#064aa7",
  50: "#e1edfd",
  100: "#b5d3fa",
  200: "#84b5f7",
  300: "#5397f4",
  400: "#2e81f1",
  500: "#096bef",
  600: "#0863ed",
  700: "#0658eb",
  800: "#054ee8",
  900: "#023ce4",
  A100: "#ffffff",
  A200: "#d8e0ff",
  A400: "#a5b7ff",
  A700: "#8ba2ff",
  contrastDefaultColor: "main",
};
const secondary = {
  light: "#405267",
  main: "#1f2b3a",
  dark: "#151e28",
  50: "#e8eef9",
  100: "#c9d5e4",
  200: "#abb9cb",
  300: "#8c9cb3",
  400: "#7487a0",
  500: "#5d738f",
  600: "#50657e",
  700: "#405267",
  800: "#313f51",
  900: "#1f2b3a",
};
export const success = {
  light: "#91E2AF",
  main: "#22C55E",
  dark: "#14B042",
  50: "#E4F8EC",
  100: "#BDEECF",
  200: "#91E2AF",
  300: "#64D68E",
  400: "#43CE76",
  500: "#22C55E",
  600: "#1EBF56",
  700: "#19B84C",
  800: "#14B042",
  900: "#0CA331",
  A100: "#D1FFDA",
  A200: "#9EFFB2",
  A400: "#6BFF89",
  A700: "#51FF75",
  contrastDefaultColor: "main",
};
const info = {
  light: "#33cfe0",
  main: "#00c3d9",
  dark: "#008897",
  50: "#e0f8fa",
  100: "#b3edf4",
  200: "#80e1ec",
  300: "#4dd5e4",
  400: "#26ccdf",
  500: "#00c3d9",
  600: "#00bdd5",
  700: "#00b5cf",
  800: "#00aeca",
  900: "#00a1c0",
  A100: "#e9fbff",
  A200: "#b6f1ff",
  A400: "#83e7ff",
  A700: "#69e3ff",
};
export const warning = {
  main: "#f4bd0e",
  50: "#fdf8e1",
  100: "#fbebb3",
  200: "#f9de82",
  300: "#f6d350",
  400: "#f5c82a",
  500: "#f4be0e",
  600: "#f4b104",
  700: "#f49e00",
  800: "#f48e00",
  900: "#f47000",
};
const error = {
  main: "#ff5547",
  50: "#ffedf0",
  100: "#ffd2d7",
  200: "#f3a2a3",
  300: "#ec7f7f",
  400: "#f8625e",
  500: "#ff5647",
  600: "#ef4d45",
  700: "#dd433e",
  800: "#d03d37",
  900: "#c0342c",
  contrastDefaultColor: "main",
};
const grey = {
  0: "#FFFFFF",
  50: "#F6F8FB",
  100: "#ebeef2 ",
  200: "#e5e9f2",
  300: "#dbdfea",
  400: "#b7c2d0",
  500: "#8091a7",
  600: "#3c4d62",
  700: "#344357",
  800: "#2b3748",
  900: "#1f2b3a",
};
const bluegrey = {
  0: "#FFFFFF",
  100: "#F2FBFC",
  200: "#E5F5FA",
  300: "#D2E9F1",
  400: "#C0D8E4",
  500: "#a6c1d3",
  600: "#7999B5",
  700: "#537497",
  800: "#34527A",
  900: "#1F3965",
};

let theme = createTheme();
theme = createTheme(theme, {
  palette: {
    mode: "light",
    background: {
      default: "#EBEEF2",
    },
    text,
    white,
    primary,
    secondary,
    success,
    info,
    warning,
    error,
    themeBorder: `${bluegrey[500]}`,
    grey: {
      0: "#FFFFFF",
      50: "#F6F8FB",
      100: "#ebeef2 ",
      200: "#e5e9f2",
      300: "#dbdfea",
      400: "#b7c2d0",
      500: "#8091a7",
      600: "#3c4d62",
      700: "#344357",
      800: "#2b3748",
      900: "#1f2b3a",
    },
    bluegrey: {
      0: "#FFFFFF",
      100: "#F2FBFC",
      200: "#E5F5FA",
      300: "#D2E9F1",
      400: "#C0D8E4",
      500: "#a6c1d3",
      600: "#7999B5",
      700: "#537497",
      800: "#34527A",
      900: "#1F3965",
    },
    divider: `${bluegrey[500]}`,
    // shadows: {
    //   0: 'none',
    //   1: '0px 16px 12px 0px rgba(112, 144, 176, 0.25)',
    //   2: '0px 16px 12px 0px rgba(112, 144, 176, 0.25)',
    // }
  },
  typography: {
    body1: {
      fontSize: "1rem",
      [theme.breakpoints.down("xl")]: {
        fontSize: "0.875rem",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: "0.875rem",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "0.875rem",
      },
    },
    vendorsubtext: {
      fontSize: "0.875rem",
      [theme.breakpoints.down("xl")]: {
        fontSize: "0.75rem",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "0.875rem",
      },
      fontWeight: 400,
      lineHeight: 1,
    },
  },
  components: {
    MuiPaper: {
      // styleOverrides: {
      //   root: {
      //     boxShadow: '0px 16px 12px 0px rgba(112, 144, 176, 0.25)',
      //   }
      // }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          //borderColor: `${bluegrey[500]}`,
        },
      },
    },
    MuiIconButton: {
      variants: [
        {
          props: { variant: "outlined", color: "default" },
          style: {
            border: "solid 1px",
            borderColor: `${bluegrey[500]}`,
          },
        },
      ],
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
      },
      variants: [
        //Icon Contained
        {
          props: { variant: "icon-contained", color: "default" },
          style: {
            color: `${grey[600]}`,
            backgroundColor: `${bluegrey[500]}`,
            border: "solid 1px",
            borderColor: `${bluegrey[500]}`,
          },
        },
        {
          props: { variant: "icon-contained", color: "primary" },
          style: {
            color: `${white}`,
            backgroundColor: `${primary.main}`,
            // border: 'solid 1px',
            // borderColor: `${primary.main}`,
            "&:hover": {
              backgroundColor: `${primary.dark}`,
            },
          },
        },
        {
          props: { variant: "icon-contained", size: "small" },
          style: {
            minWidth: 30,
            height: 30,
            padding: 0,
          },
        },
        {
          props: { variant: "icon-contained", size: "medium" },
          style: {
            minWidth: 38,
            height: 38,
            padding: 0,
          },
        },
        {
          props: { variant: "icon-contained", size: "large" },
          style: {
            minWidth: 42,
            height: 42,
            padding: 0,
          },
        },

        // Icon Outline
        {
          props: { variant: "icon-outline", color: "default" },
          style: {
            color: `${grey[600]}`,
            border: `1px solid ${bluegrey[500]}`,
          },
        },
        {
          props: { variant: "icon-outline", color: "primary" },
          style: {
            color: `${primary.main}`,
            border: `1px solid ${primary.main}`,
          },
        },
        {
          props: { variant: "icon-outline", size: "small" },
          style: {
            minWidth: 30,
            height: 30,
            padding: 0,
          },
        },
        {
          props: { variant: "icon-outline", size: "medium" },
          style: {
            minWidth: 38,
            height: 38,
            padding: 0,
          },
        },
        {
          props: { variant: "icon-outline", size: "large" },
          style: {
            minWidth: 42,
            height: 42,
            padding: 0,
          },
        },
      ],
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fieldset: {
            borderColor: `${bluegrey[500]}`,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: `${bluegrey[500]}`,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(54, 74, 99, 0.5)",
          },
          "& .MuiDialogTitle-root": {
            display: "flex",
            alignItems: "center",
            fontSize: "18px",
            color: "#1F2B3A",
            padding: "22px",
          },
          "& .MuiDialogContentText-root": {
            color: "#526484",
          },
          "& .dialog-title-action": {
            marginLeft: "auto",
          },
          "& .MuiDialogActions-root": {
            padding: "16px",
          },
          "& .MuiDialogActions-root>:not(:first-of-type)": {
            marginLeft: "16px",
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(54, 74, 99, 0.5)",
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          // fontSize: '0.875rem',
          // [theme.breakpoints.down('xl')]: {
          //   fontSize: '0.875rem',
          // },
          // [theme.breakpoints.down('lg')]: {
          //   fontSize: '0.875rem',
          // },
          // [theme.breakpoints.down('md')]: {
          //   fontSize: '1rem',
          // },
          borderColor: `${bluegrey[500]}`,
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: `${grey[50]}`,
            borderColor: `${bluegrey[500]}`,
            textTransform: "uppercase",
            fontSize: "12px",
            fontWeight: 500,
            height: "45px !important",
          },
          "& .MuiDataGrid-cell": {
            borderColor: `${bluegrey[500]}`,
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          "& .MuiTableHead-root": {
            backgroundColor: `${grey[50]}`,
          },
          "& .MuiTableHead-root th": {
            fontSize: "14px",
            textTransform: "uppercase",
            borderColor: `${bluegrey[500]}`,
          },
          "& .MuiTableRow-root td": {
            borderColor: `${bluegrey[500]}`,
          },
          //  '& .MuiTableCell-root': {
          //     fontSize: '1rem',
          //     [theme.breakpoints.down('xl')]: {
          //       fontSize: '0.875rem',
          //     },
          //     [theme.breakpoints.down('lg')]: {
          //       fontSize: '0.875rem',
          //     },
          //     [theme.breakpoints.down('md')]: {
          //       fontSize: '1rem',
          //     },
          //  }
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          "& .MuiMenuItem-root": {
            "&:hover": {
              backgroundColor: `${grey[50]}`,
            },
            "& .MuiSvgIcon-root": {
              fontSize: 24,
              marginRight: 16,
              color: `${grey[600]}`,
            },
          },
          // '& .MuiMenuItem-root.Mui-selected': {
          //   background: `${primary.main}`,
          //   color: `${grey[0]}`,
          // }
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: "60px",
          height: "36px",
          padding: "5px",
          border: "none",

          "& .MuiSwitch-thumb": {
            backgroundColor: `${bluegrey[500]}`,
            boxShadow: "none",
            width: "18px",
            height: "18px",
          },
          "& .MuiSwitch-track": {
            backgroundColor: "transparent",
            borderRadius: "50px",
            border: "1px solid",
            borderColor: `${bluegrey[500]}`,
            opacity: "1",
          },
          "& .MuiSwitch-switchBase.Mui-checked": {
            transform: "translateX(25px)",
          },
          "& .MuiSwitch-switchBase": {
            "&.Mui-checked": {
              "& .MuiSwitch-thumb": {
                backgroundColor: `${grey[0]}`,
              },
              "& + .MuiSwitch-track": {
                backgroundColor: `${primary.main}`,
                borderColor: `${primary.main}`,
                border: 0,
                opacity: 1,
              },
              // '&.Mui-disabled + .MuiSwitch-track': {
              //   opacity: 0.5,
              // },
            },
            "&.Mui-disabled .MuiSwitch-thumb": {
              opacity: "0.5",
            },
            "&.Mui-disabled + .MuiSwitch-track": {
              opacity: "0.5",
              cursor: "not-allowed",
            },
          },
          "&.MuiSwitch-sizeSmall": {
            width: "45px",
            height: "31px",
          },
          "&.MuiSwitch-sizeSmall .MuiSwitch-switchBase.Mui-checked": {
            transform: "translateX(14px)",
          },
          "&.MuiSwitch-sizeSmall .MuiSwitch-thumb": {
            width: "15px",
            height: "15px",
          },
          "&.MuiSwitch-sizeSmall .MuiSwitch-switchBase": {
            padding: "8px",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {},
      variants: [
        {
          props: { variant: "filled", size: "xsmall" },
          style: {
            "& .MuiChip-label": {
              paddingLeft: 4,
              paddingRight: 4,
            },
            lineHeight: "1.50",
            borderRadius: "3px",
            height: "20px",
          },
        },
        {
          props: { variant: "outlined", size: "xsmall" },
          style: {
            "& .MuiChip-label": {
              paddingLeft: 4,
              paddingRight: 4,
            },
            lineHeight: "1.50",
            borderRadius: "3px",
            height: "20px",
          },
        },
        {
          props: { variant: "soft", color: "default" },
          style: {
            color: `${grey[800]}`,
            backgroundColor: `${grey[100]}`,
            borderRadius: "4px",
            fontWeight: "500",
            '&.MuiChip-soft.MuiButtonBase-root:hover':{
              backgroundColor: `${grey[300]}`,
            },
            '&.MuiChip-root .MuiChip-label':{
              fontWeight: "500",
            }
          },
        },
        {
          props: { variant: "soft", color: "primary" },
          style: {
            color: `${primary.main}`,
            backgroundColor: `${primary[50]}`,
            borderRadius: "4px",
            fontWeight: "500",
            '&.MuiChip-soft.MuiButtonBase-root:hover':{
              backgroundColor: `${primary[100]}`,
            },
            '&.MuiChip-root .MuiChip-label':{
              fontWeight: "500",
            }
          },
        },
        {
          props: { variant: "soft", color: "success" },
          style: {
            color: `${success[900]}`,
            backgroundColor: `${success[50]}`,
            borderRadius: "4px",
            fontWeight: "500",
            '&.MuiChip-soft.MuiButtonBase-root:hover':{
              backgroundColor: `${success[100]}`,
            },
            '&.MuiChip-root .MuiChip-label':{
              fontWeight: "500",
            }
          },
        },
        {
          props: { variant: "soft", color: "warning" },
          style: {
            color: `${warning[900]}`,
            backgroundColor: `${warning[50]}`,
            borderRadius: "4px",
            fontWeight: "500",
            '&.MuiChip-soft.MuiButtonBase-root:hover':{
              backgroundColor: `${warning[100]}`,
            },
            '&.MuiChip-root .MuiChip-label':{
              fontWeight: "500",
            }
          },
        },
        {
          props: { variant: "soft", color: "info" },
          style: {
            color: `${info[900]}`,
            backgroundColor: `${info[50]}`,
            borderRadius: "4px",
            fontWeight: "500",
            '&.MuiChip-soft.MuiButtonBase-root:hover':{
              backgroundColor: `${info[100]}`,
            },
            '&.MuiChip-root .MuiChip-label':{
              fontWeight: "500",
            }
          },
        },
        {
          props: { variant: "soft", color: "error" },
          style: {
            color: `${error.main}`,
            backgroundColor: `${error[50]}`,
            borderRadius: "4px",
            fontWeight: "500",
            '&.MuiChip-soft.MuiButtonBase-root:hover':{
              backgroundColor: `${error[100]}`,
            },
            '&.MuiChip-root .MuiChip-label':{
              fontWeight: "500",
            }
          },
        },
        {
          props: { variant: "soft", size: "xsmall" },
          style: {
            "& .MuiChip-label": {
              paddingLeft: 4,
              paddingRight: 4,
            },
            borderRadius: "3px",
            height: "20px",
          },
        },
        {
          props: { variant: "soft-outlined" },
          style: {
            color: `${text.primary}`,
            backgroundColor: `${grey[100]}`,
            borderRadius: "4px",
            border: "solid 1px",
            borderColor: `${bluegrey[500]}`,
            '&.MuiChip-soft-outlined.MuiButtonBase-root:hover':{
              backgroundColor: `${grey[50]}`,
            },
            '&.MuiChip-root .MuiChip-label':{
              fontWeight: "500",
            }
          },
        },
        {
          props: { variant: "soft-outlined", color: "primary" },
          style: {
            color: `${primary.main}`,
            backgroundColor: `${primary[50]}`,
            borderRadius: "4px",
            border: "solid 1px",
            borderColor: `${primary.main}`,
            '&.MuiChip-soft-outlined.MuiButtonBase-root:hover':{
              backgroundColor: `${primary[50]}`,
            },
            '&.MuiChip-root .MuiChip-label':{
              fontWeight: "500",
            }
          },
        },
        {
          props: { variant: "soft-outlined", color: "secondary" },
          style: {
            color: `${primary.main}`,
            backgroundColor: `${primary[50]}`,
            borderRadius: "4px",
            border: "solid 1px",
            borderColor: `${primary.main}`,
            '&.MuiChip-soft-outlined.MuiButtonBase-root:hover':{
              backgroundColor: `${primary[50]}`,
            },
            '&.MuiChip-root .MuiChip-label':{
              fontWeight: "500",
            }
          },
        },
        {
          props: { variant: "soft-outlined", color: "success" },
          style: {
            color: `${success[900]}`,
            backgroundColor: `${success[50]}`,
            borderRadius: "4px",
            border: "solid 1px",
            borderColor: `${success.main}`,
            '&.MuiChip-soft-outlined.MuiButtonBase-root:hover':{
              backgroundColor: `${success[50]}`,
            },
            '&.MuiChip-root .MuiChip-label':{
              fontWeight: "500",
            }
          },
        },
        {
          props: { variant: "soft-outlined", color: "info" },
          style: {
            color: `${info.main}`,
            backgroundColor: `${info[50]}`,
            borderRadius: "4px",
            border: "solid 1px",
            borderColor: `${info.main}`,
            '&.MuiChip-soft-outlined.MuiButtonBase-root:hover':{
              backgroundColor: `${info[50]}`,
            },
            '&.MuiChip-root .MuiChip-label':{
              fontWeight: "500",
            }
          },
        },
        {
          props: { variant: "soft-outlined", color: "warning" },
          style: {
            color: `${warning[800]}`,
            backgroundColor: `${warning[50]}`,
            borderRadius: "4px",
            border: "solid 1px",
            borderColor: `${warning.main}`,
            '&.MuiChip-soft-outlined.MuiButtonBase-root:hover':{
              backgroundColor: `${warning[50]}`,
            },
            '&.MuiChip-root .MuiChip-label':{
              fontWeight: "500",
            }
          },
        },
        {
          props: { variant: "soft-outlined", color: "error" },
          style: {
            color: `${error.main}`,
            backgroundColor: `${error[50]}`,
            borderRadius: "4px",
            border: "solid 1px",
            borderColor: `${error.main}`,
            '&.MuiChip-soft-outlined.MuiButtonBase-root:hover':{
              backgroundColor: `${error[50]}`,
            },
            '&.MuiChip-root .MuiChip-label':{
              fontWeight: "500",
            }
          },
        },
        {
          props: { variant: "soft-outlined", size: "xsmall" },
          style: {
            "& .MuiChip-label": {
              paddingLeft: 4,
              paddingRight: 4,
            },
            borderRadius: "3px",
            height: "20px",
            lineHeight: "1.50",
            "& .MuiChip-iconXsmall": {
              marginRight: 0,
              fontSize: theme.typography.pxToRem(14),
            },
          },
        },
      ],
    },
  },
});

export default theme;
