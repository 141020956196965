import React from "react";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {  Box, IconButton, Button, Dialog, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
export default function ConfirmAlert({
  open,
  handleClose,
  handleSubmit,
  msg,
  title,
  handleDontShow=undefined
}) {
  return (
    <div>
      <Dialog open={open}
          scroll="body"
          maxWidth={"sm"}> 
        <DialogTitle>
          {title}
          <Box className="dialog-title-action">
            <IconButton size="small" onClick={handleClose} sx={{ color: (theme) => theme.palette.bluegrey[500], }}>
              <CloseIcon />
            </IconButton>
          </Box>         
        </DialogTitle>
        <DialogContent>          
            {typeof(msg) === "object"
              ? msg?.map((line, index) => <Typography key={index}>{line}</Typography>)
              : <Typography>{msg}</Typography>          
            }
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} variant="outlined" color="primary">
              No
          </Button>
          <Button onClick={() => handleSubmit()} variant="contained" autoFocus color="primary">
            Yes
          </Button>
          {handleDontShow && 
          <Button autoFocus onClick={handleDontShow} variant="outlined" color="primary">
              Don't show again
          </Button>}        
        </DialogActions>
      </Dialog>
    </div>
  );
}
