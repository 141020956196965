import { SET_RFQ_LIST, SET_RFQ_LIST_ERROR } from "../constants/type";

const initialState = {
  rfqListInfo: [],
  rfqListInfoError: "",
};

const rfqListReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_RFQ_LIST:
      return { ...state, rfqListInfo: [...state.rfqListInfo, ...payload], rfqListInfoError: "" };
    case SET_RFQ_LIST_ERROR:
      return { ...state, rfqListInfoError: payload };
    default:
      return state;
  }
};

export default rfqListReducer;
