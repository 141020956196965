import React, { Component } from "react";
import {
  Avatar,
  Typography,
  Tooltip,
  InputBase,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Box } from "@mui/system";
import { connect } from "react-redux";
import {
  DataGridPro,
  GridActionsCellItem,
  GridOverlay,
} from "@mui/x-data-grid-pro";
import {
  getItemizedVendors,
  filterItemizedVendors,
} from "../../../redux/actions/vendorActions";
import {
  getFilteredItemizedVendors,
  getLinkedItemizedVendors,
  searchItemizedVendorsByNameOrCode,
} from "../../../config/apiUrl";
import axios from "axios";
import { getFilterOperators } from "../../Utils/datagridHelper";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { debounce } from "lodash";
import noSearchimg from "../../../assets/images/no-search.svg";
import { setSelectedVendors } from "../../../redux/actions/vendorActions";
import { getVendorGMPFlag } from "../../Vendors/VendorChip";

function QuickSearchToolbar(props) {
  return (
    <Box
      borderTop={1}
      sx={{
        borderBottom: "1px solid",
        borderColor: (theme) => theme.palette.bluegrey[500],
      }}>
      <InputBase
        sx={{
          backgroundColor: (theme) => theme.palette.grey[50],
          width: "100%",
          height: "46px",
          borderRadius: "0",
          padding: "0 16px",
          "& .MuiInputAdornment-positionStart .MuiSvgIcon-root": {
            color: (theme) => theme.palette.grey[600],
          },
          "& .search-clear svg": {
            width: "18px",
            height: "18px",
            color: (theme) => theme.palette.grey[500],
          },
        }}
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon fontSize="small" />
          </InputAdornment>
        }
        endAdornment={
          <IconButton
            className="search-clear"
            title="Clear"
            aria-label="Clear"
            size="small"
            style={{ visibility: props.value ? "visible" : "hidden" }}
            onClick={props.clearSearch}>
            <ClearIcon />
          </IconButton>
        }
      />
    </Box>
  );
}

class SidebarVendors extends Component {
  state = {
    rows: [],
    loading: false,
    total: 0,
    filteredTotal: 0,
    filteredRows: [],
    filterLoading: false,
    vendorPage: 1,
    vendorLastPage: 1,
    isFilterActive: false,
    filterDataCurrentPage: 1,
    filterDataLastPage: 1,
    vendor_name: "",
    vendor_name_operator: "",
    vendor_code: "",
    vendor_code_operator: "",
    industry_type: "",
    industry_type_operator: "",
    country: "",
    country_operator: "",
    email: "",
    email_operator: "",
    state: "",
    state_operator: "",
    contact_person: "",
    contact_person_operator: "",
    phone_no: "",
    phone_no_operator: "",
    address: "",
    address_operator: "",
    filterModel: {
      items: [],
    },
    searchText: "",
    vendorSearchDebounceDelay: false,
    searchedRows: [],
    searchedDataCurrentPage: 1,
    searchedDataLastPage: 1,
    searchededTotal: 0,
  };

  componentDidMount() {
    this.fetchItemizedVendors();
  }

  fetchItemizedVendors = () => {
    this.setState({
      loading: true,
    });

    let plant = undefined;

    if (
      this.props.prlineitems &&
      this.props.accountModulePermission[
        "Plant Specific Categroy Item Vendor Linking"
      ].module_activated === "Y"
    ) {
      plant = [];
      let prlineitems = this.props.prlineitems;
      prlineitems[this.props.item?.id]?.forEach((lineItemData) => {
        if (
          this.props.selectedCategory?.old_id ===
          lineItemData?.category?.category_old_id
        ) {
            if(!plant.includes(lineItemData.plant)){
              plant.push(lineItemData.plant);
            }
          }
        });
      plant = plant.join(",");
    }

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;
    const url = getLinkedItemizedVendors(
      this.props.item?.id,
      this.props.selectedCategory?.id,
      this.state.vendorPage,
      plant
    );

    axios
      .get(url)
      .then((res) => {
        let finalRows = [...this.state.rows, ...res.data.data];
        let jsonObject = finalRows.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        let uniqueRows = Array.from(uniqueSet).map(JSON.parse);

        this.setState({
          loading: false,
          rows: uniqueRows,
          vendorLastPage: res.data.meta?.last_page,
          ...(!this.state.total && { total: res.data.meta.total }),
        });
        // this.props.setSelectedVendors(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
      });
  };

  filterItemizedVendors = () => {
    this.setState({
      filterLoading: true,
    });

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;

    const url = getFilteredItemizedVendors(
      this.props.item.id,
      this.props.selectedCategory.id,
      this.state.vendor_name.trim(),
      this.state.vendor_name_operator,
      this.state.vendor_code.trim(),
      this.state.vendor_code_operator,
      this.state.country.trim(),
      this.state.country_operator,
      this.state.industry_type.trim(),
      this.state.industry_type_operator,
      this.state.email.trim(),
      this.state.email_operator,
      this.state.state.trim(),
      this.state.state_operator,
      this.state.contact_person.trim(),
      this.state.contact_person_operator,
      this.state.phone_no.trim(),
      this.state.phone_no_operator,
      this.state.address.trim(),
      this.state.address_operator,
      this.state.filterDataCurrentPage
    );

    axios
      .get(url)
      .then((res) => {
        if (this.state.filterDataCurrentPage === 1) {
          this.setState({
            filterLoading: false,
            filteredRows: res.data.data,
            filterDataCurrentPage: res.data.meta?.last_page,
            filteredTotal: res.data.meta.total,
          });
        } else {
          let finalRows = [...this.state.filteredRows, ...res.data.data];
          let jsonObject = finalRows.map(JSON.stringify);
          let uniqueSet = new Set(jsonObject);
          let uniqueRows = Array.from(uniqueSet).map(JSON.parse);

          this.setState({
            filterLoading: false,
            filteredRows: uniqueRows,
            filterDataCurrentPage: res.data.meta?.last_page,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          filterLoading: false,
        });
      });
  };

  //for vendors pagination
  handlePageChange = () => {
    if (this.state.isFilterActive) {
      if (this.state.filterDataCurrentPage !== this.state.filterDataLastPage) {
        this.setState(
          {
            filterDataCurrentPage: this.state.filterDataCurrentPage + 1,
          },
          () => {
            this.filterItemizedVendors();
          }
        );
      }
    } else {
      if (this.state.searchText.trim() === "") {
        if (this.state.vendorPage !== this.state.vendorLastPage) {
          this.setState(
            {
              vendorPage: this.state.vendorPage + 1,
            },
            () => {
              this.fetchItemizedVendors();
            }
          );
        }
      } else {
        if (
          this.state.searchedDataCurrentPage !== this.state.searchedDataLastPage
        ) {
          this.setState(
            {
              searchedDataCurrentPage: this.state.searchedDataCurrentPage + 1,
            },
            () => {
              this.searchVendors();
            }
          );
        }
      }
    }
  };

  handleFilterModelChange = (filterModel) => {
    let vendor_name = "";
    let vendor_name_operator = "";
    let vendor_code = "";
    let vendor_code_operator = "";
    let country = "";
    let country_operator = "";
    let industry_type = "";
    let industry_type_operator = "";
    let email = "";
    let email_operator = "";
    let state = "";
    let state_operator = "";
    let contact_person = "";
    let contact_person_operator = "";
    let phone_no = "";
    let phone_no_operator = "";
    let address = "";
    let address_operator = "";

    this.setState({
      filterModel,
    });
    filterModel.items.forEach((filter) => {
      if (filter.value) {
        if (filter.field === "vendor_name") {
          vendor_name = filter.value;
          vendor_name_operator = filter.operator;
        } else if (filter.field === "vendor_code") {
          vendor_code = filter.value;
          vendor_code_operator = filter.operator;
        } else if (filter.field === "country") {
          country = filter.value;
          country_operator = filter.operator;
        } else if (filter.field === "industry_type") {
          industry_type = filter.value;
          industry_type_operator = filter.operator;
        } else if (filter.field === "email") {
          email = filter.value;
          email_operator = filter.operator;
        } else if (filter.field === "state") {
          state = filter.value;
          state_operator = filter.operator;
        } else if (filter.field === "contact_person") {
          contact_person = filter.value;
          contact_person_operator = filter.operator;
        } else if (filter.field === "phone_no") {
          phone_no = filter.value;
          phone_no_operator = filter.operator;
        } else if (filter.field === "address") {
          address = filter.value;
          address_operator = filter.operator;
        }
      }
    });
    //api call
    if (
      vendor_name ||
      vendor_code ||
      country ||
      industry_type ||
      email ||
      state ||
      contact_person ||
      phone_no ||
      address
    ) {
      // console.log("filter api calling");
      this.setState(
        {
          isFilterActive: true,
          vendor_name,
          vendor_name_operator,
          vendor_code,
          vendor_code_operator,
          country,
          country_operator,
          industry_type,
          industry_type_operator,
          email,
          email_operator,
          state,
          state_operator,
          contact_person,
          contact_person_operator,
          phone_no,
          phone_no_operator,
          address,
          address_operator,
          filterDataCurrentPage: 1,
          filterDataLastPage: 1,
          filteredRows: [],
          filteredTotal: 0,
        },
        () => {
          this.filterItemizedVendors();
        }
      );
    } else {
      this.setState({
        isFilterActive: false,
        vendor_name,
        vendor_code,
        country,
        industry_type,
        filterDataCurrentPage: 0,
        filterDataLastPage: 1,
        filteredRows: [],
      });
    }
  };

  getVendorLocation = (country) => {
    if (this.props.accountlevelInfo?.country === country) {
      return "L";
    } else return "I";
  };

  requestSearch = (searchValue) => {
    this.setState({
      loading: true,
      searchText: searchValue,
    });

    if (searchValue.trim() !== "") {
      this.setState(
        {
          isFilterActive: false,
          vendorSearchDebounceDelay: true,
          loading: true,
          searchedRows: [],
          searchedDataCurrentPage: 1,
          searchededTotal: 0,
          filterModel: {
            items: [],
          },
          filterDataCurrentPage: 1,
          filteredRows: [],
          filterDataLastPage: 1,
        },
        () => {
          this.getSearchApi();
        }
      );
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  getSearchApi = debounce(() => {
    this.setState({
      vendorSearchDebounceDelay: false,
    });
    this.searchVendors();
  }, 1000);

  searchVendors = () => {
    const url = searchItemizedVendorsByNameOrCode(
      this.props.item.id,
      this.props.selectedCategory.id,
      this.state.searchText.trim(),
      this.state.searchedDataCurrentPage
    );

    this.setState({
      loading: true,
    });

    axios
      .get(url)
      .then((res) => {
        if (this.state.searchedDataCurrentPage === 1) {
          this.setState({
            loading: false,
            searchedRows: res.data.data,
            searchedDataLastPage: res.data.meta?.last_page,
            searchededTotal: res.data.meta.total,
          });
        } else {
          let finalRows = [...this.state.searchedRows, ...res.data.data];
          let jsonObject = finalRows.map(JSON.stringify);
          let uniqueSet = new Set(jsonObject);
          let uniqueRows = Array.from(uniqueSet).map(JSON.parse);
          this.setState({
            loading: false,
            searchedRows: uniqueRows,
            searchedDataLastPage: res.data.meta?.last_page,
          });
        }
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
        console.log("searched vendors err", err);
      });
  };

  render() {
    const columns = [
      {
        field: "actions",
        type: "actions",
        width: 50,
        resizable: false,
        hide:
          !(
            this.props.userInfo?.is_super_user === "Y" ||
            this.props.masterPermission.vendor_master === "Y"
          ) || this.props.isRecipients,
        getActions: (params) => [
          <GridActionsCellItem
            className="dropdown_menu_with_icon"
            // icon={<MoreVert />}
            label="Edit Vendor"
            showInMenu
            onClick={() => {
              // deleteUser(params.id);
              this.props.handleAddVendorDialogOpen(
                params.id,
                params.row.vendor_name
              );
            }}
          />,
        ],
      },
      {
        field: "vendor_name",
        headerName: "VENDOR NAME",
        width: 350,
        editable: false,
        filterable: this.state.searchText.trim() === "",
        filterOperators: getFilterOperators(),
        renderCell: (params) => {

          return (
            <React.Fragment>
              <Avatar
                sx={{
                  backgroundColor: (theme) => theme.palette.success.main,
                }}>
                {params.row.vendor_name.slice(0, 1)}
              </Avatar>
              <Box sx={{ lineHeight: "12px", ml: 1 }}>
                <Tooltip title={params.row.vendor_name}>
                  <Typography color="text.primary" fontWeight={500} noWrap>
                    {params.row.vendor_name}
                  </Typography>
                </Tooltip>
                <Typography variant="span" color="grey.500">
                  {params.row.vendor_code}
                </Typography>
              </Box>
            </React.Fragment>
          );
        },
      },
      {
        field: "vendor_status",
        headerName: "VENDOR STATUS",
        width: 150,
        editable: false,
        filterable: this.state.searchText.trim() === "",
        filterOperators: getFilterOperators(),
        renderCell: (params) => {
          return (
            <React.Fragment>
              <Typography fontWeight={500}>
                {params.row.is_approved === "approved" ? 
                  getVendorGMPFlag("Y", "A", "Approved", 'A', "") : 
                  getVendorGMPFlag("Y", "N", "Non-Approved", 'N', "")
                }
                {getVendorGMPFlag(this.props.companyInfo.gst_verification, params.row.gstin_no, params.row.gstin_status, 'G', "GSTIN")}
                {params.row.industry_type === "MSME" ? 
                  getVendorGMPFlag(this.props.companyInfo.msme_verification, params.row.industry_type_value, params.row.msme_status, 'M', "MSME") : 
                  getVendorGMPFlag(this.props.companyInfo.msme_verification, "", params.row.msme_status, 'M', "MSME")
                }
                {getVendorGMPFlag(this.props.companyInfo.pan_verification, params.row.pan_no, params.row.pan_status, 'P', "PAN")}
              </Typography>
            </React.Fragment>
          );
        },
        // sortable: false,
      },
      {
        field: "email",
        headerName: "Email",
        filterable: this.state.searchText.trim() === "",
        width: 180,
        editable: false,
        filterOperators: getFilterOperators(),
        renderCell: (params) => {
          return (
            <React.Fragment>
              <Tooltip
                title={params.row.email}
                disableInteractive
                placement="bottom-start">
                <Typography>{params.row.email}</Typography>
              </Tooltip>
            </React.Fragment>
          );
        },
      },
      {
        field: "country",
        headerName: "COUNTRY",
        width: 150,
        editable: false,
        filterable: this.state.searchText.trim() === "",
        filterOperators: getFilterOperators(),
        // sortable: false,
      },
      {
        field: "state",
        headerName: "State",
        filterable: this.state.searchText.trim() === "",
        width: 150,
        editable: false,
        filterOperators: getFilterOperators(),
        renderCell: (params) => {
          return <Typography> {params.row.state}</Typography>;
        },
      },
      {
        field: "industry_type",
        headerName: "INDUSTRY TYPE",
        width: 200,
        editable: false,
        filterable: this.state.searchText.trim() === "",
        filterOperators: getFilterOperators(),
        // sortable: false,
      },
      {
        field: "contact_person",
        headerName: "Contact Person",
        filterable: this.state.searchText.trim() === "",
        width: 150,
        editable: false,
        filterOperators: getFilterOperators(),
        renderCell: (params) => {
          return <Typography>{params.row.contact_person}</Typography>;
        },
      },
      {
        field: "phone_no",
        headerName: "Contact Number",
        filterable: this.state.searchText.trim() === "",
        width: 150,
        editable: false,
        filterOperators: getFilterOperators(),
        renderCell: (params) => {
          return <Typography> {params.row.phone_no}</Typography>;
        },
      },
      {
        field: "address",
        headerName: "Address",
        filterable: this.state.searchText.trim() === "",
        width: 150,
        editable: false,
        filterOperators: getFilterOperators(),
        renderCell: (params) => {
          return <Typography> {params.row.address}</Typography>;
        },
      },
    ];

    const rows = this.state.isFilterActive
      ? this.state.filteredRows
      : this.state.searchText.trim() === ""
      ? this.state.rows
      : this.state.searchedRows;

    return (
      <div style={{ height: "calc(100vh - 49px)", width: "100%" }}>
        <DataGridPro
          sx={{
            "&.MuiDataGrid-root": {
              border: 0,
            },
            ".MuiDataGrid-cell--withRenderer.MuiDataGrid-cell:first-child": {
              padding: 0,
            },
          }}
          slots={{
            toolbar: QuickSearchToolbar,
            noRowsOverlay: () => {
              return (
                <GridOverlay>
                  <Box className="no-search">
                    <img src={noSearchimg} width={"150px"} height={"auto"} />
                    <Typography
                      component="p"
                      sx={{ pt: 1, textAlign: "center" }}>
                      There are no vendors
                    </Typography>
                  </Box>
                </GridOverlay>
              );
            },
          }}
          slotProps={{
            toolbar: {
              value: this.state.searchText,
              onChange: (event) => this.requestSearch(event.target.value),
              clearSearch: () => this.requestSearch(""),
            },
          }}
          columns={columns}
          rows={rows}
          // page={
          //   this.state.isFilterActive
          //     ? this.state.filterDataCurrentPage
          //     : this.state.searchText.trim() === ""
          //     ? this.state.vendorPage
          //     : this.state.searchedDataCurrentPage
          // }
          loading={
            this.state.isFilterActive
              ? this.state.filterLoading
              : this.state.loading
          }
          // rowCount={
          //   this.state.isFilterActive
          //     ? this.state.filteredTotal
          //     : this.state.searchText.trim() === ""
          //     ? this.state.total
          //     : this.state.searchededTotal
          // }
          onRowsScrollEnd={() => {
            this.handlePageChange();
            // if (this.state.pagination){
            // }
          }}
          disableRowSelectionOnClick
          disableColumnSelector
          disableColumnPinning
          // pagination
          // paginationMode="server"
          // onPageChange={this.handlePageChange}
          // pageSize={15}
          // rowsPerPageOptions={[15]}
          //hideFooter
          filterModel={this.state.filterModel}
          onFilterModelChange={this.handleFilterModelChange}
          filterMode="server"
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedCategory: state.categoryReducer.selectedCategory,
  sessionId: state.sessionReducer.sessionId,
  userInfo: state.userInfoReducer.userInfo,
  accountlevelInfo: state.permissionsReducer.accountlevelInfo,
  masterPermission: state.permissionsReducer.masterPermission,
  selectedPrCategory: state.prrfqreducer.selectedPrCategory,
  accountModulePermission: state.permissionsReducer.accountModulePermission,
  prlineitems: state.prrfqreducer.setPrLineItemsData,
  companyInfo: state.companyInfoReducer.companyInfo,
});

export default connect(mapStateToProps, {
  getItemizedVendors,
  filterItemizedVendors,
  setSelectedVendors,
})(SidebarVendors);
