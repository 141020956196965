import React, { useState, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import { Box } from "@mui/system";
import {
  Stack,
  IconButton,
  Tooltip,
  Typography,
  Toolbar,
  Skeleton,
  styled,
  Link,
  CardContent,
  CardHeader,
  Card,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { DataGrid } from "@mui/x-data-grid";
import {
  DataGridPro,
  useGridApiContext,
  gridColumnsTotalWidthSelector,
  gridColumnPositionsSelector,
} from "@mui/x-data-grid-pro";
import axios from "axios";
//CSS
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import CommonDataGridPro from "../RFQList/commonDataGridPro/commonDataGridPro";

const useStyles = makeStyles(() => ({
  RFQDrawer: {
    "&.MuiPaper-root": {
      boxShadow: "none",
      borderRadius: "0",
    },
    "& .DataGFullScreen-drawer": {
      height: "calc(100vh - 62px)",
      width: "100%",
    },
  },
}));
const columns = [
  {
    field: "item_name",
    headerName: "ITEM NAME",
    width: 100,
    // renderCell: (params) => (
    //   <Typography variant="body2" fontWeight={500}>
    //     {params.value}
    //   </Typography>
    // ),
  },
  {
    field: "rfq_qty",
    headerName: "RFQ QTY",
    width: 80,
  },
  {
    field: "quote_qty",
    headerName: "QUOTED QTY",
    width: 80,
  },
  {
    field: "unit",
    headerName: "UNIT",
    width: 80,
  },
  {
    field: "basic_rate",
    headerName: "BASIC RATE",
    width: 80,
  },
  {
    field: "landed_cost",
    headerName: "LANDED COST",
    width: 80,
  },
]
const TrasactionDetailsDrawer = ({templateNames,handleCloseDialogue,openDialogueBox,TransactionDetailsRow}) => {
  const classes = useStyles();
  return (
    <>
      <Drawer
        anchor="right"
        open={openDialogueBox}
        onClose={handleCloseDialogue}
        className="trasaction-drawer"
        variant="temporary"
      >
        <Card>
          <CardHeader
            action={
              <Tooltip title="Close">
                <IconButton
                  onClick={handleCloseDialogue}
                  aria-label="close"
                  size="small"
                  sx={{ color: "var(--grey-500)" }}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            }
            title={
              <Typography
                variant="subtitle1"
                fontWeight={500}
                color={"secondary.main"}
                noWrap
              >
                Details
              </Typography>
            }
            sx={{
              borderBottom: "1px solid var(--bluegrey-500)",
              pt: 1,
              pb: 1,
              "& .MuiCardHeader-action": {
                alignSelf: "center",
              },
            }}
          />
          <CardContent sx={{ padding:'15px' }}>
            <Box sx={{ flexGrow: 1}}>
                <Typography
                    variant="subtitle1"
                    fontWeight={500}
                    color={"secondary.main"}
                    noWrap
                >
                    {TransactionDetailsRow?.number}
                </Typography>
              <Grid container spacing={2}>
                <Grid xs={8}>
                    <Typography variant="body2" fontWeight={500}>CATEGORY NAME</Typography>
                    <Typography>{TransactionDetailsRow?.inquiry_categories?.name}</Typography>
                    <br></br>
                    <Typography variant="body2" fontWeight={500}>TEMPLATE NAME</Typography>
                    <Typography>{templateNames?.[TransactionDetailsRow?.template_id]}</Typography>

                </Grid>
                <Grid xs={4}>
                <Typography variant="body2" fontWeight={500}>CATEGORY CODE</Typography>
                    <Typography>{TransactionDetailsRow?.inquiry_categories?.code}</Typography>
                </Grid>

              </Grid>
            </Box>
            <Box className="DataGFullScreen-drawer">
          {/* <CommonDataGridPro
            // loading={loading}
            rows={[]}
            getRowId={(row) => row.id}
            columns={columns}
            columnHeaderHeight={40}
            pagination={true}
            // paginationModel={paginationModel}
            // NoRowsOverlay={CustomNoRowsOverlay}
            // onPaginationModelChange={setPaginationModel}
            // Toolbar={CustomGridToolbar}
            className="fullScreenGrid"
            sx={{
              "&.MuiDataGrid-root": {
                border: 0,
              },
              "& .MuiDataGrid-virtualScrollerContent--overflowed .MuiDataGrid-row--lastVisible .MuiDataGrid-cell":
                {
                  borderBottomColor: "var(--bluegrey-500)",
                },
            }}
          /> */}
        </Box>
          </CardContent>
          {/* <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={3000}
        open={snackbar}
        onClose={handleCloseSnackbar}
        // message="Validate Date Updated Successfully"
      >
        <Alert onClose={handleCloseSnackbar} severity="error" variant="filled">
          {errorMessage.message}
        </Alert>
      </Snackbar> */}
        </Card>
      </Drawer>
    </>
  );
};

export default TrasactionDetailsDrawer;
