export const handleItemsSelectAllClick = (ref, event) => {
  if (event.target.checked && ref.state.checkedItems.length === 0) {
    const newSelecteds = ref.props.selectedItems;
    ref.setState({
      checkedItems: newSelecteds,
    });
    return;
  }
  ref.setState({
    checkedItems: [],
  });
};

export const handleItemsClick = (ref, name) => {
  const { checkedItems } = ref.state;
  const selectedIndex = checkedItems.indexOf(name);
  let newSelected = [];

  if (selectedIndex === -1) {
    newSelected = newSelected.concat(checkedItems, name);
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(checkedItems.slice(1));
  } else if (selectedIndex === checkedItems.length - 1) {
    newSelected = newSelected.concat(checkedItems.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(
      checkedItems.slice(0, selectedIndex),
      checkedItems.slice(selectedIndex + 1)
    );
  }
  ref.setState({
    checkedItems: newSelected,
  });
};

export const isItemSelected = (ref, row) =>
  ref.state.checkedItems.some(
    e => e.id === row.id
  );