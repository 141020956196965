import { 
  SET_RFQ_SETTINGS, 
  REMIDER_DRAWER,
  RESET_REMINDER_DRAWER,
  SET_INCLUDE_TB,
  SET_ACTIVITY_TEMPLATE
} from "../constants/type";

const initialState = {
  rfqSettingValues: {
    vendorMasterIncoTerm: false,
    vendorMasterIncoTermDefaultSet: false,
    vendorMasterPaymentTerm: false,
    vendorMasterPaymentTermDefaultSet: false,
    allowMultipleSolution : false,
    basicRate:false,
    landedCost:false,
    allowMultiPlant:false
  },
  activityTemplateValues: {
    template_id: "",
    template_name:"",
    display_name:'',
    template_code:"",
    update: 0
  },
  remiderDrawer: {
    includeReminderInterval: true,
    enableHourlyReminderTB: true,
    sendSMSWithHourlyReminderTB: true,
    remiderDaysTB: 1,
    hourlyReminderValueTB: 1,
    startDate : "",
    intervalDays : "",
    sendSMS : false,
    enableHourlyReminder : false,
    hourlyReminderValue : "",
    sendSMSWithHourlyReminder : false
  },
  includeTB :false
};

const drawerValuesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_RFQ_SETTINGS:
      return { ...state, rfqSettingValues: payload };
    case SET_ACTIVITY_TEMPLATE:
      return { ...state, activityTemplateValues: payload };
    case REMIDER_DRAWER:
      return { ...state, remiderDrawer: payload };
    case RESET_REMINDER_DRAWER:
        return {
          ...state,
          remiderDrawer : initialState.remiderDrawer
          // remiderDrawer:{
          //   includeReminderInterval: true,
          //   enableHourlyReminderTB: true,
          //   sendSMSWithHourlyReminderTB: true,
          //   remiderDaysTB: 1,
          //   hourlyReminderValueTB: 1,
          // }
        }
    case SET_INCLUDE_TB:
      return {
        ...state,
        includeTB:payload
      };  
    default:
      return state;
  }
};

export default drawerValuesReducer;
