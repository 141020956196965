import { getGridStringOperators } from "@mui/x-data-grid-pro";

export const getFilterOperators = () => {
  return getGridStringOperators().filter(
    (operator) =>
      operator.value === "contains" ||
      operator.value === "equals" 
      // ||
      // operator.value === "startsWith" ||
      // operator.value === "endsWith"
  );
};
