import { CLOSE_SNACKBAR, OPEN_SNACKBAR } from "../constants/type";
const initialState = {
  open: false,
  type: "",
  msg: "",
  isMasterAction: false,
};

const snackbarReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case OPEN_SNACKBAR:
      return { open: true, type: payload.type, msg: payload.msg, isMasterAction: payload.isMasterAction ? payload.isMasterAction : false };
    case CLOSE_SNACKBAR:
      return { open: false, type: "", msg: "", isMasterAction: false };
    default:
      return state;
  }
};

export default snackbarReducer;
