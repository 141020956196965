import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import {
  Grid,
  Switch,
  Autocomplete,
  TextField,
  Typography,
  Divider,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  Button,
  MenuItem,
} from "@mui/material";
import { Box } from "@mui/system/";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { CustomSwitchLabel } from "../Switches";
import  axiosInstance  from '../../interceptor/axiosInstance';
import {withStyles } from "@mui/styles";
import { styles } from '../RFQSetting/style';
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined';
import {activityTemplateListUri} from "../../config/apiUrl";
import axios from "axios";

import {setDisplayOptions} from "../../redux/actions/prrfqactions"
import { useDispatch, useSelector,connect } from 'react-redux';

const RfqSettingTab = (props) => {
  const [error, setError] = useState("");
  const [projectList, setProjectList] = useState([]);
  // const [label,setLabel] = useState();
  const [loading, setLoading] = useState(true);
   
  const location = useLocation();
  // const valueRef = useRef();
  const [selectedActivityTemplate, setActivityTemplate] = useState(props?.settings?.activityTemplate || []);
  const [selectedallowVendorFill, setAllowVendorFill] = useState(props?.settings?.allowVendorFill || props?.settings?.activityTemplate?.allowVendorFill || "");
  const [selectedProjectValue, setSelectedProjectValue] = useState(props.settings.projects || []);
  const [activityTemplateList,setActivityTemplateList] = useState([]);
  const [vendorMasterIncoTerm,setVendorMasterIncoTerm] = useState(props.settings.vendorMasterIncoTerm || "default");
  const [includeSlab,setIncludeSlab] = useState(props.settings.includeSlab || "default");
  const [vendorMasterPaymentTerm,setVendorMasterPaymentTerm] = useState(props.settings.vendorMasterPaymentTerm || "default");
  const [serviceRFQ,setServiceRFQ] = useState(props.settings.serviceRFQ || "default");
  const [allowMultipleSolution,setAllowMultipleSolution] = useState(props?.settings.allowMultipleSolution || "default");
  const [allowMultiPlant,setAllowMultiPlant] = useState(props.settings.allowMultiPlant || "default");
  const [landedCost,setLandedCost] = useState(props.settings.landedCost || "default");
  const [basicRate,setBasicRate] = useState(props.settings.basicRate || "default");
  const [activityTemplateid,setActivityTemplateid] = useState(selectedActivityTemplate?.template_id || -1);
  let buttonClick = {};
  buttonClick.target = {};
  buttonClick.target.value = "on";
  buttonClick.target.type = 'checkbox';

    if(props.settings.vendorMasterIncoTerm != vendorMasterIncoTerm && vendorMasterIncoTerm != "default"){
    buttonClick.target.name = "vendorMasterIncoTerm";
    buttonClick.target.checked = vendorMasterIncoTerm;
    props.handleChange(buttonClick)
  }
  if(props.settings.includeSlab != includeSlab && includeSlab != "default"){
    buttonClick.target.name = "includeSlab";
    buttonClick.target.checked = includeSlab;
    props.handleChange(buttonClick)
  }
  if(props.settings.vendorMasterPaymentTerm != vendorMasterPaymentTerm && vendorMasterPaymentTerm != "default"){
    buttonClick.target.name = "vendorMasterPaymentTerm";
    buttonClick.target.checked = vendorMasterPaymentTerm;
    props.handleChange(buttonClick)
  }
  if(props.settings.serviceRFQ != serviceRFQ && serviceRFQ != "default"){
    buttonClick.target.name = "serviceRFQ";
    buttonClick.target.checked = serviceRFQ;
    props.handleChange(buttonClick)
  }
  if(props.settings.allowMultipleSolution != allowMultipleSolution && allowMultipleSolution != "default"){
    buttonClick.target.name = "allowMultipleSolution";
    buttonClick.target.checked = allowMultipleSolution;
    props.handleChange(buttonClick)
  }
  if(props.settings.allowMultiPlant != allowMultiPlant && allowMultiPlant != "default"){
    buttonClick.target.name = "allowMultiPlant";
    buttonClick.target.checked = allowMultiPlant;
    props.handleChange(buttonClick)
  }
  if(props.settings.landedCost != landedCost && landedCost != "default"){
    buttonClick.target.name = "landedCost";
    buttonClick.target.checked = landedCost;
    props.handleChange(buttonClick)
  }
  if(props.settings.basicRate != basicRate && basicRate != "default"){
    buttonClick.target.name = "basicRate";
    buttonClick.target.checked = basicRate;
    props.handleChange(buttonClick)
  }

  useEffect(() => {
    fetchProjectsOptions();
    if(props.accountModulePermission["Service PR"]?.module_activated === "Y"){
      fetchActivityTemplateOptions();
    }
    return () => {};
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    //valueRef.current = settings
  }, []);

  const handleProjects = (event, value) => {
    let e = {}
    e.target = {}
    e.target.name = "projects"
    e.target.value = value
    props.handleChange(e);
    if(event != null){
      setSelectedProjectValue(value);
    }
  };

  if((selectedProjectValue != props.settings.projects)){
    if(selectedProjectValue?.length){
      handleProjects(null,selectedProjectValue);
    }
  }

  if(selectedActivityTemplate != props.settings.activityTemplate){
    if(selectedActivityTemplate?.length && window.location.pathname.indexOf("prcombination") === -1){
      handleActivityTemplate(null,selectedActivityTemplate);
    }
  }
  const handleActivityTemplateVendorFill =(event,value) =>{
    let e = {};
    e.target = {};
    e.target.name = "allowVendorFill";
    if(event != null){
      e.target.value = value?.props?.value || value;
      setAllowVendorFill(e.target.value)
    }
    else{
      e.target.value = value;
    }
    props.handleChange(e);
  };
  if(selectedallowVendorFill != props?.settings?.allowVendorFill && selectedallowVendorFill != "" && window.location.pathname.indexOf("prcombination") === -1){
    handleActivityTemplateVendorFill(null,selectedallowVendorFill);
  }
  const handleActivityTemplate =(event,value) =>{
    let e = {};
    e.target = {};
    e.target.name = "activityTemplate";
    if(!value){
      value = {
        template_id: "",
        template_name:"",
        display_name:'',
        template_code:"",
        update: 0
      };
    }
    e.target.value = value;
    if(e &&  e.target?.template_id != activityTemplateid){
      setActivityTemplateid(value?.template_id || -1);
      props.handleChange(e);
      value.update = 1;
    }
    if(event != null){
      setActivityTemplate(value)
    }
  };
  const handleButtonToggle =(name,value) => {
    let e = {};
    e.target = {};
    e.target.name = name;
    e.target.value = value;
    props.handleChange(e);
  };
  const fetchProjectsOptions = () => {
    axiosInstance
      .get("/v1/projects")
      .then((res) => {
        setProjectList(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        props.openSnackbar("error", "Failed to fetch Projects");
        setLoading(true);
      });
  };

  const fetchActivityTemplateOptions = () => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${props.sessionId}`;
    axios
      .get(activityTemplateListUri)
      .then((res) => {
        setActivityTemplateList( res.data.data);
        if(!res.data.data?.length){
          props.openSnackbar("error", `No ${props.service_label} Templates Found`);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        props.openSnackbar("error", `Failed to fetch ${props.service_label} templates`);
        setLoading(true);
      });
  };
  return (
    <>
      {/* <Grid item lg={12} md={12} sm={12} xs={12} className="mt-25">
          {location.pathname.indexOf("prcombination")>-1?
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                    minDate= {moment(new Date()).add(1,'days')}
                    renderInput={(props) => (
                        <TextField
                            className="template_Date_picker template_textbox"
                            {...props}
                            fullWidth
                            //title={error ? moment(value).isValid() ? "Delivery date should be greater than or equal to current date" : "invalid date" : ""}
                            placeholder={"date"}
                            label="Validity Date"
                            error={error}
                            helperText={error ? "invalid" : ""}
                        />
                    )}
                    // ampm={false}
                    name={"validityDate"}
                    value={props.settings.validityDate || null}
                    onChange={(newVal) => {
                        console.log("newVal==",newVal)
                       let obj = {};
                       obj.target = {};
                       obj.target.name = "validityDate" 
                       obj.target.value = newVal
                       props.handleChange(obj)
                      
                    }}
                />
            </LocalizationProvider>:null
          }
        </Grid> */}
      {/* <Grid item lg={12} md={12} sm={12} xs={12} className="mt-25">
          {location.pathname.indexOf("prcombination")>-1 
          // && props.applyFlag === true
          ?
            <FormControl fullWidth variant="outlined">
              <InputLabel>Select Template</InputLabel>
              <Select
                fullWidth
                label="Select Template"
                name={"template"}
                value={props.settings.template}
                onChange={(event)=>props.handleChange(event)}
              >
               <MenuItem value={""}>Select Template</MenuItem>
               {props.settings.loading && (
                    <MenuItem value={20}>Loading...</MenuItem>
                  )}
                  {props.settings.templateOptions &&
                    props.settings.templateOptions.map((option, index) => (
                      <MenuItem key={option.id} value={option}>
                        {option.name}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>:null}
        </Grid>  */}
      <Box
        sx={{
          backgroundColor: "white",
          border: "1px solid",
          borderColor: (theme) => theme.palette.bluegrey[500],
          padding: "16px",
          borderRadius: "4px",
        }}
      >
        {props.accountModulePermission?.["Project"]?.module_activated ===
          "Y" && (
          <Grid item lg={12} md={12} sm={12} xs={12} className="">
            <Autocomplete
              options={projectList}
              value={selectedProjectValue}
              onChange={(event, value) => handleProjects(event,value)}
              // loading={this.state.paymentTermsCodeLoading}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              multiple
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Select Projects"
                  fullWidth
                  name="projectList"
                />
              )}
            />
          </Grid>
        )}
        {/* {location.pathname.indexOf("prcombination")>-1?
        <Grid item lg={12} md={12} sm={12} xs={12} className="mt-25">
            <FormControl fullWidth variant="outlined">
              <InputLabel>Select RFQ Type</InputLabel>
              <Select
                fullWidth
                label="RFQ Type"
                name={"rfqType"}
                value={props.settings.rfqType}
                onChange={(event) => props.handleChange(event)}
              >
                <MenuItem value="categoryVendor" selected>Category Vendor</MenuItem>
                <MenuItem value="itemVendor">item vendor</MenuItem>
              </Select>
            </FormControl>
          </Grid>:null
          } */}

        {/* <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt: 1,}}>
            <CustomSwitchLabel
            sx={{ml:0, display: 'flex', justifyContent: 'space-between' }}
            control={
                <Switch
                // disabled={
                //     props.masterPermission?.update_payment_terms ===
                //     "N"
                // }
                // onChange={(event)=>props.handleChange(event)}
                // name="allowMultipleSolution"
                // checked={props.settings.allowMultipleSolution}
                />
            }
            labelPlacement="start"
            label="Include Slab"
            />
          </Grid> */}

        {/* {props.accountModulePermission?.["Multiple Solutions"]
          ?.module_activated === "Y" && props.accountModulePermission["Service PR"]?.module_activated != "Y" && 
          props.allowed_slab != 1 && (
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 1 }}>
              <CustomSwitchLabel
                sx={{ ml: 0, display: "flex", justifyContent: "space-between" }}
                control={
                  <Switch
                    onChange={(event,value)=>{
                      if(value && serviceRFQ && serviceRFQ != "default"){
                        props.openSnackbar("error", `${props.service_label} and ${props.label} can't be activited together`);
                        setServiceRFQ(!value);
                      }
                      setAllowMultipleSolution(value);
                      props.handleChange(event);
                    }}
                    name="allowMultipleSolution"
                    checked={!(serviceRFQ != "default" ? serviceRFQ : false) && (allowMultipleSolution != "default" ? allowMultipleSolution : false)}
                    disabled = {window.location.pathname.indexOf("prcombination") > -1 && props.accountModulePermission["Service PR"]?.module_activated === "Y" }
                  />
                }
                labelPlacement="start"
                label={`Allow ${props.label}`}
              />
            </Grid>
          )}
        {props.accountModulePermission?.["Multiple Solutions"]
          ?.module_activated === "Y" && props.allowed_slab==1 
          && props.accountModulePermission["Service PR"]?.module_activated != "Y" 
          && location.pathname.indexOf("prcombination") == -1 && (
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 1 }}>
              <CustomSwitchLabel
                sx={{ ml: 0, display: "flex", justifyContent: "space-between" }}
                control={
                  <Switch
                    onChange={(event,value)=>{
                      setIncludeSlab(value);
                      props.handleChange(event);
                    }}
                    name="includeSlab"
                    checked={includeSlab != "default" ? includeSlab : false}
                  />
                }
                labelPlacement="start"
                label={`Include ${props.label}`}
              />
            </Grid>
          )} */}

        {
          //this.props.accountModulePermission["Fetch Payment Term"]
          //?.module_activated === "Y" && (
          <>
            {/* { location.pathname.indexOf("prcombination")>-1? <>
          <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt: 1,}}>
              <CustomSwitchLabel
              sx={{ml:0, display: 'flex', justifyContent: 'space-between' }}
                control={
                  <Switch
                    disabled={
                      props.masterPermission?.update_payment_terms ===
                      "N"
                    }
                    onChange={(event)=>props.handleChange(event)}
                    name="priorityRfq"
                    checked={props.settings.priorityRfq}
                  />
                }
                labelPlacement="start"
                label="Priority RFQ"
              />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt: 1,}}>
            <CustomSwitchLabel
            sx={{ml:0, display: 'flex', justifyContent: 'space-between' }}
              control={
                <Switch
                  disabled={
                    props.masterPermission?.update_payment_terms ===
                    "N"
                  }
                  onChange={(event)=>props.handleChange(event)}
                  name="sealedGroupRfq"
                  checked={props.settings.sealedGroupRfq}
                />
              }
              labelPlacement="start"
              label="Sealed Group RFQ"
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt: 1,}}>
            <CustomSwitchLabel
            sx={{ml:0, display: 'flex', justifyContent: 'space-between' }}
              control={
                <Switch
                  disabled={
                    props.masterPermission?.update_payment_terms ===
                    "N"
                  }
                  onChange={(event)=>props.handleChange(event)}
                  name="includeTechnicalBid"
                  checked={props.settings.includeTechnicalBid}
                />
              }
              labelPlacement="start"
              label="Include Technical Bid"
            />
          </Grid>
        </>:null 
        } */}
            {/* <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt: 1,}}>
            <CustomSwitchLabel
            sx={{ml:0, display: 'flex', justifyContent: 'space-between' }}
            control={
                <Switch
                disabled={
                    props.masterPermission?.update_payment_terms ===
                    "N"
                }
                onChange={(event)=>props.handleChange(event)}
                name="allowMultipleSolution"
                checked={props.settings.allowMultipleSolution}
                />
            }
            labelPlacement="start"
            label="Allow Multiple Solutions"
            />
        </Grid> */}
            {props.accountModulePermission?.["Fetch Payment Term"]
              ?.module_activated === "Y" && (
              <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
                <CustomSwitchLabel
                  sx={{
                    ml: 0,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  control={
                    <Switch
                      disabled={
                        props.masterPermission?.update_payment_terms === "N"
                      }
                      onChange={(event,value)=>{
                        setVendorMasterPaymentTerm(value)
                        props.handleChange(event)
                      }}
                      name="vendorMasterPaymentTerm"
                      checked={vendorMasterPaymentTerm != "default" ? vendorMasterPaymentTerm : false}
                    />
                  }
                  labelPlacement="start"
                  label="Vendor Master Payment Term"
                />
              </Grid>
            )}
          </>
          //  )
        }
        {props.accountModulePermission?.["Fetch Inco Term"]
          ?.module_activated === "Y" && (
          <Grid item lg={12} md={12} sm={12} xs={12}>
              <CustomSwitchLabel
                sx={{ ml: 0, display: "flex", justifyContent: "space-between" }}
                control={
                  <Switch
                    disabled={
                      props.masterPermission?.update_inco_terms ===
                      "N"
                    }
                    onChange={(event,value)=>{
                      setVendorMasterIncoTerm(value);
                      props.handleChange(event);
                    }}
                    name="vendorMasterIncoTerm"
                    checked={vendorMasterIncoTerm != "default" ? vendorMasterIncoTerm : false}
                  />
                }
                labelPlacement="start"
                label="Vendor Master Inco Term"
              />
            </Grid>
          )}
          </Box>
          
          {window.location.pathname.indexOf("prcombination") === -1 && (<Box sx={{
            backgroundColor: "white",
            border: "1px solid",
            borderColor: (theme) => theme.palette.bluegrey[500],  
            padding: '16px',
            borderRadius: '4px',
            mt: 2
          }}>

          {(props.accountModulePermission["Service PR"]
          ?.module_activated === "Y" )&& (
          <>
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt: 1,}}>
              <CustomSwitchLabel
                sx={{ml:0, display: 'flex', justifyContent: 'space-between' }}
                control={
                  <Switch
                  onChange={(event,value)=>{
                    setServiceRFQ(value);
                    if(value && (allowMultipleSolution && allowMultipleSolution != "default")){
                      props.openSnackbar("error", `${props.service_label} and ${props.label} can't be activited together`);
                      setAllowMultipleSolution(!value);
                      setIncludeSlab(!value);
                    }
                    handleButtonToggle("serviceRFQ",value);
                    props.handleChange(event)
                  }}
                  name="serviceRFQ"
                  />
                }
                checked={(serviceRFQ != "default" ? serviceRFQ : false) && !(allowMultipleSolution != "default" ? allowMultipleSolution : false)}
                disabled = {window.location.pathname.indexOf("prcombination") > -1 && props.accountModulePermission["Service PR"]
                  ?.module_activated === "Y" }
                labelPlacement="start"
                label="Service RFQ"
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt: 1,}}>
              <FormControl fullWidth>
                <Autocomplete
                  disabled = {!(serviceRFQ != "default" ? serviceRFQ : false)}
                  options={activityTemplateList}
                  value={selectedActivityTemplate}
                  onChange={(event, value) => handleActivityTemplate(event,value)}
                  getOptionLabel={(option) => option.display_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={"Select " + props.service_label +" Template"}
                      fullWidth
                      name="activityTemplateList"
                      />
                      )}
                />
              </FormControl>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt: 2,}}>
              <FormControl fullWidth>              
                <InputLabel disabled = {!(serviceRFQ != "default" ? serviceRFQ : false)}>Allow Vendor To Fill {props.service_label} Template</InputLabel>                  
                <Select
                  disabled = {!(serviceRFQ != "default" ? serviceRFQ : false)}
                  // value={age}
                  value={selectedallowVendorFill}
                  label={"Allow Vendor To Fill " + props.service_label +" Template"}
                  onChange={(event, value) => handleActivityTemplateVendorFill(event,value)}
                  className="template_dropdown_menu"
                >
                  <MenuItem value={10}>Partial</MenuItem>
                  <MenuItem value={20}>Complete</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt: 1}}>
              <Box sx={{ pb:1}} disabled = {!(serviceRFQ != "default" ? serviceRFQ : false)}>
              <Button        
                  startIcon={<VisibilityIcon />}
                  disabled = {!(serviceRFQ != "default" ? serviceRFQ : false)}
                  onClick={(e) =>
                    { 
                      if((serviceRFQ)){
                        if ( activityTemplateid == -1) {
                          props.openSnackbar("error", `Please select the ${props.service_label} Template`);
                          setLoading(true);
                        }
                        else{
                          props.triggerTemplateIFrame(
                            "activityPreview",
                            activityTemplateid,//categroyTBTemplate?.id,
                            0,//categroyTBTemplatePreviousValue?.pre_fetch_inquiry_id,
                            1,
                          )
                        }
                      }
                    }
                  }
                >
                  Preview
                </Button>

              </Box>
              { (props.accountModulePermission?.["Multiple Solutions"]
          ?.module_activated === "Y" && props.accountModulePermission["Service PR"]
          ?.module_activated === "Y" ) && <Divider textAlign="center">OR</Divider>}
            </Grid>
          </>
          )}
            {props.accountModulePermission?.["Multiple Solutions"]
          ?.module_activated === "Y" && 
              (<Grid item lg={12} md={12} sm={12} xs={12} sx={{mt: 1,}}>
              <CustomSwitchLabel
              sx={{ml:0, display: 'flex', justifyContent: 'space-between' }}
                control={
                  <Switch
                    onChange={(event,value)=>{
                      if(value && serviceRFQ && serviceRFQ != "default" && props.accountModulePermission["Service PR"]?.module_activated === "Y" ){
                        props.openSnackbar("error", `${props.service_label} and ${props.label} can't be activited together`);
                        setServiceRFQ(!value);
                        let activityHash = {};
                        activityHash = {
                          template_id: "",
                          template_name:"",
                          display_name:'',
                          template_code:"",
                          update: 0
                        };
                        handleActivityTemplate(1,activityHash)
                        handleActivityTemplateVendorFill(1,'');
                      }
                      setAllowMultipleSolution(value);
                      console.log(props.displayOptions.allowed_slab,value);
                      setIncludeSlab(props.displayOptions.allowed_slab ? value : false);
                      handleButtonToggle("allowMultipleSolution",value);
                      props.handleChange(event)
                    }}
                  />
                }
                checked={!(serviceRFQ != "default" ? serviceRFQ : false) && (allowMultipleSolution != "default" ? allowMultipleSolution : false)}
                disabled = {window.location.pathname.indexOf("prcombination") > -1 && props.accountModulePermission["Service PR"]
                ?.module_activated === "Y" }
                labelPlacement="start"
                label={`Allow ${props.label}`}
              />
            </Grid>)}
          </Box>)}
          
          {props.featurePermission?.display_vendors_rank
            ?.feature_value === "Y" && (
            <>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography fontWeight={500} sx={{textTransform:'uppercase', color: 'grey.500', mt:2, mb: 2, }}>Display Rank to Vendor on</Typography>                    
              </Grid>
              <Box sx={{
                        backgroundColor: "white",
                        border: "1px solid",
                        borderColor: (theme) => theme.palette.bluegrey[500],  
                        padding: '16px',
                        borderRadius: '4px',
                      }}>

              <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt: 1,}}>
                <CustomSwitchLabel
                sx={{ml:0, display: 'flex', justifyContent: 'space-between' }}
                  control={
                    <Switch
                    onChange={(event,value)=>{
                        setBasicRate(value);
                        props.handleChange(event)
                      }}
                      name="basicRate"
                      checked={(basicRate != "default" ? basicRate : false)}
                    />
                  }
                  labelPlacement="start"
                  label="Basic Rate"
                />
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12} sx={{mt: 1,}}>
                <CustomSwitchLabel
                sx={{ml:0, display: 'flex', justifyContent: 'space-between' }}
                  control={
                    <Switch
                    onChange={(event,value)=>{
                      setLandedCost(value);
                        props.handleChange(event);
                      }}
                      name="landedCost"
                      checked={landedCost != "default" ? landedCost : false}
                    />
                  }
                  labelPlacement="start"
                  label="Landed Cost"
                />
              </Grid>
              </Box>
            </>
          )}
      {location.pathname.indexOf("prcombination") == -1 &&
        props.featurePermission?.Multiplant_RFQ?.feature_value === "Y" && (
        <>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography fontWeight={500} sx={{textTransform:'uppercase', color: 'grey.500', mt:2, mb: 2, }}>MultiPlant</Typography>                    
            </Grid>
            <Box
              sx={{
                backgroundColor: "white",
                border: "1px solid",
                borderColor: (theme) => theme.palette.bluegrey[500],
                padding: "16px",
                borderRadius: "4px",
              }}
            >
              <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 1 }}>
                <CustomSwitchLabel
                  sx={{
                    ml: 0,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  control={
                    <Switch
                    onChange={(event,value)=>{
                        setAllowMultiPlant(value);
                        props.handleChange(event);
                      }}
                      name="allowMultiPlant"
                      checked={allowMultiPlant != "default" ? allowMultiPlant : false}
                      disabled={props.includeTB}
                    />
                  }
                  labelPlacement="start"
                  label="Allow Multi-Destination"
                />
              </Grid>
            </Box>
          </>
        )}
      {new URLSearchParams(location.search)?.get("multiplant_rfq") == 1 &&
        location.pathname.indexOf("prcombination") == 1 && (
          <>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography
                fontWeight={500}
                sx={{
                  textTransform: "uppercase",
                  color: "grey.500",
                  mt: 2,
                  mb: 2,
                }}
              >
                MULTI-DESTINATION
              </Typography>
            </Grid>
            <Box
              sx={{
                backgroundColor: "white",
                border: "1px solid",
                borderColor: (theme) => theme.palette.bluegrey[500],
                padding: "16px",
                borderRadius: "4px",
              }}
            >
              <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 1 }}>
                <CustomSwitchLabel
                  sx={{
                    ml: 0,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  control={
                    <Switch
                      onChange={(event) => props.handleChange(event)}
                      name="allowMultiPlant"
                      // checked={props.settings.allowMultiPlant}
                      // disabled={props.includeTB}
                      checked={true}
                      disabled={true}
                    />
                  }
                  labelPlacement="start"
                  label="Allow Multi-Destination"
                />
              </Grid>
            </Box>
          </>
        )}
    </>
  );
};

const mapStateToProps = (state) => ({
  displayOptions: state.prrfqreducer.displayOptions
});

export default connect(mapStateToProps, {
  setDisplayOptions
})(withStyles(styles)(RfqSettingTab));

// export default RfqSettingTab;
// export default withStyles(styles)(RfqSettingTab);
