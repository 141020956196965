import {SET_SELECTED_PR_CATEGORY,
  SET_DISABLED_PR_CATEGORY,
  SET_DISABLE_INDX,
  SET_PLANT_CODES,
  SET_PR_DIALOG,
  SET_PR_ITEMS,
  SET_PR_LINE_ITEMS_DATA,
  SET_PRDROPDOWN_VALUES,
  SET_PR_AND_ITEMS_LINK_VALUE,
  SET_RFQ_PRS,
  SET_PR_COMBINATIONS,
  CHANGE_PR_SELECTED,
  SET_GROUP_NO,
  SET_PR_COMBINATIONS_ITEMS,
  SET_TEMPLATE,
  SET_DEFAULT_VALUES,
  SET_EMPTY_PR_COMBINATION,
  SET_REMAINING_PR_COMBINATION,
  SET_API_DATA,
  SET_TEMPLATE_OPTIONS,
  SET_JSON_TEMPLATES,
  SET_TEMPLATE_VALUES,
  SET_TERMS_AND_CONDTIONS,
  SET_FILE_SPECIFICATION_FROM_API,
  SET_INVALID_COMBINATIONS,
  SET_DISPLAY_OPTIONS,
  SET_RFQ_LOADING,
} from "../constants/type";

export const setSelectedPrCategory = (data) => {
    return {
      type: SET_SELECTED_PR_CATEGORY,
      payload: data,
    };
};

export const setDisabledPrCategory = (data) => {

  return {
    type:SET_DISABLED_PR_CATEGORY,
    payload:data
  }
}

export const setDisableIndex = (num)=> {

  return {
    type:SET_DISABLE_INDX,
    payload:num
  }
}

export const setPlantCodes = (plant) => {
  return {
    type:SET_PLANT_CODES,
    payload:[...plant]
  }
}

export const setPrDropdownValues = (pr_dropdown_values) => {
  return {
    type:SET_PRDROPDOWN_VALUES,
    payload:pr_dropdown_values
  }
}


export const setPrDialog = (open) => {
  return {
    type:SET_PR_DIALOG,
    payload:open
  }
}

export const setPRselectedChange = () =>(dispatch)=> {
  dispatch( {
    type:CHANGE_PR_SELECTED
  });
}


export const setPrItems = (item) => {
  return {
    type:SET_PR_ITEMS,
    payload:item
  }
}

export const setPrLineItemsData = (prlineItems) => {
  return {
    type:SET_PR_LINE_ITEMS_DATA,
    payload:prlineItems
  }
}
  

export const setPrlinktoitemsforspecification =(value) => (dispatch)=> {
  dispatch( {
    type:SET_PR_AND_ITEMS_LINK_VALUE,
    payload:value
  });
}

export const setPrRfqs = (rfqPrs) => (dispatch) => {
  dispatch({
    type:SET_RFQ_PRS,
    payload:rfqPrs
  })
}

export const setPrCombinations = (prCombinations) => {
  return {
    type:SET_PR_COMBINATIONS,
    payload:prCombinations
  }
}

export const setGroupNo = (group_no) => {
  return {
    type:SET_GROUP_NO,
    payload:group_no
  }
}

  
export const setprcombinationItems = (items = [],removdeitems = []) =>(dispatch)=> {
  dispatch( {
    type:SET_PR_COMBINATIONS_ITEMS,
    payload:{"items":items,"removeditems":removdeitems}
  });
}

export const setTemplate = (template) => (dispatch) => {
  
  dispatch({
      type: SET_TEMPLATE,
      payload: template
    });
}
export const setDefaultValues = (payload) => (dispatch) => {
  dispatch({
      type: SET_DEFAULT_VALUES,
      payload:payload
    });
}
export const setEmptyPrCombination = (payload)=>(dispatch) => {
  dispatch({
    type: SET_EMPTY_PR_COMBINATION,
    payload:payload
  });
}
export const setRemainingPrCombination = (payload)=>(dispatch) => {
  dispatch({
    type: SET_REMAINING_PR_COMBINATION,
    payload:payload
  });
}
export const setApiData = (payload)=>(dispatch) => {
  dispatch({
    type: SET_API_DATA,
    payload:payload
  });
}

export const setTemplateOptions = (payload)=>(dispatch) => {
  dispatch({
    type: SET_TEMPLATE_OPTIONS,
    payload:payload
  });
}

export const setJsonTemplates = (payload)=>(dispatch) => {
  dispatch({
    type: SET_JSON_TEMPLATES,
    payload:payload
  });
}

export const setTemplateValues = (payload)=>(dispatch) => {
  dispatch({
    type: SET_TEMPLATE_VALUES,
    payload:payload
  });
}

export const setTermsAndConditions = (payload)=>(dispatch) => {
  dispatch({
    type: SET_TERMS_AND_CONDTIONS,
    payload:payload
  });
}

export const setFileSpecificationFromApi = (payload)=>(dispatch) => {
  dispatch({
    type: SET_FILE_SPECIFICATION_FROM_API,
    payload:payload
  });
}
export const setInValidCombinations = (payload)=>(dispatch) => {
  dispatch({
    type: SET_INVALID_COMBINATIONS,
    payload:payload
  });
}

export const setDisplayOptions = (payload)=>(dispatch) => {
  dispatch({
    type: SET_DISPLAY_OPTIONS,
    payload:payload
  });
}
export const setRfqLoading = (payload)=>(dispatch) => {
  dispatch({
    type: SET_RFQ_LOADING,
    payload:payload
  });
}
