import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import GavelOutlinedIcon from '@mui/icons-material/GavelOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import otgIcon from '../assets/vendxAdminPanelAsstes/OTG_ICON.svg'
import WysiwygOutlinedIcon from '@mui/icons-material/WysiwygOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
export const IconMapping = {
    "Transaction Register" : <ViewListOutlinedIcon />,
    "Visit Report" : <ReceiptLongOutlinedIcon />,
    "OTG" : <img src={otgIcon} />,
    "Auction" : <GavelOutlinedIcon />,
    "View Action Plan" : <WysiwygOutlinedIcon />,
    "Settings" : <SettingsOutlinedIcon />,
    "Switch Account" : <HomeOutlinedIcon />
}