import React, { Component } from "react";
import { Avatar, IconButton, Typography, Box } from "@mui/material";
import { Dialog, DialogActions, Button } from "@mui/material/";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { connect } from "react-redux";
import { DataGridPro, GridOverlay } from "@mui/x-data-grid-pro";
import { setSelectedVendors } from "../../../redux/actions/vendorActions";
import { openSnackbar } from "../../../redux/actions/snackbarAction";

class Details extends Component {
  state = {
    rows: [],
    loaderArray: [],
    error: false,
  };

  toggleLoaderArray = (arg) => {
    let temp = this.state.loaderArray;
    let index = temp.indexOf(arg);
    if (index > -1) {
      temp.splice(index, 1);
    } else {
      temp.push(arg);
    }
    this.setState({
      loaderArray: temp,
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.open) {
      if (!nextProps.selectedVendors || !nextProps.accountlevelInfo) return;
      let finalRows;
      if (nextProps.vendorType === "local") {
        finalRows = nextProps.selectedVendors.filter(
          (vendor) => vendor.country === nextProps.accountlevelInfo.country
        );
      } else {
        finalRows = nextProps.selectedVendors.filter(
          (vendor) => vendor.country !== nextProps.accountlevelInfo.country
        );
      }

      finalRows.forEach((vendor) => {
        if (nextProps.vendorTerms === "inco") {
          if (vendor.inco_term_info) {
            let obj = {
              id: vendor.id,
              vendor_name: vendor.vendor_name,
              is_approved: vendor.is_approved,
              inco_term_name: vendor.inco_term_info.inco_term_name,
              inco_term_code: vendor.inco_term_info.inco_term_code,
            };
            this.setState((prevstate) => {
              return {
                ...prevstate,
                rows: [...prevstate.rows, obj],
              };
            });
          } else {
            this.setState({ error: true });
          }
        } else {
          if (vendor.payment_term_info) {
            let obj = {
              id: vendor.id,
              vendor_name: vendor.vendor_name,
              is_approved: vendor.is_approved,
              payment_term_name: vendor.payment_term_info.payment_term_name,
              payment_term_code: vendor.payment_term_info.payment_term_code,
            };
            this.setState((prevstate) => {
              return {
                ...prevstate,
                rows: [...prevstate.rows, obj],
              };
            });
          } else {
            this.setState({ error: true });
          }
        }
      });
      // console.log(mainrows);
      // console.log(this.state.loaderArray);

      // if (!this.state.loaderArray) {
      //   console.log(mainrows);
      //   this.setState({
      //     rows:mainrows
      //   })
      // }
      // this.setState(
      //   {
      //     rows: rows,
      //   }
      // () => {
      //   this.state.rows.forEach((vendor) => {
      //     if (nextProps.vendorTerms === "inco") {
      //       // console.log(vendor.inco_term_code, "inco_term_code");
      //       if (vendor.inco_term_code) {
      //         this.toggleLoaderArray(`${vendor.vendor_code}_inco`);
      //         axios
      //           .get(getPaymentTermIncoterm(vendor.inco_term_code))
      //           .then((response) => {
      //             this.toggleLoaderArray(`${vendor.vendor_code}_inco`);
      //             console.log(response.data, "inco_term_code response");
      //           })
      //           .catch((err) => {
      //             this.toggleLoaderArray(`${vendor.vendor_code}_inco`);
      //             this.props.openSnackbar(
      //               "error",
      //               err.response?.data?.message
      //                 ? err.response?.data?.message
      //                 : "Something went wrong!"
      //             );
      //             console.log(err);
      //           });
      //       }
      //     } else {
      //       // console.log(vendor.payment_term_code, "payment_term_code");
      //       if (vendor.payment_term_code) {
      //         this.toggleLoaderArray(`${vendor.vendor_code}_payment`);

      //         axios
      //           .get(getPaymentTermIncoterm(vendor.payment_term_code))
      //           .then((response) => {
      //             this.toggleLoaderArray(`${vendor.vendor_code}_payment`);

      //             console.log(response.data, "payment_term_code response");
      //           })
      //           .catch((err) => {
      //             this.props.openSnackbar(
      //               "error",
      //               err.response?.data?.message
      //                 ? err.response?.data?.message
      //                 : "Something went wrong!"
      //             );
      //             this.toggleLoaderArray(`${vendor.vendor_code}_payment`);
      //             console.log(err);
      //           });
      //       }
      //     }
      //   });
      // }
      // );
    } else {
      this.setState({
        rows: [],
        loaderArray: [],
      });
    }
  }

  handleSave = () => {
    let temp = {};

    this.state.rows.forEach((row) => {
      let obj = {
        element_code: row.inco_term_code || row.payment_term_code,
        element_name: row.inco_term_name || row.payment_term_name,
      };
      temp[row.id] = obj;
    });

    this.props.setTermValue(JSON.stringify(temp), this.props.vendorType);
    if (this.state.error) {
      this.props.setTermError();
    }
    this.props.handleClose();
  };

  render() {
    const { open, handleClose, vendorTerms } = this.props;

    const columns = [
      {
        field: "vendor_name",
        headerName: "VENDOR NAME",
        width: 300,
        // flex: 1,
        editable: false,
        renderCell: (params) => {
          return (
            <React.Fragment>
              <Avatar
                sx={{
                  backgroundColor: (theme) => theme.palette.success.main,
                  width: "34px",
                  height: "34px",
                }}>
                {params.row.vendor_name.slice(0, 1)}
              </Avatar>
              <Box sx={{ lineHeight: "12px", ml: 1 }}>
                <Typography color="text.primary" fontWeight={500}>
                  {params.row.vendor_name}
                </Typography>
                <Typography variant="span" color="grey.500">
                  {params.row.is_approved ? params.row.is_approved : " -"}
                </Typography>
              </Box>
            </React.Fragment>
          );
        },
      },
      {
        field: vendorTerms === "inco" ? "inco_term_name" : "payment_term_name",
        headerName:
          vendorTerms === "inco" ? "Inco Term Name" : "Payment Term Name",
        headerAlign: "left",
        align: "left",
        width: 300,
        // flex: 1,
      },
      {
        field: vendorTerms === "inco" ? "inco_term_code" : "payment_term_code",
        headerName:
          vendorTerms === "inco" ? "Inco Term Code" : "Payment Term Code",
        headerAlign: "left",
        align: "left",
        width: 300,
        // flex: 1,
      },
    ];

    return (
      <div>
        <Dialog
          fullWidth
          scroll="body"
          maxWidth={"md"}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title">
          <DialogTitle id="responsive-dialog-title">
            {vendorTerms === "inco" ? "Inco" : "Payment"} Term Details
            <IconButton
              onClick={this.props.handleClose}
              sx={{
                ml: "auto",
                color: (theme) => theme.palette.bluegrey[500],
              }}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <div style={{ height: 400, margin: "1rem 0" }}>
              <DataGridPro
                columns={columns}
                rows={this.state.rows}
                loading={this.state.loaderArray.length > 0}
                hideFooter
                disableColumnReorder
                slots={{
                  noRowsOverlay: () => {
                    return (
                      <GridOverlay>
                        <Typography>No data found</Typography>
                      </GridOverlay>
                    );
                  },
                }}
                hideFooterPagination
                disableSelectionOnClick
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              autoFocus
              onClick={handleClose}>
              Close
            </Button>
            {/* <Button variant="contained" color="primary"
              onClick={() => {
                if (this.state.loaderArray.length === 0) {
                  // this.props.setTermValue(this.state.rows);
                  this.handleSave();
                }
              }}
            >
              Save
            </Button> */}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  selectedVendors: state.vendorReducer.selectedVendors || [],
  accountlevelInfo: state.permissionsReducer.accountlevelInfo,
});
export default connect(mapStateToProps, {
  setSelectedVendors,
  openSnackbar,
})(Details);
