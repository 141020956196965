import axios from "axios";
import {
    getDropdownOptions
  } from "../../config/apiUrl";

let template_data = {};
export const validatePlant = (ref, template_data1) => {
    let prplants = Array.isArray(ref.props.selectedPrCategory.plant.code) ? ref.props.selectedPrCategory.plant.code :[ ref.props.selectedPrCategory.plant.code ];
    template_data = template_data1;
    check_pr_plants_availability_in_template(ref,template_data1,prplants);
};

const check_plant_in_template = (destinations,prplants,plant_code_key,plant_name_key) => {
    let plant_with_error = [];
    prplants.forEach(prplant=> {
        let filtered_plant_length = 0;
        if(plant_code_key == 'code'){
            filtered_plant_length = destinations.filter(destination=> {
                const plant_arr = destination[ plant_code_key ].split("-");
                return plant_arr[plant_arr.length - 1] == prplant ? 1 : 0
            }).length;
        }
        else{
            filtered_plant_length = destinations.filter(destination=> destination[ plant_code_key ] == prplant).length;
        }
      if (filtered_plant_length == 0){
        plant_with_error.push(prplant);
      }
    });
    return plant_with_error;    
}

const show_plant_missing_error = (ref,plants_with_error,template_name) => {
    let plant = ref.props.selectedPrCategory.plant.code;
    if(plants_with_error.length) {
        plants_with_error.map((plantErr,i)=>{
            if(plant===plantErr || plant.includes(plantErr)){
                ref.setState({
                    validTemplate:false,
                    jsonTemplate:null,
                    jsonTemplateloading: false
                });
                ref.props.openSnackbar("error", `Destination ${plant} does not exists in ${template_name}`);
            }
        })
    } else {
        ref.setState({validTemplate:true})
        //this.props.openSnackbar("success", "Destinations  Matched");
        ref.setState({
            jsonTemplate:template_data.data,
            jsonTemplateloading: false
        });
    }
    // if(plants_with_error.length) {
    //     ref.setState({
    //         validTemplate:false,
    //         jsonTemplate:null,
    //         jsonTemplateloading: false
    //     });
    //     ref.props.openSnackbar("error", `Destination ${plants_with_error.join()} does not exists in ${template_name}`);
    // } else {
    //     ref.setState({validTemplate:true})
    //     //this.props.openSnackbar("success", "Destinations  Matched");
    //     console.log(template_data);
    //     ref.setState({
    //         jsonTemplate:template_data.data,
    //         jsonTemplateloading: false
    //     });
    // }
    
}

const check_and_show_plant_error = (ref,destinations,prplants,plant_code_key,plant_name_key,template_name) => {
    let plants_with_error = check_plant_in_template(
        destinations,prplants,plant_code_key,plant_name_key);
    show_plant_missing_error(ref,plants_with_error,template_name);
}

const check_pr_plants_availability_in_template = (ref,response,prplants) => {
    response.data?.COMMON.map((form_element, index) => {
        
        if(form_element.Identifier === "Destination" || form_element.Identifier === "DestinationForeign"){
            if (form_element.DropdownEdit === "Y") {
                const destinationDropDown = getDropdownOptions(
                "Destination",
                response.data?.PARAM[0]?.template_id
                );
                axios
                .get(destinationDropDown)
                .then((dest) => {
                check_and_show_plant_error(
                    ref,
                    dest.data.data,prplants,'dropdown_element_code','dropdown_element_name',
                    response.data?.PARAM[0]?.template_name);
                })
                .catch((error)=>{
                    if(error.response.status===401){
                        ref.props.openSnackbar("error", "Your Login session could not be validated. Please Login again...");
                      }
                      ref.setState({
                        jsonTemplate:null,
                        jsonTemplateloading: false
                    });
                })
            } else {

                const destinationDropDown = getDropdownOptions(
                    "Destination",
                    response.data?.PARAM[0]?.template_id
                );
                axios
                .get(destinationDropDown)
                .then((dest) => {
                    check_and_show_plant_error(
                        ref,
                    form_element.DefaultValue,prplants,'code','name',
                    response.data?.PARAM[0]?.template_name);
                })
                .catch((error)=>{
                    console.log("catching error");
                    if(error?.response?.status===401){
                        ref.props.openSnackbar("error", "Your Login session could not be validated. Please Login again...");
                    }
                    ref.setState({
                        jsonTemplate:null,
                        jsonTemplateloading: false,
                        template: "",
                
                    });
                })
            }
        } 
        // else {
        //     ref.setState({
        //         jsonTemplate:null,
        //         jsonTemplateloading: false,
        //         template: "",
        //     });       
        // }
    });
}