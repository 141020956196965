import {
  SET_VENDORS,
  SET_VENDORS_LOADING,
  SET_VENDORS_ERROR,
  RESET_VENDORS,
  SET_FILTERED_VENDORS,
  SET_FILTERED_VENDORS_LOADING,
  SET_FILTERED_VENDORS_ERROR,
  RESET_FILTERED_VENDORS,
  SET_ITEMIZED_VENDORS,
  SET_ITEMIZED_VENDORS_LOADING,
  SET_ITEMIZED_VENDORS_ERROR,
  SET_FILTERED_ITEMIZED_VENDORS,
  SET_FILTERED_ITEMIZED_VENDORS_LOADING,
  SET_FILTERED_ITEMIZED_VENDORS_ERROR,
  SET_SELECTED_VENDORS,
  SET_SELECT_ALL_VENDORS,
  VENDOR_MENU,
} from "../constants/type";

const initialState = {
  selectedVendors:[],
  loading: false,
  vendors: [],
  error: "",
  meta: {},
  filteredVendors: [],
  filteredVendorsLoading: false,
  filteredVendorsError: "",
  filteredVendorsMeta: {},
  itemizedVendors: [],
  itemizedVendorsLoading: [],
  itemizedVendorsError: [],
  itemizedVendorsMeta: {},
  filteredItemizedVendors: [],
  filteredItemizedVendorsLoading: false,
  filteredItemizedVendorsError: "",
  filteredItemizedVendorsMeta: {},
  isAllVendorSelected:false,
  vendorMenu: {}
};

const vendorReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_VENDORS_LOADING:
      return { vendors: [], meta: {}, loading: true, error: "" };
    case SET_VENDORS:
      return {
        loading: false,
        vendors: payload.data,
        meta: payload.meta,
        error: "",
      };
    case SET_VENDORS_ERROR:
      return { loading: false, vendors: [], meta: {}, error: payload };

    case RESET_VENDORS:
      return initialState;

    case SET_FILTERED_VENDORS:
      return {
        ...state,
        filteredVendors: payload.data,
        filteredVendorsLoading: false,
        filteredVendorsError: "",
        filteredVendorsMeta: payload.meta,
      };

    case SET_FILTERED_VENDORS_LOADING:
      return {
        ...state,
        filteredVendors: [],
        filteredVendorsLoading: true,
        filteredVendorsError: "",
        filteredVendorsMeta: {},
      };

    case SET_FILTERED_VENDORS_ERROR:
      return {
        ...state,
        filteredVendors: [],
        filteredVendorsLoading: false,
        filteredVendorsError: payload,
        filteredVendorsMeta: {},
      };

    case RESET_FILTERED_VENDORS:
      return {
        ...state,
        filteredVendors: [],
        filteredVendorsLoading: false,
        filteredVendorsError: "",
        filteredVendorsMeta: {},
      };

    case SET_ITEMIZED_VENDORS_LOADING:
      return {
        ...state,
        itemizedVendors: [],
        itemizedVendorsLoading: true,
        itemizedVendorsError: "",
        itemizedVendorsMeta: {},
      };
    case SET_ITEMIZED_VENDORS:
      return {
        ...state,
        itemizedVendors: payload.data,
        itemizedVendorsLoading: false,
        itemizedVendorsError: "",
        itemizedVendorsMeta: payload.meta,
      };
    case SET_ITEMIZED_VENDORS_ERROR:
      return {
        ...state,
        itemizedVendors: [],
        itemizedVendorsLoading: false,
        itemizedVendorsError: payload,
        itemizedVendorsMeta: {},
      };
    case SET_FILTERED_ITEMIZED_VENDORS:
      return {
        ...state,
        filteredItemizedVendors: payload.data,
        filteredItemizedVendorsLoading: false,
        filteredItemizedVendorsError: "",
        filteredItemizedVendorsMeta: payload.meta,
      };
    case SET_FILTERED_ITEMIZED_VENDORS_LOADING:
      return {
        ...state,
        filteredItemizedVendors: [],
        filteredItemizedVendorsLoading: true,
        filteredItemizedVendorsError: "",
        filteredItemizedVendorsMeta: {},
      };
    case SET_FILTERED_ITEMIZED_VENDORS_ERROR:
      return {
        ...state,
        filteredItemizedVendors: [],
        filteredItemizedVendorsLoading: false,
        filteredItemizedVendorsError: payload,
        filteredItemizedVendorsMeta: {},
      };
    case SET_SELECTED_VENDORS:
      return {
        ...state,
        selectedVendors:[...payload]
      };
      case SET_SELECT_ALL_VENDORS:
        return {
          ...state,
          isAllVendorSelected:payload
        };
      case VENDOR_MENU:
        return {
          ...state,
          vendorMenu: payload
        }
    default:
      return {
        ...state
      }
  }
};

export default vendorReducer;
