//rfqMenuItems.js

export const r2a_Action = "r2a_action";

export const action_code = "load_r2a_report";

export const calling_from = "10";

export const r2a_view_type = "normal";

export const seal_bid_error_flag = "undefined";

export const view_Inquiry_Logs_Action = "view_inquiry_logs";

export const send_RFQ_to_new_vendor = "select_new_vendors_to_resend_am";

export const qcs_plus = "undefined";

export const resend_from_qplus = "undefined";

export const master_type = "category";

export const product_id = "categoryId";

export const t_type = "Inquiry";

export const vendx_action = "edit_reminders";

export const multiple_solution_action = "ms_open_view_qplus";

export const ms_view_caller = "transaction_history";

export const extend_validy_thread_details = "extend_validy_thread_details";
