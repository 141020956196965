import GlobalStyles from "@mui/material/GlobalStyles";
import * as React from 'react';

export const LinkVendorToItemStyle = (
    <GlobalStyles
    styles={(theme) => ({     
        '&.ItemListGrid':{
            '.MuiDataGrid-columnHeaders': {
                border: 0,
                background:"transparent !important",
                color:"var(--grey-500)"
            },
            '.MuiDataGrid-row': {
                background: theme.palette.bluegrey[0],
                borderRadius: '4px',
                width: '99.75% !important',                
            },                       
            '.MuiDataGrid-row [class*="MuiDataGrid-cell"]:first-of-type': {
                borderLeft: '1px solid var(--bluegrey-500)',       
                borderTopLeftRadius: '4px',
                borderBottomLeftRadius: '4px',
                padding: '0 6px',
            },
            '.MuiDataGrid-row [class*="MuiDataGrid-cell"]:last-child': {
                borderRight: `1px solid var(--bluegrey-500)`,
                borderTopRightRadius: '4px',
                borderBottomRightRadius: '4px',
            },
            '.MuiDataGrid-row [class*="MuiDataGrid-cell"]': {
                borderTop: `1px solid var(--bluegrey-500)`,
                borderBottom: `1px solid var(--bluegrey-500)`,
            },
        },
        "&.VendorDtls-Box > .MuiBox-root":{
            overflow:"hidden"
        },
        ".ItemListGrid":{            
            height: "340px",
            width: "100%",
            [theme.breakpoints.down("sm")]: {
                height:"250px",
            }
        },
        ".PlantItemList":{
            height:"100%",
            overflow:"auto",
            background:"var(--grey-50)",
            border:"1px solid var(--bluegrey-500)",
            borderRadius:"4px",
            [theme.breakpoints.down("sm")]: {
                height:"250px",
            },
            ".MuiListItem-root":{
                backgroundColor:"var(--secondary-600)",      
                borderBottom:"1px solid var(--secondary-500)"
            },
            "li.MuiListItem-root:last-child":{
                borderRadius:"0 0 3px 3px",
                borderBottom:"none"
            },
            "li.MuiListItem-root:first-child":{
                borderRadius:"3px 3px 0 0",
            }                                 
        },
        ".noItemSelect": {
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            gap: "24px",
            height: "100%",
            justifyContent: "center"
        },
        ".DestList-Popover":{
            ".MuiPaper-root":{
                borderRadius:"5px",
            },
            ".MuiList-root":{
                width:"300px",
                maxHeight: "365px",
                overflowY: "auto"
            },
            ".MuiListItemText-root":{
                margin:"0"
            },
            ".MuiListSubheader-root":{
                background:"var(--grey-100)",
                color:"var(--secondary-700)",
                lineHeight:"36px",
                display:"flex",
                alignItems:"center"
            }
        }
          
    })}
    />
)




