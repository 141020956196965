import React, { Component, createRef } from "react";
// import "../../pages/style.css";
import { CustomDropzone, CustomsmallDialog } from "./style";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Tooltip,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { CloudDownloadOutlined, DeleteOutline, RemoveCircleRounded } from "@mui/icons-material/";
import documentIconSVG from "../../../assets/images/document-icon.svg"

import LinearProgress from "@mui/material/LinearProgress";
import { IconButton, Backdrop, CircularProgress } from "@mui/material";
import axios from "axios";
import {
  deleteFileSpecifications,
  getFileSpecifications,
  getFileSpecificationsDownloadLink,
  postFileSpecifications,
} from "../../../config/apiUrl";
import { openSnackbar } from "../../../redux/actions/snackbarAction";
import { connect } from "react-redux";
import { setFileSpecification } from "../../../redux/actions/categoryAction";
import { setItemSpecifications } from "../../../redux/actions/itemActions";
class UploadFileDialog extends Component {
  constructor(props) {
    super(props);
    this.descriptionElementRef = createRef(null);
  }
  state = {
    selectedFiles: [],
    lastIndex: null,
    percentage: 0,
    loading: false,
    fetchLoading: false,
    uploadedFiles: [],
    downloadLoading: false,
    deleteLoading: false,
    totalFileBucketSize: 0,
    minimumFileSize:1024, //1024 bytes = 1KB
    // maximumFileBucketSize:8388608, // 8388608 bytes = 8192KB = 8MB
    maximumFileBucketSize:26214400,
    updateinMaster: false,
    uploadingFiles:[]
  };

  static getDerivedStateFromProps(props, state) {
    if (props.currentItemIndex !== state.lastIndex) {
      //reset, and update last index
      return {
        selectedFiles: [],
        lastIndex: props.currentItemIndex,
        uploadedFiles : props.Alreadyexistingfiles,
        totalFileBucketSize : props.Alreadyexistingfiles.reduce((accumulator, file) => {
          return accumulator + file.file_info.FileSize;
        }, 0)
      };
    } else {
      return state;
    }
  }

  componentDidMount() {
    // this.fetchUploadedFiles();
    // in order to wait for the element to be actually drawn, using setTimeout
    setTimeout(() => {
      if (this.descriptionElementRef.current !== null) {
        this.descriptionElementRef.current.focus();
      }
    }, 0);
  }

  handleFilesChange = (newFile) => {
    const { totalFileBucketSize } = this.state;
    let finalSize = 0;
    let invalidFiles = [];
    let index = 0;
    newFile.forEach((file) => {
      if(file.size < this.state.minimumFileSize){
        this.props.openSnackbar("error", `${file.name}(${this.formatBytes(file.size)}) size is less that ${this.formatBytes(this.state.minimumFileSize)}'`);
        invalidFiles.push(index);
      } else if ((this.state.selectedFiles.length > 0  && this.state.selectedFiles.some(item => (item.name == file.name && item.size == file.size))) ||
             (this.state.uploadedFiles.some((item) =>  item.file_user_name === file.name &&
             item.file_info.FileSize === file.size) )) {
        this.props.openSnackbar("error", `${file.name} already exists`);
        invalidFiles.push(index);
      }
      else{
        finalSize = finalSize + file.size;
      }
      index++;
    });

    invalidFiles.forEach((cv) => {
      newFile.splice(cv,1);
    });
    if(totalFileBucketSize + parseFloat(finalSize) < this.state.maximumFileBucketSize ) {
      this.setState({
        totalFileBucketSize : totalFileBucketSize + finalSize,
        selectedFiles: [...this.state.selectedFiles, ...newFile],
      });  
    }
    else{
      this.props.openSnackbar("error", "File upload bucket size cannot be greater than 25 MB.");
    }
  };
 
  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
  handleDelete = (index) => {
    const { selectedFiles, totalFileBucketSize } = this.state;
    let removeFile = selectedFiles[index];
    let newBucketSize = totalFileBucketSize - removeFile.size;
    selectedFiles.splice(index, 1);
    this.setState({
      selectedFiles: selectedFiles,
      totalFileBucketSize: newBucketSize,
    });
  };

  fetchUploadedFiles = () => {
    this.setState({
      fetchLoading: true,
    });
    const url = getFileSpecifications(
      this.state.uploadingFiles
    );
    axios
      .get(url)
      .then((res) => {
        let totalUploadFilesSize = 0;
        let all_uploaded_files = [...this.state.uploadedFiles, ...res.data.data];
        all_uploaded_files.forEach(file => {
          totalUploadFilesSize += file.file_info.FileSize;
        });
        this.setState({
          uploadedFiles: all_uploaded_files,
          fetchLoading: false,
          totalFileBucketSize: totalUploadFilesSize,
          selectedFiles : [],
          uploadingFiles:[]
        });
        if(this.props.master_type === "category"){
          this.props.setFileSpecification(all_uploaded_files)
          this.props.updateCategorySpecificationFiles(all_uploaded_files);
        }else{
          this.props.setItemSpecifications({[this.props.product_id]:all_uploaded_files})
        }
      })
      .catch((err) => {
        this.setState({
          fetchLoading: false,
        });
        console.log(err, " getfilespecs error");
      });
  };

  uplaodFile = async () => {
    if (!this.state.selectedFiles.length) {
      this.props.openSnackbar("error", "No files selected!");
      return;
    }
    this.setState({ loading: true });
    let formData = new FormData();
    this.state.selectedFiles.forEach( file => {
        formData.append('files[]', file);
    });
    formData.append('product_id', this.props.product_id);
    formData.append('transpecific', 'YES');
    formData.append('update_master', this.state.updateinMaster ? "YES" : "NO");
    formData.append('transaction_type', 'INQ-RA');
    formData.append('master_type', this.props.master_type);
    const options = {
      onUploadProgress: (ProgressEvent) => {
        const { loaded, total } = ProgressEvent;
        let perc = Math.floor((loaded * 100) / total);

        if (perc < 100) {
          this.setState({ percentage: perc });
        }
      },
    };
    axios.defaults.headers.common["Authorization"] = `Bearer ${this.props.sessionId}`;
    axios.defaults.headers.common['Content-Type']  = 'multipart/form-data';

    this.setState({ loading: true });

      axios
        .post(postFileSpecifications, formData, options)
        .then((res) => {
          this.props.openSnackbar("success", "Uploaded Successfully");
          console.log("success = ",res.data.files);
        
          this.setState(
            {
              percentage: 100,
              loading: false,
              uploadingFiles: res.data.files,
              updateinMaster: false
            },
           
            () => {
              this.fetchUploadedFiles();
              setTimeout(() => {
                this.setState({
                  percentage: 0,
                });
              }, 1000);
            }
          );
        })
        .catch((err) => {
          this.setState({
            loading: false,
            percentage: 0,
          });
  
          if (err.response) {
            if (err.response?.data?.validation_errors) {
              let errorObj = err.response.data.validation_errors;
              let errorMsg = "";
              for (const key in errorObj) {
                errorObj[key].forEach((msg) => {
                  errorMsg = `${errorMsg && `${errorMsg}, `}${msg}`;
                });
              }
              this.props.openSnackbar("error", errorMsg);
            } else if (err.response.status === 500) {
              this.props.openSnackbar(
                "error",
                "Internal Server Error. Please try again later."
              );
            } else if (err.response.status === 503) {
              this.props.openSnackbar(
                "error",
                "Service temporarily unavailable. Please try again later."
              );
            } else {
              this.props.openSnackbar("error", `Something went wrong!`);
            }
          } else {
            this.props.openSnackbar("error", "Network Error");
          }
  
          console.log(err, "upload error");
        });
   
  };

  downloadFile = (file_id, file_user_name) => {
    this.setState({
      downloadLoading: true,
    });
    const url = getFileSpecificationsDownloadLink(
      file_id,
      this.props.product_id,
      this.props.master_type
    );
    axios
      .get(url)
      .then((res) => {
        if (res.data.download_link) {
          let a = document.createElement("a");
          a.href = res.data.download_link;
          a.download = file_user_name;
          a.click();
          this.setState({
            downloadLoading: false,
          });
        }
      })
      .catch((err) => {
        console.log(err, " downloadFile error");
        this.props.openSnackbar(
          "error",
          err.response.data?.message
            ? err.response.data?.message
            : "Something went wrong!"
        );
        this.setState({
          downloadLoading: false,
        });
      });
  };

  handleUploadedDelete = (file_id) => {
    this.setState({
      deleteLoading: true,
    });
    const url = deleteFileSpecifications;
    let payload = {
      file_ids: [file_id],
      update_master: this.state.updateinMaster ? 10 : 20,
      master_type: this.props.master_type,
      product_id: this.props.product_id
    };
    axios
      .put(url, payload)
      .then((res) => {
        this.state.uploadedFiles.forEach((file, index) => {
          if (file.id == file_id) {
            this.state.uploadedFiles.splice(index, 1);
          }
        });
        this.props.openSnackbar("success", "file deleted successfully");
        this.props.setItemSpecifications({[this.props.product_id]:this.state.uploadedFiles})
        this.setState({
          deleteLoading: false,
          uploadedFiles: this.state.uploadedFiles,
          updateinMaster: false,
          totalFileBucketSize: this.state.uploadedFiles.reduce((accumulator, file) => {
            return accumulator + file.file_info.FileSize;
          }, 0)
        });
      })
      .catch((err) => {
        console.log(err, " handleUploadedDelete error");
        // this.props.openSnackbar("error", "Something went wrong");
        this.props.openSnackbar(
          "error",
          err.response.data?.message
            ? err.response.data?.message
            : "Something went wrong!"
        );
        this.setState({
          deleteLoading: false,
        });
      });
  };

  render() {
    return (
      <div>
        <Backdrop
          style={{ color: "#fff", zIndex: "10000000000" }}
          open={
            this.state.fetchLoading ||
            this.state.downloadLoading ||
            this.state.deleteLoading
          }
        >
          <CircularProgress style={{ color: "#fff" }} />
        </Backdrop>
        <Grid container>
          <Grid item lg={12}>
            <CustomsmallDialog
              scroll="body"
              maxWidth={"sm"}
              open={this.props.open}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogTitle
                ref={this.descriptionElementRef}
                sx={{
                  '&:focus-visible': {
                    border: 'none',
                    outline: 'none'
                  }
                }}
                tabIndex={-1}
              >
              Upload Transaction Specifications
              <Box className="dialog-title-action">
                <IconButton
                  sx={{
                   color: (theme) => theme.palette.bluegrey[500],
                  }}
                  onClick={this.props.handleClose}>
                  <CloseIcon />
                </IconButton>
              </Box>               
              </DialogTitle>
              <DialogContent>                
                  <div className="dropzone">
                    <CustomDropzone
                      accept=".xls, .xlsx, .jpg, .jpeg, .csv, .doc, .txt, .pdf, .bmp, .7z, .rar, .docx, .ods"
                      onDrop={(acceptedFiles) => {
                        this.handleFilesChange(acceptedFiles);
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <div className="dz-message document_upload_main">
                            <div className="drop_zone_area">
                              <Typography variant="p">
                                <CloudUploadIcon sx={{mr: 1,}} /> Drag and Drop or <span>Browse</span> to upload
                              </Typography>
                            </div>
                          </div>
                        </div>
                      )}
                    </CustomDropzone>
                  </div>
                    <LinearProgress
                      sx={{marginBottom:2, marginTop: 2, borderRadius: 5,}}
                      variant="determinate"
                      value={this.state.percentage}
                    />
                  <List className="uploadfile_list upload_file_scroll">
                    {this.state.selectedFiles.length > 0 && (
                      <>
                        {this.state.selectedFiles.map((file, index) => (
                          <>                                  
                            <ListItem key={index}  className="newfile"
                            secondaryAction={
                              <IconButton color="error"
                              onClick={() => this.handleDelete(index)}
                              size="medium"
                              >
                              <RemoveCircleRounded />
                              </IconButton>
                            }>
                              <ListItemIcon>
                                <img src={documentIconSVG} />
                              </ListItemIcon>
                              <ListItemText primary={file.name} secondary={`${this.formatBytes(file.size)}`} />
                            </ListItem>
                        </>
                        ))}
                      </>
                    ) 
                    }
                    {this.state.uploadedFiles.length > 0 && (
                      <div>                        
                         <Typography variant="p"sx={{ 
                            textTransform: 'uppercase',
                            color: (theme)=> theme.palette.bluegrey[600],
                            fontWeight:500,
                            marginBottom: '16px',
                          }}>Uplaoded Files</Typography>
                        {this.state.uploadedFiles.map((file, index) => {
                          return (                        
                        
                            <ListItem secondaryAction={
                              <>
                              <Tooltip title="Download">
                                <IconButton
                                  sx={{mr:1, color: (theme)=> theme.palette.grey[600] }}
                                  disabled={!file.file_info.isValid || !file.id}
                                  size="small"
                                    onClick={() => {
                                      this.downloadFile(
                                        file.id,
                                        file.file_user_name
                                      );
                                    }}
                                  >
                                  <CloudDownloadOutlined/>
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete">
                                  <IconButton  sx={{color: (theme)=> theme.palette.grey[600] }}
                                    onClick={() => {
                                      this.handleUploadedDelete(file.id);
                                    }}
                                    size="small"
                                  >
                                    <DeleteOutline />
                                  </IconButton>
                                </Tooltip>
                              </>                             
                            }>
                              <ListItemIcon>
                                <img src={documentIconSVG} />
                              </ListItemIcon>
                              <ListItemText
                              primary= {
                                <React.Fragment>
                                <Tooltip title={file.file_user_name}>  
                                <Typography                                
                                  component="p"                                  
                                  color="text.primary"
                                  noWrap
                                  className="filename-text"                                  
                                >
                               {file.file_user_name}
                                </Typography> 
                                </Tooltip>             
                                </React.Fragment>
                                }
                                secondary= {
                                  <React.Fragment>
                                  <Typography         
                                    component="span"                                   
                                    noWrap
                                    className="filesize-text"
                                  >
                                  {this.formatBytes(file.file_info.FileSize)}
                                  </Typography>              
                                  </React.Fragment>
                                  }
                             />
                            </ListItem>
                          );
                        })}
                      </div>
                    )}
                   
                  </List>   
                  <FormControlLabel
                    sx={{ '& .MuiTypography-root':{ fontWeight:500,} }}
                      control={
                        <Checkbox
                          color="primary"
                          onChange={(e) => this.setState({updateinMaster : e.target.checked})}
                          checked={this.state.updateinMaster}
                        />
                      }
                      label={`Update Specifications in ${this.props.master_type?.charAt(0).toUpperCase() + this.props.master_type?.slice(1)} Master.`}
                  />
                  <Typography sx={{fontSize: '14px', fontWeight:500, mt:2}}> File size should be between 1KB to 25MB. </Typography>

<Typography sx={{fontSize: '12px', color: 'grey.500'}}> Note: The file should be of one of the following file types(*.jpg,*.jpeg,*.xls,*.xlsx,*.csv,*.tsv,*.doc,*.txt,*.pdf,*.bmp,*.7z,*.eml,*.rar, *.docx,*.cdr, *.ods) </Typography>             
              </DialogContent>
              <DialogActions>
              <Button variant="outlined" color="primary"
                  onClick={this.props.handleClose}                  
                  autoFocus
                >
                  Cancel
                </Button>
                <Button variant="contained" color="primary" onClick={this.uplaodFile} autoFocus disabled={this.state.loading}>
                  Add File
                </Button>
              </DialogActions>
            </CustomsmallDialog>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sessionId: state.sessionReducer.sessionId,
});

const mapDispatchToProps = (dispatch) =>{

    return {
      // dispatching plain actions
      uploadedFileIds: (files) => dispatch(setFileSpecification(files))

    }
}
export default connect(mapStateToProps,{
  openSnackbar,
  setFileSpecification,
  setItemSpecifications
})(UploadFileDialog);
