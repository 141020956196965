import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import axios from "axios";
import {
  Box,
  Typography,
  Stack,
  ButtonGroup,
  IconButton,
  Chip,
  Avatar,
  Divider,
  Tooltip,
  Button,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {
  DataGridPro,
  GridToolbarQuickFilter,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useNavigate } from "react-router-dom";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import { ReactComponent as UserPreference } from "../../../assets/RFQList/images/user-preference.svg";
import {
  FileCopyOutlined,
  MoreVert,
  SaveOutlined,
  Tune,
} from "@mui/icons-material";
import VendorMenuItems from "../../../utils/RFQList/vendorMenuItems";
import SelectGridView from "../../../common/RFQList/selectGridView";
import AccountDateRangePicker from "../../../utils/RFQList/accountDatePicker";

const PageToolbar = styled("div")(({ theme }) => ({
  background: "#ffffff",
  position: "sticky",
  top: 0,
  width: "100%",
  minHeight: "54px",
  padding: "6px 16px",
  zIndex: "99",
}));

function CustomGridToolbar() {
  return (
    <React.Fragment>
      <GridToolbarContainer className="datagrid-toolbar">
        <Grid
          container
          spacing={0}
          sx={{
            width: "100%",
            pt: 1,
            "&.MuiGrid2-root": {
              paddingTop: { xs: "0", sm: "0", md: "0", alignItems: "center" },
            },
          }}>
          <Grid xs={8} sm={8} md={3} xl={4}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Box
                className="QsearchBox"
                sx={{
                  "&.QsearchBox": {
                    maxWidth: "480px",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 1,
                    borderRadius: 1,
                    paddingLeft: 1,
                    paddingRight: 1,
                    background: "var(--grey-50)",
                    ":hover": {},
                    '& .DataGrid-SearchBar[aria-label="Search"]': {
                      maxWidth: "100%",
                    },
                    "@media screen and (max-width:991px)": {
                      "&.QsearchBox": {
                        maxWidth: "100%",
                      },
                    },
                  },
                }}>
                <GridToolbarQuickFilter
                  className="DataGrid-SearchBar"
                  sx={{ pb: 0 }}
                />
                <IconButton
                  variant=""
                  color="default"
                  size="small"
                // onClick={() => OpenSearchFilterModal()}
                >
                  <Tune />
                </IconButton>
              </Box>
            </Box>
          </Grid>
          <Grid
            xs={4}
            sm={4}
            sx={{ display: { xs: "flex", sm: "flex", md: "none" } }}>
            <SelectGridView />
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={9}
            xl={8}
            sx={{ display: { xs: "none", sm: "none", md: "flex" } }}>
            <Box className="toolbar-right">
              <Box>
                <AccountDateRangePicker />
              </Box>
              <Box className="dg-filter">
                <GridToolbarColumnsButton />
                <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
                <GridToolbarFilterButton />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </GridToolbarContainer>
      <GridToolbarContainer
        className="datagrid-toolbar"
        sx={{ display: { sm: "flex", md: "none" } }}>
        <Grid
          container
          spacing={0}
          sx={{ width: "100%", alignItems: "center" }}>
          <Grid xs={6} md={6}>
            <Box>
              <AccountDateRangePicker />
            </Box>
          </Grid>
          <Grid
            xs={6}
            md={6}
            sx={{ textAlign: { xs: "right", sm: "right" } }}>
            <Box className="dg-filter">
              <GridToolbarColumnsButton />
              <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
              <GridToolbarFilterButton />
            </Box>
          </Grid>
        </Grid>
      </GridToolbarContainer>
    </React.Fragment>
  );
}

export function CustomExpandIcon() {
  return <AddIcon className="icon" />;
}

export function CustomCollapseIcon() {
  return <RemoveIcon className="icon" />;
}

function VendorViewGrid() {
  const [data, setData] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedId, setSelectedId] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  const vendorNameCell = (params) => {
    const { value } = params;
    const myNameFirstLetter = value.split("", 1);
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ margin: 1 }}>
          <Avatar sx={{ color: "white", bgcolor: "var(grey[100])" }}>
            {myNameFirstLetter}
          </Avatar>
        </Box>
        <Tooltip title={value}>
          <Box sx={{ width: "100%" }}>
            <Typography variant="subtitle2">{value}</Typography>
            <Typography variant="body1">{value}</Typography>
          </Box>
        </Tooltip>
      </Box>
    );
  };

  const rfqStatusCell = (params) => {
    const value = params.row.quoted_status;

    // Custom formatting logic
    return (
      <div>
        {value === "Quoted" && (
          <Chip label="Quoted" variant="soft" color="success" />
        )}
        {value === "Pending" && (
          <Chip label="Pending" variant="soft" color="warning" />
        )}
        {value === "Rejected" && (
          <Chip label="Rejected" variant="soft" color="error" />
        )}
        {value === "ReQuoted" && (
          <Chip label="ReQuoted" variant="soft" color="success" />
        )}
      </div>
    );
  };

  const GroupedIcon = (params) => {
    const value = params.row.tb_icon;
    return (
      <Box sx={{ display: "flex" }}>
        <Typography sx={{ marginRight: "4px" }}>
          {value === "yes" ? (
            <Chip
              label="TB"
              color="default"
              variant="soft-outlined"
              size="xsmall"
            />
          ) : (
            <Chip
              label="TB"
              color="primary"
              variant="soft-outlined"
              size="xsmall"
            />
          )}
        </Typography>
        <Typography sx={{ marginRight: "4px" }}>
          {value === "yes" ? (
            <Chip
              label="A"
              color="primary"
              variant="soft-outlined"
              size="xsmall"
            />
          ) : (
            <Chip
              label="A"
              color="default"
              variant="soft-outlined"
              size="xsmall"
            />
          )}
        </Typography>
        <Typography sx={{ marginRight: "4px" }}>
          {value === "yes" ? (
            <Chip
              label="G"
              color="default"
              variant="soft-outlined"
              size="xsmall"
            />
          ) : (
            <Chip
              label="G"
              color="success"
              variant="soft-outlined"
              size="xsmall"
            />
          )}
        </Typography>
      </Box>
    );
  };

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
    setSelectedId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns = [
    {
      field: "1",
      headerName: "",
      width: 20,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <MoreVert
            fontSize="small"
            color="black"
            sx={{
              cursor: "pointer",
            }}
            onClick={(e) => handleClick(e, params.row.id)}
          />
        );
      },
    },
    {
      field: "vendor_Name",
      headerName: "VENDOR NAME",
      width: 180,
      renderCell: vendorNameCell,
    },
    {
      field: "quoted_status",

      headerName: "QUOTED STATUS",
      width: 140,
      renderCell: rfqStatusCell,
    },
    {
      field: "status",
      headerName: "VENDOR STATUS",
      width: 120,
      renderCell: GroupedIcon,
    },
    {
      field: "country",
      headerName: "COUNTRY",
      width: 110,
    },
    {
      field: "industry_type",
      headerName: "INDUSTRY TYPE",
      width: 110,
    },
    {
      field: "last_quotation_ref",
      headerName: "LAST QUOTATION REF #",
      width: 140,
    },
    {
      field: "date_of_last_quote",

      headerName: "DATE OF LAST QUOTE",
      width: 140,
    },
    {
      field: "transaction_status",
      headerName: "TRANSACTION STATUS",
      width: 140,
    },
  ];

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("http://192.168.1.250:5000/menuItems");
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "100%",
        padding: 0,
      }}>
      <Box className="DataGFullScreen-wrap">
        <PageToolbar className="datagrid-PageToolbar head-datagrid-toolbar">
          <Stack spacing={2} direction="row" alignItems={"center"}>
            <Typography className="app-pagetitle">Title Name</Typography>
            <Stack spacing={1} direction="row" alignItems={"center"} sx={{ ml: "auto !important", display: { xs: 'none', sm: 'none', md: 'inline-flex' } }}>
              <SelectGridView />
              <UserPreference />
            </Stack>
          </Stack>
        </PageToolbar>
        <DataGridPro
          rows={data}
          columns={columns}
          headerHeight={40}
          columnHeaderHeight={40}
          getRowHeight={() => "25px"}
          getRowId={(row) => row.id}
          pagination
          slots={{
            toolbar: CustomGridToolbar,
          }}
          className="fullScreenGrid"
          sx={{
            "&.MuiDataGrid-root": {
              border: 0,
            },
            "& .MuiDataGrid-virtualScrollerContent--overflowed .MuiDataGrid-row--lastVisible .MuiDataGrid-cell":
            {
              borderBottomColor: "#a6c1d3",
            },
          }}
        />
      </Box>
      <VendorMenuItems
        openMenu={openMenu}
        handleClose={handleClose}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      />
    </Box>
  );
}

export default VendorViewGrid;
