import {
  SET_VENDORS,
  SET_VENDORS_LOADING,
  SET_VENDORS_ERROR,
  RESET_VENDORS,
  SET_FILTERED_VENDORS_LOADING,
  SET_FILTERED_VENDORS,
  SET_FILTERED_VENDORS_ERROR,
  RESET_FILTERED_VENDORS,
  SET_ITEMIZED_VENDORS,
  SET_ITEMIZED_VENDORS_LOADING,
  SET_ITEMIZED_VENDORS_ERROR,
  SET_FILTERED_ITEMIZED_VENDORS_LOADING,
  SET_FILTERED_ITEMIZED_VENDORS,
  SET_FILTERED_ITEMIZED_VENDORS_ERROR,
  SET_SELECTED_VENDORS,
  SET_SELECT_ALL_VENDORS,
  VENDOR_MENU,
} from "../constants/type";
import {
  getAllLinkedVendors,
  getFilteredItemizedVendors,
  getFilteredVendors,
  getLinkedItemizedVendors,
} from "../../config/apiUrl";
import axios from "axios";
import { debounce } from "lodash";

export const getVendors = (category, page, sessionId) => (dispatch) => {
  dispatch({
    type: SET_VENDORS_LOADING,
  });
  axios.defaults.headers.common["Authorization"] = `Bearer ${sessionId}`;
  // axios.defaults.headers.common[
  //   "Authorization"
  // ] = `Bearer 1623996009138030.702506846885026`;
  const url = getAllLinkedVendors(category.id, page);
  axios
    .get(url)
    .then((res) => {
      dispatch({
        type: SET_VENDORS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      if (err.response?.data.message) {
        dispatch({
          type: SET_VENDORS_ERROR,
          payload: err.response.data.message,
        });
      } else {
        dispatch({
          type: SET_VENDORS_ERROR,
          payload: err.message,
        });
      }
    });
};

export const filterVendors =
  (
    category,
    vendor_name = "",
    vendor_code = "",
    country = "",
    industry_type = "",
    page = 1,
    sessionId
  ) =>
  (dispatch) => {
    dispatch({
      type: SET_FILTERED_VENDORS_LOADING,
    });
    axios.defaults.headers.common["Authorization"] = `Bearer ${sessionId}`;
    // axios.defaults.headers.common[
    //   "Authorization"
    // ] = `Bearer 1623996009138030.702506846885026`;
    const url = getFilteredVendors(
      category.id,
      vendor_name.trim(),
      vendor_code.trim(),
      country.trim(),
      industry_type.trim(),
      page
    );
    axios
      .get(url)
      .then((res) => {
        dispatch({
          type: SET_FILTERED_VENDORS,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.data.message) {
          dispatch({
            type: SET_FILTERED_VENDORS_ERROR,
            payload: err.response.data.message,
          });
        } else {
          dispatch({
            type: SET_FILTERED_VENDORS_ERROR,
            payload: err.message,
          });
        }
      });
  };
export const resetVendors = () => (dispatch) => {
  dispatch({
    type: RESET_VENDORS,
  });
};
export const resetFilteredVendors = () => (dispatch) => {
  dispatch({
    type: RESET_FILTERED_VENDORS,
  });
};
export const getItemizedVendors =
  (item, categoryID, page = 1, sessionId) =>
  (dispatch) => {
    dispatch({
      type: SET_ITEMIZED_VENDORS_LOADING,
    });
    axios.defaults.headers.common["Authorization"] = `Bearer ${sessionId}`;
    // axios.defaults.headers.common[
    //   "Authorization"
    // ] = `Bearer 1623996009138030.702506846885026`;
    const url = getLinkedItemizedVendors(item.id, categoryID, page);
    axios
      .get(url)
      .then((res) => {
        dispatch({
          type: SET_ITEMIZED_VENDORS,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.data.message) {
          dispatch({
            type: SET_ITEMIZED_VENDORS_ERROR,
            payload: err.response.data.message,
          });
        } else {
          dispatch({
            type: SET_ITEMIZED_VENDORS_ERROR,
            payload: err.message,
          });
        }
      });
  };
export const filterItemizedVendors =
  (
    item,
    categoryID,
    vendor_name = "",
    vendor_code = "",
    country = "",
    industry_type = "",
    page = 1,
    sessionId
  ) =>
  (dispatch) => {
    dispatch({
      type: SET_FILTERED_ITEMIZED_VENDORS_LOADING,
    });
    axios.defaults.headers.common["Authorization"] = `Bearer ${sessionId}`;
    // axios.defaults.headers.common[
    //   "Authorization"
    // ] = `Bearer 1623996009138030.702506846885026`;
    const url = getFilteredItemizedVendors(
      item.id,
      categoryID,
      vendor_name.trim(),
      vendor_code.trim(),
      country.trim(),
      industry_type.trim(),
      page
    );
    axios
      .get(url)
      .then((res) => {
        dispatch({
          type: SET_FILTERED_ITEMIZED_VENDORS,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.data.message) {
          dispatch({
            type: SET_FILTERED_ITEMIZED_VENDORS_ERROR,
            payload: err.response.data.message,
          });
        } else {
          dispatch({
            type: SET_FILTERED_ITEMIZED_VENDORS_ERROR,
            payload: err.message,
          });
        }
      });
  };

export const setSelectedVendors = (updatedVendors) => (dispatch) => {
  update(updatedVendors,dispatch);
};

const update = debounce((updatedVendors,dispatch) => {
  dispatch({
    type: SET_SELECTED_VENDORS,
    payload: updatedVendors,
  });
}, 1000);

export const setSelectAllVendors = (payload) => (dispatch) => {
  dispatch({
    type: SET_SELECT_ALL_VENDORS,
    payload:payload
  });
};

export const setVendorMenu = (payload) => (dispatch) => {
  dispatch({
    type: VENDOR_MENU,
    payload:payload
  });
}