import GlobalStyles from "@mui/material/GlobalStyles";
import * as React from 'react';

export const RFQDataGStyle = (
    <GlobalStyles
      styles={(theme) => ({
          '& .trasaction-drawer':{
            '& .MuiDrawer-paper': {
              width: '600px',     
              boxSizing: 'border-box',
              [theme.breakpoints.down("md")]: {
                width: '100%',
              }
            },
          },
          '.MuiDialog-paperWidthSm .MuiPickersLayout-root .MuiList-root':{
              width: '320px',
              overflowX: 'auto'
              },
              '.MuiPaper-root .MuiList-root .MuiMenuItem-root':{
              fontSize: '14px',
              '& .MuiSvgIcon-root':{
                  marginRight:'3px'
              }
          },
          "& .QsearchBox": {
            // maxWidth: "480px",
            // width: "calc(480px - 64px)",
            width:"480px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 1,
            borderRadius: 1,
            paddingLeft: 1,
            paddingRight: 1,
            background: "var(--grey-50)",
            ":hover": {},
            "& .DataGrid-SearchBar[aria-label='Search']": {
              maxWidth: "100% !important",
            },
            "& .DataGrid-SearchBar > .MuiInputBase-root > .MuiSvgIcon-root":{
              margin:"0 4px 0 6px",
              color: "var(--grey-600)"
            },
            "@media screen and (max-width:991px)": {
              "&.QsearchBox": {
                // width: "calc(100% - 64px)",
                width: "100%",
              },
            },
          },
      })}
    />
)