import * as React from 'react';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import { Divider, IconButton, Menu, MenuItem, Modal, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        md:"70%",
        xs:"100%"
    },
    "& video":{
        outline:"none"
    }
};

export default function HelpActionButtons() {
    const [langMenuAnchorEl, setLangMenuAnchorEl] = React.useState(null);
    const open = Boolean(langMenuAnchorEl);
    const handleClick = (event) => {
        setLangMenuAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setLangMenuAnchorEl(null);
    };
    
    const [openVideo, setOpenVideo] = React.useState(false);
    const [videoUrl, setVideoUrl] = React.useState('');
    const handleOpenVideo = (url) => {
        setVideoUrl(url);
        setOpenVideo(true);
    };
    const handleCloseVideo = () => {
        setOpenVideo(false);
        setVideoUrl('');
    };

    const [hoverHelpTooltip, setHoverHelpTooltip] = React.useState(true);
    return (
        <React.Fragment>
            <Box position={'fixed'} bottom={'12px'} right={'20px'} zIndex={'20'}>
                <Tooltip 
                    title="Quote Manual"
                    placement="top"         
                    arrow
                    open={hoverHelpTooltip}
                    onOpen={() => setHoverHelpTooltip(true)}
                    onClose={() => setHoverHelpTooltip(false)}
                >
                    <Fab color="secondary" aria-label="add" size='small'
                        onClick={handleClick}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <SmartDisplayIcon />
                    </Fab>      
                </Tooltip>
                <Menu
                    anchorEl={langMenuAnchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    slotProps={{
                        paper:{
                            elevation: 0,
                            sx: {
                                background:"var(--secondary-900)",
                                ".MuiList-root .MuiButtonBase-root":{
                                    fontSize:"14px",
                                    "&:hover":{
                                        background:"var(--secondary-800)",
                                    }
                                },
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: -1.5,
                                color:"var(--grey-0)",
                                '&::before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    bottom: 0,
                                    right: 20,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'var(--secondary-900)',
                                    transform: 'translateY(50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            }
                        }
                    }}
                    anchorOrigin={{
                        vertical: 'top', horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'bottom', horizontal: 'left',
                    }}
                >
                    <MenuItem onClick={() => handleOpenVideo('https://cdn.vend-x.com/images/help-video/help-quoatiton-form.mp4')}>
                        English
                    </MenuItem>
                    <Divider 
                        component="li" 
                        sx={{
                            "&.MuiDivider-root":{
                                borderColor:"var(--secondary-800)",
                                m:0
                            }
                        }}
                    />
                    <MenuItem onClick={() => handleOpenVideo('https://cdn.vend-x.com/images/help-video/quotation-form-hindi.mp4')}>
                        Hindi
                    </MenuItem>                
                </Menu>
            </Box>

            <Modal open={openVideo} sx={{m:2}}>
                <Box sx={style}>
                    <IconButton 
                        size='small'
                        aria-label="Close"
                        onClick={handleCloseVideo}
                        sx={{
                            backgroundColor: 'var(--grey-0)',
                            position: "absolute",
                            top: "-12px",
                            right: "-12px",
                            zIndex:"1",
                            "&:hover":{
                                background:"var(--grey-900)",
                                color:"var(--grey-0)"
                            }
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <video autoPlay={true} width='100%' controls>
                        <source src={videoUrl} type="video/mp4" />
                    </video>
                </Box>
            </Modal>        
        </React.Fragment>
    );
}