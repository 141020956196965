import { SET_COMPANY_INFO, SET_COMPANY_INFO_ERROR } from "../constants/type";
import { getCompanyInfo } from "../../config/apiUrl";
import axios from "axios";

//for getting all items of category
export const setCompanyInfo = (company_id, sessionId) => (dispatch) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${sessionId}`;
  // axios.defaults.headers.common[
  //   "Authorization"
  // ] = `Bearer 1623996009138030.702506846885026`;
  const url = getCompanyInfo(company_id);
  axios
    .get(url)
    .then((res) => {
      dispatch({
        type: SET_COMPANY_INFO,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err, "SET_COMPANY_INFO_ERROR");
      dispatch({
        type: SET_COMPANY_INFO_ERROR,
        payload: err.message,
      });
    });
};
