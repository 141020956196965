import {
  SET_AVAILABLE_ITEM,
  SET_AVAILABLE_ITEM_ERROR,
  SET_AVAILABLE_ITEM_LOADING,
  SET_SEARCHED_ITEM,
  SET_SEARCHED_ITEM_ERROR,
  SET_SEARCHED_ITEM_LOADING,
  SET_SELECTED_ITEM,
  RESET_ITEMS,
  SET_FILTERED_ITEMS_LOADING,
  SET_FILTERED_ITEMS,
  RESET_SEARCHED_ITEMS,
  SET_CHECK_VALID_TECHNICALBID_ITEMS,
  UNSET_CHECK_VALID_TECHNICALBID_ITEMS,
  SET_ITEM_SPECIFICATIONS,
  RESET_ITEM_SPECIFICATIONS
} from "../constants/type";

const initialState = {
  loading: false,
  availableItem: [],
  availableItemMeta: {},
  error: "",
  searchedItems: [],
  searchedItemsLoading: false,
  searchedItemsError: "",
  searchedItemsMeta: {},
  selectedItems: [],
  filteredItems: [],
  filteredItemsLoading: false,
  filteredItemsError: "",
  filteredItemsMeta: {},
  checkTechnicalBidItems: {},
  itemSpecifications:{}
};

const itemReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_AVAILABLE_ITEM_LOADING:
      return {
        ...state,
        availableItem: [],
        availableItemMeta: {},
        error: "",
        loading: true,
      };
    case SET_AVAILABLE_ITEM:
      return {
        ...state,
        loading: false,
        availableItem: payload.data,
        availableItemMeta: payload.meta,
        error: "",
      };
    case SET_AVAILABLE_ITEM_ERROR:
      return {
        ...state,
        loading: false,
        availableItem: [],
        availableItemMeta: {},
        error: payload,
      };
    case SET_SELECTED_ITEM:
      return { ...state, selectedItems: [...payload] };
    case SET_SEARCHED_ITEM_LOADING:
      return {
        ...state,
        searchedItemsLoading: true,
        searchedItemsError: "",
        searchedItems: [],
        searchedItemsMeta: {},
      };
    case SET_SEARCHED_ITEM:
      return {
        ...state,
        searchedItemsLoading: false,
        searchedItemsError: "",
        searchedItems: payload.data,
        searchedItemsMeta: payload.meta,
      };
    case SET_SEARCHED_ITEM_ERROR:
      return {
        ...state,
        searchedItemsLoading: false,
        searchedItemsError: payload,
        searchedItems: [],
        searchedItemsMeta: {},
      };
    case SET_FILTERED_ITEMS_LOADING:
      return {
        ...state,
        filteredItemsLoading: true,
        filteredItemsError: "",
        filteredItems: [],
        filteredItemsMeta: {},
      };
    case SET_FILTERED_ITEMS:
      return {
        ...state,
        filteredItemsLoading: false,
        filteredItemsError: "",
        filteredItems: payload.data,
        filteredItemsMeta: payload.meta,
      };
    case "SET_FILTERED_ITEM_ERROR":
      return {
        ...state,
        filteredItemsLoading: false,
        filteredItemsError: payload,
        filteredItems: [],
        filteredItemsMeta: {},
      };
    case RESET_SEARCHED_ITEMS:
      return {
        ...state,
        searchedItems: [],
        searchedItemsLoading: false,
        searchedItemsError: "",
        searchedItemsMeta: {},
      };
    case SET_CHECK_VALID_TECHNICALBID_ITEMS:
      let checkTechnicalBidItems =  {
        ...state.checkTechnicalBidItems,
        ...payload
      }

      return {
        ...state,
        checkTechnicalBidItems
      }
    case UNSET_CHECK_VALID_TECHNICALBID_ITEMS:
      {
        payload.forEach((item) => delete state.checkTechnicalBidItems[item.id]);
        return state;
      }
    case RESET_ITEMS:
      return initialState;
    case SET_ITEM_SPECIFICATIONS:
        let itemSpecifications = {...state.itemSpecifications, ...payload} 
        return {
          ...state,
          itemSpecifications
        }
    case RESET_ITEM_SPECIFICATIONS:
        return {
          ...state,
          itemSpecifications : {}
        }
    default:
      return state;
  }
};
export default itemReducer;
