import { Switch } from '@mui/material';
import React from 'react';
import { CustomSwitchLabel } from '../Switches';

const SwitchLables = (props) => {

    return (
        <CustomSwitchLabel sx={{ml:0, display: 'flex', justifyContent: 'space-between' }}
        control={
                <Switch
                    disabled={props.disabled}
                    onChange={(event,value)=>{props?.setChange(value);props.handleChange(event)}}
                    name={props.name}
                    checked={props.checked}   
                />
        }
        labelPlacement="start"
        label={props.label}
    />


    )
}

export default SwitchLables;