import React from "react";
import {
  Card,
  CardContent,
  Grid,
  Divider,
  Typography,  
  CardHeader,
  Button,
  TextField,
  Box
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const CardTemplate = ({ sendToNew, title, data, getField, handleOpen, shipment_mark, handleShipmentMarkChange, count }) => {
  const [ value, setValue ] = React.useState(shipment_mark);

  React.useEffect(() => {
    if (title === "INTERNATIONAL RECIPIENTS") {
      setValue(shipment_mark)
    }
  }, [shipment_mark])
  return (
    <Card variant="outlined" className="template-card local_recepients_card">
      <CardHeader title={title} 
      titleTypographyProps={{variant:'h5', fontSize: 16, fontWeight:'600', color: `grey.600` }}
      sx={{
         minHeight: '62px',
        '& .MuiCardHeader-action':{
          marginRight:0,
          marginTop:0,
        }
      }} 
      action={
        !sendToNew && <Box 
          sx={{
            display: 'flex',
            justifyContent: 'flex-end', 
            alignItems: 'center' 
          }}>
          <Typography 
            fontWeight={500}
            fontSize="14px"
            color='grey.500'
            marginRight={1}
          >
            VIEW VENDORS
          </Typography>
          <Button variant="text" color="primary" fontWeight={500} onClick={handleOpen}>
            <AccountCircleIcon sx={{mr:'5px'}} />
            {count > 0 ? count : ""}
          </Button>
        </Box>      
        } />      
        <Divider sx={{ marginBottom: "0", }} />
      <CardContent>            
        <Grid container spacing={3} className="local_recepients_card_content">
          {data?.map((cell, i) => {
            const field = getField(cell);
            if (field && cell.VisibilityFlag === "Y") {
              return (
              <Grid item xl={3} lg={4} md={6} sm={6} xs={12} key={i}>
                  {field}
                </Grid>
              );
            }
          })}
          {title === "INTERNATIONAL RECIPIENTS" && 
            <Grid item xl={3} lg={4} md={6} sm={6} xs={12}> 
              <TextField
                // className="local_template_textfield"
                fullWidth
                // key={shipment_mark}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                variant="outlined"
                label="Shipment mark"
                type="text"
                name="shipment_mark"
                onBlur={(e) => {
                  handleShipmentMarkChange(e);
                }}
              />
            </Grid>
          }
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CardTemplate;
