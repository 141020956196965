import React, { Component, createRef } from "react";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  InputAdornment,
  IconButton,
  Button,
  Dialog,
  InputBase,
  Avatar,
} from "@mui/material";
import { Box } from "@mui/system";
import PersonAddIcon from "@mui/icons-material/PersonAddAlt1Rounded";
import CloseIcon from "@mui/icons-material/Close";
import { connect } from "react-redux";
import axios from "axios";
import {
  companyToAccountImport,
  getCompanyVendors,
  searchCompanyVendorsByNameOrCode,
} from "../../../config/apiUrl";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { openSnackbar } from "../../../redux/actions/snackbarAction";
import { debounce } from "lodash";
import { DataGridPro, GridOverlay } from "@mui/x-data-grid-pro";
import { getVendorGMPFlag } from "../../Vendors/VendorChip";

function QuickSearchToolbar(props) {
  return (
    <Box borderTop={1} borderTopColor={(theme) => theme.palette.bluegrey[500]}>
      <InputBase
        sx={{
          backgroundColor: (theme) => theme.palette.grey[50],
          width: "100%",
          height: "46px",
          borderRadius: "0",
          border: 0,
          padding: "0 16px",
          "& .MuiInputAdornment-positionStart .MuiSvgIcon-root": {
            color: (theme) => theme.palette.grey[600],
          },
          "& .search-clear svg": {
            width: "18px",
            height: "18px",
            color: (theme) => theme.palette.grey[500],
          },
        }}
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon fontSize="small" />
          </InputAdornment>
        }
        endAdornment={
          <IconButton
            className="search-clear"
            title="Clear"
            aria-label="Clear"
            size="small"
            style={{ visibility: props.value ? "visible" : "hidden" }}
            onClick={props.clearSearch}>
            <ClearIcon />
          </IconButton>
        }
      />
    </Box>
  );
}

class CompanyToAccountImport extends Component {
  constructor(props) {
    super(props);
    this.descriptionElementRef = createRef(null);
  }
  state = {
    rows: [],
    searchResult: [],
    selectionModel: [],
    currentPage: 1,
    searchResultCurrentPage: 1,
    lastPage: 1,
    searchResultLastPage: 1,
    total: 0,
    searchResultTotal: 0,
    loading: false,
    searchText: "",
    prevSearchText: "",
  };

  componentDidMount() {
    this.fetchCompanyVendor();
    // in order to wait for the element to be actually drawn, using setTimeout
    setTimeout(() => {
      if (this.descriptionElementRef.current !== null) {
        this.descriptionElementRef.current.focus();
      }
    }, 0);
  }
  fetchCompanyVendor = () => {
    this.setState({
      loading: true,
    });
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;

    axios
      .get(getCompanyVendors(this.state.currentPage))
      .then((res) => {
        //append rows, unique
        let finalRows = [...this.state.rows, ...res.data.data];
        let jsonObject = finalRows.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        let uniqueRows = Array.from(uniqueSet).map(JSON.parse);

        this.setState({
          loading: false,
          rows: uniqueRows,
          currentPage: res.data.meta.current_page,
          lastPage: res.data.meta.last_page,
          total: res.data.meta.total,
        });
      })
      .catch((err) => {
        this.props.openSnackbar("error", "something went wrong!");
        console.log(err, "getCompanyVendors Error");
        this.setState({
          loading: false,
        });
      });
  };

  handleScrollEnd = () => {
    if (this.state.searchText.trim() === "") {
      if (this.state.currentPage !== this.state.lastPage) {
        this.setState(
          {
            currentPage: this.state.currentPage + 1,
          },
          () => {
            this.fetchCompanyVendor();
          }
        );
      }
    } else {
      if (
        this.state.searchResultCurrentPage !== this.state.searchResultLastPage
      ) {
        this.setState(
          {
            searchResultCurrentPage: this.state.searchResultCurrentPage + 1,
            prevSearchText: this.state.searchText,
          },
          () => {
            this.searchCompanyVendor(this.state.searchText);
            // this.fetchCompanyVendor();
          }
        );
      }
    }
  };

  requestSearch = (searchValue) => {
    this.setState({
      loading: true,
      prevSearchText: this.state.searchText,
      searchText: searchValue,
    });

    if (searchValue.trim() !== "") {
      this.setState({
        searchResultCurrentPage: 1,
      });
      this.getSearchResult(searchValue);
    } else {
      this.setState({
        loading: false,
      });
    }
    //api call
  };

  getSearchResult = debounce((searchValue) => {
    // console.log("debounce");
    this.searchCompanyVendor(searchValue);
  }, 1000);

  searchCompanyVendor = (searchValue) => {
    this.setState({
      loading: true,
    });
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;

    const url = searchCompanyVendorsByNameOrCode(
      searchValue,
      this.state.searchResultCurrentPage
    );

    axios
      .get(url)
      .then((response) => {
        let final;

        // append or not respective to search is changes or not
        if (this.state.prevSearchText.trim() === searchValue) {
          final = [...this.state.searchResult, ...response.data.data];
        } else {
          final = response.data.data;
        }

        let jsonObject = final.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        let uniqueRows = Array.from(uniqueSet).map(JSON.parse);

        this.setState({
          searchResult: uniqueRows,
          searchResultLastPage: response.data.meta?.last_page,
          searchResultCurrentPage: response.data.meta?.current_page,
          searchResultTotal: response.data.meta?.total,
          loading: false,
        });
      })
      .catch((er) => {
        this.props.openSnackbar("error", "something went wrong!");
        console.log(er);
      });
  };

  handleSubmit = () => {
    const { selectionModel } = this.state;
    if (!selectionModel.length) {
      this.props.openSnackbar("error", "No vendor(s) selected");
      return;
    }
    let payload = {
      company_vendors: selectionModel,
      category_id: this.props.selectedCategory?.id,
      are_approved: 20,
    };
    axios
      .post(companyToAccountImport, payload)
      .then((res) => {
        // this.props.openSnackbar("success", res.data.message);
        this.props.openSnackbar(
          "success",
          `${
            res.data.message
              ? res.data.message
              : "Vendors imported successfully"
          }`
        );
        this.props.handleVendorsReload();
        this.props.handleClose();
      })
      .catch((err) => {
        this.props.openSnackbar(
          "error",
          `${
            err.response.data.message
              ? err.response.data.message
              : "Failed to import vendors to your account"
          }`
        );
        console.log(err, "companyToAccountImport");
      });
  };
  render() {
    const columns = [
      {
        field: "vendor_name",
        headerName: "Vendor Name",
        width: 250,
        align: "left",
        filterable: false,
        sortable: false,
        editable: false,

        renderCell: (params) => {
          return (
            <React.Fragment>
              <Avatar
                sx={{
                  backgroundColor: (theme) => theme.palette.success.main,
                  width: "34px",
                  height: "34px",
                }}>
                {params.row.vendor_name.slice(0, 1)}
              </Avatar>
              <Box sx={{ lineHeight: "12px", ml: 1 }}>
                <Typography color="text.primary" fontWeight="500">
                  {params.row.vendor_name}
                </Typography>
                <Typography variant="span" color="grey.500">
                  {params.row.vendor_code}
                </Typography>
              </Box>
            </React.Fragment>
          );
        },
      },
      {
        field: "vendor_status",
        headerName: "Vendor Status",
        headerAlign: "left",
        align: "left",
        flex: 1,
        filterable: false,
        sortable: false,
        editable: false,
        renderCell: (params) => {
          return (
            <React.Fragment>
              <Typography fontWeight={500}>
                {getVendorGMPFlag(this.props.companyInfo.gst_verification, params.row.gstin_number, params.row.gstin_status, 'G', "GSTIN")}
                {params.row.industry_type === "MSME" ? 
                  getVendorGMPFlag(this.props.companyInfo.msme_verification, params.row.industry_type_value, params.row.msme_status, 'M', "MSME") : 
                  getVendorGMPFlag(this.props.companyInfo.msme_verification, "", params.row.msme_status, 'M', "MSME")
                }
                {getVendorGMPFlag(this.props.companyInfo.pan_verification, params.row.pan_number, params.row.pan_status, 'P', "PAN")}
              </Typography>
            </React.Fragment>
          );
        },
      },
      {
        field: "email",
        headerName: "Email",
        headerAlign: "left",
        align: "left",
        flex: 1,
        filterable: false,
        sortable: false,
      },
      {
        field: "created_by_account",
        headerName: "Created From Account",
        headerAlign: "left",
        align: "left",
        flex: 1,
        filterable: false,
        sortable: false,
      },
    ];
    return (
      <div>
        <Dialog
          scroll="body"
          open={this.props.open}
          // onClose={() => {
          //   this.props.handleClose();
          //   this.setState({
          //     rows: [],
          //   });
          // }}
          fullWidth
          maxWidth="md"
          aria-labelledby="draggable-dialog-title">
          <DialogTitle
            sx={{
              "&:focus-visible": {
                border: "none",
                outline: "none",
              },
            }}
            ref={this.descriptionElementRef}
            tabIndex={-1}>
            Add Vendor
            <Box className="dialog-title-action">
              <Button
                variant="text"
                color="primary"
                onClick={() => {
                  this.props.handleAddVendorDialogOpen();
                  this.props.handleClose();
                  this.setState({
                    rows: [],
                  });
                }}
                startIcon={<PersonAddIcon />}>
                Add new vendor
              </Button>
              <IconButton
                sx={{ color: (theme) => theme.palette.bluegrey[500] }}
                onClick={() => {
                  this.props.handleClose();
                  this.setState({
                    rows: [],
                  });
                }}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent sx={{ padding: 0 }}>
            <QuickSearchToolbar
              value={this.state.searchText}
              onChange={(event) => this.requestSearch(event.target.value)}
              clearSearch={() => this.requestSearch("")}
            />
            <div style={{ height: 400 }}>
              <DataGridPro
                sx={{ borderLeft: 0, borderRight: 0, borderRadius: 0 }}
                columns={columns}
                rows={
                  this.state.searchText.trim() === ""
                    ? this.state.rows
                    : this.state.searchResult
                }
                // disableColumnReorder
                // disableColumnPinning
                disableColumnMenu
                loading={this.state.loading}
                slots={{
                  noRowsOverlay: () => {
                    return (
                      <GridOverlay>
                        <Typography>No vendor(s) found</Typography>
                      </GridOverlay>
                    );
                  },
                  footer: () => {
                    return (
                      <Box
                        sx={{
                          textAlign: "right",
                          margin: "4px",
                          padding: "10px 16px",
                        }}>
                        <Typography>
                          {this.state.searchText.trim() === ""
                            ? `Showing ${this.state.rows.length} / ${this.state.total}`
                            : `Showing ${this.state.searchResult.length} / ${this.state.searchResultTotal}`}
                        </Typography>
                      </Box>
                    );
                  },
                }}
                disableColumnSelector
                hideFooterPagination
                onRowsScrollEnd={this.handleScrollEnd}
                // rowHeight={38}
                checkboxSelection
                onRowSelectionModelChange={(newSelectionModel) => {
                  this.setState({ selectionModel: newSelectionModel });
                }}
                rowSelectionModel={this.state.selectionModel}
                //   filterMode="server"
                //   onFilterModelChange={this.handleFilterModelChange}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="primary"
              autoFocus
              onClick={() => {
                this.props.handleClose();
                this.setState({
                  rows: [],
                });
              }}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleSubmit}
              autoFocus>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedCategory: state.categoryReducer.selectedCategory,
  sessionId: state.sessionReducer.sessionId,
  masterPermission: state.permissionsReducer.masterPermission,
  companyInfo: state.companyInfoReducer.companyInfo,
});

export default connect(mapStateToProps, { openSnackbar })(
  CompanyToAccountImport
);
