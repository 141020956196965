import { TextField, Typography } from "@mui/material";
import styled1 from "styled-components";
import {styled} from "@mui/system";
import { makeStyles } from '@mui/styles';
import GlobalStyles from "@mui/material/GlobalStyles";

export const HeaderStyle = (
  <GlobalStyles
  styles={(theme) => ({
    '.page-header': {
      backgroundColor: theme.palette.background.default,   
      width: '100%',    
      padding: '19px 22px',
      margin: '0 auto',
      top: 0,
      left: 0,
      zIndex: '999',  
    },
    '.main_category_search .MuiOutlinedInput-root': {
      height: '38px',
      backgroundColor: theme.palette.grey[0],
      paddingRight: '5px',
    },
    '.main_category_bottom':{
      backgroundColor: theme.palette.background.default,
      padding: '4px 8px !important',
    },    
    '.main_category_bottom button svg, .main_category_bottom button': {
      color: theme.palette.text.primary,
      fontWeight: '600',
      fontSize: 'calc(11px + (14 - 12) * ((100vw - 300px) / (1920 - 300)))',
    },
    '.main_category_bottom button svg': { 
      fontSize: '18px',
      marginRight: '5px',
    },
    '@media (min-width: 220px) and (max-width: 700px)': {
      '.page-header .search-box':{
        flexDirection: 'column'
      }
    }
    

  })}
  />
);

export const TitlePageHeading = styled(Typography) (({ theme }) => ({
  '&.MuiTypography-h5': {
    color: theme.palette.grey[900],
    fontWeight: '500',
    fontSize: 'calc(18px + (18 - 16) * ((100vw - 300px) / (1920 - 300)))',
    marginRight: '30px',
  }
}));
export const PRTitleHeading = styled(Typography) (({ theme }) => ({
  '&.MuiTypography-h5': {
    color: theme.palette.grey[900],
    fontWeight: '500',
    fontSize: 'calc(18px + (16 - 14) * ((100vw - 300px) / (1920 - 300)))',
    // marginRight: '30px',
  }
}));

export const useStyles = makeStyles (theme => ({
 
  CategorySearchInput: {
    '& .MuiOutlinedInput-root': {
       backgroundColor: theme.palette.grey[0],
       height: '38px', 
       width: '325px',
    },
    '& .MuiInputAdornment-positionEnd button':{
      width: '25px',
      height: '25px', 
      color: theme.palette.grey[500],
    },
    '& .MuiInputAdornment-positionEnd svg':{
      width: '20px',
      height: '20px',
    }
  },
  categoryNameChip: {
    '&.MuiChip-root':{
      backgroundColor: theme.palette.grey[0],
      height: '38px',
      borderRadius: 200,
    
    },
    '&.MuiChip-root svg':{
      color: theme.palette.bluegrey[500]
    }
  }

}));

 


export const SubHeading = styled1(Typography)``;

export const TextFieldWrapper = styled1(TextField)`
  ${(props) =>
    props.isSelected &&
    `
      fieldset {
        border-radius: 50px;
      }
    `}
`;


