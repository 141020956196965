import React from "react";
import Skeleton from '@mui/material/Skeleton';
import { Box } from "@mui/system";

const VendorItemSkeleton = () => {
    return ( 
      <div>
      {[...Array(3).keys()].map((cv) => 
        <Box key={cv} sx={{display: 'flex', alignItems: 'center', width:'100%', padding: '8px 15px 8px 15px',}}>
          <Skeleton variant="circular" width={40} height={40} animation="wave"/>
          <Box sx={{ml:'10px', width:'90%', }}>
            <Skeleton animation="wave" />
            <Skeleton width="50%" animation="wave" />
          </Box>
        </Box>
      )}
    </div>    
      
    );
  };
  
  export default VendorItemSkeleton;