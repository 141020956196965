import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";

export default function SelectGridView() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (event) => {
    const selectedOption = event.target.value;

    // Check if the selected option matches the current path or is the default value
    if (
      selectedOption === "" ||
      (selectedOption === 10 && location.pathname === "/rfq-list-data-grid") ||
      (selectedOption === 20 && location.pathname === "/vendor-view-data-grid")
    ) {
      return; // Do nothing if the selected option matches the current path or is the default value
    }

    // Perform navigation based on the selected option
    switch (selectedOption) {
      case 10:
        navigate("/rfq-list-data-grid");
        break;
      case 20:
        navigate("/vendor-view-data-grid");
        break;
      default:
        break;
    }
  };

  const [alignment, setAlignment] = React.useState("left");

  const toggleHandleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <>
      {/* <FormControl sx={{ ml: 4, mr: 1,  display:{xs:'none', sm:'none', md:'inline-flex'}  }} size="small">
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={
            location.pathname === "/rfq-list-data-grid"
              ? 10
              : location.pathname === "/vendor-view-data-grid"
              ? 20
              : ""
          }
          onChange={handleChange}
          sx={{
            boxShadow: "none",
            ".MuiOutlinedInput-notchedOutline, &.MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                border: 0,
              },
            "&.MuiInputBase-root .MuiSelect-select": {
              display: "flex",
              alignItems: "center",
              pt:0,
              pb:0,
              "& .MuiSvgIcon-root": {
                marginRight: "5px",
              },
            },
          }}>
          <MenuItem value={10}
            sx={{
              "&.MuiMenuItem-root .MuiSvgIcon-root": {
                marginRight: "5px",
              }
            }}
          >
            <FormatListBulletedIcon />
            <Typography variant="body2" sx={{lineHeight:"normal"}}>RFQ View</Typography>
          </MenuItem>
          <MenuItem value={20}
            sx={{
              "&.MuiMenuItem-root .MuiSvgIcon-root": {
                marginRight: "5px",
              }
            }}
          >
            <PeopleOutlineOutlinedIcon />
            <Typography variant="body2" sx={{lineHeight:"normal"}}>Vendor View</Typography>
          </MenuItem>
        </Select>
      </FormControl> */}

      <Box
        sx={{
          ml: "auto !important",
          display: { xs: "inline-flex", sm: "inline-flex", md: "none" },
          "& .MuiToggleButtonGroup-root .MuiButtonBase-root": {
            padding: "4px",
          },
        }}
      >
        <ToggleButtonGroup
          value={alignment}
          exclusive
          onChange={toggleHandleChange}
          aria-label="text alignment"
          size="small"
        >
          <ToggleButton
            value="left"
            aria-label="left aligned"
            onClick={() => navigate("/rfq-list-data-grid")}
          >
            <FormatListBulletedIcon />
          </ToggleButton>
          <ToggleButton
            value="center"
            aria-label="centered"
            onClick={() => navigate("/vendor-view-data-grid")}
          >
            <PeopleOutlineOutlinedIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </>
  );
}
