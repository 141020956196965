import styled from "styled-components";
import { Dialog } from "@mui/material";

export const CustomMailDialog = styled(Dialog)`
  .MuiDialog-paperWidthMd {
    min-width: 750px;
    max-width: 750px;
    border-radius: 10px;
  }
  label,
  .custom_labels {
    text-align: left;
    letter-spacing: 0.42px;
    color: var(--text-color);
    font-weight: 600;
    font-size: 15.5px;
  }
  label {
    font-size: 22.5px;
  }

  .MuiDialogTitle-root {
    border-bottom: 2px solid var(--border-outline);
    padding: 10px 24px 7px;
  }
  h2 .MuiGrid-item {
    align-items: center;
    display: inherit;
  }

  .sender_details_collapse {
    border: 1.5px solid #d7dadf;
  }
  .sender_details_collapse p {
    font-size: 16px;
    color: var(--small-text-ceolor);
    font-weight: 700;
    text-transform: uppercase;
  }
  .sender_details_collapse .MuiGrid-container {
    padding: 2px 10px;
    display: flex;
    align-items: center;
  }
  .MuiTouchRipple-root {
    display: none;
  }
  .MuiDialogActions-root {
    padding: 15px 24px;
  }
  .sender_details_collapse.open {
    border-top: none;
  }
  .sender_details_info {
    padding: 15px;
  }
  .sender_details_info p {
    color: var(--text-color);
    text-transform: uppercase;
    margin-bottom: 5px;
  }
  .sender_details_info span {
    color: var(--text-color);
    font-weight: 100;
  }
`;
