import {
  Grid,
  Checkbox,
  InputAdornment,  
  Typography,
  Card,
  CardActions,
  CardContent,
  Tooltip,
  Badge
} from "@mui/material";
import React, { Component } from "react";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";

import { connect } from "react-redux";
import { setSelectedVendors } from "../../../redux/actions/vendorActions";
import { FlatsearchInput } from "../../TextField";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from '@mui/icons-material/Close';
import { LinkRounded, LinkOffRounded } from "@mui/icons-material";

import axios from "axios";
import {
  getAllLinkedItems,
  getAllUnlinkedItems,
  searchUnlinkedItem,
  linkUnlinkItem,
  searchLinkedItemByNameOrCode,
} from "../../../config/apiUrl";
import { debounce } from "lodash";
import { IconButton } from "@mui/material";
import { openSnackbar } from "../../../redux/actions/snackbarAction";
import { setSelectedItem } from "../../../redux/actions/itemActions";
import ItemList from "./ItemList";
import ConfirmAlert from "../ConfirmAlert.js/index.js";
import { LinkItemDialog } from "../LinkVendorToCategory/style";
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import { Box } from "@mui/system";
import SwipeableEdgeDrawer from './SwipeableEdgeDrawer'

class LinkItemToCategory extends Component {
  state = {
    linkedItems: [],
    linkedItemsLoading: false,
    linkedCategoryPage: 1,
    linkedCategoryLastPage: 1,
    linkedCategoryTotal: 0,
    linkedSearchInput: "",
    checkedLinkedItems: [],

    searchedlinkedItems: [],
    searchedlinkedItemsLoading: false,
    searchedlinkedCategoryPage: 1,
    searchedlinkedCategoryLastPage: 1,
    searchedlinkedCategoryTotal: 0,
    prevLinkedSearch: "",
    checkedSearchedLinkedItems: [],

    showSelectedLinked: false,

    unlinkedItems: [],
    unlinkedItemsLoading: false,
    unlinkedCategoryPage: 1,
    unlinkedCategoryLastPage: 0,
    unlinkedCategoryTotal: 0,
    unlinkedSearchInput: "",
    checkedUnlinkedItems: [],

    searchedUnlinkedItems: [],
    searchedUnlinkedItemsLoading: false,
    searchedUnlinkedCategoryPage: 1,
    searchedUnlinkedCategoryLastPage: 1,
    searchedUnlinkedCategoryTotal: 0,
    prevUnlinkedSearch: "",
    checkedSearchedUnlinkedItems: [],

    showSelectedUnlinked: false,

    confirmDialogOpen:false,
  };

  componentDidMount() {
    this.getLinkedItems();
    this.getUnlinkedItems();
  }

  componentDidUpdate() {
    if ( this.state.showSelectedLinked && !this.state.checkedLinkedItems.length){
      this.setState({
        showSelectedLinked: false,
      });
    }
    
    if ( this.state.showSelectedUnlinked && !this.state.checkedUnlinkedItems.length){
      this.setState({
        showSelectedUnlinked: false,
      });
    }
  }

  toggleShowSelectedLinked = () => {
    this.setState({ 
      showSelectedLinked: !this.state.showSelectedLinked 
    })
  };

  toggleShowSelectedUnlinked = () => {
    this.setState({ 
      showSelectedUnlinked: !this.state.showSelectedUnlinked 
    })
  };

  getLinkedItems = () => {
    this.setState({
      linkedItemsLoading: true,
    });
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;
    const url = getAllLinkedItems(
      this.props.selectedCategory?.id,
      this.state.linkedCategoryPage
    );
    axios
      .get(url)
      .then((response) => {
        this.setState({
          linkedItems: [...this.state.linkedItems, ...response.data.data],
          linkedCategoryLastPage: response.data.meta?.last_page,
          linkedCategoryTotal: response.data.meta?.total,
          linkedItemsLoading: false,
        });
      })
      .catch((err) => {
        console.log(err, "linked Items error");
        this.setState({
          linkedItemsLoading: false,
        });
      });
  };
  getUnlinkedItems = () => {
    this.setState({
      unlinkedItemsLoading: true,
    });
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;
    const url = getAllUnlinkedItems(
      this.props.selectedCategory?.id,
      this.state.unlinkedCategoryPage
    );
    axios
      .get(url)
      .then((response) => {
        this.setState({
          unlinkedItems: [...this.state.unlinkedItems, ...response.data.data],
          unlinkedCategoryLastPage: response.data.meta?.last_page,
          unlinkedCategoryTotal: response.data.meta?.total,
          unlinkedItemsLoading: false,
        });
      })
      .catch((err) => {
        console.log(err, "unlinked Items error");
        this.setState({
          unlinkedItemsLoading: false,
        });
      });
  };
  searchLinkedItems = (value) => {
    this.setState({
      searchedlinkedItemsLoading: true,
    });
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;
    const url = searchLinkedItemByNameOrCode(this.props.selectedCategory?.id, value.trim(), this.state.searchedlinkedCategoryPage);
    axios
      .get(url)
      .then((response) => {
        let final;
        // append or not respective to search is changes or not
        if (this.state.prevLinkedSearch.trim() === value) {
          final = [...this.state.searchedlinkedItems, ...response.data.data];
        } else {
          final = response.data.data;
        }
        this.setState({
          searchedlinkedItems: final,
          searchedlinkedCategoryLastPage: response.data.meta?.last_page,
          searchedlinkedCategoryTotal: response.data.meta?.total,
          searchedlinkedItemsLoading: false,
        });
      })
      .catch((err) => {
        console.log(err, "searchedlinkedItem error");
        this.setState({
          searchedlinkedItemsLoading: false,
        });
      });
  };

  searchUnlinkedItems = (value) => {
    this.setState({
      searchedUnlinkedItemsLoading: true,
    });
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;
    const url = searchUnlinkedItem(
      this.props.selectedCategory?.id,
      value,
      this.state.searchedUnlinkedCategoryPage
    );
    axios
      .get(url)
      .then((response) => {
        // console.log(response.data, "searchedUnlinkedItems response");
        this.setState({
          searchedUnlinkedItems:
            this.state.prevUnlinkedSearch.trim() === value
              ? [...this.state.searchedUnlinkedItems, ...response.data.data]
              : response.data.data,
          searchedUnlinkedCategoryLastPage: response.data.meta?.last_page,
          searchedUnlinkedCategoryTotal: response.data.meta?.total,
          searchedUnlinkedItemsLoading: false,
        });
      })
      .catch((err) => {
        console.log(err, "searchedUnlinkedItem error");
        this.setState({
          searchedUnlinkedItemsLoading: false,
        });
      });
  };

  handleChange = (evt) => {
    const { name, value } = evt.target;

    this.setState({
      [name]: value,
      prevLinkedSearch: this.state.linkedSearchInput,
      prevUnlinkedSearch: this.state.linkedSearchInput,
    });
    if (value.trim() !== "") {
      if (name === "linkedSearchInput") {
        this.setState({
          searchedlinkedItemsLoading: true,
          searchedlinkedItems: [],
        });
      } else {
        this.setState({
          searchedUnlinkedItemsLoading: true,
          searchedUnlinkedItems: [],
        });
      }
      this.getSearchResult(name, value);
    }
  };

  getSearchResult = debounce((name, value) => {
    // console.log("debounce");
    if (name === "linkedSearchInput") {
      if (this.state.prevLinkedSearch.trim() !== value){
        this.setState({
          searchedlinkedCategoryPage: 1
        }, () => this.searchLinkedItems(value))
      } else {
        this.searchLinkedItems(value);
      }
    } else {
      if (this.state.prevUnlinkedSearch.trim() !== value){
        this.setState({
          searchedUnlinkedCategoryPage: 1
        }, () => this.searchUnlinkedItems(value))
      } else {
        this.searchUnlinkedItems(value);
      }
    }
  }, 1000);

  //Linked Item selections

  handleSelectAllClick = (event, type) => {
    let newSelecteds = [];
    if (type === "checkedLinkedItems") {
      if (this.state.linkedSearchInput.trim() === ""){
        let newRows = [...this.state.linkedItems, ...this.state.checkedLinkedItems];
        let finalRows = newRows;
        let jsonObject = finalRows.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        newSelecteds = Array.from(uniqueSet).map(JSON.parse);
      } else {
        let newRows = [...this.state.searchedlinkedItems, ...this.state.checkedLinkedItems];
        let finalRows = newRows;
        let jsonObject = finalRows.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        newSelecteds = Array.from(uniqueSet).map(JSON.parse);
      }
    } else if (type === "checkedUnlinkedItems") {
      if (this.state.unlinkedSearchInput.trim() === ""){
        let newRows = [...this.state.unlinkedItems, ...this.state.checkedUnlinkedItems];
        let finalRows = newRows;
        let jsonObject = finalRows.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        newSelecteds = Array.from(uniqueSet).map(JSON.parse);
      } else {
        let newRows = [...this.state.searchedUnlinkedItems, ...this.state.checkedUnlinkedItems];
        let finalRows = newRows;
        let jsonObject = finalRows.map(JSON.stringify);
        let uniqueSet = new Set(jsonObject);
        newSelecteds = Array.from(uniqueSet).map(JSON.parse);
      }
    }

    if (event.target.checked) {
      this.setState({
        [type]: newSelecteds,
      });
      return;
    }

    let update = [];
    if (type === "checkedLinkedItems") {
      if (this.state.linkedSearchInput.trim() === ""){
        //removing the linkedItems from the checked linked items
        update = this.state.checkedLinkedItems.filter(el => !this.state.linkedItems.map(ob => ob.id).includes(el.id));
      } else {
        //removing the searched:inkedItems from the checked linked items
        update = this.state.checkedLinkedItems.filter(el => !this.state.searchedlinkedItems.map(ob => ob.id).includes(el.id));
      }
    } else {
      if (this.state.unlinkedSearchInput.trim() === ""){
        //removing the unlinkedItems from the checked unlinked items
        update = this.state.checkedUnlinkedItems.filter(el => !this.state.unlinkedItems.map(ob => ob.id).includes(el.id));
      } else {
        //removing the searchedunlinkedItems from the checked unlinked items
        update = this.state.checkedUnlinkedItems.filter(el => !this.state.searchedUnlinkedItems.map(ob => ob.id).includes(el.id));
      }
    }
    this.setState({
      [type]: update,
    });
  };

  handleItemClick = (event, name, type) => {
    const selectedIndex = this.state[type].map(el => el.id).indexOf(name.id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state[type], name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state[type].slice(1));
    } else if (selectedIndex === this.state[type].length - 1) {
      newSelected = newSelected.concat(this.state[type].slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        this.state[type].slice(0, selectedIndex),
        this.state[type].slice(selectedIndex + 1)
      );
    }
    this.setState({
      [type]: newSelected,
    });
  };

  isItemSelected = (row, type) => {
    return this.state[type].some(
      e => e.id === row.id
    )
  };

  linkUnlinkItem = (type) => {
    //type will be the option to be performed, i.e if type = unlink then items will be unlinked
    let checked;
    if (type === "unlink") {
      checked = "checkedLinkedItems";
    } else {
      checked = "checkedUnlinkedItems";
    }
    if (!this.state[checked].length) {
      this.props.openSnackbar("error", "No item(s) selected");
      this.setState({
        unlinkedItemsLoading: false,
        linkedItemsLoading: false,
      });
      return;
    }
    let commonItems = []
    if(type === "unlink"){
      this.state[checked].forEach(item => {
        if (
          this.props.selectedItems?.some((element) => {
            return element.id === item.id;
          })
        ) {
          commonItems.push(item);
        }
      })
    }
    if(commonItems.length > 0) {
      this.setState({
        confirmDialogOpen: true,
      })
      return;
    }
    this.apiCall(type,checked);
  };

  apiCall = (type, checked) => {
    let data = {
      items: this.state[checked].map((item) => item.id),
      linking_stage: type === 'link' ? "10" : "20",
    };

    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;
    const url = linkUnlinkItem(this.props.selectedCategory?.id);

    if (type === "unlink") {
      this.setState({
        linkedItemsLoading: true,
      });
    } else {
      this.setState({
        unlinkedItemsLoading: true,
      });
    }
    axios
      .post(url, data)
      .then((response) => {
        if (response.status === 200) {
          this.props.openSnackbar("success", response.data.message, true);
          this.setState({
            linkedItems: [],
            linkedItemsLoading: false,
            linkedCategoryPage: 1,
            linkedCategoryLastPage: 1,
            linkedCategoryTotal: 0,
            linkedSearchInput: "",
            checkedLinkedItems: [],

            showSelectedLinked: false,

            searchedlinkedItems: [],
            searchedlinkedItemsLoading: false,
            searchedlinkedCategoryPage: 1,
            searchedlinkedCategoryLastPage: 1,
            searchedlinkedCategoryTotal: 0,
            prevLinkedSearch: "",
            checkedSearchedLinkedItems: [],

            unlinkedItems: [],
            unlinkedItemsLoading: false,
            unlinkedCategoryPage: 1,
            unlinkedCategoryLastPage: 0,
            unlinkedCategoryTotal: 0,
            unlinkedSearchInput: "",
            checkedUnlinkedItems: [],
            
            showSelectedUnlinked: false,

            searchedUnlinkedItems: [],
            searchedUnlinkedItemsLoading: false,
            searchedUnlinkedCategoryPage: 1,
            searchedUnlinkedCategoryLastPage: 1,
            searchedUnlinkedCategoryTotal: 0,
            prevUnlinkedSearch: "",
            checkedSearchedUnlinkedItems: [],
          }, () => {
            this.getLinkedItems();
            this.getUnlinkedItems();
          });
        }
      })
      .catch((er) => {
        console.log(er, "linkunlink");

        if (er.response) {
          if (er.response.data?.message) {
            this.props.openSnackbar("error", er.response.data?.message);
          } else if (er.response.status === 500) {
            this.props.openSnackbar(
              "error",
              "Internal Server Error. Please try again later."
            );
          } else if (er.response.status === 503) {
            this.props.openSnackbar(
              "error",
              "Service temporarily unavailable. Please try again later."
            );
          } else {
            this.props.openSnackbar(
              "error",
              `Failed to ${type} the selected item(s)`
            );
          }
        } else {
          this.props.openSnackbar(
            "error",
            "Network Error"
          );
        }

        // this.props.openSnackbar("error", "something went wrong");
        this.setState({
          linkedItemsLoading: false,
          unlinkedItemsLoading: false,
        });
      });
  }

  handleConfirm = () => {
    let checked;
    checked = "checkedLinkedItems";

    let commonItems = []
    this.state[checked].forEach(item => {
      if (
        this.props.selectedItems?.some((element) => {
          return element.id === item.id;
        })
      ) {
        commonItems.push(item);
      }
    })

    let updatedSelectedItems = [];
    this.props.selectedItems.forEach((cartItem) => {
      if (
        !commonItems.some((element) => {
          return element.id === cartItem.id;
        })
      ) {
        updatedSelectedItems.push(cartItem);
      }
    });
    this.props.setSelectedItem(updatedSelectedItems);
    this.apiCall("unlink",checked);
    this.setState({
      confirmDialogOpen:false
    })
  }

  commonItems = (arr1, arr2) => {
    return arr1.filter(function (o1) {
      return arr2.some(function (o2) {
          return o1.id === o2.id; // return the ones with equal id
     });
    });
  }

  render() {
    const { open } = this.props;
    return (
      <div>
        <ConfirmAlert 
           open={this.state.confirmDialogOpen}
           handleClose={() => {
             this.setState({
              confirmDialogOpen: false
             })
           }}
           handleSubmit={this.handleConfirm}
           msg="Unlinking this item will also remove it from cart. Are
           you sure you want to continue?"
           title="Remove from cart?"
        />
        <LinkItemDialog
          scroll="body"
          maxWidth={"md"}
          open={open}
          // onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle
            id="responsive-dialog-title"
            className="dialogcard_header_main"
          >

            Link Items To Category
             <Box className="dialog-title-action">
              <IconButton
                sx={{
                  color: (theme) => theme.palette.bluegrey[500],}}
                  onClick={this.props.handleClose}
              >
                <CloseIcon />
              </IconButton>
            </Box>  
          </DialogTitle>
          <DialogContent sx={{pt:'25px !important'}}>
              <div className="linkcategorydialog_main">
                <Grid container spacing={2}  >
                  {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography component="p" color="grey.500" sx={{mt:'25px', mb:'10px'}}>Note: Check the rows and drag to move other rows.</Typography>
                  </Grid> */}
                  {/* link item start */}
                  <Grid item  sm={6} xs={12} className="mt-2">
                    <Card className="LinkUnlinkCard" variant="outlined">
                      <CardActions>
                        <Checkbox
                          indeterminate={
                            this.state.linkedSearchInput.trim() === "" 
                            ?  this.commonItems(this.state.checkedLinkedItems, this.state.linkedItems)?.length > 0 &&
                              this.commonItems(this.state.checkedLinkedItems, this.state.linkedItems)?.length <
                                this.state.linkedItems.length
                            : this.commonItems(this.state.checkedLinkedItems, this.state.searchedlinkedItems)?.length > 0 && 
                              this.commonItems(this.state.checkedLinkedItems, this.state.searchedlinkedItems)?.length < this.state.searchedlinkedItems.length

                          }
                          checked={
                            this.state.linkedSearchInput.trim() === "" 
                              ? this.commonItems(this.state.checkedLinkedItems, this.state.linkedItems)?.length > 0 &&
                              this.commonItems(this.state.checkedLinkedItems, this.state.linkedItems)?.length ===
                                  this.state.linkedItems.length
                              : this.commonItems(this.state.checkedLinkedItems, this.state.searchedlinkedItems)?.length > 0 && 
                              this.commonItems(this.state.checkedLinkedItems, this.state.searchedlinkedItems)?.length === this.state.searchedlinkedItems.length
                          }
                          disabled={this.state.showSelectedLinked}
                          onChange={(e) => {
                            this.handleSelectAllClick(
                              e,
                              "checkedLinkedItems"
                            );
                          }}
                        />
                        <Typography
                          variant="h6"
                          component="div"
                          className="LinkCardTitle"
                        >
                          LINKED ITEM
                        </Typography>
                        <Tooltip title={this.state.checkedLinkedItems.length && "View Selected item"}>
                          <IconButton                          
                            sx={{ ml: "auto", color: (theme) => this.state.showSelectedLinked ? theme.palette.primary.main : theme.palette.grey[600]}}
                            disabled={!this.state.checkedLinkedItems.length}
                            onClick={() => this.toggleShowSelectedLinked()}
                          >
                             <Badge badgeContent={this.state.checkedLinkedItems.length} color="error">
                             <ListAltOutlinedIcon 
                             // sx={{ color: (theme) => this.state.showSelectedLinked ? theme.palette.primary.main : theme.palette.grey[600] }}
                            />
                            </Badge>
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Unlink Selected Items">
                          <IconButton                           
                            onClick={() => this.linkUnlinkItem("unlink")}
                            sx={{ ml: "auto", color: (theme) => theme.palette.grey[600] }}
                            disabled={this.state.linkedItemsLoading || this.state.searchedlinkedItemsLoading || this.state.unlinkedItemsLoading || this.state.searchedUnlinkedItemsLoading}
                          >
                            {/* <img src={vendorlink}  alt=""/> */}
                            <LinkOffRounded />
                          </IconButton>
                        </Tooltip>
                      </CardActions>
                      <CardContent sx={{position:'relative', pb: '0 !important'}} id='linked-items'>
                        <FlatsearchInput
                          name="linkedSearchInput"
                          placeholder="Search"
                          value={this.state.linkedSearchInput}
                          onChange={this.handleChange}
                          disabled={this.state.showSelectedLinked}
                          startAdornment={
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          }
                          endAdornment={
                            <React.Fragment>
                              {this.state.linkedSearchInput !== "" ? (
                                <IconButton
                                  onClick={() => {
                                    this.setState({
                                      linkedSearchInput: "",
                                    });
                                  }}
                                  size="small"
                                >
                                  <ClearIcon />
                                </IconButton>
                              ) : null}
                            </React.Fragment>
                          }
                        />
                        <div className="link-items-list" id='linked-items' style={{position:'relative'}}>
                          {this.state.linkedSearchInput.trim() === "" ? (
                            <ItemList
                              data={this.state.linkedItems}
                              isItemSelected={this.isItemSelected}
                              handleItemClick={this.handleItemClick}
                              lastPage={this.state.linkedCategoryLastPage}
                              currentPage={this.state.linkedCategoryPage}
                              loading={this.state.linkedItemsLoading}
                              type="checkedLinkedItems"
                              loadMore={() => {
                                this.setState(
                                  {
                                    linkedCategoryPage:
                                      this.state.linkedCategoryPage + 1,
                                  },
                                  () => {
                                    this.getLinkedItems();
                                  }
                                );
                              }}
                            />
                          ) : (
                            <ItemList
                              searchValue={this.state.linkedSearchInput}
                              data={this.state.searchedlinkedItems}
                              isItemSelected={this.isItemSelected}
                              handleItemClick={this.handleItemClick}
                              lastPage={
                                this.state.searchedlinkedCategoryLastPage
                              }
                              currentPage={
                                this.state.searchedlinkedCategoryPage
                              }
                              loading={this.state.searchedlinkedItemsLoading}
                              type="checkedLinkedItems"
                              loadMore={() => {
                                this.setState(
                                  {
                                    searchedlinkedCategoryPage:
                                      this.state.searchedlinkedCategoryPage + 1,
                                    prevLinkedSearch:
                                      this.state.linkedSearchInput,
                                  },
                                  () => {
                                    this.searchLinkedItems(
                                      this.state.linkedSearchInput
                                    );
                                  }
                                );
                              }}
                            />
                          )}
                        </div>
                        <Box className="LinkUnlink-footer">
                          <Typography component="p">
                            Showing <span> {this.state.linkedSearchInput.trim() === ""
                              ? `${this.state.linkedItems.length} / ${this.state.linkedCategoryTotal}`
                              : `${this.state.searchedlinkedItems.length} / ${this.state.searchedlinkedCategoryTotal}`} </span>
                          </Typography>
                        </Box>
                          <SwipeableEdgeDrawer 
                            toggleDrawer={this.toggleShowSelectedLinked}
                            open={this.state.showSelectedLinked}
                            data={this.state.checkedLinkedItems}
                            handleItemClick={this.handleItemClick}
                            type="checkedLinkedItems"
                            reference="linked-items"

                          />
                        
                      </CardContent>
                    </Card>
                  </Grid>
                  {/* link item end */}
                  {/* unlink item start */}
                  <Grid item  sm={6} xs={12} className="mt-2">
                    <Card className="LinkUnlinkCard" variant="outlined">
                      <CardActions>
                        <Checkbox
                          indeterminate={
                            this.state.unlinkedSearchInput.trim() === "" 
                            ?  this.commonItems(this.state.checkedUnlinkedItems, this.state.unlinkedItems)?.length > 0 &&
                              this.commonItems(this.state.checkedUnlinkedItems, this.state.unlinkedItems)?.length <
                                this.state.unlinkedItems.length
                            : this.commonItems(this.state.checkedUnlinkedItems, this.state.searchedUnlinkedItems)?.length > 0 && 
                              this.commonItems(this.state.checkedUnlinkedItems, this.state.searchedUnlinkedItems)?.length < this.state.searchedUnlinkedItems.length

                          }
                          checked={
                            this.state.unlinkedSearchInput.trim() === "" 
                              ? this.commonItems(this.state.checkedUnlinkedItems, this.state.unlinkedItems)?.length > 0 &&
                              this.commonItems(this.state.checkedUnlinkedItems, this.state.unlinkedItems)?.length ===
                                  this.state.unlinkedItems.length
                              : this.commonItems(this.state.checkedUnlinkedItems, this.state.searchedUnlinkedItems)?.length > 0 && 
                              this.commonItems(this.state.checkedUnlinkedItems, this.state.searchedUnlinkedItems)?.length === this.state.searchedUnlinkedItems.length
                          }
                          disabled={this.state.showSelectedUnlinked}
                          onChange={(e) => {
                            this.handleSelectAllClick(
                              e,
                              "checkedUnlinkedItems"
                            );
                          }}
                        />
                        <Typography
                          variant="h6"
                          component="div"
                          className="LinkCardTitle"
                        >
                          UNLINKED ITEM
                        </Typography>
                        <Tooltip title={this.state.checkedUnlinkedItems.length && "View Selected item"}>
                          <IconButton                          
                            sx={{ ml: "auto",  }}
                            disabled={!this.state.checkedUnlinkedItems.length}
                            onClick={() => this.toggleShowSelectedUnlinked()}
                          >
                             <Badge badgeContent={this.state.checkedUnlinkedItems.length} color="error">
                             <ListAltOutlinedIcon 
                                sx={{ color: (theme) => this.state.showSelectedUnlinked ? theme.palette.primary.main : theme.palette.grey[600] }}
                             />
                            </Badge>
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Link Selected Items">
                        <IconButton                          
                          onClick={() => this.linkUnlinkItem("link")}
                          sx={{ ml: "auto", color: (theme) => theme.palette.grey[600] }}
                          disabled={this.state.linkedItemsLoading || this.state.searchedlinkedItemsLoading || this.state.unlinkedItemsLoading || this.state.searchedUnlinkedItemsLoading}
                        >
                          <LinkRounded />
                          {/* <img src={vendorunlink} alt="" /> */}
                        </IconButton>
                        </Tooltip>
                      </CardActions>

                      <CardContent sx={{position:'relative', pb: '0 !important'}} id='unlinked-items'>
                        <FlatsearchInput
                          name="unlinkedSearchInput"
                          placeholder="Search"
                          disabled={this.state.showSelectedUnlinked}
                          value={this.state.unlinkedSearchInput}
                          onChange={this.handleChange}
                          startAdornment={
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          }
                          endAdornment={
                            <React.Fragment>
                              {this.state.unlinkedSearchInput !== "" ? (
                                <IconButton
                                  onClick={() => {
                                    this.setState({
                                      unlinkedSearchInput: "",
                                    });
                                  }}
                                  size="small"
                                >
                                  <ClearIcon />
                                </IconButton>
                              ) : null}
                            </React.Fragment>
                          }
                        />

                        <div className="link-items-list" id='unlinked-items' style={{position:'relative'}}>
                          {this.state.unlinkedSearchInput.trim() === "" ? (
                            <ItemList
                              data={this.state.unlinkedItems}
                              isItemSelected={this.isItemSelected}
                              handleItemClick={this.handleItemClick}
                              lastPage={this.state.unlinkedCategoryLastPage}
                              currentPage={this.state.unlinkedCategoryPage}
                              loading={this.state.unlinkedItemsLoading}
                              type="checkedUnlinkedItems"
                              loadMore={() => {
                                this.setState(
                                  {
                                    unlinkedCategoryPage:
                                      this.state.unlinkedCategoryPage + 1,
                                  },
                                  () => {
                                    this.getUnlinkedItems();
                                  }
                                );
                              }}
                            />
                          ) : (
                            <ItemList
                              searchValue={this.state.unlinkedSearchInput}
                              data={this.state.searchedUnlinkedItems}
                              isItemSelected={this.isItemSelected}
                              handleItemClick={this.handleItemClick}
                              lastPage={
                                this.state.searchedUnlinkedCategoryLastPage
                              }
                              currentPage={
                                this.state.searchedUnlinkedCategoryPage
                              }
                              loading={this.state.searchedUnlinkedItemsLoading}
                              type="checkedUnlinkedItems"
                              loadMore={() => {
                                this.setState(
                                  {
                                    searchedUnlinkedCategoryPage:
                                      this.state.searchedUnlinkedCategoryPage +
                                      1,
                                    prevUnlinkedSearch:
                                      this.state.unlinkedSearchInput,
                                  },
                                  () => {
                                    this.searchUnlinkedItems(
                                      this.state.unlinkedSearchInput
                                    );
                                  }
                                );
                              }}
                            />
                          )}
                        </div>
                        <Box className="LinkUnlink-footer">
                          <Typography>
                            Showing <span>{" "}
                            {this.state.unlinkedSearchInput.trim() === ""
                              ? `${this.state.unlinkedItems.length} / ${this.state.unlinkedCategoryTotal}`
                              : `${this.state.searchedUnlinkedItems.length} / ${this.state.searchedUnlinkedCategoryTotal}`}</span>
                          </Typography>
                        </Box>

                        <SwipeableEdgeDrawer 
                          toggleDrawer={this.toggleShowSelectedUnlinked}
                          open={this.state.showSelectedUnlinked}
                          data={this.state.checkedUnlinkedItems}
                          handleItemClick={this.handleItemClick}
                          type="checkedUnlinkedItems"
                          reference="unlinked-items"
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </div>
            
          </DialogContent>
        </LinkItemDialog>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  sessionId: state.sessionReducer.sessionId,
  selectedCategory: state.categoryReducer.selectedCategory,
  selectedItems: state.itemReducer.selectedItems,
  selectedVendors: state.vendorReducer.selectedVendors || [],
});
export default connect(mapStateToProps, {
  setSelectedVendors,
  openSnackbar,
  setSelectedItem
})(LinkItemToCategory);
