import { Paper, Typography } from "@mui/material";
import styled1 from "styled-components";
import {styled} from "@mui/system";

// export const CategoryEmptyCard = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.grey[0],
//   maxWidth: '100%',
//   height: 'calc(100vh - 45vh)',  
//   textAlign: 'center',
//   display: 'grid',
//   justifyContent: 'center',
//   alignContent: 'center',
//   marginTop: '25px',
//   borderRadius: '5px',

// }));

import React from 'react'

export const CategoryEmptyCard = ({cart, children}) => {
  return (
    <Paper
      sx={{
        backgroundColor: cart ? (theme) => theme.palette.grey[0] : (theme) => theme.palette.grey[50],
        maxWidth: '100%',
        height: 'calc(100vh - 30vh)',  
        textAlign: 'center',
        display: 'grid',
        justifyContent: 'center',
        alignContent: 'center',
        marginTop: '0',
        borderRadius: cart ? '5px' : '0',
        boxShadow: 'none',
      }}
    >
      {children}
    </Paper>
  )
}



export const EmptyCategoryImage = styled1.img`  
  margin: 0 auto 15px auto;
  width: 175px;
`;

export const EmptyCategoryTitle = styled(Typography) (({ theme }) => ({
  '&.MuiTypography-h5, &.MuiTypography-h6': {
    color: theme.palette.text.primary,
    letterSpacing: '0px',
    fontSize: 'calc(16px + (18 - 16) * ((100vw - 300px) / (1920 - 300)))',    
  },
  '&.MuiTypography-h5': {
    fontSize: 'calc(14px - (14 - 16) * ((100vw - 300px) / (1920 - 300)))',
    fontWeight: 700,    
  }

}));
