import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  Chip,
  FilledInput,
  FormControl,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography
} from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import RemoveIcon from "@mui/icons-material/RemoveCircleOutline";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@mui/styles";
import noSearchimg from "../../../assets/images/no-search.svg";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch } from "react-redux";
import { LinkunlinkUser } from "../../../redux/actions/LinkUnlinkUser";

const useStyles = makeStyles((theme) => ({
  // SearchInput: {
  //   "& .css-1vbc0rj-MuiInputBase-input-MuiFilledInput-input": {
  //     padding: 0,
  //   },
  // },
}));

const LinkUserList = ({
  inquiryNameArray,
  filteredUserData,
  linkDataFlag,
  onRemove,
  onRemoveAllFromAccordion,
  onRemoveFromAllRFQ,
  createdByIdArray
}) => {
  const classes = useStyles();
  const [searchText, setSearchText] = useState("");
  const [anchorElMap, setAnchorElMap] = useState({});
  const [selectedItem, setSelectedItem] = useState(null);
  const [expandedIndexes, setExpandedIndexes] = useState([]);
  // const [RfqWithUser, setRfqWithUser] = useState([...filteredUserData]);
  const [RfqWithUser, setRfqWithUser] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setRfqWithUser(filteredUserData);
    dispatch(LinkunlinkUser(filteredUserData));
  }, [filteredUserData]);
  // const [RfqWithUser, setRfqWithUser] = useState([])
  // useEffect(() => {
  //   setRfqWithUser(filteredUserData)
  // }, [filteredUserData])
  const handleAccordionChange = (index, isExpanded) => {
    if (isExpanded) {
      setExpandedIndexes((prevIndexes) => [...prevIndexes, index]);
    } else {
      setExpandedIndexes((prevIndexes) =>
        prevIndexes.filter((prevIndex) => prevIndex !== index)
      );
    }
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleCloseSearch = () => {
    setSearchText("");
  };

  // const customSort = (a, b) => {
  //   console.log("a,b", a, b);
  //   const indexA = createdByIdArray?.indexOf(a.UserID);
  //   const indexB = createdByIdArray?.indexOf(b.UserID);

  //   if (indexA !== -1 && indexB !== -1) {
  //     return indexA - indexB;
  //   }

  //   if (indexA !== -1) {
  //     return -1;
  //   }
  //   if (indexB !== -1) {
  //     return 1;
  //   }
  //   return 0;
  // };

  const sortedAndFilteredData = (inquiryData) => {
    // if (Array.isArray(inquiryData.usersWithInquiryName)) {
      
    if (inquiryData.usersWithInquiryName.length > 0) {
      return inquiryData.usersWithInquiryName.filter((item, index) =>
        item.UserName.toLowerCase().includes(searchText.toLowerCase())
      );
    } else if (searchText && inquiryData.usersWithInquiryName.length == 0) {
      return [];
    } else {
      return inquiryData;
    }
  };

  const MenuRender = (item) => {
    return (
      <Menu
        key={item.index + "_menu_" + item.userId}
        open={Boolean(anchorElMap[item.userId])}
        anchorEl={anchorElMap[item.userId]}
        onClose={(e) => handleClose(item.userId)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
      >
        <MenuItem
          sx={{ p: 1, cursor: "pointer" }}
          onClick={(e) => handleRemoveClick(e, item.userId, item.index)}
        >
          Remove From Selected RFQ
        </MenuItem>
        <MenuItem
          sx={{ p: 1, cursor: "pointer" }}
          onClick={(event) => {
            event.stopPropagation();
            onRemoveFromAllRFQ(item.userId);
            handleClose(item.userId);
          }}
        >
          Remove From All RFQ
        </MenuItem>
      </Menu>
    );
  };

  const handleClick = (e, userId, index) => {
    setSelectedItem({ e, userId, index });
    const updatedAnchorElMap = { ...anchorElMap };
    updatedAnchorElMap[userId] = e.currentTarget;
    setAnchorElMap(updatedAnchorElMap);
  };

  const handleRemoveClick = (e, userId, index) => {
    e.stopPropagation();
    onRemove(e, userId, index);
    handleClose(userId);
  };

  const handleRemoveSingleLinkUser = (e, userId, index) => {
    e.stopPropagation();
    onRemove(e, userId, index);
    handleClose(userId);
  };

  const handleClose = (userId) => {
    const updatedAnchorElMap = { ...anchorElMap };
    delete updatedAnchorElMap[userId];
    setAnchorElMap(updatedAnchorElMap);
  };

  const handleRemoveAllFromCurrentAccordion = (index) => {
    const updatedRfqWithUser = [...RfqWithUser]; // Create a copy of RfqWithUser
    updatedRfqWithUser.splice(index, 1); // Remove the element at the specified index
    setRfqWithUser(updatedRfqWithUser);
    dispatch(LinkunlinkUser(updatedRfqWithUser));
  };
  return (
    <Box>
      <Card className="LinkUnlinkCard" variant="outlined">
        <CardContent>
          <CardActions sx={{ display: { xs: "none", sm: "none", md: "flex" } }}>
            <Typography variant="h6" component="div" className="LinkCardTitle">
              RFQ NO
            </Typography>
          </CardActions>
          <FormControl sx={{ width: "100%", p: 0 }}>
            <FilledInput
              value={searchText}
              className={classes.SearchInput}
              placeholder="Search"
              startAdornment={
                <InputAdornment position="start">
                  <SearchOutlinedIcon />
                </InputAdornment>
              }
              endAdornment={
                searchText !== "" && (
                  <IconButton
                    className="search-clear-btn"
                    size="small"
                    onClick={handleCloseSearch}
                  >
                    <ClearIcon fontSize="inherit" />
                  </IconButton>
                )
              }
              onChange={handleSearchChange}
            />
          </FormControl>
          <Box className="link-items-list">
            {RfqWithUser?.some((inquiryData) => {
              const filteredInquiryData = sortedAndFilteredData(inquiryData);
              return filteredInquiryData;
            }) ? (
              RfqWithUser.map((inquiryData, index) => {
                const filteredUser = inquiryData.usersWithInquiryName.filter((item) => {
                  // Check if the UserID is not in createdByArray
                  return item.UserID != createdByIdArray[index] ;
                });
                const updatedInquiryData = {
                  ...inquiryData,
                  usersWithInquiryName: filteredUser
              };
                const { usersWithInquiryName, inquiryName } = updatedInquiryData;
                
                const filteredInquiryData = sortedAndFilteredData(updatedInquiryData);

                if (filteredInquiryData.length > 0) {
                  return (
                    <Accordion
                      key={index}
                      expanded={expandedIndexes.includes(index)}
                      onChange={(event, isExpanded) =>
                        handleAccordionChange(index, isExpanded)
                      }
                      className="RFQNo-ItemList"
                    >
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography
                          variant="body2"
                          fontWeight={500}
                          color="text.primary"
                          component="div"
                          sx={{ width: "calc(100% - 32px)" }}
                          noWrap
                        >
                          {inquiryData.inquiryName}
                          {/* {inquiryName} */}
                        </Typography>
                        {linkDataFlag === undefined ||
                          linkDataFlag === false ? (
                          <Tooltip title="Remove">
                            <IconButton
                              edge="end"
                              aria-label="remove"
                              color="error"
                              className="RemoveBtn"
                              onClick={(event) => {
                                event.stopPropagation();
                                handleRemoveAllFromCurrentAccordion(index);
                              }}
                            >
                              <RemoveIcon />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          ""
                        )}
                      </AccordionSummary>
                      <AccordionDetails sx={{ p: 0 }}>
                        <List className="RFQItemsList">
                          {usersWithInquiryName
                            .sort(function (x, y) {
                              return x.UserID == createdByIdArray[index]
                                ? -1
                                : y.UserID == createdByIdArray[index]
                                  ? 1
                                  : 0;
                            })
                            ?.map((item, itemIndex) =>
                              typeof linkDataFlag == "undefined" ||
                                linkDataFlag == false ? (
                                // True condition
                                <ListItem
                                  key={item.UserID}
                                  secondaryAction={
                                    // itemIndex !== 0 ? (
                                    item.UserID != createdByIdArray[index] ? (
                                      <Tooltip title="Remove">
                                        <IconButton
                                          edge="end"
                                          aria-label="comments"
                                          color="error"
                                          aria-describedby={item.UserID}
                                          variant="contained"
                                          onClick={(e) => {
                                            handleClick(e, item.UserID, index);
                                          }}
                                        >
                                          <RemoveIcon />
                                        </IconButton>
                                      </Tooltip>
                                    ) : (
                                      <Chip
                                        label="Floated By"
                                        size="xsmall"
                                        variant="filled"
                                      />
                                    )
                                  }
                                >
                                  <ListItemAvatar>
                                    <Avatar
                                      alt={item.UserName}
                                      src="/static/images/avatar/1.jpg"
                                      sx={{
                                        background: "var(--color-primary)"
                                      }}
                                    />
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={item.UserName}
                                    secondary={item.Email}
                                  />
                                </ListItem>
                              ) : (
                                // False condition
                                <ListItem
                                  key={item.UserID}
                                  secondaryAction={
                                    // itemIndex !== 0 ? (
                                    item.UserID != createdByIdArray[index] ? (
                                      <Tooltip title="Remove">
                                        <IconButton
                                          edge="end"
                                          aria-label="comments"
                                          color="error"
                                          aria-describedby={item.UserID}
                                          variant="contained"
                                          onClick={(e) =>
                                            handleRemoveSingleLinkUser(
                                              e,
                                              item.UserID,
                                              index
                                            )
                                          }
                                        >
                                          <RemoveIcon />
                                        </IconButton>
                                      </Tooltip>
                                    ) : (
                                      <Chip
                                        label="Floated By"
                                        size="xsmall"
                                        variant="filled"
                                      />
                                    )
                                  }
                                >
                                  <ListItemAvatar>
                                    <Avatar
                                      alt={item.UserName}
                                      src="/static/images/avatar/1.jpg"
                                      sx={{
                                        background: "var(--color-primary)"
                                      }}
                                    />
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={item.UserName}
                                    secondary={item.Email}
                                  />
                                </ListItem>
                              )
                            )}
                          {selectedItem && (
                            <MenuRender
                              userId={selectedItem.userId}
                              index={selectedItem.index}
                            />
                          )}
                        </List>
                      </AccordionDetails>
                    </Accordion>
                  );
                } else if (Object.keys(filteredInquiryData).length > 0) {
                  return (
                    <Accordion
                      key={index}
                      expanded={expandedIndexes.includes(index)}
                      onChange={(event, isExpanded) =>
                        handleAccordionChange(index, isExpanded)
                      }
                      className="RFQNo-ItemList"
                    >
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography
                          variant="body2"
                          fontWeight={500}
                          color="text.primary"
                          component="div"
                          sx={{ width: "calc(100% - 32px)" }}
                          noWrap
                        >
                          {inquiryData.inquiryName}
                          {/* {inquiryName} */}
                        </Typography>
                        {linkDataFlag === undefined ||
                          linkDataFlag === false ? (
                          <Tooltip title="Remove">
                            <IconButton
                              edge="end"
                              aria-label="remove"
                              color="error"
                              className="RemoveBtn"
                              onClick={(event) => {
                                event.stopPropagation();
                                handleRemoveAllFromCurrentAccordion(index);
                              }}
                            >
                              <RemoveIcon />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          ""
                        )}
                      </AccordionSummary>
                      <AccordionDetails sx={{ p: 0 }}>
                        <List className="RFQItemsList">
                          {usersWithInquiryName
                            .sort(function (x, y) {
                              // console.log(
                              //   x.UserID,
                              //   y.UserID,
                              //   createdByIdArray[index]
                              // );
                              return x.UserID == createdByIdArray[index]
                                ? -1
                                : y.UserID == createdByIdArray[index]
                                  ? 1
                                  : 0;
                            })
                            ?.map((item, itemIndex) =>
                              linkDataFlag === undefined ||
                                linkDataFlag === false ? (
                                // True condition
                                <ListItem
                                  key={item.UserID}
                                  secondaryAction={
                                    // itemIndex !== 0 ? (
                                    item.UserID !== createdByIdArray[index] ? (
                                      <Tooltip title="Remove">
                                        <IconButton
                                          edge="end"
                                          aria-label="comments"
                                          color="error"
                                          aria-describedby={item.UserID}
                                          variant="contained"
                                          onClick={(e) =>
                                            handleClick(e, item.UserID, index)
                                          }
                                        >
                                          <RemoveIcon />
                                        </IconButton>
                                        <Menu
                                          id={item.UserID}
                                          open={Boolean(
                                            anchorElMap[item.UserID]
                                          )}
                                          anchorEl={anchorElMap[item.UserID]}
                                          onClose={() =>
                                            handleClose(item.UserID)
                                          }
                                          anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "center"
                                          }}
                                          transformOrigin={{
                                            vertical: "top",
                                            horizontal: "left"
                                          }}
                                        >
                                          <MenuItem
                                            sx={{ p: 1, cursor: "pointer" }}
                                            onClick={() => {
                                              onRemove(item.UserID);
                                              handleClose(item.UserID);
                                            }}
                                          >
                                            Remove From Selected RFQ
                                          </MenuItem>
                                          <MenuItem
                                            sx={{ p: 1, cursor: "pointer" }}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                              onRemoveFromAllRFQ(item.UserID);
                                            }}
                                          >
                                            Remove From All RFQ
                                          </MenuItem>
                                        </Menu>
                                      </Tooltip>
                                    ) : (
                                      <Chip
                                        label="Floated By"
                                        size="xsmall"
                                        variant="filled"
                                      />
                                    )
                                  }
                                >
                                  <ListItemAvatar>
                                    <Avatar
                                      alt={item.UserName}
                                      src="/static/images/avatar/1.jpg"
                                      sx={{
                                        background: "var(--color-primary)"
                                      }}
                                    />
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={item.UserName}
                                    secondary={item.Email}
                                  />
                                </ListItem>
                              ) : (
                                // False condition
                                <ListItem
                                  key={item.UserID}
                                  secondaryAction={
                                    // itemIndex !== 0 ? (
                                    item.UserID != createdByIdArray[index] ? (
                                      <Tooltip title="Remove">
                                        <IconButton
                                          edge="end"
                                          aria-label="comments"
                                          color="error"
                                          aria-describedby={item.UserID}
                                          variant="contained"
                                          onClick={(e) => onRemove(item.UserID)}
                                        >
                                          <RemoveIcon />
                                        </IconButton>
                                      </Tooltip>
                                    ) : (
                                      <Chip
                                        label="Floated By"
                                        size="xsmall"
                                        variant="filled"
                                      />
                                    )
                                  }
                                >
                                  <ListItemAvatar>
                                    <Avatar
                                      alt={item.UserName}
                                      src="/static/images/avatar/1.jpg"
                                      sx={{
                                        background: "var(--color-primary)"
                                      }}
                                    />
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={item.UserName}
                                    secondary={item.Email}
                                  />
                                </ListItem>
                              )
                            )}
                        </List>
                      </AccordionDetails>
                    </Accordion>
                  );
                }
                return null;
              })
            ) : (
              <Box className="no-search">
                <img src={noSearchimg} />
                <Typography component="p">
                  {searchText !== "" ? (
                    <>
                      There are no Link User like <b>{searchText}</b>
                    </>
                  ) : (
                    "There are no Link User"
                  )}
                </Typography>
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default LinkUserList;
