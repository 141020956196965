import React, { useState, useEffect, Fragment } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Snackbar,
  Tab,
  Tabs,
} from "@mui/material";
import UnlinkUserLists from "./unlinkUserList";
import LinkUserLists from "./linkUserList";
import { LinkItemDialog } from "./style";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setRfqListInfo } from "../../../redux/actions/rfqListAction";
import {
  getAccountModulePermission,
  getUserInfo,
  get_Inquiries_data,
} from "../../../config/apiUrl";
import {
  OPEN_SNACKBAR,
  SET_ACCOUNT_MODULE_PERMISSION,
  SET_ACCOUNT_MODULE_PERMISSION_ERROR,
  SET_USER_INFO,
  SET_USER_INFO_ERROR,
} from "../../../redux/constants/type";
import { LinkunlinkUser, SelctedUserId } from "../../../redux/actions/LinkUnlinkUser";
import { BrowserView, MobileView } from "react-device-detect";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Box>
  );
}

export default function LinkUnlinkUser({
  sessionID,
  openDialogueBox,
  handleCloseDialogue,
  userData,
  linkDataFlag,
  // linkUnlinkData,
  rowSelectionModel,
  SelctedRFQ,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [value, setValue] = useState(0);
  const [filteredUserData, setFilteredUserData] = useState([]);
  const [remainingUserData, setRemainingUserData] = useState([]);
  const selectedUserIds = useSelector(
    (state) => state.LinkUnlinkUserReducer.selectedUserIds
  );
 
  const [removeUserId, setRemoveUserId] = useState([]);
  const [expandedAccordion, setExpandedAccordion] = useState(null); // State to store expanded accordion
  const [AccountPermission, setAccountPermission] = useState({});
  const [Profile, setProfile] = useState({});
  const [HasPerformedUpdate, setHasPerformedUpdate] = useState(false);
  const RFQListData = useSelector((state) => state.rfqListReducer.rfqListInfo);
  const [linkUnlinkData, setLinkUnlinkData] = React.useState({});
  const [snackbar, setSnackbar] = useState(false);
  const [severity, setseverity] = React.useState("");
  const [SnackMessage, setSnackMessage] = React.useState("");
  const { inquiryName, inquiryNo, linkedUserId, createdBy } = linkUnlinkData;
  const configAxios = {
    headers: { Authorization: `Bearer ${sessionID}` },
  };
  const RfqWithUser = useSelector(
    (state) => state.LinkUnlinkUserReducer.RfqWithUser
  );
  const dispatch = useDispatch();
  const inquiryNameArray = inquiryName ? inquiryName.split(", ") : [];
  const inquiryNoArray = inquiryNo
    ? typeof inquiryNo === "number"
      ? [inquiryNo]
      : inquiryNo.split(", ")
    : [];
  const linkedUserIdArray = linkedUserId ? linkedUserId.split(", ") : [];
  // const createdByArray = createdBy ? createdBy.split(", ") : [];

  const createdByArray =
    typeof createdBy === "number" ? createdBy.toString().split(", ") : [];

  // const createdByIdArray = createdBy
  //   ? createdBy?.split(",").map((id) => id.trim())
  //   : [];

  // const createdByIdArray =
  //   createdBy && typeof createdBy === "string"
  //     ? createdBy.split(",").map((id) => id.trim())
  //     : [];

  let createdByIdArray;

  if (typeof createdBy === "string") {
    if (createdBy.includes(",")) {
      createdByIdArray = createdBy.split(",").map((id) => id.trim());
    } else {
      createdByIdArray = [createdBy.trim()];
    }
  } else if (typeof createdBy === "number") {
    createdByIdArray = [createdBy.toString()];
  } else {
    createdByIdArray = [];
  }
  React.useEffect(() => {
    if (SelctedRFQ?.length > 0 && value == 0 && openDialogueBox) {
      const filteredDta = RFQListData.filter((item) =>
        SelctedRFQ.includes(parseInt(item.id))
      );
      const updatedLinkUnlinkData = {
        // ...initialLinkUnlinkData,
        inquiryName: filteredDta.map((row) => row.number).join(", "),
        inquiryNo: filteredDta.map((row) => row.id).join(", "),
        linkedUserId: filteredDta.map((row) => row.linked_user_ids).join(", "),
        createdBy: filteredDta.map((row) => row.created_by).join(", "),
      };
      setLinkUnlinkData(updatedLinkUnlinkData);
    }
  }, [RFQListData, value, SelctedRFQ, openDialogueBox]);

  const linkedUserWithCreatedBy = linkedUserIdArray?.map(
    (e, i) => e + "," + createdByIdArray[i]
  );

  useEffect(() => {
    const filteredData = linkedUserWithCreatedBy?.map((linkedIds, index) => {
      const ids = linkedIds.split(",").map((id) => id.trim());
      const usersWithInquiryName = userData
        .filter((user) => ids.includes(user.UserID.toString()))
        .map((user) => ({ ...user, inquiryName: inquiryNameArray[index] }));
      return {
        inquiryName: inquiryNameArray[index],
        usersWithInquiryName,
        inquiryNo: inquiryNoArray[index],
      };
    });

    const reducingAray = linkedUserWithCreatedBy.map((str) =>
      str.split(",").map((id) => parseInt(id, 10))
    );

    // Find common IDs
    const commonIds = reducingAray.reduce((common, current) => {
      if (common.length === 0) {
        return current;
      }
      return common.filter((id) => current.includes(id));
    }, []);
    const commonIdsStringArray = commonIds.map((id) => id.toString());

    const remainingData = userData?.filter(
      (user) => !commonIdsStringArray.includes(user.UserID.toString())
    );
    setFilteredUserData(filteredData);
    setRemainingUserData(remainingData);
  }, [userData, linkedUserId]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCloseSnackbar = () => {
    setSnackbar(false);
  };

  const handleRemove = (e, userId, accordionIndex) => {
    // Create a copy of the filteredUserData array
    const updatedFilteredData = [...RfqWithUser];

    // const accordionIndex = updatedFilteredData?.findIndex((inquiryData) =>
    //   inquiryData?.usersWithInquiryName?.some((user) => user.UserID == userId)
    // );
    // setRemoveUserId([...removeUserId, userId]);

    if (accordionIndex !== -1) {
      // Filter out the user with the given userId from the accordion data
      updatedFilteredData[accordionIndex].usersWithInquiryName =
        updatedFilteredData[accordionIndex].usersWithInquiryName.filter(
          (user) => user.UserID != userId
        );
      // Update the filteredUserData state
      dispatch(LinkunlinkUser(updatedFilteredData));
      // setFilteredUserData(updatedFilteredData);

      // Find the user that was removed
      const removedUser = userData.find((user) => user.UserID == userId);
      const isUserPresent = updatedFilteredData.some((item) =>
        item.usersWithInquiryName.some(
          (user) => user.UserID == removedUser.UserID
        )
      );

      if (removedUser) {
        const filteredUserIdsArray = selectedUserIds.filter(
          (userId) => userId != removedUser.UserID
        );
        dispatch(SelctedUserId(filteredUserIdsArray));

        setRemainingUserData((prevRemainingUserData) => {
          // Check if the user with the same UserID is already present in the array
          const isUserPresent = prevRemainingUserData.some(
            (user) => user.UserID == removedUser.UserID
          );
        
          // If the user is not present, update the state
          if (!isUserPresent) {
            return [
              ...prevRemainingUserData,
              removedUser,
            ];
          }
        
          // If the user is already present, return the current state without any changes
          return prevRemainingUserData;
        });
      }
    } else {
      console.error("Accordion not found for user with ID:", userId);
    }
  };

  const handleClickCancel = () => {
    // setFilteredUserData([]);
    // setRemainingUserData([]);
    setRemoveUserId([]);
    dispatch(SelctedUserId([]));
    setExpandedAccordion([]);
    setValue(0);
    setLinkUnlinkData({});
    handleCloseDialogue();
  };

  function arraysAreEqual(array1, array2) {
    return (
      array1.length === array2.length &&
      array1.every((value, index) => value === array2[index])
    );
  }

  useEffect(() => {
    if (selectedUserIds.length > 0) {
      let updatedInquiryNumbers;
      if (typeof inquiryNo === "number") {
        updatedInquiryNumbers = [inquiryNo.toString()];
      } else if (typeof inquiryNo === "string") {
        updatedInquiryNumbers = inquiryNo.split(", ");
      } else {
        updatedInquiryNumbers = inquiryNo;
      }
      const resultArray = updatedInquiryNumbers?.filter((inquiryNo) =>
        RfqWithUser.some((item) => item.inquiryNo == inquiryNo)
      );
      const updatedFilteredData = RfqWithUser?.map((inquiryData, index) => {
        //   if (
        //     successfullyUpdatedInquiries.includes(updatedInquiryNumbers[index])
        //   ) {
        const userIdsInInquiry = inquiryData.usersWithInquiryName.map(
          (user) => user.UserID
        );
        const updatedUserIds = selectedUserIds.filter(
          (userId) => !userIdsInInquiry.includes(userId)
        );
        const usersToAdd = userData.filter((user) =>
          updatedUserIds.includes(user.UserID)
        );

        // Update the usersWithInquiryName array in inquiryData
        inquiryData.usersWithInquiryName = [
          ...inquiryData.usersWithInquiryName,
          ...usersToAdd,
        ];

        return inquiryData;
      });
      dispatch(LinkunlinkUser(updatedFilteredData));

      const filteredArray = remainingUserData.filter(
        (item) => !selectedUserIds.includes(item.UserID)
      );
      if (!arraysAreEqual(filteredArray, remainingUserData)) {
        setRemainingUserData(filteredArray);
      }
    }
   
  }, [selectedUserIds, inquiryNo]);

  const handleRemoveAllFromAccordion = (index) => {
    let updatedFilteredData = filteredUserData.filter((element, key, array) => {
      return key !== index;
    });

    setFilteredUserData(updatedFilteredData);
  };

  const handleUserSelect = (selectedIds) => {
    dispatch(SelctedUserId(selectedIds));

  };

  const fetchPermissions = () => {
    axios.defaults.headers["Authorization"] = `Bearer ${sessionID}`;
    const url = getUserInfo;
    axios
      .get(url)
      .then((res) => {
        setProfile(res.data);
        dispatch({
          type: SET_USER_INFO,
          payload: res.data,
        });
        const modulepermisson = getAccountModulePermission(
          res.data.data.division_id
        );
        axios
          .get(modulepermisson)
          .then((res) => {
            setAccountPermission(res.data);
            dispatch({
              type: SET_ACCOUNT_MODULE_PERMISSION,
              payload: res.data,
            });
          })
          .catch((err) => {
            dispatch({
              type: SET_ACCOUNT_MODULE_PERMISSION_ERROR,
              payload: err.message,
            });
          });
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          setSnackbar(true);
          setseverity("error");
          setSnackMessage(
            "Your Login session could not be validated. Please Login again..."
          );
          // dispatch({
          //   type: OPEN_SNACKBAR,
          //   payload: {
          //     type: "error",
          //     msg: "Your Login session could not be validated. Please Login again...",
          //   },
          // });
          dispatch({
            type: SET_USER_INFO_ERROR,
            payload:
              "Your Login session could not be validated. Please Login again...",
          });
        } else {
          setSnackbar(true);
          setseverity("error");
          setSnackMessage(
            error?.response?.data.message
              ? error?.response?.data.message
              : "Something went wrong"
          );
          // dispatch({
          //   type: OPEN_SNACKBAR,
          //   payload: {
          //     type: "error",
          //     msg: error?.response?.data.message
          //       ? error?.response?.data.message
          //       : "Something went wrong",
          //   },
          // });
          dispatch({
            type: SET_USER_INFO_ERROR,
            payload: error.message,
          });
        }
      });
  };

  useEffect(() => {
    setHasPerformedUpdate(false);
    if (Object.keys(AccountPermission).length > 0) {
      if (
        AccountPermission?.data?.["Transaction Access"]?.module_activated == "Y"
      ) {
        LinkUser();
        setHasPerformedUpdate(true);
        setRemoveUserId([]);
      } else {
        setHasPerformedUpdate(true);
        setSnackbar(true);
        setseverity("error");
        setSnackMessage("You don't have permission to link/unlink user");
        // dispatch({
        //   type: OPEN_SNACKBAR,
        //   payload: {
        //     type: "error",
        //     msg: "You don't have permission to link/unlink user",
        //   },
        // });
      }
    }
  }, [Profile, AccountPermission]);

  useEffect(() => {
    if (HasPerformedUpdate) {
      setProfile({});
      setAccountPermission({});
    }
  }, [HasPerformedUpdate]);

  const handleAssign = async () => {
    if (RfqWithUser.length > 0) {
      fetchPermissions();
    } else {
      setSnackbar(true);
      setseverity("error");
      setSnackMessage("No RFQ selected.");
    }
  };

  const LinkUser = async () => {
    let successfullyUpdatedInquiries = []
    try {
      let updatedInquiryNumbers;
      if (typeof inquiryNo === "number") {
        updatedInquiryNumbers = [inquiryNo.toString()];
      } else if (typeof inquiryNo === "string") {
        updatedInquiryNumbers = inquiryNo.split(", ");
      } else {
        updatedInquiryNumbers = inquiryNo;
      }
      const resultArray = updatedInquiryNumbers.filter((inquiryNo) =>
        RfqWithUser.some((item) => item.inquiryNo == inquiryNo)
      );
      if (resultArray.length > 1) {
        try {

          const transformedData = RfqWithUser.reduce((result, item, massIndex) => {
            const { inquiryNo, usersWithInquiryName } = item;
        
            const userIds = usersWithInquiryName.map((user) => user.UserID);
            const indexCreated = linkUnlinkData.inquiryNo
            .split(", ")
            .findIndex((data) => {
              return data == item.inquiryNo;
            });
  
            userIds.push(
              parseInt(linkUnlinkData.createdBy.split(",")[indexCreated])
            )
            result.mass_linked_users[inquiryNo] =  [...new Set(userIds)];
            
            return result;
          }, { mass_linked_users: {} });
          await axios.patch(
            `${get_Inquiries_data}mass_link_unlink_users`,
              transformedData,
              configAxios
          );
          successfullyUpdatedInquiries = Object.keys(transformedData.mass_linked_users).flat()
          console.log('transformedData', Object.keys(transformedData.mass_linked_users).flat());
        } catch (err) {
          console.log('err', err);
        }
      } else {

        const patchRequests = resultArray?.map(async (inquiryNumber) => {
          try {
            // const linkedUserIdsForInquiry = linkedUserWithCreatedBy[
            //   updatedInquiryNumbers.indexOf(inquiryNumber)
            // ]
            //   .split(",")
            //   .map((id) => id.trim());
            const combinedUserIds = RfqWithUser.filter(
              (item) => item.inquiryNo == inquiryNumber
            )
              .map((item) => item.usersWithInquiryName.map((user) => user.UserID))
              .flat();
            // console.log('combinedUserIds', combinedUserIds);
            // return false;
            const index = linkUnlinkData.inquiryNo
              .split(", ")
              .findIndex((data) => {
                return data == inquiryNumber;
              });
            combinedUserIds.push(
              parseInt(linkUnlinkData.createdBy.split(",")[index])
            );
  
              const response = await axios.patch(
                `${get_Inquiries_data}${inquiryNumber}/link_unlink_users`,
                {
                  linked_user: [...new Set(combinedUserIds)],
                },
                configAxios
              );
            
            return inquiryNumber;
          } catch (error) {
            console.error(
              `Error assigning users for inquiry number ${inquiryNumber}:`,
              error
            );
            throw error;
          }
        });
        successfullyUpdatedInquiries = await Promise.all(patchRequests);
      }


      // const updatedFilteredData = filteredUserData?.map(
      //   (inquiryData, index) => {
      //     if (
      //       successfullyUpdatedInquiries.includes(updatedInquiryNumbers[index])
      //     ) {
      //       const userIdsInInquiry = inquiryData?.map((user) => user.UserID);
      //       const updatedUserIds = selectedUserIds.filter(
      //         (userId) => !userIdsInInquiry.includes(userId)
      //       );
      //       return [
      //         ...inquiryData,
      //         ...userData.filter((user) =>
      //           updatedUserIds.includes(user.UserID)
      //         ),
      //       ];
      //     } else {
      //       return inquiryData;
      //     }
      //   }
      // );

      const updatedFilteredData = RfqWithUser?.map((inquiryData, index) => {
        if (
          successfullyUpdatedInquiries.includes(updatedInquiryNumbers[index])
        ) {
          const userIdsInInquiry = inquiryData.usersWithInquiryName.map(
            (user) => user.UserID
          );
          const updatedUserIds = selectedUserIds.filter(
            (userId) => !userIdsInInquiry.includes(userId)
          );
          const usersToAdd = userData.filter((user) =>
            updatedUserIds.includes(user.UserID)
          );

          // Update the usersWithInquiryName array in inquiryData
          inquiryData.usersWithInquiryName = [
            ...inquiryData.usersWithInquiryName,
            ...usersToAdd,
          ];

          return inquiryData;
        } else {
          return inquiryData;
        }
      });

      dispatch(SelctedUserId([]));

      // setFilteredUserData(updatedFilteredData);
      dispatch(LinkunlinkUser(updatedFilteredData));
      setSnackbar(true);
      setseverity("success");
      setSnackMessage("Linked user has been successfully updated");
      handleCloseDialogue();
      dispatch(setRfqListInfo(sessionID)); //Test purpose only Has to change this Only Rejected RFQ List is coming
    } catch (error) {
      console.error("Error assigning users:", error);
    }
    setRemoveUserId([]);
  };

  const handleRemoveFromAllRFQ = (userId) => {
    // Iterate through each accordion
    // let removeUser = []
    for (let i = 0; i < RfqWithUser.length; i++) {
      const accordionData = RfqWithUser[i].usersWithInquiryName;
      // Check if the user with the given userId is in this accordion
      const userIndex = accordionData.findIndex(
        (user) => user.UserID == userId
      );

      // const CreatedBYIndex = createdByIdArray.findIndex(
      //   (user) => user == userId
      //   );
      const CreatedBYIndex = createdByIdArray
        .map((user, index) => {
          if (user == userId) {
            return index;
          }
          return -1; // Return -1 for non-matching elements
        })
        .filter((index) => index != -1);

      // const existuser = [...userId]
      //   removeUser.push(existuser)
      // setRemoveUserId([...removeUserId, userId]);

      if (userIndex !== -1 && !CreatedBYIndex.includes(i)) {
        RfqWithUser[i].usersWithInquiryName = accordionData.filter(
          (user) => user.UserID != userId
        );
      }
    }

    // Update the filteredUserData state
    dispatch(LinkunlinkUser([...RfqWithUser]));

    // setFilteredUserData([...filteredUserData]);

    // Find the user that was removed
    const removedUser = userData.find((user) => user.UserID == userId);
    if (removedUser) {
      const filteredUserIdsArray = selectedUserIds.filter(
        (userId) => userId != removedUser.UserID
      );
      dispatch(SelctedUserId(filteredUserIdsArray));

      setRemainingUserData((prevRemainingUserData) => {
        // Check if the user with the same UserID is already present in the array
        const isUserPresent = prevRemainingUserData.some(
          (user) => user.UserID == removedUser.UserID
        );
      
        // If the user is not present, update the state
        if (!isUserPresent) {
          return [
            ...prevRemainingUserData,
            removedUser,
          ];
        }
      
        // If the user is already present, return the current state without any changes
        return prevRemainingUserData;
      });
    }
    // if (removedUser) {
    //   const filteredUserIdsArray = selectedUserIds.filter(
    //     (userId) => userId != removedUser.UserID
    //   );
    //   dispatch(SelctedUserId(filteredUserIdsArray));
    // }
    // const isUserPresent = RfqWithUser.some((item) =>
    //   item.usersWithInquiryName.some(
    //     (user) => user.UserID == removedUser.UserID
    //   )
    // );
    // if (removedUser && !isUserPresent) {
    //   // Update the remainingUserData by adding the removed user back
    //   setRemainingUserData((prevRemainingUserData) => [
    //     ...prevRemainingUserData,
    //     removedUser,
    //   ]);
    // }
  };

  return (
    <Fragment>
      <LinkItemDialog
        fullScreen={fullScreen}
        open={openDialogueBox}
        onClose={handleCloseDialogue}
        className="RFQ-linkDialog"
      >
        <DialogTitle>
          Link Unlink User

        </DialogTitle>
        <DialogContent sx={{ pb: "0" }}>
          <div className="linkcategorydialog_main">
            <BrowserView>
              {/* Start Desktop View */}
              <Box
                className="LinkUser-Grid"
                sx={{ display: { xs: "none", sm: "none", md: "block" } }}
              >
                <Grid container spacing={2}>
                  <Grid item md={6} sm={6} xs={12} sx={{ mt: 2 }}>
                    <LinkUserLists
                      filteredUserData={filteredUserData}
                      inquiryNameArray={inquiryNameArray}
                      onRemove={handleRemove}
                      linkDataFlag={linkDataFlag}
                      // onRemoveAllFromAccordion={handleRemoveAllFromAccordion}
                      onRemoveFromAllRFQ={handleRemoveFromAllRFQ}
                      expandedAccordion={expandedAccordion}
                      createdByArray={createdByArray} //createdByIdArray
                      createdByIdArray={createdByIdArray} //createdByIdArray
                      setExpandedAccordion={setExpandedAccordion}
                    />
                  </Grid>
                  <Grid item md={6} sm={6} xs={12} sx={{ mt: 2 }}>
                    <UnlinkUserLists
                      remainingUserData={remainingUserData}
                      onUserSelect={handleUserSelect}
                      checkedUserIds={selectedUserIds}

                    />
                  </Grid>
                </Grid>
              </Box>
              {/* End Desktop View */}
            </BrowserView>

            <MobileView>
              {/* Start Mobile View */}
              <Box
                className="LinkUser-Tabs"
                sx={{ display: { xs: "block", sm: "block", md: "none" } }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="RFQ NO" />
                  <Tab label="USERS" />
                </Tabs>
                <TabPanel value={value} index={0} className="tabs-panel">
                  <LinkUserLists
                    filteredUserData={filteredUserData}
                    inquiryNameArray={inquiryNameArray}
                    createdByArray={createdByArray}
                    createdByIdArray={createdByIdArray}
                    onRemove={handleRemove}
                    linkDataFlag={linkDataFlag}
                    onRemoveAllFromAccordion={handleRemoveAllFromAccordion}
                    onRemoveFromAllRFQ={handleRemoveFromAllRFQ}
                    expandedAccordion={expandedAccordion}
                    setExpandedAccordion={setExpandedAccordion}
                  />
                </TabPanel>
                <TabPanel value={value} index={1} className="tabs-panel">
                  <UnlinkUserLists
                    remainingUserData={remainingUserData}
                    onUserSelect={handleUserSelect}
                    checkedUserIds={selectedUserIds}
                  />
                </TabPanel>
              </Box>
              {/* End Mobile View */}
            </MobileView>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClickCancel}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleAssign}>
            SAVE CHANGES
          </Button>
        </DialogActions>
      </LinkItemDialog>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={3000}
        open={snackbar}
        onClose={handleCloseSnackbar}
      // message="Validate Date Updated Successfully"
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={severity}
          variant="filled"
        >
          {SnackMessage}
        </Alert>
      </Snackbar>
    </Fragment>
  );
}
