import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  styled,
  Button,
  LinearProgress,
  Chip,
  Typography,
  Menu,
  Tooltip,
  Skeleton,
  ButtonGroup,
  Popper,
  Paper,
  IconButton,
  Grow,
  MenuList,
  MenuItem,
  Grid,
} from "@mui/material";
import {
  DataGridPro,
  GridToolbarQuickFilter,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  useGridApiContext,
  gridColumnsTotalWidthSelector,
  gridColumnPositionsSelector,
  GridFooterContainer,
  GridFooter,
} from "@mui/x-data-grid-pro";
export default function CommonDataGridPro(props) {
  const [pageSize, setPageSize] = useState(15);
  const SkeletonCell = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.divider}`,
  }));
  function mulberry32(a) {
    return () => {
      /* eslint-disable */
      let t = (a += 0x6d2b79f5);
      t = Math.imul(t ^ (t >>> 15), t | 1);
      t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
      return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
      /* eslint-enable */
    };
  }
  function randomBetween(seed, min, max) {
    const random = mulberry32(seed);
    return () => min + (max - min) * random();
  }
  function CustomFooter() {
    if (typeof props.label != "undefined") {
      return (
        <GridFooterContainer
          sx={{ justifyContent: "end" }}
          className="GridFooterContainerInvoice"
        >
          <Typography variant="h7">
            {props.label} : {props.lineCount}
          </Typography>
          {/* Add what you want here */}
          <GridFooter
            sx={{
              border: "none", // To delete double border.
            }}
          />
        </GridFooterContainer>
      );
    } else {
      return (
        <GridFooterContainer
          sx={{ justifyContent: "end" }}
          className="GridFooterContainerInvoice"
        >
          {/* <Typography variant="h7">{props.label} : {props.lineCount}</Typography> */}
          {/* Add what you want here */}
          <GridFooter
            sx={{
              border: "none", // To delete double border.
            }}
          />
        </GridFooterContainer>
      );
    }
  }
  function SkeletonLoadingOverlay() {
    const apiRef = useGridApiContext();

    const dimensions = apiRef.current?.getRootDimensions();
    const viewportHeight = dimensions?.viewportInnerSize.height ?? 0;

    const rowHeight = apiRef.current.unstable_getRowHeight();
    const skeletonRowsCount = Math.ceil(viewportHeight / rowHeight);

    const totalWidth = gridColumnsTotalWidthSelector(apiRef);
    const positions = gridColumnPositionsSelector(apiRef);
    const inViewportCount = React.useMemo(
      () => positions.filter((value) => value <= totalWidth).length,
      [totalWidth, positions]
    );
    const columns = apiRef.current
      .getVisibleColumns()
      .slice(0, inViewportCount);

    const children = React.useMemo(() => {
      // reseed random number generator to create stable lines betwen renders
      const random = randomBetween(12345, 25, 75);
      const array = [];

      for (let i = 0; i < skeletonRowsCount; i += 1) {
        for (const column of columns) {
          const width = Math.round(random());
          array.push(
            <SkeletonCell
              key={`column-${i}-${column.field}`}
              sx={{ justifyContent: column.align }}
            >
              <Skeleton sx={{ mx: 1 }} width={`${width}%`} />
            </SkeletonCell>
          );
        }
        array.push(<SkeletonCell key={`fill-${i}`} />);
      }
      return array;
    }, [skeletonRowsCount, columns]);

    const rowsCount = apiRef.current.getRowsCount();
    return rowsCount > 0 ? (
      <LinearProgress />
    ) : (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: `${columns
            .map(({ computedWidth }) => `${computedWidth}px`)
            .join(" ")} 1fr`,
          gridAutoRows: rowHeight,
        }}
      >
        {children}
      </div>
    );
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer className="datagrid-toolbar">
        <Grid
          container
          spacing={0}
          sx={{
            width: "100%",
            pt: 1,
            "&.MuiGrid2-root": {
              paddingTop: { xs: "0", sm: "0", md: "8px" },
            },
          }}
        >
          <Grid xs={12} sm={12} md={3} xl={4}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <GridToolbarQuickFilter className="DataGrid-SearchBar" />
            </Box>
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={9}
            xl={8}
            sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
          >
            <Box className="toolbar-right">
              <Box className="dg-filter">
                <GridToolbarColumnsButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
                <GridToolbarFilterButton />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </GridToolbarContainer>
    );
  }

  return (
    <DataGridPro
      // density={props.density}
      // apiRef={props.apiRef}
      columnHeaderHeight={40}
      // columnBuffer={props.columns.length}
      rows={props.rows}
      columns={props.columns}
      headerHeight={props.headerHeight}
      hideCheckbox={props.hideCheckbox}
      getRowId={props.getRowId}
      getRowHeight={props.getRowHeight}
      disableSelectionOnClick={props.disableSelectionOnClick}
      // rowsPerPageOptions={[15, 25, 50, 75,500,1000]}
      // pageSizeOptions={[15, 25, 50, 100, 500, 1000]}
      // pageSizeOptions=
      // disableRowSelectionOnClick
      // pageSize={pageSize}
      // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      pagination={props.pagination}
      // getDetailPanelHeight={props.getDetailPanelHeight}
      // getDetailPanelContent={props.getDetailPanelContent}
      // sortModel={props.sortModel}
      // onSortModelChange={props.onSortModelChange}
      // initialState={props.initialState}
      filterModel={props.filterModel}
      // filterMode="server"
      filterMode="client"
      slots={{
        toolbar: props.Toolbar,
        noRowsOverlay: props.NoRowsOverlay,
        loadingOverlay: SkeletonLoadingOverlay,
        // footer: CustomFooter
      }}
      onFilterModelChange={props.onFilterModelChange}
      loading={props.loading}
      onRowSelectionModelChange={props.onRowSelectionModelChange}
      onSortModelChange={props.onSortModelChange}
      paginationModel={props.paginationModel}
      disableRowSelectionOnClick={props.disableRowSelectionOnClick}
      onPaginationModelChange={props.onPaginationModelChange}
      checkboxSelection={props.checkboxSelection}
      onSelectionModelChange={(val) => props.onSelectionModelChange(val)}
      selectionModel={props.selectionModel}
      className="fullScreenGrid"
      sx={props.sx}
      onCellClick={props.onCellClick}
    />
  );
}
