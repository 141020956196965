import React from "react";
import { AppBar, Box, Toolbar, IconButton, Avatar, Link } from "@mui/material";

//CSS
import "./header.css";

import logo from "../../assets/images/vendx-logo.png";
import companylogo from "../../assets/images/mavevista-logo-new.png";

//ICONS
import {
  Menu,
  AppsRounded,
  NotificationsNoneRounded,
  InsightsRounded,
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";

export default class AppBarHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="fixed" className="app-header">
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{
                  mr: 0,
                  width: 50,
                  height: 50,
                  color: (theme) => theme.palette.grey[800],
                }}
                onClick={() => {
                  this.props.openchange();
                }}>
                <MenuIcon />
              </IconButton>

              <div className="applogo">
                <img src={logo} alt="Vendx Logo" />
              </div>

              <Box sx={{ flexGrow: 1 }} />
              <Box
                sx={{
                  display: { xs: "none", md: "flex", alignSelf: "flex-center" },
                }}>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  sx={{ mr: 1 }}>
                  <InsightsRounded />
                </IconButton>

                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  sx={{ mr: 1 }}>
                  <NotificationsNoneRounded />
                </IconButton>

                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  sx={{ mr: 1 }}>
                  <AppsRounded />
                </IconButton>
              </Box>

              <Link
                href="#"
                className="use-profile"
                onClick={this.handleToggle}>
                <div className="company-logo">
                  <img src={companylogo} />
                </div>
                <Avatar
                  sx={{ fontSize: 15, fontWeight: 500, width: 30, height: 30 }}>
                  FM
                </Avatar>
              </Link>
            </Toolbar>
          </AppBar>
        </Box>
      </React.Fragment>
    );
  }
}
