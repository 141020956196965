import React from "react";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import {
  Typography,
  Tooltip,
  IconButton,
  DialogContent,
  InputBase,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Card,
  CardContent,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import ArticleIcon from "@mui/icons-material/Article";
import { LinkItemDialog } from "../Dialogs/LinkVendorToCategory/style";
const TBSelectTemplateDialog = ({open, handleClose, data}) => {
  return (
    <LinkItemDialog
      scroll="body"
      maxWidth={"sm"}
      open={open}
      aria-labelledby="responsive-dialog-title"
      onClose={handleClose}
    >
      <DialogContent sx={{ p: "0 !important" }}>
        <Card className="LinkUnlinkCard" variant="outlined">
          <CardContent
            sx={{
              position: "relative",
              pb: "0 !important",
              minWidth: "600px",
            }}
            id="unlinked-vendors"
          >
            <Box
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "100%",
                borderBottom: "1px solid",
              }}
            >
              <IconButton sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
              />
              <IconButton
                sx={{
                  color: (theme) => theme.palette.bluegrey[500],
                }}
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <div className="link-items-list">
              <List sx={{ width: "100%" }}>
                {data.map((item, id) => {
                  // const ItemSelected = isItemSelected(item, type);
                  return (
                    <>
                      <ListItem
                        key={id}
                        disablePadding
                        sx={{ display: "block" }}
                        secondaryAction={
                          <IconButton>
                            <AddCircleOutlinedIcon />
                          </IconButton>
                        }
                      >
                        <ListItemButton
                          role={undefined}
                          dense
                          // onClick={(event) => handleItemClick(event, item, type)}
                        >
                          <ListItemIcon>
                            <ArticleIcon />
                            {/* <Checkbox edge="start" checked={ItemSelected} /> */}
                          </ListItemIcon>
                          <ListItemText
                            // multiline
                            primaryTypographyProps={{
                              component: "p",
                              variant: "body1",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                            primary={
                              <>
                                <Tooltip
                                  title={`Item Name: ${item.name}`}
                                  disableInteractive
                                  placement="bottom-start"
                                >
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      fontWeight: 500,
                                      width: "100%",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {item.name}
                                  </Typography>
                                </Tooltip>
                              </>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                    </>
                  );
                })}
              </List>
            </div>
          </CardContent>
        </Card>
      </DialogContent>
    </LinkItemDialog>
  );
};

export default TBSelectTemplateDialog;
