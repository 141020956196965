import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Drawer from "@mui/material/Drawer";
import ItemList from "./ItemList";

function SwipeableEdgeDrawer(props) {
  const { toggleDrawer, open, data, handleItemClick, type,reference  } = props;

  // This is used only for the example
  //   const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={() => toggleDrawer()}
      // onOpen={() => toggleDrawer(true)}
      // disableSwipeToOpen={false}
      sx={{
        zIndex: 99999999999,
      }}
      PaperProps={{ style: { position: "absolute",  borderRadius: '10px 10px 0 0', overflow:'inherit', } }}
      // variant="permanent"
      BackdropProps={{ style: { position: "absolute" } }}
      ModalProps={{
        container: document.getElementById(reference),
        style: { position: "absolute" },
        keepMounted: true,
      }}
    >
        <Box sx={{ p: '10px 16px', borderRadius: '10px 10px 0 0', background: (theme) => theme.palette.grey[100] }}>
          <Typography color="grey.900" fontSize={13} fontWeight={500} >Selected Items</Typography>
        </Box>
        <ItemList showSelectedLinked={true} data={data} handleItemClick={handleItemClick} type={type}/>
    </Drawer>
  );
}
export default SwipeableEdgeDrawer;
