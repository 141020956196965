import GlobalStyles from "@mui/material/GlobalStyles";

export const PRcombinationstyles = (
    <GlobalStyles
    styles={(theme) => ({
        '.prcombi-list li':{
            borderBottom: 'solid 1px',
            borderColor:  theme.palette.bluegrey[500],
            '& .pr_comb_button': {
                position: 'absolute',
                right: 5,
                top: 5,
                zIndex:5,                
            },
            '& .pr_comb_error': {
                position: 'absolute',
                right: 5,
                bottom: 10,
                zIndex:5,                
            },
            '& .prdelet-btn:hover': {
                color: theme.palette.error.main,
            },
            '& .MuiListItemButton-root': { 
            
                '&:hover':{
                    backgroundColor: theme.palette.primary[50]
                },
                '& .MuiBox-root': { 
                    
                }
            },            
            // '& .MuiListItemButton-root:hover': { 
                
            // },
            
            

        },
        
      
  
    })}
    />
  );
  