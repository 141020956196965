import {  Button, Checkbox, Dialog, DialogContent, DialogTitle, IconButton, Tooltip,DialogActions} from '@mui/material';
import React, { Component } from 'react';
import CloseIcon from "@mui/icons-material/Close";
import _ from "lodash";
import {connect} from 'react-redux'
import Grouprfqitem from './Grouprfqitem';

class GroupRFQ extends Component{
    constructor(props) {
        super(props)
        this.state = {
            rows:[],
            filteredRows:[],
            //prlineitemsByCategory:{},
            columns: [
              { 
                field: "prnumber",
                headerName: "PR NO",
                width: 150,
                editable: false,
                valueGetter: (params) => {
                    return params.row.pr.number;
                    //return params.getValue(params.id, "pr").number;
                  },
              },
              {
                field: "pr_line_item",
                headerName: "PR LINE ITEM",
                width: 150,
                editable: false,
              },              
              {
                field: "item_name",
                headerName: "ITEM NAME",
                width: 150,
                editable: false,
              },
              {
                field: "item_code",
                headerName: "ITEM CODE",
                width: 150,
                editable: false,

              },
              {
                field: "quantity",
                headerName: "QUANTITY/UNIT",
                width: 150,
                editable: false,
                valueGetter: (params) => {
                  return params.row.quantity + "/" + params.row.uom;
                }
              }                      
            ]
        }
    }

    render() {
   //   console.log("selected Prs ==",this.props.selectedPrCategories,
     // "==",this.props.prlineitems)
        return(<div>
          {this.props.open?
            <Dialog       
                scroll="body"
                open={this.props.open}
                fullWidth
                maxWidth="lg"
                maxHeight='80%'
                aria-labelledby="draggable-dialog-title"
            >
              <DialogTitle 
                sx={{
                '&:focus-visible': {
                    border: 'none',
                    outline: 'none'
                }
                }}
                tabIndex={-1}
                >
                  Group RFQ No : {this.props.grouprfqid}
                <IconButton
                  onClick={() => {
                  this.props.close();
                  window.parent.postMessage("refresh-pr-grid", "*");
                }}
                sx={{ color: (theme) => theme.palette.bluegrey[500],
                  position:"absolute",right:'10px' }}
              >
              <CloseIcon />
              </IconButton>
              </DialogTitle>
                  <DialogContent sx={{padding:"10px",overflow:"auto"}}>
                    {
                    this.props.prCombinations.map((obj,i) => {
                      let idx;
                     const value =  this.props.rfqSubmittedPrs.some((item,id)=>{
                      if(item.index===i){
                         idx=id
                      }
                      return item.index===i
                     });
                      if(!value){
                        return;
                      }
                    return (
                    <Grouprfqitem 
                    key={idx} 
                    i = {idx}
                    columns={this.state.columns} 
                    obj={obj}/>)
            })
             }

              </DialogContent>
              <DialogActions>
             <Button
              sx={{
                m:'0 auto'
              }}
                  variant='contained'
                  // className="lightbg_btn"
                  onClick={()=>{
                    this.props.close();
                  window.parent.postMessage("refresh-pr-grid", "*");
                  }}                  
                >
                  OK
                </Button>

              </DialogActions>
            </Dialog>:null
        }
        </div>
       );

    }
}
const mapStateToProps = (state) => ({
  prlineitems:state.prrfqreducer.setPrLineItemsData,
  prCombinations:state.prrfqreducer.prCombinations,
  selectedPrCategories:state.prrfqreducer.selectedPrCategories,
  rfqSubmittedPrs:state.prrfqreducer.rfqSubmittedPrs,
})

const mapDispatchToProps =  {}
export default connect(mapStateToProps,mapDispatchToProps)(GroupRFQ);