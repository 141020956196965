import {
    SET_SELECTED_PR_CATEGORY,
    SET_DISABLED_PR_CATEGORY,
    SET_DISABLE_INDX,
    SET_PLANT_CODES,
    SET_PR_DIALOG,
    SET_PR_ITEMS,
    SET_PR_LINE_ITEMS_DATA,
    SET_PRDROPDOWN_VALUES,
    SET_PR_AND_ITEMS_LINK_VALUE,
    SET_RFQ_PRS,
    SET_PR_COMBINATIONS,
    CHANGE_PR_SELECTED,
    SET_GROUP_NO,
    SET_PR_COMBINATIONS_ITEMS,
    SET_TEMPLATE,
    SET_DEFAULT_VALUES,
    SET_EMPTY_PR_COMBINATION,
    SET_REMAINING_PR_COMBINATION,
    SET_API_DATA,
    SET_TEMPLATE_OPTIONS,
    SET_JSON_TEMPLATES,
    SET_TEMPLATE_VALUES,
    SET_TERMS_AND_CONDTIONS,
    SET_FILE_SPECIFICATION_FROM_API,
    SET_INVALID_COMBINATIONS,
    SET_DISPLAY_OPTIONS,
    SET_RFQ_LOADING,
  } from "../constants/type";
  
  const initialState = {
    selectedPrCategory:{},
    disbledPRCategory:{},
    disableIndex:undefined,
    plantcodes:[],
    prDialogOpen:false,
    selectedPrItems:"",
    setPrLineItemsData:{},
    selectedPrCategories:[],
    pr_dropdown_values:{},
    setpranditemvalue : {},
    rfqSubmittedPrs:[],
    prCombinations:[],
    remainingPrCombination:[],
    displayOptions:{
      label:"",
      service_label:"",
      service_from_pr:"",
      allowed_slab:"",
      allowed_slab_limit:0
    },
    onekey:true,
    group_no: 0,
    template: "",
    defaultValues : {},
    apiData : {},
    templateOptions:[],
    jsonTemplates:{},
    templateValues:[],
    termsAndConditions:{},
    fileSpecificationFromApi:{},
    inValidCombinations:false,
    isEmptyPrCombination:false,
    rfqLoading:false,
    defaultSetting:{
      rfqSettingValues: {
        vendorMasterIncoTerm: false,
        vendorMasterIncoTermDefaultSet: false,
        vendorMasterPaymentTerm: false,
        vendorMasterPaymentTermDefaultSet: false,
        allowMultipleSolution : false,
        basicRate:false,
        landedCost:false,
        allowMultiPlant:false
      },
      activityTemplateValues: {
        activityTemplateName: false,
        activityTemplateID: false,
      },
      remiderDrawer: {
        includeReminderInterval: true,
        enableHourlyReminderTB: true,
        sendSMSWithHourlyReminderTB: true,
        remiderDaysTB: 1,
        hourlyReminderValueTB: 1,
        startDate : "",
        intervalDays : "",
        sendSMS : false,
        enableHourlyReminder : false,
        hourlyReminderValue : "",
        sendSMSWithHourlyReminder : false
      }
    }
  };
  const prrfqreducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case SET_SELECTED_PR_CATEGORY:
        let categories = state.selectedPrCategories;
        categories.push(payload)
        return {
            ...state,
            selectedPrCategory:{...payload},
            selectedPrCategories:categories
        }
      case SET_DISABLED_PR_CATEGORY:
          let disbledPRCategory = {...state.disbledPRCategory,...payload}
         
          return {
            ...state,
            disbledPRCategory
          }
      case SET_DISABLE_INDX:
        return {
          ...state,
          disableIndex:payload
        }
      case SET_PLANT_CODES:
        return {
          ...state,
          plantcodes:payload
        }
      case SET_PR_DIALOG:
        return {
          ...state,
          prDialogOpen:payload
        }
      case SET_PR_ITEMS:
        return {
          ...state,
          selectedPrItems:payload
        }
      case SET_PR_LINE_ITEMS_DATA:
        return {
          ...state,
          setPrLineItemsData:payload
        }
      case SET_PRDROPDOWN_VALUES:
        return{
          ...state,
          pr_dropdown_values:payload
        }
      case SET_PR_AND_ITEMS_LINK_VALUE:
        return {
            ...state,
            setpranditemvalue:payload
        }  
      case SET_RFQ_PRS:
        let submittedRfqs = state.rfqSubmittedPrs;
        submittedRfqs.push(payload)
        return {
          ...state,
          rfqSubmittedPrs:submittedRfqs
        }
      case SET_PR_COMBINATIONS:
        return {
          ...state,
          prCombinations:payload
        }
      case CHANGE_PR_SELECTED:
        return {
          ...state,
          onekey:!state.onekey
        }  
      case SET_GROUP_NO:
        return {
          ...state,
          group_no:payload
        }
      case SET_PR_COMBINATIONS_ITEMS :
          let prCombinations = state.prCombinations;
          let selectedPrCategory = state.selectedPrCategory;
          prCombinations[state.disableIndex].items = payload.items;
          prCombinations[state.disableIndex]["removed_items"] = payload.removeditems;
          selectedPrCategory.items = payload.items;
          selectedPrCategory["removed_items"] = payload.removeditems;
         return {
           ...state,
           prCombinations : prCombinations,
           selectedPrCategory : selectedPrCategory
         } 
      case SET_TEMPLATE:
          return { ...state,template:payload };
      case SET_DEFAULT_VALUES:
            return { ...state,defaultValues:payload};
      case SET_EMPTY_PR_COMBINATION:
           return {...state,isEmptyPrCombination:payload};
      case SET_REMAINING_PR_COMBINATION:
            return {...state,remainingPrCombination:payload};
      case SET_API_DATA:
        let apiData = {...state.apiData,...payload}
         
        return {
          ...state,
          apiData
        }
     case SET_TEMPLATE_OPTIONS:
        return {...state,templateOptions:payload};
     case SET_JSON_TEMPLATES:
        let jsonTemplates = {...state.jsonTemplates,...payload}
         
        return {
          ...state,
          jsonTemplates
        }
     case SET_TEMPLATE_VALUES:
      let templateValues = {...state.templateValues,...payload}
         
      return {
        ...state,
        templateValues
      }
      case SET_TERMS_AND_CONDTIONS:
      let termsAndConditions = {...state.termsAndConditions,...payload}
         
      return {
        ...state,
        termsAndConditions
      }
      case SET_FILE_SPECIFICATION_FROM_API:
        let fileSpecificationFromApi = {...state.fileSpecificationFromApi,...payload}
           
        return {
          ...state,
          fileSpecificationFromApi
        }
        case SET_INVALID_COMBINATIONS:
          return { ...state,inValidCombinations:payload };
        case SET_DISPLAY_OPTIONS:
          return { ...state,displayOptions:payload };
        case SET_RFQ_LOADING:
          return { ...state,rfqLoading:payload };
        default:
        return state;
    }
}

export default prrfqreducer;
