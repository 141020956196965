import { OutlinedInput } from "@mui/material";
import {styled} from "@mui/system";

export const VendorRecipSearch = styled(OutlinedInput)(({ theme }) => ({
  '&.MuiInputBase-root':{
     minWidth:'100%',
     height: '42px',
   },
   '& .MuiSvgIcon-root': {
     color: theme.palette.grey[600],
   },
   '& .searchclear-icon svg ': {
     color: theme.palette.bluegrey[500],
     width: '18px',
     height: '18px',
   }
 }));