import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Typography,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import axios from 'axios'
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { setUserInfo } from "../../redux/actions/setUserInfo";
import { getAccountModulePermission, getMasterPermission, getUserInfo } from "../../config/apiUrl";
import { OPEN_SNACKBAR, SET_ACCOUNT_MODULE_PERMISSION, SET_ACCOUNT_MODULE_PERMISSION_ERROR, SET_MASTER_PERMISSION, SET_MASTER_PERMISSION_ERROR, SET_USER_INFO, SET_USER_INFO_ERROR } from "../../redux/constants/type";

const JustificationDialog = ({ open, onClose, title, subtitle, onSubmit, floatedRfqUser,sessionID, Flag }) => {
  const dispatch = useDispatch();
  const [justification, setJustification] = useState("");
  const [Profile, setProfile] = useState({})
  const [MasterPermission, setMasterPermission] = useState({})
  const [AccountPermission, setAccountPermission] = useState({})
  const [HasPerformedUpdate,setHasPerformedUpdate] = useState(false)
  const [snackbar, setSnackbar] = React.useState(false);
  const [severity, setseverity] = React.useState('')
  const [SnackMessage, setSnackMessage] = React.useState('')

  const handleJustificationChange = (event) => {
    setJustification(event.target.value);
  };

  const handleCloseSnackbar = () => {
    setSnackbar(false);
  };

  useEffect(() => {
    setHasPerformedUpdate(false);
      if (Profile && Object.keys(MasterPermission).length > 0 && Object.keys(AccountPermission).length > 0) {
          if (Flag == 'Closed' && (floatedRfqUser == Profile?.data?.user_id || Profile?.data?.is_super_user == 'Y' || MasterPermission?.data?.closing_rfq == 'Y')) {
            onSubmit(justification);
            setJustification("");
            setHasPerformedUpdate(true);
          } else if ((Flag == 'Priority') && (floatedRfqUser == Profile?.data?.user_id || Profile?.data?.is_super_user == 'Y')) {
            onSubmit(justification);
            setJustification("");
            setHasPerformedUpdate(true);
          } else {
            setSnackbar(true);
            setseverity('error')
            setSnackMessage("You don't have permission")
            // dispatch({
            //   type: OPEN_SNACKBAR,
            //   payload: {
            //     type: "error",
            //     msg: "You don't have permission.",
            //   },
            // });
            setHasPerformedUpdate(true);
          }
      }
  }, [Profile, MasterPermission, AccountPermission])

  useEffect(() => {
    if (HasPerformedUpdate) {
      setProfile({})
      setAccountPermission({})
      setMasterPermission({})
    }
  }, [HasPerformedUpdate])
  const handleSubmit = () => {
    if (subtitle == 'Justification (RFQ Closed)' || subtitle == 'Priority to Not Priority' || subtitle == 'Not Priority to Priority') {
       fetchPermissions()
    } else {

      onSubmit(justification);
      setJustification("");
    }
   
  };
  const containsOnlyWhitespace = (input) => {
    // Use a regular expression to check for whitespace
    return /^\s*$/.test(input);
  }
  const fetchPermissions = () => {
    axios.defaults.headers["Authorization"] = `Bearer ${sessionID}`;
    const url = getUserInfo;
    axios
      .get(url)
      .then((res) => {
        setProfile(res.data)
        dispatch({
          type: SET_USER_INFO,
          payload: res.data,
        });
        const url = getMasterPermission(res.data.data.division_id);
          axios
            .get(url)
            .then((res) => {
              setMasterPermission(res.data)
              dispatch({
                type: SET_MASTER_PERMISSION,
                payload: res.data,
              });
            })
            .catch((err) => {
              dispatch({
                type: SET_MASTER_PERMISSION_ERROR,
                payload: err.message,
              });
            });
            const modulepermisson = getAccountModulePermission(res.data.data.division_id);
            axios
              .get(modulepermisson)
              .then((res) => {
                setAccountPermission(res.data)
                dispatch({
                  type: SET_ACCOUNT_MODULE_PERMISSION,
                  payload: res.data,
                });
              })
              .catch((err) => {
                dispatch({
                  type: SET_ACCOUNT_MODULE_PERMISSION_ERROR,
                  payload: err.message,
                });
              });
            })
      .catch((error) => {
        if(error?.response?.status===401){
          setSnackbar(true);
          setseverity('error')
          setSnackMessage("Your Login session could not be validated. Please Login again...")
          // dispatch({
          //   type: OPEN_SNACKBAR,
          //   payload: {
          //     type: "error",
          //     msg: "Your Login session could not be validated. Please Login again...",
          //   },
          // });
          dispatch({
            type: SET_USER_INFO_ERROR,
            payload: "Your Login session could not be validated. Please Login again...",
          });
        }else{
          setSnackbar(true);
          setseverity('error')
          setSnackMessage(error?.response?.data.message
            ? error?.response?.data.message
            : "Something went wrong")
          // dispatch({
          //   type: OPEN_SNACKBAR,
          //   payload: {
          //     type: "error",
          //     msg: error?.response?.data.message
          //       ? error?.response?.data.message
          //       : "Something went wrong",
          //   },
          // });
          dispatch({
            type: SET_USER_INFO_ERROR,
            payload: error.message,
          });
        }
      });
  }
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState("xs");
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        className="JustificationDialog"
      >
        <DialogTitle
          sx={{
            "&.MuiTypography-root": {
              p: 2,
            },
          }}
        >
          <Typography
            component="div"
            variant="subtitle1"
            fontWeight={900}
            color="var(--black-textcolor)"
          >
            {title}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{ position: "absolute", right: 8, top: 13 }}
            size="small"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ padding: "16px 16px 0 16px" }}>
          <Typography
            component="div"
            variant="subtitle2"
            fontWeight={700}
            color="var(--black-textcolor)"
          >
            {subtitle}
          </Typography>
          <TextField
            autoFocus
            fullWidth
            required={true}
            multiline
            rows={3}
            sx={{ width: "100%", resize: "none", mt: 1 }}
            value={justification} // Bind the input field value to the state
            onChange={handleJustificationChange} // Handle changes to the justification text
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleSubmit}  disabled={containsOnlyWhitespace(justification)} color="primary" variant="contained">
            Submit
          </Button>
        
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={3000}
        open={snackbar}
        onClose={handleCloseSnackbar}
        message="Validate Date Updated Successfully"
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={severity}
          variant="filled"
        >
          {SnackMessage }
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default JustificationDialog;
