import React, { Component, createRef } from "react";
import { CustomsmallDialog } from "./style";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { CloudDownloadOutlined } from "@mui/icons-material/";
import documentIconSVG from "../../../assets/images/document-icon.svg";
import { IconButton } from "@mui/material";

class TermsAndConditions extends Component {
  constructor(props) {
    super(props);
    this.descriptionElementRef = createRef(null);
  }
  state = {
    downloadLoading: false,
  };

  componentDidMount() {
    // in order to wait for the element to be actually drawn, using setTimeout
    setTimeout(() => {
      if (this.descriptionElementRef.current !== null) {
        this.descriptionElementRef.current.focus();
      }
    }, 0);
  }

  downloadFile = (file_url, user_file_name) => {
    if (file_url) {
      this.setState({
        downloadLoading: true,
      });
      let a = document.createElement("a");
      a.href = file_url;
      a.download = user_file_name;
      a.target = "_blank";
      a.rel = "noopener noreferrer";
      a.click();
      this.setState({
        downloadLoading: false,
      });
    }
  };
  render() {
    return (
      <div>
        <Grid container>
          <Grid item lg={12}>
            <CustomsmallDialog
              scroll="body"
              //maxWidth={"sm"}
              open={this.props.open}
              // onClose={this.props.handleClose}
              aria-labelledby="responsive-dialog-title"
              sx={{
                '& .MuiPaper-root': { 
                  width: '100%',                 
                }
              }}
               >
              <DialogTitle
                sx={{
                    '&:focus-visible': {
                      border: 'none',
                      outline: 'none'
                    }
                }} 
                ref={this.descriptionElementRef} 
                tabIndex={-1}
              >
                Terms and Conditions
                <Box className="dialog-title-action">
                  <IconButton
                    sx={{
                      color: (theme) => theme.palette.bluegrey[500],
                    }}
                    onClick={this.props.handleClose}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </DialogTitle>
              <DialogContent>
                <Box sx={{maxHeight:"100px", overflowY:'auto'}}>
                  <Typography>
                    {this.props.termsAndConditionsData.t_and_c_text}
                  </Typography>
                </Box>
                <List className="uploadfile_list upload_file_scroll">
                  {this.props.termsAndConditionsData?.t_and_c_files.length >
                    0 && (
                    <div>
                      <Typography
                        variant="p"
                        sx={{
                          textTransform: "uppercase",
                          color: (theme) => theme.palette.bluegrey[600],
                          fontWeight: 500,
                          marginBottom: "16px",
                        }}
                      >
                        Terms and Condtitions Files
                      </Typography>
                      {this.props.termsAndConditionsData.t_and_c_files.map(
                        (file, index) => {
                          return (
                            <ListItem
                              key={index} 
                              secondaryAction={
                                <>
                                  <Tooltip title="Download">
                                    <IconButton
                                      sx={{
                                        mr: 1,
                                        color: (theme) =>
                                          theme.palette.grey[600],
                                      }}
                                      // disabled={
                                      //   !file.file_info.isValid || !file.file_id
                                      // }
                                      size="small"
                                      onClick={() => {
                                        this.downloadFile(
                                          file.download_url,
                                          file.user_file_name
                                        );
                                      }}
                                    >
                                      <CloudDownloadOutlined />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              }
                            >
                              <ListItemIcon>
                                <img src={documentIconSVG} />
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <React.Fragment>
                                    <Tooltip title={file.user_file_name}>
                                      <Typography
                                        component="p"
                                        color="text.primary"
                                        noWrap
                                        className="filename-text"
                                      >
                                        {file.user_file_name}
                                      </Typography>
                                    </Tooltip>
                                  </React.Fragment>
                                }
                                // secondary={
                                //   <React.Fragment>
                                //     <Typography
                                //       component="span"
                                //       noWrap
                                //       className="filesize-text"
                                //     >
                                //       {this.formatBytes(
                                //         file.file_info.FileSize
                                //       )}
                                //     </Typography>
                                //   </React.Fragment>
                                // }
                              />
                            </ListItem>
                          );
                        }
                      )}
                    </div>
                  )}
                </List>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.props.handleClose}
                  autoFocus
                >
                  Close
                </Button>
              </DialogActions>
            </CustomsmallDialog>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default TermsAndConditions;
