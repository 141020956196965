export const addTocart = (ref, data) => {
  const { selectionModel } = ref.state;
  if (!selectionModel.length) {
    ref.props.openSnackbar("error", "No item(s) selected");
    return;
  }
  //check if the item is already in the cart or not
  let newlySelectedItems = [];
  selectionModel.forEach((modelSelectedItem) => {
    if (
      !ref.props.selectedItems.some((element) => {
        return element.id === modelSelectedItem;
      })
    ) {
      newlySelectedItems.push(modelSelectedItem);
    }
  });
  if (newlySelectedItems.length) {
    let common = [];


    data.forEach((avail) => {
      newlySelectedItems.forEach((item) => {
        //To acquire the item object according to the checked item id
        if (item === avail.id) {
          common.push(avail);
        }
      });
    });

    // if (ref.state.isFilterActive) {
    //   ref.state.filteredRows.forEach((avail) => {
    //     newlySelectedItems.forEach((item) => {
    //       //To acquire the item object according to the checked item id
    //       if (item === avail.id) {
    //         common.push(avail);
    //       }
    //     });
    //   });
    // } else {
    //   ref.state.rows.forEach((avail) => {
    //     newlySelectedItems.forEach((item) => {
    //       //To acquire the item object according to the checked item id
    //       if (item === avail.id) {
    //         common.push(avail);
    //       }
    //     });
    //   });
    // }
    if ([...ref.props.selectedItems, ...common].length > 50) {
      ref.props.openSnackbar("error", "Cart size cannot be greater than 50!");
      return;
    }
    ref.props.setSelectedItem([...ref.props.selectedItems, ...common]);
  } else {
    ref.props.setSelectedItem([]);
  }
  ref.setState({
    selectionModel: [],
    rows: [],
  });
  ref.props.handleClose();
};
