import { SET_IMPORT_EXCEL_DATA } from "../constants/type";

const initialState = {
    quotation : {},
  };


  const ImportQuotationReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case SET_IMPORT_EXCEL_DATA:
        return { ...state, quotation: payload };
      default:
        return state;
    }
  };

  export default ImportQuotationReducer