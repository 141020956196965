import {
  SET_CATEGORY_ITEMS,
  GET_CATEGORY_ITEMS_ERROR,
  SET_CATEGORY_LOADING,
  RESET_CATEGORY,
  SET_SELECTED_CATEGORY,
  OPEN_SNACKBAR,
  SET_CHECK_VALID_TECHNICALBID_CATEGORY,
  SET_FILE_SPECIFICATIONS
} from "../constants/type";
import { getCategoryItems } from "../../config/apiUrl";
import axios from "axios";

export const getCategoryItemsList = (categories, sessionId) => (dispatch) => {
  dispatch({
    type: SET_CATEGORY_LOADING,
  });
  axios.defaults.headers.common["Authorization"] = `Bearer ${sessionId}`;
  axios
    .get(`${getCategoryItems}%25${categories}%25`)
    .then((response) => {
      dispatch({
        type: SET_CATEGORY_ITEMS,
        payload: response.data?.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: OPEN_SNACKBAR,
        payload: {
          type: "error",
          msg: error?.response?.data.message
            ? error?.response?.data.message
            : "Something went wrong",
        },
      });
      dispatch({
        type: GET_CATEGORY_ITEMS_ERROR,
        payload: error?.response?.status,
      });
    });
};

// export const getCategoryItemsList = (categories, sessionId) => (dispatch) => {
//   dispatch({
//     type: SET_CATEGORY_LOADING,
//   });
//   axios.defaults.headers.common["Authorization"] = `Bearer ${sessionId}`;
//   // axios.defaults.headers.common[
//   //   "Authorization"
//   // ] = `Bearer 1623996009138030.702506846885026`;
//   axios
//     .get(`${getCategoryItems}%25${categories}%25`)
//     .then((response) => {
//       if (response.data.data.length === 0) {
//         dispatch({
//           type: SET_CATEGORY_NODATA,
//         });
//       } else {
//         let obj = {
//           id: 0,
//           name: "",
//           category: "'A', 'B', 'C'",
//           subName: "",
//           chip: "P",
//         };
//         let finalData = [];
//         response.data.data.forEach((item) => {
//           obj.id = item.id;
//           obj.name = item.name;
//           obj.subName = item.code;
//           finalData.push(obj);
//           obj = {
//             id: 0,
//             name: "",
//             // category: "'A', 'B', 'C'",
//             subName: "",
//             chip: "P",
//           };
//           return item;
//         });
//         dispatch({
//           type: SET_CATEGORY_ITEMS,
//           payload: finalData,
//         });
//       }
//     })
//     .catch((error) => {
//       dispatch({
//         type: OPEN_SNACKBAR,
//         payload: {
//           type: "error",
//           msg: error?.response?.data.message
//             ? error?.response?.data.message
//             : "Something went wrong",
//         },
//       });
//       dispatch({
//         type: GET_CATEGORY_ITEMS_ERROR,
//         payload: error?.response?.status,
//       });
//     });
// };

export const resetCategory = () => (dispatch) => {
  dispatch({
    type: RESET_CATEGORY,
  });
};

export const setSelectedCategory = (item) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_CATEGORY,
    payload: item,
  });
}
export const setCheckValidTechnicalBidCategory = (technicalBidCategory) => {
 
  return {
    type:SET_CHECK_VALID_TECHNICALBID_CATEGORY,
    payload: {...technicalBidCategory}
  }
};

export const setFileSpecification = (files) => (dispatch) => {
  dispatch ({
    type:SET_FILE_SPECIFICATIONS,
    payload:files
  });
}
   
