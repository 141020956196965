import * as React from 'react';
import { Box, Typography, Link, Stack, Popover, IconButton, List, ListItem} from "@mui/material";
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

// CSS
import "./footer.css"

function FooterBar(props){
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    return(
        <Stack className="FooterBar" id={props.sidebar == 'Active' ? 'sidebarActive' : 'sidebarNotActive'} direction="row" spacing={2} justifyContent="space-between" alignItems="center">
            <Typography variant="body2">{`Copyright © 2000-${new Date().getFullYear()}`} <Link href="http://mavenvista.com/" target="_blank" underline="none" color="text.primary" fontWeight={500}>MavenVista Technologies Pvt. Ltd.</Link></Typography>
            <Stack direction="row" spacing={2} sx={{display:{md:"flex", sm:"none", xs:"none"}}}>
                <Link className="desktopMobile" href="mailto:helpdesk@mavenvista.com" underline="none" color="text.primary" fontWeight={500} sx={{display: "flex", alignItems: "center", gap:"8px"}}>
                    <EmailIcon fontSize="small"/> 
                    <Typography component="span"  variant="body2">helpdesk@mavenvista.com</Typography>
                </Link>
            
                <Link href="tel:91-9033034314" underline="none" color="text.primary" sx={{display: "flex", alignItems: "center", gap:"8px"}}>
                    <CallIcon fontSize="small"/> 
                    <Typography component="span" variant="body2">91-9033034314</Typography>
                </Link>                                  
            </Stack>
            <Box sx={{display:{md:"none", sm:"flex", xs:"flex"}}}>
                <IconButton size="small" onClick={handleClick} sx={{p:0}}>
                    <SupportAgentIcon size="large"/>
                </IconButton>
                <Popover
                    
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <List>
                        <ListItem>
                            <Link className="desktopMobile" href="mailto:helpdesk@mavenvista.com" underline="none" color="text.primary" fontWeight={500} sx={{display: "flex", alignItems: "center"}}>
                                <EmailIcon fontSize="small" sx={{mr:1}} /> 
                                <Typography component="span"  variant="body2">helpdesk@mavenvista.com</Typography>
                            </Link>
                        </ListItem>
                        <ListItem>
                            <Link href="tel:91-9033034314" underline="none" color="text.primary" sx={{display: "flex", alignItems: "center"}}>
                                <CallIcon fontSize="small" sx={{mr:1}}/> 
                                <Typography component="span" variant="body2">91-9033034314</Typography>
                            </Link>   
                        </ListItem>     
                    </List>
                </Popover>
            </Box>  
        </Stack>
    );
}

export default FooterBar;