import {
  SET_CATEGORY_ITEMS,
  SET_CATEGORY_LOADING,
  GET_CATEGORY_ITEMS_ERROR,
  SET_CATEGORY_NODATA,
  UNSET_CATEGORY_LOADING,
  RESET_CATEGORY,
  SET_SELECTED_CATEGORY,
  SET_CHECK_VALID_TECHNICALBID_CATEGORY,
  SET_FILE_SPECIFICATIONS
} from "../constants/type";
const initialState = {
  categoryItems: [],
  error: { msg: "", status: "" },
  loading: false,
  noDataFound: false,
  // selectedCategory: { name: "test", id: 10 },
  selectedCategory: null,
  checkTechinicalBidCategory:{},
  fileSpecifications:[]
};

const categoryReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CATEGORY_ITEMS:
      return { ...state, categoryItems: payload, loading: false };
    case SET_CATEGORY_LOADING:
      return { ...state, loading: true, noDataFound: false };
    case SET_CATEGORY_NODATA:
      return { ...state, noDataFound: true, loading: false };
    case UNSET_CATEGORY_LOADING:
      return { ...state, noDataFound: false, loading: false };
    case RESET_CATEGORY:
      return { ...state, categoryItems: [], noDataFound: false };
    case GET_CATEGORY_ITEMS_ERROR:
      if (payload === 401) {
        return {
          ...state,
          loading: false,
          error: { msg: "Token expired please login again!", status: false },
        };
      } else if (payload === 403) {
        return {
          ...state,
          loading: false,
          error: { msg: "Forbidden", status: false },
        };
      } else if (payload === 404) {
        return {
          ...state,
          loading: false,
          error: { msg: "Category not found!", status: false },
        };
      } else if (payload === 500) {
        return {
          ...state,
          loading: false,
          error: { msg: "Internal server error", status: false },
        };
      } else if (payload === 503) {
        return {
          ...state,
          loading: false,
          error: { msg: "Service unavailable", status: false },
        };
      } else {
        return {
          ...state,
          loading: false,
          error: { msg: "Internal server error!", status: false },
        };
      }
    case SET_SELECTED_CATEGORY:
      return { ...state, selectedCategory: payload };
    
    case SET_CHECK_VALID_TECHNICALBID_CATEGORY:
      return {
        ...state,
        checkTechinicalBidCategory:payload
      }
    
    case SET_FILE_SPECIFICATIONS:
      return { ...state,fileSpecifications:payload}
    default:
      return state;
  }
};

export default categoryReducer;
