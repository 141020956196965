import React, { useState, useEffect } from "react";
import { Box,CircularProgress,Backdrop } from "@mui/material";
import LinkVendorItemDialog from "../../../components/LinkVendorToItem/linkVendorToItem";
import axios from "axios";
import {
  getAccountModulePermission,
  getUserInfo,
  pr_dropdown_mapping,
  dropdown_detail,
} from "../../../config/apiUrl";
import { setCompanyInfo } from "../../../redux/actions/setCompanyInfo";
import { useDispatch, useSelector } from "react-redux";

function LinkVendorItem() {
  const [isOpenLinkVendorItem, setIsOpenLinkVendorItem] = useState(true);

  const urlParams = new URLSearchParams(window.location.search);
  const [Profile, setProfile] = useState({});
  const [accountPermission, setAccountPermission] = useState("");
  const [accountPlant, setAccountPlant] = useState("");
  const dispatch = useDispatch();

  let inquiry_id = urlParams.get("inquiry_id");
  let destination_code = urlParams.get("destination_code");
  let sessionId = urlParams.get("q");
  const [loadingOverlay, setLoadingOverlay] = useState(false);

  const handleCloseDialog = (refresh) => {
    setIsOpenLinkVendorItem(false);
    if(refresh){
      window.parent.postMessage("refresh-q-plus", "*");
    }else{
      window.parent.postMessage("alertclose-q-plus", "*");
    }
  };
  const configAxios = {
    headers: { Authorization: `Bearer ${sessionId}` },
  };
  const fetchUserDetails = () => {
    const url = getUserInfo;
    setLoadingOverlay(true);
    axios
      .get(url, configAxios)
      .then((response) => {
        setProfile(response.data);
        fetchPermissions(response.data);
        dispatch(setCompanyInfo(response.data.data.company_id, sessionId));
        setLoadingOverlay(false);
      })
      .catch((error) => {
        setLoadingOverlay(false);
        console.log(error, "error");
      });
  };
  const fetchPermissions = (userDetails) => {
    const modulepermisson = getAccountModulePermission(
      userDetails.data.division_id
    );
    setLoadingOverlay(true);
    axios
      .get(modulepermisson, configAxios)
      .then((res) => {
        if (
          res.data.data["Plant Specific Categroy Item Vendor Linking"]
            .module_activated === "Y"
        ) {
          fetchDetails(userDetails);
        } else {
          setAccountPermission("N");
        }
        setLoadingOverlay(false);
      })
      .catch((err) => {
        console.log("err", err);
        setLoadingOverlay(false);
      });
  };
  const fetchDetails = async (userDetails) => {
    let mapping_url = pr_dropdown_mapping(
      userDetails.data.division_id,
      userDetails.data.company_id
    );
    let res = await fetch(mapping_url);
    res = await res.json();
    let dropdown_id = res.plant;
    let dropdown_detail_url = dropdown_detail(
      userDetails.data.division_id,
      userDetails.data.company_id,
      dropdown_id
    );
    setLoadingOverlay(true);
    fetch(dropdown_detail_url)
      .then((res) => res.json())
      .then(function (successMessage) {
        setAccountPlant(successMessage);
        setAccountPermission("Y");
        setLoadingOverlay(false);
      })
      .catch((err) => {
        console.log(err,"err");
        setLoadingOverlay(false);
      });
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);
  return (
    <React.Fragment>
      {accountPermission !== "" && (
        <Box>
          <LinkVendorItemDialog
            inquiryID={parseInt(inquiry_id)}
            destination_code={destination_code}
            sessionId={sessionId}
            handleCloseDialogue={handleCloseDialog}
            openLinkVendorItem={isOpenLinkVendorItem}
            plantSpecific={accountPermission}
            accountPlant={accountPlant}
          />
        </Box>
      )}
      <Backdrop
        style={{ color: "#fff", zIndex: "10000000000" }}
        open={loadingOverlay}
      >
        <CircularProgress style={{ color: "#fff" }} />
      </Backdrop>
    </React.Fragment>
  );
}

export default LinkVendorItem;
