import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
// import "../../../pages/style.css";
import _, { debounce } from "lodash";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Badge, Modal, ToggleButton, Tooltip } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { ResizableBox } from "react-resizable";
// import "./resize.style.css";
// import More from "../More/More";
import Upload from "../../Dialogs/Upload/Upload";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import QuotationFormField from "../QuotationFormField";
import { styled } from "@mui/material/styles";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  List,
  ListItem,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import RupeeIcon from "@mui/icons-material/CurrencyRupee";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { Close as CloseIcon } from "@mui/icons-material";
import LandedCostRFQDrawer from "../LandedCostRFQDrawer/LandedCostRFQDrawer";
import QuotationFormFieldView from "../QuotationFormFieldView";
import FileSpecUpload from "../../Dialogs/Upload/FileSpecUpload";
import axios from "axios";
import { postFileSpecifications } from "../../../config/apiUrl";
import UploadDynamicView from "../../Dialogs/Upload/UploadDynamicView";
import { getSymbol } from "../../AccountTemplate/CurrencySymbol";

// const PageToolbar = styled("div")(({ theme }) => ({

// export default class QuotationFormSectionTable extends Component {
export default function QuotationTemplateSectionTableView(props) {
  const [name, setName] = useState("React");
  const [rows, setRows] = useState([]);
  const [disabledProps, setDisabledProps] = useState({});
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [ModalOpenTotalCost, setModalOpenTotalCost] = useState(false)
  const [ModalTitle, setModalTitle] = useState("");
  const [isCheckedSelected, setIsCheckedSelected] = useState(false);
  const [landedCostRFQ, setLandedCostRFQ] = useState({
    // Start Total Landed Cost For RFQ Drawer script
    right: false,
  });
  const [updatedFileValue, setUpdatedFileValue] = useState([]);
  const [fileSpecificationsOpen, setFileSpecificationsOpen] = useState(false);
  const [selectedQuotedItems, setSelectedQuotedItems] = useState(
    props.selectedQuotedItems || {}
  );
  const [popHash, setPopHash] = useState({});
  const [state, setState] = useState({
    isScrollActive: false,
    currentItemIndex: null,
    anchorEl: null,
    qty: "",
    rate: "",
    estimatedValueOpen: false,
    specificationsOpen: false,
  });
  const [Loading, setLoading] = useState(true);
  const [specification, setSpecification] = useState([]);
  const [SpecificationText, setSpecificationText] = useState("");
  const [modalData, setModalData] = useState([]);
  const [unit, setUnit] = useState("");
  const [destinationCount, setDestinationCount] = useState(0);
  const [FilteredItems, setFilteredItems] = useState({});
  const [FilteredMainTemplate, setFilteredMainTemplate] = useState({});
  const [AllItemsTemplate, setAllItemsTemplate] = useState([]);
  const [TotalCostFIeld, setTotalCostFIeld] = useState({});
  const [PerCost, setPerCost] = useState({});
  const [RateField, setRateFIeld] = useState({});

  // handleChange = (e) => setState({ SlabTableToggle: e.target.checked });

  // const handleDetailsClick = (rowData) => {
  //   setSelectedRowData(rowData);
  //   setModalOpen(true);
  // };
  const handleDetailsClick = (key, index, identifier, fieldlbl, unitFlag) => {
    // Check if mainTemplateQuotation and keys are available
    if (props.mainTemplateQuotation && key !== undefined) {
      const rowData = props.mainMSTemplateQuotation[key][index];
      const unit = props.items[key]?.unit || "";

      // Extract FieldLabels and FieldIds from TotalCost template formula
      const totalCostCell = props.data[0].find(
        (cell) => cell.Identifier === identifier
      );
      const fieldIds = totalCostCell.DefaultValue.Formula.match(
        /\(f\d+\)/g
      ).map((id) => id.slice(2, -1));
      const fieldLabels = fieldIds.map((id) => {
        const correspondingCell = props.data[0].find(
          (cell) => cell.FieldId === id
        );
        return correspondingCell ? correspondingCell.FieldLabel : "";
      });

      const basicPriceRate = props.data[0].find(
        (cell) => cell.Identifier === "Rate"
      );
      let totalCostValue = 0;
      // Evaluate DefaultFormula for each FieldLabel to get values
      const values = fieldLabels.map((label) => {
        const fieldId = props?.data[0].find(
          (cell) => cell.FieldLabel === label
        )?.FieldId;
        let AttributeName = fieldId.replace("f", "");
        // console.log('fieldId', label, fieldId.replace("f", ""), props.CurrentTransaction.template.itemize[key].solution[0].data[0], rowData);

        return {
          label,
          value:
            props.CurrentTransaction.template.itemize[key].solution[index]
              .data[0][AttributeName],
          unit,
        };
      });

      // values.push({ label: "Total Cost", value: totalCostValue });
      // Open modal and pass values and labels
      setModalOpenTotalCost(true);
      setModalTitle(fieldlbl);
      setSelectedRowData(rowData);
      setModalData(values);
      setUnit(unitFlag == 3 ? "Pack" : unit);
    } else {
      console.error("mainTemplateQuotation or key is null or undefined.");
    }
  };
  const test_function = (
    formula,
    placeholders,
    basicPriceRate,
    fieldId,
    key,
    value
  ) => {
    placeholders.forEach((placeholder) => {
      const matchId = placeholder.slice(2, -1);
      let matchValue = props.mainTemplateQuotation[key][matchId]?.value;
      if (matchValue === undefined) {
        let data1 = props.data[0].find((cell) => cell.FieldId === matchId);
        if (data1) {
          matchValue = "(" + data1.DefaultValue.Formula + ")";
          let placeholders11 = "";
          if (data1.DefaultValue.Formula && data1.DefaultValue.Formula !== "") {
            placeholders11 = data1.DefaultValue.Formula.match(/\(f\d+\)/g);
          } else if (data1.DefaultFormula && data1.DefaultFormula !== "") {
            placeholders11 = data1.DefaultFormula.match(/\(f\d+\)/g);
          }
          if (placeholders11 != "") {
            let data_hash = test_function(
              data1.DefaultValue.Formula,
              placeholders11,
              basicPriceRate,
              matchId,
              key,
              0
            );
            if (data_hash.eval) {
              matchValue = eval(data_hash.formula);
            } else {
              matchValue = data_hash.value;
            }
          } else {
            matchValue = data1.DefaultValue || 0;
          }
        } else {
          matchValue = props.mainTemplateQuotation[matchId]?.value || 0;
          if (props.mainTemplateQuotation[matchId]?.Identifier === "GSTType") {
            matchValue = "'" + matchValue + "'";
          }

          if (props.mainTemplateQuotation[matchId]?.Identifier === "GSTValue") {
            matchValue = "'" + (matchValue - 0.0) + "'";
          }
          // data1 = props.additionalInfoTemplate[0].find(
          //   (cell) => cell.FieldId === matchId
          // );
        }
      }
      if (matchValue === "") {
        matchValue = 0;
      }
      if (matchValue !== undefined) {
        // If value is defined, update the result
        if (
          props.mainTemplateQuotation[key][matchId]?.Identifier === "GSTType"
        ) {
          matchValue = "'" + matchValue + "'";
        }
        if (
          props.mainTemplateQuotation[key][matchId]?.Identifier === "GSTValue"
        ) {
          matchValue = "'" + (matchValue - 0.0) + "'";
        }
        // "Identifier": "GSTValue",
        if (basicPriceRate.FieldId === fieldId) {
          value = matchValue || 0;
          return { formula: formula, value: value, eval: 0 };
        } else {
          formula = formula.replace(placeholder, matchValue);
        }
      }
    });
    return { formula: formula, value: value, eval: 1 };
  };
  const handleUplaodSpecificationOpen = (
    index,
    specifications,
    specificationText
  ) => {
    setUpdatedFileValue(specifications);
    setFileSpecificationsOpen(!fileSpecificationsOpen);
    setPopHash({ index: index, type: "specification" });
    getExistingFiles(specifications);
    setSpecificationText(specificationText);
  };
  const handleUploadClose = () => {
    setFileSpecificationsOpen(false);
    setSpecification([]);
    setLoading(true);
    setSpecificationText("");
  };
  const getExistingFiles = (file_id) => {
    const queryString = file_id
      .map((file) => `file_ids[]=${file.id}`)
      .join("&");

    const url = `${postFileSpecifications}?${queryString}`;
    axios
      .get(url)
      .then((res) => {
        setSpecification(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, " downloadFile error");
        this.props.openSnackbar(
          "error",
          err.response.data?.message
            ? err.response.data?.message
            : "Something went wrong!"
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    let allItems = [];
    let templateData = props.template;
    let elementTypes = [];

    for (let section in templateData) {
      if (section !== "PARAM") {
        if (section === "ITEMIZE") {
          allItems = allItems.concat(templateData[section][0]);
        } else {
          allItems = allItems.concat(templateData[section]);
        }
      }
    }
    setAllItemsTemplate(allItems);
  }, [props.template]);

  const updateDisabledProps = () => {
    const disabledProps = {};

    for (const key in props.mainTemplateQuotation) {
      const isNegotiation = props.lastTransactionTypeInThread === "Negotiation";
      const lastSelected = props.lastTransactionInThread[key]?.selected === "Y";
      const mainSelected = props.mainTemplateQuotation[key]?.selected === "Y";
      const filteredSelectedNotDataSelected =
        props.filteredSelectedNotData[key]?.selected;

      if (isNegotiation) {
        if (isNegotiation && lastSelected) {
          disabledProps[key] = !lastSelected;
        } else {
          if (!mainSelected && filteredSelectedNotDataSelected == "N") {
            // Allow toggling between 'Y' and 'N' when mainSelected is 'N' and
            // filteredSelectedNotDataSelected is 'N'
            disabledProps[key] = true;
          } else if (mainSelected && filteredSelectedNotDataSelected != "N") {
            disabledProps[key] = true;
          } else if (!mainSelected) {
            // quotation = N , RFQ = Y
            // Enable item if mainSelected matches filteredSelectedNotDataSelected
            disabledProps[key] = false;
          } else if (mainSelected && filteredSelectedNotDataSelected == "N") {
            // quotation = N , RFQ = Y
            // Enable item if mainSelected matches filteredSelectedNotDataSelected
            disabledProps[key] = false;
          } else {
            disabledProps[key] = false;
          }
        }
      } else {
        if (mainSelected && filteredSelectedNotDataSelected == "N") {
          disabledProps[key] = false;
        } else if (mainSelected && filteredSelectedNotDataSelected != "N") {
          disabledProps[key] = false;
        } else if (!mainSelected) {
          disabledProps[key] = true;
        } else if (mainSelected && filteredSelectedNotDataSelected == "N") {
          disabledProps[key] = true;
        } else {
          disabledProps[key] = true;
        }
      }
    }
    setDisabledProps(disabledProps);
  };

  useEffect(() => {
    updateDisabledProps();
  }, [
    props.lastTransactionTypeInThread,
    props.lastTransactionInThread,
    props.mainTemplateQuotation,
  ]);

  useEffect(() => {
    if (props.destination) {
      const destinationsArray = props.destination.split("###");
      setDestinationCount(destinationsArray?.length);
    }
  }, [props.destination]);
  useEffect(() => {
    const filteredItems = {};
    const filterMainTemplate = {};
    Object.keys(props.selectedQuotedItems).forEach((key) => {
      if (props.CurrentTransaction.template.itemize[key]?.selected === "Y") {
        filteredItems[key] = props.selectedQuotedItems[key];
      }
    });
    Object.keys(filteredItems).forEach((key) => {
      if (props.mainTemplateQuotation[key]) {
        filterMainTemplate[key] = props.mainTemplateQuotation[key];
      }
    });
    setFilteredItems(filteredItems);
    setFilteredMainTemplate(filterMainTemplate);
  }, [props.mainTemplateQuotation]);
  const getTableBody = () => {
    const filteredItems = {};
    Object.keys(props.selectedQuotedItems).forEach((key) => {
      if (props.CurrentTransaction.template.itemize[key]?.selected === "Y") {
        filteredItems[key] = props.selectedQuotedItems[key];
      }
    });
    let itemsToRender;
    const { SlabTableToggle } = state;
    if (props.selectedQuotedItems || props.selectedQuotedItems[0]) {
      const { selectedQuotedItems, mainTemplateQuotation } = props;
      const filteredItemsMap = new Map(
        Object.entries(filteredItems).map((item) => [item[0], item[1]])
      );
      const reorderedTransactionItemSequence = props?.transactionItemSequence
        .filter((key) => filteredItemsMap.has(key)) // Filter keys that are present in map
        .map((key) => [key, filteredItemsMap.get(key)]);
      // itemsToRender = Object.entries(filteredItems)
      //   .sort(([, a], [, b]) => a.name.localeCompare(b.name))
      itemsToRender = reorderedTransactionItemSequence.map(
        ([key, value], index) => {
          return (
            <>
              <TableRow key={key}>
                <TableCell
                  sx={{
                    WebkitBoxShadow:
                      state.isScrollActive && "11px 0 19px -2px #bebebe54",
                    boxShadow:
                      state.isScrollActive && "11px 0 19px -2px #bebebe54",
                  }}
                >
                  <Box display="flex" alignItems="center" gap="16px">
                    {props.CurrentTransaction.template.itemize[key].solution[0]
                      .name && ( // This is for including slab ranges in quotation form
                        <Tooltip
                          title={`Include ${props?.displayOptions["label"]}`}
                          placement="bottom-start"
                          disableInteractive
                        >
                          <Switch
                            //  onClick={() => hideComponent("SlabTableToggle")}
                            // checked={SlabTableToggle}
                            // onChange={handleChange}
                            checked={
                              props.CurrentTransaction.template.itemize[key]
                                .solution.length > 1
                                ? true
                                : false
                            }
                            disabled={true}
                            onChange={(evt) => {
                              props.handleSlab(evt, value);
                            }}
                          />
                        </Tooltip>
                      )}

                    <Tooltip // This is for Attachments specific files
                      title="Specification"
                      placement="bottom-start"
                      disableInteractive
                    >
                      <Badge
                        badgeContent={`${value?.files?.length}`}
                        color="primary"
                      >
                        <AttachFileIcon
                          onClick={() =>
                            value?.files?.length > 0 &&
                            handleUplaodSpecificationOpen(
                              key,
                              value.files,
                              value.specification_text
                            )
                          }
                          sx={{ cursor: "pointer" }}
                        />
                      </Badge>
                    </Tooltip>
                    <Tooltip //Item name header starts from here
                      title={_.unescape(value.name)}
                      placement="bottom-start"
                      disableInteractive
                    >
                      <Typography
                        noWrap
                        variant="body2"
                        color="text.primary"
                        fontWeight={500}
                        sx={{
                          width: {
                            lg: `${state.itemCellWidth > 300
                                ? state.itemCellWidth
                                : 300
                              }px`,
                            md: `${state.itemCellWidth > 200
                                ? state.itemCellWidth
                                : 200
                              }px`,
                            sm: `${state.itemCellWidth > 200
                                ? state.itemCellWidth
                                : 200
                              }px`,
                            xs: `${state.itemCellWidth > 125
                                ? state.itemCellWidth
                                : 125
                              }px`,
                          },
                          // width: `${
                          //   state.itemCellWidth > 350
                          //     ? state.itemCellWidth
                          //     : 350
                          // }px`,
                          marginLeft: "10px",
                        }}
                      >
                        {_.unescape(value.name)}
                      </Typography>

                      {props.mainTemplateQuotation[key]
                        ?.added_after_rfq_created && (
                          <Chip label="New" size="small" color="primary" sx={{ marginLeft: "10px", height: "16px" }} />
                        )}

                    </Tooltip>
                  </Box>
                </TableCell>
                {props.data[0]?.find(
                  (obj) =>
                    obj.Identifier === "ProductCode" &&
                    obj.VisibilityFlag === "Y"
                ) && (
                    <TableCell
                    // sx={{
                    //   WebkitBoxShadow:
                    //     state.isScrollActive && "11px 0 19px -2px #bebebe54",
                    //   boxShadow:
                    //     state.isScrollActive && "11px 0 19px -2px #bebebe54",
                    // }}
                    >
                      <Tooltip //Item code header starts from here
                        title={_.unescape(value.code)}
                        placement="bottom-start"
                        disableInteractive
                      >
                        <Typography
                          noWrap
                          variant="body2"
                          color="text.primary"
                          fontWeight={500}
                          sx={{
                            width: {
                              lg: `${state.itemCellWidth > 300
                                  ? state.itemCellWidth
                                  : 250
                                }px`,
                              md: `${state.itemCellWidth > 200
                                  ? state.itemCellWidth
                                  : 200
                                }px`,
                              sm: `${state.itemCellWidth > 200
                                  ? state.itemCellWidth
                                  : 200
                                }px`,
                              xs: `${state.itemCellWidth > 125
                                  ? state.itemCellWidth
                                  : 125
                                }px`,
                            },
                          }}
                        >
                          {_.unescape(value.code)}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                  )}

                {props.CurrentTransaction.template.itemize[key].solution[0]
                  .name && (
                    <TableCell>
                      <Tooltip //Item code header starts from here
                        title={_.unescape(
                          props.CurrentTransaction.template.itemize[key]
                            .solution[0].name
                        )}
                        placement="bottom-start"
                        disableInteractive
                      >
                        <Typography noWrap variant="body2">
                          {_.unescape(
                            props.CurrentTransaction.template.itemize[key]
                              .solution[0].name
                          )}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                  )}

                {props?.data[index]?.map((cell) => {
                  const DefaultFormula = cell.DefaultFormula;
                  // Regular expression to extract field IDs
                  const regex = /f(\d+)/g;
                  let match;
                  const fieldIDs = [];

                  // Extract field IDs from DefaultFormula
                  while ((match = regex.exec(DefaultFormula)) !== null) {
                    fieldIDs.push(match[1]);
                  }

                  // Assuming you always have two field IDs in the DefaultFormula
                  const [field1, field2] = fieldIDs;

                  // Initialize FieldState
                  const FieldState = {};

                  // If field IDs are found, set data in FieldState
                  if (field1 && field2) {
                  }
                  if (
                    cell.VisibilityFlag === "Y" ||
                    (cell.DefaultValue &&
                      cell.DefaultValue.Button === "Y" &&
                      cell.ElementType === "D")
                  ) {
                    if (
                      props.multiplePlantsFlag &&
                      cell.Identifier === "GSTType" &&
                      cell.FieldLabel === "GST Transition Type *"
                    ) {
                      return null;
                    }
                    if (
                      !(
                        cell.Identifier === "Product" ||
                        cell.Identifier === "ProductCode" ||
                        cell.Identifier === "ProductForeign"
                      )
                    ) {
                      return (
                        <TableCell
                          key={cell.FieldId}
                          sx={{ minWidth: "120px" }}
                        >
                          <QuotationFormFieldView
                            inquiryId={props.inquiryId}
                            batch_id={props.batch_id}
                            LATEST={props.LATEST}
                            diffrentiatedFields={
                              props?.diffrentiatedFields?.[key]?.[0]
                            }
                            cell={cell}
                            item={value}
                            index={index}
                            FieldState={
                              props.mainMSTemplateQuotation[key]?.[0][
                              cell?.FieldId
                              ]
                            }
                            base_currency={props?.base_currency}
                            selectedCurrency={
                              props.CurrentTransaction.template.itemize[key]
                                .solution[0].currency
                            }
                            CurrentTransaction={
                              props.CurrentTransaction.template.itemize[key]
                            }
                            handleChange={(
                              evt,
                              FieldId,
                              Identifier,
                              FieldLabel,
                              ElementType
                            ) =>
                              props.handleChange(
                                evt,
                                index,
                                FieldId,
                                Identifier,
                                FieldLabel,
                                ElementType
                              )
                            }
                            SlabTableToggle={SlabTableToggle}
                            // disabled={
                            //   mainTemplateQuotation[key]?.selected !== "Y" // Disable if checkbox is not checked
                            // }
                            disabled={
                              Object.keys(disabledProps).length > 0 &&
                              disabledProps[key]
                            }
                            keys={key.length > 0 && key}
                            solutionIndex={0}
                            handleDetailsClick={handleDetailsClick}
                            destination={props.destination}
                            multiplePlantsFlag={props.multiplePlantsFlag}
                          />
                        </TableCell>
                      );
                    }
                  }
                })}
              </TableRow>
              {props.slab[key] && props.slab[key].isOpen && (
                <TableRow>
                  <TableCell style={{ padding: 0 }} colSpan={100}>
                    <TableContainer
                      className="quotation_template_table slab_range_table"
                      sx={{
                        maxHeight: "400px",
                        overflowY: "auto",
                        borderTop: "solid 1px",
                        borderColor: (theme) => theme.palette.bluegrey[500],
                        borderBottomRightRadius: "4px",
                        borderBottomLeftRadius: "4px",
                      }}
                      onScroll={debounce((event) => {
                        if (
                          event.target.scrollLeft === 0 &&
                          state.isScrollActive
                        ) {
                          setState({
                            isScrollActive: false,
                          });
                        } else if (
                          event.target.scrollLeft !== 0 &&
                          !state.isScrollActive
                        ) {
                          setState({
                            isScrollActive: true,
                          });
                        }
                      }, 200)}
                    >
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              sx={{
                                WebkitBoxShadow:
                                  state.isScrollActive &&
                                  "11px 0 19px -2px #bebebe54",
                                boxShadow:
                                  state.isScrollActive &&
                                  "11px 0 19px -2px #bebebe54",
                              }}
                            >
                              <Box
                                className=""
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Checkbox sx={{ visibility: "hidden" }} />
                                <Tooltip
                                  title={
                                    props.data[0]?.find(
                                      (obj) =>
                                        obj.Identifier === "Product" ||
                                        obj.Identifier === "ProductForeign"
                                    )?.FieldLabel
                                  }
                                >
                                  <Box
                                    sx={{
                                      minWidth: "350px",
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Typography fontWeight={500}>
                                      {
                                        props.data[0]?.find(
                                          (obj) =>
                                            obj.Identifier === "Product" ||
                                            obj.Identifier === "ProductForeign"
                                        )?.FieldLabel
                                      }
                                    </Typography>
                                  </Box>
                                </Tooltip>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Tooltip
                                  title={
                                    props.data[0]?.find(
                                      (obj) => obj.Identifier === "ProductCode"
                                    )?.FieldLabel
                                  }
                                >
                                  <Typography fontWeight={500}>
                                    {
                                      props.data[0]?.find(
                                        (obj) =>
                                          obj.Identifier === "ProductCode"
                                      )?.FieldLabel
                                    }
                                  </Typography>
                                </Tooltip>
                              </Box>
                            </TableCell>

                            {props.data[0]?.map((cell, index) => {
                              if (
                                cell.VisibilityFlag === "Y" ||
                                (cell.DefaultValue &&
                                  cell.DefaultValue.Button === "Y" &&
                                  cell.ElementType === "D")
                              ) {
                                if (
                                  !(
                                    cell.Identifier === "Product" ||
                                    cell.Identifier === "ProductForeign" ||
                                    cell.Identifier === "ProductCode"
                                  )
                                ) {
                                  return (
                                    <TableCell key={cell.FieldId}>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Tooltip title={cell.FieldLabel}>
                                          {/* <p>{cell.FieldLabel}</p> */}
                                          <Typography fontWeight={500}>
                                            {cell.FieldLabel}
                                          </Typography>
                                        </Tooltip>
                                      </div>
                                    </TableCell>
                                  );
                                }
                              } else {
                                return "";
                              }
                            })}
                          </TableRow>
                        </TableHead>
                        {/* {getSecondTableBody()} */}
                      </Table>
                    </TableContainer>
                  </TableCell>
                </TableRow>
              )}
              {props.CurrentTransaction.template.itemize[key].solution.length >
                1 &&
                getHeaders(
                  props.CurrentTransaction.template.itemize[key].solution,
                  key,
                  value
                )}
            </>
          );
        }
      );
    } else itemsToRender = "loading...";
    return <TableBody>{itemsToRender}</TableBody>;
  };

  const getHeaders = (solutions, key, value) => {
    return (
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={100}>
          <Table
            className="MultiSolution-Table SlabDetailsForm"
            sx={{
              "&.MultiSolution-Table tr td:first-of-type, &.MultiSolution-Table tr th:first-of-type":
              {
                width: {
                  lg: "486px",
                  md: "386px",
                },
                position: {
                  md: "sticky",
                  sm: "static",
                },
                left: "0",
                boxShadow: {
                  md: "inset -1px 0px 0 #a6c1d3",
                  sm: "none",
                },
              },
              // "&.SlabDetailsForm .MuiTableBody-root tr td, &.SlabDetailsForm .MuiTableHead-root tr th":
              //   {
              //     position: "static",
              //   },
              "&.MultiSolution-Table .MuiTableBody-root tr:last-of-type td": {
                borderBottom: "none",
              },
              // "&.SlabDetailsForm tr td:first-of-type, &.SlabDetailsForm tr th:first-of-type":
              //   {
              //     width: "628px",
              //     position: "sticky",
              //     left: "0",
              //     boxShadow: "inset -1px 0px 0 #a6c1d3",
              //   },
              // "&.SlabDetailsForm .MuiTableHead-root tr th:nth-child(2), &.SlabDetailsForm .MuiTableBody-root tr td:nth-child(2)":
              //   {
              //     left: "auto",
              //     minWidth: "200px",
              //   },
            }}
          >
            <TableHead>
              <TableRow>
                {Object.values(props.CurrentTransaction.template.itemize)[0]
                  .solution[0]?.name && (
                    <TableCell key={"M2"} align="left">
                      <Tooltip title={""}>
                        {/* <p>{cell.FieldLabel}</p> */}
                        <Typography
                          variant="body2"
                          color={"grey.600"}
                          fontWeight={500}
                          textAlign={{ sm: "left", md: "right" }}
                        >
                          MULTIPLE SOLUTION
                        </Typography>
                      </Tooltip>
                    </TableCell>
                  )}
                {props.data[0]?.map((cell, index) => {
                  if (
                    cell.VisibilityFlag === "Y" ||
                    (cell.DefaultValue &&
                      cell.DefaultValue.Button === "Y" &&
                      cell.ElementType === "D")
                  ) {
                    if (
                      !(
                        cell.Identifier === "Product" ||
                        cell.Identifier === "ProductForeign" ||
                        cell.Identifier === "ProductCode"
                      )
                    ) {
                      // return (
                      return (
                        <TableCell key={cell.FieldId} colSpan={100}>
                          {/* <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            > */}

                          <Tooltip title={cell.FieldLabel}>
                            {/* <p>{cell.FieldLabel}</p> */}
                            <Typography
                              variant="body2"
                              color={"grey.600"}
                              fontWeight={500}
                            >
                              {cell.FieldLabel}
                            </Typography>
                          </Tooltip>
                          {/* </div> */}
                        </TableCell>
                      );
                      // );
                    }
                  } else {
                    return "";
                  }
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {solutions.map((solution, index) => {
                  if (index != 0) {
                    return (
                      <React.Fragment key={index}>
                        <TableCell>
                          <Tooltip //Item code header starts from here
                            title={_.unescape(solution.name)}
                            placement="bottom-start"
                            disableInteractive
                          >
                            <Typography
                              variant="body2"
                              color={"grey.600"}
                              textAlign={{ sm: "left", md: "right" }}
                              noWrap
                            >
                              {_.unescape(solution.name)}
                            </Typography>
                          </Tooltip>
                        </TableCell>
                        {props?.data[index]?.map((cell) => {
                          const DefaultFormula = cell.DefaultFormula;
                          const regex = /f(\d+)/g;
                          let match;
                          const fieldIDs = [];

                          while (
                            (match = regex.exec(DefaultFormula)) !== null
                          ) {
                            fieldIDs.push(match[1]);
                          }

                          const [field1, field2] = fieldIDs;
                          const FieldState = {};

                          if (field1 && field2) {
                          }
                          if (
                            cell.VisibilityFlag === "Y" ||
                            (cell.DefaultValue &&
                              cell.DefaultValue.Button === "Y" &&
                              cell.ElementType === "D")
                          ) {
                            if (
                              props.multiplePlantsFlag &&
                              cell.Identifier === "GSTType" &&
                              cell.FieldLabel === "GST Transition Type *"
                            ) {
                              return null;
                            }
                            if (
                              !(
                                cell.Identifier === "Product" ||
                                cell.Identifier === "ProductCode" ||
                                cell.Identifier === "ProductForeign"
                              )
                            ) {
                              return (
                                <TableCell
                                  key={cell.FieldId}
                                  sx={{ minWidth: "120px" }}
                                  colSpan={100}
                                >
                                  <QuotationFormFieldView
                                    batch_id={props.batch_id}
                                    LATEST={props.LATEST}
                                    // diffrentiatedFields={

                                    //   props?.diffrentiatedFields?.[key]?.[index]
                                    // }
                                    cell={cell}
                                    item={value}
                                    index={index}
                                    FieldState={
                                      props.mainMSTemplateQuotation[key]?.[
                                      index
                                      ]?.[cell?.FieldId]
                                    }
                                    base_currency={props?.base_currency}
                                    selectedCurrency={
                                      props.CurrentTransaction.template.itemize[
                                        key
                                      ].solution[0].currency
                                    }
                                    CurrentTransaction={
                                      props.CurrentTransaction.template.itemize[
                                      key
                                      ]
                                    }
                                    handleChange={(
                                      evt,
                                      FieldId,
                                      Identifier,
                                      FieldLabel,
                                      ElementType
                                    ) =>
                                      props.handleChange(
                                        evt,
                                        index,
                                        FieldId,
                                        Identifier,
                                        FieldLabel,
                                        ElementType
                                      )
                                    }
                                    SlabTableToggle={""}
                                    disabled={
                                      Object.keys(disabledProps).length > 0 &&
                                      disabledProps[key]
                                    }
                                    keys={key.length > 0 && key}
                                    solutionIndex={index}
                                    handleDetailsClick={handleDetailsClick}
                                    destination={props.destination}
                                    multiplePlantsFlag={
                                      props.multiplePlantsFlag
                                    }
                                  />
                                </TableCell>
                              );
                            }
                          }
                        })}
                      </React.Fragment>
                    );
                  }
                })}
              </TableRow>
            </TableBody>
          </Table>
        </TableCell>
      </TableRow>
    );
  };

  const LandedCostRFQOpen = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (AllItemsTemplate.length > 0) {
      let totalCost = AllItemsTemplate.find((items) => {
        return (
          items.DefaultValue?.Button == "Y" || items.Identifier === "TotalCost"
        );
      });

      let rateCell = AllItemsTemplate.find((items) => {
        return (
          (items.Identifier === "EstimatedCost" ||
            items.Identifier === "EstimatedCostForeign" ||
            items.Identifier == "Budgetedrate" ||
            items.Identifier === "Rate" ||
            items.Identifier === "CIFRate" ||
            items.Identifier == "rate") &&
          items.ElementType === "D"
        );
      });
      let PerCost = AllItemsTemplate.find((items) => {
        return (
          items.Identifier === "PER"
        );
      });
      setRateFIeld(rateCell);
      setPerCost(PerCost)

      setTotalCostFIeld(totalCost);
    }
    setLandedCostRFQ({ ...landedCostRFQ, [anchor]: open });
  };

  return (
    <>
      <Card variant="outlined" id="Template">
        <CardHeader
          title={
            <Typography variant="subtitle2" component="h5" color={"grey.900"}>
              TEMPLATE
            </Typography>
          }
          action={
            props.multiplePlantsFlag && (
              <>
                <Badge
                  badgeContent={destinationCount}
                  color="primary"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  sx={{ mr: 2 }}
                >
                  <Button
                    variant="text"
                    size="small"
                    startIcon={<LocationOnOutlinedIcon />}
                    onClick={() =>
                      props.setIsDestinationDetailsDialogOpen(true)
                    }
                    sx={{ backgroundColor: "var(--primary-50)" }}
                  >
                    Destination
                  </Button>
                </Badge>
              </>
            )
          }
        />
        <CardContent sx={{ p: 0 }}>
          <TableContainer
            className="quotation_template_table view_quotation_t_table"
            sx={{
              maxHeight: "400px",
              overflowY: "auto",
              borderTop: "solid 1px",
              borderColor: (theme) => theme.palette.bluegrey[500],
              borderBottomRightRadius: "4px",
              borderBottomLeftRadius: "4px",
            }}
            onScroll={debounce((event) => {
              if (event.target.scrollLeft === 0 && state.isScrollActive) {
                setState({
                  isScrollActive: false,
                });
              } else if (
                event.target.scrollLeft !== 0 &&
                !state.isScrollActive
              ) {
                setState({
                  isScrollActive: true,
                });
              }
            }, 200)}
          // sx={{
          //   maxHeight: "400px",
          //   overflowY: "auto",
          //   borderTop: "solid 1px",
          //   borderColor: (theme) => theme.palette.bluegrey[500],
          //   borderBottomRightRadius: "4px",
          //   borderBottomLeftRadius: "4px",
          //   ".items_template_table .MuiTableHead-root": {
          //     backgroundColor: (theme) => theme.palette.bluegrey[500],
          //   },
          //   "& .MuiTableHead-root ": {},
          //   "& .MuiTableBody-root": {
          //     backgroundColor: (theme) => theme.palette.grey[0],
          //   },
          //   "& tr": {
          //     whiteSpace: "nowrap",
          //   },
          //   "& .MuiTableHead-root tr th": {
          //     padding: "15px 8px",
          //   },
          //   "& .MuiTableBody-root tr td": {
          //     padding: "5px 8px",
          //   },
          //   ".MuiTableBody-root tr td:first-of-type, .MuiTableHead-root tr th:first-of-type ":
          //     {
          //       // position: "sticky",
          //       left: "0",
          //       minWidth: "380px",
          //     },
          //   "& .MuiTableHead-root tr th:first-of-type": {
          //     zIndex: "3",
          //   },
          //   "& .MuiTableBody-root tr td:first-of-type": {
          //     backgroundColor: (theme) => theme.palette.grey[0],
          //     zIndex: "1",
          //   },
          //   "& .css-17hdxs-MuiTypography-root": {
          //     fontSize: "14px",
          //   },
          // }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      WebkitBoxShadow:
                        state.isScrollActive && "11px 0 19px -2px #bebebe54",
                      boxShadow:
                        state.isScrollActive && "11px 0 19px -2px #bebebe54",
                    }}
                  >
                    <Box
                      className=""
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {/* <Checkbox sx={{ visibility: "hidden" }} /> */}
                      <Tooltip
                        title={
                          props.data[0]?.find(
                            (obj) =>
                              obj.Identifier === "Product" ||
                              obj.Identifier === "ProductForeign"
                          )?.FieldLabel
                        }
                      >
                        <Box
                          sx={{
                            minWidth: "350px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography fontWeight={500}>
                            {
                              props.data[0]?.find(
                                (obj) =>
                                  obj.Identifier === "Product" ||
                                  obj.Identifier === "ProductForeign"
                              )?.FieldLabel
                            }
                          </Typography>

                          {/* <ResizableBox
                            axis="x"
                            height={20}
                            width={20}
                            onResizeStop={(e, data) => {
                              setState({
                                itemCellWidth: data.size.width,
                              });
                            }}
                            style={{
                              textAlign: "left",
                              width: "100%",
                            }}
                            minConstraints={[20, 20]}
                          ></ResizableBox> */}
                        </Box>
                      </Tooltip>
                    </Box>
                  </TableCell>
                  {props.data[0]?.find(
                    (obj) =>
                      obj.Identifier === "ProductCode" &&
                      obj.VisibilityFlag === "Y"
                  ) && (
                      <TableCell>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Tooltip
                            title={
                              props.data[0]?.find(
                                (obj) => obj.Identifier === "ProductCode"
                              )?.FieldLabel
                            }
                          >
                            <Typography fontWeight={500}>
                              {
                                props.data[0]?.find(
                                  (obj) => obj.Identifier === "ProductCode"
                                )?.FieldLabel
                              }
                            </Typography>
                          </Tooltip>
                          {/* <ResizableBox
                        axis="x"
                        height={20}
                        width={20}
                        style={{
                          textAlign: "left",
                          width: "100%",
                        }}
                        minConstraints={[20, 20]}
                      ></ResizableBox> */}
                        </Box>
                      </TableCell>
                    )}
                  {Object.values(props.CurrentTransaction.template.itemize)[0]
                    .solution[0]?.name && (
                      <TableCell key={"M1"}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Tooltip title={""}>
                            {/* <p>{cell.FieldLabel}</p> */}
                            <Typography fontWeight={500}>
                              MULTIPLE SOLUTION
                            </Typography>
                          </Tooltip>
                        </div>
                      </TableCell>
                    )}
                  {props.data[0]?.map((cell, index) => {
                    if (
                      props.multiplePlantsFlag === true &&
                      cell.Identifier === "GSTType"
                    ) {
                      return null;
                    }
                    if (
                      cell.VisibilityFlag === "Y" ||
                      (cell.DefaultValue &&
                        cell.DefaultValue.Button === "Y" &&
                        cell.ElementType === "D")
                    ) {
                      if (
                        !(
                          cell.Identifier === "Product" ||
                          cell.Identifier === "ProductForeign" ||
                          cell.Identifier === "ProductCode"
                        )
                      ) {
                        return (
                          <TableCell key={cell.FieldId}>
                            <Tooltip title={cell.FieldLabel}>
                              {/* <p>{cell.FieldLabel}</p> */}
                              <Typography fontWeight={500}>
                                {cell.FieldLabel}
                              </Typography>
                            </Tooltip>
                          </TableCell>
                        );
                      }
                    } else {
                      return "";
                    }
                  })}

                  {/* <TableCell>Total value</TableCell> */}
                </TableRow>
              </TableHead>
              {getTableBody()}
              {/* {getSecondTableBody()} */}
            </Table>
          </TableContainer>
        </CardContent>
        <CardActions
          sx={{
            justifyContent: "end",
            alignItems: "center",
            padding: "8px 16px",
          }}
          disableSpacing
        >
          <Typography variant="body2" fontWeight={500} color="secondary.main">
            Total Value
          </Typography>
          <IconButton onClick={LandedCostRFQOpen("right", true)}>
            <InfoIcon fontSize="small" color="primary" />
          </IconButton>
        </CardActions>
      </Card>

      {state.specificationsOpen && (
        <Upload
          currentItemIndex={state.currentItemIndex}
          product_id={props.selectedQuotedItems[state.currentItemIndex]?.id}
          randomId={props.randomId}
          master_type={"item"}
          handleUplaod={props.handleUplaod}
          open={state.specificationsOpen}
          handleClose={() => {
            setState({
              specificationsOpen: false,
            });
          }}
          Alreadyexistingfiles={
            props.itemSpecificFiles[
            props.selectedQuotedItems[state.currentItemIndex]?.id
            ] || []
          }
        />
      )}
      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "2px 15px",
          }}
        >
          <Typography variant="body1">Estimated Value Details</Typography>
          <IconButton
            edge="end"
            sx={{ color: (theme) => theme.palette.bluegrey[500] }}
            onClick={() => setModalOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <Table>
            <TableBody>
              {Object.keys(selectedRowData).map((key) => {
                const field = selectedRowData[key];
                if (field.FieldLabel === "Basic Price *") {
                  return (
                    <TableRow key={key}>
                      <TableCell align="left">Basic price of a bag:</TableCell>
                      <TableCell align="right">{field.value}</TableCell>
                    </TableRow>
                  );
                }
                return null;
              })}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
      {fileSpecificationsOpen && (
        <UploadDynamicView
          Loading={Loading}
          title="Specifications"
          SpecificationText={SpecificationText}
          uploadedFiles={specification?.data}
          open={fileSpecificationsOpen}
          handleClose={handleUploadClose}
        />
      )}
      <LandedCostRFQDrawer
        isLandedCostRFQOpened={landedCostRFQ}
        data={FilteredItems}
        isLandedCostRFQClosed={() => setLandedCostRFQ(false)}
        mainTemplateQuotation={FilteredMainTemplate}
        selectedCurrency={props.quotedCurrency}
        currencyDetails={props?.currencyDetails}
        transactionItemSequence={props?.transactionItemSequence}
        vendorType={props?.vendorType}
        TotalCostFIeld={TotalCostFIeld}
        currentTxn={props.CurrentTransaction}
        from="View"
        PerCost={PerCost}
        RateField={RateField}
      />
      <Dialog
        open={ModalOpenTotalCost}
        onClose={() => setModalOpenTotalCost(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle
          component="div"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "2px 16px",
          }}
        >
          <Typography variant="subtitle1" fontWeight={500} component="h6">
            {ModalTitle} Details
          </Typography>
          <IconButton
            edge="end"
            sx={{ color: (theme) => theme.palette.bluegrey[500] }}
            onClick={() => setModalOpenTotalCost(false)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Table size="small">
            <TableBody>
              {modalData?.map(({ label, value }) => (
                <TableRow key={label}>
                  <TableCell>{label}</TableCell>
                  <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
                    {getSymbol(props.currencyDetails)} &nbsp;
                    {isNaN(parseFloat(value).toFixed(3))
                      ? "0.000"
                      : parseFloat(value).toLocaleString("en-IN", {
                        minimumFractionDigits: 3,
                        maximumFractionDigits: 3,
                      })}
                    {" / "} {unit}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    </>
  );
}
