import React from 'react';
import { Chip, Tooltip } from "@mui/material";

// Define the status mappings

const getColorCodeForGMPStatus = (status) => {
  switch (status) {
    case 'Verified':
      return 'success'; // Green
    case 'Inactive':
      return 'error'; // Red
    case 'Pending':
      return 'warning'; // Yellow
    case 'Not Found':
      return 'default'; // Grey
    case 'Approved':
      return 'default'; // Grey
    case 'Non-Approved':
      return 'default'; // Grey
    case 'Exists':
      return 'default'; // Grey
    default:
      return 'default'; // Grey
  }
};

const getVendorGMPFlag = (permission, number, status, flag, type) => {
  var colorCode    = "";
  var chip_opacity = 1;
  if(number) {
    status = (permission === 'Y') ? status : "Exists";
  }
  else {
    status       = "Not Exists";
  }
  
  colorCode = getColorCodeForGMPStatus(status);

  if(status === "Pending") {
    status = "Verification Pending";
  }

  if(status === "Not Found" || status === "Not Exists") {
    chip_opacity = 0.5;
  }
  
  const tooltipTitle = `${type} ${status}`;
  return (
    <Tooltip title={tooltipTitle}>
      <Chip
        label={flag}
        color={colorCode}
        variant="soft-outlined"
        size="xsmall"
        style={{'margin': '3px'}}
        sx={{ opacity: chip_opacity }}
      /> 
    </Tooltip>
  );
};


const VendorChip = ({ vendor_data, module_permission, flag }) => {
  const isApproved = vendor_data.is_approved;

  const gst_flag  = getVendorGMPFlag(module_permission.gst_verification, vendor_data.gstin_no, vendor_data.gstin_status, 'G', "GSTIN");
  const pan_flag  = getVendorGMPFlag(module_permission.pan_verification, vendor_data.pan_no, vendor_data.pan_status, 'P', "PAN");  
  const approved_flag =  isApproved === true ? getVendorGMPFlag("Y", "A", "Approved", 'A', "") : getVendorGMPFlag("Y", "N", "Non-Approved", 'N', "");

  const msme_flag = vendor_data.industry_type === "MSME" ? 
    getVendorGMPFlag(module_permission.msme_verification, vendor_data.industry_type_value, vendor_data.msme_status, 'M', "MSME") : 
    getVendorGMPFlag(module_permission.msme_verification, "", vendor_data.msme_status, 'M', "MSME");

  return (
    <>
      {[approved_flag, gst_flag, msme_flag, pan_flag]}
    </>
  );
};

export { getVendorGMPFlag };
export default VendorChip;
