import { LINKUNLINKUSER, SELECTEDUSERIDS } from "../constants/type";

const initialState = {
    RfqWithUser : [],
    selectedUserIds:[]
  };


  const LinkUnlinkUserReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case LINKUNLINKUSER:
        return { ...state, RfqWithUser: payload };
      case SELECTEDUSERIDS:
        return {...state, selectedUserIds:payload}
      default:
        return state;
    }
  };

  export default LinkUnlinkUserReducer