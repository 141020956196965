import React, { useState, useEffect } from "react";
import ArrowDownIcon from "@mui/icons-material/ArrowDropDown";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import {
  Button,
  Typography,
  Tooltip,
  Divider,
  IconButton,
  Switch,
  ListItem,
  ListItemText,
  LinearProgress,
  Popover,
  Chip,
} from "@mui/material";
import { Box } from "@mui/system";
import TBSelectTemplateDialog from "./TBSelectTemplateDialog";
import {EditNote} from "../SvgIcon/SvgIcon";
import {
  getCategoryLinkedTechnicalBidTemplateDetails,
  getCategoryTBPreviousValues,
} from "../../config/apiUrl";
import { useSelector,useDispatch } from "react-redux";
import axios from "axios";
import { openSnackbarfunc } from "../../redux/actions/snackbarAction";
import {setCheckValidTechnicalBidCategory} from '../../redux/actions/categoryAction'

export default function TBOptions({
  handleChange,
  includeTB,
  itemizeTB,
  handleTBAction,
  selectedCategory,
  isUpdated,
  setIsUpdated,
  tbStage,
  disabledtechnicalbid,
  location
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [templateDialogOpen, setTemplateDialogOpen] = useState(false);
  const [categroyTBTemplate, setCategroyTBTemplate] = useState({});
  const [categroyTBTemplatePreviousValue, setcategroyTBTemplatePreviousValue] = useState({});
  const { userInfo } = useSelector((reducer) => reducer.userInfoReducer);
  const disableIndex = useSelector(state => state.prrfqreducer.disableIndex);
  const { masterPermission } = useSelector(
    (reducer) => reducer.permissionsReducer
  );

  const [loader, setLoader] = useState(false);
  const [loadedFirstTime, setLoadedFirstTime] = useState(true)
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  const fetchCategoryTBTempalteDetails = () => {
    setLoader(true);
    let url = getCategoryLinkedTechnicalBidTemplateDetails(
      selectedCategory?.id
    );
    axios
      .get(url)
      .then((res) => {
        setCategroyTBTemplate(res.data.data);
        // if its not the first time then off loader and dont go further!
        if (loadedFirstTime) {
          setLoadedFirstTime(false);
        }
        fetchCategoryTBTempaltePreviousValue(res.data.data.id);
        setIsUpdated(true);
      })
      .catch((err) => {
        // dispatch(openSnackbarfunc("error", err.response.data.message))
        setIsUpdated(true);
        setLoader(false);
        setCategroyTBTemplate({});
      });
  };

  const fetchCategoryTBTempaltePreviousValue = (template_id) => {
    let url = getCategoryTBPreviousValues(selectedCategory?.id, template_id);
    axios
      .get(url)
      .then((res) => {
        setIsUpdated(true);
        setLoader(false)
        setcategroyTBTemplatePreviousValue(res.data.data);
      })
      .catch((err) => {
        dispatch(openSnackbarfunc("error", err.response.data.message))
        setIsUpdated(true);
        setLoader(false);
      });
  };

  // const fetchCategoryTBTemplateDefaultValue = (template_id) => {
  //   let url = getCategoryTBDefaultValues(selectedCategory?.id, template_id);
  //   axios
  //     .head(url)
  //     .then((res) => {
  //       setDefaultValue(true);
  //       setLoader(false);
  //       setIsUpdated(true);
  //     })
  //     .catch((err) => {
  //       setDefaultValue(false);
  //       setLoader(false);
  //       setIsUpdated(true);
  //     });
  // };
  useEffect(()=>{
       setCategroyTBTemplate({});
       setcategroyTBTemplatePreviousValue({});
},[disableIndex]);

  useEffect(() => {
    if (includeTB && !itemizeTB && !isUpdated) {
      fetchCategoryTBTempalteDetails();
    }
    //eslint-disable-next-line
  }, [includeTB,itemizeTB, isUpdated]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if(includeTB) {
    if(!itemizeTB && !categroyTBTemplate?.id) {
      dispatch(setCheckValidTechnicalBidCategory({[selectedCategory?.id]:"ERROR"}))
    } else {
      if( !categroyTBTemplate?.has_user_fields) {
        dispatch(setCheckValidTechnicalBidCategory({[selectedCategory?.id]: categroyTBTemplate?.id}))
      } else {
        if(categroyTBTemplatePreviousValue?.pre_fetch_inquiry_id > 0) {
          dispatch(setCheckValidTechnicalBidCategory({[selectedCategory?.id]: categroyTBTemplate.id }))
        } else {
          if(parseInt(tbStage) !== 40) {
            dispatch(setCheckValidTechnicalBidCategory({[selectedCategory?.id]:"ERROR"}))
          } else {
            dispatch(setCheckValidTechnicalBidCategory({[selectedCategory.id]: categroyTBTemplate.id}))
          }
        }
      }
    }
  }
  return (
    <React.Fragment>
      <Tooltip title="Select TB options">
        <Button
          id="demo-customized-button"
          variant="outlined"
          color="primary"
          aria-controls={open ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          disableElevation
          disabled={new URLSearchParams(location.search)?.get(
            "multiplant_rfq"
          ) == 1 &&
          location?.pathname.indexOf("/prcombination") > -1}
          onClick={handleClick}
          endIcon={<ArrowDownIcon />}
          sx={{
            color: 'text.primary',
            borderColor: 'bluegrey.500',
            width: { xs: '100%',},
            height:"100%",
            fontWeight:400,
            textTransform: 'none',
          }}
        >
          Technical Bid
        </Button>
      </Tooltip>
      <Popover
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1,
            width: 350,
            pt:1,
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box sx={{pl:2, pr:2, display: 'flex', alignItems: 'center',}}>
          <Typography variant="body1">
            Include Technical Bid
          </Typography>
          <Switch
            name="includeTB"
            checked={includeTB}
            disabled = {disabledtechnicalbid} 
            onChange={handleChange}
            sx={{ml: 'auto',}}
          />
        </Box>
        <Divider />
        {loader && <LinearProgress />}
        <Box
          sx={{ m: 2 }}
        >
          <Box
            sx={{
              display: 'flex', 
              alignItems: 'center', 
              border: ((theme)=> `1px solid ${theme.palette.bluegrey[500]}`),
              borderRadius: '4px'
            }}
          >
            <Chip 
              label={categroyTBTemplate?.display_name || "Link Template"}            
              disabled={!includeTB || itemizeTB || loader}            
              variant="filled"            
              sx={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                maxWidth: "70%",
                backgroundColor: 'transparent',
                mb:0,
              }}
              disableElevation
            />
            <Box 
              sx={{
                ml: 'auto'
              }}
            >
              {
                categroyTBTemplate?.id && 
                  (
                    <Tooltip title="Edit">
                      <IconButton
                        disabled={!includeTB || itemizeTB || loader}
                        onClick={() =>
                          handleTBAction(
                            "edit",
                            null, // itemId
                            null, // oldItemId
                            categroyTBTemplate?.id,
                            categroyTBTemplatePreviousValue?.pre_fetch_inquiry_id,
                          )
                        }
                        sx={{ color: (theme) => theme.palette.grey[600], }}
                      >
                        <EditNote />
                      </IconButton>
                    </Tooltip>
                  )
              }
              {
                (userInfo?.is_super_user === "Y" ||
                  masterPermission?.linking === "Y") && (
                  <Tooltip title="Link">
                    <IconButton
                      disabled={!includeTB || itemizeTB || loader}
                      onClick={() =>
                        handleTBAction(
                          "link",
                          null, // itemId
                          null, // oldItemId
                          categroyTBTemplate?.id,
                          categroyTBTemplatePreviousValue?.pre_fetch_inquiry_id,
                        )
                      }
                      sx={{ color: (theme) => theme.palette.grey[600], }}
                    >
                      <InsertLinkOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                )
              }
            </Box>
          </Box>
          
          <Box 
            sx={
              (!includeTB || itemizeTB || loader)
                ? { mb: 1, opacity: '0.5'}
                : { mb: 1}
            }
            >
            {includeTB && !loader && !itemizeTB &&
              (!categroyTBTemplate?.id 
                ? 
                  (
                    <Typography
                      variant="caption"
                      sx={{
                        mt: 1,
                        color: (theme) => theme.palette.error.main,
                      }}
                    >
                      TB not linked
                    </Typography>
                  ) 
                : 
                  !categroyTBTemplate?.has_user_fields
                    ?
                      <Typography
                        variant="caption"
                        sx={{
                          color: (theme) => theme.palette.success.dark,
                        }}
                      >
                        TB buyer fields does not exists
                      </Typography>
                    :
                      (
                        (categroyTBTemplatePreviousValue?.pre_fetch_inquiry_id > 0) &&
                        (parseInt(tbStage) !== 40)
                          ?
                            (
                              <Typography
                                variant="caption"
                                sx={{
                                  color: (theme) =>theme.palette.success.dark,
                                }}
                              >
                                Prefetched TB values
                              </Typography>
                            )
                          :
                            (
                              (parseInt(tbStage) === 40)
                                ?
                                  (
                                    <Typography
                                      variant="caption"
                                      sx={{
                                        color: (theme) =>theme.palette.success.dark,
                                      }}
                                    >
                                      TB values updated
                                    </Typography>
                                  )
                                :
                                  (
                                    <Typography
                                      variant="caption"
                                      sx={{ color: (theme) =>theme.palette.error.main }}
                                    >
                                      TB values not filled
                                    </Typography>
                                  )
                            )
                      )
              )
            }
          </Box>
        </Box>

        <Divider />

        <ListItem
          secondaryAction={
            <Switch
              disabled={!includeTB || loader || disabledtechnicalbid}
              name="itemizeTB"
              checked={includeTB && itemizeTB}
              onChange={handleChange}
            />
          }
        >
          <ListItemText primary="Itemize Technical Bid" />
        </ListItem>
      </Popover >

      {/* new design for linking the template (on hold) */}

      <TBSelectTemplateDialog
        open={templateDialogOpen}
        handleClose={() => setTemplateDialogOpen(false)}
        data={data}
      />
    </React.Fragment>
  );
}
const data = [
  {
    id: 27725,
    code: "Canon LED 32inch",
    name: "Canon LED 32inch",
    unit: "BAG",
    old_id: "27725",
    vendor_count: {
      total_count: 0,
      internation_count: 0,
      local_count: 0,
    },
    budgeted_rate: "900.000",
  },
  {
    id: 27725,
    code: "Canon LED 32inch",
    name: "Canon LED 32inch",
    unit: "BAG",
    old_id: "27725",
    vendor_count: {
      total_count: 0,
      internation_count: 0,
      local_count: 0,
    },
    budgeted_rate: "900.000",
  },
  {
    id: 27725,
    code: "Canon LED 32inch",
    name: "Canon LED 32inch",
    unit: "BAG",
    old_id: "27725",
    vendor_count: {
      total_count: 0,
      internation_count: 0,
      local_count: 0,
    },
    budgeted_rate: "900.000",
  },
  {
    id: 27725,
    code: "Canon LED 32inch",
    name: "Canon LED 32inch",
    unit: "BAG",
    old_id: "27725",
    vendor_count: {
      total_count: 0,
      internation_count: 0,
      local_count: 0,
    },
    budgeted_rate: "900.000",
  },
  {
    id: 27725,
    code: "Canon LED 32inch",
    name: "Canon LED 32inch",
    unit: "BAG",
    old_id: "27725",
    vendor_count: {
      total_count: 0,
      internation_count: 0,
      local_count: 0,
    },
    budgeted_rate: "900.000",
  },
  {
    id: 27725,
    code: "Canon LED 32inch",
    name: "Canon LED 32inch",
    unit: "BAG",
    old_id: "27725",
    vendor_count: {
      total_count: 0,
      internation_count: 0,
      local_count: 0,
    },
    budgeted_rate: "900.000",
  },
  {
    id: 27725,
    code: "Canon LED 32inch",
    name: "Canon LED 32inch",
    unit: "BAG",
    old_id: "27725",
    vendor_count: {
      total_count: 0,
      internation_count: 0,
      local_count: 0,
    },
    budgeted_rate: "900.000",
  },
  {
    id: 27725,
    code: "Canon LED 32inch",
    name: "Canon LED 32inch",
    unit: "BAG",
    old_id: "27725",
    vendor_count: {
      total_count: 0,
      internation_count: 0,
      local_count: 0,
    },
    budgeted_rate: "900.000",
  },
  {
    id: 27725,
    code: "Canon LED 32inch",
    name: "Canon LED 32inch",
    unit: "BAG",
    old_id: "27725",
    vendor_count: {
      total_count: 0,
      internation_count: 0,
      local_count: 0,
    },
    budgeted_rate: "900.000",
  },
  {
    id: 27725,
    code: "Canon LED 32inch",
    name: "Canon LED 32inch",
    unit: "BAG",
    old_id: "27725",
    vendor_count: {
      total_count: 0,
      internation_count: 0,
      local_count: 0,
    },
    budgeted_rate: "900.000",
  },
  {
    id: 27725,
    code: "Canon LED 32inch",
    name: "Canon LED 32inch",
    unit: "BAG",
    old_id: "27725",
    vendor_count: {
      total_count: 0,
      internation_count: 0,
      local_count: 0,
    },
    budgeted_rate: "900.000",
  },
  {
    id: 27725,
    code: "Canon LED 32inch",
    name: "Canon LED 32inch",
    unit: "BAG",
    old_id: "27725",
    vendor_count: {
      total_count: 0,
      internation_count: 0,
      local_count: 0,
    },
    budgeted_rate: "900.000",
  },
];
