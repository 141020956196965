import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import locale from 'date-fns/locale/en-US';
import InputBase from '@mui/material/InputBase';
import {
    GRID_DATE_COL_DEF,
    useGridApiContext,
  } from "@mui/x-data-grid-pro";
import { DateTimePicker } from '@mui/x-date-pickers';
import { styled } from "@mui/material/styles";


function buildApplyDateFilterFn(filterItem, compareFn, showTime = false) {
    if (!filterItem.value) {
      return null;
    }
  
    // Make a copy of the date to not reset the hours in the original object
    const filterValueCopy = new Date(filterItem.value);
    filterValueCopy.setHours(0, 0, 0, 0);
  
    const filterValueMs = filterValueCopy.getTime();
  
    return ({ value }) => {
      if (!value) {
        return false;
      }
  
      // Make a copy of the date to not reset the hours in the original object
      const dateCopy = new Date(value);
      dateCopy.setHours(
        showTime ? value.getHours() : 0,
        showTime ? value.getMinutes() : 0,
        0,
        0,
      );
      const cellValueMs = dateCopy.getTime();
  
      return compareFn(cellValueMs, filterValueMs);
    };
  }

  function GridFilterDateInput(props) {
    const { item, showTime, applyValue, apiRef } = props;
  
    const Component = showTime ? DateTimePicker : DatePicker;
  
    const handleFilterChangeDate = (newValue) => {
      
      applyValue({ ...item, value: newValue });
    };
  
    return (
      <Component
        value={item.value || null}
        autoFocus
        label={apiRef.current.getLocaleText('filterPanelInputLabel')}
        format="dd/MM/yyyy"
        slotProps={{
          textField: {
            variant: 'standard',
          },
          inputAdornment: {
            sx: {
              '& .MuiButtonBase-root': {
                marginRight: -1,
              },
            },
          },
        }}
        onChange={handleFilterChangeDate}
      />
    );
  }

  function getDateFilterOperators(showTime = false) {
    return [
      {
        value: 'is',
        getApplyFilterFn: (filterItem) => {
          return buildApplyDateFilterFn(
            filterItem,
            (value1, value2) => value1 === value2,
            showTime,
          );
        },
        InputComponent: GridFilterDateInput,
        InputComponentProps: { showTime },
      },
      {
        value: 'not',
        getApplyFilterFn: (filterItem) => {
          return buildApplyDateFilterFn(
            filterItem,
            (value1, value2) => value1 !== value2,
            showTime,
          );
        },
        InputComponent: GridFilterDateInput,
        InputComponentProps: { showTime },
      },
      {
        value: 'after',
        getApplyFilterFn: (filterItem) => {
          return buildApplyDateFilterFn(
            filterItem,
            (value1, value2) => value1 > value2,
            showTime,
          );
        },
        InputComponent: GridFilterDateInput,
        InputComponentProps: { showTime },
      },
      {
        value: 'onOrAfter',
        getApplyFilterFn: (filterItem) => {
          return buildApplyDateFilterFn(
            filterItem,
            (value1, value2) => value1 >= value2,
            showTime,
          );
        },
        InputComponent: GridFilterDateInput,
        InputComponentProps: { showTime },
      },
      {
        value: 'before',
        getApplyFilterFn: (filterItem) => {
          return buildApplyDateFilterFn(
            filterItem,
            (value1, value2) => value1 < value2,
            showTime,
          );
        },
        InputComponent: GridFilterDateInput,
        InputComponentProps: { showTime },
      },
      {
        value: 'onOrBefore',
        getApplyFilterFn: (filterItem) => {
          return buildApplyDateFilterFn(
            filterItem,
            (value1, value2) => value1 <= value2,
            showTime,
          );
        },
        InputComponent: GridFilterDateInput,
        InputComponentProps: { showTime },
      },
      {
        value: 'isEmpty',
        getApplyFilterFn: () => {
          return ({ value }) => {
            return value == null;
          };
        },
        requiresFilterValue: false,
      },
      {
        value: 'isNotEmpty',
        getApplyFilterFn: () => {
          return ({ value }) => {
            return value != null;
          };
        },
        requiresFilterValue: false,
      },
    ];
  }
  
  const dateAdapter = new AdapterDateFns({ locale });

  const GridEditDateInput = styled(InputBase)({
    fontSize: 'inherit',
    padding: '0 9px',
  });
  
  function WrappedGridEditDateInput(props) {
    const { InputProps, ...other } = props;
    return <GridEditDateInput fullWidth {...InputProps} {...other} />;
  }
  function GridEditDateCell({ id, field, value, colDef }) {
    const apiRef = useGridApiContext();
  
    const Component = colDef.type === 'dateTime' ? DateTimePicker : DatePicker;
  
    const handleChangeDate = (newValue) => {
      apiRef.current.setEditCellValue({ id, field, value: newValue });
    };
  
    return (
      <Component
        value={value}
        autoFocus
        onChange={handleChangeDate}
        slots={{ textField: WrappedGridEditDateInput }}
      />
    );
  }

  export const dateColumnTypeFilter = {
    ...GRID_DATE_COL_DEF,
    resizable: false,
    renderEditCell: (params) => {
      return <GridEditDateCell {...params} />;
    },
    filterOperators: getDateFilterOperators(),
    valueFormatter: (params) => {
      if (typeof params.value === 'string') {
        return params.value;
      }
      if (params.value) {
        return dateAdapter.format(params.value, 'keyboardDate');
      }
      return '';
    },
  };
